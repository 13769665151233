import React, { Component } from "react";
import "./styles.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";
import { setCurrentTreatmentPlan } from "../../CreateTPlan_1Upload/actions";
import MarksScorePlot from "./MarksScorePlot";
import { getNewLesionContour } from "./lesion_helper";
import WorkflowHeader from "./WorkflowHeader";
import LesionStatistics from "./LesionStatistics";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import AHButton from "../../__shared__/StyleGuide/AHButton";

class LesionContourWorkflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userSelectedProstateCoverage: 0,
            userSelectedCPMThreshold: 0,
            userSelectedMarksScore: 0,
            userSelectedLesionVolume: 0,
            updatingMargin: false,
        };

        this.setLesionParameters = this.setLesionParameters.bind(this);
        this.handleConfirmClick = this.handleConfirmClick.bind(this);
        this.getNewLesionContourHandler =
            this.getNewLesionContourHandler.bind(this);
    }

    componentDidMount() {
        let plan = this.props.currentTreatmentPlan;

        let lesionVolume = 0.0;
        if (TreatmentPlan.hasMargins(plan.TreatmentPlan)) {
            lesionVolume = TreatmentPlan.getMarginByIdx(
                plan.TreatmentPlan,
                0
            ).VolumeCC;
        }

        this.setState({
            userSelectedProstateCoverage:
                plan.SavedData.UserSelectedLesionCoverageOfProstate,
            userSelectedCPMThreshold: plan.SavedData.UserSelectedCPMThreshold,
            userSelectedMarksScore: plan.SavedData.UserSelectedMarksScore,
            userSelectedLesionVolume: lesionVolume,
        });
    }

    handleConfirmClick() {
        if (this.props.handleConfirm) {
            this.props.handleConfirm();
        }
    }

    handleClick(e) {
        e.stopPropagation();
    }

    getNewLesionContourHandler(userSelectedCPMThreshold = undefined) {
        let cpmThreshold = userSelectedCPMThreshold
            ? userSelectedCPMThreshold
            : this.state.userSelectedCPMThreshold;

        getNewLesionContour(this, cpmThreshold);
    }

    setLesionParameters(params) {
        this.setState({
            userSelectedProstateCoverage: params.prostateCoverage,
            userSelectedCPMThreshold: params.cpmThreshold,
            userSelectedMarksScore: params.marksScore,
            userSelectedLesionVolume: params.lesionVolume,
        });
    }

    render() {
        let plan = this.props.currentTreatmentPlan;

        return (
            <div
                id={this.props.id}
                className={`margin-workflow`}
                style={this.props.style}
                onClick={this.handleClick}
            >
                <WorkflowHeader />
                <MarksScorePlot
                    currentTreatmentPlan={plan}
                    defaultProstateCoverage={
                        plan.SavedData.OptimalLesionCoverageOfProstate
                    }
                    defaultMarksScore={plan.SavedData.OptimalMarksScore}
                    userSelectedProstateCoverage={
                        this.state.userSelectedProstateCoverage
                    }
                    userSelectedMarksScore={this.state.userSelectedMarksScore}
                    setLesionParameters={this.setLesionParameters}
                    getNewLesionContourHandler={this.getNewLesionContourHandler}
                />
                <LesionStatistics
                    currentTreatmentPlan={plan}
                    updatingMargin={this.state.updatingMargin}
                    userSelectedProstateCoverage={
                        this.state.userSelectedProstateCoverage
                    }
                    userSelectedMarksScore={this.state.userSelectedMarksScore}
                    userSelectedLesionVolume={
                        this.state.userSelectedLesionVolume
                    }
                />
                <div className={"margin-workflow-confirm-btn"}>
                    <AHButton
                        id="lesion-contour-confirm-btn"
                        onClick={this.handleConfirmClick}
                        size={"large"}
                        text={"Confirm Cancer Lesion Contour"}
                    />
                </div>
            </div>
        );
    }
}

LesionContourWorkflow.propTypes = {
    handleConfirm: PropTypes.func,
    active: PropTypes.bool,
    onMarginCreation: PropTypes.func,
    id: PropTypes.string,
    style: PropTypes.object,
    patientuuid: PropTypes.string,
    visituuid: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
};

LesionContourWorkflow.defaultProps = {
    handleConfirm: () => {},
    active: false,
    onMarginCreation: () => {},
    patientuuid: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    currentTreatmentPlan: {},
};

/* istanbul ignore once */
const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        planuuid: state.CreatePlanReducer.planuuid,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

/* istanbul ignore once */
const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LesionContourWorkflow);
