import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const NumberCircle = ({ fill, textFill, listIndex }) => {
    const [indexCounter, setIndexCounter] = useState(1);

    const getCircleFill = () =>
        getComputedStyle(document.body).getPropertyValue(
            "--avendaStyleGuideGray4"
        );
    const getCharacterFill = () =>
        getComputedStyle(document.body).getPropertyValue("--backgroundWhite");

    const circleFill = fill || getCircleFill();
    const characterFill = textFill || getCharacterFill();
    const defaultListIndex = listIndex ? listIndex : indexCounter;

    useEffect(() => {
        setIndexCounter((prevIndex) => prevIndex + 1);
    }, []);

    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <circle fill={circleFill} cx="15" cy="15" r="15" />
                    <text
                        fontFamily="Helvetica"
                        fontSize="18px"
                        fontWeight="normal"
                        fill={characterFill}
                        style={{ userSelect: "none" }}
                    >
                        <tspan x="10" y="21">
                            {defaultListIndex}
                        </tspan>
                    </text>
                </g>
            </g>
        </svg>
    );
};

NumberCircle.propTypes = {
    fill: PropTypes.string,
    fontSize: PropTypes.string,
    textFill: PropTypes.string,
    listIndex: PropTypes.number,
};

export default NumberCircle;
