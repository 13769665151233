import React, { Component } from "react";
import "./styles.css";
import UploadCardWrapper from "../UploadCardWrapper";
import { connect } from "react-redux";
import {
    setBiopsyDataUploaded,
    setPathReportUploaded,
    setTemplateUploaded,
    setUploadProgress,
    setParseProgress,
    setUploadState,
} from "../actions";
import {
    BIOPSY_UPLOAD_NUDGE_TEXT,
    PATIENT_DETAILS_NUDGE_TEXT,
    uploadCardText,
    uploadStates,
    parsingCardLabels,
    UPLOAD_CARD_NUMBER,
} from "../constants";
import { deleteUpload } from "../helpers";
import DecisionAlertCard from "../../__shared__/DecisionAlertCard";
import MutedNudgeText from "../../__shared__/MutedNudgeText";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../Home/constants";
import SpinLoader from "../../__shared__/SpinLoader";
import { If, Then, Else } from "react-if";
import { deleteFileFromCloud } from "../../../helpers/backend_api";
import { getMRIURIs } from "../../../helpers/tplan/tplan";
import { rootStore } from "../../../redux/store";
import { setErrorState } from "../../../redux/error_banner/actions";
import { clientErrorMessages } from "../../../constants";

class UploadContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uploadIteration: 0,
            displayDeleteAlert: parsingCardLabels.NONE,
            deleteInProgress: false,
        };

        this.cardRefs = [];
        for (let i = 0; i < UPLOAD_CARD_NUMBER; i++) {
            this.cardRefs[parseInt(i)] = React.createRef();
        }

        this.handleDeleteUpload = this.handleDeleteUpload.bind(this);
        this.deleteAllUploads = this.deleteAllUploads.bind(this);
        this.getDeleteAlert = this.getDeleteAlert.bind(this);
        this.resetUploadCard = this.resetUploadCard.bind(this);
        this.setDeleteAlert = this.setDeleteAlert.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
        this.deleteTemplate = this.deleteTemplate.bind(this);
        this.deleteMRI = this.deleteMRI.bind(this);
    }

    componentDidMount() {
        for (let i = 0; i < UPLOAD_CARD_NUMBER; i++) {
            if (this.props.uploadState[parseInt(i)] !== uploadStates.COMPLETE) {
                this.cardRefs[parseInt(i)].current.resetCardState();

                if (i === 0) {
                    setBiopsyDataUploaded(false);
                }
            }
        }
    }

    handleDeleteUpload() {
        this.setState({
            displayDeleteAlert: parsingCardLabels.BIOPSY,
        });
    }

    deleteAllUploads() {
        let scope = this;
        deleteUpload({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            visituuid: this.props.visituuid,
            iteration: this.state.uploadIteration,
            token: this.props.authToken,
        }).then(() => {
            for (var i = 0; i < UPLOAD_CARD_NUMBER; i++) {
                scope.cardRefs[parseInt(i)].current.resetCardState();
            }

            this.setState({
                displayDeleteAlert: parsingCardLabels.NONE,
                uploadIteration: this.state.uploadIteration + 1,
                deleteInProgress: false,
            });

            setBiopsyDataUploaded(false);
        });
    }

    getDeleteAlert(label) {
        if (label === parsingCardLabels.NONE) {
            return null;
        }

        let cardTitleText, cardInfoText;

        switch (label) {
            case parsingCardLabels.BIOPSY:
                cardTitleText =
                    "DELETE ALL Uploaded files including biopsy data?";
                cardInfoText =
                    "Note: This will delete ALL uploaded files and reset the plan.";
                break;
            case parsingCardLabels.REPORT:
                cardTitleText = "DELETE Uploaded pathology report?";
                cardInfoText = "Note: You can upload the same files again.";
                break;
            case parsingCardLabels.TEMPLATE:
                cardTitleText = "DELETE Uploaded template?";
                cardInfoText = "Note: You can upload the same files again.";
                break;
            case parsingCardLabels.MRI:
                cardTitleText = "DELETE Uploaded MRI files?";
                cardInfoText =
                    "Note: You can upload the same files again. You must have an MRI uploaded to proceed.";
                break;
            default:
                break;
        }

        return (
            <div className={"delete-alert-card"}>
                <DecisionAlertCard
                    id={"summary-alert"}
                    noBtnCallback={() => {
                        this.setState({
                            displayDeleteAlert: parsingCardLabels.NONE,
                        });
                    }}
                    noBtnTxt={"Go Back"}
                    yesBtnTxt={
                        <If condition={this.state.deleteInProgress}>
                            <Then>
                                <SpinLoader
                                    display={"inline-block"}
                                    height={"25px"}
                                    width={"25px"}
                                    animationDuration={"1s"}
                                />
                            </Then>
                            <Else>{"Confirm"}</Else>
                        </If>
                    }
                    yesBtnCallback={() => {
                        this.resetUploadCard(label);
                    }}
                >
                    <div className={"summary-alert-card-header"}>
                        <p
                            className={"display-26"}
                            style={{ color: "#000000" }}
                        >
                            {cardTitleText}
                        </p>
                    </div>
                    <div className={"summary-alert-card-body"}>
                        <div className={"summary-alert-row"}>
                            <p
                                className={"display-16"}
                                style={{ color: "#000000" }}
                            >
                                {cardInfoText}
                            </p>
                        </div>
                    </div>
                </DecisionAlertCard>
            </div>
        );
    }

    setDeleteAlert(label) {
        this.setState({
            displayDeleteAlert: label,
        });
    }

    async deleteReport() {
        let scope = this;
        await deleteFileFromCloud({
            visituuid: this.props.visituuid,
            patientuuid: this.props.patientuuid,
            token: this.props.authToken,
            fileURI:
                this.props.currentTreatmentPlan.TreatmentPlan.PatientData
                    .PathologyReportURI,
        }).then(() => {
            scope.cardRefs[1].current.resetCardState();
            this.props.setPathReportUploaded(false);
        });
    }

    async deleteTemplate() {
        let scope = this;
        await deleteFileFromCloud({
            visituuid: this.props.visituuid,
            patientuuid: this.props.patientuuid,
            token: this.props.authToken,
            fileURI:
                this.props.currentTreatmentPlan.TreatmentPlan.PatientData
                    .TemplateURI,
        }).then(() => {
            scope.cardRefs[3].current.resetCardState();
            this.props.setTemplateUploaded(false);
        });
    }

    async deleteMRI() {
        try {
            const mriUris = getMRIURIs(
                JSON.parse(
                    JSON.stringify(
                        this.props.currentTreatmentPlan.TreatmentPlan
                    )
                )
            );
            if (Array.isArray(mriUris) && mriUris.length > 0) {
                const deletePromises = mriUris.map((uri) =>
                    deleteFileFromCloud({
                        visituuid: this.props.visituuid,
                        patientuuid: this.props.patientuuid,
                        token: this.props.authToken,
                        fileURI: uri,
                    })
                );

                await Promise.all(deletePromises);
            }

            this.cardRefs[2].current.resetCardState();
            this.props.setMriUploaded(false);
        } catch (error) {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.FAILED_TO_DELETE_MRI)
            );
        }
    }

    resetUploadCard(label) {
        this.setState({
            deleteInProgress: true,
        });

        switch (label) {
            case parsingCardLabels.BIOPSY:
                this.deleteAllUploads();
                return;
            case parsingCardLabels.REPORT:
                this.deleteReport();
                break;
            case parsingCardLabels.TEMPLATE:
                this.deleteTemplate();
                break;
            case parsingCardLabels.MRI:
                this.deleteMRI();
                break;
            default:
                break;
        }

        this.props.resetPlanProgress(label);
        this.setState({
            displayDeleteAlert: parsingCardLabels.NONE,
            uploadIteration: this.state.uploadIteration + 1,
            deleteInProgress: false,
        });
    }

    render() {
        let upldState = this.props.isBiopsyDataParsed ? "finished" : "waiting";
        let mutedTextContent = this.props.isBiopsyDataParsed
            ? PATIENT_DETAILS_NUDGE_TEXT
            : BIOPSY_UPLOAD_NUDGE_TEXT;

        return (
            <div>
                <div className={`nudge-text-cntr ${upldState}`}>
                    <MutedNudgeText content={mutedTextContent} id={"Step1"} />
                </div>
                <div
                    id={this.props.id}
                    className={`upload-container ${upldState}`}
                    style={this.props.style}
                >
                    <div className={`upload-container-header ${upldState}`}>
                        <div className={"header-cnts"}>
                            <p
                                id={"upload-header-text"}
                                className={"display-30"}
                                style={{ color: "#7C7C7C" }}
                            >
                                Upload Patient Data
                            </p>
                            <div
                                className={`trash-upload-button ${upldState}`}
                                onClick={this.handleDeleteUpload}
                            />
                        </div>
                    </div>
                    <div className={`upload-cards ${upldState}`}>
                        <UploadCardWrapper
                            ref={this.cardRefs[0]}
                            idx={0}
                            completedText={
                                uploadCardText.BIOPSY_UPLOAD_COMPLETE
                            }
                            text={uploadCardText.BIOPSY_UPLOAD_DEFAULT}
                            label={parsingCardLabels.BIOPSY}
                            folderSelect={true}
                            uploadCallback={this.props.setBiopsyDataUploaded}
                            parseCallback={this.props.setBiopsyDataParsed}
                            uploadIteration={this.state.uploadIteration}
                            setDeleteAlert={this.setDeleteAlert}
                            isFreehand={false}
                        />
                        <div className={`sub-upload-section ${upldState}`}>
                            <UploadCardWrapper
                                ref={this.cardRefs[1]}
                                idx={1}
                                completedText={
                                    uploadCardText.PATH_UPLOAD_COMPLETE
                                }
                                text={uploadCardText.PATH_UPLOAD_DEFAULT}
                                label={parsingCardLabels.REPORT}
                                folderSelect={false}
                                uploadCallback={
                                    this.props.setPathReportUploaded
                                }
                                uploadIteration={this.state.uploadIteration}
                                setDeleteAlert={this.setDeleteAlert}
                                isFreehand={false}
                            />
                            <UploadCardWrapper
                                ref={this.cardRefs[3]}
                                idx={3}
                                completedText={
                                    uploadCardText.TEMPLATE_UPLOAD_COMPLETE
                                }
                                text={uploadCardText.TEMPLATE_UPLOAD_DEFAULT}
                                label={parsingCardLabels.TEMPLATE}
                                folderSelect={false}
                                uploadCallback={this.props.setTemplateUploaded}
                                uploadIteration={this.state.uploadIteration}
                                setDeleteAlert={this.setDeleteAlert}
                                isFreehand={false}
                            />
                            <UploadCardWrapper
                                ref={this.cardRefs[2]}
                                idx={2}
                                completedText={uploadCardText.MRI_UPLOAD_FOUND}
                                text={uploadCardText.MRI_UPLOAD_DEFAULT}
                                label={parsingCardLabels.MRI}
                                folderSelect={true}
                                uploadCallback={this.props.setMriUploaded}
                                parseCallback={this.props.setMriParsed}
                                setDeleteAlert={this.setDeleteAlert}
                                uploadIteration={this.state.uploadIteration}
                                uploadCompleteOverride={this.props.mriParsed}
                                isFreehand={false}
                            />
                        </div>
                    </div>
                </div>
                {this.getDeleteAlert(this.state.displayDeleteAlert)}
            </div>
        );
    }
}

UploadContainer.propTypes = {
    useruuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    visituuid: PropTypes.string,
    authToken: PropTypes.string,
    isBiopsyDataParsed: PropTypes.bool,
    setBiopsyDataUploaded: PropTypes.func,
    id: PropTypes.string,
    setBiopsyDataParsed: PropTypes.func,
    setPathReportUploaded: PropTypes.func,
    setTemplateUploaded: PropTypes.func,
    setMriUploaded: PropTypes.func,
    style: PropTypes.object,
    mriUploaded: PropTypes.bool,
    showMRIDeleteCard: PropTypes.func,
    resetPlanProgress: PropTypes.func,
    setMriParsed: PropTypes.func,
    mriParsed: PropTypes.bool,
    uploadState: PropTypes.array,
    currentTreatmentPlan: PropTypes.object,
};

UploadContainer.defaultProps = {
    useruuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    mriUploaded: false,
    mriParsed: false,
    currentTreatmentPlan: {},
};

const mapStateToProps = function (state) {
    return {
        isBiopsyDataUploaded: state.CreatePlanReducer.isBiopsyDataUploaded,
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        planuuid: state.CreatePlanReducer.planuuid,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        uploadState: state.CreatePlanReducer.uploadState,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setBiopsyDataUploaded: (isOn) => dispatch(setBiopsyDataUploaded(isOn)),
        setPathReportUploaded: (isOn) => dispatch(setPathReportUploaded(isOn)),
        setTemplateUploaded: (isOn) => dispatch(setTemplateUploaded(isOn)),
        setUploadProgress: (idx, prog) =>
            dispatch(setUploadProgress(idx, prog)),
        setParseProgress: (idx, prog) => dispatch(setParseProgress(idx, prog)),
        setUploadState: (idx, upstate) =>
            dispatch(setUploadState(idx, upstate)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadContainer);
