import React, { Component } from "react";
import LoginForgotPassword from "../ForgotPassword";
import LoginSignupButton from "../SignupButton";
import PropTypes from "prop-types";
import AHButton from "../../__shared__/StyleGuide/AHButton";
import { EMPTY_STRING, ENTER_KEY, MAX_EMAIL_LENGTH } from "../../../constants";
import AHAlphaField from "../../__shared__/StyleGuide/AHAlphaField";
import { setPassword, setUsername } from "../actions";
import { connect } from "react-redux";
import { signUpFormFields } from "../../SignUpFlow/constants";

class LoginDialog extends Component {
    onChange = (label, value) => {
        switch (label) {
            case signUpFormFields.USERNAME: {
                this.props.setUsername(value);
                break;
            }
            case signUpFormFields.PASS: {
                this.props.setPassword(value);
                break;
            }
            default:
                break;
        }
    };

    handleKey = (e) => {
        if (e.which === ENTER_KEY) {
            e.preventDefault();
            this.props.submitHandler();
        }
    };

    render() {
        return (
            <div className={"signup-form"}>
                <form onKeyPress={this.handleKey}>
                    {this.props.error && (
                        <div
                            style={{
                                textAlign: "center",
                                marginBottom: "20px",
                            }}
                        >
                            <p
                                className={
                                    "display-14-bold red text-center d-inline-block ml-2"
                                }
                            >
                                {this.props.error}
                            </p>
                        </div>
                    )}

                    <AHAlphaField
                        label={signUpFormFields.USERNAME}
                        value={this.props.unText}
                        placeholder={"Email"}
                        onChange={this.onChange}
                        id={"login-username-field"}
                        className={"ah-alpha-field-wrapper"}
                        type={"text"}
                        maxLength={MAX_EMAIL_LENGTH}
                        showLabel={false}
                    />

                    <AHAlphaField
                        label={signUpFormFields.PASS}
                        value={this.props.pwText}
                        placeholder={"Password"}
                        onChange={this.onChange}
                        id={"login-password-field"}
                        className={"ah-alpha-field-wrapper"}
                        type={"password"}
                        maxLength={MAX_EMAIL_LENGTH}
                        showLabel={false}
                    />

                    <AHButton
                        id={"login-submit-button"}
                        className={"login-submit-button"}
                        onClick={this.props.submitHandler}
                        isOutline={false}
                        isSecondary={false}
                        isDynamic={false}
                        text={"Log In"}
                        size={"large"}
                    />

                    <LoginForgotPassword />

                    <LoginSignupButton />
                </form>
            </div>
        );
    }
}

LoginDialog.propTypes = {
    error: PropTypes.string,
    unText: PropTypes.string,
    pwText: PropTypes.string,
    setUsername: PropTypes.func,
    setPassword: PropTypes.func,
    submitHandler: PropTypes.func,
};

LoginDialog.defaultProps = {
    unText: EMPTY_STRING,
    pwText: EMPTY_STRING,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        unText: state.LoginReducer.unText,
        pwText: state.LoginReducer.pwText,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setUsername: (text) => dispatch(setUsername(text)),
        setPassword: (text) => dispatch(setPassword(text)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginDialog);
