import { EMPTY_STRING } from "../../constants";
import { signUpFlowStates } from "./constants";

export const initialState = {
    form: {
        firstName: EMPTY_STRING,
        lastName: EMPTY_STRING,
        email: EMPTY_STRING,
        phone: EMPTY_STRING,
        birthDay: EMPTY_STRING,
        username: EMPTY_STRING,
        password: EMPTY_STRING,
        confPassword: EMPTY_STRING,
    },
    error: {
        isInvalid: false,
        msg: EMPTY_STRING,
    },
    flowState: signUpFlowStates.ACCESS_CODE,
};
