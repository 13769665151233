// action types
export const UPDATE_FORM = "UPDATE_FORM";
export const SET_FORM_ERROR_STATE = "SET_FORM_ERROR_STATE";
export const SET_SIGNUP_FLOW_STATE = "SET_SIGNUP_FLOW_STATE";

// action creators
export const updateForm = function (formData) {
    return {
        type: UPDATE_FORM,
        formData,
    };
};

export const setFormErrorState = function (error) {
    return {
        type: SET_FORM_ERROR_STATE,
        error,
    };
};

export const setSignUpFlowState = function (state) {
    return {
        type: SET_SIGNUP_FLOW_STATE,
        state,
    };
};
