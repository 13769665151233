export const CUSTOM_TARGET_NAMES = {
    TARGET_NAME: "CUSTOM_TARGET",
    LC_NAME: "LC_CUSTOM_TARGET",
    AXES_NAME: "AXES_CUSTOM_TARGET",
};

export const SPHERE_SEGMENTS = 20;

export const LC_MESH_NAMES = {
    TIP: "LC_TIP",
    ROD: "LC_ROD",
};
