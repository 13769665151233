export const initialState = {
    segmentationEditingActive: false,
    resetSegmentation: false,
    saveSegmentation: false,
    saveEditsSegError: false,

    displaySettings: {
        axesEnabled: true,
        targetsFixedVisible: false,
    },
};
