import React, { Component } from "react";
import {
    EMPTY_STRING,
    messages,
    fields,
    buttonSize,
    viewType,
    submitButtonText,
} from "./constants";
import PropTypes from "prop-types";
import { If, Then } from "react-if";
import "../../../Profile/Security/styles.css";
import TableView from "./Views/TableView";
import {
    checkConfPassword,
    checkValidPassword,
} from "../../../SignUpFlow/helpers";
import MiniView from "./Views/MiniView";

class NewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: EMPTY_STRING,
            confirmPassword: EMPTY_STRING,
            errorPasswordMsg: EMPTY_STRING,
            successPasswordMsg: EMPTY_STRING,
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange = (label, value) => {
        switch (label) {
            case fields.NEW_PASSWORD: {
                this.setState({ newPassword: value });
                break;
            }
            case fields.ENTER_PASSWORD: {
                this.setState({ newPassword: value });
                break;
            }
            case fields.PASSWORD_CONFIRMATION: {
                this.setState({ confirmPassword: value });
                break;
            }
            default:
                break;
        }
    };

    onSubmit() {
        let newUserData = this.props.userData;
        newUserData.password = this.state.newPassword;
        this.setState({
            errorPasswordMsg: EMPTY_STRING,
            successPasswordMsg: EMPTY_STRING,
        });

        if (this.props.viewType === viewType.TABLE_VIEW) {
            if (
                this.props.currentPassword.length === 0 ||
                !this.props.currentPassword
            ) {
                this.props.onParentSubmit(messages.EMPTY_CURRENT_PASSWORD);
                return;
            }

            if (this.state.newPassword === this.props.currentPassword) {
                this.props.onParentSubmit(messages.SAME_PASSWORD);
                return;
            }
        }

        let form = {
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword,
            firstName: this.props.userData.firstName,
            lastName: this.props.userData.lastName,
            email: this.props.userData.username,
        };
        let res = checkValidPassword(form);
        if (res) {
            this.props.onParentSubmit(res);
            return;
        }

        res = checkConfPassword(
            this.state.newPassword,
            this.state.confirmPassword
        );
        if (res != null) {
            this.props.onParentSubmit(res);
            return;
        }

        this.props.onParentSubmit(messages.SUCCESS, this.state.newPassword);
    }

    render() {
        return (
            <>
                <If condition={this.props.viewType === viewType.TABLE_VIEW}>
                    <Then>
                        <TableView
                            submitButtonText={this.props.submitButtonText}
                            newPassword={this.state.newPassword}
                            confirmPassword={this.state.confirmPassword}
                            userData={this.props.userData}
                            onChange={this.onChange}
                            onSubmit={this.onSubmit}
                            isLabelVisible={this.props.isLabelVisible}
                            isSubmitButtonPlacedRight={
                                this.props.isSubmitButtonPlacedRight
                            }
                            submitButtonSize={this.props.submitButtonSize}
                            newPasswordId={this.props.newPasswordId}
                            confirmPasswordId={this.props.confirmPasswordId}
                            newPasswordPlaceholder={
                                this.props.newPasswordPlaceholder
                            }
                            confirmPasswordPlaceholder={
                                this.props.confirmPasswordPlaceholder
                            }
                        />
                    </Then>
                </If>
                <If condition={this.props.viewType === viewType.MINI_VIEW}>
                    <Then>
                        <MiniView
                            submitButtonText={this.props.submitButtonText}
                            newPassword={this.state.newPassword}
                            confirmPassword={this.state.confirmPassword}
                            userData={this.props.userData}
                            onChange={this.onChange}
                            onSubmit={this.onSubmit}
                            isLabelVisible={this.props.isLabelVisible}
                            isSubmitButtonPlacedRight={
                                this.props.isSubmitButtonPlacedRight
                            }
                            submitButtonSize={this.props.submitButtonSize}
                            newPasswordId={this.props.newPasswordId}
                            confirmPasswordId={this.props.confirmPasswordId}
                            newPasswordPlaceholder={
                                this.props.newPasswordPlaceholder
                            }
                            confirmPasswordPlaceholder={
                                this.props.confirmPasswordPlaceholder
                            }
                        />
                    </Then>
                </If>
            </>
        );
    }
}

NewPassword.propTypes = {
    submitButtonText: PropTypes.string,
    currentPassword: PropTypes.string,
    viewType: PropTypes.string,
    userData: PropTypes.object,
    onParentSubmit: PropTypes.func,
    isLabelVisible: PropTypes.bool,
    isSubmitButtonPlacedRight: PropTypes.bool,
    submitButtonSize: PropTypes.string,
    newPasswordId: PropTypes.string,
    confirmPasswordId: PropTypes.string,
    newPasswordPlaceholder: PropTypes.string,
    confirmPasswordPlaceholder: PropTypes.string,
};

NewPassword.defaultProps = {
    submitButtonText: submitButtonText.SUBMIT,
    currentPassword: EMPTY_STRING,
    userData: {},
    isLabelVisible: true,
    isSubmitButtonPlacedRight: true,
    submitButtonSize: buttonSize.MEDIUM,
    newPasswordPlaceholder: EMPTY_STRING,
    confirmPasswordPlaceholder: EMPTY_STRING,
};

export default NewPassword;
