import React from "react";

const LightBulbAlert = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 38 38"
        fill="none"
    >
        <circle cx="26.5" cy="7.5" r="4.5" fill="#FF3838" />
        <path
            d="M22.3299 4.01281C21.0213 3.46778 19.5867 3.16669 18.0833 3.16669C11.9733 3.16669 7 8.13994 7 14.25C7 17.8679 8.68942 19.836 10.3266 21.7392L10.3302 21.7434C10.8959 22.4039 11.482 23.0883 12.0429 23.8751C12.2709 24.2013 12.6446 25.4521 12.9787 26.9167L13.3333 30.0834H22.8333L23.1912 26.9167C23.5284 25.4489 23.9037 24.1949 24.1301 23.8688C24.68 23.0829 25.2639 22.3988 25.8284 21.7373L25.8512 21.7107C27.482 19.8012 29.1667 17.8268 29.1667 14.25C29.1667 13.6339 29.1161 13.0294 29.0189 12.4404C29.0185 12.438 29.0181 12.4356 29.0177 12.4333C28.2839 12.7961 27.4574 13 26.5833 13C26.35 13 26.1201 12.9855 25.8945 12.9573C25.9639 13.3781 26 13.8099 26 14.25C26 16.6575 24.9826 17.8498 23.4444 19.6523L23.4429 19.6539L23.4139 19.688C22.8304 20.3731 22.172 21.1462 21.5382 22.0479C20.851 23.0249 20.2778 25.3334 19.9453 26.9167H16.2229C15.892 25.3302 15.3173 23.0169 14.6222 22.04C13.9778 21.1344 13.3143 20.3601 12.7285 19.6777C11.1863 17.8822 10.1667 16.6947 10.1667 14.25C10.1667 9.88477 13.7181 6.33335 18.0833 6.33335C19.1555 6.33335 20.1785 6.54759 21.1119 6.9355C21.2244 5.83211 21.6631 4.82523 22.3299 4.01281Z"
            fill="#0050C4"
        />
        <path
            d="M22.8333 34.8334V31.6667H13.3333V34.8334H22.8333Z"
            fill="#0050C4"
        />
    </svg>
);

export default LightBulbAlert;
