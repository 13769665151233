import React, { Component } from "react";
import "./styles.css";
import { Form } from "react-bootstrap";
import { MODALITY_MENU_ITEMS } from "../constants";
import PropTypes from "prop-types";
import AHButton from "../../__shared__/StyleGuide/AHButton";
import AHDropdown from "../../__shared__/StyleGuide/AHDropdown";
import AHDropdownItem from "../../__shared__/StyleGuide/AHDropdownItem";

export default class AblationVolumesListing extends Component {
    render() {
        return (
            <div
                className={
                    "custom-tool-profile-workflow-sidebar hide-scrollbar"
                }
            >
                <div className={"workflow-title-cntr"}>
                    <div className={"workflow-title display-30"}>
                        Create a Custom Tool Profile
                    </div>
                </div>
                <div className={"ablation-volume-list-cntr"}>
                    <div
                        className={"ablation-volume-list darkmode-item-listing"}
                    >
                        {this.props.volumeList}
                    </div>
                </div>
                <div className={"ablation-profile-details-cntr display-16"}>
                    <div className={"details-label display-18"}>
                        Enter profile details
                    </div>
                    <div className={"profile-details-item"}>
                        <div className={"profile-details-item-label"}>
                            Tool Modality:
                        </div>
                        <div className={"modality-dropdown-cntr"}>
                            <AHDropdown
                                title={
                                    this.props.selectedModality
                                        ? this.props.selectedModality
                                        : "Select Tool Modality..."
                                }
                                id="modality-dropdown-menu"
                                isSecondary={true}
                                onSelect={this.props.handleModalitySelect}
                            >
                                <AHDropdownItem
                                    eventKey={MODALITY_MENU_ITEMS.LASER}
                                    id="modality-laser"
                                >
                                    {MODALITY_MENU_ITEMS.LASER}
                                </AHDropdownItem>
                                <AHDropdownItem
                                    eventKey={MODALITY_MENU_ITEMS.CRYO}
                                    id="modality-cryo"
                                >
                                    {MODALITY_MENU_ITEMS.CRYO}
                                </AHDropdownItem>
                                <AHDropdownItem
                                    eventKey={MODALITY_MENU_ITEMS.HIFU}
                                    id="modality-hifu"
                                >
                                    {MODALITY_MENU_ITEMS.HIFU}
                                </AHDropdownItem>
                                <AHDropdownItem
                                    eventKey={MODALITY_MENU_ITEMS.OTHER}
                                    id="modality-other"
                                >
                                    {MODALITY_MENU_ITEMS.OTHER}
                                </AHDropdownItem>
                            </AHDropdown>
                        </div>
                    </div>
                    <div className={"profile-details-item"}>
                        <div className={"profile-details-item-label"}>
                            Tool Profile Name:
                        </div>
                        <Form className={"ablation-volume-name-form"}>
                            <Form.Control
                                type={"text"}
                                className={
                                    "ablation-volume-name h-100 w-100 d-block"
                                }
                                size={"lg"}
                                value={this.props.profileName}
                                onChange={(event) => {
                                    this.props.handleProfileNameChange(event);
                                }}
                                placeholder={"Enter Tool Profile Name"}
                            />
                        </Form>
                    </div>
                    <div
                        className={"profile-details-item"}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <AHButton
                            id={"save-ablation-profile-btn"}
                            className={`save-profile-btn ${
                                this.props.allowSave() ? "enabled" : "disabled"
                            }`}
                            isDisabled={!this.props.allowSave()}
                            size={"large"}
                            onClick={this.props.saveAblationProfile}
                            text={"Save Tool Profile and Return"}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

AblationVolumesListing.propTypes = {
    volumeList: PropTypes.array,
    selectedModality: PropTypes.func,
    handleModalitySelect: PropTypes.func,
    allowSave: PropTypes.func,
    handleProfileNameChange: PropTypes.func,
    saveAblationProfile: PropTypes.func,
    profileName: PropTypes.string,
};

AblationVolumesListing.defaultProps = {
    volumeList: [],
    modality: null,
};
