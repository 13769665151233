import {
    createMargin,
    updateTreatmentPlan,
} from "../../../helpers/backend_api";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import { clientErrorMessages, refFrames, SUCCESS } from "../../../constants";
import { rootStore } from "../../../redux/store";
import { setErrorState } from "../../../redux/error_banner/actions";

export function getNewLesionContour(scope, userSelectedCPMThreshold) {
    let plan = scope.props.currentTreatmentPlan;

    if (scope.state.updatingMargin) {
        return;
    }

    scope.setState({ updatingMargin: true });

    createMargin(_getCreateMarginInput(userSelectedCPMThreshold, plan)).then(
        (r) => {
            if (r && r.message === SUCCESS) {
                _packPlanWithPayload(scope, plan, r.payload);
                _updatePlan(scope, plan);
            } else {
                rootStore.dispatch(
                    setErrorState(
                        true,
                        clientErrorMessages.MARGIN_CREATION_FAILED
                    )
                );
            }

            scope.setState({ updatingMargin: false });

            if (scope.props.onMarginCreation) {
                scope.props.onMarginCreation();
            }
        }
    );
}

function _getCreateMarginInput(userSelectedCPMThreshold, plan) {
    return {
        prostateURI: TreatmentPlan.getStructureInFrame(
            TreatmentPlan.getCoreStructure(plan.TreatmentPlan),
            refFrames.MRI
        ).URI,
        cpmURI: plan.SavedData.CPMURI,
        customCPMThreshold:
            userSelectedCPMThreshold != null
                ? userSelectedCPMThreshold
                : plan.SavedData.OptimalCPMThreshold,
        outputFormat: ".vtk",
    };
}

function _packPlanWithPayload(scope, plan, payload) {
    plan.TreatmentPlan.StructureData.Margins[0].VolumeCC = payload.marginVolume;
    plan.TreatmentPlan.StructureData.CoreStructure.VolumeCC =
        payload.prostateVolume;
    plan.SavedData.UserSelectedCPMThreshold = payload.optimalCPMThreshold;
    plan.SavedData.UserSelectedMarksScore = payload.marksScore;
    plan.SavedData.UserSelectedLesionCoverageOfProstate =
        payload.prostateCoverage;
    plan.SavedData.IsPatientFTEligible = payload.focalTherapyEligible;

    scope.setState({
        userSelectedProstateCoverage: payload.prostateCoverage,
        userSelectedMarksScore: payload.marksScore,
        userSelectedLesionVolume: payload.marginVolume,
    });
}

function _updatePlan(scope, plan) {
    updateTreatmentPlan({
        useruuid: scope.props.useruuid,
        patientuuid: scope.props.patientuuid,
        planuuid: scope.props.planuuid,
        token: scope.props.authToken,
        plan: plan,
    })
        .then((payload) => payload.json())
        .then((json) => {
            scope.props.setCurrentTreatmentPlan(json.payload.plan);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
            );
        });
}
