import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";

export default class AHCard extends Component {
    render() {
        const { totalSteps, currStep } = this.props;

        let html = [];

        for (let i = 0; i < totalSteps; i++) {
            let markCurr = "";

            if (i === currStep - 1 || i < currStep - 1) {
                markCurr = "ah-bubble-marker-active";
            }

            html.push([i, markCurr]);
        }

        return (
            <div className={"ah-bubble-container"}>
                <div className={"ah-bubble-aligner"}>
                    {html.map((bubble) => (
                        <div
                            key={bubble}
                            className={`ah-bubble-marker ${bubble[1]}`}
                        ></div>
                    ))}
                </div>
            </div>
        );
    }
}

AHCard.propTypes = {
    totalSteps: PropTypes.number,
    currStep: PropTypes.number,
};
