import { vsprintf } from "sprintf-js";
import {
    API_ENDPOINT,
    TASKS_API_ENDPOINT,
    API_VERSION,
    httpMethods,
} from "../../constants";
import { rootStore } from "../../redux/store";
import { setErrorState } from "../../redux/error_banner/actions";

export async function updateBxCore(input) {
    return await fetch(
        vsprintf("%s/api/%s/patient/%s/plan/%s/bx", [
            API_ENDPOINT,
            API_VERSION,
            input.patientuuid,
            input.planuuid,
        ]),
        {
            method: httpMethods.PUT,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                coreIdx: input.coreIdx,
                bx: input.bx,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function anatomicalPosition(input) {
    return await fetch(
        vsprintf("%s/api/%s/proc/roi/anatomicalPosition", [
            TASKS_API_ENDPOINT,
            API_VERSION,
        ]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                s3Bucket: "",
                prostateURI: input.prostateURI,
                rois: input.rois,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch(() => null);
}

export async function standardRegionOverlap(input) {
    return await fetch(
        vsprintf("%s/api/%s/proc/roi/standardRegionOverlap", [
            TASKS_API_ENDPOINT,
            API_VERSION,
        ]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                s3Bucket: "",
                prostateURI: input.prostateURI,
                rois: input.rois,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch(() => null);
}

export async function standardBiopsyRegion(input) {
    return await fetch(
        vsprintf("%s/api/%s/proc/bx/standardBiopsyRegion", [
            TASKS_API_ENDPOINT,
            API_VERSION,
        ]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                s3Bucket: "",
                prostateURI: input.prostateURI,
                biopsyCores: input.biopsyCores,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch(() => null);
}

export async function intersectROIs(input) {
    return await fetch(
        vsprintf("%s/api/%s/proc/bx/intersectROIs", [
            TASKS_API_ENDPOINT,
            API_VERSION,
        ]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                s3Bucket: "",
                rois: input.rois,
                biopsyCores: input.biopsyCores,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch(() => null);
}

export function zeroOutCore(plan, idx) {
    let bx = plan.TreatmentPlan.StructureData.Biopsy[parseInt(idx)];
    bx.GleasonGradeGroup = 0;
    bx.PrimaryScore = 1;
    bx.SecondaryScore = 1;
    bx.TissueLength = 0;
    bx.CancerLength = 0;
    bx.PercentCancer = 0;
    plan.TreatmentPlan.StructureData.Biopsy[parseInt(idx)] = bx;
    return plan;
}

export function getMaxGleasonGradeGroupFromCores(bxCores) {
    let maxGgg = 0;
    bxCores.forEach((core) => {
        if (core.GleasonGradeGroup > maxGgg) {
            maxGgg = core.GleasonGradeGroup;
        }
    });
    return maxGgg;
}

export function getValidCEMCores(bxCores) {
    let validBx = [];
    bxCores.forEach((core) => {
        if (core.GleasonGradeGroup >= 2 && !core.IsMuted) {
            validBx.push(core);
        }
    });
    return validBx.length > 0 ? true : false;
}

export const mouseEventIsWithinCanvas = (event, canvas) => {
    return (
        event.clientX >= canvas.left &&
        event.clientX <= canvas.right &&
        event.clientY >= canvas.top &&
        event.clientY <= canvas.bottom
    );
};
