import React, { Component } from "react";
import "./styles.css";
import { EMPTY_STRING, sceneOrientations } from "../../../constants";
import axialBox from "../../../assets/axial_box.svg";
import coronalBox from "../../../assets/coronal_box.svg";
import sagittalBox from "../../../assets/sagittal_box.svg";
import PropTypes from "prop-types";
import MRIModel from "../Viewers/Structures/MRI/MRIModel";
import NavCubeButton from "./NavCubeButton";

class NavCubeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentOrientation: sceneOrientations.AXIAL,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(sceneOrientation) {
        this.props.mriModel.orientation = sceneOrientation;
        this.setState({ currentOrientation: sceneOrientation });
        this.props.resetTick();
    }

    getImg(sceneOrientation) {
        switch (sceneOrientation) {
            case sceneOrientations.AXIAL:
                return axialBox;
            case sceneOrientations.SAGITTAL:
                return sagittalBox;
            case sceneOrientations.CORONAL:
                return coronalBox;
            default:
                return EMPTY_STRING;
        }
    }

    getNavCubeButton(sceneOrientation) {
        return (
            <NavCubeButton
                isCurrentOrientation={
                    this.state.currentOrientation === sceneOrientation
                }
                orientation={sceneOrientation}
                handleClick={this.handleClick}
                img={this.getImg(sceneOrientation)}
            />
        );
    }

    render() {
        let display = this.props.active ? "active" : "inactive";

        return (
            <div
                className={`nav-cube-modal ${display}`}
                style={this.props.style}
            >
                {this.getNavCubeButton(sceneOrientations.AXIAL)}
                {this.getNavCubeButton(sceneOrientations.CORONAL)}
                {this.getNavCubeButton(sceneOrientations.SAGITTAL)}
            </div>
        );
    }
}

NavCubeModal.propTypes = {
    mriModel: PropTypes.instanceOf(MRIModel),
    active: PropTypes.bool,
    dark: PropTypes.bool,
    style: PropTypes.object,
    resetTick: PropTypes.func,
};

NavCubeModal.defaultProps = {
    active: false,
    dark: true,
};

export default NavCubeModal;
