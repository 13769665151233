export const EMPTY_STRING = "";

export const fields = {
    CURRENT_PASSWORD: "Current password",
    NEW_PASSWORD: "New password",
    PASSWORD_CONFIRMATION: "Password confirmation",
    PASSWORD_SUBMIT: "Change Password",
    ENTER_PASSWORD: "Enter password",
};

export const messages = {
    SUCCESS: "Password validated.",
    EMPTY_CURRENT_PASSWORD: "Current password must be provided.",
    SAME_PASSWORD: "New password must be different from current password.",
};

export const buttonSize = {
    MEDIUM: "medium",
    LARGE: "large",
};

export const viewType = {
    TABLE_VIEW: "TableView",
    MINI_VIEW: "MiniView",
};

export const submitButtonText = {
    SUBMIT: "Submit",
    SIGN_UP: "Sign Up",
};
