import * as THREE from "three";
import {
    getAdjacencyListFromMesh,
    getAverageDistanceBetweenQueryPointAndPointCloud,
    getIntersectionsBetweenPlaneAndMesh,
    getPointProjectedOntoPlane,
    getRaycasterIntersectionWithPlane,
} from "../../helpers";
import StructureModel from "../PatientAnatomy/StructureModel";
import ProstateAxes from "../Other/ProstateAxes";
import { structureColorPalette, volumeNames } from "../../../../../constants";
import pencil from "../../../../../assets/edit_pencil_cursor.svg";
import { laplacianFilter, laplacianHCFilter } from "./LaplacianFilter";
import { RAS2LPS } from "../../helpers";

export default class MeshEditController {
    constructor(mriModel) {
        this._mriModel = mriModel;
    }

    get linkedStructure() {
        return this._linkedStructure;
    }

    set linkedStructure(structure) {
        if (!structure) {
            return;
        }

        this._linkedStructure = structure;
    }

    get editedStructure() {
        return this._editStructure;
    }

    get faceVertexRepresentation() {
        return {
            vtxPts: this._editStructure.geometry.attributes.array,
            triIdx: this._editStructure.geometry.index.array,
        };
    }

    get editingActive() {
        return !(this._editStructure == null);
    }

    cancelEditing() {
        if (this._editStructure) {
            this._editStructure.delete();
            this._editStructure = null;
        }

        if (this._linkedStructure) {
            this._linkedStructure.mriViewer.mount.style.cursor = "default";
        }
    }

    initializeEditing() {
        if (!this._linkedStructure) {
            return;
        }

        let clonedGeometry = this._linkedStructure.geometry.clone();

        this._editStructure = new StructureModel();
        this._editStructure.mriViewer = this._linkedStructure.mriViewer;
        this._editStructure.meshViewer = this._linkedStructure.meshViewer;
        this._editStructure.initializeViewFromGeometry(clonedGeometry, {
            color: structureColorPalette.EDITING_MESH,
            opacity: 0.3,
            name: `${this._linkedStructure.name}${volumeNames.EDITING}`,
        });
        let prostateAxes = new ProstateAxes();
        this._editStructure.initProstateAxes(prostateAxes);
        this._editStructure.visibility = true;
        this._linkedStructure.visibility = false;
        this._linkedStructure.mriViewer.mount.style.cursor = `url(${pencil}),auto`;
    }

    updateStructureMeshFromRaycaster(raycaster) {
        let intersectRAS = getRaycasterIntersectionWithPlane(
            raycaster,
            this._mriModel.clipPlane
        );
        let intersectLPS = intersectRAS.clone().applyMatrix4(RAS2LPS);

        const mathPlane = this._mriModel.clipPlane;
        let faces = this._editStructure.faces;
        let vertices = this._editStructure.geometry.attributes.position.array;

        let pointsOfIntersection = getIntersectionsBetweenPlaneAndMesh(
            mathPlane,
            faces,
            vertices
        );

        const center = this._editStructure.center;
        const projectedPoint = getPointProjectedOntoPlane(center, mathPlane);

        let averageDistance = getAverageDistanceBetweenQueryPointAndPointCloud(
            pointsOfIntersection,
            projectedPoint
        );

        let maxUpdateDistance = 15; // default value [mm]

        if (averageDistance) {
            maxUpdateDistance = averageDistance * 0.7;
        }

        if (intersectLPS) {
            let projectedMeshCentroid = getPointProjectedOntoPlane(
                this._editStructure.center,
                this._mriModel.clipPlane
            );
            let updateDirectionVector = intersectLPS.clone();
            updateDirectionVector.sub(projectedMeshCentroid);
            updateDirectionVector.normalize();

            let updateDirectionVectorRAS = updateDirectionVector
                .clone()
                .applyMatrix4(RAS2LPS);

            // Raycaster operates after object transforms, therefore we must intersect in RAS
            raycaster.set(intersectRAS, updateDirectionVectorRAS);
            const intersectList = raycaster.intersectObject(
                this._editStructure._selfMeshView.children[0]
            );

            const neighbors = getAdjacencyListFromMesh(faces);

            const vertexIndicesToSmooth = [];

            for (let i = 0; i < vertices.length; i += 3) {
                let vertex = new THREE.Vector3(
                    vertices[i + 0],
                    vertices[i + 1],
                    vertices[i + 2]
                );
                let distance = vertex.distanceTo(intersectLPS);

                const smoothingRangeFactor = 1.5;

                if (distance > maxUpdateDistance * smoothingRangeFactor) {
                    continue;
                }
                vertexIndicesToSmooth.push(i);
                if (distance > maxUpdateDistance) {
                    continue;
                }

                let movingVertex = vertex.clone();

                let vertexUpdateDirectionVector = vertex.clone();
                vertexUpdateDirectionVector.sub(this._editStructure.center);
                vertexUpdateDirectionVector.normalize();

                if (intersectList.length % 2 !== 1) {
                    vertexUpdateDirectionVector.multiplyScalar(-1);
                }

                let af = _getAmplificationFactor(distance);

                let update = vertexUpdateDirectionVector.clone();
                update.multiplyScalar(af);

                movingVertex.add(update);

                vertices[i + 0] = movingVertex.x;
                vertices[i + 1] = movingVertex.y;
                vertices[i + 2] = movingVertex.z;
            }

            const iterations = 3;
            const originalVertices = Array.from(
                this._editStructure.geometry.attributes.position.array
            );
            Object.freeze(originalVertices);
            for (let i = 0; i < iterations; i++) {
                const prevVertices = Array.from(vertices);
                Object.freeze(prevVertices);

                laplacianFilter(
                    vertices,
                    prevVertices,
                    vertexIndicesToSmooth,
                    neighbors
                );
                laplacianHCFilter(
                    originalVertices,
                    vertices,
                    prevVertices,
                    vertexIndicesToSmooth,
                    neighbors
                );
            }

            this._editStructure.geometry.attributes.position.needsUpdate = true;
        }
    }
}

const e = (x) => Math.exp(x);

function _getAmplificationFactor(x) {
    const alpha = -0.8;
    const beta = 0.1;
    const x0 = -4;
    return alpha * e(-1 * beta * (x + x0));
}
