import React, { Component } from "react";
import "./styles.css";
import logo_gif from "../../../../assets/avenda_logo_gray_loading.gif";
import {
    createAISegmentation,
    getPHISignedURLS3,
    updateTreatmentPlan,
} from "../../../../helpers/backend_api";
import * as TreatmentPlan from "../../../../helpers/tplan/tplan";
import {
    clientErrorMessages,
    structureColorPalette,
    volumeNames,
} from "../../../../constants";
import { rootStore } from "../../../../redux/store";
import { setErrorState } from "../../../../redux/error_banner/actions";
import { segmentationDisplayOptions } from "../../constants";
import { connect } from "react-redux";
import { setCurrentTreatmentPlan } from "../../../CreateTPlan_1Upload/actions";
import StructureModel from "../../../__shared__/Viewers/Structures/PatientAnatomy/StructureModel";
import ProstateAxes from "../../../__shared__/Viewers/Structures/Other/ProstateAxes";

import PropTypes from "prop-types";
import { getSignedUrl, toArray } from "../../../../helpers/helpers";

class AISegmentationLoadingPanel extends Component {
    componentDidMount() {
        let scope = this;

        let ids = {
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            planuuid: scope.props.planuuid,
            visituuid: scope.props.visituuid,
            token: scope.props.authToken,
        };

        let plan = scope.props.currentTreatmentPlan;
        let data3Dfolder = TreatmentPlan.get3DDataFolder(
            plan,
            this.props.patientuuid,
            this.props.visituuid
        );
        createAISegmentation({
            outputURI: data3Dfolder,
            mriURIs: TreatmentPlan.getMRIURIs(plan.TreatmentPlan),
        }).then((result) => {
            if (result && result.uri) {
                _updateTreatmentPlanWithSegmentationURIs(
                    scope,
                    plan,
                    result.uri,
                    ids
                );
                _downloadAndCreateAISegmentationModel(scope, result.uri, ids);
            } else {
                rootStore.dispatch(
                    setErrorState(true, clientErrorMessages.AI_SEG_FAILED)
                );
            }
        });
    }

    render() {
        return (
            <div id={"ai-segmentation-loading-panel"}>
                <div className={"segmentation-listing-header"}>
                    <p className={"display-20"}>
                        Creating Segmentation. Please wait...
                    </p>
                </div>
                <div className={"ai-segmentation-loading-animation"}>
                    <img src={logo_gif} alt={""} />
                </div>
            </div>
        );
    }
}

AISegmentationLoadingPanel.propTypes = {
    setWorkflowState: PropTypes.func,
    visituuid: PropTypes.string,
    patientuuid: PropTypes.string,
};

AISegmentationLoadingPanel.defaultProps = {
    setWorkflowState: () => {},
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        setErrorState: (hasError, errorText) =>
            dispatch(setErrorState(hasError, errorText)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AISegmentationLoadingPanel);

function _updateTreatmentPlanWithSegmentationURIs(scope, plan, uri, ids) {
    plan.SavedData.AIProstateURI = uri;

    updateTreatmentPlan({
        ...ids,
        plan: plan,
    })
        .then((payload) => payload.json())
        .then((json) => {
            scope.props.setCurrentTreatmentPlan(json.payload.plan);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
            );
            scope.props.setWorkflowState(
                segmentationDisplayOptions.SEG_LISTING
            );
        });
}

function _downloadAndCreateAISegmentationModel(scope, uri, ids) {
    getPHISignedURLS3({
        ...ids,
        uris: toArray(uri),
    })
        .then((payload) => payload.json())
        .then((json) => {
            let url = getSignedUrl(json.payload.signedurls);
            let aiProstateStructure = new StructureModel();

            aiProstateStructure.mriViewer =
                scope.props.patientAnatomyModel.mriViewer;
            aiProstateStructure.meshViewer =
                scope.props.patientAnatomyModel.meshViewer;

            aiProstateStructure
                .initializeView(url, {
                    name: volumeNames.MR_PROSTATE_AI,
                    color: structureColorPalette.AI_PROSTATE,
                    opacity: 0.3,
                })
                .then(() => {
                    if (scope.props.patientAnatomyModel.prostateUploaded) {
                        let uploadedProstate =
                            scope.props.patientAnatomyModel.prostateUploaded;
                        uploadedProstate.visibility = false;
                    }

                    aiProstateStructure.visibility = true;
                    scope.props.patientAnatomyModel.prostateAI =
                        aiProstateStructure;
                    scope.props.setWorkflowState(
                        segmentationDisplayOptions.SEG_LISTING
                    );

                    let prostateAxes = new ProstateAxes();
                    aiProstateStructure.initProstateAxes(prostateAxes);
                });
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.MESH_DOWNLOAD_FAILED)
            );
            scope.props.setWorkflowState(
                segmentationDisplayOptions.SEG_LISTING
            );
        });
}
