import * as THREE from "three";
import { mouseEventIsWithinCanvas } from "../../../CreateTPlan_3Biopsy/helpers";
import { mouseEventName } from "./consts";

export function onWindowResize() {
    if (this.mount) {
        this.camera.aspect = this.mount.clientWidth / this.mount.clientHeight;
        this.camera.updateProjectionMatrix();

        this.setComposerSize();
        this.renderer.setSize(this.mount.offsetWidth, this.mount.offsetHeight);
    }
}

export function setComposerSize() {
    if (this.composer) {
        const pixelRatio = this.renderer.getPixelRatio();
        this.effectFXAA.uniforms["resolution"].value.x =
            1 / (this.mount.clientWidth * pixelRatio);
        this.effectFXAA.uniforms["resolution"].value.y =
            1 / (this.mount.clientHeight * pixelRatio);

        this.composer.setSize(this.mount.offsetWidth, this.mount.offsetHeight);
    }
}

export function onMouseMove(e, mouseEvent) {
    // Prevent 3D Mesh Viewer in step 2, 4, and 5 from highlighting cores by click
    if (!this.props.activateCoreClick && mouseEvent === mouseEventName.CLICK) {
        return;
    }

    if (!this.mount) return;

    let mouse = new THREE.Vector2();
    let rect = this.mount.getBoundingClientRect();

    if (!mouseEventIsWithinCanvas(e, rect)) {
        return;
    }

    mouse.x = ((e.clientX - rect.left) / this.mount.clientWidth) * 2 - 1;
    mouse.y = -((e.clientY - rect.top) / this.mount.clientHeight) * 2 + 1;

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, this.camera);

    if (this.props.patientAnatomyController && this.outlinePass) {
        this.props.patientAnatomyController.outlineWithRaycaster(
            raycaster,
            mouseEvent
        );
    }
}

export function setRendererSize(width, height) {
    this.renderer.setSize(width, height);
}
