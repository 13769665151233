import React, { Component } from "react";
import * as viewerMethods from "./viewer";
import * as helperMethods from "./helpers";
import * as controlMethods from "./controls";
import "./styles.css";
import { connect } from "react-redux";
import { isEmpty } from "../../../../helpers/helpers";
import PropTypes from "prop-types";
import MRIModel from "../Structures/MRI/MRIModel";
import TargetSetController from "../Structures/Targets/TargetSetController";
import TargetSetModel from "../Structures/Targets/TargetSetModel";
import PatientAnatomyModel from "../Structures/PatientAnatomy/PatientAnatomyModel";

class MRIViewer extends Component {
    constructor(props) {
        super(props);

        this.initScene = viewerMethods.initScene.bind(this);
        this.animate = viewerMethods.animate.bind(this);
        this.start = viewerMethods.start.bind(this);
        this.stop = viewerMethods.stop.bind(this);
        this.renderScene = viewerMethods.renderScene.bind(this);
        this.onMouseDown = controlMethods.onMouseDown.bind(this);
        this.onMouseUp = controlMethods.onMouseUp.bind(this);
        this.onMouseMove = controlMethods.onMouseMove.bind(this);
        this.onWindowResize = controlMethods.onWindowResize.bind(this);
        this.onClick = controlMethods.onClick.bind(this);
    }

    componentDidMount() {
        this.props.mriModel.mriViewer = this;

        if (this.props.targetSetController) {
            this.props.targetSetController.mriViewer = this;
        }

        if (this.props.targetSetModel) {
            this.props.targetSetModel.mriViewer = this;
        }

        if (this.props.patientAnatomyModel) {
            this.props.patientAnatomyModel.mriViewer = this;
        }

        this.initScene();
        window.addEventListener("resize", this.onWindowResize);
        document.addEventListener("mousedown", this.onMouseDown, true);
        document.addEventListener("mouseup", this.onMouseUp, true);
        document.addEventListener("mousemove", this.onMouseMove, true);
    }

    componentWillUnmount() {
        this.stop();
        this.cleanup();
        window.removeEventListener("resize", this.onWindowResize);
        document.removeEventListener("mousedown", this.onMouseDown);
        document.removeEventListener("mouseup", this.onMouseUp);
        document.removeEventListener("mousemove", this.onMouseMove);
    }

    render() {
        return (
            <div
                id={"mri-viewer-internal"}
                className={"mri-viewer-internal"}
                onClick={this.onClick}
                style={this.props.style}
                ref={(mount) => {
                    this.mount = mount;
                }}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
            >
                {this.props.children}
            </div>
        );
    }
}

MRIViewer.propTypes = {
    mriModel: PropTypes.instanceOf(MRIModel),
    targetSetController: PropTypes.instanceOf(TargetSetController),
    targetSetModel: PropTypes.instanceOf(TargetSetModel),
    patientAnatomyModel: PropTypes.instanceOf(PatientAnatomyModel),
    viewerInput: PropTypes.object,
    displaySettings: PropTypes.object,
    style: PropTypes.object,
    children: PropTypes.node,
};

Object.assign(MRIViewer.prototype, { ...viewerMethods });
Object.assign(MRIViewer.prototype, { ...helperMethods });
Object.assign(MRIViewer.prototype, { ...controlMethods });

const mapStateToProps = function (state, ownProps) {
    let displaySettings = isEmpty(ownProps.displaySettings)
        ? state.ImageViewerReducer.displaySettings
        : ownProps.displaySettings;
    return {
        displaySettings: displaySettings,
        viewerInput: state.ImageViewerReducer.viewerInput,
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
    MRIViewer
);
