export const prostateSegOptions = {
    UPLOADED_SEG: 0,
    AI_SEG: 1,
};

export const segmentationDisplayOptions = {
    SEG_LISTING: "SEG_LISTING",
    AI_SEG_OPTIONS: "AI_SEG_OPTIONS",
    AI_SEG_LOADING: "AI_SEG_LOADING",
    SEG_EDITING: "SEG_EDITING",
};
export const step2AlertOptions = {
    NONE: "NONE",
    CONFIRM_EDITS: "CONFIRM_EDITS",
    SELECT_SEGMENTATION: "SELECT_SEGMENTATION",
};

export const prostateMeshLabels = {
    USER: "Prostate (User Upload)",
    AI: "Prostate (AI Generated)",
};

export const PROSTATE_CORE_STRUCTURE_VTK_PATH = "/volumes/MR_PROSTATE.vtk";

export const mriViewerButtonTooltip = {
    SCENE_ORIENTATION: "Scene Orientation",
    FILTER: "Filter",
};

export const RESET_VIEW = "Reset View";
