import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../../constants";

class AHTextAreaField extends Component {
    render() {
        return (
            <textarea
                className={`ah-text-area ${this.props.className}`}
                placeholder={this.props.placeholder}
                value={this.props.value}
                label={this.props.label}
                onChange={(e) => {
                    this.props.onChange(e.target.value);
                }}
            />
        );
    }
}

AHTextAreaField.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
};

AHTextAreaField.defaultProps = {
    className: EMPTY_STRING,
    id: undefined,
    label: EMPTY_STRING,
    placeholder: EMPTY_STRING,
    value: EMPTY_STRING,
    onChange: undefined,
};

export default AHTextAreaField;
