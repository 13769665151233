import "./styles.css";
import React, { Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import PropTypes from "prop-types";

export default class AHDropdownItem extends Component {
    render() {
        let isDisabled = this.props.isHeader || this.props.disabled;
        return (
            <Dropdown.Item
                bsPrefix={"avenda-custom-"}
                className={`avenda-style-guide-dropdown-item ${this.props.className}`}
                id={this.props.id}
                style={this.props.style}
                eventKey={this.props.eventKey}
                disabled={isDisabled}
                onClick={this.props.onClick}
                active={this.props.active}
            >
                {this.props.children}
            </Dropdown.Item>
        );
    }
}

AHDropdownItem.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    isHeader: PropTypes.bool,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.node,
    eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
