import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { EMPTY_STRING } from "../../../constants";
import { GLEASON_GRADE } from "../constants";

export default class BiopsyButtonModal extends Component {
    render() {
        let display = this.props.active ? "active" : "inactive";

        let gleasonGradeGroup =
            Object.values(GLEASON_GRADE)[this.props.gleasonGradeGroup];

        return (
            <div
                id={this.props.id}
                className={`biopsy-button-modal ${display}`}
                style={this.props.style}
            >
                <p className={"display-14"} style={{ color: "#000000" }}>
                    {this.props.label} | {gleasonGradeGroup}
                </p>
            </div>
        );
    }
}

BiopsyButtonModal.propTypes = {
    id: PropTypes.string,
    gleasonGradeGroup: PropTypes.number,
    style: PropTypes.object,
    label: PropTypes.string,
    active: PropTypes.bool,
};

BiopsyButtonModal.defaultProps = {
    id: EMPTY_STRING,
};
