import React, { Component } from "react";
import TopBar from "../__shared__/TopBar";
import ProfileMenu from "../__shared__/ProfileMenu";
import StepMarkerBar from "../__shared__/StepMarkerBar";
import AvendaLogo from "../__shared__/AvendaLogo";
import "./styles.css";
import PreviousStepButton from "../__shared__/PreviousStepButton";
import NextStepButton from "../__shared__/NextStepButton";

import { freehandStepMetaData, stepNumber, stepPath } from "../../constants";

import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
    setFreehandPlan,
    setPlanCreationStepMetaData,
} from "../CreateTPlan_1Upload/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AHButton from "../__shared__/StyleGuide/AHButton";

class CreateTPlan_1_1_SelectPlanType extends Component {
    constructor(props) {
        super(props);

        this.handleNoClick = this.handleNoClick.bind(this);
        this.handleYesClick = this.handleYesClick.bind(this);
    }

    handleNoClick() {
        this.props.setFreehandPlan(true);
        this.props.setPlanCreationStepMetaData(
            freehandStepMetaData[stepNumber.UPLOAD]
        );
    }
    handleYesClick() {
        this.props.setFreehandPlan(false);
    }
    render() {
        return (
            <div>
                <div className={"create-plan-upload-screen"}>
                    <TopBar>
                        <AvendaLogo
                            id={"create-plan-avenda-logo"}
                            to={stepPath.HOME}
                        />
                        <div className={"create-plan-step-bar-container"}>
                            <PreviousStepButton to={stepPath.HOME} />
                            <StepMarkerBar />
                            <NextStepButton style={{ visibility: "hidden" }} />
                        </div>
                        <ProfileMenu />
                    </TopBar>
                    <div className={"select-plan-body"}>
                        <div className={"select-plan-body-text display-20"}>
                            Upload Patient Data:
                        </div>
                        <div className={"select-plan-body-contents"}>
                            <div
                                className={
                                    "select-plan-body-content display-16 soft-black-text"
                                }
                            >
                                <p>Features with MRI Only:</p>
                                <ListGroup
                                    className={"select-plan-feature-list"}
                                    variant={"flush"}
                                >
                                    <ListGroup.Item>
                                        <div className="upload-plan-bullet-point-txt">
                                            <div className="upload-plan-bullet-point" />
                                            Create AI prostate Segmentation
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="upload-plan-bullet-point-txt">
                                            <div className="upload-plan-bullet-point" />
                                            View / Edit prostate segmentations
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                                <div className={"select-plan-body-btn"}>
                                    <Link to={stepPath.STEP1}>
                                        <AHButton
                                            id="upload-plan-select-mri-btn"
                                            text={"Only MRI data available"}
                                            isSecondary={true}
                                            isOutline={true}
                                            size={"large"}
                                            onClick={this.handleNoClick}
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className={"selection-divider-line"} />
                            <div
                                className={
                                    "select-plan-body-content display-16 soft-black-text"
                                }
                            >
                                <p>Features with Tracked Biopsy Data:</p>
                                <ListGroup
                                    className={"select-plan-feature-list"}
                                    variant={"flush"}
                                >
                                    <ListGroup.Item>
                                        <div className="upload-plan-bullet-point-txt">
                                            <div className="upload-plan-bullet-point" />
                                            Create AI prostate Segmentation
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="upload-plan-bullet-point-txt">
                                            <div className="upload-plan-bullet-point" />
                                            View / Edit prostate segmentations
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="upload-plan-bullet-point-txt">
                                            <div className="upload-plan-bullet-point" />
                                            Pathology Data visualization
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="upload-plan-bullet-point-txt">
                                            <div className="upload-plan-bullet-point" />
                                            Create Contour for lesion treatment
                                        </div>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className="upload-plan-bullet-point-txt">
                                            <div className="upload-plan-bullet-point" />
                                            Place simulated tool sites
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                                <div className={"select-plan-body-btn"}>
                                    <Link
                                        to={stepPath.STEP1}
                                        className="upload-plan-add-patient-link"
                                    >
                                        <AHButton
                                            id="upload-plan-select-biopsy-btn"
                                            onClick={this.handleYesClick}
                                            isOutline={false}
                                            isSecondary={false}
                                            size={"large"}
                                            text={"Tracked biopsy available"}
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CreateTPlan_1_1_SelectPlanType.propTypes = {
    setFreehandPlan: PropTypes.func,
    setPlanCreationStepMetaData: PropTypes.func,
};

const mapStateToProps = function (state) {
    return {
        freehandPlan: state.CreatePlanReducer.freehandPlan,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setFreehandPlan: (isOn) => dispatch(setFreehandPlan(isOn)),
        setPlanCreationStepMetaData: (stepMetaData) =>
            dispatch(setPlanCreationStepMetaData(stepMetaData)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTPlan_1_1_SelectPlanType);
