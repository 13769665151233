import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/Unfold_AI_Logo.svg";
import "./styles.css";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";

export default class Index extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let div;

        if (this.props.to) {
            div = (
                <Link to={this.props.to} style={this.props.style}>
                    <img src={logo} alt={"Avenda Logo"} />
                </Link>
            );
        } else {
            div = (
                <img
                    src={logo}
                    alt={"Avenda Logo"}
                    className="login-unfold-logo"
                />
            );
        }

        return (
            <div
                id={this.props.id}
                className={"avenda-logo"}
                style={this.props.style}
            >
                {div}
            </div>
        );
    }
}

Index.propTypes = {
    to: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object,
};

Index.defaultProps = {
    to: EMPTY_STRING,
};
