import { VOLUME_SHAPES } from "../../../../CreateTPlan_4EditMargin/constants";
import CustomTargetModel from "./CustomTargetModel";

export default class CustomTargetSetModel {
    constructor() {
        this._laserCathetersVisibile = true;
        this._currentCustomTarget = null;
    }

    get currentCustomTarget() {
        return this._currentCustomTarget;
    }

    set currentCustomTarget(target) {
        this._currentCustomTarget = target;
    }

    get meshViewer() {
        return this._meshViewer;
    }

    set meshViewer(meshViewer) {
        this._meshViewer = meshViewer;
    }

    get laserCatheterVisibility() {
        return this._laserCathetersVisibile;
    }

    set laserCatheterVisibility(visibility) {
        this._laserCathetersVisibile = visibility;

        this._currentCustomTarget.laserCatheterVisibility = visibility;
    }

    setPrimitiveVolume(volumeName) {
        let primitiveShape = new CustomTargetModel(volumeName);

        if (this._currentCustomTarget) {
            this._currentCustomTarget.delete();
            this._currentCustomTarget = null;
        }

        primitiveShape.name = volumeName;
        primitiveShape.meshViewer = this._meshViewer;
        primitiveShape.initializeView();
        this._currentCustomTarget = primitiveShape;
    }

    updateVolumeDimensions(x, y, z, offset) {
        if (this._currentCustomTarget) {
            this._currentCustomTarget.xDim = x;
            this._currentCustomTarget.yDim = y;
            this._currentCustomTarget.zDim = z;
            this._currentCustomTarget.laserCatheterOffset = offset;

            switch (this._currentCustomTarget.volumeShape) {
                case VOLUME_SHAPES.ELLIPSE:
                    this._currentCustomTarget.scaleCustomTargetMesh();
                    break;

                default:
                    this._currentCustomTarget.delete();
                    this._currentCustomTarget.initializeView();
                    break;
            }

            this._currentCustomTarget.laserCatheterVisibility =
                this._laserCathetersVisibile;
        }
    }
}
