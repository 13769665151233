import React from "react";
import {
    EMPTY_STRING,
    pageLoadStates,
    TOTAL_DEFAULT_STEPS,
    TOTAL_FREEHAND_STEPS,
} from "../constants";
import LoadingScreen from "../components/__shared__/LoadingScreen";
import FailureScreen from "../components/__shared__/FailureScreen";
import { FREEHAND_SUMMARY_STEP } from "../components/CreateTPlan_1Upload/constants";

export const interleave = (arr, arr2) =>
    arr.reduce((combArr, el, i) => combArr.concat(el, arr2[parseInt(i)]), []);

export function isEmpty(obj) {
    if (obj == null) {
        return true;
    }

    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

export function hasProperty(obj, key) {
    if (!obj) {
        return false;
    }

    let keyArgs = key.split(".");
    for (let i = 0; i < keyArgs.length; i++) {
        if (
            obj == null ||
            !Object.prototype.hasOwnProperty.call(obj, keyArgs[parseInt(i)])
        ) {
            return false;
        }
        obj = obj[keyArgs[parseInt(i)]];
    }
    return true;
}

export function toQueryString(args) {
    if (args.length > 0) {
        return "?" + args.join("&");
    }

    return EMPTY_STRING;
}

export function traverseDirectory(entry, fileList) {
    const reader = entry.createReader();

    return new Promise((resolve, reject) => {
        const iterationAttempts = [];

        function readEntries() {
            reader.readEntries(
                (entries) => {
                    if (!entries.length) {
                        resolve(Promise.all(iterationAttempts));
                    } else {
                        iterationAttempts.push(
                            Promise.all(
                                entries.map((ientry) => {
                                    if (ientry.isFile) {
                                        fileList.push(ientry);
                                        return ientry;
                                    } else {
                                        return traverseDirectory(
                                            ientry,
                                            fileList
                                        );
                                    }
                                })
                            )
                        );

                        readEntries();
                    }
                },
                (error) => reject(error)
            );
        }

        readEntries();
    });
}

export function getLoadingScreen(state, mode) {
    let overlay;

    switch (state) {
        case pageLoadStates.LOADING: {
            overlay = <LoadingScreen mode={mode} />;
            break;
        }
        case pageLoadStates.FAILED: {
            overlay = <FailureScreen />;
            break;
        }
        case pageLoadStates.COMPLETE: {
            overlay = null;
            break;
        }
        default:
            break;
    }

    return overlay;
}

export function authPlanCompleteFunc(passedProps) {
    return !passedProps.flags.planCompleted;
}

export function authTokenFunc(passedProps) {
    return passedProps.flags.isAuthenticated;
}

export function getFreehandWorkflowAdjustedStep(step) {
    if (step >= TOTAL_DEFAULT_STEPS - TOTAL_FREEHAND_STEPS) {
        return FREEHAND_SUMMARY_STEP;
    }
    return step;
}

export const getSignedUrl = (urls) => (urls.length ? urls[0] : EMPTY_STRING);

export const toArray = (items) => {
    if (items.constructor === Array) {
        return items;
    } else {
        return [items];
    }
};

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
