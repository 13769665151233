import React from "react";
import PropTypes from "prop-types";

const WarningTriangle = ({ width = "35", height = "31", className }) => {
    return (
        <svg
            className={className}
            width={width}
            height={height}
            viewBox="0 0 35 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8991 0.999999C18.1293 -0.333334 16.2048 -0.333333 15.435 1L0.270933 27.265C-0.498868 28.5983 0.463384 30.265 2.00298 30.265H32.3312C33.8708 30.265 34.833 28.5983 34.0632 27.265L18.8991 0.999999ZM18.6696 19.7175V10.7017H15.6644V19.7175H18.6696ZM18.6696 25.7281V22.7228H15.6644V25.7281H18.6696Z"
                fill="#DFAC18"
            />
        </svg>
    );
};

WarningTriangle.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
};

export default WarningTriangle;
