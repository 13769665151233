import TargetModel from "./TargetModel";
import {
    COLOR_BLIND_SAFE_COLORS,
    AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
    EMPTY_STRING,
} from "../../../../../constants";
import { defaultAblationVolumeSize } from "./consts";

export default class TargetSetModel {
    constructor() {
        this._targets = new Map();
        this._laserCathetersVisibile = true;
    }

    get targets() {
        return this._targets;
    }

    get mriViewer() {
        return this._mriViewer;
    }

    get meshViewer() {
        return this._meshViewer;
    }

    set targets(targets) {
        this._targets = targets;
    }

    set mriViewer(mriViewer) {
        this._mriViewer = mriViewer;
    }

    set meshViewer(meshViewer) {
        this._meshViewer = meshViewer;
    }

    get laserCatheterVisibility() {
        return this._laserCathetersVisibile;
    }

    set laserCatheterVisibility(visibility) {
        this._laserCathetersVisibile = visibility;

        Array.from(this._targets.values()).forEach((target) => {
            target.laserCatheterVisibility = visibility;
        });
    }

    lookupModelWithView(view) {
        return this._targets.get(view.ID);
    }

    isModelTarget(model) {
        return model instanceof TargetModel;
    }

    addTarget(position, quaternion, color, ablationProfile) {
        let id = _getUniqueTargetId(this._targets);

        if (!color) {
            color = _getTargetColorFromPalette(id);
        }

        let newTarget = new TargetModel(
            ablationProfile,
            ablationProfile.ablationProfileUUID,
            ablationProfile.selectedVolume
        );

        newTarget.ID = id;
        newTarget.meshViewer = this._meshViewer;
        newTarget.mriViewer = this._mriViewer;
        newTarget.initializeView(position, quaternion, color);

        this._targets.set(id, newTarget);
    }

    getTargetByID(queryID) {
        return this._getTargetByID(queryID);
    }

    deleteAllTargets() {
        Array.from(this._targets.values()).forEach((target) => {
            target.delete();
        });

        this._targets = new Map();
    }

    deleteTargetByID(queryID) {
        let target = this._getTargetByID(queryID);
        target.delete();
        this._targets.delete(queryID);
    }

    getTargetsInTreatmentPlanFormat() {
        let targetFmts = [];

        Array.from(this._targets.values()).forEach((target) => {
            let targetFmt = target.getTargetInTreatmentPlanFormat();

            if (targetFmt) {
                targetFmts.push(targetFmt);
            }
        });

        return targetFmts;
    }

    setTargetsFromTreatmentPlanFormat(targets, profile) {
        if (!targets || !profile) {
            return;
        }

        let profileUUID, volumeUUID;

        targets.forEach((targetTplanFormat) => {
            profileUUID = targetTplanFormat.AblationProfileUUID
                ? targetTplanFormat.AblationProfileUUID
                : AVENDA_DEFAULT_ABLATION_PROFILE_UUID;
            volumeUUID = targetTplanFormat.AblationVolumeUUID
                ? targetTplanFormat.AblationVolumeUUID
                : defaultAblationVolumeSize.LARGE;

            profileUUID =
                profileUUID === EMPTY_STRING
                    ? AVENDA_DEFAULT_ABLATION_PROFILE_UUID
                    : profileUUID;
            volumeUUID =
                volumeUUID === EMPTY_STRING
                    ? defaultAblationVolumeSize.LARGE
                    : volumeUUID;

            let newTarget = new TargetModel(profile, profileUUID, volumeUUID);
            newTarget.ID = targetTplanFormat.ID;
            newTarget.meshViewer = this._meshViewer;
            newTarget.mriViewer = this._mriViewer;
            newTarget.setTargetFromTreatmentPlanFormat(targetTplanFormat);
            this._targets.set(targetTplanFormat.ID, newTarget);
        });
    }

    _getTargetByID(queryID) {
        let idMatch;
        this._targets.forEach((target) => {
            if (target.ID === queryID) {
                idMatch = target;
            }
        });

        return idMatch;
    }
}

function _getUniqueTargetId(targetMap) {
    let id = 0;

    let targetIds = Array.from(targetMap.keys());

    if (!targetIds || targetIds.length < 1) {
        return String(0);
    }

    targetIds.sort(function (ta, tb) {
        let idA = parseInt(ta.ID);
        let idB = parseInt(tb.ID);

        if (idA < idB) {
            return -1;
        }

        if (idA > idB) {
            return 1;
        }

        return 0;
    });

    targetIds.forEach(function (tID) {
        if (id === parseInt(tID)) {
            id++;
        }
    });

    return String(id);
}

export function _getTargetColorFromPalette(ID) {
    return COLOR_BLIND_SAFE_COLORS[ID % COLOR_BLIND_SAFE_COLORS.length];
}
