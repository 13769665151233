import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import backArrow from "../../../../assets/back_arrow.svg";
import AHButton from "../../../__shared__/StyleGuide/AHButton";

class AblationPlacementModeSelectionPanel extends Component {
    constructor(props) {
        super(props);

        this.handleAutoClick = this.handleAutoClick.bind(this);
        this.handleManualClick = this.handleManualClick.bind(this);
        this.handlePanelClick = this.handlePanelClick.bind(this);
    }

    handleAutoClick(e) {
        let scope = this;

        e.stopPropagation();

        scope.props.onAutomaticPlacementClick();
    }

    handleManualClick(e) {
        e.stopPropagation();

        this.props.onManualPlacementClick();
    }

    handlePanelClick(e) {
        e.stopPropagation();
    }

    render() {
        return (
            <div
                className={`ablation-placement-mode-selection-panel`}
                style={this.props.style}
                onClick={this.handlePanelClick}
            >
                <div className={"target-workflow-header"}>
                    <div
                        className={"manually-place-targets-back-btn"}
                        onClick={this.props.onClose}
                    >
                        <img src={backArrow} alt={"<-"} />
                    </div>
                    <p className={"display-20"}>
                        Choose Method for Tool Placement:
                    </p>
                </div>
                <div className={"placement-button-group"}>
                    <AHButton
                        id="auto-place-btn"
                        className={"tgt-shared-btn auto-tgt-btn"}
                        onClick={this.handleAutoClick}
                        isDynamic={true}
                        size={"large"}
                        text={"Automatic Placement"}
                    />
                    <AHButton
                        id="manual-place-btn"
                        className={"tgt-shared-btn man-tgt-btn"}
                        onClick={this.handleManualClick}
                        isSecondary={true}
                        isOutline={true}
                        isDynamic={true}
                        size={"large"}
                        text={"Manual Placement"}
                    />
                </div>
            </div>
        );
    }
}

AblationPlacementModeSelectionPanel.propTypes = {
    onClose: PropTypes.func,
    onAutomaticPlacementClick: PropTypes.func,
    onManualPlacementClick: PropTypes.func,
    style: PropTypes.object,
};

AblationPlacementModeSelectionPanel.defaultProps = {
    onClose: () => {},
    onAutomaticPlacementClick: () => {},
    onManualPlacementClick: () => {},
    style: {},
};

export default AblationPlacementModeSelectionPanel;
