import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";

class MRIControlBar extends Component {
    render() {
        return <div className={"mri-control-bar"}>{this.props.children}</div>;
    }
}

MRIControlBar.propTypes = {
    children: PropTypes.any,
};

export default MRIControlBar;
