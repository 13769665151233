import { keyStrings } from "../../../../../constants";

export default class MRIController {
    constructor(mriModel) {
        this._mriModel = mriModel;
    }

    shiftSliceByKey(key, isShifted) {
        switch (key) {
            case keyStrings.ARROW_LEFT: {
                let sliceDelta = isShifted ? 5 : 1;
                this._shiftSlice(sliceDelta);
                break;
            }

            case keyStrings.ARROW_RIGHT: {
                let sliceDelta = isShifted ? -5 : -1;
                this._shiftSlice(sliceDelta);
                break;
            }

            default:
                return;
        }
    }

    shiftSliceByWheel(e) {
        if (e.deltaY > 0) {
            this._shiftSlice(1);
        } else {
            this._shiftSlice(-1);
        }
    }

    _shiftSlice(sliceDelta) {
        let newSliceIdx;

        if (sliceDelta > 0) {
            newSliceIdx =
                this._mriModel.sliceIndex + sliceDelta >
                this._mriModel.orientationMaxIndex
                    ? this._mriModel.orientationMaxIndex
                    : this._mriModel.sliceIndex + sliceDelta;
        } else if (sliceDelta < 0) {
            newSliceIdx =
                this._mriModel.sliceIndex + sliceDelta < 0
                    ? 0
                    : this._mriModel.sliceIndex + sliceDelta;
        }

        this._mriModel.sliceIndex = newSliceIdx;
    }
}
