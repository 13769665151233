import * as THREE from "three";
import { hasProperty, isEmpty, toArray } from "./helpers";
import { clientErrorMessages } from "../constants";
import { getPHISignedURLS3 } from "./backend_api";
import { setErrorState } from "../redux/error_banner/actions";
import { rootStore } from "../redux/store";

export async function getMRIURLsHelper(tplan, input) {
    if (
        !isEmpty(tplan) &&
        hasProperty(tplan, "AdditionalData.MRI") &&
        tplan.AdditionalData.MRI.length > 0
    ) {
        return getPHISignedURLS3({
            token: input.token,
            useruuid: input.useruuid,
            patientuuid: input.patientuuid,
            visituuid: input.visituuid,
            uris: toArray(
                tplan.AdditionalData.MRI[0].Slices.map((mri) => mri.URI)
            ),
        })
            .then((payload) => payload.json())

            .then((json) => json.payload.signedurls)

            .then((mriData) => mriData.filter((slice) => slice !== undefined))

            .catch(() => {
                rootStore.dispatch(
                    setErrorState(true, clientErrorMessages.MRI_LOAD_FAILED)
                );
            });
    }
}

export function flattenVertices(vtxs) {
    let vtxPts = [];
    if (vtxs) {
        for (let i = 0; i < vtxs.length; i++) {
            vtxPts.push(vtxs[parseInt(i)].x);
            vtxPts.push(vtxs[parseInt(i)].y);
            vtxPts.push(vtxs[parseInt(i)].z);
        }
    }
    return vtxPts;
}

export function flattenFaces(faces) {
    let triIdx = [];
    if (faces) {
        for (let i = 0; i < faces.length; i++) {
            triIdx.push(faces[parseInt(i)].a);
            triIdx.push(faces[parseInt(i)].b);
            triIdx.push(faces[parseInt(i)].c);
        }
    }
    return triIdx;
}

export function fullClone(obj) {
    let cloneObj = obj.clone();
    cloneObj.updateMatrix();
    cloneObj.geometry = new THREE.BufferGeometry().fromBufferGeometry(
        obj.geometry
    );
    cloneObj.geometry.applyMatrix4(cloneObj.matrix);
    return cloneObj;
}

export function computeRotationMatrix(vecA, vecB) {
    if (isEmpty(vecA) || isEmpty(vecB)) {
        return;
    }
    let q = new THREE.Quaternion();
    q.setFromUnitVectors(vecA.normalize(), vecB.normalize());
    let R4 = new THREE.Matrix4();
    R4.makeRotationFromQuaternion(q);
    let R3 = new THREE.Matrix3();
    R3.setFromMatrix4(R4);
    return R3;
}

export function r4FromR3(R3) {
    let r = R3.elements;
    let R4 = new THREE.Matrix4();

    R4.set(
        r[0],
        r[1],
        r[2],
        0,
        r[3],
        r[4],
        r[5],
        0,
        r[6],
        r[7],
        r[8],
        0,
        0,
        0,
        0,
        1
    );
    return R4;
}

export function deleteAllObjectsInScene(scene) {
    while (scene.children.length) {
        let object = scene.children[0];
        disposeHierarchy(object);
        scene.remove(object);
    }
    scene = null;
}

/* eslint-disable */
export function disposeHierarchy(object) {
    if (object.children && object.children.length !== 0) {
        object.children.reduceRight((_, child) => {
            disposeHierarchy(child);
            disposeNode(child);
        });
    } else {
        disposeNode(object);
    }
}
/* eslint-enable */

export function disposeNode(node) {
    // Dispose of memory in VRAM using dispose method
    if (node instanceof THREE.Mesh) {
        node.parent = null;

        // Dispose of geometry
        if (node.geometry) {
            node.geometry.dispose();
            node.geometry = null;
        }

        // Dispose of material
        let material = node.material;
        if (material) {
            if (material.map) material.map.dispose();
            if (material.lightMap) material.lightMap.dispose();
            if (material.bumpMap) material.bumpMap.dispose();
            if (material.normalMap) material.normalMap.dispose();
            if (material.specularMap) material.specularMap.dispose();
            if (material.envMap) material.envMap.dispose();

            material.dispose();
            material = null;
        }
    } else if (node instanceof THREE.Object3D) {
        // Check if a given node has a disposal method before removing it
        if (node.dispose !== null && typeof node.dispose == "function") {
            node.dispose();
        }
    }
}

export function removeEntity(scene, name) {
    let selectedObject = scene.getObjectByName(name);

    if (selectedObject != null) {
        while (selectedObject.children.length) {
            selectedObject.remove(selectedObject.children[0]);
        }
        scene.remove(selectedObject);
    }
}
