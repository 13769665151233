import React, { Component } from "react";
import "./styles.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import QRCodeWidget from "./QRCodeWidget";
import OTPCodeWidget from "./OTPCodeWidget";
import { generateMFAAuth } from "./helpers";
import {
    EMPTY_STRING,
    SUCCESS,
    stepPath,
    clientErrorMessages,
} from "../../constants";
import PropTypes from "prop-types";
import { getUser } from "../Login/helpers";
import { rootStore } from "../../redux/store";
import { setErrorState } from "../../redux/error_banner/actions";
import { setUserData } from "../Login/actions";

export const mfaSteps = {
    LOADING: "LOADING",
    QR_CODE_WIDGET: "QR_CODE_WIDGET",
    OTP_CODE: "OTP_CODE",
};

class EnableMFA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: mfaSteps.LOADING,
            base32: null,
            otpAuthUrl: null,
        };
        this.getSignUpFlowState = this.getSignUpFlowState.bind(this);
        this.setStep = this.setStep.bind(this);
        this.handleOTPVerified = this.handleOTPVerified.bind(this);
    }

    setStep(step) {
        this.setState({
            step: step,
        });
    }

    handleOTPVerified() {
        getUser(this.props.username, this.props.authToken)
            .then((result) => {
                if (result && result.status === SUCCESS) {
                    this.props.setUserData(result.user);
                } else {
                    rootStore.dispatch(
                        setErrorState(
                            true,
                            String(clientErrorMessages.FAILED_TO_UPDATE_USER)
                        )
                    );
                }
                this.props.history.push(stepPath.PROFILE);
            })
            .catch((e) => {
                rootStore.dispatch(setErrorState(true, String(e)));
            });
    }

    componentDidMount() {
        generateMFAAuth(this.props.useruuid, this.props.authToken).then(
            (result) => {
                if (result.status === SUCCESS) {
                    this.setState({
                        base32: result.base32,
                        otpAuthUrl: result.otpAuthUrl,
                        step: mfaSteps.QR_CODE_WIDGET,
                    });
                }
            }
        );
    }

    getSignUpFlowState(flowState) {
        switch (flowState) {
            case mfaSteps.QR_CODE_WIDGET:
                return (
                    <QRCodeWidget
                        otpAuthUrl={this.state.otpAuthUrl}
                        base32={this.state.base32}
                        setStep={this.setStep}
                    />
                );
            case mfaSteps.OTP_CODE:
                return (
                    <OTPCodeWidget
                        username={this.props.username}
                        useruuid={this.props.useruuid}
                        authToken={this.props.authToken}
                        initialVerification={true}
                        onSuccess={this.handleOTPVerified}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        return (
            <div className={"signup-flow"}>
                {this.getSignUpFlowState(this.state.step)}
            </div>
        );
    }
}

EnableMFA.propTypes = {
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    username: PropTypes.string,
    history: PropTypes.object,
    setUserData: PropTypes.func,
};

EnableMFA.defaultProps = {
    useruuid: EMPTY_STRING,
    authToken: EMPTY_STRING,
    username: EMPTY_STRING,
    setUserData: () => {},
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        authToken: state.LoginReducer.authToken,
        useruuid: state.LoginReducer.useruuid,
        username: state.LoginReducer.userData.username,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setUserData: (userData) => dispatch(setUserData(userData)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EnableMFA)
);
