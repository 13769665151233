import React, { Component } from "react";
import TopBar from "../__shared__/TopBar";
import AvendaLogo from "../__shared__/AvendaLogo";
import "./styles.css";
import { connect } from "react-redux";
import {
    EMPTY_STRING,
    SUCCESS,
    imageSize,
    clientErrorMessages,
    stepPath,
    AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
} from "../../constants";
import { withRouter } from "react-router";
import { signOut } from "../Login/helpers";
import { isEmpty } from "../../helpers/helpers";
import { deleteAblationProfile, getAblationProfiles } from "./helpers";
import blankUser from "../../assets/blank_user.svg";
import { handleLogout, setUserData } from "../Login/actions";
import PropTypes from "prop-types";
import { setErrorState } from "../../redux/error_banner/actions";
import { rootStore } from "../../redux/store";
import { PROFILE_MENU_ITEMS } from "./constants";
import ReportBugs from "./ReportBugs";
import {
    setAblationProfile,
    setCreateNewProfile,
} from "../CreateAblationProfile/actions";
import AboutThisSoftware from "./AboutThisSoftware";
import { updateUser } from "../Login/helpers";
import Security from "./Security";
import PreviousStepButton from "../__shared__/PreviousStepButton";
import ProfileInfoPage from "./ProfileInfoPage";
import ToolProfileMenu from "./ToolProfileMenu";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenuItem: PROFILE_MENU_ITEMS.ACCOUNT_INFO,
            loadedProfiles: [],
            deleteInProgress: false,
        };

        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setSelectedMenuItem = this.setSelectedMenuItem.bind(this);
        this.unshiftDefaultProfile = this.unshiftDefaultProfile.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.loadAblationProfiles = this.loadAblationProfiles.bind(this);
        this.editCallback = this.editCallback.bind(this);
        this.setDefaultProfile = this.setDefaultProfile.bind(this);
        this.deleteCallback = this.deleteCallback.bind(this);
        this.createAblationProfileCallback =
            this.createAblationProfileCallback.bind(this);
        this.getMenuItemClassName = this.getMenuItemClassName.bind(this);
    }

    setSelectedMenuItem(itemName) {
        this.setState({
            selectedMenuItem: itemName,
        });
    }

    componentDidMount() {
        this.loadAblationProfiles();
        const { selectedMenuItem } = this.props.location.state || {
            selectedMenuItem: PROFILE_MENU_ITEMS.ACCOUNT_INFO,
        };
        this.setState({ selectedMenuItem });
    }

    openFileDialog() {
        this.fileInputRef.current.click();
    }

    onChange(e) {
        const files = e.target.files;

        if (files.length > 0) {
            let imageFile = files[0];
            if (imageFile.size < imageSize.PROFILE_PIC_MIN) {
                alert(clientErrorMessages.PROFILE_PICTURE_TOO_SMALL);
                return;
            }

            if (imageFile.size > imageSize.PROFILE_PIC_MAX) {
                alert(clientErrorMessages.PROFILE_PICTURE_TOO_LARGE);
                return;
            }
        }
    }

    unshiftDefaultProfile(loadedCallback) {
        this.state.loadedProfiles.unshift({
            name: "Avenda Health Laser Ablation",
            modality: "Laser",
            custom: false,
            ablationProfileUUID: AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
        });

        loadedCallback && loadedCallback();
    }

    loadAblationProfiles(loadedCallback) {
        if (
            !isEmpty(this.props.userData.uuid) &&
            !isEmpty(this.props.authToken)
        ) {
            let input = {
                userUuid: this.props.userData.uuid,
                authToken: this.props.authToken,
            };

            getAblationProfiles(input).then((result) => {
                if (result !== null && !isEmpty(result.customProfiles)) {
                    this.setState({
                        loadedProfiles: result.customProfiles,
                    });
                } else {
                    this.setState({
                        loadedProfiles: [],
                    });
                }

                this.unshiftDefaultProfile(loadedCallback);
            });
        } else {
            this.unshiftDefaultProfile(loadedCallback);
        }
    }

    createAblationProfileCallback() {
        this.props.setCreateNewProfile(true);
        setTimeout(() => {
            this.props.history.push(stepPath.CREATE_ABLATION_PROFILE);
        }, 0);
    }

    editCallback(uuid) {
        this.props.setAblationProfile(uuid);
        this.props.history.push(stepPath.CREATE_ABLATION_PROFILE);
    }

    deleteCallback(uuid) {
        this.setState({ deleteInProgress: true });

        let input = {
            userUuid: this.props.userData.uuid,
            authToken: this.props.authToken,
            ablationProfileUUID: uuid,
        };

        deleteAblationProfile(input).then(() => {
            this.loadAblationProfiles(() => {
                this.setState({ deleteInProgress: false });
            });
        });
    }

    setDefaultProfile(uuid) {
        let updatedUserData = this.props.userData;
        updatedUserData.defaultAblationProfileUUID = uuid;
        this.props.setUserData(updatedUserData);

        updateUser(
            this.props.userData.username,
            this.props.authToken,
            updatedUserData
        )
            .then((payload) => {
                if (!(!isEmpty(payload) && payload.status === SUCCESS)) {
                    throw new Error(clientErrorMessages.FAILED_TO_UPDATE_USER);
                }
            })
            .catch((e) => {
                rootStore.dispatch(setErrorState(true, String(e)));
                return null;
            });
    }

    closePopup() {
        this.fileInputRef.current.value = EMPTY_STRING;
        this.setState({ popup: EMPTY_STRING });
    }

    getMenuItemDisplay(selectedItem) {
        const profileInfoPageComponent = (
            <ProfileInfoPage
                userData={this.props.userData}
                authToken={this.props.authToken}
            />
        );

        switch (selectedItem) {
            case PROFILE_MENU_ITEMS.ACCOUNT_INFO:
                return profileInfoPageComponent;

            case PROFILE_MENU_ITEMS.ABLATION_PROFILES:
                return (
                    <ToolProfileMenu
                        userData={this.props.userData}
                        unText={this.props.userData.username}
                        authToken={this.props.authToken}
                        editToolCallback={this.editCallback}
                        createToolCallback={this.createAblationProfileCallback}
                    />
                );

            case PROFILE_MENU_ITEMS.REPORT_BUGS:
                return (
                    <ReportBugs
                        uuid={this.props.userData.uuid}
                        email={this.props.userData.email}
                    />
                );

            case PROFILE_MENU_ITEMS.ABOUT_THIS:
                return <AboutThisSoftware />;

            case PROFILE_MENU_ITEMS.SECURITY:
                return (
                    <Security
                        userData={this.props.userData}
                        authToken={this.props.authToken}
                        handleLogout={this.handleLogout}
                    />
                );

            default:
                return profileInfoPageComponent;
        }
    }

    getMenuItemClassName = (menuItem) =>
        "profile-page-left-menu-item" +
        (this.state.selectedMenuItem === menuItem ? " active" : "");

    handleLogout() {
        signOut(this.props.userData.username).then(() => {
            this.props.history.push(stepPath.LOGIN);
            this.props.handleLogout();
        });
    }

    render() {
        const userData = this.props.userData;
        let profilePictureUrl = userData.profilePictureUrl
            ? userData.profilePictureUrl
            : blankUser;

        return (
            <div id="profile-grid-layout">
                <TopBar id="profile-topbar">
                    <PreviousStepButton
                        to={stepPath.HOME}
                        style={{ margin: 30 }}
                    />
                    <AvendaLogo
                        id="create-plan-avenda-logo"
                        to={stepPath.HOME}
                    />
                </TopBar>
                <div
                    className="profile-child-container display-regular-18"
                    id="profile-menu-container"
                >
                    <div>
                        <div
                            className={
                                this.getMenuItemClassName(
                                    PROFILE_MENU_ITEMS.ACCOUNT_INFO
                                ) + " first"
                            }
                            onClick={() => {
                                this.setSelectedMenuItem(
                                    PROFILE_MENU_ITEMS.ACCOUNT_INFO
                                );
                            }}
                        >
                            {PROFILE_MENU_ITEMS.ACCOUNT_INFO}
                        </div>
                        <div
                            className={this.getMenuItemClassName(
                                PROFILE_MENU_ITEMS.ABLATION_PROFILES
                            )}
                            onClick={() => {
                                this.setSelectedMenuItem(
                                    PROFILE_MENU_ITEMS.ABLATION_PROFILES
                                );
                            }}
                        >
                            {PROFILE_MENU_ITEMS.ABLATION_PROFILES}
                        </div>
                        <div
                            className={this.getMenuItemClassName(
                                PROFILE_MENU_ITEMS.ABOUT_THIS
                            )}
                            id="left-menu-item-about"
                            onClick={() => {
                                this.setSelectedMenuItem(
                                    PROFILE_MENU_ITEMS.ABOUT_THIS
                                );
                            }}
                        >
                            {PROFILE_MENU_ITEMS.ABOUT_THIS}
                        </div>
                        <div
                            className={this.getMenuItemClassName(
                                PROFILE_MENU_ITEMS.SECURITY
                            )}
                            id="left-menu-item-security"
                            onClick={() => {
                                this.setSelectedMenuItem(
                                    PROFILE_MENU_ITEMS.SECURITY
                                );
                            }}
                        >
                            {PROFILE_MENU_ITEMS.SECURITY}
                        </div>
                        <div
                            className={this.getMenuItemClassName(
                                PROFILE_MENU_ITEMS.REPORT_BUGS
                            )}
                            onClick={() => {
                                this.setSelectedMenuItem(
                                    PROFILE_MENU_ITEMS.REPORT_BUGS
                                );
                            }}
                        >
                            {PROFILE_MENU_ITEMS.REPORT_BUGS}
                        </div>
                    </div>
                    <button
                        id="profile-page-left-menu-logout-button"
                        onClick={this.handleLogout}
                    >
                        Log Out
                    </button>
                </div>
                <div
                    className="profile-child-container"
                    id="profile-content-container"
                >
                    <div
                        className="display-regular-28"
                        id="profile-content-header"
                    >
                        {this.state.selectedMenuItem}
                    </div>
                    <div
                        className="display-regular-16"
                        id="profile-content-container-content"
                    >
                        {userData
                            ? this.getMenuItemDisplay(
                                  this.state.selectedMenuItem,
                                  userData,
                                  profilePictureUrl
                              )
                            : null}
                    </div>
                </div>
            </div>
        );
    }
}

Profile.propTypes = {
    authToken: PropTypes.string,
    history: PropTypes.object,
    handleLogout: PropTypes.func,
    onClick: PropTypes.func,
    children: PropTypes.any,
    id: PropTypes.string,
    setCreateNewProfile: PropTypes.func,
    setAblationProfile: PropTypes.func,
    setUserData: PropTypes.func,
    userData: PropTypes.object,
    location: PropTypes.shape({
        state: PropTypes.object,
    }),
};

Profile.defaultProps = {
    authToken: EMPTY_STRING,
    userData: {},
};

const mapStateToProps = function (state) {
    return {
        userData: state.LoginReducer.userData,
        authToken: state.LoginReducer.authToken,
        flags: state.LoginReducer.flags,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setUserData: (userData) => dispatch(setUserData(userData)),
        handleLogout: () => dispatch(handleLogout()),
        setCreateNewProfile: (isOn) => dispatch(setCreateNewProfile(isOn)),
        setAblationProfile: (uuid) => dispatch(setAblationProfile(uuid)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Profile)
);
