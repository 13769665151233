import React, { Component } from "react";
import "./styles.css";

class CPMColorKey extends Component {
    render() {
        return (
            <div className={"cpm-color-key"}>
                <div className={"cpm-color-key-header"}>
                    <p
                        className={"display-12"}
                        style={{
                            color: blackColorText,
                            fontSize: titleFontSize,
                        }}
                    >
                        Cancer Estimation Map
                    </p>
                </div>
                <div className={"lesion-heat-map-color-key-container"}>
                    <div
                        className={
                            "lesion-heat-map-color-key-gradient-container"
                        }
                    >
                        <div id={"lesion-heat-map-color-key-gradient"} />
                    </div>
                    <div className={"lesion-heat-map-color-key-text-container"}>
                        <p
                            className={
                                "lesion-heat-map-color-key-text-high-risk display-12"
                            }
                            style={{
                                color: blackColorText,
                                fontSize: titleFontSize,
                            }}
                        >
                            High
                        </p>
                        <p
                            className={
                                "lesion-heat-map-color-key-text-low-risk display-12"
                            }
                            style={{
                                color: blackColorText,
                                fontSize: titleFontSize,
                            }}
                        >
                            Low
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default CPMColorKey;

const blackColorText = "#000000";
const titleFontSize = "14px";
