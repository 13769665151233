import { defaultTxParameters } from "../../consts";
import {
    AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
    EMPTY_STRING,
    avendaDefaultAblationProfileVolumeNames,
} from "../../../../../constants";
import { VOLUME_SHAPES } from "../../../../CreateTPlan_4EditMargin/constants";

export const defaultFLATargetParams = {
    Type: defaultTxParameters.TYPE,
    Modality: defaultTxParameters.MODALITY,
    Opacity: defaultTxParameters.OPACITY,
    UserModified: true,
    Treated: false,
    TreatmentDuration: defaultTxParameters.TX_DURATION,
    CooldownDuration: defaultTxParameters.TX_COOLDOWN_DURATION,
    Power: defaultTxParameters.POWER,
    CriticalSensor: defaultTxParameters.CRITICAL_SENSOR,
    ApplicatorOffset: 0,
    Frame: {
        MRI: {
            Path: EMPTY_STRING,
            URI: EMPTY_STRING,
            Checksum: EMPTY_STRING,
            Center: {
                X: 0,
                Y: 0,
                Z: 0,
            },
            Orientation: {
                X: 0,
                Y: 0,
                Z: 1,
            },
            BoundingBox: {
                Min: {
                    X: -1,
                    Y: -1,
                    Z: -1,
                },
                Max: {
                    X: 1,
                    Y: 1,
                    Z: 1,
                },
            },
            Registration: {},
        },
    },
    Notes: EMPTY_STRING,
};

export const defaultAblationVolumeSize = {
    LARGE: avendaDefaultAblationProfileVolumeNames.LARGE,
    MED: avendaDefaultAblationProfileVolumeNames.MED,
    SMALL: avendaDefaultAblationProfileVolumeNames.SMALL,
};

export const defaultVolumeMetaData = {
    LARGE: {
        ablationVolumeUUID: defaultAblationVolumeSize.LARGE,
        ablationProfileUUID: AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
        name: "Avenda Large",
        meshPrimitiveType: VOLUME_SHAPES.ELLIPSE,
    },
    MED: {
        ablationVolumeUUID: defaultAblationVolumeSize.MED,
        ablationProfileUUID: AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
        name: "Avenda Medium",
        meshPrimitiveType: VOLUME_SHAPES.ELLIPSE,
    },
    SMALL: {
        ablationVolumeUUID: defaultAblationVolumeSize.SMALL,
        ablationProfileUUID: AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
        name: "Avenda Small",
        meshPrimitiveType: VOLUME_SHAPES.ELLIPSE,
    },
};

export const defaultProfileMetaData = {
    ablationProfileUUID: "default",
    name: "Avenda Default",
    userUUID: "global",
    volumeUUIDs: [
        defaultAblationVolumeSize.LARGE,
        defaultAblationVolumeSize.MED,
        defaultAblationVolumeSize.SMALL,
    ],
    volumes: [
        defaultVolumeMetaData.LARGE,
        defaultVolumeMetaData.MED,
        defaultVolumeMetaData.SMALL,
    ],
};

const October2022LCModel = {
    PART_NUMBER: "41377",
    REVISION_NUMBER: "6",
};

export const LaserCatheterModels = {
    OCTOBER_2022_MODEL: October2022LCModel,
};
