import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";
import CheckCircle from "../IconComponents/CheckCircle";
import NumberCircle from "../IconComponents/NumberCircle";

export default class RequirementListItem extends Component {
    render() {
        return (
            <div
                id={this.props.id}
                className="requirement-list-item"
                style={this.props.style}
            >
                <div className="req-list-icon-container">
                    {this.props.satisfied ? (
                        <CheckCircle />
                    ) : (
                        <NumberCircle listIndex={this.props.listIndex} />
                    )}
                </div>
                <div className="req-list-item-description">
                    <div
                        className={`display-regular-16 ${
                            this.props.satisfied ? "text-blue" : "text-gray"
                        }`}
                    >
                        {this.props.reqText}
                    </div>
                </div>
            </div>
        );
    }
}

RequirementListItem.propTypes = {
    id: PropTypes.string,
    satisfied: PropTypes.bool,
    reqText: PropTypes.string,
    listIndex: PropTypes.number,
    style: PropTypes.object,
};

RequirementListItem.defaultProps = {
    id: EMPTY_STRING,
    satisfied: false,
    reqText: "NA",
    listIndex: 0,
};
