import * as THREE from "three";
import { VTKLoader } from "three/examples/jsm/loaders/VTKLoader";
import { volumeNames } from "../../../../../constants";
import { makeMRIViewerMesh } from "../../MRIViewer/helpers";
import { GENERIC_MATERIAL } from "../../MeshViewer/materials";
import { removeEntity } from "../../../../../helpers/threejs_helpers";
import { THREE_VIEWER_NAMES } from "../../consts";
import { RAS2LPS } from "../../helpers";

export default class StructureModel {
    constructor() {
        this._color = null;
        this._position = null;
        this._quaternion = null;
        this._name = null;
        this._visible = true;
        this._highlightColor = new THREE.Color("#FFFFFF");
    }

    get color() {
        return this._color;
    }

    set color(color) {
        this._color = color;

        if (this._selfMRIView) {
            this._selfMRIView.meshFront.material.color = color;
            this._selfMRIView.meshBack.material.color = color;
        }

        if (this._selfMeshView) {
            this._selfMeshView.material.color = color;
            if (this._selfMeshView.material.uniforms) {
                this._selfMeshView.material.uniforms.color = new THREE.Uniform(
                    color
                );
            }
        }
    }

    get opacity() {
        return this._opacity;
    }

    set opacity(opacity) {
        this._opacity = opacity;

        if (this._selfMRIView) {
            this._selfMRIView.meshFront.material.opacity = opacity;
            this._selfMRIView.meshBack.material.opacity = opacity;
        }

        if (this._selfMeshView) {
            this._selfMeshView.material.opacity = opacity;
        }
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;

        if (this._selfMRIView) {
            this._selfMRIView.meshFront.name = `${this._name}${volumeNames.FRONT}`;
            this._selfMRIView.meshBack.name = `${this._name}${volumeNames.BACK}`;
        }

        if (this._selfMeshView) {
            this._selfMeshView.name = `${this._name}_${volumeNames.BOTH}`;
        }
    }

    get visibility() {
        return this._visible;
    }

    set visibility(visibility) {
        this._visible = visibility;
        if (this._selfMRIView) {
            this._selfMRIView.scene.visible = visibility;
        }

        if (this._selfMeshView) {
            this._selfMeshView.visible = visibility;
        }
    }

    get highlight() {
        return this._highlighted;
    }

    set highlight(isHighlighted) {
        this._highlighted = isHighlighted;

        if (isHighlighted) {
            if (this._selfMRIView) {
                this._selfMRIView.meshFront.material.color =
                    this._highlightColor;
                this._selfMRIView.meshBack.material.color =
                    this._highlightColor;
            }

            if (this._selfMeshView) {
                this._selfMeshView.material.color = this._highlightColor;
                this._selfMeshView.children.forEach((i) => {
                    if (i.material) i.material.color = this._highlightColor;
                });
                if (this._selfMeshView.material.uniforms) {
                    this._selfMeshView.material.uniforms.color =
                        new THREE.Uniform(this._highlightColor);
                }
            }
        } else {
            if (this._selfMRIView) {
                this._selfMRIView.meshFront.material.color = this._color;
                this._selfMRIView.meshBack.material.color = this._color;
            }

            if (this._selfMeshView) {
                this._selfMeshView.material.color = this._color;
                this._selfMeshView.children.forEach((i) => {
                    if (i.material) i.material.color = this._color;
                });
                if (this._selfMeshView.material.uniforms) {
                    this._selfMeshView.material.uniforms.color =
                        new THREE.Uniform(this._color);
                }
            }
        }
    }

    get wireframe() {
        return this._wireframe;
    }

    set wireframe(isWire) {
        this._wireframe = isWire;

        if (this._selfMeshView) {
            if (this._selfMeshView.material) {
                this._selfMeshView.material.wireframe = isWire;
            }

            this._selfMeshView.children.forEach((i) => {
                if (i.material) {
                    i.material.wireframe = isWire;
                }
            });
        }
    }

    get position() {
        return this._position;
    }

    set position(position) {
        this._position = position;

        if (this._selfMRIView) {
            this._selfMRIView.meshFront.position.copy(position);
            this._selfMRIView.meshBack.position.copy(position);
        }

        if (this._selfMeshView) {
            this._selfMeshView.position.copy(position);

            if (this._frameAxes) {
                this._frameAxes.update(this._selfMeshView.matrixWorld);
            }
        }
    }

    get quaternion() {
        return this._quaternion;
    }

    set quaternion(quaternion) {
        this._quaternion = quaternion;

        if (this._selfMRIView) {
            this._selfMRIView.meshFront.quaternion.copy(quaternion);
            this._selfMRIView.meshBack.quaternion.copy(quaternion);
        }

        if (this._selfMeshView) {
            this._selfMeshView.quaternion.copy(quaternion);

            if (this._frameAxes) {
                this._frameAxes.update(this._selfMeshView.matrixWorld);
            }
        }

        let orientation = new THREE.Vector3(0, 1, 0);
        orientation.applyQuaternion(quaternion);
        this._orientation = orientation;
    }

    get mriViewer() {
        return this._mriViewer;
    }

    set mriViewer(mriViewer) {
        this._mriViewer = mriViewer;
    }

    get meshViewer() {
        return this._meshViewer;
    }

    set meshViewer(meshViewer) {
        this._meshViewer = meshViewer;
    }

    get boundingBox() {
        if (this._selfMeshView) {
            return new THREE.Box3().setFromObject(this._selfMeshView);
        } else if (this._selfMRIView) {
            return new THREE.Box3().setFromObject(this._selfMRIView.meshFront);
        } else {
            return new THREE.Box3(
                new THREE.Vector3(0, 0, 0),
                new THREE.Vector3(0, 0, 0)
            );
        }
    }

    get boundingSphere() {
        if (this._selfMRIView) {
            this._selfMRIView.meshFront.geometry.computeBoundingSphere();
            return this._selfMRIView.meshFront.geometry.boundingSphere;
        } else if (this._selfMeshView) {
            this._selfMeshView.children[0].geometry.computeBoundingSphere();
            return this._selfMeshView.children[0].geometry.boundingSphere;
        } else {
            return new THREE.Sphere(new THREE.Vector3(0, 0, 0), 1);
        }
    }

    get center() {
        return this.boundingSphere.center;
    }

    get initialized() {
        return this._initialized;
    }

    get worldTransformMatrix() {
        if (this._selfMRIView) {
            return this._selfMRIView.meshFront.matrixWorld;
        } else if (this._selfMeshView) {
            return this._selfMeshView.matrixWorld;
        } else {
            return null;
        }
    }

    get geometry() {
        if (this._selfMRIView) {
            return this._selfMRIView.meshBack.geometry;
        } else if (this._selfMeshView) {
            if (this._selfMeshView.children[0]) {
                return this._selfMeshView.children[0].geometry;
            } else if (this._selfMeshView.geometry) {
                return this._selfMeshView.geometry;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    get faces() {
        let geometry = this.geometry;

        return geometry.index.array;
    }

    get vertices() {
        let geometry = this.geometry;

        let vertices = geometry.attributes.position.array;

        let tf_vertices = [];

        for (let i = 0; i < Math.floor(vertices.length); i += 3) {
            let tmp_vtx = new THREE.Vector3(
                vertices[parseInt(i)],
                vertices[parseInt(i + 1)],
                vertices[parseInt(i + 2)]
            );

            if (this._selfMRIView) {
                this._selfMRIView.meshFront.updateMatrixWorld();
                this._selfMRIView.meshFront.localToWorld(tmp_vtx);
                tmp_vtx.applyMatrix4(RAS2LPS);
            } else if (this._selfMeshView) {
                this._selfMeshView.localToWorld(tmp_vtx);
                tmp_vtx.applyMatrix4(RAS2LPS);
            }

            tf_vertices.push(tmp_vtx.x, tmp_vtx.y, tmp_vtx.z);
        }

        return tf_vertices;
    }

    set verticesNeedUpdate(needUpdate) {
        if (this._selfMRIView) {
            this._selfMRIView.meshFront.geometry.verticesNeedUpdate =
                needUpdate;
            this._selfMRIView.meshBack.geometry.verticesNeedUpdate = needUpdate;
        }

        if (this._selfMeshView) {
            this._selfMeshView.children[0].geometry.verticesNeedUpdate =
                needUpdate;
        }
    }

    raycast(raycaster, intersects) {
        if (
            this._selfMRIView &&
            raycaster.camera.type === THREE_VIEWER_NAMES.ORTHOGRAPHIC_CAMERA
        ) {
            let intersectionArray = raycaster.intersectObject(
                this._selfMRIView.meshFront
            );

            if (intersectionArray && intersectionArray.length > 0) {
                intersectionArray.forEach((itx) => {
                    itx.StructureModel = this;

                    intersects.push(itx);
                });
            }
        } else if (
            this._selfMeshView &&
            raycaster.camera.type === THREE_VIEWER_NAMES.PERSPECTIVE_CAMERA
        ) {
            let intersectionArray = raycaster.intersectObject(
                this._selfMeshView,
                true
            );

            if (intersectionArray && intersectionArray.length > 0) {
                intersectionArray.forEach((itx) => {
                    itx.StructureModel = this;

                    intersects.push(itx);
                });
            }
        }
    }

    rotateOnWorldAxis(axis, rotationMagnitude) {
        if (this._selfMRIView) {
            this._selfMRIView.meshFront.rotateOnWorldAxis(
                axis,
                rotationMagnitude
            );
            this._selfMRIView.meshBack.rotateOnWorldAxis(
                axis,
                rotationMagnitude
            );
        }

        if (this._selfMeshView) {
            this._selfMeshView.rotateOnWorldAxis(axis, rotationMagnitude);

            if (this._frameAxes) {
                this._frameAxes.update(this._selfMeshView.matrixWorld);
            }
        }
    }

    delete() {
        if (this._mriViewer) {
            removeEntity(
                this._mriViewer.RASframe,
                `${this.name}${volumeNames.FRONT}`
            );
            removeEntity(
                this._mriViewer.RASframe,
                `${this.name}${volumeNames.BACK}`
            );
            removeEntity(this._mriViewer.RASframe, this.name);
        }

        if (this._meshViewer) {
            removeEntity(this._meshViewer.RASframe, `${this.name}`);
        }

        this._selfMRIView = null;
        this._selfMeshView = null;
    }

    async initializeView(structureURL, options) {
        let scope = this;
        options.visibility =
            options.visibility === undefined ? true : options.visibility;
        this.color = new THREE.Color(options.color);
        this.opacity = options.opacity;
        this.name = options.name;
        this.visibility = options.visibility;

        let loadPromise = new Promise((resolve) => {
            let vtkLoader = new VTKLoader();

            function loadComplete(x) {
                resolve(x);
            }

            vtkLoader.load(structureURL, loadComplete);
        });

        await loadPromise.then((_geometry) => {
            scope.initializeViewFromGeometry(_geometry, options);
        });

        this._initialized = true;
    }

    initializeViewFromGeometry(geometry, options) {
        this.color = new THREE.Color(options.color);
        this.opacity = options.opacity;
        this.name = options.name;

        geometry.computeVertexNormals();
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();

        this._createMRIView(geometry, options);
        this._createMeshView(geometry, options);
    }

    initAxes(frameAxes) {
        if (!this._meshViewer) {
            return;
        }

        this._frameAxes = frameAxes;
        this._frameAxes.meshViewer = this._meshViewer;
        this._frameAxes.initializeView(
            this._selfMeshView.matrixWorld,
            this.name
        );
    }

    initProstateAxes(prostateAxes) {
        if (!this._meshViewer) {
            return;
        }

        this._prostateAxes = prostateAxes;
        this._prostateAxes.meshViewer = this._meshViewer;
        this._prostateAxes.initializeView(this);
    }

    _createMRIView(geometry, options) {
        if (!this._mriViewer) {
            return;
        }

        let meshObject = makeMRIViewerMesh({
            name: options.name,
            geometry: geometry,
            frontOpacity: 0,
            backOpacity: 1.0,
            color: options.color,
            visible: options.visibility,
        });

        this._selfMRIView = meshObject;
        this._mriViewer.RASframe.add(meshObject.scene);

        if (this._mriViewer.meshObjects) {
            this._mriViewer.meshObjects.push(meshObject);
        } else {
            this._mriViewer.meshObjects = [];
        }
    }

    _createMeshView(geometry, options) {
        if (!this._meshViewer) {
            return;
        }

        geometry.computeBoundsTree();
        let material = GENERIC_MATERIAL.clone();
        material.transparent = true;
        material.visible = true;
        material.opacity = options.opacity;
        material.color = new THREE.Color(options.color);
        material.depthWrite = false;

        let volume = new THREE.Object3D();
        volume.name = options.name;

        let volumeBack = new THREE.Mesh(geometry, material.clone());
        volumeBack.material.side = THREE.BackSide;
        volumeBack.name = options.name;
        volume.add(volumeBack);

        let volumeFront = new THREE.Mesh(geometry, material.clone());
        volumeFront.material.side = THREE.FrontSide;
        volumeFront.name = options.name;
        volume.add(volumeFront);
        volume.visible = options.visibility;

        this._selfMeshView = volume;
        this._meshViewer.RASframe.add(volume);
    }
}
