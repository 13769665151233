import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

class ProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            display: "visible",
            progressIteration: 0,
        };

        this.updateWidth = this.updateWidth.bind(this);
    }

    componentDidMount() {
        if (!this.props.loadComplete) {
            this.pollProgress = setInterval(() => {
                this.updateWidth();
                this.setState({
                    progressIteration: this.state.progressIteration + 1,
                });
            }, 1000);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.loadComplete !== prevProps.loadComplete ||
            (this.props.loadFailed && this.state.width !== FinalWidth)
        ) {
            this.setState({ width: FinalWidth });
            clearInterval(this.pollProgress);
            this.pollProgress = setTimeout(() => {
                this.setState({ display: "hidden" });
            }, 1500);
        }
    }

    componentWillUnmount() {
        clearInterval(this.pollProgress);
    }

    updateWidth() {
        let width = getNewWidth(this.state.progressIteration);
        this.setState({ width: width });
    }

    render() {
        let style = {
            width: `${this.state.width}%`,
        };
        return (
            <div className={`loading-bar-${this.state.display}`}>
                <div className={`loading-bar-fill`} style={{ ...style }}></div>
            </div>
        );
    }
}

ProgressBar.propTypes = {
    loadComplete: PropTypes.bool,
    loadFailed: PropTypes.bool,
};

ProgressBar.defaultProps = {
    loadComplete: false,
    loadFailed: false,
};

export default ProgressBar;
const getNewWidth = (input) => 100 - 20 * Math.exp(DecayRate * input);
const FinalWidth = 100;
const DecayRate = -0.05;
