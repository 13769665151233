import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { EMPTY_STRING } from "../../../../constants";
import eyeOpen from "../../../../assets/eye-outline.svg";
import eyeClosed from "../../../../assets/eye-outline-slash.svg";
import { themes } from "../../../__shared__/EditButton/constants";
import EditButton from "../../../__shared__/EditButton";

export default class SegmentationListItem extends Component {
    getEditPencil(isEditable) {
        if (isEditable) {
            return (
                <EditButton
                    id="seg-edit-btn"
                    alt="edit segmentation button"
                    theme={themes.LIGHT}
                    className={
                        "segmentation-list-item-edit-pencil unselectable"
                    }
                    onClick={() => {
                        this.props.onEditClick(this.props.name);
                    }}
                />
            );
        }
    }

    getHideIcon(isHideable) {
        if (isHideable) {
            let eyeSrc;
            if (this.props.isHidden) {
                eyeSrc = eyeClosed;
            } else {
                eyeSrc = eyeOpen;
            }

            return (
                <div
                    className={"segmentation-list-item-eye-toggle unselectable"}
                >
                    <img
                        id="seg-vis-toggle"
                        src={eyeSrc}
                        alt="segmentation visibility toggle"
                        onClick={() => {
                            this.props.onHideClick(this.props.name);
                        }}
                    />
                </div>
            );
        }
    }

    render() {
        let darkStyle = this.props.dark ? "dark" : "light";
        return (
            <div className={`segmentation-list-item ${darkStyle}`}>
                <div
                    className={`segmentation-list-item-color-indicator`}
                    style={{ backgroundColor: this.props.color }}
                ></div>
                <p className={`segmentation-list-item-text display-16`}>
                    {this.props.text}
                </p>
                {this.getEditPencil(this.props.isEditable)}
                {this.getHideIcon(this.props.isHideable)}
            </div>
        );
    }
}

SegmentationListItem.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    dark: PropTypes.bool,
    text: PropTypes.string,
    isEditable: PropTypes.bool,
    isHideable: PropTypes.bool,
    isHidden: PropTypes.bool,
    onEditClick: PropTypes.func,
    onHideClick: PropTypes.func,
};

SegmentationListItem.defaultProps = {
    color: EMPTY_STRING,
    dark: false,
    text: EMPTY_STRING,
    isEditable: false,
    isHideable: false,
    isHidden: false,
};
