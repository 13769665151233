import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import eyeOpen from "../../../../assets/eye-outline.svg";
import eyeClosed from "../../../../assets/eye-outline-slash.svg";
import trashcan from "../../../../assets/trash_can.svg";
import { SwatchesPicker } from "react-color";
import { COLOR_BLIND_SAFE_COLORS } from "../../../../constants";
import EditButton from "../../../__shared__/EditButton";
import { themes } from "../../../__shared__/EditButton/constants";

const ACTION_BUTTON_NAMES = {
    VIEW: "VIEW",
    EDIT: "EDIT",
    DELETE: "DELETE",
    SELECT: "SELECT",
    COLOR: "COLOR",
};

export default class AblationListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: "#ffffff",
            showColorPicker: false,
            colorModalOpen: false,
        };

        this.handleColorChange = this.handleColorChange.bind(this);
        this.onColorModalClick = this.onColorModalClick.bind(this);
        this.getColorModal = this.getColorModal.bind(this);
    }

    componentDidMount() {
        this.setState({ color: this.props.color });
    }

    handleClick(e, actionButtonName) {
        e.stopPropagation();

        switch (actionButtonName) {
            case ACTION_BUTTON_NAMES.SELECT:
                break;
            case ACTION_BUTTON_NAMES.VIEW:
                this.props.hideTargetHandler(this.props.targetID);
                break;
            case ACTION_BUTTON_NAMES.EDIT:
                this.props.editTargetHandler(
                    this.props.targetID,
                    this.props.targetIndex
                );
                break;
            case ACTION_BUTTON_NAMES.DELETE:
                this.props.deleteTargetHandler(this.props.targetID);
                break;
            default:
                break;
        }
    }

    handleColorChange(color) {
        this.props.changeTargetColorHandler(this.props.targetID, color.hex);
        this.setState({ color: color.hex });
    }

    onColorModalClick() {
        this.setState({ colorModalOpen: !this.state.colorModalOpen });
    }

    getColorModal() {
        if (this.state.colorModalOpen) {
            return (
                <div className={`swatches-cntr`}>
                    <SwatchesPicker
                        height={100}
                        width={175}
                        colors={COLOR_BLIND_SAFE_COLORS}
                        onChange={this.handleColorChange}
                    />
                </div>
            );
        }
    }

    render() {
        let selected = this.props.selected ? "selected" : "unselected";
        let boxColor = this.state.color;
        let eye = this.props.hideTarget ? eyeOpen : eyeClosed;
        let eyeD = this.props.hideTarget
            ? "hide-tgt-eye-img"
            : "show-tgt-eye-img";
        let dark = this.props.targetIndex % 2 === 0 ? "light" : "dark";

        return (
            <div
                className={`target-list-item ${dark} ${selected}`}
                style={this.props.style}
                onClick={(e) => this.handleClick(e, ACTION_BUTTON_NAMES.SELECT)}
            >
                <div className={"target-list-item-contents"}>
                    <div
                        className={`target-item-color-indicator`}
                        style={{ backgroundColor: boxColor }}
                    />
                    <div className={`target-item-title-txt-cntr`}>
                        <div className={`target-item-title-txt display-16`}>
                            {`Tool Site ${this.props.targetIndex + 1}`}
                        </div>
                    </div>
                    <div className={`target-item-buttons-cntr`}>
                        <img
                            src={eye}
                            alt={"Toggle"}
                            id={eyeD}
                            onClick={(e) =>
                                this.handleClick(e, ACTION_BUTTON_NAMES.VIEW)
                            }
                        />
                        <EditButton
                            alt={"Edit"}
                            id={"target-pencil-icon"}
                            theme={themes.LIGHT}
                            onClick={(e) =>
                                this.handleClick(e, ACTION_BUTTON_NAMES.EDIT)
                            }
                        />
                        <img
                            src={trashcan}
                            alt={"Delete"}
                            id={"target-trash-icon"}
                            onClick={(e) =>
                                this.handleClick(e, ACTION_BUTTON_NAMES.DELETE)
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

AblationListItem.propTypes = {
    targetID: PropTypes.string,
    changeTargetColorHandler: PropTypes.func,
    editTargetHandler: PropTypes.func,
    deleteTargetHandler: PropTypes.func,
    hideTargetHandler: PropTypes.func,
    targetIndex: PropTypes.number,
    selected: PropTypes.bool,
    color: PropTypes.string,
    style: PropTypes.object,
    hideTarget: PropTypes.bool,
};
