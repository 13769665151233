import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setErrorState } from "../../../redux/error_banner/actions";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";
import "./styles.css";

class ErrorBanner extends React.Component {
    dismissError() {
        this.props.setErrorState(false, EMPTY_STRING);
    }

    render() {
        if (this.props.hasError) {
            return (
                <div className={"error-banner"}>
                    <div className={"error-text"}>
                        ERROR: {this.props.errorText}
                    </div>
                    <button
                        id="error-banner-close-btn"
                        className={"dismiss-error-button click-outside-ignore"}
                        onClick={() => this.dismissError()}
                    >
                        X
                    </button>
                </div>
            );
        }
        return React.Fragment;
    }
}

ErrorBanner.propTypes = {
    hasError: PropTypes.bool,
    errorText: PropTypes.string,
    setErrorState: PropTypes.func,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        hasError: state.ErrorBannerReducer.hasError,
        errorText: state.ErrorBannerReducer.errorText,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setErrorState: (hasError, errorText) =>
            dispatch(setErrorState(hasError, errorText)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ErrorBanner)
);
