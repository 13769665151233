import React, { Component } from "react";
import "./styles.css";
import PathReportViewer from "../PathReportViewer";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const maxZoom = 3.0;
const zoomStep = 0.25;
const minZoom = 1.0;

class PathReportViewerContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: null,
            isHovering: false,
            zoom: minZoom,
            currentPage: 1,
            numPages: null,
            prevY: 0,
        };
        this.setDivSize = this.setDivSize.bind(this);
        this.handleMouseHover = this.handleMouseHover.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleZoom = this.handleZoom.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.setNumPages = this.setNumPages.bind(this);
        this.getPdfWrapperElements = this.getPdfWrapperElements.bind(this);
        this.setLastVisiblePage = this.setLastVisiblePage.bind(this);
        this.setFirstVisiblePage = this.setFirstVisiblePage.bind(this);

        this.mouseDown = false;
        this.pageLeft = 0;
    }

    componentDidMount() {
        this.setDivSize();
        window.addEventListener("resize", this.setDivSize);
        window.addEventListener("mousemove", this.handleMouseMove);
        window.addEventListener("mouseup", this.handleMouseUp);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setDivSize);
        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("mouseup", this.handleMouseUp);
    }

    handleMouseHover(entryState) {
        this.setState({
            isHovering: entryState,
        });
    }

    handleMouseDown(e) {
        if (!e) {
            return;
        }

        this.mouseDown = true;
    }

    handleMouseMove(e) {
        if (!e || !this.mouseDown || !this.pdfWrapper) {
            return;
        }

        let minLeft = -100 * (this.state.zoom - 1);
        let maxLeft = 0;
        let panSpeed = 0.05;
        let pdfPages = this.getPdfWrapperElements("react-pdf__Page");

        if (pdfPages) {
            Array.from(pdfPages).forEach((page) => {
                this.pageLeft = this.pageLeft + panSpeed * e.movementX;
                this.pageLeft =
                    this.pageLeft >= minLeft ? this.pageLeft : minLeft;
                this.pageLeft =
                    this.pageLeft <= maxLeft ? this.pageLeft : maxLeft;

                page.style.left = this.pageLeft + "%";
            });
        }
    }

    handleMouseUp(e) {
        if (!e) {
            return;
        }

        this.mouseDown = false;
    }

    getPdfWrapperElements(className) {
        if (!this.pdfWrapper) {
            return;
        }

        return this.pdfWrapper.getElementsByClassName(className);
    }

    setNumPages() {
        let pdfPages = this.getPdfWrapperElements("react-pdf__Page");

        if (pdfPages && pdfPages.length > 0) {
            this.setState({
                numPages: pdfPages.length,
            });
        }
    }

    setFirstVisiblePage(y, margin, pdfPages) {
        let firstVisiblePage = this.state.currentPage;

        if (y < this.state.prevY && this.state.currentPage !== 1) {
            let prevPageIndex = this.state.currentPage - 2;

            if (
                pdfPages[parseInt(prevPageIndex)].getBoundingClientRect().top <=
                    0 &&
                pdfPages[parseInt(prevPageIndex)].getBoundingClientRect()
                    .bottom +
                    margin >
                    0
            ) {
                firstVisiblePage = prevPageIndex + 1;
            }
        }

        return firstVisiblePage;
    }

    setLastVisiblePage(y, viewerHeight, margin, pdfPages) {
        let lastVisiblePage = this.state.currentPage;

        if (
            y > this.state.prevY &&
            this.state.currentPage + 1 <= this.state.numPages
        ) {
            let nextPageIndex = this.state.currentPage;
            if (
                pdfPages[parseInt(nextPageIndex)].getBoundingClientRect()
                    .bottom +
                    margin >=
                    viewerHeight &&
                pdfPages[parseInt(nextPageIndex)].getBoundingClientRect().top <
                    viewerHeight &&
                pdfPages[parseInt(nextPageIndex)].getBoundingClientRect().top >
                    0
            ) {
                lastVisiblePage = nextPageIndex + 1;
            }
        }

        return lastVisiblePage;
    }

    getIntersection(page_top, page_bottom, viewerHeight) {
        let intersection;

        if (page_top < 0) {
            intersection = page_bottom / viewerHeight;
        } else {
            intersection = (viewerHeight - page_top) / viewerHeight;
        }

        return intersection;
    }

    handleScroll() {
        let pdfPages = this.getPdfWrapperElements("react-pdf__Page");

        if (!pdfPages || pdfPages.length === 0) {
            return;
        }

        let y = this.pdfWrapper.scrollTop;
        let viewerHeight = this.pdfWrapper.offsetHeight;
        let margin = 10;
        let firstVisiblePage = this.setFirstVisiblePage(y, margin, pdfPages);
        let lastVisiblePage = this.setLastVisiblePage(
            y,
            viewerHeight,
            margin,
            pdfPages
        );

        this.setState({
            prevY: y,
        });

        if (firstVisiblePage === lastVisiblePage) {
            return;
        }

        let mostVisiblePage = this.state.currentPage;
        let maxIntersection = 0;

        for (let i = firstVisiblePage; i <= lastVisiblePage; i++) {
            let page_bottom =
                pdfPages[i - 1].getBoundingClientRect().bottom + margin;
            let page_top = pdfPages[i - 1].getBoundingClientRect().top;
            let intersection = this.getIntersection(
                page_top,
                page_bottom,
                viewerHeight
            );

            if (intersection > maxIntersection) {
                maxIntersection = intersection;
                mostVisiblePage = i;
            }
        }

        this.setState({
            currentPage: mostVisiblePage,
        });
    }

    handleZoom(zoomOption) {
        if (!this.pdfWrapper) {
            return;
        }

        let newZoom = this.state.zoom;

        if (zoomOption === pdfZoomOptions.ZOOM_IN) {
            newZoom =
                newZoom + zoomStep <= maxZoom ? newZoom + zoomStep : maxZoom;
        } else {
            newZoom =
                newZoom - zoomStep >= minZoom ? newZoom - zoomStep : minZoom;
        }

        if (newZoom !== this.state.zoom) {
            this.setState({
                prevY: 0,
                currentPage: 1,
            });
        }

        this.setState({
            zoom: newZoom,
        });
    }

    setDivSize = () => {
        this.setState({ width: this.pdfWrapper.getBoundingClientRect().width });
    };

    render() {
        let viewer;
        if (this.state.numPages == null || this.state.numPages === 0) {
            this.setNumPages();
        }

        if (
            this.props.pathReportByteArray &&
            this.props.pathReportByteArray.length > 1
        ) {
            viewer = (
                <PathReportViewer
                    setPdfLoaded={this.props.setPdfLoaded}
                    bytes={this.props.pathReportByteArray}
                    width={this.state.width}
                    scale={this.state.zoom}
                />
            );
        }

        let hovered = this.state.isHovering ? "visible" : "invisible";

        return (
            <div
                id={this.props.id}
                className={"path-report-viewer-container"}
                style={this.props.style}
                onMouseEnter={() => {
                    this.handleMouseHover(true);
                }}
                onMouseLeave={() => {
                    this.handleMouseHover(false);
                }}
                onScroll={() => {
                    this.handleScroll();
                }}
                onMouseDown={this.handleMouseDown}
                ref={(ref) => (this.pdfWrapper = ref)}
            >
                <div className={`pdf-tool-bar ${hovered}`}>
                    <div className={"pdf-tool-bar-dummy"}></div>
                    <div className={"pdf-tool-bar-core"}>
                        <p
                            id={"pdf-zoom-out-btn"}
                            className={"display-28"}
                            onClick={() => {
                                this.handleZoom(pdfZoomOptions.ZOOM_OUT);
                            }}
                        >
                            -
                        </p>
                        <p className={"pdf-tool-bar-guardrails display-14"}>
                            |
                        </p>
                        <p
                            className={"display-26"}
                            style={{ color: "#FFFFFF" }}
                        >
                            {this.state.zoom * 100}%
                        </p>
                        <p className={"pdf-tool-bar-guardrails display-14"}>
                            |
                        </p>
                        <p
                            id={"pdf-zoom-in-btn"}
                            className={"display-28"}
                            onClick={() => {
                                this.handleZoom(pdfZoomOptions.ZOOM_IN);
                            }}
                        >
                            +
                        </p>
                    </div>
                    <div className={"pdf-tool-bar-page"}>
                        <p
                            className={"display-26"}
                            style={{ color: "#FFFFFF" }}
                        >
                            {this.state.currentPage}/{this.state.numPages}
                        </p>
                    </div>
                </div>
                {viewer}
            </div>
        );
    }
}

PathReportViewerContainer.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    pathReportByteArray: PropTypes.array,
    setPdfLoaded: PropTypes.func,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        pathReportByteArray: state.CreatePlanBiopsyReducer.pathReportByteArray,
    };
};

export default connect(mapStateToProps)(PathReportViewerContainer);

export const pdfZoomOptions = {
    ZOOM_IN: "zoom-in",
    ZOOM_OUT: "zoom-out",
};
