import {
    AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
    EMPTY_STRING,
    AVENDA_DEFAULT_ABLATION_PROFILE_USER_UUID,
} from "../../../constants";
import {
    getAblationProfileWithVolumes,
    getAblationVolumeMeshURI,
} from "../../CreateAblationProfile/helpers";
import TargetProfileTemplates from "../../__shared__/Viewers/Structures/Targets/TargetProfileTemplates";
import { multiUrlMeshLoad } from "../../__shared__/Viewers/helpers";

export async function setAblationProfileHelper(scope, uuid) {
    if (!uuid) {
        uuid = AVENDA_DEFAULT_ABLATION_PROFILE_UUID;
    }

    let ablationProfile;
    if (
        uuid !== AVENDA_DEFAULT_ABLATION_PROFILE_UUID &&
        uuid !== EMPTY_STRING
    ) {
        ablationProfile = await _profileLoadHelper(scope, uuid, {
            userUuid: scope.props.useruuid,
            authToken: scope.props.authToken,
            ablationProfileId: uuid,
        });
    } else {
        ablationProfile = await _profileLoadHelper(scope, uuid, {
            authToken: scope.props.authToken,
            userUuid: AVENDA_DEFAULT_ABLATION_PROFILE_USER_UUID,
            ablationProfileId: AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
        });
    }

    return ablationProfile;
}

async function _profileLoadHelper(scope, uuid, input) {
    let selectedProfile;
    let numVolumes;
    let volumeStruct = {};
    let volumeMeshUrls = {};

    let ablationProfile = await getAblationProfileWithVolumes(input);

    selectedProfile = new TargetProfileTemplates(uuid, ablationProfile.volumes);

    if (ablationProfile.volumes) {
        selectedProfile.volumesDict = volumeStruct;

        numVolumes = ablationProfile.volumes.length;

        for (let volume of ablationProfile.volumes) {
            let ablationMeshInput = {
                userUuid: input.userUuid,
                authToken: input.authToken,
                ablationProfileId: volume.ablationProfileUUID,
                volumeUuid: volume.ablationVolumeUUID,
            };

            let ablationVolumeMeshURIResponse = await getAblationVolumeMeshURI(
                ablationMeshInput
            );

            volumeMeshUrls[
                ablationVolumeMeshURIResponse.ablationVolume.ablationVolumeUUID
            ] = ablationVolumeMeshURIResponse.downloadUri;

            if (numVolumes === Object.values(volumeMeshUrls).length) {
                await _getAblationVolumes(
                    scope,
                    volumeMeshUrls,
                    selectedProfile
                );
            }
        }
    }

    return ablationProfile;
}

async function _getAblationVolumes(scope, volumeMeshUrls, selectedProfile) {
    let geometryStruct = await multiUrlMeshLoad(volumeMeshUrls);
    if (geometryStruct) {
        selectedProfile.volumesDict = geometryStruct;

        let targetSetController;
        if (scope.targetSetController) {
            targetSetController = scope.targetSetController;
        } else if (scope.props.targetSetController) {
            targetSetController = scope.props.targetSetController;
        }

        targetSetController.ablationProfileTemplate = selectedProfile;
    }
}
