import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles.css";

export default class LoginForgotPassword extends Component {
    render() {
        return (
            <div className={"login-forgot-password"}>
                <Link
                    to={"/forgot_pw"}
                    id="forgot-pwd-btn"
                    className="no-underline"
                >
                    <p className={"display-16"}>Forgot Password?</p>
                </Link>
            </div>
        );
    }
}
