import StructureModel from "./StructureModel";

export default class ProstateModel extends StructureModel {
    get prostateAxes() {
        return this._prostateAxes;
    }

    initProstateAxes(prostateAxes) {
        if (!this._meshViewer) {
            return;
        }

        this._prostateAxes = prostateAxes;
        this._prostateAxes.meshViewer = this._meshViewer;
        this._prostateAxes.initializeView(this);
    }
}
