import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import backArrow from "../../../../assets/back_arrow.svg";
import TargetSetController from "../../../__shared__/Viewers/Structures/Targets/TargetSetController";
import manualPlacementCartoon1 from "../../../../assets/manual_target_placement_cartoon_1.svg";
import manualPlacementCartoon2 from "../../../../assets/manual_target_placement_cartoon_2.svg";
import { defaultAblationVolumeSize } from "../../../__shared__/Viewers/Structures/Targets/consts";
import { getIconForAblationVolumePrimitiveType } from "../../helpers";

class AblationSiteManualPlacementPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedVolume: defaultAblationVolumeSize.LARGE,
        };

        this.onClose = this.onClose.bind(this);
        this.getVolumeList = this.getVolumeList.bind(this);
        this.setAblationVolumeMesh = this.setAblationVolumeMesh.bind(this);
    }

    componentDidMount() {
        this.props.targetSetController.guidanceMode = true;
        if (
            this.props.ablationProfile &&
            this.props.ablationProfile.volumes.length > 0
        ) {
            this.props.targetSetController.ablationProfileVolume =
                this.props.ablationProfile.volumes[0].ablationVolumeUUID;
            this.setState({
                selectedVolume:
                    this.props.ablationProfile.volumes[0].ablationVolumeUUID,
            });
        }
    }

    componentWillUnmount() {
        this.props.targetSetController.guidanceMode = false;
    }

    onClose(e) {
        e.stopPropagation();
        this.props.onClose();
    }

    setAblationVolumeMesh(uuid) {
        this.props.targetSetController.ablationProfileVolume = uuid;

        this.setState({
            selectedVolume: uuid,
        });
    }

    getVolumeList(ablationProfile) {
        let listItems = [];

        if (ablationProfile) {
            ablationProfile.volumes.forEach((ablationVolume, idx) => {
                let shade = idx % 2 === 0 ? "light" : "dark";

                if (
                    this.state.selectedVolume ===
                    ablationVolume.ablationVolumeUUID
                ) {
                    shade = "selected";
                }

                let volumeIcon = getIconForAblationVolumePrimitiveType(
                    ablationVolume.meshPrimitiveType
                );

                listItems.push(
                    <div
                        className={`volume-list-item ${shade}`}
                        onClick={() => {
                            this.setAblationVolumeMesh(
                                ablationVolume.ablationVolumeUUID
                            );
                        }}
                    >
                        <img
                            src={volumeIcon}
                            className={"volume-list-icon"}
                            alt={"Volume img"}
                        />
                        <p className={"volume-list-item-text display-14"}>
                            {ablationVolume.name}
                        </p>
                    </div>
                );
            });
        }

        return listItems;
    }

    render() {
        return (
            <div
                className={"manually-place-targets-workflow"}
                style={this.props.style}
            >
                <div className={"manually-place-targets-workflow-header"}>
                    <div
                        className={"manually-place-targets-back-btn"}
                        onClick={this.onClose}
                    >
                        <img src={backArrow} alt={"<-"} />
                    </div>
                    <p className={"display-20"}>Manually Add Tool Site</p>
                </div>
                <div className={"ablation-volume-cntr"}>
                    <div className={"active-profile-label"}>
                        <p className={"display-14"}>
                            <text className={"active-profile-label"}>
                                Active Tool Profile:{" "}
                                {this.props.ablationProfile &&
                                this.props.ablationProfile.name
                                    ? this.props.ablationProfile.name
                                    : ""}
                            </text>
                        </p>
                    </div>
                    <div className={"profile-volume-list-cntr"}>
                        <div className={"profile-volume-list"}>
                            {this.getVolumeList(this.props.ablationProfile)}
                        </div>
                    </div>
                </div>
                <div className={"manually-place-targets-guide-container"}>
                    <div className={"manually-place-targets-guide"}>
                        <p className={"display-14 target-place-guide-text"}>
                            A guide will follow your cursor over the 2D MRI.
                            <br />
                            Click with your cursor to confirm placement.
                        </p>
                        <img
                            className={"manual-target-cartoon"}
                            src={manualPlacementCartoon1}
                            alt={""}
                        />
                        <p className={"display-14 target-place-guide-text"}>
                            Click elsewhere to place additional tool volumes.
                        </p>
                        <img
                            className={"manual-target-cartoon"}
                            src={manualPlacementCartoon2}
                            alt={""}
                        />
                        <p className={"display-14 target-place-guide-text"}>
                            When you are done, click the back arrow to return to
                            the tool placement site list.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

AblationSiteManualPlacementPanel.propTypes = {
    targetSetController: PropTypes.instanceOf(TargetSetController),
    onClose: PropTypes.func,
    style: PropTypes.object,
    ablationProfile: PropTypes.object,
};

export default AblationSiteManualPlacementPanel;
