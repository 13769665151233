import React, { Component } from "react";
import "./styles.css";
import { stepPath, clientErrorMessages } from "../../../constants";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";
import DecisionAlertCard from "../../__shared__/DecisionAlertCard";
import { biopsyAlertCardProps } from "../constants";
import { deleteFileFromCloud } from "../../../helpers/backend_api";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import { getValidCEMCores } from "../helpers";
import { setBiopsyError } from "../actions";
import { connect } from "react-redux";
import { rawCPMFileName } from "../../CreateTPlan_4_1_GenerateCPM/constants";

class BiopsyCompleteAlertHandler extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stateMachine: biopsyAlertStateMachine.DEFAULT,
        };
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.bxError) {
            // TODO using props to update local state is an anti-pattern
            if (this.state.stateMachine !== "BX_HAS_ERROR") {
                this.setState({
                    stateMachine: biopsyAlertStateMachine.BX_HAS_ERROR,
                });
            }
        }
        return true;
    }

    deleteCEM = async () => {
        await deleteFileFromCloud({
            visituuid: this.props.currentTreatmentPlan.MetaData.VisitUUID,
            patientuuid:
                this.props.currentTreatmentPlan.TreatmentPlan.PatientData
                    .PatientUUID,
            token: sessionStorage.authToken,
            fileURI: this.getCPMURIfromPlan(rawCPMFileName),
        }).catch(() => {
            this.props.setLHMError(true, clientErrorMessages.CEM_DELETE_FAILED);
        });

        let plan = zeroOutCEMTreatmentPlanFields(
            this.props.currentTreatmentPlan
        );
        this.props.setCurrentTreatmentPlan(plan);
    };

    getCPMURIfromPlan(suffix) {
        let scanId = this.props.currentTreatmentPlan.MetaData.MasterScanID;
        let cpmURI = `${this.props.currentTreatmentPlan.TreatmentPlan.PatientData.PatientUUID}/${this.props.currentTreatmentPlan.MetaData.VisitUUID}/${scanId}/3D_DATA/CPM/${suffix}`;
        return cpmURI;
    }

    getYesBtnHandler = () => {
        switch (this.state.stateMachine) {
            case biopsyAlertStateMachine.CEM_NEEDS_DELETE:
                this.yesBtnHandlerCEMDelete();
                break;
            case biopsyAlertStateMachine.CEM_NEEDS_UPDATE:
                this.yesBtnHandlerCEMUpdate();
                break;
            case biopsyAlertStateMachine.BX_HAS_ERROR:
                this.yesBtnHandlerBxError();
                break;
            default:
                this.yesBtnHandlerDefault();
        }
    };

    yesBtnHandlerDefault = () => {
        let hasCEM = TreatmentPlan.hasCancerEstimationMap(
            this.props.currentTreatmentPlan
        );

        let validCEMCores = getValidCEMCores(
            TreatmentPlan.getBiopsyCores(
                this.props.currentTreatmentPlan.TreatmentPlan
            )
        );

        if (hasCEM && validCEMCores) {
            this.setState({
                stateMachine: biopsyAlertStateMachine.CEM_NEEDS_UPDATE,
            });
        } else if (hasCEM && !validCEMCores) {
            this.setState({
                stateMachine: biopsyAlertStateMachine.CEM_NEEDS_DELETE,
            });
        } else if (!hasCEM && validCEMCores) {
            // Exit State Machine to Step 4.1
            this.props.step3CompleteCallback(stepPath.STEP4_1);
        } else {
            // Exit State Machine to Step 4
            this.props.step3CompleteCallback(stepPath.STEP4);
        }
    };

    yesBtnHandlerBxError = () => {
        this.yesBtnHandlerDefault();
    };

    yesBtnHandlerCEMUpdate = () => {
        // Exit State Machine to Step 4.1
        this.deleteCEM().then(() => {
            this.props.step3CompleteCallback(stepPath.STEP4_1);
        });
    };

    yesBtnHandlerCEMDelete = () => {
        // Exit State Machine to Step 4
        this.deleteCEM().then(() => {
            this.props.step3CompleteCallback(stepPath.STEP4);
        });
    };

    getNoBtnHandler = () => {
        switch (this.state.stateMachine) {
            case biopsyAlertStateMachine.CEM_NEEDS_DELETE:
                this.noBtnHandlerCEMDelete();
                break;
            case biopsyAlertStateMachine.CEM_NEEDS_UPDATE:
                this.noBtnHandlerCEMUpdate();
                break;
            case biopsyAlertStateMachine.BX_HAS_ERROR:
                this.noBtnHandlerBxError();
                break;
            default:
                this.noBtnHandlerDefault();
        }
    };

    noBtnHandlerDefault = () => {
        // Exit State Machine to Step 3
        this.props.setAlertState(false);
    };

    noBtnHandlerBxError = () => {
        this.props.setBiopsyError("UNDEFINED ERROR");
        this.props.setAlertState(false);
    };

    noBtnHandlerCEMDelete = () => {
        // Exit State Machine to Step 3
        this.props.setAlertState(false);
    };

    noBtnHandlerCEMUpdate = () => {
        // Exit State Machine to Step 4
        this.props.step3CompleteCallback(stepPath.STEP4);
    };

    render() {
        let decisionCardProps = getCardContent(this.state.stateMachine);

        return (
            <div>
                <DecisionAlertCard
                    id={"review-biopsy-data-alert"}
                    noBtnCallback={() => {
                        this.getNoBtnHandler();
                    }}
                    noBtnTxt={decisionCardProps.noTxt}
                    yesBtnTxt={decisionCardProps.yesTxt}
                    yesBtnCallback={() => {
                        this.getYesBtnHandler();
                    }}
                >
                    <div className={"review-biopsy-data-alert-card-header"}>
                        <p className={"display-26 black-text"}>
                            {decisionCardProps.Title}
                        </p>
                    </div>
                    <div className={"review-biopsy-data-alert-card-body"}>
                        <div className={"review-biopsy-data-alert-row"}>
                            <p className={"display-16 black-text"}>
                                {decisionCardProps.Content}
                            </p>
                        </div>
                    </div>
                </DecisionAlertCard>
                <div id={"review-biopsy-data-alert-overlay"} />
            </div>
        );
    }
}

BiopsyCompleteAlertHandler.propTypes = {
    hasCEM: PropTypes.bool,
    step3CompleteCallback: PropTypes.func,
    setAlertState: PropTypes.func,
    setLHMError: PropTypes.func,
    currentTreatmentPlan: PropTypes.object,
    setCurrentTreatmentPlan: PropTypes.func,
    bxError: PropTypes.string,
    setBiopsyError: PropTypes.func,
};

BiopsyCompleteAlertHandler.defaultProps = {
    hasCEM: false,
    step3CompleteCallback: EMPTY_STRING,
    setAlertState: EMPTY_STRING,
    setLHMError: EMPTY_STRING,
    currentTreatmentPlan: {},
    setCurrentTreatmentPlan: () => {},
    bxError: EMPTY_STRING,
};

const biopsyAlertStateMachine = {
    DEFAULT: "DEFAULT",
    BX_HAS_ERROR: "BX_HAS_ERROR",
    CEM_NEEDS_UPDATE: "CEM_NEEDS_UPDATE",
    CEM_NEEDS_DELETE: "CEM_NEEDS_DELETE",
};

const getCardContent = (state) => {
    switch (state) {
        case biopsyAlertStateMachine.CEM_NEEDS_DELETE:
            return biopsyAlertCardProps.CEM_NEEDS_DELETE;
        case biopsyAlertStateMachine.CEM_NEEDS_UPDATE:
            return biopsyAlertCardProps.CEM_NEEDS_UPDATE;
        case biopsyAlertStateMachine.BX_HAS_ERROR:
            return biopsyAlertCardProps.BX_HAS_ERROR;
        default:
            return biopsyAlertCardProps.DEFAULT;
    }
};

const zeroOutCEMTreatmentPlanFields = (plan) => {
    plan.SavedData.CPMURI = EMPTY_STRING;
    plan.SavedData.CPMForLUTDisplayURI = EMPTY_STRING;
    plan.SavedData.LesionConfirmed = false;
    plan.SavedData.UserSelectedMarksScore = 0.0;
    plan.SavedData.OptimalCPMThreshold = 0.0;
    plan.SavedData.UserSelectedCPMThreshold = 0.0;
    plan.SavedData.OptimalMarksScore = 0.0;
    plan.SavedData.UserSelectedMarksScore = 0.0;
    plan.SavedData.OptimalLesionCoverageOfProstate = 0.0;
    plan.SavedData.UserSelectedLesionCoverageOfProstate = 0.0;
    plan.SavedData.OptimalLesionVolume = 0.0;
    plan.SavedData.LesionConfirmed = false;
    plan.SavedData.AblationCoverageOfLesion = 0.0;
    plan.SavedData.IsPatientFTEligible = false;
    plan.SavedData.CPMURI = EMPTY_STRING;
    plan.SavedData.CPMForLUTDisplayURI = EMPTY_STRING;
    plan.SavedData.CPMThresholdCurve = [];
    plan.SavedData.MarksScoreCurve = [];
    plan.SavedData.ProstateCoverageCurve = [];
    plan.SavedData.LesionVolumeCurve = [];
    plan.TreatmentPlan.StructureData.Margins = [];
    return plan;
};

const mapStateToProps = function (state) {
    return {
        bxError: state.CreatePlanBiopsyReducer.bxError,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setBiopsyError: (bxError) => dispatch(setBiopsyError(bxError)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BiopsyCompleteAlertHandler);
