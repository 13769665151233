import React, { Component } from "react";
import "../GenericStepMarkerBar/styles.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import GenericStepMarkerBar from "../GenericStepMarkerBar";

class StepMarkerBar extends Component {
    render() {
        return (
            <GenericStepMarkerBar
                planCreationStepMetaData={this.props.planCreationStepMetaData}
                previousStepsDisabled={this.props.previousStepsDisabled}
            ></GenericStepMarkerBar>
        );
    }
}

StepMarkerBar.propTypes = {
    planCreationStepMetaData: PropTypes.array,
    previousStepsDisabled: PropTypes.bool,
};

StepMarkerBar.defaultProps = {
    planCreationStepMetaData: [],
    previousStepsDisabled: false,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        planCreationStepMetaData:
            state.CreatePlanReducer.planCreationStepMetaData,
    };
};

export default connect(mapStateToProps)(StepMarkerBar);
