import React, { Component } from "react";
import SignupForm from "../Form";
import AvendaLogo from "../../__shared__/AvendaLogo";
import "./styles.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class SignUpPage extends Component {
    render() {
        return (
            <div className={"signup-screen"}>
                <AvendaLogo id={"signup-screen-avenda-logo"} />
                <SignupForm />
            </div>
        );
    }
}

SignUpPage.propTypes = {
    error: PropTypes.object,
};

SignUpPage.defaultProps = {
    error: {},
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        error: state.SignupReducer.error,
    };
};

export default connect(mapStateToProps)(SignUpPage);
