import {
    SET_STEP_ACTIVE,
    SET_STEP_COMPLETE,
    SET_PATH_REPORT_UPLOADED,
    SET_BIOPSY_DATA_UPLOADED,
    SET_PATH_REPORT_THUMBNAIL_URL,
    SET_TEMPLATE_UPLOADED,
    SET_TEMPLATE_THUMBNAIL_URL,
    SET_MRI_THUMBNAIL_URL,
    SET_BIOPSY_DATA_THUMBNAIL_URL,
    SET_CURRENT_PLAN_UUID,
    SET_CURRENT_PATIENT_UUID,
    SET_UPLOAD_STATE,
    SET_UPLOAD_PROGRESS,
    SET_PARSE_PROGRESS,
    SET_PARSE_FAILURE_MESSAGE,
    SET_CURRENT_TREATMENT_PLAN,
    SET_CURRENT_VISIT_UUID,
    RESET_CREATE_PLAN_SHARD,
    SET_PLAN_CREATION_STEP_METADATA,
    SET_FREEHAND_PLAN,
} from "./actions";
import { initialState } from "./init";

export default CreatePlanReducer;

function CreatePlanReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_STEP_ACTIVE:
            return setStepActive(state, action);
        case SET_STEP_COMPLETE:
            return setStepComplete(state, action);
        case SET_PATH_REPORT_UPLOADED:
            return setPathReportUploaded(state, action);
        case SET_TEMPLATE_UPLOADED:
            return setTemplateUploaded(state, action);
        case SET_BIOPSY_DATA_UPLOADED:
            return setBiopsyDataUploaded(state, action);
        case SET_PATH_REPORT_THUMBNAIL_URL:
            return setPathReportThumbnailURL(state, action);
        case SET_TEMPLATE_THUMBNAIL_URL:
            return setTemplateThumbnailURL(state, action);
        case SET_MRI_THUMBNAIL_URL:
            return setMriThumbnailURL(state, action);
        case SET_BIOPSY_DATA_THUMBNAIL_URL:
            return setBiopsyDataThumbnailURL(state, action);
        case SET_CURRENT_PLAN_UUID:
            return setCurrentPlanUUID(state, action);
        case SET_CURRENT_PATIENT_UUID:
            return setCurrentPatientUUID(state, action);
        case SET_CURRENT_VISIT_UUID:
            return setCurrentVisitUUID(state, action);
        case SET_UPLOAD_STATE:
            return setUploadState(state, action);
        case SET_UPLOAD_PROGRESS:
            return setUploadProgress(state, action);
        case SET_PARSE_PROGRESS:
            return setParseProgress(state, action);
        case SET_PARSE_FAILURE_MESSAGE:
            return setParseFailureMessage(state, action);
        case SET_CURRENT_TREATMENT_PLAN:
            return setCurrentTreatmentPlan(state, action);
        case RESET_CREATE_PLAN_SHARD:
            return resetCreatePlanShard(state, action);
        case SET_PLAN_CREATION_STEP_METADATA:
            return setPlanCreationStepMetaData(state, action);
        case SET_FREEHAND_PLAN:
            return setFreehandPlan(state, action);
        default:
            return state;
    }
}

function setStepActive(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.planCreationStepMetaData[action.stepIdx].active = action.isActive;
    return stateCpy;
}

function setStepComplete(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.planCreationStepMetaData[action.stepIdx].complete =
        action.isComplete;
    return stateCpy;
}

function setPathReportUploaded(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.isPathReportUploaded = action.isOn;
    return stateCpy;
}

function setTemplateUploaded(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.isTemplateUploaded = action.isOn;
    return stateCpy;
}

function setBiopsyDataUploaded(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.isBiopsyDataUploaded = action.isOn;
    return stateCpy;
}

function setPathReportThumbnailURL(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.pathReportThumbnailURL = action.url;
    return stateCpy;
}

function setTemplateThumbnailURL(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.templateThumbnailURL = action.url;
    return stateCpy;
}

function setMriThumbnailURL(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.mriThumbnailURL = action.url;
    return stateCpy;
}

function setBiopsyDataThumbnailURL(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.biopsyThumbnail = action.url;
    return stateCpy;
}

function setCurrentPlanUUID(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.planuuid = action.planuuid;
    return stateCpy;
}

function setCurrentPatientUUID(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.patientuuid = action.patientuuid;
    return stateCpy;
}

function setCurrentVisitUUID(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.visituuid = action.visituuid;
    return stateCpy;
}

function setUploadState(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.uploadState[action.idx] = action.upstate;
    return stateCpy;
}

function setUploadProgress(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.uploadProgress[action.idx] = action.prog.toFixed(1);
    return stateCpy;
}

function setParseProgress(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.parseProgress[action.idx] = action.prog.toFixed(1);
    return stateCpy;
}

function setParseFailureMessage(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.parseFailureMessage[action.idx] = action.msg;
    return stateCpy;
}

function setCurrentTreatmentPlan(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.currentTreatmentPlan = action.plan;
    return stateCpy;
}

function resetCreatePlanShard() {
    return initialState;
}

function setPlanCreationStepMetaData(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.planCreationStepMetaData = action.stepMetaData;
    return stateCpy;
}

function setFreehandPlan(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.isFreehandPlan = action.isOn;
    return stateCpy;
}
