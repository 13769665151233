import {
    API_ENDPOINT,
    API_VERSION,
    httpMethods,
    SUCCESS,
    clientErrorMessages,
} from "../../constants";
import { vsprintf } from "sprintf-js";
import { setErrorState } from "../../redux/error_banner/actions";
import { rootStore } from "../../redux/store";

export async function getUploadProfilePictureURL(input) {
    let endpoint = vsprintf(
        "%s/api/%s/user/%s/profilePicture?image_type=%s&image_size=%s",
        [
            API_ENDPOINT,
            API_VERSION,
            input.userUuid,
            input.imageType,
            input.imageSize,
        ]
    );

    return await fetch(endpoint, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            } else {
                throw new Error(
                    clientErrorMessages.PROFILE_PICTURE_UNSUPPORTED_TYPE
                );
            }
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function updatePassword(
    username,
    oldPassword,
    newPassword,
    authToken
) {
    return await fetch(
        vsprintf("%s/api/%s/user/%s/updatePw", [
            API_ENDPOINT,
            API_VERSION,
            username,
        ]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
            mode: "cors",
            body: JSON.stringify({
                username: username,
                oldPassword: oldPassword,
                newPassword: newPassword,
            }),
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            }
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function getAblationProfiles(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/ablationProfiles", [
        API_ENDPOINT,
        API_VERSION,
        input.userUuid,
    ]);

    return await fetch(endpoint, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            } else {
                throw new Error(clientErrorMessages.FAILED_TO_GET_PROFILES);
            }
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function deleteAblationProfile(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/ablationProfile/%s", [
        API_ENDPOINT,
        API_VERSION,
        input.userUuid,
        input.ablationProfileUUID,
    ]);

    return await fetch(endpoint, {
        method: httpMethods.DELETE,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            }
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}
