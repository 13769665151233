export const SET_PATH_REPORT_BYTE_ARRAY = "SET_PATH_REPORT_BYTE_ARRAY";
export const SET_CORE_LOCATIONS = "SET_CORE_LOCATIONS";
export const SET_ACTIVE_CORE = "SET_ACTIVE_CORE";
export const RESET_CREATE_PLAN_BIOPSY_SHARD = "RESET_CREATE_PLAN_BIOPSY_SHARD";
export const SET_BIOPSY_ERROR = "SET_BIO_ERROR";
export const SET_IS_CORE_FOCUSED = "SET_IS_CORE_FOCUSED";
export const SET_IS_ON_MESH_VIEWER_CONTROL = "SET_IS_ON_MESH_VIEWER_CONTROL";

export const setPathReportByteArray = function (bytes) {
    return {
        type: SET_PATH_REPORT_BYTE_ARRAY,
        bytes: bytes,
    };
};

export const setCoreLocations = function (cores) {
    return {
        type: SET_CORE_LOCATIONS,
        cores: cores,
    };
};

export const setActiveCore = function (core) {
    return {
        type: SET_ACTIVE_CORE,
        core: core,
    };
};

export const resetCreatePlanBiopsyShard = function () {
    return {
        type: RESET_CREATE_PLAN_BIOPSY_SHARD,
    };
};

export const setBiopsyError = function (bxError) {
    return {
        type: SET_BIOPSY_ERROR,
        bxError: bxError,
    };
};

export const setIsCoreFocused = function (isCoreFocused) {
    return {
        type: SET_IS_CORE_FOCUSED,
        isCoreFocused: isCoreFocused,
    };
};

export const setIsOnMeshViewerControl = function (isOnMeshViewerControl) {
    return {
        type: SET_IS_ON_MESH_VIEWER_CONTROL,
        isOnMeshViewerControl: isOnMeshViewerControl,
    };
};
