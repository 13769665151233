import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { segmentationDisplayOptions } from "../../constants";
import backArrow from "../../../../assets/back_arrow.svg";
import editCartoon1 from "../../../../assets/edit_seg_cartoon_1.svg";
import editCartoon2 from "../../../../assets/edit_seg_cartoon_2.svg";
import greenCheck from "../../../../assets/circle_green_check.svg";

import { volumeNames } from "../../../../constants";
import MeshEditController from "../../../__shared__/Viewers/Structures/Other/MeshEditController";
import PatientAnatomyModel from "../../../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyModel";
import {
    resetProstateSegmentationEdits,
    saveProstateSegmentationEdits,
} from "../../helpers";
import AHButton from "../../../__shared__/StyleGuide/AHButton";
import DecisionAlertCard from "../../../__shared__/DecisionAlertCard";

class ManualEditingActivePanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editIsSaved: false,
            resetIsSaved: false,
            resetAlertStatus: false,
        };

        this.handleBackArrow = this.handleBackArrow.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.getSaveIcon = this.getSaveIcon.bind(this);

        this.onYesClick = this.onYesClick.bind(this);
        this.onNoClick = this.onNoClick.bind(this);
    }

    componentDidMount() {
        this.props.meshEditController.initializeEditing();
    }

    componentWillUnmount() {
        this.props.meshEditController.cancelEditing();
    }

    handleBackArrow() {
        let activeStructureName =
            this.props.meshEditController.linkedStructure.name;
        if (activeStructureName === volumeNames.MR_PROSTATE_USER_UPLOAD) {
            this.props.patientAnatomyModel.prostateUploaded.visibility = true;
        } else if (activeStructureName === volumeNames.MR_PROSTATE_AI) {
            this.props.patientAnatomyModel.prostateAI.visibility = true;
        }
        this.props.setWorkflowState(segmentationDisplayOptions.SEG_LISTING);
    }

    onYesClick() {
        this.handleReset();
        this.setState({
            resetAlertStatus: false,
        });
    }

    onNoClick() {
        this.setState({
            resetAlertStatus: false,
        });
    }

    handleReset() {
        let plan = this.props.currentTreatmentPlan;
        const setHandleResetState = (plan) => {
            this.props.setCurrentTreatmentPlan(plan);
            this.setState({
                resetIsSaved: true,
                editIsSaved: false,
            });
            this.props.setEditsSavedState(true);
        };
        resetProstateSegmentationEdits(
            this.props.ids,
            plan,
            this.props.meshEditController,
            this.props.patientAnatomyModel,
            setHandleResetState
        );
    }

    handleSave() {
        let plan = this.props.currentTreatmentPlan;

        let saveIds = {
            token: this.props.ids.token,
            useruuid: this.props.ids.useruuid,
            patientuuid: this.props.ids.patientuuid,
            planuuid: this.props.ids.planuuid,
            visituuid: this.props.ids.visituuid,
            scanID: plan.MetaData.MasterScanID,
        };

        const setHandleSaveState = (plan) => {
            this.props.setCurrentTreatmentPlan(plan);
            this.setState({
                resetIsSaved: false,
                editIsSaved: true,
            });
            this.props.setEditsSavedState(true);
        };
        saveProstateSegmentationEdits(
            saveIds,
            plan,
            this.props.meshEditController,
            this.props.patientAnatomyModel,
            setHandleSaveState
        );
    }

    getSaveIcon(isSaved) {
        if (isSaved && this.props.editsSaved) {
            return <img id={"green-saved-check"} src={greenCheck} alt={"X"} />;
        }
    }

    /**
     * ES6 negates the need to bind in the constructor
     * thoughts on this moving forward?
     */
    toggleResetAlertStatus = () => {
        this.setState({
            resetAlertStatus: true,
        });
    };

    renderResetDecisionAlertCard() {
        return (
            <DecisionAlertCard
                id={"review-seg-alert"}
                noBtnCallback={this.onNoClick}
                noBtnTxt={"Cancel"}
                yesBtnTxt={"Reset"}
                yesBtnCallback={this.onYesClick}
            >
                <div className={"review-seg-alert-card-header"}>
                    <p className={"display-26"}>Confirm Reset Changes?</p>
                </div>
                <div className={"review-seg-alert-card-body"}>
                    <p className={"display-16"}>
                        Proceeding will reset any changes made since you last
                        saved.
                    </p>
                </div>
            </DecisionAlertCard>
        );
    }

    render() {
        return (
            <div id={"manual-editing-active-panel"}>
                <div className={"segmentation-edit-header"}>
                    <div
                        className={"segmentation-edit-header-back-btn"}
                        onClick={this.handleBackArrow}
                    >
                        <img src={backArrow} alt={"<"} />
                    </div>
                    <p className={"display-20"}>Edit Segmentation</p>
                </div>
                <div className={"segmentation-mesh-label"}>
                    <p className={"display-14"}>
                        Currently editing: {this.props.meshLabel}
                    </p>
                </div>
                <div className={"segmentation-edit-guide-text-container"}>
                    <p className={"display-14 segmentation-edit-guide-text"}>
                        Use your cursor to edit the segmentation
                        <br />
                        Clicks outside will grow the segmentation.
                    </p>
                    <img
                        className={"edit-seg-cartoon"}
                        src={editCartoon1}
                        alt={""}
                    />
                    <p className={"display-14 segmentation-edit-guide-text"}>
                        Clicks inside will shrink the segmentation.
                    </p>
                    <img
                        className={"edit-seg-cartoon"}
                        src={editCartoon2}
                        alt={""}
                    />
                    <p className={"display-14 segmentation-edit-guide-text"}>
                        Click the Save button below to save your edits. Click
                        Reset to discard your edits.
                    </p>
                </div>
                <div className={"segmentation-edit-done-buttons"}>
                    <div className={"side-by-side-ah-btn-container"}>
                        <AHButton
                            id="edit-seg-reset-btn"
                            className={
                                "segmentation-edit-btn segmentation-edit-reset-btn"
                            }
                            onClick={this.toggleResetAlertStatus}
                            isOutline={true}
                            isSecondary={true}
                            isDynamic={true}
                            size={"medium"}
                            text={"Reset"}
                        >
                            {this.getSaveIcon(this.state.resetIsSaved)}
                        </AHButton>
                    </div>
                    <div className={"side-by-side-ah-btn-container"}>
                        <div className={"float-btn-right"}>
                            <AHButton
                                id="edit-seg-save-btn"
                                className={
                                    "segmentation-edit-btn segmentation-edit-save-btn"
                                }
                                onClick={this.handleSave}
                                isDynamic={true}
                                size={"medium"}
                                text={"Save"}
                            >
                                {this.getSaveIcon(this.state.editIsSaved)}
                            </AHButton>
                        </div>
                    </div>
                </div>
                {this.state.resetAlertStatus &&
                    this.renderResetDecisionAlertCard()}
            </div>
        );
    }
}

ManualEditingActivePanel.propTypes = {
    setWorkflowState: PropTypes.func,
    meshEditController: PropTypes.instanceOf(MeshEditController),
    patientAnatomyModel: PropTypes.instanceOf(PatientAnatomyModel),
    setCurrentTreatmentPlan: PropTypes.func,
    setEditsSavedState: PropTypes.func,
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    visituuid: PropTypes.string,
    patientuuid: PropTypes.string,
    meshLabel: PropTypes.string,
    planuuid: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
    ids: PropTypes.object,
    editsSaved: PropTypes.bool,
};

ManualEditingActivePanel.defaultProps = {
    setWorkflowState: () => {},
};

export default ManualEditingActivePanel;
