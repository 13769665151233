export const sortCategories = {
    NONE: -1,
    NAME: 0,
    ID: 1,
    DATE: 2,
    SEARCH_SCORE: 3,
};

export const EMPTY_STRING = "";
export const N_A = "N.A.";

export const LIST_ITEM_HEIGHT = 74;
export const CONTAINER_HEIGHT = 435;

export const buttonNames = {
    RESUME: "Resume Plan",
    REVIEW: "Review Plan",
};

export const lockedErrorMessage =
    "The plan is currently being worked on by another user. Please try again later.";

export const lockedPlanTooltipMsg = (firstName, lastName, email) =>
    `Plan is currently being worked on by ${firstName} ${lastName} (${email}). Please check back later.`;
