import {
    EMPTY_STRING,
    refFrames,
    WEBSOCKET_API_ENDPOINT,
} from "../../constants";
import * as TreatmentPlan from "../../helpers/tplan/tplan";
import {
    cpmErrorCodes,
    cpmErrorMessages,
    cpmStatusCodes,
    generateCPMLoadStates,
    WEBSOCKET_TIMEOUT_MESSAGE,
    webSocketActions,
} from "./constants";

export function generateCPM() {
    const socket = new WebSocket(WEBSOCKET_API_ENDPOINT);
    let {
        useruuid,
        patientuuid,
        planuuid,
        visituuid,
        authToken: token,
        currentTreatmentPlan,
    } = this.props;
    let plan = currentTreatmentPlan.TreatmentPlan;
    let outputURI = TreatmentPlan.get3DDataFolder(
        currentTreatmentPlan,
        patientuuid,
        visituuid
    );

    socket.onopen = () => {
        socket.send(
            JSON.stringify({
                action: webSocketActions.CPM_WITH_UPDATE_PLAN,
                body: {
                    prostateURI: TreatmentPlan.getStructureInFrame(
                        TreatmentPlan.getCoreStructure(plan),
                        refFrames.MRI
                    ).URI,
                    roiURIs: TreatmentPlan.getROIs(plan).map((r) => {
                        return r.Frame[refFrames.MRI].URI;
                    }),
                    mriURIs: TreatmentPlan.getMRI(plan)[0].Slices.map((s) => {
                        return s.URI;
                    }),
                    bxCores: plan.StructureData.Biopsy,
                    psa: plan.PatientData.PSA,
                    stride: 2,
                    outputURI: outputURI,
                    useruuid: useruuid,
                    patientuuid: patientuuid,
                    planuuid: planuuid,
                    authToken: token,
                },
            })
        );
    };

    socket.onmessage = (event) => {
        let data = JSON.parse(event.data);

        if (data.message === WEBSOCKET_TIMEOUT_MESSAGE) return;

        if (data.body.message && data.body.message === cpmStatusCodes.FAILURE) {
            this.setState({
                cemError: {
                    errorCode: cpmErrorCodes.NETWORK_ERROR,
                    errorDetails: cpmErrorMessages.NETWORK_ERROR,
                },
            });
            if (window.pendo) {
                try {
                    window.pendo.track("CEM generation failed", {
                        cemStatus: cpmStatusCodes.FAILURE,
                        planId: planuuid,
                    });
                } catch {
                    () => {};
                }
            }
            socket.close();
            return;
        }

        if (data.body.payload.status === cpmStatusCodes.CEM_WARNINGS_UPDATED) {
            this.setState({
                loadState: generateCPMLoadStates.CPM_COMPLETE,
            });
            if (window.pendo) {
                try {
                    window.pendo.track("CEM warnings updated", {
                        cemStatus: cpmStatusCodes.CEM_WARNINGS_UPDATED,
                        planId: planuuid,
                    });
                } catch {
                    () => {};
                }
            }
            socket.close();
            return;
        }

        if (data.body.payload.progress) {
            this.setState({
                mapProgressMessages: [
                    ...this.state.mapProgressMessages,
                    data.body.payload.progress,
                ],
                requestUuid: data.body.payload.progress.requestUUID,
            });
        }

        if (
            data.body.payload.progress.errorCode &&
            data.body.payload.progress.errorCode !== EMPTY_STRING
        ) {
            this.setState({
                cemError: {
                    errorCode: data.body.payload.progress.errorCode,
                    errorDetails: data.body.payload.progress.errorDetails,
                    requestUuid: this.state.requestUuid,
                    timestamp: data.body.payload.progress.timestamp,
                },
            });
            if (window.pendo) {
                try {
                    window.pendo.track("CEM generation failed", {
                        cemStatus: cpmStatusCodes.CEM_GENERATION_FAILED,
                        errorCode: data.body.payload.progress.errorCode,
                        errorDetails: data.body.payload.progress.errorDetails,
                        planId: planuuid,
                    });
                } catch {
                    () => {};
                }
            }
            socket.close();
        }
    };

    socket.onclose = () => {
        if (this.state.loadState === generateCPMLoadStates.CPM_COMPLETE) {
            this.generateMargin();
            if (window.pendo) {
                try {
                    window.pendo.track("CEM generation complete", {
                        cemStatus:
                            cpmStatusCodes.CANCER_MAP_GENERATION_COMPLETE,
                        planId: planuuid,
                    });
                } catch {
                    () => {};
                }
            }
        }
    };
}
