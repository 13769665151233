import React, { Component } from "react";
import "./styles.css";
import { specialSelection } from "../../../constants";
import PropTypes from "prop-types";

class ButtonGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIdx: null,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(idx) {
        this.setState({ activeIdx: idx });
    }

    componentDidMount() {
        if (this.props.initialActiveIdx != null) {
            this.setState({ activeIdx: this.props.initialActiveIdx });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.forceReset !== prevProps.forceReset) {
            if (this.props.forcedActiveIdx != null) {
                this.setState({
                    activeIdx: this.props.forcedActiveIdx,
                });
            } else {
                this.setState({
                    activeIdx: specialSelection.NONE_SELECTED,
                });
            }
        }
    }

    render() {
        let scope = this;
        let childrenWithProps = React.Children.map(
            this.props.children,
            function (child, idx) {
                let isActive = false;
                if (idx === scope.state.activeIdx) {
                    isActive = true;
                }

                return React.cloneElement(child, {
                    idx: idx,
                    active: isActive,
                    onClick: scope.handleClick,
                });
            }
        );

        return (
            <div
                id={this.props.id}
                className={"button-group"}
                style={this.props.style}
            >
                {childrenWithProps}
            </div>
        );
    }
}

ButtonGroup.propTypes = {
    forceReset: PropTypes.number,
    id: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
    forcedActiveIdx: PropTypes.number,
    initialActiveIdx: PropTypes.number,
};

ButtonGroup.defaultProps = {
    forceReset: null,
    id: "0",
    style: {},
    children: [],
    forcedActiveIdx: null,
    initialActiveIdx: null,
};

export default ButtonGroup;
