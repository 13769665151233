import React from "react";
import "./styles.css";
import { sliderTypes } from "../../../constants";
import PropTypes from "prop-types";
import { DEFAULT_SLIDER_PERCENT } from "../Viewers/Structures/MRI/consts";

class FilterSliderItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        let sliderValue = DEFAULT_SLIDER_PERCENT;
        if (
            this.props.name === sliderTypes.INTENSITY &&
            this.props.mriModel.intensity != null
        ) {
            sliderValue = 100 - this.props.mriModel.intensity * 100;
        }
        if (
            this.props.name === sliderTypes.CONTRAST &&
            this.props.mriModel.contrast != null
        ) {
            sliderValue = 100 - this.props.mriModel.contrast * 100;
        }

        this.state = {
            sliderPercentage: sliderValue,
        };
    }

    handleChange(event) {
        event.stopPropagation();
        this.setState({ sliderPercentage: event.target.value });
        if (!this.props.mriModel.autoLevel) {
            if (this.props.name === sliderTypes.INTENSITY) {
                this.props.mriModel.intensity =
                    1 - Number(event.target.value) / 100;
            }
            if (this.props.name === sliderTypes.CONTRAST) {
                this.props.mriModel.contrast =
                    1 - Number(event.target.value) / 100;
            }
            this.forceUpdate();
        }
        return true;
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.autoLevel !== this.props.autoLevel &&
            this.props.autoLevel
        ) {
            this.setState({ sliderPercentage: DEFAULT_SLIDER_PERCENT });
        }
    }

    render() {
        return (
            <div
                id={this.props.id}
                className={"filter-slider-item"}
                style={this.props.style}
                ref={this.setWrapperRef}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <p className={"display-16"}>{this.props.name}</p>
                <input
                    type="range"
                    step={"1"}
                    min="1"
                    max="100"
                    id={this.props.name + "-slider"}
                    className={"filter-slider"}
                    onChange={this.handleChange}
                    value={this.state.sliderPercentage}
                    disabled={this.props.autoLevel}
                />
            </div>
        );
    }
}

FilterSliderItem.propTypes = {
    mriModel: PropTypes.object,
    autoLevel: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object,
};

export default FilterSliderItem;
