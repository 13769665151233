import React, { Component } from "react";
import { withRouter } from "react-router";
import { setFormErrorState, updateForm } from "../actions";
import { connect } from "react-redux";
import { signUpFlowStates, signUpFormFields } from "../constants";
import { EMPTY_STRING } from "../../../constants";
import { setSignUpFlowState } from "../actions";
import isEqual from "react-fast-compare";
import AHButton from "../../__shared__/StyleGuide/AHButton";
import { createAccount, checkSignUpFormValid } from "../helpers";
import { SUCCESS, MAX_EMAIL_LENGTH, stepPath } from "../../../constants";
import PropTypes from "prop-types";
import SignupLoginButton from "../LoginButton";
import AHAlphaField from "../../__shared__/StyleGuide/AHAlphaField";
import AHProgressBubbles from "../../__shared__/StyleGuide/AHProgressBubbles";
import NewPassword from "../../__shared__/StyleGuide/NewPassword";
import {
    buttonSize,
    messages,
    submitButtonText,
    viewType,
} from "../../__shared__/StyleGuide/NewPassword/constants";
import "./styles.css";

let step1Viz = true;
let step2Viz = false;

class SignupForm extends Component {
    constructor(props) {
        super(props);

        this.handleStep = this.handleStep.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            errorMsg: EMPTY_STRING,
        };
    }

    componentDidUpdate() {
        let scope = this;
        checkSignUpFormValid(this.props.form, step1Viz, step2Viz)
            .then((error) => {
                if (!isEqual(error, scope.props.error)) {
                    scope.props.setFormErrorState(error);
                }
            })
            .catch(() => {});
    }

    handleSubmit(message, password) {
        if (message !== messages.SUCCESS) {
            this.setState({
                errorMsg: message,
            });
        } else {
            let scope = this;
            this.props.form.password = password;
            checkSignUpFormValid(this.props.form, step1Viz, step2Viz)
                .then((error) => {
                    if (error && error.msg.length === 0) {
                        createAccount(scope.props.form).then((result) => {
                            if (result === SUCCESS) {
                                this.clearForm();
                                scope.props.history.push(stepPath.LOGIN);
                            }
                        });
                    }
                })
                .catch(() => {});
        }
    }

    /**
     * clear form fields on successful signup
     * edge case: multiple signups occur during same session use prev values
     */
    clearForm() {
        Object.entries(this.props.form).map(([key]) =>
            this.props.updateForm({
                fieldName: key,
                value: EMPTY_STRING,
            })
        );
        step1Viz = true;
        step2Viz = false;
        this.setState({
            errorMsg: EMPTY_STRING,
        });
    }

    onChange = (label, value) => {
        switch (label) {
            case signUpFormFields.FIRST_NAME: {
                this.props.updateForm({
                    fieldName: "firstName",
                    value: value,
                });
                break;
            }
            case signUpFormFields.LAST_NAME: {
                this.props.updateForm({
                    fieldName: "lastName",
                    value: value,
                });
                break;
            }
            /**
             * deprecating username field - this will set email and username to
             * same value this avoids need for backend changes and will allow
             * username to be added back in future
             */
            case signUpFormFields.EMAIL: {
                this.props.updateForm({
                    fieldName: "email",
                    value: value,
                });
                this.props.updateForm({
                    fieldName: "username",
                    value: value,
                });
                break;
            }
            default: {
                break;
            }
        }
    };

    // toggle between next/prev step
    handleStep(step1, step2) {
        /**
         * reset error in both directions to prevent need for double-clicking
         * next/back button failure to reset message may cause validation
         * conflict with componentDidMount()
         */
        if (
            step1Viz &&
            !this.props.error.isInvalid &&
            !this.state.errorStatus
        ) {
            this.props.setFormErrorState({
                isInvalid: false,
                msg: [],
            });

            this.setState({
                errorMsg: EMPTY_STRING,
            });

            step1Viz = step1;
            step2Viz = step2;
        } else if (step1Viz && this.props.error.isInvalid) {
            this.setState({
                errorMsg: "Please correct the errors below.",
            });
        } else if (step2Viz) {
            this.props.setFormErrorState({
                isInvalid: false,
                msg: [],
            });

            this.setState({
                errorMsg: EMPTY_STRING,
            });

            // clear password if toggle from step 2 back to step 1
            this.props.updateForm({
                fieldName: "password",
                value: EMPTY_STRING,
            });

            this.props.updateForm({
                fieldName: "confPassword",
                value: EMPTY_STRING,
            });

            step1Viz = step1;
            step2Viz = step2;
        }
    }

    handleBack = () => {
        this.props.setFormErrorState({
            isInvalid: false,
            msg: [],
        });
        this.clearForm();
        let scope = this;
        scope.props.setSignUpFlowState(signUpFlowStates.ACCESS_CODE);
    };

    render() {
        // react doesn't allow objects as children so convert to array
        let errors = [];
        if (this.props.error.msg) {
            // prettier-ignore
            Object.entries(this.props.error.msg).map(
                ([k, v]) => (errors[`${k}`] = v)
            );
        }

        let userData = {
            firstName: this.props.form.firstName,
            lastName: this.props.form.lastName,
            email: this.props.form.email,
        };

        return (
            <div className="signup-form">
                <p className="display-regular-24 signup-form-title">
                    {step1Viz && "Create a New User Account"}
                    {step2Viz && "Create a New Password"}
                </p>
                {this.state.errorMsg && (
                    <div className="signup-form-error">
                        {this.state.errorMsg}
                    </div>
                )}
                {step1Viz && (
                    <>
                        <AHAlphaField
                            label={signUpFormFields.FIRST_NAME}
                            value={this.props.form.firstName}
                            placeholder={""}
                            onChange={this.onChange}
                            id={"registration-text-fname"}
                            className={
                                "ah-alpha-field-wrapper ah-alpha-field-wrapper-left"
                            }
                            type={"text"}
                            showLabel={true}
                            error={errors.firstName}
                        />
                        <AHAlphaField
                            label={signUpFormFields.LAST_NAME}
                            value={this.props.form.lastName}
                            placeholder={""}
                            onChange={this.onChange}
                            id={"registration-text-lname"}
                            className={
                                "ah-alpha-field-wrapper ah-alpha-field-wrapper-right"
                            }
                            type={"text"}
                            showLabel={true}
                            error={errors.lastName}
                        />
                        <AHAlphaField
                            label={signUpFormFields.EMAIL}
                            value={this.props.form.email}
                            placeholder={""}
                            onChange={this.onChange}
                            id={"registration-text-email"}
                            className={"ah-alpha-field-wrapper"}
                            type={"text"}
                            maxLength={MAX_EMAIL_LENGTH}
                            showLabel={true}
                            error={errors.email}
                        />
                        <AHButton
                            isOutline={true}
                            isSecondary={true}
                            size={"large"}
                            isDynamic={false}
                            text={"Back"}
                            className={"left"}
                            onClick={(e) => {
                                e.preventDefault();
                                this.handleBack();
                            }}
                        />
                        <AHButton
                            isOutline={false}
                            isSecondary={false}
                            size={"large"}
                            isDynamic={false}
                            text={"Next"}
                            className={"right"}
                            onClick={(e) => {
                                e.preventDefault();
                                this.handleStep(false, true);
                            }}
                        />
                        <SignupLoginButton />
                        <AHProgressBubbles
                            totalSteps={signUpFlowStates.SIGNUP_FORM_STEPS}
                            currStep={2}
                        />
                    </>
                )}
                {step2Viz && (
                    <>
                        <NewPassword
                            submitButtonText={submitButtonText.SIGN_UP}
                            viewType={viewType.MINI_VIEW}
                            userData={userData}
                            onParentSubmit={this.handleSubmit}
                            isLabelVisible={true}
                            submitButtonSize={buttonSize.LARGE}
                            newPasswordId={"registration-text-pword"}
                            confirmPasswordId={
                                "registration-text-pword-confirm"
                            }
                        />
                        <AHButton
                            isOutline={true}
                            isSecondary={true}
                            size={"large"}
                            isDynamic={false}
                            text={"Back"}
                            className={"left"}
                            onClick={(e) => {
                                e.preventDefault();
                                this.handleStep(true, false);
                            }}
                        />
                        <SignupLoginButton />
                        <AHProgressBubbles
                            totalSteps={signUpFlowStates.SIGNUP_FORM_STEPS}
                            currStep={3}
                        />
                    </>
                )}
            </div>
        );
    }
}

SignupForm.propTypes = {
    form: PropTypes.object,
    error: PropTypes.object,
    updateForm: PropTypes.func,
    setFormErrorState: PropTypes.func,
    err: PropTypes.object,
};

SignupForm.defaultProps = {
    form: {},
    error: {},
    err: {},
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        form: state.SignupReducer.form,
        error: state.SignupReducer.error,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        updateForm: (formData) => dispatch(updateForm(formData)),
        setFormErrorState: (error) => dispatch(setFormErrorState(error)),
        setSignUpFlowState: (error) => dispatch(setSignUpFlowState(error)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SignupForm)
);
