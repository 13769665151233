import React, { Component } from "react";
import PropTypes from "prop-types";

export default class CheckboxRenderer extends Component {
    constructor(props) {
        super(props);
        this.checkedHandler = this.checkedHandler.bind(this);
    }

    checkedHandler(event) {
        let checked = event.target.checked;
        let colId = this.props.column.colId;
        this.props.node.setDataValue(colId, checked);
    }

    render() {
        return (
            <input
                type="checkbox"
                onChange={this.checkedHandler}
                checked={this.props.value}
            />
        );
    }
}

CheckboxRenderer.propTypes = {
    column: PropTypes.object,
    node: PropTypes.object,
    value: PropTypes.bool,
};
