import * as THREE from "three";

export var MRI_GENERIC_MATERIAL = new THREE.MeshBasicMaterial({
    color: "#ffffff",
    depthWrite: true,
    transparent: true,
    opacity: 1.0,
    side: THREE.FrontSide,
    clippingPlanes: [],
});
