import React, { Component } from "react";
import "./styles.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import QuestionMark from "../../../__shared__/IconComponents/QuestionMark";

class WorkflowHeader extends Component {
    render() {
        const renderTooltip = (props) => (
            <Tooltip
                style={{ width: "300px" }}
                id="choose-lesion-size-tooltip"
                {...props}
            >
                {ChooseLesionSizeTooltip}
            </Tooltip>
        );

        return (
            <div className={"margin-workflow-header-text"}>
                <p className={"display-24"}>Choose Lesion Size:</p>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <div className="choose-lesion-size-tooltip">
                        <QuestionMark />
                    </div>
                </OverlayTrigger>
            </div>
        );
    }
}

export default WorkflowHeader;

const ChooseLesionSizeTooltip =
    "Using the interactive plot below, select a lesion contour that has the desired tradeoff between Marks confidence score and lesion volume";
