import StructureModel from "./StructureModel";

export default class ROIModel extends StructureModel {
    constructor(roi) {
        super();
        this._metadata = roi;
    }

    get metadata() {
        return this._metadata;
    }
}
