import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";

const enabledStyling = { pointerEvents: "auto" };

class LinkWithCallback extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.props.disabled) {
            return;
        }

        if (this.props.callback) {
            this.props.callback();
        }
        if (
            this.props.history &&
            this.props.history.location.path !== this.props.to
        ) {
            this.props.history.push(this.props.to);
        }
    }

    render() {
        return (
            <div
                id={this.props.id}
                style={this.props.style}
                className={this.props.className}
                onClick={this.handleClick}
            >
                {this.props.children}
            </div>
        );
    }
}

LinkWithCallback.propTypes = {
    to: PropTypes.string,
    callback: PropTypes.func,
    style: PropTypes.object,
    className: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.any,
    history: PropTypes.object,
    disabled: PropTypes.bool,
};

LinkWithCallback.defaultProps = {
    to: EMPTY_STRING,
    callback: undefined,
    style: enabledStyling,
    disabled: false,
};

export default withRouter(LinkWithCallback);
