// action types
export const RESET_IMAGE_VIEWER_SHARD = "RESET_IMAGE_VIEWER_SHARD";
export const SET_EDIT_SEGMENTATION_ACTIVE = "SET_EDIT_SEGMENTATION_ACTIVE";
export const SET_RESET_SEGMENTATION = "SET_RESET_SEGMENTATION";
export const SAVE_EDITED_SEGMENTATION = "SAVE_EDITED_SEGMENTATION";
export const SAVE_EDIT_SEG_ERROR = "SAVE_EDIT_SEG_ERROR";

// action creators

export const resetImageViewerShard = function () {
    return {
        type: RESET_IMAGE_VIEWER_SHARD,
    };
};

export const setEditSegmentationActive = function (isOn) {
    return {
        type: SET_EDIT_SEGMENTATION_ACTIVE,
        isOn: isOn,
    };
};

export const setResetSegmentation = function (isOn) {
    return {
        type: SET_RESET_SEGMENTATION,
        isOn: isOn,
    };
};

export const saveEditedSegmentation = function (isOn) {
    return {
        type: SAVE_EDITED_SEGMENTATION,
        isOn: isOn,
    };
};

export const setSaveEditSegError = function (isOn) {
    return {
        type: SAVE_EDIT_SEG_ERROR,
        isOn: isOn,
    };
};
