import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LoginDialog from "./Dialog";
import AvendaLogo from "../__shared__/AvendaLogo";
import logo from "../../assets/avenda_logo_full.svg";
import {
    clientErrorMessages,
    EMPTY_STRING,
    formatting,
    INCORRECT_CREDENTIALS,
    LOGIN_ATTEMPTS_EXCEEDED,
    loginReducerFlags,
    stepPath,
    SUCCESS,
    SUCCESS_OTP_NEXT,
} from "../../constants";
import PropTypes from "prop-types";
import {
    setAuthToken,
    setFlag,
    setPassword,
    setUserData,
    setUsername,
    setUserUUID,
} from "./actions";
import { getUser, signInFetchToken } from "./helpers";
import OTPCodeWidget from "../EnableMFA/OTPCodeWidget";
import { If, Then, Else } from "react-if";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            connectionError: false,
            unPwIncorrect: false,
            loginAttemptsExceeded: false,
            waitTime: 0,
            mfaDialog: false,
            authToken: EMPTY_STRING,
        };

        this.submitHandler = this.submitHandler.bind(this);
        this.onLoginSuccess = this.onLoginSuccess.bind(this);
    }

    componentDidUpdate() {
        if (this.props.flags.isAuthenticated === true) {
            this.props.history.push(stepPath.HOME);
        }
    }

    onLoginSuccess(userData, token) {
        this.props.setUserUUID(userData.uuid);
        this.props.setAuthToken(token);
        this.props.setFlag({
            flagId: loginReducerFlags.IS_AUTHENTICATED,
            newFlagState: true,
        });
        this.props.setPassword(EMPTY_STRING);
        this.props.setUserData(userData);

        if (window.pendo) {
            try {
                window.pendo.identify({
                    visitor: {
                        id: userData.uuid,
                        email: userData.email,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        mfaEnabled: userData.mfaEnabled,
                    },

                    account: {
                        id: userData?.institution
                            ? userData.institution
                            : "ACCOUNT-UNIQUE-ID",
                    },
                });
            } catch {
                () => {};
            }
        }
    }

    submitHandler() {
        this.setState({
            connectionError: false,
            unPwIncorrect: false,
            loginAttemptsExceeded: false,
        });
        signInFetchToken(this.props.unText, this.props.pwText).then(
            (payload) => {
                if (
                    payload &&
                    payload.status === SUCCESS &&
                    payload.token &&
                    payload.useruuid
                ) {
                    getUser(this.props.unText, payload.token).then((result) => {
                        if (result && result.status === SUCCESS) {
                            this.onLoginSuccess(result.user, payload.token);
                        }
                    });
                } else if (
                    payload &&
                    payload.status === SUCCESS_OTP_NEXT &&
                    payload.useruuid
                ) {
                    let userData = {
                        uuid: payload.useruuid,
                    };
                    this.props.setUserData(userData);
                    this.setState({
                        mfaDialog: true,
                    });
                } else if (
                    payload &&
                    payload.status === INCORRECT_CREDENTIALS
                ) {
                    this.setState({
                        unPwIncorrect: true,
                    });
                } else if (
                    payload &&
                    payload.status === LOGIN_ATTEMPTS_EXCEEDED
                ) {
                    this.setState({
                        loginAttemptsExceeded: true,
                        waitTime: payload.waitTime,
                    });
                } else {
                    this.setState({
                        connectionError: true,
                    });
                }
            }
        );
    }

    render() {
        let errText = EMPTY_STRING;
        if (this.state.connectionError) {
            errText = clientErrorMessages.LOGIN_FAILED;
        } else if (this.state.unPwIncorrect) {
            errText = clientErrorMessages.UN_PW_INCORRECT;
        } else if (this.state.loginAttemptsExceeded) {
            let minuteStr = formatting.space + "min.";
            errText =
                clientErrorMessages.LOGIN_ATTEMPTS_EXCEEDED +
                this.state.waitTime +
                minuteStr;
        }

        return (
            <div className={"signup-screen"}>
                <AvendaLogo />
                <If condition={this.state.mfaDialog}>
                    <Then>
                        <OTPCodeWidget
                            useruuid={this.props.userData.uuid}
                            username={this.props.unText}
                            onSuccess={this.onLoginSuccess}
                        />
                    </Then>
                    <Else>
                        <LoginDialog
                            submitHandler={this.submitHandler}
                            error={errText}
                        />
                    </Else>
                </If>
                <div className="signup-credits">
                    Created by
                    <img src={logo} alt="Avenda logo" />
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    unText: PropTypes.string,
    pwText: PropTypes.string,
    userData: PropTypes.object,
    flags: PropTypes.object,
    history: PropTypes.object,
    setUserUUID: PropTypes.func,
    setAuthToken: PropTypes.func,
    setFlag: PropTypes.func,
    setPassword: PropTypes.func,
    setUserData: PropTypes.func,
};

Login.defaultProps = {
    userData: {},
    unText: EMPTY_STRING,
    pwText: EMPTY_STRING,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        userData: state.LoginReducer.userData,
        unText: state.LoginReducer.unText,
        pwText: state.LoginReducer.pwText,
        flags: state.LoginReducer.flags,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setUserData: (userData) => dispatch(setUserData(userData)),
        setFlag: (flag) => dispatch(setFlag(flag)),
        setAuthToken: (token) => dispatch(setAuthToken(token)),
        setUsername: (text) => dispatch(setUsername(text)),
        setPassword: (text) => dispatch(setPassword(text)),
        setUserUUID: (uuid) => dispatch(setUserUUID(uuid)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
