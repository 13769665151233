import {
    clientErrorMessages,
    editedVtkNames,
    EMPTY_STRING,
    refFrames,
    structureColorPalette,
    volumeNames,
} from "../../constants";
import {
    createMeshFromFaceVertexInS3,
    getPHISignedURLS3,
    updateTreatmentPlan,
} from "../../helpers/backend_api";
import { rootStore } from "../../redux/store";
import { setErrorState } from "../../redux/error_banner/actions";
import * as TreatmentPlan from "../../helpers/tplan/tplan";
import * as path from "path";
import { getSignedUrl, toArray } from "../../helpers/helpers";
import ProstateAxes from "../__shared__/Viewers/Structures/Other/ProstateAxes";
import ProstateModel from "../__shared__/Viewers/Structures/PatientAnatomy/ProstateModel";

export function getProstateSegmentationURI(plan, ids, name) {
    let basePath;
    let meshName;

    if (name === volumeNames.MR_PROSTATE_USER_UPLOAD) {
        basePath = TreatmentPlan.hasUserUploadedSegmentationURI(plan)
            ? plan.SavedData.UploadedProstateURI
            : TreatmentPlan.getStructureInFrame(
                  TreatmentPlan.getCoreStructure(plan.TreatmentPlan),
                  refFrames.MRI
              ).URI;
        basePath = path.dirname(basePath);

        meshName = editedVtkNames.UPLOADED_EDITED_SEGMENTATION_FILE_NAME;
    } else if (name === volumeNames.MR_PROSTATE_AI) {
        basePath = path.dirname(plan.SavedData.AIProstateURI);
        meshName = editedVtkNames.AI_EDITED_SEGMENTATION_FILE_NAME;
    } else {
        basePath = EMPTY_STRING;
        meshName = EMPTY_STRING;
    }
    return `${basePath}/${meshName}`;
}

export function resetProstateSegmentationEdits(
    ids,
    plan,
    meshEditController,
    patientAnatomyModel,
    callback
) {
    resetActiveMeshOptions(ids, plan, patientAnatomyModel, meshEditController);

    updateTreatmentPlan({
        ...ids,
        plan: plan,
    })
        .then((payload) => payload.json())
        .then((json) => {
            callback(json.payload.plan);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
            );
        });
}

export function saveProstateSegmentationEdits(
    ids,
    plan,
    meshEditController,
    patientAnatomyModel,
    callback
) {
    let editedStructure = meshEditController.editedStructure;
    let linkedStructure = meshEditController.linkedStructure;
    let linkedStructureName = linkedStructure.name;
    let prostateURI = getProstateSegmentationURI(
        plan,
        ids,
        linkedStructureName
    );

    createMeshFromFaceVertexInS3({
        faces: editedStructure.faces,
        vertices: editedStructure.vertices,
        destinationURI: prostateURI,
        token: ids.token,
    })
        .then(() => {
            setLinkedProstateWithProperties(
                linkedStructureName,
                plan,
                patientAnatomyModel,
                meshEditController,
                prostateURI
            );

            updateTreatmentPlan({
                ...ids,
                plan: plan,
            })
                .then((payload) => payload.json())
                .then((json) => {
                    callback(json.payload.plan);
                })
                .catch(() => {
                    rootStore.dispatch(
                        setErrorState(
                            true,
                            clientErrorMessages.TPLAN_UPDATE_FAILED
                        )
                    );
                });
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.SAVE_MESH_UPDATE_FAILED)
            );
        });
}

function setLinkedProstateWithProperties(
    linkedStructureName,
    plan,
    patientAnatomyModel,
    meshEditController,
    prostateURI
) {
    let color, volName;

    if (linkedStructureName === volumeNames.MR_PROSTATE_USER_UPLOAD) {
        plan.SavedData.UploadedEditedProstateURI = prostateURI;

        color = patientAnatomyModel.prostateUploaded.color;
        volName = patientAnatomyModel.prostateUploaded.name;

        patientAnatomyModel.prostateUploaded.delete();
        patientAnatomyModel.prostateUploaded = null;
    } else if (linkedStructureName === volumeNames.MR_PROSTATE_AI) {
        plan.SavedData.AIEditedProstateURI = prostateURI;

        color = patientAnatomyModel.prostateAI.color;
        volName = patientAnatomyModel.prostateAI.name;

        patientAnatomyModel.prostateAI.delete();
        patientAnatomyModel.prostateAI = null;
    }

    let newProstate = new ProstateModel();
    newProstate.mriViewer = patientAnatomyModel.mriViewer;
    newProstate.meshViewer = patientAnatomyModel.meshViewer;

    let geometryCopy = meshEditController.editedStructure.geometry.clone();

    newProstate.initializeViewFromGeometry(geometryCopy, {
        color: color,
        opacity: 0.3,
        name: volName,
    });

    newProstate.visibility = false;

    patientAnatomyModel.setProstateStructByName(
        linkedStructureName,
        newProstate
    );

    meshEditController.linkedStructure = newProstate;

    let prostateAxes = new ProstateAxes();
    newProstate.initProstateAxes(prostateAxes);
}

function resetActiveMeshOptions(
    ids,
    plan,
    patientAnatomyModel,
    meshEditController
) {
    let activeStructureName = meshEditController.linkedStructure.name;

    let prostateURI, meshOptions;

    if (activeStructureName === volumeNames.MR_PROSTATE_USER_UPLOAD) {
        plan.SavedData.UploadedEditedProstateURI = EMPTY_STRING;

        if (
            plan.SavedData.UploadedProstateURI &&
            plan.SavedData.UploadedProstateURI !== EMPTY_STRING
        ) {
            prostateURI = plan.SavedData.UploadedProstateURI;
        } else {
            prostateURI = TreatmentPlan.getStructureInFrame(
                TreatmentPlan.getCoreStructure(plan.TreatmentPlan),
                refFrames.MRI
            ).URI;
        }

        meshOptions = {
            volName: volumeNames.MR_PROSTATE_USER_UPLOAD,
            color: structureColorPalette.PROSTATE,
        };
    } else if (activeStructureName === volumeNames.MR_PROSTATE_AI) {
        plan.SavedData.AIEditedProstateURI = EMPTY_STRING;
        prostateURI = plan.SavedData.AIProstateURI;

        meshOptions = {
            volName: volumeNames.MR_PROSTATE_AI,
            color: structureColorPalette.AI_PROSTATE,
        };
    }

    resetActiveProstateMesh(
        ids,
        prostateURI,
        patientAnatomyModel,
        meshEditController,
        meshOptions,
        activeStructureName
    );
}

function resetActiveProstateMesh(
    ids,
    prostateURI,
    patientAnatomyModel,
    meshEditController,
    meshOptions,
    activeStructureName
) {
    getPHISignedURLS3({
        ...ids,
        uris: toArray(prostateURI),
    })
        .then((payload) => payload.json())
        .then((json) => {
            let url = getSignedUrl(json.payload.signedurls);

            let prostateStructure = new ProstateModel();

            prostateStructure.mriViewer = patientAnatomyModel.mriViewer;
            prostateStructure.meshViewer = patientAnatomyModel.meshViewer;

            prostateStructure
                .initializeView(url, {
                    name: meshOptions.volName,
                    color: meshOptions.color,
                    opacity: 0.3,
                })
                .then(() => {
                    prostateStructure.visibility = false;

                    patientAnatomyModel.setProstateStructByName(
                        activeStructureName,
                        prostateStructure
                    );
                    meshEditController.cancelEditing();
                    meshEditController.linkedStructure = prostateStructure;
                    meshEditController.initializeEditing();

                    let prostateAxes = new ProstateAxes();
                    prostateStructure.initProstateAxes(prostateAxes);
                });
        });
}
