import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.css";
import PropTypes from "prop-types";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";

class PathologyInfoMiniCard extends Component {
    render() {
        let plan = this.props.currentTreatmentPlan;
        if (!plan) {
            return;
        }

        let prostateVolume = TreatmentPlan.getCoreStructure(
            plan.TreatmentPlan
        ).VolumeCC;
        prostateVolume = prostateVolume.toFixed(1);

        let margin = TreatmentPlan.getMarginByIdx(plan.TreatmentPlan, 0);
        let marginVolume = margin ? margin.VolumeCC : 0;
        marginVolume = marginVolume.toFixed(1);

        let txSuccessProbability = plan.SavedData.UserSelectedMarksScore;
        txSuccessProbability = (100 * txSuccessProbability).toFixed(1);

        let gleasonGradeGroup =
            plan.TreatmentPlan.PatientData.GleasonGradeGroup;

        let psa = plan.TreatmentPlan.PatientData.PSA;
        let psaTxt = psa === 0.0 ? "N/A " : psa;

        let psad;
        if (psa !== 0.0) {
            psad = psa / prostateVolume;
            psad = psad.toFixed(3);
        } else {
            psad = "N/A ";
        }

        return (
            <div className={"binfo-mini-card"} style={this.props.style}>
                <div className={"binfo-mini-header"}>
                    <p className={"display-26"} style={{ color: "#7C7C7C" }}>
                        Pathology Information
                    </p>
                </div>
                <div className={"binfo-mini-table"}>
                    <table className={"binfo-mini-col"}>
                        <tbody>
                            <tr>
                                <td className={"binfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        PSA
                                    </p>
                                </td>
                                <td className={"binfo-mini-right-item"}>
                                    <p
                                        id={"psa-value"}
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {psaTxt}ng/mL
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={"binfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        Prostate Volume
                                    </p>
                                </td>
                                <td className={"binfo-mini-right-item"}>
                                    <p
                                        id={"prostate-volume-value"}
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {prostateVolume}cc
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={"binfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        PSAD
                                    </p>
                                </td>
                                <td className={"binfo-mini-right-item"}>
                                    <p
                                        id={"psad-value"}
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {psad}ng/mL<sup>2</sup>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={"binfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        Gleason Grade Group
                                    </p>
                                </td>
                                <td className={"binfo-mini-right-item"}>
                                    <p
                                        id={"gleason-grade-group-value"}
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {gleasonGradeGroup}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={"binfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        Cancer Lesion Volume
                                    </p>
                                </td>
                                <td className={"binfo-mini-right-item"}>
                                    <p
                                        id={"margin-volume-value"}
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {marginVolume}cc
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={"binfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        Encapsulation Confidence Score
                                    </p>
                                </td>
                                <td className={"binfo-mini-right-item"}>
                                    <p
                                        id={"tx-success-probability-value"}
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {txSuccessProbability}%
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

PathologyInfoMiniCard.propTypes = {
    currentTreatmentPlan: PropTypes.object,
    style: PropTypes.object,
};

PathologyInfoMiniCard.defaultProps = {
    currentTreatmentPlan: {},
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function () {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PathologyInfoMiniCard);
