import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { EMPTY_STRING, specialSelection } from "../../../constants";
import { BIOPSY_ERROR_MESSAGES, editable_column_ids } from "../constants";
import { updateBxCore } from "../helpers";
import {
    setActiveCore,
    setBiopsyError,
    setIsCoreFocused,
    setIsOnMeshViewerControl,
} from "../actions";
import { setCurrentTreatmentPlan } from "../../CreateTPlan_1Upload/actions";
import { connect } from "react-redux";

import * as helpers from "./helpers";
import isEqual from "react-fast-compare";
import ClickOutside from "../../__shared__/ClickOutside";
import PatientAnatomyController from "../../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyController";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import { mouseEventIsWithinCanvas } from "../helpers";

class PathologyTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gridApi: null,
        };
        this.rowData = null;
        this.colData = null;

        this.mount = React.createRef();
    }

    componentDidMount() {
        //zero out to prevent crash when loading a new patient with diff # of bx cores
        this.props.setActiveCore(specialSelection.NONE_SELECTED);
        this.props.setIsCoreFocused(false);
        this.rowData = helpers.rowDefs(this.props.currentTreatmentPlan);
        let bxTemplate = TreatmentPlan.getBxTemplate(
            this.props.currentTreatmentPlan.TreatmentPlan
        );
        this.colData = helpers.columnDefs(bxTemplate);
    }

    shouldComponentUpdate(nextProps) {
        if (
            !isEqual(
                nextProps.currentTreatmentPlan.TreatmentPlan.StructureData
                    .Biopsy,
                this.props.currentTreatmentPlan.TreatmentPlan.StructureData
                    .Biopsy
            )
        ) {
            this.rowData = helpers.rowDefs(nextProps.currentTreatmentPlan);
        }
        return true;
    }

    onGridReady = async (params) => {
        this.setState({
            gridApi: params.api,
        });
    };

    focusRow = (activeCore) => {
        if (activeCore === specialSelection.NONE_SELECTED) {
            if (this.state.gridApi) {
                this.state.gridApi.deselectAll();
            }
            return;
        }
        if (
            this.state.gridApi &&
            activeCore !== null &&
            activeCore !== specialSelection.NONE_SELECTED
        ) {
            this.state.gridApi.getRowNode(activeCore).setSelected(true);
            this.state.gridApi.ensureIndexVisible(activeCore);
        }
    };

    /**
     * grid (keyboard): if tabbing to next/prev row, select/highlight currently focused row and cartoon button
     */
    onCellKeyDown = (e) => {
        if (["Tab", "Enter"].includes(e.event.code)) {
            let activeCore = e.rowIndex;

            if (e.event.code === "Tab") {
                const isFirstColumn =
                    e.column.instanceId ===
                    editable_column_ids.FIRST_EDITABLE_COLUMN_ID;
                const isLastColumn =
                    e.column.instanceId ===
                    editable_column_ids.LAST_EDITABLE_COLUMN_ID;
                const isNotFirstRow = e.rowIndex !== 0;
                const isNotLastRow = e.rowIndex !== this.rowData.length - 1;

                if (e.event.shiftKey && isFirstColumn && isNotFirstRow) {
                    activeCore = e.rowIndex - 1;
                } else if (!e.event.shiftKey && isLastColumn && isNotLastRow) {
                    activeCore = e.rowIndex + 1;
                }

                this.props.setActiveCore(activeCore);
                this.props.setIsCoreFocused(true);
            }

            this.focusRow(activeCore);
        }
    };

    onCellMouseOver = (e) => {
        if (e.rowIndex !== this.props.activeCore && !this.props.isCoreFocused) {
            this.props.setActiveCore(e.rowIndex);
        }
    };

    onCellMouseOut = (e) => {
        if (e.rowIndex === this.props.activeCore && !this.props.isCoreFocused) {
            this.props.setActiveCore(specialSelection.NONE_SELECTED);
        }
    };

    onCellClicked = (e) => {
        // If the user clicks on a cell in an editable column and a core is already focused,
        // return so it won't deselect the core
        if (
            e.column.instanceId <=
                editable_column_ids.LAST_EDITABLE_COLUMN_ID &&
            e.column.instanceId >=
                editable_column_ids.FIRST_EDITABLE_COLUMN_ID &&
            this.props.isCoreFocused
        ) {
            return;
        }

        if (this.props.isCoreFocused && e.rowIndex === this.props.activeCore) {
            this.props.setIsCoreFocused(false);
            this.props.setActiveCore(specialSelection.NONE_SELECTED);
        } else {
            this.props.setIsCoreFocused(true);
            this.props.setActiveCore(e.rowIndex);
        }
    };

    onClickOutside = (event) => {
        if (!this.mount.current) {
            return;
        }

        if (!this.props.meshViewer.current && !this.props.biopsyPlot.current) {
            return;
        }

        let meshViewerRect =
            this.props.meshViewer.current.mount.getBoundingClientRect();
        let biopsyPlotRect =
            this.props.biopsyPlot.current.biopsyPlotRef.current.mount.current.getBoundingClientRect();
        if (this.props.isOnMeshViewerControl) {
            this.props.setIsOnMeshViewerControl(false);
        } else if (
            !mouseEventIsWithinCanvas(event, meshViewerRect) &&
            !mouseEventIsWithinCanvas(event, biopsyPlotRect)
        ) {
            this.props.setIsCoreFocused(false);
            this.props.setActiveCore(specialSelection.NONE_SELECTED);

            let node = this.state.gridApi.getRowNode(this.props.activeCore);
            if (!node) {
                return;
            }

            this.checkErrorAndSave(node, null);
            node.setSelected(false);
        }
    };

    onCellValueChange = (event) => {
        this.checkErrorAndSave(event.node, event);
    };

    checkErrorAndSave = async (node, event = null) => {
        if (event) {
            this.updateNodeWithChangeEvent(node, event);
        }

        let errorCode = this.checkForErrors(node);

        node.data.InputError = errorCode;
        this.props.setBiopsyError(errorCode);

        this.state.gridApi.refreshCells({ node, force: true });
        if (!errorCode) {
            this.saveBiopsyData(node.data, node.rowIndex);
            this.props.updateBiopsyPlot();
        }

        return errorCode;
    };

    updateNodeWithChangeEvent(node, event) {
        /*
        If the columns changed were tissue length or cancer length, update the node object
        with the tissue length or cancer length values from the event object.
        Node object will not have the most recent values
         */
        if (!event) {
            return node;
        }

        const tissueLengthColIdx = 3;
        const cancerLengthColIdx = 4;
        if (
            event.column.instanceId === tissueLengthColIdx ||
            event.column.instanceId === cancerLengthColIdx
        ) {
            node.data[event.column.colId] = event.newValue;
        }
    }

    checkForErrors(node) {
        let errorCode = BIOPSY_ERROR_MESSAGES.DEFAULT;
        if (!node.data.TemplateLocation)
            errorCode = BIOPSY_ERROR_MESSAGES.TEMPLATE_LOCATION_MISSING;

        if (node.data.GleasonGradeGroup > 0 && node.data.TissueLength === 0)
            errorCode = BIOPSY_ERROR_MESSAGES.GGG_NO_TISSUE;

        if (
            node.data.GleasonGradeGroup > 0 &&
            node.data.TissueLength > 0 &&
            node.data.CancerLength === 0
        )
            errorCode = BIOPSY_ERROR_MESSAGES.GGG_NO_CANCER;

        if (node.data.GleasonGradeGroup === 0 && node.data.CancerLength > 0)
            errorCode = BIOPSY_ERROR_MESSAGES.CANCER_NO_GGG;

        if (node.data.CancerLength > node.data.TissueLength)
            errorCode = BIOPSY_ERROR_MESSAGES.CANCER_GREATER_TISSUE;

        return errorCode;
    }

    saveBiopsyData = (nodeData, index) => {
        const bxCore =
            this.props.currentTreatmentPlan.TreatmentPlan.StructureData.Biopsy[
                parseInt(index)
            ];
        bxCore.Frame.MRI.TemplateLocation = nodeData.TemplateLocation;
        bxCore.CancerLength = parseFloat(nodeData.CancerLength);
        bxCore.GleasonGradeGroup = parseFloat(nodeData.GleasonGradeGroup);
        bxCore.PercentCancer = parseFloat(nodeData.PercentCancer);
        bxCore.TissueLength = parseFloat(nodeData.TissueLength);
        bxCore.IsMuted = nodeData.IsMuted;
        bxCore.IsROITargeted = nodeData.IsROITargeted;

        // Update Biopsy model to ensure 3d view is synced
        this.props.patientAnatomyController.updateBiopsyCore(index, bxCore);

        updateBxCore({
            token: this.props.authToken,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            coreIdx: index,
            bx: bxCore,
        });
    };

    render() {
        const defaultColDef = {
            initialFlex: 1,
            editable: true,
            resizable: true,
            suppressMovable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        };

        this.focusRow(this.props.activeCore);

        return (
            <div
                className="ag-theme-alpine ah-ag-biopsy-interface-container pendo-ignore"
                ref={this.mount}
            >
                <ClickOutside onClickOutsideCallback={this.onClickOutside}>
                    <AgGridReact
                        columnDefs={this.colData}
                        rowData={this.rowData}
                        defaultColDef={defaultColDef}
                        checkboxSelection={true}
                        rowSelection={"single"}
                        onGridReady={this.onGridReady}
                        onCellKeyDown={this.onCellKeyDown}
                        onCellBlur={this.onCellValueChange}
                        onCellValueChanged={this.onCellValueChange}
                        onCellMouseOver={this.onCellMouseOver}
                        onCellMouseOut={this.onCellMouseOut}
                        onCellClicked={this.onCellClicked}
                        suppressRowVirtualisation={true}
                        suppressDragLeaveHidesColumns={true}
                        stopEditingWhenCellsLoseFocus={true}
                        overlayNoRowsTemplate={
                            BIOPSY_ERROR_MESSAGES.NO_BIOPSIES_FOUND
                        }
                    />
                </ClickOutside>
            </div>
        );
    }
}

PathologyTable.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    activeCore: PropTypes.number,
    isCoreFocused: PropTypes.bool,
    isOnMeshViewerControl: PropTypes.bool,
    meshViewer: PropTypes.object,
    biopsyPlot: PropTypes.object,
    currentTreatmentPlan: PropTypes.object,
    useruuid: PropTypes.string,
    visituuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    setCurrentTreatmentPlan: PropTypes.func,
    setActiveCore: PropTypes.func,
    setIsCoreFocused: PropTypes.func,
    setIsOnMeshViewerControl: PropTypes.func,
    authToken: PropTypes.string,
    setBiopsyError: PropTypes.func,
    bxError: PropTypes.string,
    patientAnatomyController: PropTypes.instanceOf(PatientAnatomyController),
    updateBiopsyPlot: PropTypes.func,
};

PathologyTable.defaultProps = {
    activeCore: specialSelection.NONE_SELECTED,
    isCoreFocused: false,
    currentTreatmentPlan: {},
    useruuid: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    bxError: EMPTY_STRING,
};

const mapStateToProps = function (state) {
    return {
        activeCore: state.CreatePlanBiopsyReducer.activeCore,
        isCoreFocused: state.CreatePlanBiopsyReducer.isCoreFocused,
        isOnMeshViewerControl:
            state.CreatePlanBiopsyReducer.isOnMeshViewerControl,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        bxError: state.CreatePlanBiopsyReducer.bxError,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setActiveCore: (core) => dispatch(setActiveCore(core)),
        setIsCoreFocused: (isFocused) => dispatch(setIsCoreFocused(isFocused)),
        setIsOnMeshViewerControl: (isOnMeshViewerControl) =>
            dispatch(setIsOnMeshViewerControl(isOnMeshViewerControl)),
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        setBiopsyError: (bxError) => dispatch(setBiopsyError(bxError)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PathologyTable);
