import * as THREE from "three";

export function laplacianFilter(
    vertices,
    prevVertices,
    vertexIndicesToSmooth,
    neighbors
) {
    for (let i of vertexIndicesToSmooth) {
        let vertexNeighbors = neighbors[Math.floor(i / 3)];
        let average = new THREE.Vector3();

        if (!vertexNeighbors) {
            continue;
        }

        for (let j of vertexNeighbors) {
            let neighbor = new THREE.Vector3(
                prevVertices[j * 3],
                prevVertices[j * 3 + 1],
                prevVertices[j * 3 + 2]
            );
            average.add(neighbor);
        }
        average.divideScalar(vertexNeighbors.size);

        vertices[i + 0] = average.x;
        vertices[i + 1] = average.y;
        vertices[i + 2] = average.z;
    }
}

export function laplacianHCFilter(
    originalVertices,
    vertices,
    prevVertices,
    vertexIndicesToSmooth,
    neighbors,
    alpha = 0.1,
    beta = 0.4
) {
    let differences = [];
    for (let i of vertexIndicesToSmooth) {
        differences[i + 0] =
            vertices[i + 0] -
            (alpha * originalVertices[i + 0] +
                (1.0 - alpha) * prevVertices[i + 0]);
        differences[i + 1] =
            vertices[i + 1] -
            (alpha * originalVertices[i + 1] +
                (1.0 - alpha) * prevVertices[i + 1]);
        differences[i + 2] =
            vertices[i + 2] -
            (alpha * originalVertices[i + 2] +
                (1.0 - alpha) * prevVertices[i + 2]);
    }

    for (let i of vertexIndicesToSmooth) {
        let vertexNeighbors = neighbors[Math.floor(i / 3)];
        let averageDiff = new THREE.Vector3(0, 0, 0);

        if (!vertexNeighbors) {
            continue;
        }

        for (let j of vertexNeighbors) {
            let neighbor = new THREE.Vector3(
                differences[j * 3 + 0] || 0,
                differences[j * 3 + 1] || 0,
                differences[j * 3 + 2] || 0
            );
            averageDiff.add(neighbor);
        }

        vertices[i + 0] =
            vertices[i + 0] -
            (beta * differences[i + 0] +
                ((1.0 - beta) * averageDiff.x) / vertexNeighbors.size);
        vertices[i + 1] =
            vertices[i + 1] -
            (beta * differences[i + 1] +
                ((1.0 - beta) * averageDiff.y) / vertexNeighbors.size);
        vertices[i + 2] =
            vertices[i + 2] -
            (beta * differences[i + 2] +
                ((1.0 - beta) * averageDiff.z) / vertexNeighbors.size);
    }
}
