import React from "react";

const LightBulb = () => (
    <svg
        width="30"
        height="30"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.2501 31.6667H23.7501V34.8333H14.2501V31.6667ZM26.7679 21.7107C28.3987 19.8012 30.0834 17.8267 30.0834 14.25C30.0834 8.13991 25.1102 3.16666 19.0001 3.16666C12.89 3.16666 7.91675 8.13991 7.91675 14.25C7.91675 17.8679 9.60616 19.836 11.2433 21.7392C11.8102 22.401 12.3976 23.0866 12.9597 23.8751C13.1877 24.2012 13.5613 25.4521 13.8954 26.9167L14.2501 30.0833C18.4723 30.0833 19.5279 30.0833 23.7501 30.0833L24.1079 26.9167C24.4452 25.4489 24.8204 24.1949 25.0468 23.8687C25.6042 23.0723 26.1963 22.3804 26.7679 21.7107ZM22.4549 22.0479C21.7677 23.0248 21.1946 25.3333 20.8621 26.9167H17.1397C16.8087 25.3302 16.234 23.0169 15.5389 22.04C14.8945 21.1343 14.2311 20.3601 13.6452 19.6777C12.1031 17.8822 11.0834 16.6947 11.0834 14.25C11.0834 9.88474 14.6348 6.33332 19.0001 6.33332C23.3653 6.33332 26.9167 9.88474 26.9167 14.25C26.9167 16.6582 25.8987 17.8505 24.3597 19.6539C23.7691 20.3474 23.0993 21.1312 22.4549 22.0479Z"
            fill="#0050C4"
        />
    </svg>
);

export default LightBulb;
