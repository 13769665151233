import * as THREE from "three";
import {
    clientErrorMessages,
    sceneOrientations,
} from "../../../../../constants";
import * as AMI from "../../../../../lib/ami/src/ami";
import { rootStore } from "../../../../../redux/store";
import { setErrorState } from "../../../../../redux/error_banner/actions";
import FrameAxes from "../Other/FrameAxes";
import {
    DefaultCPMOpacity,
    TurboColorMapColorsSHIFTED,
    TurboColorMapOpacityLevelsSHIFTED,
} from "./consts";
import { deleteAllObjectsInScene } from "../../../../../helpers/threejs_helpers";

export default class MRIModel {
    constructor(enableLUTOverlay = false, defaultCameraDistance = 200) {
        this._clipPlane = new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
        this._visible = true;
        this._orientation = 0;
        this._lutInitialized = false;
        this._lutEnabled = enableLUTOverlay;
        this._defaultCameraDistance = defaultCameraDistance;
        if (this.lutEnabled) {
            this._lutVisible = true;
            this._sceneImgLayer = new THREE.Scene();
            this._sceneLUTLayer = new THREE.Scene();
        }
    }

    get visibility() {
        return this._visible;
    }

    set visibility(visibility) {
        this._visible = visibility;
        if (this._meshStackHelper) {
            this._meshStackHelper.visible = visibility;
        }
    }

    get lutVisibility() {
        return this._lutVisible;
    }

    set lutVisibility(isOn) {
        if (!this.lutEnabled) {
            return;
        }
        this._uniformsMixLayer.uOpacity1.value = isOn ? DefaultCPMOpacity : 0.0;
        this._lutVisible = isOn;
    }

    get lutEnabled() {
        return this._lutEnabled;
    }

    set lutEnabled(isOn) {
        this._lutEnabled = isOn;
    }

    get orientation() {
        return this._orientation;
    }

    set orientation(orientation) {
        this._orientation = orientation;
        this._setOrientation(this._mriStackHelper, orientation);
        this._setOrientation(this._meshStackHelper, orientation);

        if (this.lutEnabled && this._lutStackHelper) {
            this._setOrientation(this._lutStackHelper, orientation);
            this._updateLUTLayer();
            this._updateLayerMix();
        }

        this._applySetIntensity();
        this._applySetContrast();
    }

    get sliceIndex() {
        if (this._mriStackHelper) {
            return this._mriStackHelper.index;
        } else if (this._meshStackHelper) {
            return this._meshStackHelper.index;
        } else {
            return 0;
        }
    }

    set sliceIndex(sliceIndex) {
        this._setSliceIndex(this._mriStackHelper, sliceIndex);
        this._setSliceIndex(this._meshStackHelper, sliceIndex);

        if (this.lutEnabled && this._lutStackHelper) {
            this._setSliceIndex(this._lutStackHelper);
            this._updateLUTLayer();
            this._updateLayerMix();
        }

        this._applySetIntensity();
        this._applySetContrast();

        this._updateClipPlane();

        if (this._displayMeshContours && this._meshIntersectContourHelper) {
            this._meshIntersectContourHelper.geometry =
                this._mriStackHelper.slice.geometry;
        }
    }

    get sliceIndexPercent() {
        if (this._mriStackHelper) {
            return (
                100 *
                (this._mriStackHelper.index /
                    this._mriStackHelper._orientationMaxIndex)
            );
        } else if (this._meshStackHelper) {
            return (
                100 *
                (this._meshStackHelper.index /
                    this._meshStackHelper._orientationMaxIndex)
            );
        } else {
            return 0;
        }
    }

    set sliceIndexPercent(sliceIndexPercent) {
        this._setSliceIndexPercent(this._mriStackHelper, sliceIndexPercent);
        this._setSliceIndexPercent(this._meshStackHelper, sliceIndexPercent);

        if (this.lutEnabled && this._lutStackHelper) {
            this._setSliceIndexPercent(this._lutStackHelper, sliceIndexPercent);
            this._updateLUTLayer();
            this._updateLayerMix();
        }

        this._applySetIntensity();
        this._applySetContrast();

        this._updateClipPlane();

        if (this._displayMeshContours && this._meshIntersectContourHelper) {
            this._meshIntersectContourHelper.geometry =
                this._mriStackHelper.slice.geometry;
        }
    }

    get clipPlane() {
        return this._clipPlane;
    }

    set clipPlane(clipPlane) {
        this._clipPlane = clipPlane;
    }

    get orientationMaxIndex() {
        if (this._mriStackHelper) {
            return this._mriStackHelper._orientationMaxIndex;
        } else if (this._meshStackHelper) {
            return this._meshStackHelper._orientationMaxIndex;
        } else {
            return 0;
        }
    }

    get boundingBox() {
        if (this._mriStackHelper) {
            let rawBboxArray = this._mriStackHelper.stack.worldBoundingBox();
            return new THREE.Box3(
                new THREE.Vector3(
                    rawBboxArray[0],
                    rawBboxArray[2],
                    rawBboxArray[4]
                ),
                new THREE.Vector3(
                    rawBboxArray[1],
                    rawBboxArray[3],
                    rawBboxArray[5]
                )
            );
        } else if (this._meshStackHelper) {
            let rawBboxArray = this._meshStackHelper.stack.worldBoundingBox();
            return new THREE.Box3(
                new THREE.Vector3(
                    rawBboxArray[0],
                    rawBboxArray[2],
                    rawBboxArray[4]
                ),
                new THREE.Vector3(
                    rawBboxArray[1],
                    rawBboxArray[3],
                    rawBboxArray[5]
                )
            );
        } else {
            return new THREE.Box3(
                new THREE.Vector3(0, 0, 0),
                new THREE.Vector3(0, 0, 0)
            );
        }
    }

    get autoLevel() {
        if (this._mriStackHelper) {
            return this._mriStackHelper.slice.intesityAuto;
        } else if (this._meshStackHelper) {
            return this._meshStackHelper.slice.intesityAuto;
        } else {
            return null;
        }
    }

    set autoLevel(isOn) {
        if (isOn) {
            this._intensity = null;
            this._contrast = null;
        }
        this._setAutoLevel(this._mriStackHelper, isOn);
        this._setAutoLevel(this._meshStackHelper, isOn);
    }

    get stackHelper() {
        if (this._mriStackHelper) {
            return this._mriStackHelper;
        } else if (this._meshStackHelper) {
            return this._meshStackHelper;
        } else {
            return null;
        }
    }

    get contrast() {
        return this._contrast;
    }

    set contrast(contrastPercent) {
        this._contrast = contrastPercent;
        this._setContrast(this._mriStackHelper, contrastPercent);
        this._setContrast(this._meshStackHelper, contrastPercent);
    }

    get intensity() {
        return this._intensity;
    }

    set intensity(intensityPercent) {
        this._intensity = intensityPercent;
        this._setIntensity(this._mriStackHelper, intensityPercent);
        this._setIntensity(this._meshStackHelper, intensityPercent);
    }

    set displayMeshContours(isOn) {
        this._displayMeshContours = isOn;
    }

    get mriViewer() {
        return this._mriViewer;
    }

    set mriViewer(mriViewer) {
        this._mriViewer = mriViewer;
    }

    get meshViewer() {
        return this._meshViewer;
    }

    set meshViewer(meshViewer) {
        this._meshViewer = meshViewer;
    }

    get initialized() {
        return this._initialized;
    }

    initializeView(mriURLs) {
        if (this.lutEnabled) {
            this._sceneImgLayerTextureTarget = new THREE.WebGLRenderTarget(
                this._mriViewer.mount.clientWidth,
                this._mriViewer.mount.clientHeight,
                {
                    minFilter: THREE.NearestFilter,
                    magFilter: THREE.NearestFilter,
                    format: THREE.RGBAFormat,
                }
            );

            this._sceneLUTLayerTextureTarget = new THREE.WebGLRenderTarget(
                this._mriViewer.mount.clientWidth,
                this._mriViewer.mount.clientHeight,
                {
                    minFilter: THREE.NearestFilter,
                    magFilter: THREE.NearestFilter,
                    format: THREE.RGBAFormat,
                }
            );
        }

        return this._createView(mriURLs);
    }

    initializeLUT(lutURLs) {
        // LUT means Look-Up Table FYI
        if (!this.lutEnabled) {
            return;
        }

        let loader = new AMI.VolumeLoader(null);
        return loader
            .load(lutURLs)
            .then(() => {
                const series = loader.data[0].mergeSeries(loader.data);
                const stack = series[0].stack[0];
                loader.free();

                if (this._mriViewer) {
                    stack.prepare();
                    stack.pack();

                    // Put LUT data into a Texture
                    let lutTextures = [];
                    for (let m = 0; m < stack._rawData.length; m++) {
                        let tex = new THREE.DataTexture(
                            stack.rawData[parseInt(m)],
                            stack.textureSize,
                            stack.textureSize,
                            stack.textureType,
                            THREE.UnsignedByteType,
                            THREE.UVMapping,
                            THREE.ClampToEdgeWrapping,
                            THREE.ClampToEdgeWrapping,
                            THREE.NearestFilter,
                            THREE.NearestFilter
                        );
                        tex.needsUpdate = true;
                        tex.flipY = true;

                        lutTextures.push(tex);
                    }

                    // Link data texture and spatial information into a LUT shader uniform object
                    this._uniformsLUTLayer = AMI.DataUniformShader.uniforms();
                    this._uniformsLUTLayer.uTextureSize.value =
                        stack.textureSize;
                    this._uniformsLUTLayer.uTextureContainer.value =
                        lutTextures;
                    this._uniformsLUTLayer.uWorldToData.value = stack.lps2IJK;
                    this._uniformsLUTLayer.uNumberOfChannels.value =
                        stack.numberOfChannels;
                    this._uniformsLUTLayer.uPixelType.value = stack.pixelType;
                    this._uniformsLUTLayer.uPackedPerPixel.value =
                        stack.packedPerPixel;
                    this._uniformsLUTLayer.uBitsAllocated.value =
                        stack.bitsAllocated;
                    this._uniformsLUTLayer.uWindowCenterWidth.value = [
                        stack.windowCenter,
                        stack.windowWidth,
                    ];
                    this._uniformsLUTLayer.uRescaleSlopeIntercept.value = [
                        stack.rescaleSlope,
                        stack.rescaleIntercept,
                    ];
                    this._uniformsLUTLayer.uDataDimensions.value = [
                        stack.dimensionsIJK.x,
                        stack.dimensionsIJK.y,
                        stack.dimensionsIJK.z,
                    ];
                    this._uniformsLUTLayer.uInterpolation.value = 1;
                    this._uniformsLUTLayer.uLowerUpperThreshold.value = [
                        ...stack.minMax,
                    ];

                    // Define an actual LUT for the CPM data.
                    // This LUT determines what colors and opacities will be chosen for the CPM data values
                    let lutLayer1 = new AMI.LutHelper(
                        "mri-viewer-internal",
                        "turbo",
                        "linear",
                        TurboColorMapColorsSHIFTED,
                        TurboColorMapOpacityLevelsSHIFTED,
                        false
                    );
                    this._uniformsLUTLayer.uLut.value = 1;
                    this._uniformsLUTLayer.uTextureLUT.value =
                        lutLayer1.texture;
                    this._lutStackHelper = this._initStackHelper(stack);

                    // Define a shader program for rendering the LUT to a shader material
                    let fs = new AMI.DataFragmentShader(this._uniformsLUTLayer);
                    let vs = new AMI.DataVertexShader();
                    this._materialLUTLayer = new THREE.ShaderMaterial({
                        side: THREE.DoubleSide,
                        uniforms: this._uniformsLUTLayer,
                        vertexShader: vs.compute(),
                        fragmentShader: fs.compute(),
                    });

                    // Add LUT object to a no-display (render to texture only) scene
                    this._meshLUTLayer = new THREE.Mesh(
                        this._mriStackHelper.slice.geometry,
                        this._materialLUTLayer
                    );
                    this._meshLUTLayer.applyMatrix4(
                        this._mriStackHelper.stack._ijk2LPS
                    );
                    this._sceneLUTLayer.add(this._meshLUTLayer);

                    // Now we will create the Mix Layer
                    // mix() is a shader function that outputs a weighted sum of two pixels
                    // This Mix layer will combine the MRI 'ImgLayer' and the CPM 'LUTLayer' scene render textures
                    // uOpacity1 is the weight applied to the LUT pixels
                    this._uniformsMixLayer = AMI.LayerUniformShader.uniforms();
                    this._uniformsMixLayer.uTextureBackTest0.value =
                        this._sceneImgLayerTextureTarget.texture;
                    this._uniformsMixLayer.uTextureBackTest1.value =
                        this._sceneLUTLayerTextureTarget.texture;
                    this._uniformsMixLayer.uOpacity1.value = DefaultCPMOpacity;

                    // Define a shader program for rendering the Mix layer to a shader material
                    let fls = new AMI.LayerFragmentShader(
                        this._uniformsMixLayer
                    );
                    let vls = new AMI.LayerVertexShader();
                    this._materialMixLayer = new THREE.ShaderMaterial({
                        side: THREE.DoubleSide,
                        uniforms: this._uniformsMixLayer,
                        vertexShader: vls.compute(),
                        fragmentShader: fls.compute(),
                        transparent: true,
                    });

                    // Add Mix Layer object to the main scene for visualization
                    this._meshMixLayer = new THREE.Mesh(
                        this._mriStackHelper.slice.geometry,
                        this._materialMixLayer
                    );
                    this._meshMixLayer.applyMatrix4(
                        this._mriStackHelper.stack._ijk2LPS
                    );
                    this._mriViewer.scene.add(this._meshMixLayer);
                }

                this._lutInitialized = true;
            })
            .catch(() => {
                rootStore.dispatch(
                    setErrorState(true, clientErrorMessages.CEM_OVERLAY_FAILED)
                );
            });
    }

    renderLUT() {
        if (!this.lutEnabled || !this._lutInitialized) {
            return;
        }

        // Used as a guard to reset the render target to what it was before
        const currentTarget = this._mriViewer.renderer.getRenderTarget();

        this._mriViewer.renderer.setRenderTarget(
            this._sceneLUTLayerTextureTarget
        );
        this._mriViewer.renderer.clear();
        this._mriViewer.renderer.render(
            this._sceneLUTLayer,
            this._mriViewer.camera
        );

        this._mriViewer.renderer.setRenderTarget(
            this._sceneImgLayerTextureTarget
        );
        this._mriViewer.renderer.clear();
        this._mriViewer.renderer.render(
            this._sceneImgLayer,
            this._mriViewer.camera
        );

        this._mriViewer.renderer.setRenderTarget(currentTarget);
    }

    updateContourRenders(meshObject) {
        if (this._meshIntersectContourScene) {
            this._mriViewer.renderer.clippingPlanes = [this._clipPlane];
            const currentTarget = this._mriViewer.renderer.getRenderTarget();

            this._mriViewer.renderer.setRenderTarget(
                this._meshIntersectTextureTarget
            );

            this._mriViewer.renderer.clear();

            this._mriViewer.renderer.render(
                meshObject.scene,
                this._mriViewer.camera
            );

            this._mriViewer.renderer.setRenderTarget(null);

            //Orthographic camera to guarantee the contour quad is always rendered
            const quadCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
            this._mriViewer.renderer.render(
                this._meshIntersectContourScene,
                quadCamera
            );

            this._mriViewer.renderer.clearDepth();

            this._mriViewer.renderer.setRenderTarget(currentTarget);
        }
    }

    updateContourTextures() {
        if (this._mriStackHelper) {
            this._initContourHelpers(this._mriStackHelper.stack);
        }
    }

    showLPSAxes() {
        if (!this._meshViewer) {
            return;
        }

        let frameTransform = this.stackHelper.stack._aabb2LPS;
        let text = "MRI Frame";

        this._frameAxes = new FrameAxes();
        this._frameAxes.meshViewer = this.meshViewer;
        this._frameAxes.initializeView(frameTransform, text);
    }

    dispose() {
        if (this._mriStackHelper) this._mriStackHelper.dispose();
        if (this._meshStackHelper) this._meshStackHelper.dispose();

        if (this._meshIntersectTextureTarget)
            this._meshIntersectTextureTarget.dispose();
        if (this._meshIntersectContourHelper)
            this._meshIntersectContourHelper.dispose();
        if (this._lutEnabled) {
            if (this._lutStackHelper) this._lutStackHelper.dispose();
            if (this._materialLUTLayer) this._materialLUTLayer.dispose();
            if (this._materialMixLayer) this._materialMixLayer.dispose();
            if (this._sceneImgLayerTextureTarget)
                this._sceneImgLayerTextureTarget.dispose();
            if (this._sceneImgLayerTextureTarget)
                this._sceneLUTLayerTextureTarget.dispose();

            deleteAllObjectsInScene(this._sceneLUTLayer);
            deleteAllObjectsInScene(this._sceneImgLayer);
        }

        this._uniformsLUTLayer = null;
        this._uniformsMixLayer = null;
        this._materialLUTLayer = null;
        this._materialMixLayer = null;
        this._meshLUTLayer = null;
        this._meshMixLayer = null;
        this._sceneLUTLayer = null;
        this._sceneImgLayer = null;
    }

    async _createView(mriURLs) {
        let loader = new AMI.VolumeLoader(null);
        return await loader
            .load(mriURLs)
            .then(() => {
                const series = loader.data[0].mergeSeries(loader.data);
                series[0].stack[0]._frame = _aggregateStackFrames(series);
                const stack = series[0].stack[0];

                loader.free();

                if (this._mriViewer) {
                    this._mriStackHelper = this._initStackHelper(stack);

                    if (this.lutEnabled) {
                        this._sceneImgLayer.add(this._mriStackHelper);
                    } else {
                        this._mriViewer.scene.add(this._mriStackHelper);
                    }

                    this._mriViewer.camera.directions = [
                        stack.xCosine,
                        stack.yCosine,
                        stack.zCosine,
                    ];
                    this._calcCameraPositions(this._mriViewer);
                    this._initContourHelpers(stack);
                }

                if (this._meshViewer) {
                    this._meshStackHelper = this._initStackHelper(stack);
                    this._calcCameraPositions(this._meshViewer);
                    this._meshViewer.scene.add(this._meshStackHelper);
                    this._meshViewer.camera.directions = [
                        stack.xCosine,
                        stack.yCosine,
                        stack.zCosine,
                    ];
                }

                this._initialized = true;
            })
            .catch(() => {
                rootStore.dispatch(
                    setErrorState(true, clientErrorMessages.MRI_LOAD_FAILED)
                );
            });
    }

    _initStackHelper(stack) {
        let stackHelper = new AMI.StackHelper(stack);

        stackHelper.bbox.visible = false;
        stackHelper.index = Math.floor(0.5 * stackHelper.orientationMaxIndex);
        stackHelper.name = "MRI";
        stackHelper.border.color = 0x353535;
        stackHelper.slice.interpolation = 1;
        stackHelper.visible = this.visibility;
        return stackHelper;
    }

    _initContourHelpers(stack) {
        if (!this._displayMeshContours) {
            return;
        }

        if (!this._mriViewer) {
            return;
        }

        this._meshIntersectTextureTarget = new THREE.WebGLRenderTarget(
            this._mriViewer.mount.clientWidth,
            this._mriViewer.mount.clientHeight,
            {
                minFilter: THREE.LinearFilter,
                magFilter: THREE.NearestFilter,
                format: THREE.RGBAFormat,
            }
        );

        this._meshIntersectContourHelper = new AMI.ContourHelper(
            stack,
            this._mriStackHelper.slice.geometry
        );
        this._meshIntersectContourHelper.canvasWidth =
            this._meshIntersectTextureTarget.width;
        this._meshIntersectContourHelper.canvasHeight =
            this._meshIntersectTextureTarget.height;
        this._meshIntersectContourHelper.textureToFilter =
            this._meshIntersectTextureTarget.texture;
        this._meshIntersectContourHelper.contourWidth = 4;
        this._meshIntersectContourHelper.contourOpacity = 1.0;
        this._meshIntersectContourScene = new THREE.Scene();
        this._meshIntersectContourScene.add(this._meshIntersectContourHelper);

        this._updateClipPlane();
    }

    _updateLUTLayer() {
        if (this._meshLUTLayer) {
            this._meshLUTLayer.geometry.dispose();
            this._meshLUTLayer.geometry = this._mriStackHelper.slice.geometry;
            this._meshLUTLayer.geometry.verticesNeedUpdate = true;
        }
    }

    _updateLayerMix() {
        if (this._meshMixLayer) {
            this._mriViewer.scene.remove(this._meshMixLayer);
            this._meshMixLayer.material.dispose();
            this._meshMixLayer.material = null;
            this._meshMixLayer.geometry.dispose();
            this._meshMixLayer.geometry = null;

            // Regenerate Mix layer geometry
            this._meshMixLayer = new THREE.Mesh(
                this._mriStackHelper.slice.geometry,
                this._materialMixLayer
            );
            this._meshMixLayer.applyMatrix4(
                this._mriStackHelper.stack._ijk2LPS
            );
            this._mriViewer.scene.add(this._meshMixLayer);
        }
    }

    _applySetIntensity() {
        if (this._intensity) {
            this.intensity = this._intensity;
        }
    }

    _applySetContrast() {
        if (this._contrast) {
            this.contrast = this._contrast;
        }
    }

    _calcCameraPositions(viewer) {
        if (!viewer) {
            return;
        }
        const worldbb = this.boundingBox;
        let center = worldbb.getCenter(viewer.controls.target);

        viewer.axialCam = new THREE.Vector3(
            center.x,
            center.y,
            center.z + this._defaultCameraDistance
        );
        viewer.corCam = new THREE.Vector3(
            center.x,
            center.y - this._defaultCameraDistance,
            center.z
        );
        viewer.sagCam = new THREE.Vector3(
            center.x - this._defaultCameraDistance,
            center.y,
            center.z
        );
    }

    _updateClipPlane() {
        let stackHelper;

        if (this._mriStackHelper) {
            stackHelper = this._mriStackHelper;
        } else if (this._meshStackHelper) {
            stackHelper = this._meshStackHelper;
        } else {
            return;
        }

        let vertices = stackHelper.slice.geometry.vertices;

        let p1 = new THREE.Vector3(
            vertices[0].x,
            vertices[0].y,
            vertices[0].z
        ).applyMatrix4(stackHelper._stack.ijk2LPS);

        let p2 = new THREE.Vector3(
            vertices[1].x,
            vertices[1].y,
            vertices[1].z
        ).applyMatrix4(stackHelper._stack.ijk2LPS);

        let p3 = new THREE.Vector3(
            vertices[2].x,
            vertices[2].y,
            vertices[2].z
        ).applyMatrix4(stackHelper._stack.ijk2LPS);

        this._clipPlane.setFromCoplanarPoints(p1, p2, p3);

        if (
            this.orientation === sceneOrientations.AXIAL ||
            this.orientation === sceneOrientations.CORONAL
        ) {
            this._clipPlane.negate();
        }
    }

    _setOrientation(stackHelper, orientation) {
        if (stackHelper === this._mriStackHelper) {
            if (!this._mriViewer) {
                return;
            }

            let axis = _getCameraDirectionVector(orientation, this._mriViewer);
            let up = _getOrientationUpVector(orientation);
            let orientationIdx = _getAMIOrientationIdx(orientation);

            if (!axis || !up) {
                return;
            }

            stackHelper.orientation = orientationIdx;
            stackHelper.index = Math.floor(
                0.5 * stackHelper.orientationMaxIndex
            );

            this._mriViewer.camera.position.copy(axis);
            this._mriViewer.camera.rotation.set(0, 0, 0);
            this._mriViewer.camera.up.copy(up);
            this._mriViewer.camera.updateMatrixWorld();

            const worldbb = this.boundingBox;
            worldbb.getCenter(this._mriViewer.controls.target);

            if (this._displayMeshContours && this._meshIntersectContourHelper) {
                this._meshIntersectContourHelper.geometry =
                    this._mriStackHelper.slice.geometry;
            }

            this._updateClipPlane();
        } else {
            if (!this._meshViewer) {
                return;
            }

            let axis = _getCameraDirectionVector(orientation, this._meshViewer);
            let up = _getOrientationUpVector(orientation);
            let orientationIdx = _getAMIOrientationIdx(orientation);

            if (!axis || !up) {
                return;
            }

            stackHelper.orientation = orientationIdx;
            stackHelper.index = Math.floor(
                0.5 * stackHelper.orientationMaxIndex
            );

            this._meshViewer.camera.position.copy(axis);
            this._meshViewer.camera.rotation.set(0, 0, 0);
            this._meshViewer.camera.up.copy(up);
        }
    }

    _setSliceIndexPercent(stackHelper, sliceIndexPercent) {
        if (stackHelper) {
            let sliceIndex = Math.floor(
                (sliceIndexPercent / 100) * stackHelper._orientationMaxIndex
            );

            this._setSliceIndex(stackHelper, sliceIndex);
        }
    }

    _setSliceIndex(stackHelper, sliceIndex) {
        if (stackHelper) {
            stackHelper.index = sliceIndex;
        }
    }

    _setContrast(stackHelper, contrastPercent) {
        if (stackHelper) {
            stackHelper.slice.windowWidth = Math.floor(
                contrastPercent * stackHelper.stack.minMax[1]
            );
        }
    }

    _setIntensity(stackHelper, intensityPercent) {
        if (stackHelper) {
            stackHelper.slice.windowCenter = Math.floor(
                intensityPercent * stackHelper.stack.minMax[1]
            );
        }
    }

    _setAutoLevel(stackHelper, isOn) {
        if (stackHelper) {
            stackHelper.slice.intesityAuto = isOn;
            stackHelper.index += 1;
            stackHelper.index -= 1;
        }
    }
}

function _getAMIOrientationIdx(orientation) {
    switch (orientation) {
        case sceneOrientations.AXIAL:
            return 0;

        case sceneOrientations.CORONAL:
            return 2;

        case sceneOrientations.SAGITTAL:
            return 1;

        default:
            break;
    }
}

function _getOrientationUpVector(orientation) {
    switch (orientation) {
        case sceneOrientations.AXIAL:
            return new THREE.Vector3(0, -1, 0);

        case sceneOrientations.CORONAL:
            return new THREE.Vector3(0, 0, -1);

        case sceneOrientations.SAGITTAL:
            return new THREE.Vector3(0, 0, -1);

        default:
            return null;
    }
}

function _getCameraDirectionVector(orientation, viewer) {
    switch (orientation) {
        case sceneOrientations.AXIAL:
            return viewer.axialCam;

        case sceneOrientations.CORONAL:
            return viewer.corCam;

        case sceneOrientations.SAGITTAL:
            return viewer.sagCam;

        default:
            return null;
    }
}

function _aggregateStackFrames(series) {
    let frames = [];
    for (var i = 0; i < series[0].stack.length; i++) {
        frames = frames.concat(series[0].stack[parseInt(i)]._frame);
    }
    return frames;
}
