import React, { Component } from "react";
import uncheckedBox from "../../../assets/UncheckedSquare.svg";
import checkedBox from "../../../assets/CheckedSquare.svg";
import "./styles.css";
import { Form } from "react-bootstrap";
import { EMPTY_STRING } from "../../../constants";
import { DIMENSION_LABELS, MAX_TOOL_VOLUME_DIMENSION } from "../constants";
import { VOLUME_SHAPES } from "../../CreateTPlan_4EditMargin/constants";
import cubeIcon from "../../../assets/CAB_Cube_Icon.svg";
import prismIcon from "../../../assets/CAB_Prism_Icon.svg";
import sphereIcon from "../../../assets/CAB_Sphere_Icon.svg";
import ellipseIcon from "../../../assets/CAB_Ellipse_Icon.svg";
import PropTypes from "prop-types";
import { debounce } from "debounce";
import AHButton from "../../__shared__/StyleGuide/AHButton";

export default class VolumeDimensionMenu extends Component {
    constructor(props) {
        super(props);
        this.debounceSaveVolume = debounce(this.props.saveVolume, 1000, true);
    }

    getDimensionParameters(startingVolume) {
        let dimParams;

        switch (startingVolume) {
            case VOLUME_SHAPES.CUBE:
                dimParams = {
                    xText: "Edge Length",
                };
                break;
            case VOLUME_SHAPES.SPHERE:
                dimParams = {
                    xText: "Radius",
                };
                break;
            case VOLUME_SHAPES.PRISM:
                dimParams = {
                    xText: "X-Axis Length",
                    yText: "Y-Axis Length",
                    zText: "Z-Axis Length",
                };
                break;
            case VOLUME_SHAPES.ELLIPSE:
                dimParams = {
                    xText: "X-Axis Radius",
                    yText: "Y-Axis Radius",
                    zText: "Z-Axis Radius",
                };
                break;
            default:
                dimParams = {
                    xText: "X-Axis Length",
                    yText: "Y-Axis Length",
                    zText: "Z-Axis Length",
                };
        }

        return dimParams;
    }

    renderVolumeImageButton(shapeName, volumeShape, iconSrc) {
        return (
            <div
                className={`volume-img-btn`}
                onClick={() => {
                    this.props.setStartingVolume(volumeShape);
                }}
            >
                <div
                    className={`icon-border-box ${
                        this.props.startingVolume === volumeShape
                            ? "selected"
                            : EMPTY_STRING
                    }`}
                >
                    <img
                        className={"volume-img-icon"}
                        src={iconSrc}
                        alt={"volume icon"}
                    />
                </div>
                <div className={"volume-btn-txt"}>{shapeName}</div>
            </div>
        );
    }

    render() {
        let displayDims =
            this.props.startingVolume !== null ? "active" : "inactive";
        let dimParams = this.getDimensionParameters(this.props.startingVolume);

        return (
            <div className={"custom-tool-profile-workflow-sidebar"}>
                <div className={"workflow-title-cntr"}>
                    <div className={"workflow-title display-30"}>
                        Create a Custom Tool Profile
                    </div>
                </div>
                <div className={"workflow-cntr"}>
                    <div className={"starting-volume-cntr"}>
                        <div className={"starting-volume-txt display-16"}>
                            Choose a starting Volume
                        </div>
                        <div className={"volume-img-cntr display-16"}>
                            {this.renderVolumeImageButton(
                                "Cube",
                                VOLUME_SHAPES.CUBE,
                                cubeIcon
                            )}
                            {this.renderVolumeImageButton(
                                "Prism",
                                VOLUME_SHAPES.PRISM,
                                prismIcon
                            )}
                            {this.renderVolumeImageButton(
                                "Sphere",
                                VOLUME_SHAPES.SPHERE,
                                sphereIcon
                            )}
                            {this.renderVolumeImageButton(
                                "Ellipse",
                                VOLUME_SHAPES.ELLIPSE,
                                ellipseIcon
                            )}
                        </div>
                    </div>
                    <div
                        className={`volume-dims-cntr display-16 ${displayDims}`}
                    >
                        <div className={"volume-dims-title display-16"}>
                            Set tool volume dimensions
                        </div>
                        <div className={"volume-dim-item"}>
                            <div
                                className={`dim-color-bar ${DIMENSION_LABELS.X}`}
                            />
                            <div className={"dim-label-text"}>
                                {dimParams.xText}
                            </div>
                            <Form>
                                <Form.Control
                                    type={"number"}
                                    id={"x-len"}
                                    className={"h-100 d-inline-block"}
                                    size={"sm"}
                                    value={this.props.xVal}
                                    onChange={(event) => {
                                        this.props.handleDimensionChange(
                                            DIMENSION_LABELS.X,
                                            event
                                        );
                                    }}
                                    min={0.0}
                                    max={MAX_TOOL_VOLUME_DIMENSION}
                                />
                            </Form>
                            <div className={"vol-len-unit-txt"}>mm</div>
                        </div>
                        <div
                            className={`volume-dim-item ${
                                dimParams.yText ? EMPTY_STRING : "hide-dim"
                            }`}
                        >
                            <div
                                className={`dim-color-bar ${DIMENSION_LABELS.Y}`}
                            />
                            <div className={"dim-label-text"}>
                                {dimParams.yText}
                            </div>
                            <Form>
                                <Form.Control
                                    type={"number"}
                                    id={"y-len"}
                                    className={"h-100 d-inline-block"}
                                    size={"sm"}
                                    value={this.props.yVal}
                                    onChange={(event) => {
                                        this.props.handleDimensionChange(
                                            DIMENSION_LABELS.Y,
                                            event
                                        );
                                    }}
                                    min={0.0}
                                    max={MAX_TOOL_VOLUME_DIMENSION}
                                />
                            </Form>
                            <div className={"vol-len-unit-txt"}>mm</div>
                        </div>
                        <div
                            className={`volume-dim-item ${
                                dimParams.zText ? EMPTY_STRING : "hide-dim"
                            }`}
                        >
                            <div
                                className={`dim-color-bar ${DIMENSION_LABELS.Z}`}
                            />
                            <div className={"dim-label-text"}>
                                {dimParams.zText}
                            </div>
                            <Form>
                                <Form.Control
                                    type={"number"}
                                    id={"z-len"}
                                    className={"h-100 d-inline-block"}
                                    size={"sm"}
                                    value={this.props.zVal}
                                    onChange={(event) => {
                                        this.props.handleDimensionChange(
                                            DIMENSION_LABELS.Z,
                                            event
                                        );
                                    }}
                                    min={0.0}
                                    max={MAX_TOOL_VOLUME_DIMENSION}
                                />
                            </Form>
                            <div className={"vol-len-unit-txt"}>mm</div>
                        </div>
                        <div className={"volume-dim-item top-margin-spacing"}>
                            <img
                                src={
                                    this.props.hideApplicator
                                        ? checkedBox
                                        : uncheckedBox
                                }
                                alt={"hide applicator box"}
                                onClick={() => {
                                    this.props.handleDimensionChange(
                                        DIMENSION_LABELS.APPLICATOR,
                                        {
                                            target: {
                                                value: !this.props
                                                    .hideApplicator,
                                            },
                                        }
                                    );
                                }}
                            />
                            <div className={"checkbx-label-text"}>
                                Hide Interstitial Applicator
                            </div>
                        </div>
                        <div
                            className={
                                "volume-dims-title display-16 top-margin-spacing"
                            }
                        >
                            Set Interstitial Applicator offset
                        </div>
                        <div className={"volume-dim-item"}>
                            <div
                                className={`dim-color-bar ${DIMENSION_LABELS.Y}`}
                            />
                            <div className={"dim-label-text"}>
                                Y Axis Offset
                            </div>
                            <Form>
                                <Form.Control
                                    type={"number"}
                                    id={"zoff-len"}
                                    className={"h-100 d-inline-block"}
                                    size={"sm"}
                                    value={this.props.zOffset}
                                    onInput={(event) => {
                                        this.props.handleDimensionChange(
                                            DIMENSION_LABELS.OFFSET,
                                            event
                                        );
                                    }}
                                    min={-MAX_TOOL_VOLUME_DIMENSION}
                                    max={MAX_TOOL_VOLUME_DIMENSION}
                                />
                            </Form>
                            <div className={"vol-len-unit-txt"}>mm</div>
                        </div>
                        <div
                            className={
                                "volume-dims-title display-16 top-margin-spacing"
                            }
                        >
                            Tool Volume Name:
                        </div>
                        <div className={"volume-dim-item"}>
                            <Form className={"ablation-volume-name-form"}>
                                <Form.Control
                                    type={"text"}
                                    className={
                                        "ablation-volume-name h-100 w-100 d-block"
                                    }
                                    size={"lg"}
                                    value={this.props.volumeName}
                                    onChange={(event) => {
                                        this.props.handleDimensionChange(
                                            DIMENSION_LABELS.NAME,
                                            event
                                        );
                                    }}
                                    placeholder={"Enter Tool Volume Name"}
                                />
                            </Form>
                        </div>
                        <div
                            className={"volume-dim-item"}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <AHButton
                                id={"save-volume-btn"}
                                className={`save-vol-btn`}
                                isDisabled={!this.props.allowSave}
                                onClick={this.debounceSaveVolume}
                                size={"large"}
                                text={"Save Tool Volume"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

VolumeDimensionMenu.propTypes = {
    xVal: PropTypes.number,
    yVal: PropTypes.number,
    zVal: PropTypes.number,
    handleDimensionChange: PropTypes.func,
    zOffset: PropTypes.number,
    volumeName: PropTypes.string,
    allowSave: PropTypes.bool,
    hideApplicator: PropTypes.func,
    startingVolume: PropTypes.string,
    setStartingVolume: PropTypes.func,
    saveVolume: PropTypes.func,
};
