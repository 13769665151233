import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";

export default class PopupBar extends Component {
    render() {
        return (
            <div className={"popup-bar"}>
                <p id={"popup-bar-text"} className={"display-16"}>
                    {this.props.text}
                </p>
                <button
                    onClick={this.props.closePopup}
                    id={"popup-bar-close-button"}
                    className={"display-16"}
                >
                    X
                </button>
            </div>
        );
    }
}

PopupBar.propTypes = {
    text: PropTypes.string,
    closePopup: PropTypes.func,
};

PopupBar.defaultProps = {
    text: "POPUP_POPUP_POPUP",
    closePopup: undefined,
};
