import { vsprintf } from "sprintf-js";
import {
    API_ENDPOINT,
    API_VERSION,
    clientErrorMessages,
    httpMethods,
    SUCCESS,
} from "../../constants";
import { rootStore } from "../../redux/store";
import { setErrorState } from "../../redux/error_banner/actions";

export async function generateMFAAuth(userUUID, token) {
    return await fetch(
        vsprintf("%s/api/%s/auth/otp/generate", [API_ENDPOINT, API_VERSION]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            mode: "cors",
            body: JSON.stringify({
                userUUID: userUUID,
            }),
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }

            throw new Error(clientErrorMessages.MFA_AUTH_FAILED);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function checkOTPCode(
    userUUID,
    OTPtoken,
    firstTimeVerification = false
) {
    let urlSuffix;
    if (firstTimeVerification) {
        urlSuffix = "verify";
    } else {
        urlSuffix = "validate";
    }

    return await fetch(
        vsprintf("%s/api/%s/auth/otp/%s", [
            API_ENDPOINT,
            API_VERSION,
            urlSuffix,
        ]),
        {
            method: httpMethods.POST,
            mode: "cors",
            body: JSON.stringify({
                userUUID: userUUID,
                token: OTPtoken,
            }),
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json) {
                return json.payload;
            }
        });
}
