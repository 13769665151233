import React, { Component } from "react";
import SplitPane from "react-split-pane";
import TopBar from "../__shared__/TopBar";
import ProfileMenu from "../__shared__/ProfileMenu";
import StepMarkerBar from "../__shared__/StepMarkerBar";
import AvendaLogo from "../__shared__/AvendaLogo";
import PreviousStepButton from "../__shared__/PreviousStepButton";
import NextStepButton from "../__shared__/NextStepButton";
import { connect } from "react-redux";
import {
    BIOPSY_STEP,
    EDIT_MARGIN_STEP,
    SUMMARY_STEP,
} from "../CreateTPlan_1Upload/constants";
import {
    setCurrentTreatmentPlan,
    setStepActive,
    setStepComplete,
} from "../CreateTPlan_1Upload/actions";
import "./styles.css";
import { withRouter } from "react-router-dom";
import MRIControlBar from "../__shared__/MRIControlBar";
import ButtonGroup from "../__shared__/ButtonGroup";
import SliceControl from "../__shared__/SliceControl";
import MRIViewer from "../__shared__/Viewers/MRIViewer";
import { getLoadingScreen, hasProperty, isEmpty } from "../../helpers/helpers";
import {
    pageLoadStates,
    stepPath,
    USER_MANUAL_URL,
    EMPTY_STRING,
} from "../../constants";
import RequirementsModal from "../__shared__/RequirementsModal";
import MRIViewerButton from "../__shared__/MRIViewerButton";
import NavCubeModal from "../__shared__/NavCubeModal";
import cubeBlue from "../../assets/nav_cube_blue.svg";
import cubeLight from "../../assets/nav_cube_light.svg";
import cubeDark from "../../assets/nav_cube_dark.svg";
import filterBlue from "../../assets/filter_blue.svg";
import filterLight from "../../assets/filter_light.svg";
import filterDark from "../../assets/filter_dark.svg";
import LesionContourWorkflow from "./LesionContourWorkflow";
import WorkflowManager from "./WorkflowManager";
import { getTreatmentPlan } from "../CreateTPlan_1Upload/helpers";
import FilterToggle from "../__shared__/FilterToggle";
import PropTypes from "prop-types";
import AblationWorkflow from "./AblationWorkflow";
import { setErrorState } from "../../redux/error_banner/actions";
import * as TreatmentPlan from "../../helpers/tplan/tplan";
import TargetSetModel from "../__shared__/Viewers/Structures/Targets/TargetSetModel";
import TargetSetController from "../__shared__/Viewers/Structures/Targets/TargetSetController";
import MRIModel from "../__shared__/Viewers/Structures/MRI/MRIModel";
import MeshViewer from "../__shared__/Viewers/MeshViewer";
import MRIController from "../__shared__/Viewers/Structures/MRI/MRIController";
import PatientAnatomyModel from "../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyModel";
import {
    loadBxCores,
    loadMargin,
    loadMRI,
    loadOtherStructures,
    loadProstate,
    loadROIs,
    resetViewer,
} from "../../helpers/load_helpers";
import AblationStatisticsConfirmPrompt from "./AblationStatisticsConfirmPrompt";
import { setDebugHooks } from "../../helpers/debug/debug_hooks";
import CLCWidget from "../__shared__/CLCWidget";
import { getCancerLesionCoverage } from "../__shared__/Viewers/helpers";
import { updateTreatmentPlan } from "../../helpers/backend_api";
import PatientAnatomyController from "../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyController";
import ResetViewerButton from "../__shared__/ResetViewerButton";
import {
    mriViewerButtonTooltip,
    RESET_VIEW,
} from "../CreateTPlan_2SegMRI/constants";
import DecisionAlertCard from "../__shared__/DecisionAlertCard";
import { CEM_WARNING_TITLE, cemWarningMsgFooter } from "./constants";
import { getCemWarningMsg } from "./helpers";
import WarningTriangle from "../__shared__/IconComponents/WarningTriangle";

class CreateTPlan_4EditMargin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayAlert: false,
            loadState: pageLoadStates.LOADING,
            ablationSitesLoaded: false,
            cancerLesionCoverage: 0,
            cancerLesionCoverageLoaded: false,
            showCLCWidget: false,
            showCemWarningModal: false,
            cemWarningMsg: EMPTY_STRING,
        };

        this.requirements = {
            required: [
                {
                    id: "margin-req",
                    satisfied: false,
                    reqText:
                        "Confirm a cancer lesion contour if Gleason Grade Group > 1",
                    listIndex: 1,
                },
            ],
            optionals: [
                {
                    id: "target-req",
                    satisfied: false,
                    reqText: "Plan 0 or more tool volume placement sites",
                    listIndex: 2,
                },
            ],
        };

        this.mriRef = React.createRef();
        this.meshRef = React.createRef();
        this.sliceControlRef = React.createRef();
        this.targetMngrRef = React.createRef();

        this.mriModel = new MRIModel(true);
        this.mriController = new MRIController(this.mriModel);
        this.patientAnatomyModel = new PatientAnatomyModel();
        this.targetSetModel = new TargetSetModel();
        this.targetSetController = new TargetSetController(
            this.targetSetModel,
            this.mriModel
        );
        this.patientAnatomyModel.targetSet = this.targetSetModel;
        this.patientAnatomyController = new PatientAnatomyController(
            this.patientAnatomyModel,
            this.targetSetModel
        );

        setDebugHooks({
            mriModel: this.mriModel,
            patientAnatomyModel: this.patientAnatomyModel,
            targetSetModel: this.targetSetModel,
        });

        this.nextStep = this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.handleMarginCreated = this.handleMarginCreated.bind(this);
        this.requirementsCheck = this.requirementsCheck.bind(this);
        this.verifyTargetsBtnCallback =
            this.verifyTargetsBtnCallback.bind(this);
        this.goToAblationSiteMenu = this.goToAblationSiteMenu.bind(this);
        this.updateCoverage = this.updateCoverage.bind(this);
        this.handleShowCemWarningModal =
            this.handleShowCemWarningModal.bind(this);
    }

    goToAblationSiteMenu() {
        this.setState({
            forcedIdx: 3,
            btnGroupForceReset: this.state.btnGroupForceReset + 1,
        });
    }

    componentDidMount() {
        let scope = this;

        getTreatmentPlan(
            this.props.authToken,
            this.props.patientuuid,
            this.props.planuuid
        )
            .then((payload) => payload.json())
            .then((json) => {
                let plan = json.payload.plan;
                scope.props.setCurrentTreatmentPlan(plan);
                this.getCemWarnings(plan);
                this.getMRIURLs(plan);
                this.getMeshURLs(plan);
            })
            .catch(() => {
                scope.setState({ loadState: pageLoadStates.FAILED });
            });
    }

    componentDidUpdate() {
        this.targetSetController.mouseControlsEnabled =
            !this.state.displayAlert;
        if (
            this.state.ablationSitesLoaded &&
            !this.state.cancerLesionCoverageLoaded
        ) {
            this.updateCoverage();
        }
    }

    handleShowCemWarningModal() {
        this.setState({
            showCemWarningModal: false,
        });
    }

    prevStep() {
        this.props.setStepActive(EDIT_MARGIN_STEP, false);
        this.props.setStepComplete(EDIT_MARGIN_STEP, false);
        this.props.setStepActive(BIOPSY_STEP, true);

        let plan = this.props.currentTreatmentPlan;
        plan.SavedData.PlanCreationStep = BIOPSY_STEP;
        this.props.setCurrentTreatmentPlan(plan);

        updateTreatmentPlan({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            token: this.props.authToken,
            plan: plan,
        });
    }

    nextStep() {
        let scope = this;
        let plan = this.props.currentTreatmentPlan;
        if (hasProperty(plan, "SavedData.PlanCreationStep")) {
            plan.SavedData.PlanCreationStep = SUMMARY_STEP;
        }

        updateTreatmentPlan({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            token: this.props.authToken,
            plan: plan,
        }).finally(() => {
            scope.props.history.push(stepPath.STEP5);
            scope.props.setStepComplete(EDIT_MARGIN_STEP, true);
            scope.props.setStepActive(EDIT_MARGIN_STEP, false);
            scope.props.setStepActive(SUMMARY_STEP, true);
        });
    }

    updateCoverage() {
        if (!this.state.showCLCWidget) return;

        if (!this.cancerLesionCoverageInstance)
            this.cancerLesionCoverageInstance = getCancerLesionCoverage(
                this.mriModel.meshViewer
            );
        let cancerLesionCoverage = this.cancerLesionCoverageInstance();
        this.setState({
            cancerLesionCoverageLoaded: true,
            cancerLesionCoverage,
        });
    }

    verifyTargetsBtnCallback() {
        let scope = this;

        if (this.requirementsCheck()) {
            scope.setState({ displayAlert: true });
            return true;
        } else {
            this.requirementsModalRef.openModal();
            return false;
        }
    }

    getCemWarnings(plan) {
        if (!plan.SavedData.CEMWarnings) {
            return;
        }

        let cemWarnings = plan.SavedData.CEMWarnings;

        let warningString = getCemWarningMsg(cemWarnings);

        if (warningString === EMPTY_STRING) {
            return;
        }

        this.setState({
            showCemWarningModal: true,
            cemWarningMsg: warningString,
        });
    }

    getMeshURLs(plan) {
        let scope = this;

        let ids = {
            token: scope.props.authToken,
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            visituuid: scope.props.visituuid,
        };

        loadBxCores(scope, plan);
        loadProstate(scope, plan, ids, true, true);
        loadROIs(scope, plan, ids);
        loadMargin(scope, plan, ids);
        loadOtherStructures(scope, plan, ids);
    }

    getMRIURLs(plan) {
        let scope = this;

        let ids = {
            token: scope.props.authToken,
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            visituuid: scope.props.visituuid,
        };

        loadMRI(scope, plan, ids);
    }

    requirementsCheck() {
        if (TreatmentPlan.getGleasonGradeGroup(TreatmentPlan) >= 2) {
            return TreatmentPlan.hasMargins(
                this.props.currentTreatmentPlan.TreatmentPlan
            );
        } else {
            return true;
        }
    }

    getRequirements() {
        if (TreatmentPlan.getGleasonGradeGroup(TreatmentPlan) >= 2) {
            this.requirements.required[0].satisfied = TreatmentPlan.hasMargins(
                this.props.currentTreatmentPlan.TreatmentPlan
            );
        } else {
            this.requirements.required[0].satisfied = true;
        }

        this.requirements.optionals[0].satisfied = TreatmentPlan.hasTargets(
            this.props.currentTreatmentPlan.TreatmentPlan
        );

        return this.requirements;
    }

    handleMarginCreated() {
        let scope = this;
        let plan = this.props.currentTreatmentPlan;
        let tplan = plan.TreatmentPlan;

        if (isEmpty(tplan) || !TreatmentPlan.hasMargins(tplan)) {
            return null;
        }

        let ids = {
            token: scope.props.authToken,
            useruuid: scope.props.useruuid,
            patientuuid: scope.props.patientuuid,
            visituuid: scope.props.visituuid,
        };

        scope.patientAnatomyModel.margin = null;
        loadMargin(scope, plan, ids);
    }

    getAlert(isOn) {
        if (!isOn) {
            return null;
        }

        if (!this.cancerLesionCoverageInstance)
            this.cancerLesionCoverageInstance = getCancerLesionCoverage(
                this.mriModel.meshViewer
            );

        return (
            <AblationStatisticsConfirmPrompt
                noCallback={() => {
                    this.setState({ displayAlert: false });
                }}
                yesCallback={this.nextStep}
                targetSetModel={this.targetSetModel}
                cancerLesionCoverageInstance={this.cancerLesionCoverageInstance}
            />
        );
    }

    render() {
        return (
            <div className={"create-plan-edit-margin-screen"}>
                <TopBar>
                    <AvendaLogo id={"create-plan-avenda-logo"} to={"/home"} />
                    <div className={"create-plan-step-bar-container"}>
                        <div className={"step-bar-prev-btn-group"}>
                            <PreviousStepButton
                                to={stepPath.STEP3}
                                callback={this.prevStep}
                                isReady={
                                    this.state.loadState !==
                                    pageLoadStates.LOADING
                                }
                            />
                            <StepMarkerBar />
                        </div>
                        <NextStepButton
                            reqsComplete={this.requirementsCheck()}
                            reqsCompleteTxt={"Verify Tool Placements"}
                            noLink={true}
                            text={""}
                            to={stepPath.STEP5}
                            callback={this.verifyTargetsBtnCallback}
                        />
                    </div>
                    <ProfileMenu />
                </TopBar>
                <RequirementsModal
                    id={"edit-margin-pull-tab"}
                    onRef={(ref) => (this.requirementsModalRef = ref)}
                    requirements={this.getRequirements()}
                />
                {getLoadingScreen(this.state.loadState)}
                {this.state.showCemWarningModal && (
                    <DecisionAlertCard
                        id="cem-warning-modal"
                        singleBtn={true}
                        yesBtnTxt="Confirm"
                        yesBtnCallback={this.handleShowCemWarningModal}
                    >
                        <div className="cem-warning-modal-children">
                            <div className="cem-warning-header">
                                <WarningTriangle width="32" height="27" />
                                <div className="display-regular-28">
                                    {CEM_WARNING_TITLE}
                                </div>
                            </div>
                            <div className="cem-warning-content display-regular-16">
                                <p>{this.state.cemWarningMsg}</p>
                                <p>
                                    {cemWarningMsgFooter.FOOTER_PREFIX}{" "}
                                    <a
                                        href={USER_MANUAL_URL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {cemWarningMsgFooter.USER_MANUAL}
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </DecisionAlertCard>
                )}
                <WorkflowManager>
                    <LesionContourWorkflow
                        onMarginCreation={this.handleMarginCreated}
                    />
                    <AblationWorkflow
                        ref={this.targetMngrRef}
                        loadState={this.state.loadState}
                        ablationSitesLoaded={this.state.ablationSitesLoaded}
                        mriModel={this.mriModel}
                        targetSetController={this.targetSetController}
                        targetSetModel={this.targetSetModel}
                    />
                </WorkflowManager>
                {this.getAlert(this.state.displayAlert)}
                <div className={"mri-scene-container"}>
                    <MRIControlBar>
                        <div className={"mri-control-bar-left"}>
                            <ButtonGroup>
                                <MRIViewerButton
                                    toolTipText={
                                        mriViewerButtonTooltip.SCENE_ORIENTATION
                                    }
                                    name={"nav-cube"}
                                    imgBlue={cubeBlue}
                                    imgDark={cubeDark}
                                    imgLight={cubeLight}
                                    alt={
                                        mriViewerButtonTooltip.SCENE_ORIENTATION
                                    }
                                >
                                    <NavCubeModal
                                        mriModel={this.mriModel}
                                        resetTick={() =>
                                            this.sliceControlRef.current.reset()
                                        }
                                    />
                                </MRIViewerButton>
                                <MRIViewerButton
                                    toolTipText={mriViewerButtonTooltip.FILTER}
                                    name={"filter"}
                                    imgBlue={filterBlue}
                                    imgDark={filterDark}
                                    imgLight={filterLight}
                                    alt={mriViewerButtonTooltip.FILTER}
                                >
                                    <FilterToggle
                                        mriModel={this.mriModel}
                                        patientAnatomyModel={
                                            this.patientAnatomyModel
                                        }
                                        targetSetModel={this.targetSetModel}
                                        displaySettings={{
                                            axesEnabled: true,
                                            mriEnabled: true,
                                            noMRIOption: true,
                                        }}
                                    />
                                </MRIViewerButton>
                            </ButtonGroup>
                            <SliceControl
                                ref={this.sliceControlRef}
                                mriModel={this.mriModel}
                                mriController={this.mriController}
                            />
                        </div>
                        <div className={"mri-control-bar-right"}>
                            <ResetViewerButton
                                toolTipText={RESET_VIEW}
                                resetViewer={() =>
                                    resetViewer(
                                        this.meshRef,
                                        this.mriRef,
                                        this.sliceControlRef,
                                        this.mriModel
                                    )
                                }
                                style={{ marginRight: "40px" }}
                            />
                        </div>
                    </MRIControlBar>
                    <div className={"mri-viewer"}>
                        <SplitPane
                            defaultSize={"60%"}
                            minSize={"0px"}
                            split={"vertical"}
                            pane1ClassName={"mri-pane"}
                            pane2ClassName={"mesh-pane"}
                            onDragFinished={() => {
                                this.mriRef.current.onWindowResize();
                                this.meshRef.current.onWindowResize();
                            }}
                        >
                            <MRIViewer
                                ref={this.mriRef}
                                targetSetController={this.targetSetController}
                                targetSetModel={this.targetSetModel}
                                mriModel={this.mriModel}
                                mriController={this.mriController}
                                patientAnatomyModel={this.patientAnatomyModel}
                                updateCoverageCallback={this.updateCoverage}
                            >
                                {this.state.showCLCWidget && (
                                    <CLCWidget
                                        coverageText={
                                            this.state
                                                .cancerLesionCoverageLoaded
                                                ? Math.round(
                                                      this.state
                                                          .cancerLesionCoverage *
                                                          100
                                                  ) + "%"
                                                : "Loading"
                                        }
                                    />
                                )}
                            </MRIViewer>
                            <MeshViewer
                                ref={this.meshRef}
                                staticViewer={true}
                                displaySettings={{
                                    mriEnabled: false,
                                    composerEnabled: true,
                                }}
                                targetSetController={this.targetSetController}
                                targetSetModel={this.targetSetModel}
                                mriModel={this.mriModel}
                                mriController={this.mriController}
                                patientAnatomyModel={this.patientAnatomyModel}
                                patientAnatomyController={
                                    this.patientAnatomyController
                                }
                                activateCoreClick={false}
                            />
                        </SplitPane>
                    </div>
                </div>
            </div>
        );
    }
}

CreateTPlan_4EditMargin.propTypes = {
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    visituuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
    displaySettings: PropTypes.object,
    setStepActive: PropTypes.func,
    history: PropTypes.object,
    setStepComplete: PropTypes.func,
    setErrorState: PropTypes.func,
    setCurrentTreatmentPlan: PropTypes.func,
};

CreateTPlan_4EditMargin.defaultProps = {
    useruuid: EMPTY_STRING,
    authToken: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    currentTreatmentPlan: {},
    displaySettings: {},
};

/* istanbul ignore next */
const mapStateToProps = function (state, ownProps) {
    let displaySettings = isEmpty(ownProps.displaySettings)
        ? state.ImageViewerReducer.displaySettings
        : ownProps.displaySettings;

    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
        displaySettings: displaySettings,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setStepActive: (stepIdx, isActive) =>
            dispatch(setStepActive(stepIdx, isActive)),
        setStepComplete: (stepIdx, isComplete) =>
            dispatch(setStepComplete(stepIdx, isComplete)),
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        setErrorState: (hasError, errorText) =>
            dispatch(setErrorState(hasError, errorText)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateTPlan_4EditMargin)
);
