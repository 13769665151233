import { EMPTY_STRING } from "../../constants";

export const initialState = {
    userData: {},
    flags: {
        isAuthenticated: false,
        planCompleted: true,
    },
    pwText: EMPTY_STRING,
    unText: EMPTY_STRING,
    authToken: EMPTY_STRING,
    useruuid: EMPTY_STRING,
};
