import React, { forwardRef } from "react";
import PropTypes from "prop-types";

/**
 * React Bootstrap's `OverlayTrigger` component requires that the triggering component
 * (i.e., QuestionMark) must be able to accept a `ref` in order to:
 *
 * 1. Manage DOM events properly (e.g., hover, click, focus) for showing and hiding the tooltip.
 * 2. Correctly position the tooltip relative to the triggering element in the DOM.
 */
const QuestionMark = forwardRef(
    ({ width = "26", height = "26", color = "#d8d8d8" }, ref) => {
        return (
            <svg
                ref={ref}
                width={width}
                height={height}
                viewBox="0 0 26 26"
                role="img"
                aria-label="Question mark icon"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g fill="none">
                    <g transform="translate(0.5, 1)">
                        <circle
                            stroke={color}
                            strokeWidth="2"
                            cx="12"
                            cy="12"
                            r="12"
                        />
                        <text
                            fontFamily="Roboto-Medium, Roboto, sans-serif"
                            fontSize="18"
                            fill={color}
                        >
                            <tspan x="7.85833629" y="18.5">
                                ?
                            </tspan>
                        </text>
                    </g>
                </g>
            </svg>
        );
    }
);

QuestionMark.displayName = "QuestionMark";

QuestionMark.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
};

export default QuestionMark;
