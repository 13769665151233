import React, { Component } from "react";
import "./styles.css";
import avendaLogo from "../../../assets/avenda_logo_full.svg";
import { MINIMUM_CHROME_VERISON } from "../../../constants";

export class BrowserSplash extends Component {
    render() {
        return (
            <div id={"browser-splash-screen"}>
                <div id={"browser-splash-content"}>
                    <img src={avendaLogo} alt={""} />
                    <p className={"display-22"} style={{ color: "black" }}>
                        The Avenda Health Cloud is currently only available on
                        Desktop for the Google Chrome Browser versions{" "}
                        {MINIMUM_CHROME_VERISON} or greater.
                        <br />
                        <br />
                        Please download the Google Chrome Browser and revisit
                        this site on a Desktop Computer to proceed.
                    </p>
                </div>
            </div>
        );
    }
}

export default BrowserSplash;
