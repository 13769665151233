import React, { Component } from "react";
import "./styles.css";
import { updateUser } from "../../Login/helpers";
import { If, Then } from "react-if";
import AHButton from "../../__shared__/StyleGuide/AHButton";
import { withRouter } from "react-router";
import {
    clientErrorMessages,
    EMPTY_STRING,
    MAX_EMAIL_LENGTH,
    stepPath,
    SUCCESS,
} from "../../../constants";
import PropTypes from "prop-types";
import AHAlphaField from "../../__shared__/StyleGuide/AHAlphaField";
import { securityFields, securityMessages, securityTitles } from "../constants";
import { updatePassword } from "../helpers";
import { signUpErrorMsgs } from "../../SignUpFlow/constants";
import NewPassword from "../../__shared__/StyleGuide/NewPassword";
import {
    messages,
    viewType,
} from "../../__shared__/StyleGuide/NewPassword/constants";
import "../../../styles/standard_styles.css";
import { isEmpty } from "../../../helpers/helpers";
import { rootStore } from "../../../redux/store";
import { setErrorState } from "../../../redux/error_banner/actions";
import { setUserData } from "../../Login/actions";
import { connect } from "react-redux";

class Security extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mfaEnabled: this.props.userData.mfaEnabled,
            currentPassword: EMPTY_STRING,
            errorPasswordMsg: EMPTY_STRING,
            successPasswordMsg: EMPTY_STRING,
        };
        this.handleSetMFA = this.handleSetMFA.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.timerId = null;
    }

    handleSetMFA(enable) {
        this.setState({ mfaEnabled: enable });

        if (enable) {
            this.props.history.push(stepPath.ENABLE_MFA);
        } else {
            let newUserData = this.props.userData;
            newUserData.mfaEnabled = enable;
            updateUser(
                this.props.userData.username,
                this.props.authToken,
                newUserData
            )
                .then((payload) => {
                    if (!isEmpty(payload) && payload.status === SUCCESS) {
                        this.props.setUserData(newUserData);
                    } else {
                        throw new Error(
                            clientErrorMessages.FAILED_TO_UPDATE_USER
                        );
                    }
                })
                .catch((e) => {
                    rootStore.dispatch(setErrorState(true, String(e)));
                });
        }
    }

    handlePasswordChange(label, value) {
        if (label === securityFields.CURRENT_PASSWORD) {
            this.setState({ currentPassword: value });
        }
    }

    handleSubmit(message, newPassword) {
        this.setState({
            errorPasswordMsg: EMPTY_STRING,
            successPasswordMsg: EMPTY_STRING,
        });

        if (message !== messages.SUCCESS) {
            let errorMsg;
            switch (message) {
                case signUpErrorMsgs.EMPTY_PASSWORD: {
                    errorMsg = securityMessages.EMPTY_PASSWORD;
                    break;
                }
                case signUpErrorMsgs.PERSONAL_INFO_PASSWORD: {
                    errorMsg = securityMessages.PERSONAL_INFO_PASSWORD;
                    break;
                }
                case signUpErrorMsgs.COMMON_PASSWORD: {
                    errorMsg = securityMessages.COMMON_PASSWORD;
                    break;
                }
                default:
                    errorMsg = message;
                    break;
            }
            this.setState({
                errorPasswordMsg: errorMsg,
            });
        } else {
            updatePassword(
                this.props.userData.username,
                this.state.currentPassword,
                newPassword,
                this.props.authToken
            )
                .then((json) => {
                    if (json) {
                        this.setState({
                            successPasswordMsg: securityMessages.SUCCESS,
                        });
                        clearInterval(this.timerId);
                        this.timerId = setTimeout(() => {
                            this.props.handleLogout();
                        }, 2000);
                    } else {
                        this.setState({
                            errorPasswordMsg:
                                securityMessages.WRONG_CURRENT_PASSWORD,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({ errorPasswordMsg: error.message });
                });
        }
    }

    render() {
        const errorMsgColor = {
            color: "var(--avendared1)",
        };
        const successMsgColor = {
            color: "var(--avendaGreen1)",
        };

        return (
            <div id="security-container">
                <div
                    className="security-child-container"
                    id="security-mfa-container"
                >
                    <div className="security-child-heading">
                        {securityTitles.MFA}
                    </div>
                    <div>
                        <p>
                            MFA Enabled for Account:&nbsp;
                            {this.state.mfaEnabled ? "Yes" : "No"}
                        </p>
                        <AHButton
                            isOutline={false}
                            isSecondary={this.state.mfaEnabled ? true : false}
                            size={"medium"}
                            isDynamic={false}
                            text={
                                this.state.mfaEnabled
                                    ? "Disable MFA"
                                    : "Enable MFA"
                            }
                            onClick={() => {
                                this.handleSetMFA(!this.state.mfaEnabled);
                            }}
                        />
                    </div>
                </div>
                <div
                    className="security-child-container"
                    id="security-change-password-container"
                >
                    <div className="security-child-heading">
                        {securityTitles.CHANGE_PASSWORD}
                    </div>
                    <div>
                        <If condition={this.state.errorPasswordMsg}>
                            <Then>
                                <div
                                    style={errorMsgColor}
                                    id="security-change-password-error-msg"
                                >
                                    {this.state.errorPasswordMsg}
                                </div>
                            </Then>
                        </If>
                        <If condition={this.state.successPasswordMsg}>
                            <Then>
                                <div
                                    style={successMsgColor}
                                    id="security-change-password-success-msg"
                                >
                                    {this.state.successPasswordMsg}
                                </div>
                            </Then>
                        </If>
                        <table id="security-change-password-table">
                            <tr>
                                <td>{securityFields.CURRENT_PASSWORD}</td>
                                <td>
                                    <AHAlphaField
                                        label={securityFields.CURRENT_PASSWORD}
                                        value={this.state.currentPassword}
                                        placeholder={
                                            securityFields.CURRENT_PASSWORD
                                        }
                                        id="security-current-password"
                                        type={"password"}
                                        maxLength={MAX_EMAIL_LENGTH}
                                        showLabel={false}
                                        onChange={this.handlePasswordChange}
                                    />
                                </td>
                            </tr>
                            <NewPassword
                                viewType={viewType.TABLE_VIEW}
                                currentPassword={this.state.currentPassword}
                                userData={this.props.userData}
                                onParentSubmit={this.handleSubmit}
                                newPasswordId={"security-new-password"}
                                confirmPasswordId={"security-confirm-password"}
                                newPasswordPlaceholder={
                                    securityFields.NEW_PASSWORD
                                }
                                confirmPasswordPlaceholder={
                                    securityFields.PASSWORD_CONFIRMATION
                                }
                            />
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

Security.propTypes = {
    userData: PropTypes.object,
    authToken: PropTypes.string,
    history: PropTypes.object,
    handleLogout: PropTypes.func,
    setUserData: PropTypes.func,
};

Security.defaultProps = {
    userData: {},
    authToken: EMPTY_STRING,
    setUserData: () => {},
};

const mapDispatchToProps = function (dispatch) {
    return {
        setUserData: (userData) => dispatch(setUserData(userData)),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(Security));
