import React, { Component } from "react";
import "./styles.css";
import { connect } from "react-redux";
import { signUpFlowStates } from "./constants";
import AccessCode from "./AccessCode";
import SignUpPage from "./SignUpPage";
import { setSignUpFlowState } from "./actions";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../constants";

class SignUpFlow extends Component {
    constructor(props) {
        super(props);

        this.getSignUpFlowState = this.getSignUpFlowState.bind(this);
    }

    componentWillUnmount() {
        this.props.setSignUpFlowState(signUpFlowStates.ACCESS_CODE);
    }

    getSignUpFlowState(flowState) {
        switch (flowState) {
            case signUpFlowStates.ACCESS_CODE:
                return <AccessCode />;
            case signUpFlowStates.SIGNUP_FORM:
                return <SignUpPage />;
            default:
                return <AccessCode />;
        }
    }

    render() {
        return (
            <div className={"signup-flow"}>
                {this.getSignUpFlowState(this.props.flowState)}
            </div>
        );
    }
}

SignUpFlow.propTypes = {
    flowState: PropTypes.string,
    setSignUpFlowState: PropTypes.func,
};

SignUpFlow.defaultProps = {
    flowState: EMPTY_STRING,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        flowState: state.SignupReducer.flowState,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setSignUpFlowState: (error) => dispatch(setSignUpFlowState(error)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpFlow);
