import "abortcontroller-polyfill";

const AbortController = window.AbortController;
let abortController = new AbortController();

export function getAbortController() {
    if (!abortController) {
        abortController = new AbortController();
    }
    return abortController;
}

export function resetAbortController() {
    abortController = new AbortController();
}
