import { defaultAblationVolumeSize } from "./consts";

export default class TargetProfileTemplates {
    _offsets;
    constructor(abUUID = defaultAblationVolumeSize.LARGE, metaData) {
        this._ablationProfileUUID = abUUID;
        this._volumesDict = {}; // volUUID: volMesh;
        this._selectedVolumeUUID = null;
        this._metaData = metaData;
    }

    set volumesDict(dict) {
        this._volumesDict = dict;
    }

    get volumesDict() {
        return this._volumesDict;
    }

    set metaData(dat) {
        this._metaData = dat;
    }

    get metaData() {
        return this._metaData;
    }

    set selectedVolume(uuid) {
        this._selectedVolumeUUID = uuid;
    }

    get selectedVolume() {
        return this._selectedVolumeUUID;
    }

    set ablationProfileUUID(uuid) {
        this._ablationProfileUUID = uuid;
    }

    get ablationProfileUUID() {
        return this._ablationProfileUUID;
    }

    get applicatorOffsets() {
        if (!this._offsets) {
            this._offsets = {};
            this._metaData.forEach((item) => {
                this._offsets[item.ablationVolumeUUID] = item.applicatorOffset;
            });
        }

        return this._offsets;
    }

    addToVolumeDict(uuid, geometry) {
        this._volumesDict[String(uuid)] = geometry;
    }
}
