// action types
export const SET_SORT_CATEGORY = "SET_SORT_CATEGORY";
export const SET_SORT_ORDER = "SET_SORT_ORDER";
export const SET_DOWNLOAD_SELECT_OPEN = "SET_DOWNLOAD_SELECT_OPEN";
export const SET_DOWNLOAD_PROPERTIES = "SET_DOWNLOAD_PROPERTIES";

export const setSortCategory = function (category) {
    return {
        type: SET_SORT_CATEGORY,
        category: category,
    };
};

export const setSortOrder = function (order) {
    return {
        type: SET_SORT_ORDER,
        order: order,
    };
};

export const setDownloadSelectOpen = function (active) {
    return {
        type: SET_DOWNLOAD_SELECT_OPEN,
        active: active,
    };
};

export const setDownloadProperties = function (selectOpen, patID, planID) {
    return {
        type: SET_DOWNLOAD_PROPERTIES,
        propt: {
            selectOpen: selectOpen,
            patID: patID,
            planID: planID,
        },
    };
};
