import { vsprintf } from "sprintf-js";
import {
    API_ENDPOINT,
    API_VERSION,
    ASCENDING,
    httpMethods,
    SUCCESS,
    clientErrorMessages,
} from "../../constants";
import { sortCategories } from "./constants";
import { rootStore } from "../../redux/store";
import { setErrorState } from "../../redux/error_banner/actions";

export async function getUserPatientsMetadata(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/patients/metadata", [
        API_ENDPOINT,
        API_VERSION,
        input.useruuid,
    ]);
    return await fetch(endpoint, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.TPLAN_RETRIEVE_FAILED);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function getNotifications(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/notifications/plan/%s", [
        API_ENDPOINT,
        API_VERSION,
        input.useruuid,
        input.planuuid,
    ]);
    return await fetch(endpoint, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            } else {
                return null;
            }
        })
        .catch(() => null);
}

const compareValues = (valueA, valueB, order) => {
    if (valueA < valueB) {
        return order === ASCENDING ? -1 : 1;
    } else if (valueA > valueB) {
        return order === ASCENDING ? 1 : -1;
    } else {
        return 0;
    }
};

export function sortPatientData(patA, patB, category, order) {
    switch (category) {
        case sortCategories.SEARCH_SCORE: {
            return compareValues(patA.score, patB.score, order);
        }

        case sortCategories.NAME: {
            return compareValues(
                patA.fullName.toLowerCase(),
                patB.fullName.toLowerCase(),
                order
            );
        }

        case sortCategories.DATE: {
            const creationDateA = patA.plans[0].creationDate;
            const creationDateB = patB.plans[0].creationDate;
            const stepA = patA.plans[0].SavedData.PlanCreationStep;
            const stepB = patB.plans[0].SavedData.PlanCreationStep;

            if (creationDateA !== creationDateB) {
                return compareValues(creationDateA, creationDateB, order);
            } else {
                return compareValues(stepA, stepB, order);
            }
        }

        case sortCategories.ID: {
            return compareValues(
                patA.patientId.toLowerCase(),
                patB.patientId.toLowerCase(),
                order
            );
        }

        default: {
            return 0;
        }
    }
}

export function sortPlanData(planA, planB, category, order) {
    switch (category) {
        case sortCategories.DATE: {
            const d1 = Date.parse(planA.creationDate);
            const d2 = Date.parse(planB.creationDate);
            return compareValues(d1, d2, order);
        }

        case sortCategories.ID: {
            return compareValues(planA.planId, planB.planId, order);
        }

        default: {
            return 0;
        }
    }
}

export function sortInProgressPlans(planA, planB, order) {
    let d1 = Date.parse(
        `${planA.MetaData.PlanCreationDate} ${planA.PlanCreationTime}`
    );
    let d2 = Date.parse(
        `${planB.MetaData.PlanCreationDate} ${planB.PlanCreationTime}`
    );
    if (d1 < d2) {
        return order === ASCENDING ? -1 : 1;
    } else if (d1 > d2) {
        return order === ASCENDING ? 1 : -1;
    } else {
        return 0;
    }
}
