import React from "react";
import "./styles.css";
import PropTypes from "prop-types";
import {
    PATIENT_SHARE_HEADER,
    PATIENT_SHARE_HEADER_NOTES,
    PATIENT_SHARE_HEADER_SHARE,
} from "../constants";

export default function PatientShareCardHeader(props) {
    function handleTabClick(tab) {
        if (props.openTab !== tab) {
            props.onTabChange(tab);
        }
    }

    function getTabClassName(selectedTab) {
        return (
            `header-tab-${selectedTab}` +
            (props.openTab === selectedTab ? " active" : "")
        );
    }

    return (
        <div className="patient-share-card-header text-gray">
            <div className="patient-share-header-top display-regular-22">
                {PATIENT_SHARE_HEADER}
            </div>
            <div className="patient-share-header-bottom display-regular-16">
                <div
                    id="pt-share-tab-btn"
                    className={getTabClassName("share")}
                    onClick={() => handleTabClick("share")}
                >
                    {PATIENT_SHARE_HEADER_SHARE}
                </div>
                <div
                    id="pt-notes-tab-btn"
                    className={getTabClassName("notes")}
                    onClick={() => handleTabClick("notes")}
                >
                    {PATIENT_SHARE_HEADER_NOTES}
                </div>
            </div>
        </div>
    );
}

PatientShareCardHeader.propTypes = {
    openTab: PropTypes.oneOf(["share", "notes"]).isRequired,
    onTabChange: PropTypes.func.isRequired,
};
