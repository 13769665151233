import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { AVENDA_COMPONENT_COLORS } from "../../../constants";
import { DEFAULT_TICK_PERCENT } from "./consts";

const NUM_TICKS = 24;

class SliceControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ticks: null,
            percent: DEFAULT_TICK_PERCENT,
            updateKey: 0,
        };

        this.mouseDown = false;
        this.scRef = React.createRef();
        this.updateTicks = this.updateTicks.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.keyControlHandler = this.keyControlHandler.bind(this);
        this.reset = this.reset.bind(this);
    }

    componentDidMount() {
        this.updateTicks();
        window.addEventListener("resize", this.updateTicks);
        window.addEventListener("mouseup", this.handleMouseUp);
        window.addEventListener("keydown", this.keyControlHandler);
        window.addEventListener("wheel", this.keyControlHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateTicks);
        window.removeEventListener("mouseup", this.handleMouseUp);
        window.removeEventListener("keydown", this.keyControlHandler);
        window.removeEventListener("wheel", this.keyControlHandler);
    }

    updateTicks() {
        let rect;
        if (this.scRef && this.scRef.current) {
            rect = this.scRef.current.getBoundingClientRect();
            this.setState({
                updateKey: this.state.updateKey + 1,
                ticks: SliceControl.generateSliceTicks(NUM_TICKS, rect.width),
            });
        }
    }

    handleMouseDown() {
        this.mouseDown = true;
    }

    handleMouseUp() {
        this.mouseDown = false;
    }

    handleClick(e) {
        let rect = this.scRef.current.getBoundingClientRect();
        let percent = 100 * (1 - (e.clientX - rect.left) / rect.width);
        percent = percent > 100 ? 100 : percent;
        percent = percent < 0 ? 0 : percent;
        this.props.mriModel.sliceIndexPercent = percent;
        this.setState({
            percent: percent,
        });
    }

    handleMouseMove(e) {
        if (this.mouseDown) {
            let rect = this.scRef.current.getBoundingClientRect();
            let percent = 100 * (1 - (e.clientX - rect.left) / rect.width);
            percent = percent > 100 ? 100 : percent;
            percent = percent < 0 ? 0 : percent;
            this.props.mriModel.sliceIndexPercent = percent;
            this.setState({
                percent: percent,
            });
        }
    }

    static generateSliceTicks(nticks, width) {
        let ticks = [];
        for (let i = 0; i < nticks; i++) {
            let offset = i * (width / (nticks - 1));
            ticks.push(
                <rect
                    key={i}
                    x={offset}
                    y={"calc(50% - 7px)"}
                    width="2px"
                    height="14px"
                    fill={AVENDA_COMPONENT_COLORS.BLUE}
                />
            );
        }
        return ticks;
    }

    reset() {
        this.props.mriModel.sliceIndexPercent = DEFAULT_TICK_PERCENT;
        this.setState({
            percent: DEFAULT_TICK_PERCENT,
        });
    }

    keyControlHandler(e) {
        if (e == null) {
            return;
        }
        this.props.mriController.shiftSliceByKey(e.key, e.shiftKey);
        this.setState({
            percent: this.props.mriModel.sliceIndexPercent,
        });
    }

    render() {
        return (
            <div
                className={"mri-slice-control"}
                style={this.props.style}
                ref={this.scRef}
                onClick={this.handleClick}
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
                onMouseMove={this.handleMouseMove}
            >
                <svg
                    width="calc(100% + 2px)"
                    height="100%"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {this.state.ticks}
                    <rect
                        key={this.state.updateKey}
                        x={`calc(${100 - this.state.percent}% - 2px)`}
                        y={"calc(50% - 20px)"}
                        width="4px"
                        height="40px"
                        fill={"#87e4FF"}
                        id={"mri-slice-control-slider"}
                    />
                </svg>
            </div>
        );
    }
}

SliceControl.propTypes = {
    mriModel: PropTypes.object,
    mriController: PropTypes.object,
    style: PropTypes.object,
};

export default SliceControl;
