import React, { Component } from "react";
import TopBar from "../__shared__/TopBar";
import ProfileMenu from "../__shared__/ProfileMenu";
import StepMarkerBar from "../__shared__/StepMarkerBar";
import AvendaLogo from "../__shared__/AvendaLogo";
import PreviousStepButton from "../__shared__/PreviousStepButton";
import { connect } from "react-redux";
import {
    BIOPSY_STEP,
    EDIT_MARGIN_STEP,
} from "../CreateTPlan_1Upload/constants";
import {
    setCurrentTreatmentPlan,
    setStepActive,
    setStepComplete,
} from "../CreateTPlan_1Upload/actions";
import { withRouter } from "react-router-dom";
import {
    clientErrorMessages,
    EMPTY_STRING,
    pageLoadStates,
    refFrames,
    stepPath,
    structureColorPalette,
    SUCCESS,
    volumeNames,
} from "../../constants";
import PropTypes from "prop-types";
import { setErrorState } from "../../redux/error_banner/actions";
import * as TreatmentPlan from "../../helpers/tplan/tplan";
import {
    displayOnlyCPMFileName,
    generateCPMLoadStates,
    rawCPMFileName,
} from "./constants";
import CancerMapProgressTracker from "./CancerMapProgressTracker";
import FailureScreen from "../__shared__/FailureScreen";
import { vsprintf } from "sprintf-js";
import {
    createOptimalMargin,
    updateTreatmentPlan,
} from "../../helpers/backend_api";
import { rootStore } from "../../redux/store";
import { deleteTargets } from "../CreateTPlan_4EditMargin/helpers";
import * as helpers from "./helpers";

class CreateTPlan_4_1_GenerateCPM extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadState: generateCPMLoadStates.CPM_GENERATING,
            mapProgressMessages: [],
            cemError: null,
            requestUuid: EMPTY_STRING,
        };

        this.prevStep = this.prevStep.bind(this);
        this.performSteps = this.performSteps.bind(this);
        this.generateCPM = helpers.generateCPM.bind(this);
    }

    componentDidMount() {
        this.performSteps();
    }

    performSteps() {
        const tPlan = this.props.currentTreatmentPlan.TreatmentPlan;
        if (
            TreatmentPlan.getGleasonGradeGroup(tPlan) >= 2 &&
            TreatmentPlan.hasROIs(tPlan)
        ) {
            this.performIntermediateRiskPCaSteps();
        }
    }

    prevStep() {
        this.props.setStepActive(EDIT_MARGIN_STEP, false);
        this.props.setStepComplete(EDIT_MARGIN_STEP, false);
        this.props.setStepActive(BIOPSY_STEP, true);

        let plan = this.props.currentTreatmentPlan;
        plan.SavedData.PlanCreationStep = BIOPSY_STEP;
        this.props.setCurrentTreatmentPlan(plan);

        updateTreatmentPlan({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            token: this.props.authToken,
            plan: plan,
        });
    }

    async performLowRiskPCaSteps() {
        let scope = this;
        let plan = this.props.currentTreatmentPlan;

        plan.SavedData.OptimalCPMThreshold = 0.0;
        plan.SavedData.UserSelectedCPMThreshold = 0.0;
        plan.SavedData.OptimalMarksScore = 0.0;
        plan.SavedData.UserSelectedMarksScore = 0.0;
        plan.SavedData.OptimalLesionCoverageOfProstate = 0.0;
        plan.SavedData.UserSelectedLesionCoverageOfProstate = 0.0;
        plan.SavedData.IsPatientFTEligible = false;
        plan.SavedData.CPMURI = EMPTY_STRING;
        plan.SavedData.LesionConfirmed = true;

        await updateTreatmentPlan({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            token: this.props.authToken,
            plan: plan,
        })
            .then((payload) => payload.json())
            .then((json) => {
                scope.props.setCurrentTreatmentPlan(json.payload.plan);
            })
            .catch(() => {
                rootStore.dispatch(
                    setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
                );
            });

        await deleteTargets(scope, plan.TreatmentPlan.StructureData.Targets);

        scope.props.history.push(stepPath.STEP4);
    }

    async performIntermediateRiskPCaSteps() {
        let scope = this;
        let plan = this.props.currentTreatmentPlan;

        plan.SavedData.OptimalCPMThreshold = 0.0;
        plan.SavedData.UserSelectedCPMThreshold = 0.0;
        plan.SavedData.OptimalMarksScore = 0.0;
        plan.SavedData.UserSelectedMarksScore = 0.0;
        plan.SavedData.OptimalLesionCoverageOfProstate = 0.0;
        plan.SavedData.UserSelectedLesionCoverageOfProstate = 0.0;
        plan.SavedData.IsPatientFTEligible = false;
        plan.SavedData.CPMURI = EMPTY_STRING;
        plan.SavedData.LesionConfirmed = false;

        await updateTreatmentPlan({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            token: this.props.authToken,
            plan: plan,
        })
            .then((payload) => payload.json())
            .then((json) => {
                scope.props.setCurrentTreatmentPlan(json.payload.plan);
            })
            .catch(() => {
                rootStore.dispatch(
                    setErrorState(true, clientErrorMessages.TPLAN_UPDATE_FAILED)
                );
                scope.setState({ loadState: pageLoadStates.FAILED });
            });

        await deleteTargets(scope, plan.TreatmentPlan.StructureData.Targets);

        this.generateCPM();
    }

    generateMargin() {
        let scope = this;
        createOptimalMargin({
            prostateURI: TreatmentPlan.getStructureInFrame(
                TreatmentPlan.getCoreStructure(
                    this.props.currentTreatmentPlan.TreatmentPlan
                ),
                refFrames.MRI
            ).URI,
            cpmURI: this.getCPMURIfromPlan(rawCPMFileName),
        })
            .then((response) => response.json())
            .then((r) => {
                if (r.message === SUCCESS) {
                    if (window.pendo) {
                        try {
                            window.pendo.track("Margin generation successful", {
                                planId: scope.props.planuuid,
                            });
                        } catch {
                            () => {};
                        }
                    }

                    let plan = scope.props.currentTreatmentPlan;

                    plan.TreatmentPlan.StructureData.Margins = [
                        {
                            Name: volumeNames.MR_MARGIN_AI,
                            Color: structureColorPalette.TX_MARGIN,
                            Opacity: 0.5,
                            VolumeCC: r.payload.marginVolume,
                            Frame: {
                                MRI: {
                                    Path: "/volumes/MR_MARGIN.vtk",
                                    URI: vsprintf("%s/CPM/MR_MARGIN.vtk", [
                                        TreatmentPlan.get3DDataFolder(
                                            scope.props.currentTreatmentPlan,
                                            scope.props.patientuuid,
                                            scope.props.visituuid
                                        ),
                                    ]),
                                },
                            },
                        },
                    ];

                    plan.TreatmentPlan.StructureData.CoreStructure.VolumeCC =
                        r.payload.prostateVolume;
                    plan.SavedData.OptimalCPMThreshold =
                        r.payload.optimalCPMThreshold;
                    plan.SavedData.UserSelectedCPMThreshold =
                        r.payload.optimalCPMThreshold;
                    plan.SavedData.OptimalMarksScore = r.payload.marksScore;
                    plan.SavedData.UserSelectedMarksScore =
                        r.payload.marksScore;
                    plan.SavedData.OptimalLesionCoverageOfProstate =
                        r.payload.prostateCoverage;
                    plan.SavedData.UserSelectedLesionCoverageOfProstate =
                        r.payload.prostateCoverage;
                    plan.SavedData.OptimalLesionVolume = r.payload.marginVolume;
                    plan.SavedData.IsPatientFTEligible =
                        r.payload.focalTherapyEligible;
                    plan.SavedData.CPMThresholdCurve =
                        r.payload.cpmThresholdCurve;
                    plan.SavedData.MarksScoreCurve = r.payload.marksScoreCurve;
                    plan.SavedData.ProstateCoverageCurve =
                        r.payload.prostateCoverageCurve;
                    plan.SavedData.LesionVolumeCurve =
                        r.payload.marginVolumeCurve;
                    plan.SavedData.CPMURI =
                        this.getCPMURIfromPlan(rawCPMFileName);
                    plan.SavedData.CPMForLUTDisplayURI = this.getCPMURIfromPlan(
                        displayOnlyCPMFileName
                    );

                    updateTreatmentPlan({
                        useruuid: scope.props.useruuid,
                        patientuuid: scope.props.patientuuid,
                        planuuid: scope.props.planuuid,
                        token: scope.props.authToken,
                        plan: plan,
                    })
                        .then((payload) => payload.json())
                        .then((json) => {
                            scope.props.setCurrentTreatmentPlan(
                                json.payload.plan
                            );
                            scope.props.history.push(stepPath.STEP4);
                            if (window.pendo) {
                                try {
                                    window.pendo.track(
                                        "Treatment plan update successful",
                                        {
                                            planId: scope.props.planuuid,
                                        }
                                    );
                                } catch {
                                    () => {};
                                }
                            }
                        })
                        .catch(() => {
                            rootStore.dispatch(
                                setErrorState(
                                    true,
                                    clientErrorMessages.TPLAN_UPDATE_FAILED
                                )
                            );
                            scope.setState({
                                loadState: pageLoadStates.FAILED,
                            });
                            if (window.pendo) {
                                try {
                                    window.pendo.track(
                                        "Treatment plan update failed",
                                        {
                                            planId: scope.props.planuuid,
                                        }
                                    );
                                } catch {
                                    () => {};
                                }
                            }
                        });
                } else {
                    rootStore.dispatch(
                        setErrorState(
                            true,
                            clientErrorMessages.MARGIN_CREATION_FAILED
                        )
                    );
                    scope.setState({ loadState: pageLoadStates.FAILED });
                    if (window.pendo) {
                        try {
                            window.pendo.track("Treatment plan update failed", {
                                planId: scope.props.planuuid,
                            });
                        } catch {
                            () => {};
                        }
                    }
                }
            })
            .catch(() => {
                rootStore.dispatch(
                    setErrorState(
                        true,
                        clientErrorMessages.MARGIN_CREATION_FAILED
                    )
                );
                scope.setState({ loadState: pageLoadStates.FAILED });
                if (window.pendo) {
                    try {
                        window.pendo.track("Margin generation failed", {
                            planId: scope.props.planuuid,
                        });
                    } catch {
                        () => {};
                    }
                }
            });
    }

    getCPMURIfromPlan(filename) {
        return `${TreatmentPlan.get3DDataFolder(
            this.props.currentTreatmentPlan,
            this.props.patientuuid,
            this.props.visituuid
        )}/CPM/${filename}`;
    }

    getLoadingScreen() {
        let overlay;

        if (this.state.loadState === pageLoadStates.FAILED) {
            overlay = <FailureScreen />;
        } else {
            overlay = (
                <CancerMapProgressTracker
                    mapProgressMessages={this.state.mapProgressMessages}
                    cemError={this.state.cemError}
                />
            );
        }

        return overlay;
    }

    render() {
        return (
            <div className={"create-plan-edit-margin-screen"}>
                <TopBar>
                    <AvendaLogo id={"create-plan-avenda-logo"} to={"/home"} />
                    <div className={"create-plan-step-bar-container"}>
                        <div className={"step-bar-prev-btn-group"}>
                            <PreviousStepButton
                                to={stepPath.STEP3}
                                callback={this.prevStep}
                            />
                            <StepMarkerBar />
                        </div>
                    </div>
                    <ProfileMenu />
                </TopBar>
                {this.getLoadingScreen()}
            </div>
        );
    }
}

CreateTPlan_4_1_GenerateCPM.propTypes = {
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    visituuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
    setCurrentTreatmentPlan: PropTypes.func,
    history: PropTypes.object,
    setErrorState: PropTypes.func,
    setStepActive: PropTypes.func,
    setStepComplete: PropTypes.func,
};

CreateTPlan_4_1_GenerateCPM.defaultProps = {
    useruuid: EMPTY_STRING,
    authToken: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    currentTreatmentPlan: {},
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setStepComplete: (stepIdx, isComplete) =>
            dispatch(setStepComplete(stepIdx, isComplete)),
        setStepActive: (stepIdx, isActive) =>
            dispatch(setStepActive(stepIdx, isActive)),
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        setErrorState: (hasError, errorText) =>
            dispatch(setErrorState(hasError, errorText)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CreateTPlan_4_1_GenerateCPM)
);
