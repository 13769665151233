import { clientErrorMessages, EMPTY_STRING, refFrames } from "../../constants";
import * as TreatmentPlan from "../../helpers/tplan/tplan";
import {
    createMeshFromFaceVertexInS3,
    deleteAblationTargetBackend,
    deleteFileFromCloud,
    getAblationVolumeIsValidForAutoPlacement,
    getAutoPlacedTargets,
    updateTreatmentPlan,
} from "../../helpers/backend_api";
import { getTreatmentPlan } from "../CreateTPlan_1Upload/helpers";
import { rootStore } from "../../redux/store";
import { setErrorState } from "../../redux/error_banner/actions";
import {
    cemWarningCodes,
    cemWarningMsg,
    DEFAULT_AVENDA_PROFILE,
    VOLUME_SHAPES,
} from "./constants";
import cubeIcon from "../../assets/CAB_Cube_Icon.svg";
import ellipseIcon from "../../assets/CAB_Ellipse_Icon.svg";
import prismIcon from "../../assets/CAB_Prism_Icon.svg";
import sphereIcon from "../../assets/CAB_Sphere_Icon.svg";

export function getAutoPlacedTargetsWrapper(
    tplan,
    ablationVolume,
    cloudIDs,
    cropToMidline = false
) {
    return getAutoPlacedTargets(
        _aaspInputHelper(tplan, ablationVolume, cloudIDs, cropToMidline)
    );
}

export function getAblationVolumeIsValidForAutoPlacementWrapper(
    tplan,
    ablationVolume,
    cloudIDs
) {
    return getAblationVolumeIsValidForAutoPlacement(
        _aaspInputHelper(tplan, ablationVolume, cloudIDs)
    );
}

function _aaspInputHelper(
    tplan,
    ablationVolume,
    cloudIDs,
    cropToMidline = false
) {
    let marginURI;
    if (TreatmentPlan.hasMargins(tplan)) {
        marginURI = TreatmentPlan.getStructureInFrame(
            TreatmentPlan.getMarginByIdx(tplan, 0),
            refFrames.MRI
        ).URI;
    } else if (TreatmentPlan.hasROIs(tplan)) {
        marginURI = TreatmentPlan.getStructureInFrame(
            TreatmentPlan.getROIByIdx(tplan, 0),
            refFrames.MRI
        ).URI;
    }

    return {
        prostateURI: TreatmentPlan.getStructureInFrame(
            TreatmentPlan.getCoreStructure(tplan),
            refFrames.MRI
        ).URI,
        marginURI: marginURI,
        roiURIs: TreatmentPlan.getROIs(tplan).map(
            (roi) => TreatmentPlan.getStructureInFrame(roi, refFrames.MRI).URI
        ),
        ablationVolume: ablationVolume,
        bxCores: TreatmentPlan.getBiopsyCores(tplan),
        cropToMidline: cropToMidline,
        cloudIDs: cloudIDs,
    };
}

export async function saveTargets(scope, inMemoryTargets) {
    let savePlan = await _saveTargets(scope, inMemoryTargets);

    await updateTreatmentPlan({
        useruuid: scope.props.useruuid,
        patientuuid: scope.props.patientuuid,
        planuuid: scope.props.planuuid,
        token: scope.props.authToken,
        plan: savePlan,
    })
        .then((payload) => payload.json())
        .then((json) => {
            let plan = json.payload.plan;
            scope.props.setCurrentTreatmentPlan(plan);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(
                    true,
                    clientErrorMessages.UPDATE_ABLATION_SITE_FAILED
                )
            );
        });
}

async function _saveTargets(scope, inMemoryTargets) {
    if (!inMemoryTargets) {
        return;
    }

    // First delete all previously saved targets using the URIs saved in the treatment plan
    let tplanTargets = TreatmentPlan.getTargets(
        scope.props.currentTreatmentPlan.TreatmentPlan
    );
    if (tplanTargets) {
        for (const target of tplanTargets) {
            await deleteAblationTargetBackend({
                visituuid: scope.props.visituuid,
                useruuid: scope.props.useruuid,
                patientuuid: scope.props.patientuuid,
                planuuid: scope.props.planuuid,
                token: scope.props.authToken,
                id: target.ID,
            }).catch(() => {
                rootStore.dispatch(
                    setErrorState(
                        true,
                        clientErrorMessages.UPDATE_ABLATION_SITE_FAILED
                    )
                );
            });

            await deleteFileFromCloud({
                visituuid: scope.props.visituuid,
                patientuuid: scope.props.patientuuid,
                token: scope.props.authToken,
                fileURI: TreatmentPlan.getStructureInFrame(
                    target,
                    refFrames.MRI
                ).URI,
            });
        }
    }

    for (const target of inMemoryTargets) {
        if (
            TreatmentPlan.getStructureInFrame(target, refFrames.MRI).URI ===
            EMPTY_STRING
        ) {
            let uri = _createAblationSiteURI(scope, target.ID);
            TreatmentPlan.getStructureInFrame(target, refFrames.MRI).URI = uri;
        }

        await createMeshFromFaceVertexInS3({
            faces: target.GeometricData.Faces,
            vertices: target.GeometricData.Vertices,
            destinationURI: TreatmentPlan.getStructureInFrame(
                target,
                refFrames.MRI
            ).URI,
            token: scope.props.authToken,
        }).catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.SAVE_MESH_UPDATE_FAILED)
            );
        });
    }

    let savePlan = scope.props.currentTreatmentPlan;
    savePlan.TreatmentPlan.StructureData.Targets = inMemoryTargets;

    return savePlan;
}

function _createAblationSiteURI(scope, id) {
    let scanID = scope.props.currentTreatmentPlan.MetaData.MasterScanID;
    return `${scope.props.patientuuid}/${scope.props.visituuid}/${scanID}/3D_DATA/MR_TARGET_${id}.vtk`;
}

export async function getAvendaDefaultProfile() {
    return {
        defaultProfile: DEFAULT_AVENDA_PROFILE,
    };
}

export async function deleteTargets(scope, inMemoryTargets) {
    if (!inMemoryTargets) {
        return;
    }

    for (const target of inMemoryTargets) {
        if (
            TreatmentPlan.getStructureInFrame(target, refFrames.MRI).URI !==
            EMPTY_STRING
        ) {
            await deleteAblationTargetBackend({
                useruuid: scope.props.useruuid,
                visituuid: scope.props.visituuid,
                patientuuid: scope.props.patientuuid,
                planuuid: scope.props.planuuid,
                token: scope.props.authToken,
                id: target.ID,
            }).catch(() => {
                scope.props.setErrorState(
                    true,
                    clientErrorMessages.UPDATE_ABLATION_SITE_FAILED
                );
            });

            deleteFileFromCloud({
                visituuid: scope.props.visituuid,
                patientuuid: scope.props.patientuuid,
                token: scope.props.authToken,
                fileURI: TreatmentPlan.getStructureInFrame(
                    target,
                    refFrames.MRI
                ).URI,
            });
        }
    }

    getTreatmentPlan(
        scope.props.authToken,
        scope.props.patientuuid,
        scope.props.planuuid
    )
        .then((payload) => payload.json())
        .then((json) => {
            let plan = json.payload.plan;
            scope.props.setCurrentTreatmentPlan(plan);
        })
        .catch(() => {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.TPLAN_RETRIEVE_FAILED)
            );
        });
}

export function getIconForAblationVolumePrimitiveType(meshPrimitiveType) {
    let volumeIcon;

    switch (meshPrimitiveType) {
        case VOLUME_SHAPES.CUBE:
            volumeIcon = cubeIcon;
            break;
        case VOLUME_SHAPES.ELLIPSE:
            volumeIcon = ellipseIcon;
            break;
        case VOLUME_SHAPES.PRISM:
            volumeIcon = prismIcon;
            break;
        case VOLUME_SHAPES.SPHERE:
            volumeIcon = sphereIcon;
            break;
        case VOLUME_SHAPES.CAPSULE:
            volumeIcon = ellipseIcon;
            break;
        default:
            volumeIcon = ellipseIcon;
            break;
    }

    return volumeIcon;
}

export function getCemWarningMsg(cemWarnings) {
    if (!cemWarnings || cemWarnings.length === 0) {
        return EMPTY_STRING;
    }

    let checkWarning = [];
    for (const code in cemWarningCodes) {
        checkWarning[cemWarningCodes[code]] = false;
    }
    for (let i = 0; i < cemWarnings.length; i++) {
        checkWarning[cemWarnings[i]] = true;
    }

    // Initialize the warning string with the base message
    let warningString = cemWarningMsg.WARNING_PREFIX;

    // Check conditions to append specific messages to the base warning
    if (checkWarning[cemWarningCodes.CANCER_MAP_UNDERSAMPLED_ROI_ONLY]) {
        warningString += cemWarningMsg.TARGETED;
    }

    if (checkWarning[cemWarningCodes.CANCER_MAP_UNDERSAMPLED_UNILATERAL]) {
        // Add "or" only if the previous condition was also true
        if (checkWarning[cemWarningCodes.CANCER_MAP_UNDERSAMPLED_ROI_ONLY]) {
            warningString += cemWarningMsg.CONNECTOR_OR;
        }
        warningString += cemWarningMsg.UNILATERAL;
    }

    if (checkWarning[cemWarningCodes.CANCER_MAP_UNDERSAMPLED_LARGE_PROSTATE]) {
        // Add "or" only if one of the previous conditions was true
        if (
            checkWarning[cemWarningCodes.CANCER_MAP_UNDERSAMPLED_ROI_ONLY] ||
            checkWarning[cemWarningCodes.CANCER_MAP_UNDERSAMPLED_UNILATERAL]
        ) {
            warningString += cemWarningMsg.CONNECTOR_OR;
        }
        warningString += cemWarningMsg.LARGE_PROSTATE;
    }

    warningString += cemWarningMsg.WARNING_POSTFIX;

    return warningString;
}
