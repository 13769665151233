import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";

class MutedNudgeText extends Component {
    render() {
        let display = this.props.showText ? "active" : "inactive";
        return (
            <div
                className={`muted-nudge-text-cntr ${display}`}
                style={this.props.style}
            >
                <div className={"display-16 muted-nudge-text"}>
                    {this.props.content}
                </div>
            </div>
        );
    }
}

MutedNudgeText.propTypes = {
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    showText: PropTypes.bool,
    style: PropTypes.object,
};

MutedNudgeText.defaultProps = {
    content: "content prop empty",
    showText: true,
};

export default MutedNudgeText;
