import React, { Component } from "react";
import "./styles.css";
import StepNode from "../StepNode";
import StepEdge from "../StepEdge";
import { interleave } from "../../../helpers/helpers";
import PropTypes from "prop-types";
import {
    AVENDA_COMPONENT_COLORS,
    stepNumber,
    stepPath,
    SUCCESS,
    clientErrorMessages,
    EMPTY_STRING,
} from "../../../constants";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    setCurrentTreatmentPlan,
    setStepActive,
    setStepComplete,
} from "../../CreateTPlan_1Upload/actions";
import { updateTreatmentPlan } from "../../../helpers/backend_api";
import { setErrorState } from "../../../redux/error_banner/actions";
import { rootStore } from "../../../redux/store";

class GenericStepMarkerBar extends Component {
    constructor(props) {
        super(props);

        this.generateNodes = this.generateNodes.bind(this);
        this.navigateToStepCallback = this.navigateToStepCallback.bind(this);
    }

    navigateToStepCallback(stepNum) {
        let plan = this.props.currentTreatmentPlan;

        let currentStepNumber = plan.SavedData.PlanCreationStep;
        for (var i = 0; i < currentStepNumber - stepNum + 1; i++) {
            this.props.setStepActive(stepNum + i, false);
            this.props.setStepComplete(stepNum + i, false);
        }

        plan.SavedData.PlanCreationStep = stepNum - 1;
        this.props.setCurrentTreatmentPlan(plan);
        this.props.setStepActive(stepNum - 1, true);

        updateTreatmentPlan({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            token: this.props.authToken,
            plan: plan,
        })
            .then((response) => response.json())
            .then((json) => {
                if (json.message === SUCCESS) {
                    let convertedStepNum = stepNum - 1;

                    switch (convertedStepNum) {
                        case stepNumber.UPLOAD:
                            this.props.history.push(stepPath.STEP1);
                            break;
                        case stepNumber.SEG_MRI:
                            this.props.history.push(stepPath.STEP2);
                            break;
                        case stepNumber.BIOPSY:
                            this.props.history.push(stepPath.STEP3);
                            break;
                        case stepNumber.EDIT_MARGIN:
                            this.props.history.push(stepPath.STEP4);
                            break;
                        default:
                            break;
                    }
                }
            })
            .catch(() => {
                rootStore.dispatch(
                    setErrorState(
                        true,
                        clientErrorMessages.FAILED_TO_RETURN_TO_PREV_PAGE
                    )
                );
                return null;
            });
    }

    generateNodes(planSteps) {
        let scope = this;

        return planSteps.map(function (step, idx) {
            return (
                <StepNode
                    id={"StepNode" + idx}
                    key={"StepNode" + idx}
                    finished={step.complete}
                    previousStepDisabled={scope.props.previousStepsDisabled}
                    stepNumber={idx + 1}
                    active={step.active}
                    text={step.name}
                    callback={scope.navigateToStepCallback}
                />
            );
        });
    }

    static generateEdges(planSteps) {
        return planSteps
            .map(function (step, idx) {
                if (idx === 0) {
                    return null;
                }

                let isActive =
                    (planSteps[parseInt(idx)].complete &&
                        planSteps[idx - 1].complete) ||
                    (planSteps[parseInt(idx)].active &&
                        planSteps[parseInt(idx) - 1].complete);
                let isDotted = planSteps[parseInt(idx)].active;

                if (idx === 1 && !isActive) {
                    isDotted =
                        !planSteps[parseInt(idx)].complete &&
                        !planSteps[idx - 1].complete;
                    isActive = true;
                }

                return (
                    <StepEdge
                        key={"Edge" + idx}
                        isActive={isActive}
                        activeStep={isDotted}
                    />
                );
            })
            .filter(function (edge) {
                return edge != null;
            });
    }

    static generateLabels(planSteps, noLabel) {
        if (noLabel) {
            return;
        }

        let labels = planSteps.map(function (step, idx) {
            let color =
                step.active || step.complete
                    ? AVENDA_COMPONENT_COLORS.BLUE
                    : AVENDA_COMPONENT_COLORS.GRAY;

            let padding;
            if (idx === 0) {
                padding = "0 0 0 2.5%";
            } else if (idx === planSteps.length - 1) {
                padding = "0 2.5% 0 0";
            } else {
                padding = "0";
            }

            return (
                <p
                    key={"Label" + idx}
                    className={"display-12"}
                    style={{
                        color: color,
                        width: "20%",
                        textAlign: "center",
                        padding: padding,
                    }}
                >
                    {step.name}
                </p>
            );
        });
        return <div className={"step-marker-bar-labels"}>{labels}</div>;
    }

    render() {
        let nodes = this.generateNodes(this.props.planCreationStepMetaData);
        let edges = GenericStepMarkerBar.generateEdges(
            this.props.planCreationStepMetaData
        );
        let set = interleave(nodes, edges);

        let labels = GenericStepMarkerBar.generateLabels(
            this.props.planCreationStepMetaData,
            this.props.noLabel
        );

        return (
            <div
                id={this.props.id}
                className={"step-marker-bar"}
                style={this.props.style}
            >
                <div className={"step-marker-bar-steps"}>{set}</div>
                {labels}
            </div>
        );
    }
}

GenericStepMarkerBar.propTypes = {
    planCreationStepMetaData: PropTypes.array,
    previousStepsDisabled: PropTypes.bool,
    id: PropTypes.string,
    style: PropTypes.object,
    noLabel: PropTypes.bool,
    currentTreatmentPlan: PropTypes.object,
    authToken: PropTypes.string,
    useruuid: PropTypes.string,
    planuuid: PropTypes.string,
    patientuuid: PropTypes.string,
    setStepActive: PropTypes.func,
    setStepComplete: PropTypes.func,
    setCurrentTreatmentPlan: PropTypes.func,
    history: PropTypes.object,
};

GenericStepMarkerBar.defaultProps = {
    planCreationStepMetaData: [],
    previousStepsDisabled: false,
    noLabel: false,
    currentTreatmentPlan: {},
    authToken: EMPTY_STRING,
    useruuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setStepActive: (stepIdx, isActive) =>
            dispatch(setStepActive(stepIdx, isActive)),
        setStepComplete: (stepIdx, isComplete) =>
            dispatch(setStepComplete(stepIdx, isComplete)),
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(GenericStepMarkerBar)
);
