import { initialState } from "./init";
import {
    RESET_IMAGE_VIEWER_SHARD,
    SET_EDIT_SEGMENTATION_ACTIVE,
    SET_RESET_SEGMENTATION,
    SAVE_EDITED_SEGMENTATION,
    SAVE_EDIT_SEG_ERROR,
} from "./actions";

export default function ImageViewerReducer(state = initialState, action = {}) {
    switch (action.type) {
        case RESET_IMAGE_VIEWER_SHARD:
            return resetImageViewerShard(state, action);
        case SET_EDIT_SEGMENTATION_ACTIVE:
            return setEditSegmentationActive(state, action);
        case SET_RESET_SEGMENTATION:
            return setResetSegmentation(state, action);
        case SAVE_EDITED_SEGMENTATION:
            return saveEditedSegmentation(state, action);
        case SAVE_EDIT_SEG_ERROR:
            return setSaveEditSegError(state, action);
        default:
            return state;
    }
}

function resetImageViewerShard() {
    return initialState;
}

function setEditSegmentationActive(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.segmentationEditingActive = action.isOn;
    return stateCpy;
}

function setResetSegmentation(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.resetSegmentation = action.isOn;
    return stateCpy;
}

function saveEditedSegmentation(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.saveSegmentation = action.isOn;
    return stateCpy;
}

function setSaveEditSegError(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.saveEditsSegError = action.isOn;
    return stateCpy;
}
