import React, { Component } from "react";
import { Document, Page } from "react-pdf";
import PropTypes from "prop-types";
import "./styles.css";
import { pdfjs } from "react-pdf";
import { EMPTY_STRING, pageLoadStates } from "../../../constants";
import { PDF_LOADING_TEXT } from "../constants";
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

class PathReportViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            pageNumber: 1,
        };
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.bytes.length !== this.props.bytes.length ||
            nextProps.width !== this.props.width ||
            nextState.numPages !== this.state.numPages ||
            nextProps.scale !== this.props.scale
        );
    }

    onDocumentLoadSuccess({ numPages }) {
        this.setState({ numPages });
        this.props.setPdfLoaded(pageLoadStates.COMPLETE);
    }

    render() {
        const numPages = this.state.numPages;
        let pathReportData = new Uint8Array(this.props.bytes);
        let zoomed = this.props.scale > 1.0 ? "zoomed" : EMPTY_STRING;
        return (
            <div
                id={this.props.id}
                className={`path-report-viewer ${zoomed}`}
                style={this.props.style}
            >
                <Document
                    className="pendo-ignore"
                    file={{ data: pathReportData }}
                    onLoadSuccess={this.onDocumentLoadSuccess}
                    loading={PDF_LOADING_TEXT}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={this.props.width}
                            scale={this.props.scale}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                        />
                    ))}
                </Document>
            </div>
        );
    }
}

PathReportViewer.propTypes = {
    bytes: PropTypes.array,
    width: PropTypes.number,
    pageNumber: PropTypes.number,
    id: PropTypes.string,
    style: PropTypes.object,
    setPdfLoaded: PropTypes.func,
    scale: PropTypes.number,
};

PathReportViewer.defaultProps = {
    id: EMPTY_STRING,
    scale: 1.0,
};

export default PathReportViewer;
