import { pageLoadStates } from "../../constants";

export const WEBSOCKET_TIMEOUT_MESSAGE = "Endpoint request timed out";

export const generateCPMLoadStates = {
    CPM_GENERATING: "CPM_GENERATING",
    CPM_COMPLETE: "CPM_COMPLETE",
    DEFAULT_MARGIN_COMPLETE: "DEFAULT_MARGIN_COMPLETE",
    ...pageLoadStates,
};

export const rawCPMFileName = "CPM_RAW.nii.gz";
export const displayOnlyCPMFileName = "CPM_FOR_DISPLAY_RESAMPLED.nii.gz";

export const webSocketActions = {
    CPM_WITH_UPDATE_PLAN: "cpm_with_update_plan",
};

export const cpmStatusCodes = {
    NETWORK_ERROR: "NETWORK_ERROR",
    CEM_FINISH: "CEM_FINISH",
    CANCER_MAP_GENERATION_COMPLETE: "CANCER_MAP_GENERATION_COMPLETE",
    CEM_GENERATION_FAILED: "CEM_GENERATION_FAILED",
    CEM_WARNINGS_UPDATE_FAILED: "CEM_WARNINGS_UPDATE_FAILED",
    CEM_WARNINGS_UPDATED: "CEM_WARNINGS_UPDATED",
    ERROR: "ERROR",
    FAILURE: "FAILURE",
};

export const cpmErrorCodes = {
    NETWORK_ERROR: "NETWORK_ERROR",
    CEM_TIMEOUT: "CEM_TIMEOUT",
};

export const cpmErrorMessages = {
    NETWORK_ERROR:
        "Unable to connect to CEM service. Please refresh the page and try again.",
    CEM_TIMEOUT: "Cancer Map exceeded maximum allowed generation time.",
};

export const cpmProgressTrackerHeader = {
    CEM_ERROR: "CEM Error",
    CEM_LOADING: "CEM is loading...",
};

export const cpmProgressTrackerSubtitles = {
    ERROR_CODE: "Error Code: ",
    REQUEST_UUID: "RequestUuid: ",
    TIMESTAMP: "Timestamp: ",
    DETAILS: "Details: ",
    MESSAGE: "Message: ",
    PAYLOAD: "Payload: ",
};
