import * as THREE from "three";

const STATE = {
    NONE: -1,
    ROTATE: 0,
    ZOOM: 2,
    PAN: 1,
    TOUCH_ROTATE: 3,
    TOUCH_ZOOM_PAN: 4,
};
const EPS = 0.000001;
const changeEvent = { type: "change" };
const startEvent = { type: "start" };
const endEvent = { type: "end" };
const touchEvents = {
    ONE_FINGER: 1,
    TWO_FINGER: 2,
};

export default class CustomTrackballControls {
    constructor(
        objectUnderControl,
        domElement,
        mriController,
        setIsOnMeshViewerControl = null
    ) {
        Object.assign(
            CustomTrackballControls,
            Object.create(THREE.EventDispatcher.prototype)
        );

        this.objectUnderControl = objectUnderControl;
        this.domElement = domElement !== undefined ? domElement : document;
        this.mriController = mriController;
        // API

        this.enabled = true;

        this.setIsOnMeshViewerControl = setIsOnMeshViewerControl;

        this.screen = { left: 0, top: 0, width: 0, height: 0 };

        this.rotateSpeed = 1.0;
        this.zoomSpeed = 1.2;
        this.panSpeed = 0.3;

        this.noRotate = false;
        this.noZoom = false;
        this.noPan = false;
        this.noSliceScroll = false;
        this.invertMouseX = false;

        this.staticMoving = false;
        this.dynamicDampingFactor = 0.2;

        this.minDistance = 0;
        this.maxDistance = Infinity;

        this.keys = [65 /*A*/, 83 /*S*/, 68 /*D*/];

        // internals

        this.target = new THREE.Vector3();

        this.lastPosition = new THREE.Vector3();

        this.state = STATE.NONE;
        this.prevState = STATE.NONE;
        this.eye = new THREE.Vector3();
        this.movePrev = new THREE.Vector2();
        this.moveCurr = new THREE.Vector2();
        this.lastAxis = new THREE.Vector3();
        this.lastAngle = 0;
        this.zoomStart = new THREE.Vector2();
        this.zoomEnd = new THREE.Vector2();
        this.touchZoomDistanceStart = 0;
        this.touchZoomDistanceEnd = 0;
        this.panStart = new THREE.Vector2();
        this.panEnd = new THREE.Vector2();

        // for reset

        this.target0 = this.target.clone();
        this.position0 = this.objectUnderControl.position.clone();
        this.up0 = this.objectUnderControl.up.clone();

        this._onMouseWheel = null;
        this._onMouseDown = null;
        this._onMouseUp = null;
        this._onMouseMove = null;
        this._onContextMenu = null;
        this._onTouchStart = null;
        this._onTouchEnd = null;
        this._onTouchMove = null;
    }

    handleResize() {
        if (this.domElement === document) {
            this.screen.left = 0;
            this.screen.top = 0;
            this.screen.width = window.innerWidth;
            this.screen.height = window.innerHeight;
        } else {
            let box = this.domElement.getBoundingClientRect();
            // adjustments come from similar code in the jquery offset() function
            let d = this.domElement.ownerDocument.documentElement;
            this.screen.left = box.left + window.pageXOffset - d.clientLeft;
            this.screen.top = box.top + window.pageYOffset - d.clientTop;
            this.screen.width = box.width;
            this.screen.height = box.height;
        }
    }

    handleEvent(event) {
        if (typeof this[event.type] == "function") {
            this[event.type](event);
        }
    }

    getMouseOnScreen(pageX, pageY) {
        let x = (pageX - this.screen.left) / this.screen.width;
        let y = (pageY - this.screen.top) / this.screen.height;

        let vector = new THREE.Vector2();
        vector.set(x, y);

        if (this.invertMouseX) {
            vector.x = -1 * vector.x;
        }

        return vector;
    }

    getMouseOnCircle(pageX, pageY) {
        let x =
            (pageX - this.screen.width * 0.5 - this.screen.left) /
            (this.screen.width * 0.5);

        let y =
            (this.screen.height + 2 * (this.screen.top - pageY)) /
            this.screen.width; // screen.width intentional

        let vector = new THREE.Vector2();
        vector.set(x, y);

        if (this.invertMouseX) {
            vector.x = -1 * vector.x;
        }

        return vector;
    }

    rotateCamera() {
        let axis = new THREE.Vector3();
        let quaternion = new THREE.Quaternion();
        let eyeDirection = new THREE.Vector3();
        let objectUnderControlUpDirection = new THREE.Vector3();
        let objectUnderControlSidewaysDirection = new THREE.Vector3();
        let moveDirection = new THREE.Vector3();
        let angle;

        moveDirection.set(
            this.movePrev.x - this.moveCurr.x,
            this.moveCurr.y - this.movePrev.y,
            0
        );
        angle = moveDirection.length();

        if (angle) {
            this.eye.copy(this.objectUnderControl.position).sub(this.target);

            eyeDirection.copy(this.eye).normalize();
            objectUnderControlUpDirection
                .copy(this.objectUnderControl.up)
                .normalize();
            objectUnderControlSidewaysDirection
                .crossVectors(objectUnderControlUpDirection, eyeDirection)
                .normalize();

            objectUnderControlUpDirection.setLength(moveDirection.y);
            objectUnderControlSidewaysDirection.setLength(moveDirection.x);

            moveDirection.copy(
                objectUnderControlUpDirection.add(
                    objectUnderControlSidewaysDirection
                )
            );

            axis.crossVectors(moveDirection, this.eye).normalize();

            angle *= this.rotateSpeed;
            quaternion.setFromAxisAngle(axis, angle);

            this.eye.applyQuaternion(quaternion);
            this.objectUnderControl.up.applyQuaternion(quaternion);

            this.lastAxis.copy(axis);
            this.lastAngle = angle;
        } else if (!this.staticMoving && this.lastAngle) {
            this.lastAngle *= Math.sqrt(1.0 - this.dynamicDampingFactor);
            this.eye.copy(this.objectUnderControl.position).sub(this.target);
            quaternion.setFromAxisAngle(this.lastAxis, this.lastAngle);
            this.eye.applyQuaternion(quaternion);
            this.objectUnderControl.up.applyQuaternion(quaternion);
        }

        this.movePrev.copy(this.moveCurr);
    }

    zoomCamera() {
        let factor;

        if (this.state === STATE.TOUCH_ZOOM_PAN) {
            factor = this.touchZoomDistanceStart / this.touchZoomDistanceEnd;
            this.touchZoomDistanceStart = this.touchZoomDistanceEnd;
            this.eye.multiplyScalar(factor);
        } else {
            factor = 1.0 + (this.zoomEnd.y - this.zoomStart.y) * this.zoomSpeed;

            if (factor !== 1.0 && factor > 0.0) {
                this.eye.multiplyScalar(factor);
            }

            if (this.staticMoving) {
                this.zoomStart.copy(this.zoomEnd);
            } else {
                this.zoomStart.y +=
                    (this.zoomEnd.y - this.zoomStart.y) *
                    this.dynamicDampingFactor;
            }
        }
    }

    panCamera() {
        let mouseChange = new THREE.Vector2();
        let objectUnderControlUp = new THREE.Vector3();
        let pan = new THREE.Vector3();

        mouseChange.copy(this.panEnd).sub(this.panStart);

        if (mouseChange.lengthSq()) {
            mouseChange.multiplyScalar(this.eye.length() * this.panSpeed);

            pan.copy(this.eye)
                .cross(this.objectUnderControl.up)
                .setLength(-mouseChange.x);
            pan.add(
                objectUnderControlUp
                    .copy(this.objectUnderControl.up)
                    .setLength(mouseChange.y)
            );

            this.objectUnderControl.position.add(pan);
            this.target.add(pan);

            if (this.staticMoving) {
                this.panStart.copy(this.panEnd);
            } else {
                this.panStart.add(
                    mouseChange
                        .subVectors(this.panEnd, this.panStart)
                        .multiplyScalar(this.dynamicDampingFactor)
                );
            }
        }
    }

    checkDistances() {
        if (!this.noZoom || !this.noPan) {
            if (this.eye.lengthSq() > this.maxDistance * this.maxDistance) {
                this.objectUnderControl.position.addVectors(
                    this.target,
                    this.eye.setLength(this.maxDistance)
                );
                this.zoomStart.copy(this.zoomEnd);
            }

            if (this.eye.lengthSq() < this.minDistance * this.minDistance) {
                this.objectUnderControl.position.addVectors(
                    this.target,
                    this.eye.setLength(this.minDistance)
                );
                this.zoomStart.copy(this.zoomEnd);
            }
        }
    }

    saveState() {
        this.target0.copy(this.target);
        this.position0.copy(this.objectUnderControl.position);
        this.up0.copy(this.objectUnderControl.up);
    }

    update() {
        let scope = this;

        this.eye.subVectors(this.objectUnderControl.position, this.target);

        if (!this.noRotate) {
            this.rotateCamera();
        }

        if (!this.noZoom) {
            this.zoomCamera();
        }

        if (!this.noPan) {
            this.panCamera();
        }

        this.objectUnderControl.position.addVectors(this.target, this.eye);

        this.checkDistances();

        this.objectUnderControl.lookAt(this.target);

        if (
            this.lastPosition.distanceToSquared(
                this.objectUnderControl.position
            ) > EPS
        ) {
            scope.dispatchEvent(changeEvent);

            this.lastPosition.copy(this.objectUnderControl.position);
        }
    }

    reset() {
        this.state = STATE.NONE;
        this.prevState = STATE.NONE;

        this.target.copy(this.target0);
        this.objectUnderControl.position.copy(this.position0);
        this.objectUnderControl.up.copy(this.up0);

        this.eye.subVectors(this.objectUnderControl.position, this.target);

        this.objectUnderControl.lookAt(this.target);

        this.dispatchEvent(changeEvent);

        this.lastPosition.copy(this.objectUnderControl.position);
    }

    mousedown(event) {
        if (!this.enabled) return;

        event.preventDefault();
        event.stopPropagation();

        if (this.state === STATE.NONE) {
            this.state = event.button;
        }

        if ((this.state === STATE.PAN || event.shiftKey) && !this.noPan) {
            this.panStart.copy(this.getMouseOnScreen(event.pageX, event.pageY));
            this.panEnd.copy(this.panStart);
        } else if (this.state === STATE.ROTATE && !this.noRotate) {
            this.moveCurr.copy(this.getMouseOnCircle(event.pageX, event.pageY));
            this.movePrev.copy(this.moveCurr);
        } else if (this.state === STATE.ZOOM && !this.noZoom) {
            this.zoomStart.copy(
                this.getMouseOnScreen(event.pageX, event.pageY)
            );
            this.zoomEnd.copy(this.zoomStart);
        }

        document.addEventListener("mousemove", this._onMouseMove, false);
        document.addEventListener("mouseup", this._onMouseUp, false);

        this.dispatchEvent(startEvent);
    }

    mousemove(event) {
        if (!this.enabled) return;

        event.preventDefault();
        event.stopPropagation();

        if (this.setIsOnMeshViewerControl) {
            this.setIsOnMeshViewerControl(true);
        }

        if ((this.state === STATE.PAN || event.shiftKey) && !this.noPan) {
            this.panEnd.copy(this.getMouseOnScreen(event.pageX, event.pageY));
        } else if (this.state === STATE.ROTATE && !this.noRotate) {
            this.movePrev.copy(this.moveCurr);
            this.moveCurr.copy(this.getMouseOnCircle(event.pageX, event.pageY));
        } else if (this.state === STATE.ZOOM && !this.noZoom) {
            this.zoomEnd.copy(this.getMouseOnScreen(event.pageX, event.pageY));
        }
    }

    mouseup(event) {
        if (!this.enabled) return;

        event.preventDefault();
        event.stopPropagation();

        this.state = STATE.NONE;

        document.removeEventListener("mousemove", this._onMouseMove);
        document.removeEventListener("mouseup", this._onMouseUp);
        this.dispatchEvent(endEvent);
    }

    mousewheel(event) {
        if (!this.enabled) return;

        if (this.noSliceScroll) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (this.mriController) {
                this.mriController.shiftSliceByWheel(event);
            }
        }
    }

    touchstart(event) {
        if (this.enabled === false) return;

        let dx, dy, x, y;

        switch (event.touches.length) {
            case touchEvents.ONE_FINGER:
                this.state = STATE.TOUCH_ROTATE;
                this.moveCurr.copy(
                    this.getMouseOnCircle(
                        event.touches[0].pageX,
                        event.touches[0].pageY
                    )
                );
                this.movePrev.copy(this.moveCurr);
                break;

            default:
                // 2 or more
                this.state = STATE.TOUCH_ZOOM_PAN;
                dx = event.touches[0].pageX - event.touches[1].pageX;
                dy = event.touches[0].pageY - event.touches[1].pageY;
                this.touchZoomDistanceEnd = this.touchZoomDistanceStart =
                    Math.sqrt(dx * dx + dy * dy);

                x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                this.panStart.copy(this.getMouseOnScreen(x, y));
                this.panEnd.copy(this.panStart);
                break;
        }

        this.dispatchEvent(startEvent);
    }

    touchmove(event) {
        if (this.enabled === false) return;

        event.preventDefault();
        event.stopPropagation();

        let dx, dy, x, y;

        switch (event.touches.length) {
            case touchEvents.ONE_FINGER:
                this.movePrev.copy(this.moveCurr);
                this.moveCurr.copy(
                    this.getMouseOnCircle(
                        event.touches[0].pageX,
                        event.touches[0].pageY
                    )
                );
                break;

            default:
                // 2 or more
                dx = event.touches[0].pageX - event.touches[1].pageX;
                dy = event.touches[0].pageY - event.touches[1].pageY;
                this.touchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy);

                x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                this.panEnd.copy(this.getMouseOnScreen(x, y));
                break;
        }
    }

    touchend(event) {
        if (this.enabled === false) return;

        switch (event.touches.length) {
            case touchEvents.ONE_FINGER:
                this.state = STATE.NONE;
                break;

            case touchEvents.TWO_FINGER:
                this.state = STATE.TOUCH_ROTATE;
                this.moveCurr.copy(
                    this.getMouseOnCircle(
                        event.touches[0].pageX,
                        event.touches[0].pageY
                    )
                );
                this.movePrev.copy(this.moveCurr);
                break;

            default:
                break;
        }

        this.dispatchEvent(endEvent);
    }

    contextmenu(event) {
        if (!this.enabled) return;

        event.preventDefault();
    }

    dispose() {
        this.domElement.removeEventListener(
            "contextmenu",
            this._onContextMenu,
            false
        );
        this.domElement.removeEventListener(
            "mousedown",
            this._onMouseDown,
            false
        );
        this.domElement.removeEventListener("wheel", this._onMouseWheel, false);

        this.domElement.removeEventListener(
            "touchstart",
            this._onTouchStart,
            false
        );
        this.domElement.removeEventListener(
            "touchend",
            this._onTouchEnd,
            false
        );
        this.domElement.removeEventListener(
            "touchmove",
            this._onTouchMove,
            false
        );

        document.removeEventListener("mousemove", this._onMouseMove, false);
        document.removeEventListener("mouseup", this._onMouseUp, false);
    }

    start() {
        function bind(scope, fn) {
            return function () {
                fn.apply(scope, arguments);
            };
        }

        this._onMouseWheel = bind(this, this.mousewheel);
        this._onMouseDown = bind(this, this.mousedown);
        this._onMouseUp = bind(this, this.mouseup);
        this._onMouseMove = bind(this, this.mousemove);
        this._onContextMenu = bind(this, this.contextmenu);
        this._onTouchStart = bind(this, this.touchstart);
        this._onTouchEnd = bind(this, this.touchend);
        this._onTouchMove = bind(this, this.touchmove);

        this.domElement.addEventListener(
            "contextmenu",
            this._onContextMenu,
            false
        );
        this.domElement.addEventListener("mousedown", this._onMouseDown, false);
        this.domElement.addEventListener("mouseup", this._onMouseUp, false);
        this.domElement.addEventListener("wheel", this._onMouseWheel, false);

        this.domElement.addEventListener(
            "touchstart",
            this._onTouchStart,
            false
        );
        this.domElement.addEventListener("touchend", this._onTouchEnd, false);
        this.domElement.addEventListener("touchmove", this._onTouchMove, false);

        this.handleResize();

        // force an update at start
        this.update();
    }

    init(values) {
        if (values) {
            for (let key in values) {
                let newValue = values[String(key)];
                let currentValue = this[String(key)];
                if (newValue !== undefined && currentValue !== undefined) {
                    this[String(key)] = newValue;
                }
            }
        }
    }

    addEventListener(type, listener) {
        if (!this._listeners) this._listeners = {};

        let listeners = this._listeners;

        if (listeners[String(type)] === undefined) {
            listeners[String(type)] = [];
        }

        if (listeners[String(type)].indexOf(listener) === -1) {
            listeners[String(type)].push(listener);
        }
    }

    hasEventListener(type, listener) {
        if (!this._listeners) return false;

        let listeners = this._listeners;

        return (
            listeners[String(type)] !== undefined &&
            listeners[String(type)].indexOf(listener) !== -1
        );
    }

    removeEventListener(type, listener) {
        if (!this._listeners) return;

        let listeners = this._listeners;
        let listenerArray = listeners[String(type)];

        if (listenerArray !== undefined) {
            let index = listenerArray.indexOf(listener);

            if (index !== -1) {
                listenerArray.splice(index, 1);
            }
        }
    }

    dispatchEvent(event) {
        if (!this._listeners) return;

        let listeners = this._listeners;
        let listenerArray = listeners[event.type];

        if (listenerArray) {
            event.target = this;

            let array = listenerArray.slice(0);

            for (let i = 0, l = array.length; i < l; i++) {
                array[Number(i)].call(this, event);
            }
        }
    }
}
