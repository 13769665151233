import React, { Component } from "react";
import { connect } from "react-redux";
import { setCurrentTreatmentPlan } from "../../CreateTPlan_1Upload/actions";
import { withRouter } from "react-router-dom";
import { EMPTY_STRING } from "../../../constants";
import "./styles.css";
import DecisionAlertCard from "../../__shared__/DecisionAlertCard";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { setErrorState } from "../../../redux/error_banner/actions";
import { saveTargets } from "../helpers";
import SpinLoader from "../../__shared__/SpinLoader";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import { When } from "react-if";

class AblationStatisticsConfirmPrompt extends Component {
    constructor(props) {
        super(props);
        this.saveAndContinue = this.saveAndContinue.bind(this);

        this.state = {
            clcCoverage: 0,
            clcCoverageLoaded: false,
            saveComplete: false,
            saveInProgress: false,
        };
    }

    componentDidMount() {
        let coverage = this.props.cancerLesionCoverageInstance();
        if (!coverage) coverage = 0;

        this.setState({
            clcCoverage: coverage,
            clcCoverageLoaded: true,
        });
    }

    saveAndContinue() {
        let scope = this;
        let inMemoryTargets =
            this.props.targetSetModel.getTargetsInTreatmentPlanFormat();

        scope.setState({
            saveInProgress: true,
        });

        this.props.currentTreatmentPlan.SavedData.AblationCoverageOfLesion =
            this.state.clcCoverage;

        saveTargets(scope, inMemoryTargets).then(() => {
            scope.setState({
                saveComplete: true,
            });

            this.props.yesCallback();
        });
    }

    getNumAblations(tplan) {
        if (TreatmentPlan.hasTargets(tplan)) {
            return TreatmentPlan.getTargets(tplan).length;
        } else {
            return 0;
        }
    }

    render() {
        let clcCoverage = this.state.clcCoverage * 100;
        let numTrgts = this.getNumAblations(
            this.props.currentTreatmentPlan.TreatmentPlan
        );

        let clcCoverageTxt;
        if (this.state.clcCoverageLoaded) {
            clcCoverageTxt = `${clcCoverage.toFixed(0)}%`;
        } else {
            clcCoverageTxt = "--%";
        }

        return (
            <DecisionAlertCard
                id={"edit-margin-alert"}
                noBtnCallback={this.props.noCallback}
                noBtnTxt={"Edit"}
                yesBtnTxt={
                    this.state.saveInProgress ? (
                        <SpinLoader
                            display={"inline-block"}
                            height={"25px"}
                            width={"25px"}
                            animationDuration={"1s"}
                        />
                    ) : (
                        "Confirm"
                    )
                }
                yesBtnCallback={this.saveAndContinue}
            >
                <div className={"treatment-check-card-header"}>
                    <p className={"display-26"} style={{ color: "#FFFFFF" }}>
                        Tool Placement Checks
                    </p>
                </div>
                <div className={"treatment-check-card-body"}>
                    <div className={"treatment-check-row"}>
                        <p className={"display-16"}>Number of Tool Sites:</p>
                        <div className={"treatment-check-row-widget"}>
                            <Form>
                                <Form.Control
                                    className={"h-100"}
                                    size="lg"
                                    readOnly={true}
                                    placeholder={numTrgts}
                                />
                            </Form>
                        </div>
                    </div>
                    <div className={"treatment-check-row"}>
                        <p className={"display-16"}>Cancer Lesion Coverage:</p>
                        <div className={"treatment-check-row-widget"}>
                            <Form>
                                <Form.Control
                                    id={"treatment-check-row-value"}
                                    className={"h-100"}
                                    size="lg"
                                    readOnly={true}
                                    placeholder={clcCoverageTxt}
                                />
                            </Form>
                        </div>
                        <When condition={!this.state.clcCoverageLoaded}>
                            <SpinLoader
                                id={"clc-coverage-spinner"}
                                width={"20px"}
                                height={"20px"}
                                loaderWidth={"3px"}
                                animationDuration={"1s"}
                            />
                        </When>
                    </div>
                </div>
            </DecisionAlertCard>
        );
    }
}

AblationStatisticsConfirmPrompt.propTypes = {
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    visituuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
    history: PropTypes.object,
    setErrorState: PropTypes.func,
    yesCallback: PropTypes.func,
    noCallback: PropTypes.func,
    targetSetModel: PropTypes.object,
    cancerLesionCoverageInstance: PropTypes.func,
};

AblationStatisticsConfirmPrompt.defaultProps = {
    useruuid: EMPTY_STRING,
    authToken: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    currentTreatmentPlan: {},
    yesCallback: () => {},
    noCallback: () => {},
    targetSetModel: {},
    cancerLesionCoverageInstance: () => {},
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        setErrorState: (hasError, errorText) =>
            dispatch(setErrorState(hasError, errorText)),
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AblationStatisticsConfirmPrompt)
);
