import { volumeNames } from "../../../../../constants";
import PatientAnatomyModel from "./PatientAnatomyModel";

export default class StructurePickerPatientAnatomyModel extends PatientAnatomyModel {
    get prostateUploaded() {
        return this._models.get(volumeNames.MR_PROSTATE_USER_UPLOAD);
    }

    set prostateUploaded(structure) {
        this._setModel(volumeNames.MR_PROSTATE_USER_UPLOAD, structure, true);
    }

    get prostateAI() {
        return this._models.get(volumeNames.MR_PROSTATE_AI);
    }

    set prostateAI(structure) {
        this._setModel(volumeNames.MR_PROSTATE_AI, structure, true);
    }

    setProstateStructByName(structureName, value) {
        if (structureName === volumeNames.MR_PROSTATE_USER_UPLOAD) {
            this.prostateUploaded = value;
        } else if (structureName === volumeNames.MR_PROSTATE_AI) {
            this.prostateAI = value;
        }
    }
}
