export default class CustomTargetController {
    constructor(customTargetModel) {
        this._customTargetSetModel = customTargetModel;
        this._mouseControlsEnabled = false;
        this._showApplicator = true;
    }

    get showApplicator() {
        return this._showApplicator;
    }

    set showApplicator(show) {
        this._showApplicator = show;

        if (this._customTargetSetModel) {
            this._customTargetSetModel.laserCatheterVisibility =
                this._showApplicator;
        }
    }

    get customTargetSetModel() {
        return this._customTargetSetModel;
    }

    set customTargetSetModel(model) {
        this._customTargetSetModel = model;
    }

    get mouseControlsEnabled() {
        return this._mouseControlsEnabled;
    }

    set mouseControlsEnabled(isOn) {
        this._mouseControlsEnabled = isOn;
    }

    get meshViewer() {
        return this._meshViewer;
    }

    set meshViewer(meshViewer) {
        this._meshViewer = meshViewer;
    }

    setPrimitive(volumeName) {
        if (!this._meshViewer) {
            return;
        }

        this._customTargetSetModel.setPrimitiveVolume(volumeName);
    }
}
