import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

export default function ClickOutside({ children, onClickOutsideCallback }) {
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target) &&
                !event.target.closest(".click-outside-ignore")
            ) {
                onClickOutsideCallback && onClickOutsideCallback(event);
            }
        };

        window.addEventListener("click", handleClickOutside);
        return () => window.removeEventListener("click", handleClickOutside);
    }, [onClickOutsideCallback]);

    return (
        <div style={{ display: "contents" }} ref={wrapperRef}>
            {children}
        </div>
    );
}

ClickOutside.propTypes = {
    children: PropTypes.element,
    onClickOutsideCallback: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool,
    ]),
};
