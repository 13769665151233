import React from "react";
import PropTypes from "prop-types";
import "./styles.css";

class SpinLoader extends React.Component {
    render() {
        let style = {
            width: this.props.width,
            height: this.props.height,
            border: `${this.props.loaderWidth} solid ${this.props.bgColor}`,
            borderRadius: "50%",
            borderTop: `${this.props.loaderWidth} solid ${this.props.fgColor}`,
            WebkitAnimation: `spin ${this.props.animationDuration} linear infinite`,
            animation: `spin ${this.props.animationDuration} linear infinite`,
            display: this.props.display,
        };

        return (
            <div
                id={this.props.id}
                className={"spin-loader"}
                style={{ ...style, ...this.props.style }}
            />
        );
    }
}

SpinLoader.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    loaderWidth: PropTypes.string,
    bgColor: PropTypes.string,
    fgColor: PropTypes.string,
    animationDuration: PropTypes.string,
    id: PropTypes.string,
    display: PropTypes.string,
    style: PropTypes.object,
};

SpinLoader.defaultProps = {
    width: "50px",
    height: "50px",
    loaderWidth: "5px",
    bgColor: "rgba(0.0, 0.0, 0.0, 0.0)",
    fgColor: "#FFFFFF",
    animationDuration: "2s",
    style: {},
};

export default SpinLoader;
