export default class ShadersFragment {
    // pass uniforms object
    constructor(uniforms) {
        this._uniforms = uniforms;
        this._functions = {};
        this._main = "";
    }

    functions() {
        if (this._main === "") {
            // if main is empty, functions can not have been computed
            this.main();
        }

        let content = "";
        for (let property in this._functions) {
            content += this._functions[property] + "\n";
        }

        return content;
    }

    uniforms() {
        let content = "";
        for (let property in this._uniforms) {
            let uniform = this._uniforms[property];
            content += `uniform ${uniform.typeGLSL} ${property}`;

            if (uniform && uniform.length) {
                content += `[${uniform.length}]`;
            }

            content += ";\n";
        }

        return content;
    }

    main() {
        // need to pre-call main to fill up the functions list
        this._main = `precision highp float;

float luma (vec3 rgb) {
  return (rgb.r + rgb.g + rgb.b)/3.0;
}

const float T = 0.04;
const float M = 1.0;
const float L = 0.002;

void main(void) {
  // Sobel filter
  // Adapted from https://github.com/mrdoob/three.js/blob/dev/examples/webgl_postprocessing_sobel.html

  float borderWidth = uWidth; // in px
  vec2 resolution = vec2(uCanvasWidth, uCanvasHeight);

  float tx0y0 = texture2D(uTextureFilled, (gl_FragCoord.xy + vec2(-borderWidth, -borderWidth)) / resolution.xy).a;
  float tx0y1 = texture2D(uTextureFilled, (gl_FragCoord.xy + vec2(-borderWidth, 0.0)) / resolution.xy).a;
  float tx0y2 = texture2D(uTextureFilled, (gl_FragCoord.xy + vec2(-borderWidth, borderWidth)) / resolution.xy).a;

  float tx1y0 = texture2D(uTextureFilled, (gl_FragCoord.xy + vec2(0.0, -borderWidth)) / resolution.xy).a;
  float tx1y1 = texture2D(uTextureFilled, (gl_FragCoord.xy + vec2(0.0, 0.0)) / resolution.xy).a;
  float tx1y2 = texture2D(uTextureFilled, (gl_FragCoord.xy + vec2(0.0, borderWidth)) / resolution.xy).a;

  float tx2y0 = texture2D(uTextureFilled, (gl_FragCoord.xy + vec2(borderWidth, -borderWidth)) / resolution.xy).a;
  float tx2y1 = texture2D(uTextureFilled, (gl_FragCoord.xy + vec2(borderWidth, 0.0)) / resolution.xy).a;
  float tx2y2 = texture2D(uTextureFilled, (gl_FragCoord.xy + vec2(borderWidth, borderWidth)) / resolution.xy).a;

  const mat3 Gx = mat3( -1, -2, -1, 0, 0, 0, 1, 2, 1 ); // x direction kernel
  const mat3 Gy = mat3( -1, 0, 1, -2, 0, 2, -1, 0, 1 ); // y direction kernel

  float valueGx = Gx[0][0] * tx0y0 + Gx[1][0] * tx1y0 + Gx[2][0] * tx2y0 +
                  Gx[0][1] * tx0y1 + Gx[1][1] * tx1y1 + Gx[2][1] * tx2y1 +
                  Gx[0][2] * tx0y2 + Gx[1][2] * tx1y2 + Gx[2][2] * tx2y2;

  float valueGy = Gy[0][0] * tx0y0 + Gy[1][0] * tx1y0 + Gy[2][0] * tx2y0 +
                  Gy[0][1] * tx0y1 + Gy[1][1] * tx1y1 + Gy[2][1] * tx2y1 +
                  Gy[0][2] * tx0y2 + Gy[1][2] * tx1y2 + Gy[2][2] * tx2y2;

  float G = sqrt( ( valueGx * valueGx ) + ( valueGy * valueGy ) );

  gl_FragColor = vec4( texture2D(uTextureFilled, (gl_FragCoord.xy) / resolution.xy).rgb, G );

  // Check if less than 3 adjacent pixels are blank and if so, discard
  float sum = tx0y0 + tx0y1 + tx0y2 + tx1y0 + tx1y1 + tx1y2 + tx2y0 + tx2y1 + tx2y2;
  if (sum <= 2.0) {
    gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
    return;
  }

  // Guard against erroneous pixels
  if (gl_FragColor.r == 0.0 && gl_FragColor.g == 0.0 && gl_FragColor.b == 0.0) {
    gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
  }
  
  return;
}
   `;
    }

    compute() {
        return `
// uniforms
${this.uniforms()}

// varying (should fetch it from vertex directly)
varying vec4      vProjectedCoords;

// tailored functions
${this.functions()}

// main loop
${this._main}
      `;
    }
}
