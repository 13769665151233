export const PATIENT_SHARE_HEADER = "Share a Patient";
export const PATIENT_SHARE_HEADER_SHARE = "Share";
export const PATIENT_SHARE_HEADER_NOTES = "Notes";
export const SELECT_DOCTOR = {
    DEFAULT: "Select Doctor",
};
export const PATIENT_SHARE_NO_PATIENT_DATA = "Patient data is not available.";
export const PATIENT_SHARE_PINFO_HEADER_ID = "ID";
export const PATIENT_SHARE_PINFO_HEADER_DOB = "DOB";
export const PATIENT_SHARE_PINFO_HEADER_PSA = "PSA";
export const PATIENT_SHARE_DR_PREFIX = "Dr.";
export const PATIENT_SHARE_QUESTION = "Who are you sharing with?";
export const PATIENT_SHARE_NO_DR_AVAIL = "No doctors available";
export const PATIENT_SHARE_BUTTON_TEXT = "Share Info";
export const TIME_OUT_DURATION_MS = 3000;
export const PATIENT_SHARE_NOTES_TITLE = "Note History";
export const PATIENT_SHARE_NO_NOTES = "Notes will appear here when posted.";
export const PATIENT_SHARE_ADD_NOTE_PLACEHOLDER =
    "Add a note for everyone to see (optional)";
export const PATIENT_SHARE_ADD_NOTE_BUTTON_TEXT = "Post Note";
