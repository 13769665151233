import React, { Component } from "react";
import AvendaLogo from "../../__shared__/AvendaLogo";
import { EMPTY_STRING } from "../../../constants";
import "./styles.css";
import AHButton from "../../__shared__/StyleGuide/AHButton";
import { QRCodeSVG } from "qrcode.react";
import { mfaSteps } from "../index";
import PropTypes from "prop-types";

class QRCodeWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMsg: EMPTY_STRING,
        };
    }

    render() {
        return (
            <div className={"signup-screen"}>
                <AvendaLogo style={{ padding: "30px 0" }} />

                <div className={"signup-form"}>
                    <p
                        className={"display-36 black-text"}
                        style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                        Enable MFA
                    </p>

                    <p
                        className={"display-14 black-text"}
                        style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                        Please scan the QR Code to link your MFA device to your
                        account.
                    </p>
                    <div className={"mfa-qr-code-container"}>
                        <QRCodeSVG
                            id={"qr-code-svg"}
                            value={this.props.otpAuthUrl}
                        />
                        <div style={{ height: 20 }} />
                        <p
                            className={"display-14 black-text"}
                            style={{
                                textAlign: "center",
                                marginBottom: "20px",
                            }}
                        >
                            Or enter the code below into your app
                        </p>

                        <p
                            className={"display-14 black-text"}
                            style={{
                                textAlign: "center",
                                marginBottom: "20px",
                            }}
                        >
                            Secret Key: {this.props.base32}
                        </p>
                        <AHButton
                            id={"access-code-handle-back"}
                            className={"left"}
                            isOutline={false}
                            isSecondary={false}
                            size={"medium"}
                            isDynamic={false}
                            text={"I Have Registered the QR Code"}
                            onClick={() => {
                                this.props.setStep(mfaSteps.OTP_CODE);
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

QRCodeWidget.propTypes = {
    base32: PropTypes.string,
    otpAuthUrl: PropTypes.string,
    setStep: PropTypes.func,
};

QRCodeWidget.defaultProps = {
    base32: EMPTY_STRING,
    otpAuthUrl: EMPTY_STRING,
    setStep: undefined,
};

export default QRCodeWidget;
