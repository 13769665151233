import "./styles.css";
import React, { Component } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import PropTypes from "prop-types";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import black_arrow from "../../../../assets/down_black_arrow.svg";
import white_arrow from "../../../../assets/down_white_arrow.svg";

const CustomToggle = React.forwardRef(
    (
        { children, onClick, arrow, variant, preventDisplayTextOverflow },
        ref
    ) => (
        <button
            className={`avenda-style-guide-dropdown-toggle ${variant}`}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <div
                className={`avenda-style-guide-dropdown-text ${preventDisplayTextOverflow}`}
            >
                {children}
            </div>
            <span>
                <img alt={"arrow"} src={arrow} />
            </span>
        </button>
    )
);

CustomToggle.displayName = "CustomToggle";

CustomToggle.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    arrow: PropTypes.string,
    variant: PropTypes.string,
    preventDisplayTextOverflow: PropTypes.string,
};

export default class AHDropdown extends Component {
    render() {
        let variant = this.props.isSecondary ? "outline-secondary" : "primary";
        let arrow = this.props.isSecondary ? white_arrow : black_arrow;
        let stripedMenu = this.props.isMenuStriped ? "striped" : "undefined";
        let preventDisplayTextOverflow;
        preventDisplayTextOverflow = this.props.preventDisplayTextOverflow
            ? "hide-txt-overflow"
            : preventDisplayTextOverflow;
        const popperConfig = {
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, 3],
                    },
                },
                {
                    name: "preventOverflow",
                    options: {
                        mainAxis: false,
                    },
                },
            ],
        };

        return (
            <Dropdown
                className={`avenda-style-guide-dropdown unselectable ${this.props.className}`}
                bsPrefix={"avenda-custom-"}
                id={this.props.id}
                style={this.props.style}
                drop={this.props.drop}
                onSelect={this.props.onSelect}
            >
                <DropdownToggle
                    as={CustomToggle}
                    variant={variant}
                    preventDisplayTextOverflow={preventDisplayTextOverflow}
                    arrow={arrow}
                >
                    {this.props.title}
                </DropdownToggle>
                <Dropdown.Menu
                    flip={true}
                    popperConfig={popperConfig}
                    className={`avenda-style-guide-dropdown-menu 
                        avenda-style-guide-dropdown-text 
                        ${stripedMenu}`}
                    bsPrefix={"avenda-custom-"}
                >
                    {this.props.children}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

AHDropdown.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onSelect: PropTypes.func,
    title: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
    ]),
    preventDisplayTextOverflow: PropTypes.bool,
    isSecondary: PropTypes.bool,
    isMenuStriped: PropTypes.bool,
    drop: PropTypes.string,
};
