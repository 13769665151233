import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.css";
import { isEmpty } from "../../../helpers/helpers";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";

class KeyPatientInfoMiniCard extends Component {
    render() {
        let fullName = [
            this.props.firstName,
            this.props.middleName,
            this.props.lastName,
        ].join(" ");

        return (
            <div className={"kpinfo-mini-card"}>
                <div className={"kpinfo-mini-header"}>
                    <p className={"display-26"} style={{ color: "#7C7C7C" }}>
                        Key Patient Information
                    </p>
                </div>
                <div className={"kpinfo-mini-table"}>
                    <table className={"kpinfo-mini-col"}>
                        <tbody>
                            <tr>
                                <td className={"kpinfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        Name
                                    </p>
                                </td>
                                <td className={"kpinfo-mini-right-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {fullName}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={"kpinfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        ID
                                    </p>
                                </td>
                                <td className={"kpinfo-mini-right-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {this.props.patientMRN}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={"kpinfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        DOB
                                    </p>
                                </td>
                                <td className={"kpinfo-mini-right-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {this.props.patientDOB}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

KeyPatientInfoMiniCard.propTypes = {
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    patientMRN: PropTypes.string,
    patientDOB: PropTypes.string,
};

KeyPatientInfoMiniCard.defaultProps = {
    firstName: EMPTY_STRING,
    middleName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    patientMRN: EMPTY_STRING,
    patientDOB: EMPTY_STRING,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    let plan = state.CreatePlanReducer.currentTreatmentPlan.TreatmentPlan;

    let newProps = {};
    if (!isEmpty(plan) && !isEmpty(plan.PatientData)) {
        newProps = {
            firstName: plan.PatientData.FirstName,
            middleName: plan.PatientData.MiddleName,
            lastName: plan.PatientData.LastName,
            patientMRN: plan.PatientData.PatientMRN,
            patientDOB: plan.PatientData.DOB,
        };
    }

    return newProps;
};

export default connect(mapStateToProps)(KeyPatientInfoMiniCard);
