import Fonts from "../../../../../helpers/threejs_fonts";
import { volumeNames } from "../../../../../constants";
import { LINE_MATERIAL, TEXT_MATERIAL } from "../../MeshViewer/materials";
import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { Font } from "three/examples/jsm/loaders/FontLoader";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { Line2 } from "three/examples/jsm/lines/Line2";
import StructureModel from "../PatientAnatomy/StructureModel";
import { LPS2RAS } from "../../helpers";

export default class ProstateAxes extends StructureModel {
    initializeView(prostateMesh) {
        this._createMeshView(prostateMesh);
    }

    _createMeshView(prostateMesh) {
        let center = new THREE.Vector3();
        center.copy(prostateMesh.center);

        center.applyMatrix4(LPS2RAS);

        let size = new THREE.Vector3();
        prostateMesh.boundingBox.getSize(size);

        let axesObj = new THREE.BoxHelper(prostateMesh._selfMeshView, 0xffffff);
        axesObj.material.visible = false;
        axesObj.name = volumeNames.PROSTATE_AXES;

        let p1, p2, name, position;

        p1 = new THREE.Vector3(center.x, center.y, center.z - size.z / 2);
        p2 = new THREE.Vector3(
            center.x,
            center.y,
            center.z - (size.z / 2 + 10)
        );
        name = volumeNames.PROSTATE_AXES + "_BASE_LINE";
        axesObj.children.push(createLine(p1, p2, name));

        position = new THREE.Vector3(
            center.x,
            center.y,
            center.z - (size.z / 2 + 20)
        );
        name = volumeNames.PROSTATE_AXES + "_BASE_TEXT";
        axesObj.children.push(createText("base", position, name));

        p1 = new THREE.Vector3(center.x, center.y, center.z + size.z / 2);
        p2 = new THREE.Vector3(
            center.x,
            center.y,
            center.z + (size.z / 2 + 10)
        );
        name = volumeNames.PROSTATE_AXES + "_APEX_LINE";
        axesObj.children.push(createLine(p1, p2, name));

        position = new THREE.Vector3(
            center.x,
            center.y,
            center.z + (size.z / 2 + 20)
        );
        name = volumeNames.PROSTATE_AXES + "_APEX_TEXT";
        axesObj.children.push(createText("apex", position, name));

        p1 = new THREE.Vector3(center.x + size.x / 2, center.y, center.z);
        p2 = new THREE.Vector3(center.x + size.x / 2 + 10, center.y, center.z);
        name = volumeNames.PROSTATE_AXES + "_RIGHT_LINE";
        axesObj.children.push(createLine(p1, p2, name));

        position = new THREE.Vector3(
            center.x + size.x / 2 + 14,
            center.y,
            center.z
        );
        name = volumeNames.PROSTATE_AXES + "_RIGHT_TEXT";
        axesObj.children.push(createText("right", position, name));

        p1 = new THREE.Vector3(center.x - size.x / 2, center.y, center.z);
        p2 = new THREE.Vector3(
            center.x - (size.x / 2 + 10),
            center.y,
            center.z
        );
        name = volumeNames.PROSTATE_AXES + "_LEFT_LINE";
        axesObj.children.push(createLine(p1, p2, name));

        position = new THREE.Vector3(
            center.x - (size.x / 2 + 14),
            center.y,
            center.z
        );
        name = volumeNames.PROSTATE_AXES + "_LEFT_TEXT";
        axesObj.children.push(createText("left", position, name));

        p1 = new THREE.Vector3(center.x, center.y - size.y / 2, center.z);
        p2 = new THREE.Vector3(
            center.x,
            center.y - (size.y / 2 + 10),
            center.z
        );
        name = volumeNames.PROSTATE_AXES + "_ANTERIOR_LINE";
        axesObj.children.push(createLine(p1, p2, name));

        position = new THREE.Vector3(
            center.x,
            center.y - (size.y / 2 + 14),
            center.z
        );
        name = volumeNames.PROSTATE_AXES + "_ANTERIOR_TEXT";
        axesObj.children.push(createText("anterior", position, name));

        p1 = new THREE.Vector3(center.x, center.y + size.y / 2, center.z);
        p2 = new THREE.Vector3(
            center.x,
            center.y + (size.y / 2 + 10),
            center.z
        );
        name = volumeNames.PROSTATE_AXES + "_POSTERIOR_LINE";
        axesObj.children.push(createLine(p1, p2, name));

        position = new THREE.Vector3(
            center.x,
            center.y + (size.y / 2 + 14),
            center.z
        );
        name = volumeNames.PROSTATE_AXES + "_POSTERIOR_TEXT";
        axesObj.children.push(createText("posterior", position, name));

        this._selfMeshView = axesObj;
        if (prostateMesh._selfMeshView) {
            prostateMesh._selfMeshView.add(axesObj);
        }
    }
}

const textSettings = {
    font: new Font(Fonts.helvetikaRegular),
    size: 3.0,
    height: 0.2,
    curveSegments: 20,
    bevelEnabled: false,
    bevelThickness: 5,
    bevelSize: 5,
    bevelSegments: 5,
};

const createLine = (p1, p2, name, width = 0.4, glLine = false) => {
    if (glLine) {
        let points = [p1, p2];

        let lineGeometry = new THREE.BufferGeometry();
        lineGeometry.setFromPoints(points);

        let lineMaterial = LINE_MATERIAL.clone();

        let lineMesh = new THREE.Line(lineGeometry, lineMaterial);
        lineMesh.name = name;
        return lineMesh;
    } else {
        let points = [p1.x, p1.y, p1.z, p2.x, p2.y, p2.z];

        let lineGeometry = new LineGeometry();
        lineGeometry.setPositions(points);
        let lineMaterial = new LineMaterial({
            color: 0xffffff,
            linewidth: width,
            worldUnits: true,
        });

        let line = new Line2(lineGeometry, lineMaterial);
        line.name = name;
        return line;
    }
};

const createText = (text, position, name) => {
    let textGeometry = new TextGeometry(text, textSettings);
    let textObject = new THREE.Mesh(textGeometry, TEXT_MATERIAL.clone());
    textObject.name = name;
    textObject.position.copy(position);

    return textObject;
};
