import React, { Component } from "react";
import "./styles.css";
import { connect } from "react-redux";
import { setDownloadProperties } from "../actions";
import {
    ASCENDING,
    AVENDA_COMPONENT_COLORS,
    EMPTY_STRING,
} from "../../../constants";
import { N_A, sortCategories } from "../constants";
import { sortPatientData } from "../helpers";
import PatientListItem from "../PatientListItem";
import PropTypes from "prop-types";
import { sprintf } from "sprintf-js";
import { withRouter } from "react-router-dom";
import SpinLoader from "../../__shared__/SpinLoader";

class PatientList extends Component {
    constructor(props) {
        super(props);

        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.createListComponents = this.createListComponents.bind(this);
        this.parsePatientHistoryList = this.parsePatientHistoryList.bind(this);
        this.getListContent = this.getListContent.bind(this);
    }

    handleListItemClick(patientUuid, planUuid) {
        this.props.setDownloadProperties(true, patientUuid, planUuid);
    }

    checkInProgress(element) {
        return !element.SavedData.PlanCreationCompleteFlag;
    }

    createListComponents(patientSet) {
        let listItems = [];
        let colorShift = false;
        let patientIndex = 0;
        for (let patientId in patientSet) {
            if (Object.prototype.hasOwnProperty.call(patientSet, patientId)) {
                let ptnt = patientSet[patientId.toString()];

                let inProgress = ptnt.plans.some((elem) =>
                    this.checkInProgress(elem)
                );

                let listItem = (
                    <PatientListItem
                        key={"patient-" + patientIndex}
                        listItemId={"patient-list-item-" + patientIndex}
                        name={ptnt.fullName}
                        id={ptnt.patientId}
                        clickCallback={this.handleListItemClick}
                        style={colorShift ? regColor : offColor}
                        plansData={ptnt.plans}
                        toggleClose={this.props.toggleCloseAll}
                        inProgress={inProgress}
                        checkNotification={this.props.checkNotification}
                        patientData={this.props.patientData}
                    />
                );

                patientIndex += 1;
                colorShift = !colorShift;
                listItems.push(listItem);
            }
        }

        return listItems;
    }

    parsePatientHistoryList(patientData) {
        let scope = this;

        let patientSet = {};
        patientData.forEach((p) => {
            let metadata = p.MetaData;
            let savedData = p.SavedData;

            let fullName =
                metadata.FirstName === EMPTY_STRING
                    ? N_A
                    : sprintf(
                          "%s %s %s",
                          metadata.FirstName,
                          parseMiddleName(metadata.MiddleName),
                          metadata.LastName
                      ).replace(/\s+/g, " ");
            let patientId =
                !metadata.PatientMRN.length > 0 ? N_A : metadata.PatientMRN;
            let creationDate =
                !metadata.PlanCreationDate.length > 0
                    ? N_A
                    : metadata.PlanCreationDate;

            let listItem = {
                fullName: fullName,
                patientId: patientId,
                creationDate: creationDate,
                visitUuid: metadata.VisitUUID,
                patientUuid: metadata.PatientUUID,
                planUuid:
                    !metadata.PlanUUID.length > 0 ? N_A : metadata.PlanUUID,
                SavedData: savedData,
            };

            if (metadata.PatientUUID in patientSet) {
                patientSet[metadata.PatientUUID].plans.push(listItem);
            } else {
                patientSet[metadata.PatientUUID] = {
                    fullName: fullName,
                    patientId: patientId,
                    plans: [listItem],
                };
            }
        });

        let orderedPatientSet = {};
        Object.entries(patientSet)
            .sort((patA, patB) =>
                sortPatientData(
                    patA[1],
                    patB[1],
                    scope.props.sortCategory,
                    scope.props.sortOrder
                )
            )
            .forEach((entry) => {
                orderedPatientSet[entry[0]] = entry[1];
            });

        return this.createListComponents(orderedPatientSet);
    }

    getListContent() {
        if (!this.props.loadComplete) {
            return (
                <div className={"loading-list-box"}>
                    <SpinLoader fgColor={AVENDA_COMPONENT_COLORS.BLUE} />
                </div>
            );
        }

        if (
            this.props.patientData == null ||
            (this.props.loadComplete && this.props.patientData.length < 1)
        ) {
            return (
                <div className={"empty-list-item"}>
                    <p className={"display-18"}>{this.props.noItemsText}</p>
                </div>
            );
        }

        return this.parsePatientHistoryList(this.props.patientData);
    }

    render() {
        let listContent = this.getListContent();
        return (
            <div className={"patient-list"} style={this.props.style}>
                {listContent}
            </div>
        );
    }
}

PatientList.propTypes = {
    style: PropTypes.object,
    //Sort Category for patients
    sortCategory: PropTypes.number,
    //Sort order for Category
    sortOrder: PropTypes.bool,
    patientData: PropTypes.array,
    loadComplete: PropTypes.bool,
    toggleCloseAll: PropTypes.bool,
    setDownloadProperties: PropTypes.func,
    noItemsText: PropTypes.string,
    checkNotification: PropTypes.func,
};

PatientList.defaultProps = {
    sortCategory: sortCategories.DATE,
    sortOrder: ASCENDING,
    patientsFetchedData: [],
    toggleCloseAll: false,
};

const mapDispatchToProps = function (dispatch) {
    return {
        setDownloadProperties: (selectOpen, patID, planID) =>
            dispatch(setDownloadProperties(selectOpen, patID, planID)),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(PatientList));

const regColor = { backgroundColor: "#FFF" };
const offColor = { backgroundColor: "#F8F9FA" };

const parseMiddleName = (mn) => (mn && mn.length > 0 ? mn : EMPTY_STRING);
