import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.css";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import PropTypes from "prop-types";
import WarningTriangle from "../../__shared__/IconComponents/WarningTriangle";
import { EMPTY_STRING } from "../../../constants";

class TreatmentPlanInfoMiniCard extends Component {
    render() {
        let plan = this.props.currentTreatmentPlan;
        if (!plan) {
            return;
        }

        let numTargets = 0;
        if (TreatmentPlan.hasTargets(plan.TreatmentPlan)) {
            numTargets = TreatmentPlan.getTargets(plan.TreatmentPlan).length;
        }

        let lesionCoverage = plan.SavedData
            ? plan.SavedData.AblationCoverageOfLesion
            : 0.0;
        lesionCoverage = (100 * lesionCoverage).toFixed(1);

        let ablationProfileName =
            plan.SavedData && plan.SavedData.AblationProfileName
                ? plan.SavedData.AblationProfileName
                : "N/A";

        return (
            <div className={"tpinfo-mini-card"} style={this.props.style}>
                <div className={"tpinfo-mini-header"}>
                    <p className={"display-26"} style={{ color: "#7C7C7C" }}>
                        Intervention Plan Information
                    </p>
                </div>
                <div className={"tpinfo-mini-table-1"}>
                    <table className={"tpinfo-mini-col"}>
                        <tbody>
                            <tr>
                                <td className={"tpinfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        Num. Tool Sites
                                    </p>
                                </td>
                                <td className={"tpinfo-mini-right-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {numTargets}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={"tpinfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        Cancer Lesion Coverage
                                    </p>
                                </td>
                                <td className={"tpinfo-mini-right-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {lesionCoverage}%
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td className={"tpinfo-mini-left-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-title"
                                        }
                                    >
                                        Tool Profile
                                    </p>
                                </td>
                                <td className={"tpinfo-mini-right-item"}>
                                    <p
                                        className={
                                            "display-14 summary-panel-field-data pendo-ignore"
                                        }
                                    >
                                        {ablationProfileName}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {this.props.cemWarningMsg && (
                    <div className="tpinfo-mini-warning display-14">
                        <WarningTriangle
                            width="21"
                            height="19"
                            className="tpinfo-mini-warning-icon"
                        />
                        Warning: {this.props.cemWarningMsg}
                    </div>
                )}
            </div>
        );
    }
}

TreatmentPlanInfoMiniCard.propTypes = {
    currentTreatmentPlan: PropTypes.object,
    style: PropTypes.object,
    cemWarningMsg: PropTypes.string,
};

TreatmentPlanInfoMiniCard.defaultProps = {
    currentTreatmentPlan: {},
    cemWarningMsg: EMPTY_STRING,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function () {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TreatmentPlanInfoMiniCard);
