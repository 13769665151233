import React, { Component } from "react";
import "./styles.css";
import AHTextAreaField from "../../__shared__/StyleGuide/AHTextAreaField";
import { reportBug } from "../../../helpers/backend_api";
import {
    EMPTY_STRING,
    MAX_REPORT_BUG_PARAGRAPH_LENGTH,
    submitBugsButtonText,
    SUCCESS,
} from "../../../constants";
import { SUBJECT_MENU_ITEMS } from "../../CreateAblationProfile/constants";
import AHDropdownItem from "../../__shared__/StyleGuide/AHDropdownItem";
import AHDropdown from "../../__shared__/StyleGuide/AHDropdown";
import PropTypes from "prop-types";

class ReportBugs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: SUBJECT_MENU_ITEMS.DEFAULT,
            body: "",
            bodyLength: 0,
            overflow: false,
            reportState: submitBugsButtonText.STANDBY,
        };
        this.chooseCategory = this.chooseCategory.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.allowSubmission = this.allowSubmission.bind(this);
    }

    onChange = (value) => {
        let trimmedValue = value.trim();
        this.setState({
            body: value,
            bodyLength: trimmedValue.length,
            overflow: trimmedValue.length > MAX_REPORT_BUG_PARAGRAPH_LENGTH,
        });
    };

    chooseCategory(e) {
        this.setState({ subject: e });
    }

    allowSubmission() {
        return (
            this.state.bodyLength > 0 &&
            this.state.bodyLength <= MAX_REPORT_BUG_PARAGRAPH_LENGTH
        );
    }

    handleSubmit() {
        reportBug({
            user_id: this.props.uuid,
            email: this.props.email,
            subject: this.state.subject,
            body: this.state.body,
        }).then((response) => {
            if (response.message === SUCCESS) {
                this.setState({
                    body: EMPTY_STRING,
                    subject: SUBJECT_MENU_ITEMS.DEFAULT,
                    reportState: submitBugsButtonText.SUCCESS,
                });
            } else {
                this.setState({
                    body: EMPTY_STRING,
                    subject: SUBJECT_MENU_ITEMS.DEFAULT,
                    reportState: submitBugsButtonText.FAILURE,
                });
            }
        });
    }

    render() {
        return (
            <div id="report-bug-container">
                <div id="report-bug-child-container">
                    <div id="report-bug-header">Report an Issue:</div>
                    <AHDropdown
                        id="report-bug-dropdown"
                        title={this.state.subject}
                        isSecondary={false}
                        onSelect={this.chooseCategory}
                    >
                        <AHDropdownItem eventKey={SUBJECT_MENU_ITEMS.OPTION_1}>
                            {SUBJECT_MENU_ITEMS.OPTION_1}
                        </AHDropdownItem>
                        <AHDropdownItem eventKey={SUBJECT_MENU_ITEMS.OPTION_2}>
                            {SUBJECT_MENU_ITEMS.OPTION_2}
                        </AHDropdownItem>
                        <AHDropdownItem eventKey={SUBJECT_MENU_ITEMS.OPTION_3}>
                            {SUBJECT_MENU_ITEMS.OPTION_3}
                        </AHDropdownItem>
                    </AHDropdown>
                    <AHTextAreaField
                        className={
                            "report-bug-text-field" +
                            (this.state.overflow ? " overflow" : "")
                        }
                        label={"bug report content"}
                        placeholder={this.state.reportState}
                        value={this.state.body}
                        onChange={this.onChange}
                    />
                    <div
                        className={
                            "report-bug-text-count" +
                            (this.state.overflow ? " overflow" : "")
                        }
                    >
                        {this.state.bodyLength} /{" "}
                        {MAX_REPORT_BUG_PARAGRAPH_LENGTH}
                    </div>
                    <button
                        id="submit-bug-btn"
                        className={
                            "report-bug-submit-button" +
                            (this.allowSubmission() ? "" : " disabled")
                        }
                        onClick={
                            this.allowSubmission()
                                ? this.handleSubmit
                                : () => {}
                        }
                        disabled={this.state.overflow}
                    >
                        Submit
                    </button>
                </div>
            </div>
        );
    }
}

ReportBugs.propTypes = {
    uuid: PropTypes.string,
    email: PropTypes.string,
};

ReportBugs.defaultProps = {
    uuid: EMPTY_STRING,
    email: EMPTY_STRING,
};

export default ReportBugs;
