import React from "react";
import "./styles.css";
import ListToggle from "../../__shared__/ListToggle";
import {
    CONTAINER_HEIGHT,
    LIST_ITEM_HEIGHT,
    sortCategories,
    buttonNames,
    EMPTY_STRING,
} from "../constants";
import { ASCENDING } from "../../../constants";
import PatientPlansSubListItem from "../PatientPlansSubListItem";
import { sortPlanData } from "../helpers";
import PropTypes from "prop-types";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import { getFreehandWorkflowAdjustedStep } from "../../../helpers/helpers";
import AHButton from "../../__shared__/StyleGuide/AHButton";
import { acquirePlanLock } from "../../../helpers/backend_api";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Else, If, Then } from "react-if";

const TOGGLE_THRESHOLD = 5; //Number of plans required to display sorting toggles
const REG_COLOR = { backgroundColor: "#FFF" };
const OFF_COLOR = { backgroundColor: "#F8F9FA" };
const VISIBLE_STYLE = { display: "block" };
const HIDDEN_STYLE = { display: "none" };

class PatientPlansSubList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentSortOrder: ASCENDING,
            currentSortCategory: sortCategories.NONE,
        };
        this.handleToggle = this.handleToggle.bind(this);
        this.makePlanList = this.makePlanList.bind(this);
        this.acquirePlanLock = this.acquirePlanLock.bind(this);
    }

    sortPlanList() {
        let category = this.state.currentSortCategory;
        let order = this.state.currentSortOrder;

        return this.props.plansData.sort(function (planA, planB) {
            return sortPlanData(planA, planB, category, order);
        });
    }

    acquirePlanLock() {
        const acquirePlanLockRequest = {
            planuuid: this.props.plansData[0].planUuid,
            useruuid: this.props.userData.uuid,
            token: this.props.authToken,
        };

        acquirePlanLock(acquirePlanLockRequest);
    }

    makePlanList() {
        let sortedPlanData = this.sortPlanList();
        let plans = [];
        let colorShift = false;
        sortedPlanData.forEach((plan, idx) => {
            let inProgress = !plan.SavedData.PlanCreationCompleteFlag;
            let btnId = `patient-button-${this.props.listItemId}-${idx}`;
            let isFreehandPlan = TreatmentPlan.isMRIOnly(plan);
            let finalPlanStep = isFreehandPlan
                ? getFreehandWorkflowAdjustedStep(
                      plan.SavedData.PlanCreationStep
                  )
                : plan.SavedData.PlanCreationStep;

            const isLocked = this.props.isLocked === true;
            const btnTxt = inProgress ? buttonNames.RESUME : buttonNames.REVIEW;

            let btn = (
                <If condition={btnTxt === buttonNames.RESUME && isLocked}>
                    <Then>
                        <OverlayTrigger
                            placement="right"
                            overlay={this.props.tooltipMsg}
                        >
                            <div>
                                <AHButton
                                    id={btnId}
                                    className={"left"}
                                    size={"large"}
                                    text={btnTxt}
                                    isDisabled={true}
                                />
                            </div>
                        </OverlayTrigger>
                    </Then>
                    <Else>
                        <AHButton
                            id={btnId}
                            className={"left"}
                            size={"large"}
                            onClick={(event) => {
                                this.props.checkNotification(event, plan);
                                this.acquirePlanLock();
                            }}
                            text={btnTxt}
                        />
                    </Else>
                </If>
            );

            let listItem = (
                <PatientPlansSubListItem
                    key={`plan-${idx}-${plan.planUuid}`}
                    keyId={idx}
                    patientUuid={plan.patientUuid}
                    planUuid={plan.planUuid}
                    date={plan.creationDate}
                    clickCallback={this.props.clickCallback}
                    style={colorShift ? REG_COLOR : OFF_COLOR}
                    finalStep={finalPlanStep}
                    isFreehandPlan={isFreehandPlan}
                    inProgress={inProgress}
                    button={btn}
                />
            );

            colorShift = !colorShift;
            plans.push(listItem);
        });

        return plans;
    }

    handleToggle(sortCategory) {
        if (sortCategory === this.state.currentSortCategory) {
            this.setState({ currentSortOrder: !this.state.currentSortOrder });
        } else {
            this.setState({
                currentSortOrder: ASCENDING,
                currentSortCategory: sortCategory,
            });
        }
    }

    render() {
        let plans = this.makePlanList();
        let sortingVisibility =
            plans.length >= TOGGLE_THRESHOLD ? VISIBLE_STYLE : HIDDEN_STYLE;
        let containerHeight =
            plans.length >= TOGGLE_THRESHOLD
                ? CONTAINER_HEIGHT
                : plans.length * LIST_ITEM_HEIGHT;

        return (
            <div>
                <div
                    className={"plan-sub-list-container"}
                    style={{ height: containerHeight + "px" }}
                >
                    <div
                        className={"patient-plans-toggles"}
                        style={sortingVisibility}
                    >
                        <ListToggle
                            id={"plans-history-date-toggle"}
                            text={"Date"}
                            callback={() =>
                                this.handleToggle(sortCategories.DATE)
                            }
                            textStyle={{ lineHeight: "74px" }}
                            order={this.state.currentSortOrder}
                            selected={
                                this.state.currentSortCategory ===
                                sortCategories.DATE
                            }
                        />
                        <ListToggle
                            id={"plans-history-id-toggle"}
                            text={"ID"}
                            callback={() =>
                                this.handleToggle(sortCategories.ID)
                            }
                            textStyle={{ lineHeight: "74px" }}
                            order={this.state.currentSortOrder}
                            selected={
                                this.state.currentSortCategory ===
                                sortCategories.ID
                            }
                        />
                    </div>
                    <div className={"plan-sub-list"}>{plans}</div>
                </div>
            </div>
        );
    }
}

PatientPlansSubList.propTypes = {
    //Data for all plans associated with a single patient
    plansData: PropTypes.arrayOf(PropTypes.object),
    // Function to callback from plan Download Icon click
    clickCallback: PropTypes.func,
    listItemId: PropTypes.string,
    checkNotification: PropTypes.func,
    userData: PropTypes.object,
    authToken: PropTypes.string,
    isLocked: PropTypes.bool,
    tooltipMsg: PropTypes.object,
};

PatientPlansSubList.defaultProps = {
    plansData: [],
    userData: {},
    authToken: EMPTY_STRING,
};

export default PatientPlansSubList;
