import React from "react";
import "./styles.css";
import downChevron from "../../../assets/down_chevron_white.svg";
import PropTypes from "prop-types";

class FilterMRIModalItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        this.props.onClick();
        event.stopPropagation();
        return true;
    }

    render() {
        let rotation = this.props.mriOptionsVisible ? "up" : "down";

        return (
            <div
                id={this.props.id}
                className={"filter-mri-modal-item"}
                ref={this.setWrapperRef}
                onClick={this.handleClick}
            >
                <img
                    src={downChevron}
                    className={`filter-mri-modal-chevron-${rotation}`}
                    width="24px"
                    data-rotate={rotation}
                    height="24px"
                    alt={"Check Box"}
                    onClick={this.handleClick}
                />
                <p className={"display-16"}>MRI Options</p>
            </div>
        );
    }
}

FilterMRIModalItem.propTypes = {
    mriOptionsVisible: PropTypes.bool,
    onClick: PropTypes.func,
    id: PropTypes.string,
};

FilterMRIModalItem.defaultProps = {
    mriOptionsVisible: false,
    onClick: () => {},
};

export default FilterMRIModalItem;
