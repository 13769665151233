import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../../constants";
import { capitalizeFirstLetter } from "../../../../helpers/helpers";

export default class NavCubeButton extends Component {
    render() {
        let selected = this.props.isCurrentOrientation ? "selected" : "";

        return (
            <div
                className={`nav-cube-modal-button ${this.props.orientation} ${selected}`}
                onClick={() => {
                    if (!this.props.isCurrentOrientation) {
                        this.props.handleClick(this.props.orientation);
                    }
                }}
            >
                <img src={this.props.img} alt={this.props.orientation} />
                <p className={"display-16"}>
                    {capitalizeFirstLetter(this.props.orientation)}
                </p>
            </div>
        );
    }
}

NavCubeButton.propTypes = {
    isCurrentOrientation: PropTypes.bool,
    orientation: PropTypes.string,
    handleClick: PropTypes.func,
    img: PropTypes.string,
};

NavCubeButton.defaultProps = {
    isCurrentOrientation: false,
    orientation: EMPTY_STRING,
    img: EMPTY_STRING,
};
