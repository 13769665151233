import * as THREE from "three";
import StructureModel from "./StructureModel";
import {
    refFrames,
    structureColorPalette,
    volumeNames,
} from "../../../../../constants";
import { GENERIC_MATERIAL } from "../../MeshViewer/materials";
import { makeMRIViewerMesh } from "../../MRIViewer/helpers";

export default class BiopsyCoreModel extends StructureModel {
    constructor() {
        super();
        this._metadata = null;
    }

    update(metadata) {
        this._metadata = metadata;
        this._color = this._getColor(metadata);
        if (this._selfMeshView) {
            this._selfMeshView.material.color = new THREE.Color(this._color);
        }
        if (this._selfMRIView) {
            this._selfMRIView.materialFront.color = new THREE.Color(
                this._color
            );
        }
    }
    get gleasonGradeGroup() {
        return this._metadata.GleasonGradeGroup;
    }

    initializeView(core) {
        this._metadata = core;
        this._createMeshView(core);
        this._createMRIView(core);
    }

    get metadata() {
        return this._metadata;
    }

    get visibility() {
        return this._visible;
    }

    set visibility(visibility) {
        this._visible = visibility;

        if (this._selfMRIView) {
            this._selfMRIView.meshFront.material.visible = visibility;
            this._selfMRIView.meshBack.material.visible = visibility;
        }

        if (this._selfMeshView) {
            this._selfMeshView.visible = visibility;
        }
    }

    _createMRIView(core) {
        if (!this._mriViewer) {
            return;
        }

        let tip = core.Frame[refFrames.MRI].CorTip;
        let bot = core.Frame[refFrames.MRI].CorBot;

        let tipPt = new THREE.Vector3(tip.X, tip.Y, tip.Z);
        let botPt = new THREE.Vector3(bot.X, bot.Y, bot.Z);

        let geoAttr = this._computeCoreGeometricAttributes(tipPt, botPt);
        this._color = this._getColor(core);

        let meshObject = makeMRIViewerMesh({
            name: volumeNames.MR_BIOPSY + String(core.ID),
            geometry: geoAttr.geometry,
            frontOpacity: 0,
            backOpacity: 1.0,
            color: this._color,
            quaternion: geoAttr.quaternion,
            position: new THREE.Vector3(tipPt.x, tipPt.y, tipPt.z),
        });

        this._selfMRIView = meshObject;
        this._mriViewer.RASframe.add(meshObject.scene);

        if (this._mriViewer.meshObjects) {
            this._mriViewer.meshObjects.push(meshObject);
        } else {
            this._mriViewer.meshObjects = [];
        }
    }

    _createMeshView(core) {
        if (!this._meshViewer) {
            return;
        }

        let tip = core.Frame[refFrames.MRI].CorTip;
        let bot = core.Frame[refFrames.MRI].CorBot;

        let tipPt = new THREE.Vector3(tip.X, tip.Y, tip.Z);
        let botPt = new THREE.Vector3(bot.X, bot.Y, bot.Z);

        let geoAttr = this._computeCoreGeometricAttributes(tipPt, botPt);
        let color = this._getColor(core);

        let material = GENERIC_MATERIAL.clone();
        material.color = new THREE.Color(color);
        material.opacity = 1.0;
        material.transparent = false;

        let cylinder;
        cylinder = new THREE.Mesh(geoAttr.geometry, material);
        cylinder.applyQuaternion(geoAttr.quaternion);
        cylinder.position.set(tipPt.x, tipPt.y, tipPt.z);
        cylinder.name = volumeNames.MR_BIOPSY + String(core.ID);

        this._selfMeshView = cylinder;
        this._meshViewer.RASframe.add(cylinder);
    }

    _computeCoreGeometricAttributes(p1, p2) {
        let dif = new THREE.Vector3().subVectors(p1, p2);
        let len = dif.length();
        dif.normalize();

        let geometry = new THREE.CylinderGeometry(0.5, 0.5, len, 32);
        geometry.translate(0, -len / 2, 0);

        let quaternion = new THREE.Quaternion();
        quaternion.setFromUnitVectors(new THREE.Vector3(0, 1, 0), dif);

        return {
            geometry: geometry,
            quaternion: quaternion,
        };
    }

    _getColor(core) {
        let color;
        if (core.IsMuted) {
            color = structureColorPalette.MUTED;
        } else if (core.GleasonGradeGroup === 0) {
            color = structureColorPalette.BX_NEGATIVE;
        } else if (core.GleasonGradeGroup === 1) {
            color = structureColorPalette.BX_CANCER;
        } else {
            color = structureColorPalette.BX_CS_CANCER;
        }

        return color;
    }
}
