import * as helpers from "../../helpers";
import { EMPTY_STRING, specialSelection } from "../../../../../constants";
import { mouseEventName } from "../../MeshViewer/consts";

export default class PatientAnatomyController {
    constructor(pamModel, targetSetModel) {
        this._pamModel = pamModel;
        this._targetSetModel = targetSetModel;
        this._allInteractableModels = [];
        this._allHighlightableModels = [];
        this._mouseControlsEnabled = true;
    }

    updateBiopsyCore(idx, core) {
        let bxCores = this._pamModel.bxCores;
        if (idx < bxCores.length - 1) {
            let bxCore = bxCores[parseInt(idx)];
            bxCore.update(core);
        }
    }
    get mouseControlsEnabled() {
        return this._mouseControlsEnabled;
    }

    set mouseControlsEnabled(isOn) {
        this._mouseControlsEnabled = isOn;
    }

    get mriViewer() {
        return this._mriViewer;
    }

    set mriViewer(mriViewer) {
        this._mriViewer = mriViewer;
    }

    get meshViewer() {
        return this._meshViewer;
    }

    set meshViewer(meshViewer) {
        this._meshViewer = meshViewer;
    }

    get hoveredBxCoreTextElement() {
        return this._hoveredBxCoreTextElement;
    }

    set hoveredBxCoreTextElement(node) {
        this._hoveredBxCoreTextElement = node;
    }

    update() {
        this._updateInteractableModels();
        this._updateHighlightableModels();
    }

    _updateInteractableModels() {
        this._allInteractableModels = [];
        this._allInteractableModels.push(this._pamModel.prostate);
        this._allInteractableModels = this._allInteractableModels.concat(
            this._pamModel.rois
        );
        this._allInteractableModels = this._allInteractableModels.concat(
            this._pamModel.margins
        );
        this._allInteractableModels = this._allInteractableModels.concat(
            this._pamModel.bxCores
        );
        if (this._targetSetModel) {
            this._allInteractableModels = this._allInteractableModels.concat(
                this._targetSetModel.targets
            );
        }
    }

    _updateHighlightableModels() {
        let models = [];

        models = this._pamModel.bxCores
            ? models.concat(this._pamModel.bxCores)
            : models;

        models = this._pamModel.rois
            ? models.concat(this._pamModel.rois)
            : models;

        if (this._targetSetModel) {
            const targets = Array.from(this._targetSetModel.targets.values());
            models = targets ? models.concat(targets) : models;
        }

        this._allHighlightableModels = models;
    }

    outlineWithRaycaster(raycaster, mouseEvent) {
        document.body.style.cursor = "default";

        if (!this._mouseControlsEnabled) {
            return;
        }

        if (!this._meshViewer) {
            return;
        }

        const intersections = helpers.getRaycasterIntersections(
            raycaster,
            this._allHighlightableModels
        );

        const selectedModel = getFirstVisibleModel(
            intersections,
            this._pamModel
        );

        if (selectedModel) {
            document.body.style.cursor = "pointer";
        }

        if (mouseEvent === mouseEventName.CLICK) {
            if (selectedModel) {
                if (this._pamModel.isModelBxCore(selectedModel.model)) {
                    if (
                        this._meshViewer.props.isCoreFocused &&
                        this._meshViewer.props.activeCore ===
                            selectedModel.model.metadata.coreIndex
                    ) {
                        this._meshViewer.props.setIsCoreFocused(false);
                        this._meshViewer.props.setActiveCore(
                            specialSelection.NONE_SELECTED
                        );
                    } else {
                        this._meshViewer.props.setIsCoreFocused(true);
                        this._meshViewer.outlinePass.selectedObjects = [
                            selectedModel.intersection,
                        ];
                        if (this._pamModel.isModelBxCore(selectedModel.model)) {
                            this._meshViewer.props.setActiveCore(
                                selectedModel.model.metadata.coreIndex
                            );
                        }
                        this.updateHoveredText(
                            selectedModel,
                            this._hoveredBxCoreTextElement
                        );
                    }
                }
            }
        }

        if (mouseEvent === mouseEventName.HOVER) {
            if (this._meshViewer.props.isCoreFocused) {
                return;
            }
            this._meshViewer.props.setActiveCore(
                specialSelection.NONE_SELECTED
            );

            if (selectedModel) {
                this._meshViewer.outlinePass.selectedObjects = [
                    selectedModel.intersection,
                ];
                if (this._pamModel.isModelBxCore(selectedModel.model)) {
                    this._meshViewer.props.setActiveCore(
                        selectedModel.model.metadata.coreIndex
                    );
                }
                this.updateHoveredText(
                    selectedModel,
                    this._hoveredBxCoreTextElement
                );
            } else {
                this._meshViewer.outlinePass.selectedObjects = [];
                this._hoveredBxCoreTextElement.innerText = EMPTY_STRING;
            }
        }
    }

    outlineActiveCore(coreIndex) {
        if (this._pamModel && this._pamModel.bxCores) {
            if (coreIndex === specialSelection.NONE_SELECTED) {
                this._meshViewer.outlinePass.selectedObjects = [];
                this._hoveredBxCoreTextElement.innerText = EMPTY_STRING;
            } else {
                let core = this._pamModel.bxCores[coreIndex];
                this._meshViewer.outlinePass.selectedObjects = [
                    core._selfMeshView,
                ];
                this.updateHoveredText(
                    { model: core },
                    this._hoveredBxCoreTextElement
                );
            }
        }
    }

    updateHoveredText(selectedModel, bxCoreTextElement) {
        if (!selectedModel) {
            bxCoreTextElement.innerText = EMPTY_STRING;
            return;
        }

        const model = selectedModel.model;

        let text = EMPTY_STRING;

        if (this._targetSetModel.isModelTarget(model)) {
            text = ``; // `Tool Site ID: ${model.ID}`; TODO - 1 indexed in list, 0 indexed in ID. Would be confusing.
        } else if (this._pamModel.isModelBxCore(model)) {
            const metadata = model.metadata;
            text = `Biopsy ID: ${metadata.ID}\n
            Systematic Template Location: ${metadata.Frame.MRI.TemplateLocation}\n
            Gleason Grade Group: ${metadata.GleasonGradeGroup}\n
            Tissue Length: ${metadata.TissueLength}mm\n
            Cancer Length: ${metadata.CancerLength}mm`;
        } else if (this._pamModel.isModelROI(model)) {
            const metadata = model.metadata;
            text = `ROI ID: ${metadata.Name}\n
            Volume: ${metadata.VolumeCC.toFixed(2)}cc`;
        }

        bxCoreTextElement.innerText = text;
    }
}

function getFirstVisibleModel(intersections, pamModel) {
    for (let idx = 0; idx < intersections.length; idx++) {
        const intersect = intersections[parseInt(idx)];
        const candidateModel =
            intersect.object && pamModel.lookupModelWithView(intersect.object);
        if (candidateModel && candidateModel.visibility) {
            return { model: candidateModel, intersection: intersect.object };
        }
    }

    return null;
}
