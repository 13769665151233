import React from "react";
import "./styles.css";
import {
    EMPTY_STRING,
    TOTAL_DEFAULT_STEPS,
    TOTAL_FREEHAND_STEPS,
} from "../../../constants";
import PropTypes from "prop-types";
import AHButton from "../../__shared__/StyleGuide/AHButton";

class PatientPlansSubListItem extends React.PureComponent {
    render() {
        let inProgColor = this.props.inProgress
            ? "in-progress-list-item"
            : "completed-list-item";
        let downloadDisplay = this.props.inProgress
            ? { display: "none" }
            : { display: "auto" };
        let totalPlanSteps = this.props.isFreehandPlan
            ? TOTAL_FREEHAND_STEPS
            : TOTAL_DEFAULT_STEPS;
        let btnDownloadId = `patient-button-download-${this.props.keyId}`;

        return (
            <div className={"plan-sub-list-item"} style={this.props.style}>
                <div className={"plan-date-box"}>
                    <p className={`display-22 ${inProgColor}`}>
                        Plan Date:&nbsp;{this.props.date}
                    </p>
                </div>
                <div className={"plan-id-box"}>
                    <p className={`display-22 ${inProgColor}`}>
                        Step:&nbsp;{this.props.finalStep + 1}/
                        {totalPlanSteps.toString()}
                    </p>
                </div>
                <div className={"patient-history-button-wrapper"}>
                    {this.props.button}

                    <AHButton
                        id={btnDownloadId}
                        className={"right left-margin"}
                        size={"large"}
                        style={downloadDisplay}
                        onClick={() =>
                            this.props.clickCallback(
                                this.props.patientUuid,
                                this.props.planUuid
                            )
                        }
                        text={"Download Plan"}
                    />
                </div>
            </div>
        );
    }
}

PatientPlansSubListItem.propTypes = {
    keyId: PropTypes.number,
    date: PropTypes.string,
    patientUuid: PropTypes.string,
    planUuid: PropTypes.string,
    clickCallback: PropTypes.func,
    inProgress: PropTypes.bool,
    style: PropTypes.object,
    button: PropTypes.any,
    finalStep: PropTypes.number,
    isFreehandPlan: PropTypes.bool,
};

PatientPlansSubListItem.defaultProps = {
    date: EMPTY_STRING,
    patientUuid: EMPTY_STRING,
    planUuid: EMPTY_STRING,
    clickCallback: null,
    inProgress: false,
    finalStep: 0,
};

export default PatientPlansSubListItem;
