import * as THREE from "three";
import StructureModel from "../PatientAnatomy/StructureModel";
import { GENERIC_MATERIAL } from "../../MeshViewer/materials";
import { makeMRIViewerMesh } from "../../MRIViewer/helpers";
import {
    computeRotationMatrix,
    r4FromR3,
} from "../../../../../helpers/threejs_helpers";
import { structureColorPalette, volumeNames } from "../../../../../constants";

const downYVec = new THREE.Vector3(0, -1, 0);

export default class BoundingCone extends StructureModel {
    get radius() {
        return this._radius;
    }

    get height() {
        return this._height;
    }

    get vertex() {
        return this._vertex;
    }

    get theta() {
        return this._theta;
    }

    get direction() {
        return this._direction;
    }

    initializeView(cone) {
        this._radius = cone.radius;
        this._height = cone.height;
        this._vertex = new THREE.Vector3(
            cone.vertex.X,
            cone.vertex.Y,
            cone.vertex.Z
        );

        this._theta = cone.theta;
        this._direction = new THREE.Vector3(
            cone.direction.X,
            cone.direction.Y,
            cone.direction.Z
        );

        this._r4 = r4FromR3(computeRotationMatrix(this._direction, downYVec));

        let coneGeometry = new THREE.ConeGeometry(
            this._radius,
            this._height,
            32
        );

        this._createMeshView(coneGeometry);
        this._createMRIView(coneGeometry);
    }

    _createMRIView(coneGeometry) {
        let meshObject = makeMRIViewerMesh({
            name: volumeNames.BOUNDING_CONE,
            geometry: coneGeometry,
            frontOpacity: 0,
            backOpacity: 1.0,
            visible: false,
            color: structureColorPalette.BX_CS_CANCER,
            rotation: this._r4,
            translation: {
                axis: downYVec,
                distance: this._height / 2,
            },
            position: this._vertex,
        });

        this._selfMRIView = meshObject;
        this._mriViewer.RASframe.add(meshObject.scene);
        this._mriViewer.meshObjects.push(meshObject);
    }

    _createMeshView(coneGeometry) {
        let coneObj = new THREE.Mesh(coneGeometry, GENERIC_MATERIAL);

        coneObj.name = volumeNames.BOUNDING_CONE;
        coneObj.position.set(this._vertex.x, this._vertex.y, this._vertex.z);

        coneObj.setRotationFromMatrix(this._r4);
        coneObj.translateOnAxis(downYVec, this._height / 2);

        this._selfMeshView = coneObj;
        this._meshViewer.RASframe.add(coneObj);
    }
}
