import React from "react";
import PropTypes from "prop-types";

const DownArrow = ({ className, id, onClick, stroke }) => {
    const getStrokeColor = () =>
        getComputedStyle(document.body).getPropertyValue(
            "--disabledButtonGray"
        );

    const strokeColor = stroke || getStrokeColor();

    return (
        <button
            style={{
                border: "none",
                background: "none",
                margin: "0",
                outline: "none",
            }}
            className={className}
            id={id}
            onClick={onClick}
        >
            <svg
                width="18px"
                height="11px"
                viewBox="0 0 18 11"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                >
                    <g
                        transform="translate(-789.000000, -991.000000)"
                        stroke={strokeColor}
                        strokeWidth="2"
                    >
                        <g transform="translate(634.000000, 986.000000)">
                            <polyline points="156 6 164 14 172 6" />
                        </g>
                    </g>
                </g>
            </svg>
        </button>
    );
};

DownArrow.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func,
    stroke: PropTypes.string,
};

export default DownArrow;
