import {
    SET_FORM_ERROR_STATE,
    SET_SIGNUP_FLOW_STATE,
    UPDATE_FORM,
} from "./actions";
import { initialState } from "./init";

export default SignupReducer;

function SignupReducer(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_FORM:
            return updateForm(state, action);
        case SET_FORM_ERROR_STATE:
            return setFormErrorState(state, action);
        case SET_SIGNUP_FLOW_STATE:
            return setSignUpFlowState(state, action);
        default:
            return state;
    }
}

function updateForm(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.form[action.formData.fieldName] = action.formData.value;
    return stateCpy;
}

function setFormErrorState(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.error.isInvalid = action.error.isInvalid;
    stateCpy.error.msg = action.error.msg;
    return stateCpy;
}

function setSignUpFlowState(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.flowState = action.state;
    return stateCpy;
}
