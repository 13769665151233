import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import {
    setDownloadProperties,
    setDownloadSelectOpen,
} from "../../Home/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { clientErrorMessages, EMPTY_STRING } from "../../../constants";
import { rootStore } from "../../../redux/store";
import { setErrorState } from "../../../redux/error_banner/actions";
import xbtn from "../../../assets/close_x.svg";
import { getTreatmentPlan } from "../../CreateTPlan_1Upload/helpers";
import {
    MODAL_STATES,
    NAV_PLAN_OPTIONS,
    DOWNLOAD_CARD_TITLE,
    DOWNLOAD_CARD_SUBTITLE,
    FILE_TYPE_HEADER,
} from "./constants";
import { DownloadGroupListItem } from "./DownloadGroupListItem";
import { If } from "react-if";
import { ReactComponent as CheckIcon } from "../../../assets/check.svg";
import { ReactComponent as XIcon } from "../../../assets/x_icon.svg";
import { ReactComponent as FileIcon } from "../../../assets/file_icon.svg";
import SpinLoader from "../SpinLoader";

class DownloadSelectCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalState: MODAL_STATES.INITIAL,
            currentTreatmentPlan: {},
            latestDownloadedFileType: EMPTY_STRING,
            latestDownloadedFileName: EMPTY_STRING,
        };

        this.handleClose = this.handleClose.bind(this);
        this.setModalState = this.setModalState.bind(this);
        this.setLatestDownloadedFileInfo =
            this.setLatestDownloadedFileInfo.bind(this);
        this.getDescriptionContents = this.getDescriptionContents.bind(this);
        this.fileTypes = this.fileTypes.bind(this);
    }

    componentDidMount() {
        let scope = this;
        /*
        Not super happy with copying to local state here, but letting a passed in
        treatment plan short circuit the GET call avoids the race condition when this
        component is mounted on the review screen while DynamoDB is in an inconsistent state
         */
        if (this.props.currentTreatmentPlan) {
            this.setState({
                currentTreatmentPlan: this.props.currentTreatmentPlan,
            });
        } else {
            getTreatmentPlan(
                this.props.authToken,
                this.props.patientuuid,
                this.props.planuuid
            )
                .then((payload) => payload.json())
                .then((json) => {
                    scope.setState({
                        currentTreatmentPlan: json.payload.plan,
                    });
                })
                .catch(() => {
                    rootStore.dispatch(
                        setErrorState(
                            true,
                            clientErrorMessages.TPLAN_RETRIEVE_FAILED
                        )
                    );
                });
        }
    }

    handleClose() {
        if (this.state.modalState === MODAL_STATES.DOWNLOADING) {
            return;
        }
        let selectOpen = false;
        let patientUuid = EMPTY_STRING;
        let planUuid = EMPTY_STRING;
        this.props.setDownloadProperties(selectOpen, patientUuid, planUuid);
    }

    componentWillUnmount() {
        this.handleClose();
    }

    setModalState(modalState) {
        this.setState({ modalState: modalState });
    }

    setLatestDownloadedFileInfo(fileType, fileName) {
        this.setState({
            latestDownloadedFileType: fileType,
            latestDownloadedFileName: fileName,
        });
    }

    getDescriptionContents() {
        return (
            <div>
                <If condition={this.state.modalState === MODAL_STATES.FINISHED}>
                    <div>
                        <div
                            style={{
                                marginBottom: "10px",
                            }}
                        >
                            {this.state.latestDownloadedFileType}{" "}
                            {DOWNLOAD_CARD_SUBTITLE.DOWNLOAD_COMPLETE}
                        </div>
                        <div
                            className={"download-file-name"}
                            style={{
                                marginBottom: "10px",
                            }}
                        >
                            <CheckIcon className={"check-icon"} />{" "}
                            {this.state.latestDownloadedFileName}
                        </div>
                    </div>
                </If>
                <If condition={this.state.modalState === MODAL_STATES.ERROR}>
                    <div
                        className={"download-file-type-error"}
                        style={{
                            marginBottom: "20px",
                        }}
                    >
                        <XIcon className={"x-icon"} />{" "}
                        {this.state.latestDownloadedFileType}{" "}
                        {DOWNLOAD_CARD_SUBTITLE.ERROR}
                    </div>
                </If>
                <div
                    style={{
                        marginBottom: "10px",
                    }}
                >
                    {DOWNLOAD_CARD_SUBTITLE.SELECT_ANOTHER_FORMAT}
                </div>
            </div>
        );
    }

    fileTypes() {
        return Object.entries(NAV_PLAN_OPTIONS).map(([key, value]) => {
            if (value === EMPTY_STRING) {
                return null;
            }
            return (
                <DownloadGroupListItem
                    key={key}
                    fileKey={key}
                    fileType={value}
                    useruuid={this.props.useruuid}
                    patientuuid={this.props.patientuuid}
                    planuuid={this.props.planuuid}
                    authToken={this.props.authToken}
                    currentTreatmentPlan={this.state.currentTreatmentPlan}
                    userData={this.props.userData}
                    modalState={this.state.modalState}
                    setModalState={this.setModalState}
                    setLatestDownloadedFileInfo={
                        this.setLatestDownloadedFileInfo
                    }
                />
            );
        });
    }

    render() {
        return (
            <div className={`download-select-overlay click-outside-ignore`}>
                <div id={this.props.id} className={"download-select-card"}>
                    <img
                        className={`close-dl-btn ${
                            this.state.modalState === MODAL_STATES.DOWNLOADING
                                ? "close-dl-btn-not-clickable"
                                : ""
                        }`}
                        src={xbtn}
                        alt={"Exit"}
                        onClick={this.handleClose}
                    />

                    <div className={"download-card-content"}>
                        <div className={"download-card-left-column"}>
                            <div className={"download-list-container"}>
                                <ul className={"list-group"}>
                                    <li
                                        className={
                                            "list-group-item list-group-title-item display-medium-18"
                                        }
                                    >
                                        <FileIcon className={"file-icon"} />
                                        {FILE_TYPE_HEADER}
                                    </li>
                                </ul>
                                <ul
                                    id={"download-list-file-type"}
                                    className={"list-group file-type-list"}
                                >
                                    {this.fileTypes()}
                                </ul>
                            </div>
                        </div>

                        <div className={"download-card-right-column"}>
                            <div className={"download-card-title"}>
                                <div className={"display-36 black-text"}>
                                    {DOWNLOAD_CARD_TITLE}
                                </div>
                                <hr />
                                <div
                                    className={"display-regular-18 black-text"}
                                >
                                    <If
                                        condition={
                                            this.state.modalState ===
                                            MODAL_STATES.INITIAL
                                        }
                                    >
                                        {DOWNLOAD_CARD_SUBTITLE.INITIAL}
                                    </If>
                                    <If
                                        condition={
                                            this.state.modalState ===
                                            MODAL_STATES.DOWNLOADING
                                        }
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                {
                                                    DOWNLOAD_CARD_SUBTITLE.DOWNLOADING
                                                }
                                            </div>
                                            <br />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    "justify-content": "center",
                                                }}
                                            >
                                                <SpinLoader
                                                    width={"50px"}
                                                    height={"50px"}
                                                    display={"block"}
                                                    loaderWidth={"5px"}
                                                    fgColor={getComputedStyle(
                                                        document.documentElement
                                                    ).getPropertyValue(
                                                        "--avendaTeal1"
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </If>
                                    <If
                                        condition={
                                            this.state.modalState ===
                                                MODAL_STATES.FINISHED ||
                                            this.state.modalState ===
                                                MODAL_STATES.ERROR
                                        }
                                    >
                                        {this.getDescriptionContents()}
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DownloadSelectCard.propTypes = {
    currentTreatmentPlan: PropTypes.object,
    noBtnCallback: PropTypes.func,
    yesBtnCallback: PropTypes.func,
    useruuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    authToken: PropTypes.string,
    id: PropTypes.string,
    userData: PropTypes.object,
    downloadProperties: PropTypes.object,
    setDownloadSelectOpen: PropTypes.func,
    setDownloadProperties: PropTypes.func,
    history: PropTypes.object,
};

const mapStateToProps = function (state, ownProps) {
    let patientuuid = ownProps.patientuuid
        ? ownProps.patientuuid
        : state.CreatePlanReducer.patientuuid;
    let planuuid = ownProps.planuuid
        ? ownProps.planuuid
        : state.CreatePlanReducer.planuuid;

    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        patientuuid: patientuuid,
        planuuid: planuuid,
        downloadProperties: state.HomeReducer.downloadProperties,
        userData: state.LoginReducer.userData,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setDownloadSelectOpen: (active) =>
            dispatch(setDownloadSelectOpen(active)),
        setErrorState: (hasError, errorText) =>
            dispatch(setErrorState(hasError, errorText)),
        setDownloadProperties: (selectOpen, patID, planID) =>
            dispatch(setDownloadProperties(selectOpen, patID, planID)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DownloadSelectCard)
);
