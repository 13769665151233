import {
    SET_AUTH_TOKEN,
    SET_FLAG,
    SET_PASSWORD,
    SET_USER_UUID,
    SET_USERNAME,
    HANDLE_LOGOUT,
    SET_USERDATA,
} from "./actions";
import { initialState } from "./init";
import { EMPTY_STRING, loginReducerFlags } from "../../constants";
import blankUser from "../../assets/blank_user.svg";

export default LoginReducer;

function LoginReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_USERDATA:
            return setUserData(state, action);
        case SET_FLAG:
            return setFlag(state, action);
        case SET_USERNAME:
            return setUsername(state, action);
        case SET_PASSWORD:
            return setPassword(state, action);
        case SET_AUTH_TOKEN:
            return setAuthToken(state, action);
        case SET_USER_UUID:
            return setUserUUID(state, action);
        case HANDLE_LOGOUT:
            return handleLogout(state, action);
        default:
            return state;
    }
}

function setUserData(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.userData = action.userData;
    return stateCpy;
}

function setFlag(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.flags[action.flag.flagId] = action.flag.newFlagState;
    return stateCpy;
}

function setUsername(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.unText = action.text;
    return stateCpy;
}

function setPassword(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.pwText = action.text;
    return stateCpy;
}

function setAuthToken(state, action) {
    sessionStorage.authToken = action.token;
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.authToken = action.token;
    return stateCpy;
}

function setUserUUID(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.useruuid = action.useruuid;
    return stateCpy;
}

function handleLogout(state) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    sessionStorage.authToken = EMPTY_STRING;
    stateCpy.userData = {};
    stateCpy.useruuid = EMPTY_STRING;
    stateCpy.authToken = EMPTY_STRING;
    stateCpy.flags[loginReducerFlags.IS_AUTHENTICATED] = false;
    stateCpy.unText = EMPTY_STRING;
    stateCpy.userPicture = blankUser;
    stateCpy.pwText = EMPTY_STRING;
    return stateCpy;
}
