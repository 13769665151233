import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { segmentationDisplayOptions } from "../../constants";
import SegmentationListItem from "../SegmentationListItem";
import PatientAnatomyModel from "../../../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyModel";
import MeshEditController from "../../../__shared__/Viewers/Structures/Other/MeshEditController";
import MutedNudgeText from "../../../__shared__/MutedNudgeText";
import { CREATE_SEGMENTATION_NUDGE_TEXT } from "../../../CreateTPlan_1Upload/constants";
import AHButton from "../../../__shared__/StyleGuide/AHButton";

class SegmentationListingPanel extends Component {
    constructor(props) {
        super(props);

        this.onHideClick = this.onHideClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
    }

    onHideClick(name) {
        let pam = this.props.patientAnatomyModel;

        if (pam.prostateUploaded && name === pam.prostateUploaded.name) {
            if (pam.prostateAI) {
                pam.prostateAI.visibility = pam.prostateUploaded.visibility;
            }

            pam.prostateUploaded.visibility = !pam.prostateUploaded.visibility;
        } else if (pam.prostateAI && name === pam.prostateAI.name) {
            if (pam.prostateUploaded) {
                pam.prostateUploaded.visibility = pam.prostateAI.visibility;
            }

            pam.prostateAI.visibility = !pam.prostateAI.visibility;
        }

        this.forceUpdate();
    }

    onEditClick(name) {
        let pam = this.props.patientAnatomyModel;

        if (pam.prostateUploaded && name === pam.prostateUploaded.name) {
            if (pam.prostateAI) {
                pam.prostateAI.visibility = false;
            }

            pam.prostateUploaded.visibility = true;
            this.props.meshEditController.linkedStructure =
                pam.prostateUploaded;
        } else if (pam.prostateAI && name === pam.prostateAI.name) {
            if (pam.prostateUploaded) {
                pam.prostateUploaded.visibility = false;
            }

            pam.prostateAI.visibility = true;
            this.props.meshEditController.linkedStructure = pam.prostateAI;
        }

        this.props.setWorkflowState(segmentationDisplayOptions.SEG_EDITING);
    }

    getListItems() {
        let items = [];

        let isDark = false;

        let prostateUploaded = this.props.patientAnatomyModel.prostateUploaded;
        if (prostateUploaded) {
            let prostateName = prostateUploaded.name;
            let prostateColor = prostateUploaded.color.getHexString();
            items.push(
                <SegmentationListItem
                    key={`uploaded-${prostateName}`}
                    name={prostateName}
                    text={"Prostate (User Upload)"}
                    index={0}
                    dark={isDark}
                    color={`#${prostateColor}`}
                    isEditable={true}
                    isHideable={true}
                    isHidden={!prostateUploaded.visibility}
                    onEditClick={this.onEditClick}
                    onHideClick={this.onHideClick}
                />
            );
            isDark = !isDark;
        }

        let prostateAI = this.props.patientAnatomyModel.prostateAI;
        if (prostateAI) {
            let prostateName = prostateAI.name;
            let prostateColor = prostateAI.color.getHexString();
            items.push(
                <SegmentationListItem
                    key={`ai-${prostateName}`}
                    name={prostateName}
                    text={"Prostate (AI Generated)"}
                    index={0}
                    dark={isDark}
                    color={`#${prostateColor}`}
                    isEditable={true}
                    isHideable={true}
                    isHidden={!prostateAI.visibility}
                    onEditClick={this.onEditClick}
                    onHideClick={this.onHideClick}
                />
            );
            isDark = !isDark;
        }

        let rois = this.props.patientAnatomyModel.rois;
        if (rois && rois.length > 0) {
            rois.forEach((roi, idx) => {
                if (roi) {
                    let roiName = roi.name;
                    let roiColor = roi.color.getHexString();

                    items.push(
                        <SegmentationListItem
                            key={`roi-${roiName}`}
                            text={`ROI ${1 + idx}`}
                            index={1 + idx}
                            dark={isDark}
                            color={`#${roiColor}`}
                        />
                    );

                    isDark = !isDark;
                }
            });
        }

        if (items.length === 0) {
            items.push(
                <div
                    className={`step2-nudge-text-cntr`}
                    key={"muted-nudge-text"}
                >
                    <MutedNudgeText
                        content={CREATE_SEGMENTATION_NUDGE_TEXT}
                        id={"step2-nudge-txt"}
                    />
                </div>
            );
        }

        return items;
    }

    render() {
        return (
            <div id={"segmentation-listing-panel"}>
                <div className={"segmentation-listing-header"}>
                    <p className={"display-20"}>
                        Review Anatomical Segmentations
                    </p>
                </div>
                <AHButton
                    id="add-seg-btn"
                    className={"segmentation-listing-add-seg-btn"}
                    onClick={() => {
                        this.props.setWorkflowState(
                            segmentationDisplayOptions.AI_SEG_OPTIONS
                        );
                    }}
                    size={"large"}
                    isDynamic={true}
                    text={"+ Add Segmentation"}
                />
                <div
                    className={
                        "segmentation-listing-cntr darkmode-item-listing"
                    }
                >
                    {this.getListItems()}
                </div>
            </div>
        );
    }
}

SegmentationListingPanel.propTypes = {
    patientAnatomyModel: PropTypes.instanceOf(PatientAnatomyModel),
    meshEditController: PropTypes.instanceOf(MeshEditController),
    setWorkflowState: PropTypes.func,
};

SegmentationListingPanel.defaultProps = {
    setWorkflowState: () => {},
};

export default SegmentationListingPanel;
