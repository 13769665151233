import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";

class CLCWidget extends Component {
    render() {
        return (
            <div className="clc-widget">
                <p
                    className={"display-12"}
                    style={{
                        color: blackColorText,
                        fontSize: titleFontSize,
                    }}
                >
                    Coverage: {this.props.coverageText}
                </p>
            </div>
        );
    }
}

CLCWidget.propTypes = {
    coverageText: PropTypes.string,
};

export default CLCWidget;

const blackColorText = "#000000";
const titleFontSize = "14px";
