import React, { Component } from "react";
import * as THREE from "three";
import { Form } from "react-bootstrap";
import "./styles.css";
import PropTypes from "prop-types";
import { ADJUSTMENT_DIRECTION, DIRECTION } from "../../constants";
import { PLANE } from "../../../__shared__/Viewers/consts";
import backArrow from "../../../../assets/back_arrow.svg";
import upDirectionArrowBlue from "../../../../assets/w_arrow_blue.svg";
import upDirectionArrowBlack from "../../../../assets/w_arrow_dark.svg";
import downDirectionArrowBlue from "../../../../assets/s_arrow_blue.svg";
import downDirectionArrowBlack from "../../../../assets/s_arrow_dark.svg";
import leftDirectionArrowBlue from "../../../../assets/a_arrow_blue.svg";
import leftDirectionArrowBlack from "../../../../assets/a_arrow_dark.svg";
import rightDirectionArrowBlue from "../../../../assets/d_arrow_blue.svg";
import rightDirectionArrowBlack from "../../../../assets/d_arrow_dark.svg";
import cwDirectionArrowBlue from "../../../../assets/cw_btn_blue.svg";
import cwDirectionArrowBlack from "../../../../assets/cw_btn_dark.svg";
import ccwDirectionArrowBlue from "../../../../assets/ccw_btn_blue.svg";
import ccwDirectionArrowBlack from "../../../../assets/ccw_btn_dark.svg";
import OrientationButtonGroup from "../OrientationButtonGroup";
import { COLOR_BLIND_SAFE_COLORS_2D, keyStrings } from "../../../../constants";
import { SwatchesPicker } from "react-color";
import Button from "react-bootstrap/Button";
import AdjustmentDirectionButton from "../../../__shared__/AdjustmentDirectionButton";
import TargetSetModel from "../../../__shared__/Viewers/Structures/Targets/TargetSetModel";
import TargetSetController from "../../../__shared__/Viewers/Structures/Targets/TargetSetController";
import MRIModel from "../../../__shared__/Viewers/Structures/MRI/MRIModel";

class AblationSiteDetailsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: "#ffffff",
        };

        this.onClose = this.onClose.bind(this);
        this.keyBoardControlHandler = this.keyBoardControlHandler.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
        this.onColorModalClick = this.onColorModalClick.bind(this);
        this.getColorModal = this.getColorModal.bind(this);
    }

    componentDidMount() {
        this.props.targetSetController.mouseControlsEnabled = false;
        this.props.targetSetController.setTargetHighlightByID(
            this.props.targetID,
            true
        );

        this.setState({ color: this.getTargetColor() });
        window.addEventListener("keydown", this.keyBoardControlHandler);
    }

    componentWillUnmount() {
        this.props.targetSetController.setTargetHighlightByID(
            this.props.targetID,
            false
        );
        this.props.targetSetController.mouseControlsEnabled = true;
        window.removeEventListener("keydown", this.keyBoardControlHandler);
    }

    handleClick(e, actionButtonName, maxShift) {
        e.stopPropagation();
        let plane, direction;

        switch (actionButtonName) {
            case ADJUSTMENT_DIRECTION.UP:
                plane = PLANE.VERTICAL;
                direction = DIRECTION.POSITIVE;
                break;
            case ADJUSTMENT_DIRECTION.DOWN:
                plane = PLANE.VERTICAL;
                direction = DIRECTION.NEGATIVE;
                break;
            case ADJUSTMENT_DIRECTION.LEFT:
                plane = PLANE.HORIZONTAL;
                direction = DIRECTION.POSITIVE;
                break;
            case ADJUSTMENT_DIRECTION.RIGHT:
                plane = PLANE.HORIZONTAL;
                direction = DIRECTION.NEGATIVE;
                break;
            case ADJUSTMENT_DIRECTION.ROTATE_CW:
                direction = DIRECTION.NEGATIVE;
                break;
            case ADJUSTMENT_DIRECTION.ROTATE_CCW:
                direction = DIRECTION.POSITIVE;
                break;
            default:
                break;
        }

        if (maxShift) {
            direction = direction * DIRECTION.MULTIPLIER;
        }

        switch (actionButtonName) {
            case ADJUSTMENT_DIRECTION.UP:
            case ADJUSTMENT_DIRECTION.DOWN:
            case ADJUSTMENT_DIRECTION.LEFT:
            case ADJUSTMENT_DIRECTION.RIGHT:
                this.props.targetSetController.translateTargetByID(
                    this.props.targetID,
                    this.props.mriModel.orientation,
                    direction,
                    plane
                );
                break;
            default:
                this.props.targetSetController.rotateTargetByID(
                    this.props.targetID,
                    this.props.mriModel.orientation,
                    direction
                );
        }
    }

    onClose(e) {
        e.stopPropagation();
        this.props.onClose();
    }

    keyBoardControlHandler(e) {
        if (e == null) {
            return;
        }

        e.stopPropagation();

        let acName;

        switch (e.key) {
            case keyStrings.W:
                acName = ADJUSTMENT_DIRECTION.UP;
                break;
            case keyStrings.S:
                acName = ADJUSTMENT_DIRECTION.DOWN;
                break;
            case keyStrings.A:
                acName = ADJUSTMENT_DIRECTION.LEFT;
                break;
            case keyStrings.D:
                acName = ADJUSTMENT_DIRECTION.RIGHT;
                break;
            case keyStrings.w:
                acName = ADJUSTMENT_DIRECTION.UP;
                break;
            case keyStrings.s:
                acName = ADJUSTMENT_DIRECTION.DOWN;
                break;
            case keyStrings.a:
                acName = ADJUSTMENT_DIRECTION.LEFT;
                break;
            case keyStrings.d:
                acName = ADJUSTMENT_DIRECTION.RIGHT;
                break;
            case keyStrings.E:
                acName = ADJUSTMENT_DIRECTION.ROTATE_CW;
                break;
            case keyStrings.Q:
                acName = ADJUSTMENT_DIRECTION.ROTATE_CCW;
                break;
            case keyStrings.e:
                acName = ADJUSTMENT_DIRECTION.ROTATE_CW;
                break;
            case keyStrings.q:
                acName = ADJUSTMENT_DIRECTION.ROTATE_CCW;
                break;
            default:
                return;
        }

        this.handleClick(e, acName, e.shiftKey);
    }

    handleColorChange(color) {
        this.props.targetSetController.setTargetColorByID(
            this.props.targetID,
            new THREE.Color(color.hex)
        );

        this.setState({ color: color.hex });
    }

    onColorModalClick(e) {
        e.stopPropagation();

        if (!this.state.colorModalOpen) {
            this.props.targetSetController.setTargetHighlightByID(
                this.props.targetID,
                false
            );
        }

        this.setState({ colorModalOpen: !this.state.colorModalOpen });
    }

    getColorModal() {
        if (this.state.colorModalOpen) {
            return (
                <div className={`swatches-cntr`}>
                    <SwatchesPicker
                        height={100}
                        width={175}
                        colors={COLOR_BLIND_SAFE_COLORS_2D}
                        onChange={this.handleColorChange}
                    />
                </div>
            );
        }
    }

    getTargetColor() {
        let target = this.props.targetSetModel.getTargetByID(
            this.props.targetID
        );
        let color = target.color.getHexString();
        return `#${color}`;
    }

    render() {
        let display = this.props.display;
        let boxColor = this.state.color;

        return (
            <div
                className={`target-workflow ${display}`}
                style={this.props.style}
                onClick={(event) => event.stopPropagation()}
            >
                <div className={`target-adj-cntr`}>
                    <div className={"target-adj-workflow-header"}>
                        <div className={"back-btn-cntr"}>
                            <img
                                alt={"<"}
                                className={"back-to-manager-btn"}
                                src={backArrow}
                                onClick={this.onClose}
                            />
                        </div>
                        <div className={"tgt-adj-panel-title display-20"}>
                            {`Tool Site ${Number(this.props.targetIndex) + 1}`}
                        </div>
                    </div>
                    <div className={`target-prop-sctn`}>
                        <div className={`target-prop-contents`}>
                            <div className={`tgt-properties-title display-20`}>
                                Properties
                            </div>
                            <div className={`tgt-properties-table`}>
                                <div className={`tgt-properties-item`}>
                                    <div
                                        className={
                                            "tgt-properties-item-title display-16"
                                        }
                                    >
                                        Color
                                    </div>
                                    <div
                                        className={`tgt-properties-item-value`}
                                    >
                                        <Button
                                            id="tool-site-color-select-btn"
                                            className={`tgt-color-btn`}
                                            size={"sm"}
                                            onClick={this.onColorModalClick}
                                            style={{
                                                backgroundColor: boxColor,
                                            }}
                                        >
                                            <div
                                                className={`color-box-triangle`}
                                            ></div>
                                            {this.getColorModal()}
                                        </Button>
                                    </div>
                                </div>
                                <div className={`tgt-properties-item`}>
                                    <div
                                        className={
                                            "tgt-properties-item-title display-16"
                                        }
                                    >
                                        Power
                                    </div>
                                    <div
                                        className={`tgt-properties-item-value display-16`}
                                    >
                                        <Form.Control
                                            className={"item-value-field h-100"}
                                            readOnly
                                            defaultValue="14W"
                                        />
                                    </div>
                                </div>
                                <div className={`tgt-properties-item`}>
                                    <div
                                        className={
                                            "tgt-properties-item-title display-16"
                                        }
                                    >
                                        Duration
                                    </div>
                                    <div
                                        className={`tgt-properties-item-value display-16`}
                                    >
                                        <Form.Control
                                            className={"item-value-field h-100"}
                                            readOnly
                                            defaultValue="05:00"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`target-adj-sctn-title`}>
                        <div className={`edit-pos-title-text display-20`}>
                            Edit Position
                        </div>
                    </div>
                    <div className={`target-adj-sctn`}>
                        <div className={"adjustment-panel"}>
                            <div className={`adjustment-panel-content`}>
                                <div className={`translation-adj-btn-table`}>
                                    <div className={`adj-table-row`}>
                                        <div
                                            className={`adjustment-btn`}
                                            onClick={(e) =>
                                                this.handleClick(
                                                    e,
                                                    ADJUSTMENT_DIRECTION.UP,
                                                    false
                                                )
                                            }
                                        >
                                            <AdjustmentDirectionButton
                                                imgSrcBlue={
                                                    upDirectionArrowBlue
                                                }
                                                imgSrcDark={
                                                    upDirectionArrowBlack
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className={`adj-table-mid-row`}>
                                        <div
                                            className={`adjustment-btn`}
                                            onClick={(e) =>
                                                this.handleClick(
                                                    e,
                                                    ADJUSTMENT_DIRECTION.LEFT,
                                                    false
                                                )
                                            }
                                        >
                                            <AdjustmentDirectionButton
                                                imgSrcBlue={
                                                    leftDirectionArrowBlue
                                                }
                                                imgSrcDark={
                                                    leftDirectionArrowBlack
                                                }
                                            />
                                        </div>
                                        <div
                                            className={`adjustment-btn`}
                                            onClick={(e) =>
                                                this.handleClick(
                                                    e,
                                                    ADJUSTMENT_DIRECTION.RIGHT,
                                                    false
                                                )
                                            }
                                        >
                                            <AdjustmentDirectionButton
                                                imgSrcBlue={
                                                    rightDirectionArrowBlue
                                                }
                                                imgSrcDark={
                                                    rightDirectionArrowBlack
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className={`adj-table-row`}>
                                        <div
                                            className={`adjustment-btn`}
                                            onClick={(e) =>
                                                this.handleClick(
                                                    e,
                                                    ADJUSTMENT_DIRECTION.DOWN,
                                                    false
                                                )
                                            }
                                        >
                                            <AdjustmentDirectionButton
                                                imgSrcBlue={
                                                    downDirectionArrowBlue
                                                }
                                                imgSrcDark={
                                                    downDirectionArrowBlack
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={"rotation-adjustment-btn-table"}
                                >
                                    <div
                                        className={"rotation-btn"}
                                        onClick={(e) =>
                                            this.handleClick(
                                                e,
                                                ADJUSTMENT_DIRECTION.ROTATE_CW,
                                                false
                                            )
                                        }
                                    >
                                        <AdjustmentDirectionButton
                                            imgSrcBlue={cwDirectionArrowBlue}
                                            imgSrcDark={cwDirectionArrowBlack}
                                            iconStyle={{
                                                height: "max(3.0vmax, 40px)",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={"rotation-adjustment-btn-table"}
                                >
                                    <div
                                        className={"rotation-btn"}
                                        onClick={(e) =>
                                            this.handleClick(
                                                e,
                                                ADJUSTMENT_DIRECTION.ROTATE_CCW,
                                                false
                                            )
                                        }
                                    >
                                        <AdjustmentDirectionButton
                                            imgSrcBlue={ccwDirectionArrowBlue}
                                            imgSrcDark={ccwDirectionArrowBlack}
                                            iconStyle={{
                                                height: "max(3.0vmax, 40px)",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`target-ornt-sctn`}>
                        <div className={"orientation-adjustment-btns"}>
                            <OrientationButtonGroup
                                mriModel={this.props.mriModel}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AblationSiteDetailsPanel.propTypes = {
    targetIndex: PropTypes.number,
    targetSetModel: PropTypes.instanceOf(TargetSetModel),
    targetSetController: PropTypes.instanceOf(TargetSetController),
    mriModel: PropTypes.instanceOf(MRIModel),
    targetID: PropTypes.string,
    onClose: PropTypes.func,
    style: PropTypes.object,
    display: PropTypes.string,
    color: PropTypes.string,
};

AblationSiteDetailsPanel.defaultProps = {
    active: false,
    adjustmentTargetID: null,
    targetPower: "N.A.",
    targetDuration: "N.A.",
};

export default AblationSiteDetailsPanel;
