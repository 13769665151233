export const defaultTxParameters = {
    TYPE: "Elliptical",
    MODALITY: "FLA",
    OPACITY: 0.6,
    TX_DURATION: 600,
    TX_COOLDOWN_DURATION: 180,
    CRITICAL_SENSOR: 7,
    POWER: 13.0,
};

export const ADJUSTMENT_FACTOR = {
    TRANSLATION: 0.3, // MM
    ROTATION: 0.05, // Radians
};

export const PLANE = {
    VERTICAL: "VERTICAL",
    HORIZONTAL: "HORIZONTAL",
};

export const THREE_VIEWER_NAMES = {
    PERSPECTIVE_CAMERA: "PerspectiveCamera",
    ORTHOGRAPHIC_CAMERA: "OrthographicCamera",
};
