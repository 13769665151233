import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { prostateMeshLabels, segmentationDisplayOptions } from "../constants";
import SegmentationListingPanel from "./SegmentationListingPanel";
import SelectAISegmentationPanel from "./SelectAISegmentationPanel";
import AISegmentationLoadingPanel from "./AISegmentationLoadingPanel";
import ManualEditingActivePanel from "./ManualEditingActivePanel";
import PatientAnatomyModel from "../../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyModel";
import MeshEditController from "../../__shared__/Viewers/Structures/Other/MeshEditController";

class SegmentationPanel extends Component {
    getPanel(workflowState) {
        let prostateAI = this.props.patientAnatomyModel.prostateAI;
        let meshLabel =
            prostateAI && prostateAI.visibility
                ? prostateMeshLabels.AI
                : prostateMeshLabels.USER;

        switch (workflowState) {
            case segmentationDisplayOptions.SEG_LISTING:
                return (
                    <SegmentationListingPanel
                        patientAnatomyModel={this.props.patientAnatomyModel}
                        meshEditController={this.props.meshEditController}
                        setWorkflowState={this.props.setWorkflowState}
                    />
                );
            case segmentationDisplayOptions.AI_SEG_OPTIONS:
                return (
                    <SelectAISegmentationPanel
                        patientAnatomyModel={this.props.patientAnatomyModel}
                        setWorkflowState={this.props.setWorkflowState}
                    />
                );
            case segmentationDisplayOptions.AI_SEG_LOADING:
                return (
                    <AISegmentationLoadingPanel
                        patientAnatomyModel={this.props.patientAnatomyModel}
                        setWorkflowState={this.props.setWorkflowState}
                    />
                );
            case segmentationDisplayOptions.SEG_EDITING:
                return (
                    <ManualEditingActivePanel
                        patientAnatomyModel={this.props.patientAnatomyModel}
                        meshEditController={this.props.meshEditController}
                        setWorkflowState={this.props.setWorkflowState}
                        setCurrentTreatmentPlan={
                            this.props.setCurrentTreatmentPlan
                        }
                        currentTreatmentPlan={this.props.currentTreatmentPlan}
                        editsSaved={this.props.editsSaved}
                        setEditsSavedState={this.props.setEditsSavedState}
                        ids={this.props.ids}
                        meshLabel={meshLabel}
                    />
                );
            default:
                return (
                    <SegmentationListingPanel
                        patientAnatomyModel={this.props.patientAnatomyModel}
                        meshEditController={this.props.meshEditController}
                        setWorkflowState={this.props.setWorkflowState}
                    />
                );
        }
    }

    render() {
        return (
            <div id={"segmentation-panel"}>
                {this.getPanel(this.props.activeWorkflowState)}
            </div>
        );
    }
}

SegmentationPanel.propTypes = {
    patientAnatomyModel: PropTypes.instanceOf(PatientAnatomyModel),
    meshEditController: PropTypes.instanceOf(MeshEditController),
    activeWorkflowState: PropTypes.string,
    setWorkflowState: PropTypes.func,
    setEditsSavedState: PropTypes.func,
    editsSaved: PropTypes.bool,
    setCurrentTreatmentPlan: PropTypes.func,
    currentTreatmentPlan: PropTypes.object,
    ids: PropTypes.object,
};

export default SegmentationPanel;
