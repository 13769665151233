import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AvendaLogo from "../../__shared__/AvendaLogo";
import { EMPTY_STRING } from "../../../constants";
import { checkSignUpCode } from "../helpers";
import { signUpFlowStates } from "../constants";
import { setSignUpFlowState } from "../actions";
import AHButton from "../../__shared__/StyleGuide/AHButton";
import AHAlphaField from "../../__shared__/StyleGuide/AHAlphaField";
import SignupLoginButton from "../LoginButton";
import AHProgressBubbles from "../../__shared__/StyleGuide/AHProgressBubbles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../Form/styles.css";

class AccessCode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accessCode: EMPTY_STRING,
            errorMsg: EMPTY_STRING,
        };
    }

    verifyCode = () => {
        let scope = this;
        checkSignUpCode(this.state.accessCode)
            .then((result) => {
                if (result.status === 200) {
                    scope.props.setSignUpFlowState(
                        signUpFlowStates.SIGNUP_FORM
                    );
                } else {
                    this.setState({
                        errorMsg: "The provided access code is invalid.",
                    });
                }
            })
            .catch(() => {});
    };

    handleBack = () => {
        this.props.history.push("/login");
    };

    onChange = (EMPTY_STRING, value) => {
        this.setState({
            accessCode: value,
        });
    };

    render() {
        return (
            <div className="signup-screen">
                <AvendaLogo id="signup-screen-avenda-logo" />
                <div className="signup-form">
                    <p className="display-regular-24 signup-form-title">
                        Access Code
                    </p>
                    <p className="display-regular-16 signup-form-title">
                        Please enter the access code we sent you so that we can
                        verify your organization.
                    </p>
                    {this.state.errorMsg && (
                        <div className="signup-form-error">
                            {this.state.errorMsg}
                        </div>
                    )}
                    <AHAlphaField
                        label={"Access Code"}
                        value={this.state.accessCode}
                        placeholder={"Access Code"}
                        onChange={this.onChange}
                        id={"registration-access-code"}
                        className={"ah-alpha-field-wrapper"}
                        type={"text"}
                        showLabel={false}
                    />
                    <AHButton
                        id={"access-code-handle-back"}
                        className={"left"}
                        isOutline={true}
                        isSecondary={true}
                        size={"large"}
                        isDynamic={false}
                        text={"Back"}
                        onClick={() => this.handleBack()}
                    />
                    <AHButton
                        id={"access-code-verify-code"}
                        className={"right"}
                        isOutline={false}
                        isSecondary={false}
                        size={"large"}
                        isDynamic={false}
                        text={"Next"}
                        onClick={() => this.verifyCode()}
                    />
                    <SignupLoginButton />
                    <AHProgressBubbles
                        totalSteps={signUpFlowStates.SIGNUP_FORM_STEPS}
                        currStep={1}
                    />
                </div>
            </div>
        );
    }
}

AccessCode.propTypes = {
    history: PropTypes.object,
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setSignUpFlowState: (error) => dispatch(setSignUpFlowState(error)),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(AccessCode));
