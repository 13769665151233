export const DefaultCPMOpacity = 1.0;

export const DEFAULT_SLIDER_PERCENT = 50;

export const TurboColorMapColors = [
    [0.0, 0, 0, 0],
    [0.00392156862745098, 0.18995, 0.07176, 0.23217],
    [0.00784313725490196, 0.19483, 0.08339, 0.26149],
    [0.011764705882352941, 0.19956, 0.09498, 0.29024],
    [0.01568627450980392, 0.20415, 0.10652, 0.31844],
    [0.0196078431372549, 0.2086, 0.11802, 0.34607],
    [0.023529411764705882, 0.21291, 0.12947, 0.37314],
    [0.027450980392156862, 0.21708, 0.14087, 0.39964],
    [0.03137254901960784, 0.22111, 0.15223, 0.42558],
    [0.03529411764705882, 0.225, 0.16354, 0.45096],
    [0.0392156862745098, 0.22875, 0.17481, 0.47578],
    [0.043137254901960784, 0.23236, 0.18603, 0.50004],
    [0.047058823529411764, 0.23582, 0.1972, 0.52373],
    [0.050980392156862744, 0.23915, 0.20833, 0.54686],
    [0.054901960784313725, 0.24234, 0.21941, 0.56942],
    [0.058823529411764705, 0.24539, 0.23044, 0.59142],
    [0.06274509803921569, 0.2483, 0.24143, 0.61286],
    [0.06666666666666667, 0.25107, 0.25237, 0.63374],
    [0.07058823529411765, 0.25369, 0.26327, 0.65406],
    [0.07450980392156863, 0.25618, 0.27412, 0.67381],
    [0.0784313725490196, 0.25853, 0.28492, 0.693],
    [0.08235294117647059, 0.26074, 0.29568, 0.71162],
    [0.08627450980392157, 0.2628, 0.30639, 0.72968],
    [0.09019607843137255, 0.26473, 0.31706, 0.74718],
    [0.09411764705882353, 0.26652, 0.32768, 0.76412],
    [0.09803921568627451, 0.26816, 0.33825, 0.7805],
    [0.10196078431372549, 0.26967, 0.34878, 0.79631],
    [0.10588235294117647, 0.27103, 0.35926, 0.81156],
    [0.10980392156862745, 0.27226, 0.3697, 0.82624],
    [0.11372549019607843, 0.27334, 0.38008, 0.84037],
    [0.11764705882352941, 0.27429, 0.39043, 0.85393],
    [0.12156862745098039, 0.27509, 0.40072, 0.86692],
    [0.12549019607843137, 0.27576, 0.41097, 0.87936],
    [0.12941176470588237, 0.27628, 0.42118, 0.89123],
    [0.13333333333333333, 0.27667, 0.43134, 0.90254],
    [0.13725490196078433, 0.27691, 0.44145, 0.91328],
    [0.1411764705882353, 0.27701, 0.45152, 0.92347],
    [0.1450980392156863, 0.27698, 0.46153, 0.93309],
    [0.14901960784313725, 0.2768, 0.47151, 0.94214],
    [0.15294117647058825, 0.27648, 0.48144, 0.95064],
    [0.1568627450980392, 0.27603, 0.49132, 0.95857],
    [0.1607843137254902, 0.27543, 0.50115, 0.96594],
    [0.16470588235294117, 0.27469, 0.51094, 0.97275],
    [0.16862745098039217, 0.27381, 0.52069, 0.97899],
    [0.17254901960784313, 0.27273, 0.5304, 0.98461],
    [0.17647058823529413, 0.27106, 0.54015, 0.9893],
    [0.1803921568627451, 0.26878, 0.54995, 0.99303],
    [0.1843137254901961, 0.26592, 0.55979, 0.99583],
    [0.18823529411764706, 0.26252, 0.56967, 0.99773],
    [0.19215686274509805, 0.25862, 0.57958, 0.99876],
    [0.19607843137254902, 0.25425, 0.5895, 0.99896],
    [0.2, 0.24946, 0.59943, 0.99835],
    [0.20392156862745098, 0.24427, 0.60937, 0.99697],
    [0.20784313725490197, 0.23874, 0.61931, 0.99485],
    [0.21176470588235294, 0.23288, 0.62923, 0.99202],
    [0.21568627450980393, 0.22676, 0.63913, 0.98851],
    [0.2196078431372549, 0.22039, 0.64901, 0.98436],
    [0.2235294117647059, 0.21382, 0.65886, 0.97959],
    [0.22745098039215686, 0.20708, 0.66866, 0.97423],
    [0.23137254901960785, 0.20021, 0.67842, 0.96833],
    [0.23529411764705882, 0.19326, 0.68812, 0.9619],
    [0.23921568627450981, 0.18625, 0.69775, 0.95498],
    [0.24313725490196078, 0.17923, 0.70732, 0.94761],
    [0.24705882352941178, 0.17223, 0.7168, 0.93981],
    [0.25098039215686274, 0.16529, 0.7262, 0.93161],
    [0.2549019607843137, 0.15844, 0.73551, 0.92305],
    [0.25882352941176473, 0.15173, 0.74472, 0.91416],
    [0.2627450980392157, 0.14519, 0.75381, 0.90496],
    [0.26666666666666666, 0.13886, 0.76279, 0.8955],
    [0.27058823529411763, 0.13278, 0.77165, 0.8858],
    [0.27450980392156865, 0.12698, 0.78037, 0.8759],
    [0.2784313725490196, 0.12151, 0.78896, 0.86581],
    [0.2823529411764706, 0.11639, 0.7974, 0.85559],
    [0.28627450980392155, 0.11167, 0.80569, 0.84525],
    [0.2901960784313726, 0.10738, 0.81381, 0.83484],
    [0.29411764705882354, 0.10357, 0.82177, 0.82437],
    [0.2980392156862745, 0.10026, 0.82955, 0.81389],
    [0.30196078431372547, 0.0975, 0.83714, 0.80342],
    [0.3058823529411765, 0.09532, 0.84455, 0.79299],
    [0.30980392156862746, 0.09377, 0.85175, 0.78264],
    [0.3137254901960784, 0.09287, 0.85875, 0.7724],
    [0.3176470588235294, 0.09267, 0.86554, 0.7623],
    [0.3215686274509804, 0.0932, 0.87211, 0.75237],
    [0.3254901960784314, 0.09451, 0.87844, 0.74265],
    [0.32941176470588235, 0.09662, 0.88454, 0.73316],
    [0.3333333333333333, 0.09958, 0.8904, 0.72393],
    [0.33725490196078434, 0.10342, 0.896, 0.715],
    [0.3411764705882353, 0.10815, 0.90142, 0.70599],
    [0.34509803921568627, 0.11374, 0.90673, 0.69651],
    [0.34901960784313724, 0.12014, 0.91193, 0.6866],
    [0.35294117647058826, 0.12733, 0.91701, 0.67627],
    [0.3568627450980392, 0.13526, 0.92197, 0.66556],
    [0.3607843137254902, 0.14391, 0.9268, 0.65448],
    [0.36470588235294116, 0.15323, 0.93151, 0.64308],
    [0.3686274509803922, 0.16319, 0.93609, 0.63137],
    [0.37254901960784315, 0.17377, 0.94053, 0.61938],
    [0.3764705882352941, 0.18491, 0.94484, 0.60713],
    [0.3803921568627451, 0.19659, 0.94901, 0.59466],
    [0.3843137254901961, 0.20877, 0.95304, 0.58199],
    [0.38823529411764707, 0.22142, 0.95692, 0.56914],
    [0.39215686274509803, 0.23449, 0.96065, 0.55614],
    [0.396078431372549, 0.24797, 0.96423, 0.54303],
    [0.4, 0.2618, 0.96765, 0.52981],
    [0.403921568627451, 0.27597, 0.97092, 0.51653],
    [0.40784313725490196, 0.29042, 0.97403, 0.50321],
    [0.4117647058823529, 0.30513, 0.97697, 0.48987],
    [0.41568627450980394, 0.32006, 0.97974, 0.47654],
    [0.4196078431372549, 0.33517, 0.98234, 0.46325],
    [0.4235294117647059, 0.35043, 0.98477, 0.45002],
    [0.42745098039215684, 0.36581, 0.98702, 0.43688],
    [0.43137254901960786, 0.38127, 0.98909, 0.42386],
    [0.43529411764705883, 0.39678, 0.99098, 0.41098],
    [0.4392156862745098, 0.41229, 0.99268, 0.39826],
    [0.44313725490196076, 0.42778, 0.99419, 0.38575],
    [0.4470588235294118, 0.44321, 0.99551, 0.37345],
    [0.45098039215686275, 0.45854, 0.99663, 0.3614],
    [0.4549019607843137, 0.47375, 0.99755, 0.34963],
    [0.4588235294117647, 0.48879, 0.99828, 0.33816],
    [0.4627450980392157, 0.50362, 0.99879, 0.32701],
    [0.4666666666666667, 0.51822, 0.9991, 0.31622],
    [0.47058823529411764, 0.53255, 0.99919, 0.30581],
    [0.4745098039215686, 0.54658, 0.99907, 0.29581],
    [0.47843137254901963, 0.56026, 0.99873, 0.28623],
    [0.4823529411764706, 0.57357, 0.99817, 0.27712],
    [0.48627450980392156, 0.58646, 0.99739, 0.26849],
    [0.49019607843137253, 0.59891, 0.99638, 0.26038],
    [0.49411764705882355, 0.61088, 0.99514, 0.2528],
    [0.4980392156862745, 0.62233, 0.99366, 0.24579],
    [0.5019607843137255, 0.63323, 0.99195, 0.23937],
    [0.5058823529411764, 0.64362, 0.98999, 0.23356],
    [0.5098039215686274, 0.65394, 0.98775, 0.22835],
    [0.5137254901960784, 0.66428, 0.98524, 0.2237],
    [0.5176470588235295, 0.67462, 0.98246, 0.2196],
    [0.5215686274509804, 0.68494, 0.97941, 0.21602],
    [0.5254901960784314, 0.69525, 0.9761, 0.21294],
    [0.5294117647058824, 0.70553, 0.97255, 0.21032],
    [0.5333333333333333, 0.71577, 0.96875, 0.20815],
    [0.5372549019607843, 0.72596, 0.9647, 0.2064],
    [0.5411764705882353, 0.7361, 0.96043, 0.20504],
    [0.5450980392156862, 0.74617, 0.95593, 0.20406],
    [0.5490196078431373, 0.75617, 0.95121, 0.20343],
    [0.5529411764705883, 0.76608, 0.94627, 0.20311],
    [0.5568627450980392, 0.77591, 0.94113, 0.2031],
    [0.5607843137254902, 0.78563, 0.93579, 0.20336],
    [0.5647058823529412, 0.79524, 0.93025, 0.20386],
    [0.5686274509803921, 0.80473, 0.92452, 0.20459],
    [0.5725490196078431, 0.8141, 0.91861, 0.20552],
    [0.5764705882352941, 0.82333, 0.91253, 0.20663],
    [0.5803921568627451, 0.83241, 0.90627, 0.20788],
    [0.5843137254901961, 0.84133, 0.89986, 0.20926],
    [0.5882352941176471, 0.8501, 0.89328, 0.21074],
    [0.592156862745098, 0.85868, 0.88655, 0.2123],
    [0.596078431372549, 0.86709, 0.87968, 0.21391],
    [0.6, 0.8753, 0.87267, 0.21555],
    [0.6039215686274509, 0.88331, 0.86553, 0.21719],
    [0.6078431372549019, 0.89112, 0.85826, 0.2188],
    [0.611764705882353, 0.8987, 0.85087, 0.22038],
    [0.615686274509804, 0.90605, 0.84337, 0.22188],
    [0.6196078431372549, 0.91317, 0.83576, 0.22328],
    [0.6235294117647059, 0.92004, 0.82806, 0.22456],
    [0.6274509803921569, 0.92666, 0.82025, 0.2257],
    [0.6313725490196078, 0.93301, 0.81236, 0.22667],
    [0.6352941176470588, 0.93909, 0.80439, 0.22744],
    [0.6392156862745098, 0.94489, 0.79634, 0.228],
    [0.6431372549019608, 0.95039, 0.78823, 0.22831],
    [0.6470588235294118, 0.9556, 0.78005, 0.22836],
    [0.6509803921568628, 0.96049, 0.77181, 0.22811],
    [0.6549019607843137, 0.96507, 0.76352, 0.22754],
    [0.6588235294117647, 0.96931, 0.75519, 0.22663],
    [0.6627450980392157, 0.97323, 0.74682, 0.22536],
    [0.6666666666666666, 0.97679, 0.73842, 0.22369],
    [0.6705882352941176, 0.98, 0.73, 0.22161],
    [0.6745098039215687, 0.98289, 0.7214, 0.21918],
    [0.6784313725490196, 0.98549, 0.7125, 0.2165],
    [0.6823529411764706, 0.98781, 0.7033, 0.21358],
    [0.6862745098039216, 0.98986, 0.69382, 0.21043],
    [0.6901960784313725, 0.99163, 0.68408, 0.20706],
    [0.6941176470588235, 0.99314, 0.67408, 0.20348],
    [0.6980392156862745, 0.99438, 0.66386, 0.19971],
    [0.7019607843137254, 0.99535, 0.65341, 0.19577],
    [0.7058823529411765, 0.99607, 0.64277, 0.19165],
    [0.7098039215686275, 0.99654, 0.63193, 0.18738],
    [0.7137254901960784, 0.99675, 0.62093, 0.18297],
    [0.7176470588235294, 0.99672, 0.60977, 0.17842],
    [0.7215686274509804, 0.99644, 0.59846, 0.17376],
    [0.7254901960784313, 0.99593, 0.58703, 0.16899],
    [0.7294117647058823, 0.99517, 0.57549, 0.16412],
    [0.7333333333333333, 0.99419, 0.56386, 0.15918],
    [0.7372549019607844, 0.99297, 0.55214, 0.15417],
    [0.7411764705882353, 0.99153, 0.54036, 0.1491],
    [0.7450980392156863, 0.98987, 0.52854, 0.14398],
    [0.7490196078431373, 0.98799, 0.51667, 0.13883],
    [0.7529411764705882, 0.9859, 0.50479, 0.13367],
    [0.7568627450980392, 0.9836, 0.49291, 0.12849],
    [0.7607843137254902, 0.98108, 0.48104, 0.12332],
    [0.7647058823529411, 0.97837, 0.4692, 0.11817],
    [0.7686274509803922, 0.97545, 0.4574, 0.11305],
    [0.7725490196078432, 0.97234, 0.44565, 0.10797],
    [0.7764705882352941, 0.96904, 0.43399, 0.10294],
    [0.7803921568627451, 0.96555, 0.42241, 0.09798],
    [0.7843137254901961, 0.96187, 0.41093, 0.0931],
    [0.788235294117647, 0.95801, 0.39958, 0.08831],
    [0.792156862745098, 0.95398, 0.38836, 0.08362],
    [0.796078431372549, 0.94977, 0.37729, 0.07905],
    [0.8, 0.94538, 0.36638, 0.07461],
    [0.803921568627451, 0.94084, 0.35566, 0.07031],
    [0.807843137254902, 0.93612, 0.34513, 0.06616],
    [0.8117647058823529, 0.93125, 0.33482, 0.06218],
    [0.8156862745098039, 0.92623, 0.32473, 0.05837],
    [0.8196078431372549, 0.92105, 0.31489, 0.05475],
    [0.8235294117647058, 0.91572, 0.3053, 0.05134],
    [0.8274509803921568, 0.91024, 0.29599, 0.04814],
    [0.8313725490196079, 0.90463, 0.28696, 0.04516],
    [0.8352941176470589, 0.89888, 0.27824, 0.04243],
    [0.8392156862745098, 0.89298, 0.26981, 0.03993],
    [0.8431372549019608, 0.88691, 0.26152, 0.03753],
    [0.8470588235294118, 0.88066, 0.25334, 0.03521],
    [0.8509803921568627, 0.87422, 0.24526, 0.03297],
    [0.8549019607843137, 0.8676, 0.2373, 0.03082],
    [0.8588235294117647, 0.86079, 0.22945, 0.02875],
    [0.8627450980392157, 0.8538, 0.2217, 0.02677],
    [0.8666666666666667, 0.84662, 0.21407, 0.02487],
    [0.8705882352941177, 0.83926, 0.20654, 0.02305],
    [0.8745098039215686, 0.83172, 0.19912, 0.02131],
    [0.8784313725490196, 0.82399, 0.19182, 0.01966],
    [0.8823529411764706, 0.81608, 0.18462, 0.01809],
    [0.8862745098039215, 0.80799, 0.17753, 0.0166],
    [0.8901960784313725, 0.79971, 0.17055, 0.0152],
    [0.8941176470588236, 0.79125, 0.16368, 0.01387],
    [0.8980392156862745, 0.7826, 0.15693, 0.01264],
    [0.9019607843137255, 0.77377, 0.15028, 0.01148],
    [0.9058823529411765, 0.76476, 0.14374, 0.01041],
    [0.9098039215686274, 0.75556, 0.13731, 0.00942],
    [0.9137254901960784, 0.74617, 0.13098, 0.00851],
    [0.9176470588235294, 0.73661, 0.12477, 0.00769],
    [0.9215686274509803, 0.72686, 0.11867, 0.00695],
    [0.9254901960784314, 0.71692, 0.11268, 0.00629],
    [0.9294117647058824, 0.7068, 0.1068, 0.00571],
    [0.9333333333333333, 0.6965, 0.10102, 0.00522],
    [0.9372549019607843, 0.68602, 0.09536, 0.00481],
    [0.9411764705882353, 0.67535, 0.0898, 0.00449],
    [0.9450980392156862, 0.66449, 0.08436, 0.00424],
    [0.9490196078431372, 0.65345, 0.07902, 0.00408],
    [0.9529411764705882, 0.64223, 0.0738, 0.00401],
    [0.9568627450980393, 0.63082, 0.06868, 0.00401],
    [0.9607843137254902, 0.61923, 0.06367, 0.0041],
    [0.9647058823529412, 0.60746, 0.05878, 0.00427],
    [0.9686274509803922, 0.5955, 0.05399, 0.00453],
    [0.9725490196078431, 0.58336, 0.04931, 0.00486],
    [0.9764705882352941, 0.57103, 0.04474, 0.00529],
    [0.9803921568627451, 0.55852, 0.04028, 0.00579],
    [0.984313725490196, 0.54583, 0.03593, 0.00638],
    [0.9882352941176471, 0.53295, 0.03169, 0.00705],
    [0.9921568627450981, 0.51989, 0.02756, 0.0078],
    [0.996078431372549, 0.50664, 0.02354, 0.00863],
    [1.0, 0.49321, 0.01963, 0.00955],
];

export const TurboColorMapOpacityLevels = [
    [0.0, 0.0],
    [0.00392156862745098, 1.0],
    [0.00784313725490196, 1.0],
    [0.011764705882352941, 1.0],
    [0.01568627450980392, 1.0],
    [0.0196078431372549, 1.0],
    [0.023529411764705882, 1.0],
    [0.027450980392156862, 1.0],
    [0.03137254901960784, 1.0],
    [0.03529411764705882, 1.0],
    [0.0392156862745098, 1.0],
    [0.043137254901960784, 1.0],
    [0.047058823529411764, 1.0],
    [0.050980392156862744, 1.0],
    [0.054901960784313725, 1.0],
    [0.058823529411764705, 1.0],
    [0.06274509803921569, 1.0],
    [0.06666666666666667, 1.0],
    [0.07058823529411765, 1.0],
    [0.07450980392156863, 1.0],
    [0.0784313725490196, 1.0],
    [0.08235294117647059, 1.0],
    [0.08627450980392157, 1.0],
    [0.09019607843137255, 1.0],
    [0.09411764705882353, 1.0],
    [0.09803921568627451, 1.0],
    [0.10196078431372549, 1.0],
    [0.10588235294117647, 1.0],
    [0.10980392156862745, 1.0],
    [0.11372549019607843, 1.0],
    [0.11764705882352941, 1.0],
    [0.12156862745098039, 1.0],
    [0.12549019607843137, 1.0],
    [0.12941176470588237, 1.0],
    [0.13333333333333333, 1.0],
    [0.13725490196078433, 1.0],
    [0.1411764705882353, 1.0],
    [0.1450980392156863, 1.0],
    [0.14901960784313725, 1.0],
    [0.15294117647058825, 1.0],
    [0.1568627450980392, 1.0],
    [0.1607843137254902, 1.0],
    [0.16470588235294117, 1.0],
    [0.16862745098039217, 1.0],
    [0.17254901960784313, 1.0],
    [0.17647058823529413, 1.0],
    [0.1803921568627451, 1.0],
    [0.1843137254901961, 1.0],
    [0.18823529411764706, 1.0],
    [0.19215686274509805, 1.0],
    [0.19607843137254902, 1.0],
    [0.2, 1.0],
    [0.20392156862745098, 1.0],
    [0.20784313725490197, 1.0],
    [0.21176470588235294, 1.0],
    [0.21568627450980393, 1.0],
    [0.2196078431372549, 1.0],
    [0.2235294117647059, 1.0],
    [0.22745098039215686, 1.0],
    [0.23137254901960785, 1.0],
    [0.23529411764705882, 1.0],
    [0.23921568627450981, 1.0],
    [0.24313725490196078, 1.0],
    [0.24705882352941178, 1.0],
    [0.25098039215686274, 1.0],
    [0.2549019607843137, 1.0],
    [0.25882352941176473, 1.0],
    [0.2627450980392157, 1.0],
    [0.26666666666666666, 1.0],
    [0.27058823529411763, 1.0],
    [0.27450980392156865, 1.0],
    [0.2784313725490196, 1.0],
    [0.2823529411764706, 1.0],
    [0.28627450980392155, 1.0],
    [0.2901960784313726, 1.0],
    [0.29411764705882354, 1.0],
    [0.2980392156862745, 1.0],
    [0.30196078431372547, 1.0],
    [0.3058823529411765, 1.0],
    [0.30980392156862746, 1.0],
    [0.3137254901960784, 1.0],
    [0.3176470588235294, 1.0],
    [0.3215686274509804, 1.0],
    [0.3254901960784314, 1.0],
    [0.32941176470588235, 1.0],
    [0.3333333333333333, 1.0],
    [0.33725490196078434, 1.0],
    [0.3411764705882353, 1.0],
    [0.34509803921568627, 1.0],
    [0.34901960784313724, 1.0],
    [0.35294117647058826, 1.0],
    [0.3568627450980392, 1.0],
    [0.3607843137254902, 1.0],
    [0.36470588235294116, 1.0],
    [0.3686274509803922, 1.0],
    [0.37254901960784315, 1.0],
    [0.3764705882352941, 1.0],
    [0.3803921568627451, 1.0],
    [0.3843137254901961, 1.0],
    [0.38823529411764707, 1.0],
    [0.39215686274509803, 1.0],
    [0.396078431372549, 1.0],
    [0.4, 1.0],
    [0.403921568627451, 1.0],
    [0.40784313725490196, 1.0],
    [0.4117647058823529, 1.0],
    [0.41568627450980394, 1.0],
    [0.4196078431372549, 1.0],
    [0.4235294117647059, 1.0],
    [0.42745098039215684, 1.0],
    [0.43137254901960786, 1.0],
    [0.43529411764705883, 1.0],
    [0.4392156862745098, 1.0],
    [0.44313725490196076, 1.0],
    [0.4470588235294118, 1.0],
    [0.45098039215686275, 1.0],
    [0.4549019607843137, 1.0],
    [0.4588235294117647, 1.0],
    [0.4627450980392157, 1.0],
    [0.4666666666666667, 1.0],
    [0.47058823529411764, 1.0],
    [0.4745098039215686, 1.0],
    [0.47843137254901963, 1.0],
    [0.4823529411764706, 1.0],
    [0.48627450980392156, 1.0],
    [0.49019607843137253, 1.0],
    [0.49411764705882355, 1.0],
    [0.4980392156862745, 1.0],
    [0.5019607843137255, 1.0],
    [0.5058823529411764, 1.0],
    [0.5098039215686274, 1.0],
    [0.5137254901960784, 1.0],
    [0.5176470588235295, 1.0],
    [0.5215686274509804, 1.0],
    [0.5254901960784314, 1.0],
    [0.5294117647058824, 1.0],
    [0.5333333333333333, 1.0],
    [0.5372549019607843, 1.0],
    [0.5411764705882353, 1.0],
    [0.5450980392156862, 1.0],
    [0.5490196078431373, 1.0],
    [0.5529411764705883, 1.0],
    [0.5568627450980392, 1.0],
    [0.5607843137254902, 1.0],
    [0.5647058823529412, 1.0],
    [0.5686274509803921, 1.0],
    [0.5725490196078431, 1.0],
    [0.5764705882352941, 1.0],
    [0.5803921568627451, 1.0],
    [0.5843137254901961, 1.0],
    [0.5882352941176471, 1.0],
    [0.592156862745098, 1.0],
    [0.596078431372549, 1.0],
    [0.6, 1.0],
    [0.6039215686274509, 1.0],
    [0.6078431372549019, 1.0],
    [0.611764705882353, 1.0],
    [0.615686274509804, 1.0],
    [0.6196078431372549, 1.0],
    [0.6235294117647059, 1.0],
    [0.6274509803921569, 1.0],
    [0.6313725490196078, 1.0],
    [0.6352941176470588, 1.0],
    [0.6392156862745098, 1.0],
    [0.6431372549019608, 1.0],
    [0.6470588235294118, 1.0],
    [0.6509803921568628, 1.0],
    [0.6549019607843137, 1.0],
    [0.6588235294117647, 1.0],
    [0.6627450980392157, 1.0],
    [0.6666666666666666, 1.0],
    [0.6705882352941176, 1.0],
    [0.6745098039215687, 1.0],
    [0.6784313725490196, 1.0],
    [0.6823529411764706, 1.0],
    [0.6862745098039216, 1.0],
    [0.6901960784313725, 1.0],
    [0.6941176470588235, 1.0],
    [0.6980392156862745, 1.0],
    [0.7019607843137254, 1.0],
    [0.7058823529411765, 1.0],
    [0.7098039215686275, 1.0],
    [0.7137254901960784, 1.0],
    [0.7176470588235294, 1.0],
    [0.7215686274509804, 1.0],
    [0.7254901960784313, 1.0],
    [0.7294117647058823, 1.0],
    [0.7333333333333333, 1.0],
    [0.7372549019607844, 1.0],
    [0.7411764705882353, 1.0],
    [0.7450980392156863, 1.0],
    [0.7490196078431373, 1.0],
    [0.7529411764705882, 1.0],
    [0.7568627450980392, 1.0],
    [0.7607843137254902, 1.0],
    [0.7647058823529411, 1.0],
    [0.7686274509803922, 1.0],
    [0.7725490196078432, 1.0],
    [0.7764705882352941, 1.0],
    [0.7803921568627451, 1.0],
    [0.7843137254901961, 1.0],
    [0.788235294117647, 1.0],
    [0.792156862745098, 1.0],
    [0.796078431372549, 1.0],
    [0.8, 1.0],
    [0.803921568627451, 1.0],
    [0.807843137254902, 1.0],
    [0.8117647058823529, 1.0],
    [0.8156862745098039, 1.0],
    [0.8196078431372549, 1.0],
    [0.8235294117647058, 1.0],
    [0.8274509803921568, 1.0],
    [0.8313725490196079, 1.0],
    [0.8352941176470589, 1.0],
    [0.8392156862745098, 1.0],
    [0.8431372549019608, 1.0],
    [0.8470588235294118, 1.0],
    [0.8509803921568627, 1.0],
    [0.8549019607843137, 1.0],
    [0.8588235294117647, 1.0],
    [0.8627450980392157, 1.0],
    [0.8666666666666667, 1.0],
    [0.8705882352941177, 1.0],
    [0.8745098039215686, 1.0],
    [0.8784313725490196, 1.0],
    [0.8823529411764706, 1.0],
    [0.8862745098039215, 1.0],
    [0.8901960784313725, 1.0],
    [0.8941176470588236, 1.0],
    [0.8980392156862745, 1.0],
    [0.9019607843137255, 1.0],
    [0.9058823529411765, 1.0],
    [0.9098039215686274, 1.0],
    [0.9137254901960784, 1.0],
    [0.9176470588235294, 1.0],
    [0.9215686274509803, 1.0],
    [0.9254901960784314, 1.0],
    [0.9294117647058824, 1.0],
    [0.9333333333333333, 1.0],
    [0.9372549019607843, 1.0],
    [0.9411764705882353, 1.0],
    [0.9450980392156862, 1.0],
    [0.9490196078431372, 1.0],
    [0.9529411764705882, 1.0],
    [0.9568627450980393, 1.0],
    [0.9607843137254902, 1.0],
    [0.9647058823529412, 1.0],
    [0.9686274509803922, 1.0],
    [0.9725490196078431, 1.0],
    [0.9764705882352941, 1.0],
    [0.9803921568627451, 1.0],
    [0.984313725490196, 1.0],
    [0.9882352941176471, 1.0],
    [0.9921568627450981, 1.0],
    [0.996078431372549, 1.0],
    [1.0, 1.0],
];

// grab_index=64, release_index=10
export const TurboColorMapColorsSHIFTED = [
    [0.0, 0.0, 0.0, 0.0], // #000000 1.3877787807814457e-17
    [0.00392156862745098, 0.21708, 0.14087, 0.39964], // #372365 0.000948139266210124
    [0.00784313725490196, 0.24234, 0.21941, 0.56942], // #3d3791 0.0019052682131014825
    [0.011764705882352941, 0.26074, 0.29568, 0.71162], // #424bb5 0.0028714720753666295
    [0.01568627450980392, 0.27226, 0.3697, 0.82624], // #455ed2 0.0038468368958415045
    [0.0196078431372549, 0.27691, 0.44145, 0.91328], // #4670e8 0.00483144953316815
    [0.023529411764705882, 0.27469, 0.51094, 0.97275], // #4682f8 0.005825397669529303
    [0.027450980392156862, 0.25862, 0.57958, 0.99876], // #4193fe 0.006828769818457106
    [0.03137254901960784, 0.22039, 0.64901, 0.98436], // #38a5fb 0.007841655332715067
    [0.03529411764705882, 0.17223, 0.7168, 0.93981], // #2bb6ef 0.008864144412255512
    [0.0392156862745098, 0.16529, 0.7262, 0.93161], // #2ab9ed 0.009896328112251732
    [
        0.043137254901960784, 0.15994979591836722, 0.7334580000000002,
        0.924936693877551,
    ], // #28bbeb 0.010938298351207035
    [
        0.047058823529411764, 0.1546878775510204, 0.7406600816326531,
        0.9180788571428571,
    ], // #27bcea 0.01199014791914016
    [
        0.050980392156862744, 0.14951440816326525, 0.7477994693877552,
        0.9110432653061223,
    ], // #26bee8 0.013051970485848302
    [
        0.054901960784313725, 0.14444073469387755, 0.7548729387755102,
        0.9038402448979591,
    ], // #24c0e6 0.014123860609248848
    [
        0.058823529411764705, 0.13950591836734694, 0.7618736734693877,
        0.8964653061224489,
    ], // #23c2e4 0.015205913743800037
    [
        0.06274509803921569, 0.1347404897959184, 0.7687931020408162,
        0.8889277551020409,
    ], // #22c4e2 0.016298226249001097
    [
        0.06666666666666667, 0.13012857142857143, 0.7756362857142857,
        0.8812742857142857,
    ], // #21c5e0 0.01740089539797375
    [
        0.07058823529411765, 0.12568506122448989, 0.782403551020408,
        0.8735113469387756,
    ], // #20c7de 0.01851401938612414
    [
        0.07450980392156863, 0.1214264081632653, 0.7890977959183674,
        0.8656431428571428,
    ], // #1ec9dc 0.01963769733988785
    [
        0.0784313725490196, 0.11743489795918366, 0.7956775510204082,
        0.8576757142857142,
    ], // #1dcada 0.020772029325556884
    [
        0.08235294117647059, 0.11367359183673473, 0.8021709795918367,
        0.8496392244897959,
    ], // #1cccd8 0.02191711635819127
    [
        0.08627450980392157, 0.11014661224489791, 0.8085734285714287,
        0.8415533877551019,
    ], // #1cced6 0.023073060410614354
    [
        0.09019607843137255, 0.1068668163265306, 0.8148821632653062,
        0.8334297551020408,
    ], // #1bcfd4 0.02423996442249382
    [
        0.09411764705882353, 0.10389657142857141, 0.8210877142857144,
        0.8252674285714285,
    ], // #1ad1d2 0.02541793230950895
    [
        0.09803921568627451, 0.1012732653061225, 0.8271683673469387,
        0.8170981632653063,
    ], // #19d2d0 0.026607068972604175
    [
        0.10196078431372549, 0.09895322448979595, 0.8331436326530611,
        0.8089327755102041,
    ], // #19d4ce 0.0278074803073311
    [
        0.10588235294117647, 0.09694832653061228, 0.8390151836734693,
        0.8007805714285715,
    ], // #18d5cc 0.029019273213278657
    [
        0.10980392156862745, 0.09526938775510203, 0.8447851020408164,
        0.7926520408163265,
    ], // #18d7ca 0.030242555603592597
    [
        0.11372549019607843, 0.09406102040816326, 0.8503981632653062,
        0.7845832653061224,
    ], // #17d8c8 0.03147743641458578
    [
        0.11764705882352941, 0.09323734693877551, 0.8558928571428571,
        0.7765795918367348,
    ], // #17dac6 0.03272402561543891
    [0.12156862745098039, 0.09279571428571429, 0.861272, 0.7686485714285713], // #17dbc4 0.03398243421799357
    [
        0.12549019607843137, 0.09275004081632653, 0.8665322040816326,
        0.7608003673469389,
    ], // #17dcc2 0.035252774286638505
    [
        0.12941176470588237, 0.09316322448979593, 0.8716541224489797,
        0.7530590204081632,
    ], // #17dec0 0.0365351589482886
    [
        0.13333333333333333, 0.09413036734693878, 0.8766055918367347,
        0.7454668163265306,
    ], // #18dfbe 0.0378297024024597
    [
        0.13725490196078433, 0.09554346938775508, 0.8814277551020407,
        0.738001836734694,
    ], // #18e0bc 0.03913651993143791
    [
        0.1411764705882353, 0.09741738775510206, 0.886118612244898,
        0.7306735510204081,
    ], // #18e1ba 0.04045572791054611
    [
        0.1450980392156863, 0.09976808163265301, 0.8906742857142856,
        0.723492612244898,
    ], // #19e3b8 0.041787443818507214
    [
        0.14901960784313725, 0.1027617142857143, 0.8950400000000001,
        0.716530857142857,
    ], // #1ae4b6 0.043131786247906034
    [
        0.15294117647058825, 0.10629661224489795, 0.8992962448979592,
        0.7095204489795919,
    ], // #1be5b4 0.04448887491575035
    [
        0.1568627450980392, 0.1103175510204082, 0.9034789795918368,
        0.702314081632653,
    ], // #1ce6b3 0.04585883067413174
    [
        0.1607843137254902, 0.1148110204081632, 0.9076002040816327,
        0.6948515918367347,
    ], // #1de7b1 0.04724177552098807
    [
        0.16470588235294117, 0.11980040816326534, 0.9116540816326532,
        0.6871258367346937,
    ], // #1ee8af 0.048637832610967435
    [
        0.16862745098039217, 0.12536375510204087, 0.9156207755102042,
        0.6790949387755102,
    ], // #1fe9ad 0.05004712626639565
    [
        0.17254901960784313, 0.13134355102040815, 0.9195203673469388,
        0.6708494285714286,
    ], // #21eaab 0.051469781988347174
    [0.17647058823529413, 0.13773142857142864, 0.92335, 0.6623942857142856], // #23eba8 0.052905926467821496
    [
        0.1803921568627451, 0.1445186530612244, 0.9271075918367346,
        0.6537355102040817,
    ], // #24eca6 0.054355687597025276
    [
        0.1843137254901961, 0.1517844489795919, 0.9307794693877551,
        0.644848163265306,
    ], // #26eda4 0.05581919448076125
    [
        0.18823529411764706, 0.15944991836734704, 0.9343701632653061,
        0.6357672244897958,
    ], // #28eea2 0.0572965774479256
    [
        0.19215686274509805, 0.16746518367346935, 0.9378841224489796,
        0.6265250612244898,
    ], // #2aef9f 0.058787968063113955
    [
        0.19607843137254902, 0.1758161224489795, 0.9413216326530611,
        0.6171300000000001,
    ], // #2cf09d 0.06029349913833748
    [0.2, 0.1845007755102042, 0.9446816734693878, 0.6075799999999998], // #2ff09a 0.06181330474485017
    [
        0.20392156862745098, 0.19358657142857155, 0.9479377142857144,
        0.5978665714285712,
    ], // #31f198 0.0633475202250883
    [
        0.20784313725490197, 0.20295342857142845, 0.951115469387755,
        0.5880405714285716,
    ], // #33f295 0.06489628220472285
    [
        0.21176470588235294, 0.21259081632653057, 0.9542119183673469,
        0.5781087755102041,
    ], // #36f393 0.06645972860482635
    [
        0.21568627450980393, 0.2224869387755101, 0.9572244897959183,
        0.5680787755102041,
    ], // #38f490 0.0680379986541555
    [
        0.2196078431372549, 0.23267620408163278, 0.9601323673469389,
        0.557944081632653,
    ], // #3bf48e 0.06963123290154913
    [
        0.2235294117647059, 0.24312820408163283, 0.9629441224489796,
        0.5477388979591835,
    ], // #3df58b 0.07123957322844499
    [
        0.22745098039215686, 0.2537842448979592, 0.9656677959183674,
        0.5374722040816327,
    ], // #40f689 0.07286316286151445
    [
        0.23137254901960785, 0.26463399999999987, 0.9683039999999999,
        0.5271540000000001,
    ], // #43f686 0.07450214638541716
    [
        0.23529411764705882, 0.27568081632653046, 0.9708532653061225,
        0.5168010204081634,
    ], // #46f783 0.07615666975567673
    [
        0.23921568627450981, 0.2869402040816328, 0.9732810612244898,
        0.5064176734693877,
    ], // #49f881 0.07782688031167831
    [0.24313725490196078, 0.2983453877551021, 0.975614, 0.49602273469387753], // #4cf87e 0.0795129267897898
    [
        0.24705882352941178, 0.30988322448979616, 0.9778518775510204,
        0.48562616326530594,
    ], // #4ff97b 0.08121495933660677
    [
        0.25098039215686274, 0.32154016326530604, 0.979994693877551,
        0.4752381224489797,
    ], // #51f979 0.08293312952232376
    [
        0.2549019607843137, 0.33331979591836725, 0.9820216326530612,
        0.4648773469387756,
    ], // #54fa76 0.08466759035423188
    [
        0.25882352941176473, 0.3451980000000001, 0.9839368571428572,
        0.4545559999999999,
    ], // #58fa73 0.08641849629034432
    [
        0.2627450980392157, 0.3571469795918368, 0.9857526530612245,
        0.4442813061224489,
    ], // #5bfb71 0.08818600325315146
    [
        0.26666666666666666, 0.3691544081632652, 0.9874677959183673,
        0.43406342857142866,
    ], // #5efb6e 0.08997026864350596
    [
        0.27058823529411763, 0.38120689795918355, 0.9890815510204082,
        0.42391314285714293,
    ], // #61fc6c 0.09177145135463985
    [
        0.27450980392156865, 0.3932981632653063, 0.9905557142857143,
        0.41387142857142845,
    ], // #64fc69 0.09358971178631417
    [
        0.2784313725490196, 0.40538963265306116, 0.9919236734693877,
        0.4039191020408164,
    ], // #67fc66 0.09542521185910319
    [
        0.2823529411764706, 0.4174744081632653, 0.993185387755102,
        0.3940729795918367,
    ], // #6afd64 0.09727811502881356
    [
        0.28627450980392155, 0.4295434285714285, 0.9943408571428571,
        0.38434428571428575,
    ], // #6dfd62 0.09914858630104087
    [
        0.2901960784313726, 0.4415725306122451, 0.995369918367347,
        0.3747553061224488,
    ], // #70fd5f 0.10103679224586334
    [
        0.29411764705882354, 0.453534285714286, 0.9962642857142857,
        0.3653346938775508,
    ], // #73fe5d 0.10294290101267556
    [
        0.2980392156862745, 0.46543106122448974, 0.9970468163265307,
        0.35606746938775513,
    ], // #76fe5a 0.10486708234516262
    [
        0.30196078431372547, 0.47724910204081605, 0.9977198367346939,
        0.34696146938775535,
    ], // #79fe58 0.106809507596416
    [
        0.3058823529411765, 0.48897159183673466, 0.9982862448979591,
        0.3380234693877552,
    ], // #7cfe56 0.10877034974419345
    [
        0.30980392156862746, 0.5005329387755104, 0.9986838367346939,
        0.32933102040816314,
    ], // #7ffe53 0.11074978340632219
    [
        0.3137254901960784, 0.5119628571428574, 0.9989671428571428,
        0.3208442857142855,
    ], // #82fe51 0.11274798485625029
    [
        0.3176470588235294, 0.5232501224489795, 0.9991315918367347,
        0.31256587755102044,
    ], // #85fe4f 0.11476513203874303
    [
        0.3215686274509804, 0.5343824897959181, 0.9991743265306122,
        0.30450387755102065,
    ], // #88fe4d 0.11680140458573035
    [
        0.3254901960784314, 0.545320163265306, 0.9990807755102041,
        0.29670795918367354,
    ], // #8bfe4b 0.11885698383230264
    [
        0.32941176470588235, 0.5560164081632655, 0.9988354693877551,
        0.2892017551020407,
    ], // #8dfe49 0.12093205283285977
    [
        0.3333333333333333, 0.5665075510204083, 0.9984671428571429,
        0.2819538775510203,
    ], // #90fe47 0.12302679637741212
    [
        0.33725490196078434, 0.5767793469387757, 0.9979757959183674,
        0.2749713061224488,
    ], // #93fe46 0.1251414010080368
    [
        0.3411764705882353, 0.5868157142857142, 0.9973611428571428,
        0.2682582857142857,
    ], // #95fe44 0.12727605503548955
    [
        0.34509803921568627, 0.5965216326530612, 0.9965737551020408,
        0.26193579591836735,
    ], // #98fe42 0.12943094855597417
    [
        0.34901960784313724, 0.6059454285714287, 0.9956511836734694,
        0.2559248163265305,
    ], // #9afd41 0.13160627346807174
    [
        0.35294117647058826, 0.6150861224489796, 0.9945963265306123,
        0.2502248979591836,
    ], // #9cfd3f 0.1338022234898284
    [
        0.3568627450980392, 0.6239316326530613, 0.9934087346938776,
        0.2448466530612245,
    ], // #9ffd3e 0.1360189941760077
    [
        0.3607843137254902, 0.6324291836734693, 0.9920756326530612,
        0.23984167346938776,
    ], // #a1fc3d 0.13825678293550456
    [
        0.36470588235294116, 0.640566612244898, 0.9905660000000001,
        0.23526742857142857,
    ], // #a3fc3b 0.140515789048925
    [
        0.3686274509803922, 0.6486325714285713, 0.9889020000000001,
        0.2310294285714286,
    ], // #a5fc3a 0.14279621368633294
    [
        0.37254901960784315, 0.6566832653061225, 0.987084081632653,
        0.22711632653061223,
    ], // #a7fb39 0.14509825992516437
    [
        0.3764705882352941, 0.6647442448979591, 0.9851151836734694,
        0.22351591836734694,
    ], // #a9fb38 0.14742213276831256
    [
        0.3803921568627451, 0.672805224489796, 0.982947918367347,
        0.2203195918367347,
    ], // #abfa38 0.14976803916238332
    [
        0.3843137254901961, 0.6808541224489796, 0.9806175510204082,
        0.21743738775510202,
    ], // #adfa37 0.1521361880161249
    [
        0.38823529411764707, 0.6888956734693876, 0.9781400408163266,
        0.21483828571428573,
    ], // #aff936 0.15452679021903112
    [
        0.39215686274509803, 0.6969283673469386, 0.9755204081632654,
        0.21251224489795922,
    ], // #b1f836 0.15694005866012192
    [
        0.396078431372549, 0.7049425714285714, 0.9727528571428572,
        0.2104697142857143,
    ], // #b3f835 0.1593762082469017
    [0.4, 0.7129278775510206, 0.969804693877551, 0.20875228571428567], // #b5f735 0.1618354559244974
    [
        0.403921568627451, 0.7208857959183677, 0.9667167346938774,
        0.20727142857142852,
    ], // #b7f634 0.16431802069497783
    [
        0.40784313725490196, 0.7288157551020409, 0.9634974285714286,
        0.20601697959183674,
    ], // #b9f534 0.1668241236368562
    [
        0.4117647058823529, 0.7367165306122447, 0.9601544897959184,
        0.20498000000000002,
    ], // #bbf434 0.1693539879247782
    [
        0.41568627450980394, 0.7445670204081632, 0.9566463265306122,
        0.20421599999999998,
    ], // #bdf334 0.17190783884939584
    [
        0.4196078431372549, 0.7523740816326533, 0.9530016734693877,
        0.20366914285714285,
    ], // #bff333 0.1744859038374302
    [0.4235294117647059, 0.7601340000000001, 0.9492339999999999, 0.203302], // #c1f233 0.17708841247192456
    [
        0.42745098039215684, 0.7678453877551019, 0.9453468979591837,
        0.20310820408163266,
    ], // #c3f133 0.179715596512689
    [
        0.43137254901960786, 0.7755087755102039, 0.9413397959183675,
        0.2031004081632653,
    ], // #c5f033 0.18236768991693966
    [
        0.43529411764705883, 0.7830908979591837, 0.9371849387755102,
        0.20329208163265308,
    ], // #c7ee33 0.18504492886013243
    [
        0.4392156862745098, 0.7906115102040818, 0.9329182448979592,
        0.2036191836734694,
    ], // #c9ed33 0.187747551756996
    [
        0.44313725490196076, 0.7980676326530612, 0.928542693877551,
        0.20407751020408166,
    ], // #cbec34 0.19047579928276218
    [
        0.4470588235294118, 0.8054566530612245, 0.9240616734693877,
        0.2046621224489796,
    ], // #cdeb34 0.19322991439459972
    [
        0.45098039215686275, 0.8127614285714286, 0.9194542857142857,
        0.20538714285714288,
    ], // #cfea34 0.19601014235324912
    [
        0.4549019607843137, 0.8199770612244899, 0.9147386530612245,
        0.2062267755102041,
    ], // #d1e934 0.19881673074486464
    [
        0.4588235294117647, 0.8271102448979591, 0.9099237959183674,
        0.2071504081632653,
    ], // #d2e834 0.20164992950306201
    [
        0.4627450980392157, 0.8341575918367348, 0.905014163265306,
        0.20815036734693879,
    ], // #d4e635 0.20450999093117558
    [
        0.4666666666666667, 0.8411115510204082, 0.9000169795918367,
        0.20922620408163267,
    ], // #d6e535 0.2073971697247269
    [
        0.47058823529411764, 0.8479522448979593, 0.8948914285714284,
        0.21037755102040817,
    ], // #d8e435 0.21031172299410542
    [
        0.4745098039215686, 0.8546876734693878, 0.8896815102040815,
        0.2115741224489796,
    ], // #d9e235 0.21325391028746568
    [
        0.47843137254901963, 0.8613231428571428, 0.8843908571428571,
        0.21280599999999997,
    ], // #dbe136 0.21622399361383968
    [
        0.4823529411764706, 0.8678607346938775, 0.879021918367347,
        0.21406395918367344,
    ], // #dde036 0.21922223746647015
    [
        0.48627450980392156, 0.8742611836734694, 0.8735569795918366,
        0.21534248979591836,
    ], // #dede36 0.2222489088463642
    [
        0.49019607843137253, 0.8805310204081633, 0.8680071428571428,
        0.21662102040816328,
    ], // #e0dd37 0.22530427728607003
    [
        0.49411764705882355, 0.8866890204081632, 0.862384612244898,
        0.21788657142857143,
    ], // #e2db37 0.22838861487368037
    [
        0.4980392156862745, 0.8927288163265307, 0.8566915102040816,
        0.2191353469387755,
    ], // #e3da37 0.231502196277061
    [
        0.5019607843137255, 0.8986381224489796, 0.8509303265306124,
        0.2203671020408163,
    ], // #e5d838 0.23464529876831272
    [
        0.5058823529411764, 0.9043700000000001, 0.8450842857142856,
        0.22153714285714285,
    ], // #e6d738 0.2378182022484611
    [
        0.5098039215686274, 0.9099732653061227, 0.8391767346938773,
        0.2226514285714286,
    ], // #e8d538 0.24102118927238383
    [
        0.5137254901960784, 0.9154413061224491, 0.8332142857142856,
        0.22370318367346942,
    ], // #e9d439 0.24425454507397185
    [
        0.5176470588235295, 0.920769551020408, 0.8271993061224491,
        0.2246856326530612,
    ], // #ead239 0.24751855759153116
    [
        0.5215686274509804, 0.9259304489795918, 0.8211106938775511,
        0.22557436734693878,
    ], // #ecd139 0.2508135174934234
    [
        0.5254901960784314, 0.930910612244898, 0.8149685306122448,
        0.226349306122449,
    ], // #edcf39 0.2541397182039513
    [
        0.5294117647058824, 0.9357397959183672, 0.8087816326530614,
        0.22701571428571427,
    ], // #eece39 0.2574974559294887
    [0.5333333333333333, 0.9404157142857141, 0.8025500000000002, 0.227568], // #efcc3a 0.2608870296848582
    [
        0.5372549019607843, 0.9449348979591835, 0.7962737959183677,
        0.2280025306122449,
    ], // #f0cb3a 0.26430874131995963
    [
        0.5411764705882353, 0.9492226530612246, 0.7899513061224488,
        0.22824420408163268,
    ], // #f2c93a 0.2677628955466499
    [
        0.5450980392156862, 0.9533458775510205, 0.7835891020408161,
        0.2283383673469388,
    ], // #f3c73a 0.27124979996587884
    [
        0.5490196078431373, 0.957296530612245, 0.7771912244897957,
        0.22827326530612246,
    ], // #f4c63a 0.2747697650950819
    [
        0.5529411764705883, 0.9610695102040817, 0.7707610612244895,
        0.2280378775510204,
    ], // #f5c43a 0.27832310439583163
    [
        0.5568627450980392, 0.9646400408163266, 0.7642982448979591,
        0.2275935102040816,
    ], // #f5c23a 0.2819101343017535
    [0.5607843137254902, 0.9679774285714285, 0.757808, 0.226916], // #f6c139 0.28553117424670416
    [
        0.5647058823529412, 0.9711339999999998, 0.7512953877551024,
        0.22603906122448986,
    ], // #f7bf39 0.28918654669321797
    [
        0.5686274509803921, 0.9741018367346939, 0.7447628571428574,
        0.2249510204081633,
    ], // #f8bd39 0.2928765771612234
    [
        0.5725490196078431, 0.976868612244898, 0.7382137959183673,
        0.22363906122448982,
    ], // #f9bc39 0.29660159425703103
    [
        0.5764705882352941, 0.9793711020408163, 0.7316496326530612,
        0.22201751020408161,
    ], // #f9ba38 0.30036192970259723
    [
        0.5803921568627451, 0.9816868163265307, 0.7249804081632651,
        0.2201916734693877,
    ], // #fab838 0.304157918365064
    [
        0.5843137254901961, 0.9838344897959185, 0.71816693877551,
        0.21820644897959177,
    ], // #fab737 0.3079898982865805
    [
        0.5882352941176471, 0.9858214285714286, 0.7111857142857141,
        0.21608285714285708,
    ], // #fbb537 0.3118582107144062
    [
        0.592156862745098, 0.987630081632653, 0.7040134693877552,
        0.21380644897959186,
    ], // #fbb336 0.31576320013129966
    [
        0.596078431372549, 0.9892491836734694, 0.6966446530612244,
        0.2113685714285714,
    ], // #fcb135 0.31970521428619625
    [0.6, 0.9907124897959183, 0.6891288979591841, 0.2088068979591838], // #fcaf35 0.32368460422517487
    [
        0.6039215686274509, 0.9920244489795919, 0.6814677551020412,
        0.2061248163265307,
    ], // #fcad34 0.3277017243227205
    [
        0.6078431372549019, 0.993190612244898, 0.673662857142857,
        0.20332612244897952,
    ], // #fdab33 0.33175693231328185
    [
        0.611764705882353, 0.994157306122449, 0.6656954285714286,
        0.2003870612244898,
    ], // #fda933 0.3358505893231283
    [0.615686274509804, 0.994962, 0.6575899999999999, 0.19734599999999997], // #fda732 0.3399830599025089
    [
        0.6196078431372549, 0.995623306122449, 0.6493711428571426,
        0.19420608163265296,
    ], // #fda531 0.3441547120581172
    [
        0.6235294117647059, 0.9961448163265306, 0.6410444489795915,
        0.19097028571428556,
    ], // #fea330 0.3483659172858625
    [
        0.6274509803921569, 0.9965112244897959, 0.632593673469388,
        0.18764142857142865,
    ], // #fea12f 0.3526170506039529
    [
        0.6313725490196078, 0.9966908571428572, 0.6240279591836733,
        0.1842119999999999,
    ], // #fe9f2e 0.3569084905862909
    [
        0.6352941176470588, 0.9967350612244898, 0.6153727755102038,
        0.18070428571428557,
    ], // #fe9c2e 0.36124061939618735
    [
        0.6392156862745098, 0.9966422857142857, 0.6066308979591839,
        0.17712661224489804,
    ], // #fe9a2d 0.36561382282039323
    [
        0.6431372549019608, 0.9964108571428572, 0.5978068571428572,
        0.17348742857142857,
    ], // #fe982c 0.3700284903034553
    [
        0.6470588235294118, 0.9960132653061224, 0.5888961224489795,
        0.169768775510204,
    ], // #fd962b 0.37448501498239684
    [
        0.6509803921568628, 0.9954615918367347, 0.5799175918367346,
        0.16598848979591832,
    ], // #fd932a 0.37898379372172775
    [0.6549019607843137, 0.994782, 0.570885469387755, 0.16216416326530608], // #fd9129 0.38352522714878623
    [
        0.6588235294117647, 0.9939758775510203, 0.561803020408163,
        0.15830069387755089,
    ], // #fd8f28 0.3881097196894153
    [
        0.6627450980392157, 0.9930247755102041, 0.5526662040816327,
        0.15439493877551022,
    ], // #fd8c27 0.39273767960397865
    [
        0.6666666666666666, 0.9919120408163266, 0.5434853061224492,
        0.15044510204081643,
    ], // #fc8a26 0.39740951902371635
    [
        0.6705882352941176, 0.9906762857142857, 0.5342811428571427,
        0.1464668571428571,
    ], // #fc8825 0.40212565398744704
    [
        0.6745098039215687, 0.9893175102040817, 0.5250516734693879,
        0.14246653061224493,
    ], // #fc8524 0.4068865044786163
    [
        0.6784313725490196, 0.9878364489795919, 0.5157971836734696,
        0.13845089795918378,
    ], // #fb8323 0.41169249446269784
    [
        0.6823529411764706, 0.9862071020408163, 0.5065356326530612,
        0.13442820408163267,
    ], // #fb8122 0.4165440519249486
    [
        0.6862745098039216, 0.9844448979591837, 0.4972740816326529,
        0.13039285714285706,
    ], // #fb7e21 0.42144160890852156
    [
        0.6901960784313725, 0.9825611428571428, 0.4880166530612243,
        0.12635869387755094,
    ], // #fa7c20 0.4263856015529409
    [
        0.6941176470588235, 0.9805601224489795, 0.47876865306122435,
        0.12233204081632647,
    ], // #fa7a1f 0.43137647013294045
    [
        0.6980392156862745, 0.9784474285714285, 0.46953828571428546,
        0.11831714285714275,
    ], // #f9771e 0.43641465909767196
    [
        0.7019607843137254, 0.9761770204081632, 0.4603379591836734,
        0.11432477551020405,
    ], // #f8751d 0.441500617110284
    [
        0.7058823529411765, 0.9737997959183673, 0.4511653061224488,
        0.1103544897959183,
    ], // #f8731c 0.4466347970878767
    [
        0.7098039215686275, 0.9713163265306123, 0.4420330204081634,
        0.10640967346938782,
    ], // #f7701b 0.4518176562418347
    [
        0.7137254901960784, 0.968726612244898, 0.4329501632653061,
        0.10249461224489796,
    ], // #f76e1a 0.45704965611854365
    [
        0.7176470588235294, 0.966005836734694, 0.42392248979591857,
        0.09862783673469395,
    ], // #f66c19 0.46233126264049185
    [
        0.7215686274509804, 0.9631617551020408, 0.4149597142857144,
        0.09481297959183675,
    ], // #f56918 0.46766294614776127
    [
        0.7254901960784313, 0.9602157142857143, 0.40606571428571414,
        0.0910471428571428,
    ], // #f46717 0.47304518143991325
    [
        0.7294117647058823, 0.9571711020408162, 0.39724440816326506,
        0.0873337142857142,
    ], // #f46516 0.47847844781826987
    [
        0.7333333333333333, 0.9540293469387755, 0.388497387755102,
        0.08367742857142856,
    ], // #f36315 0.48396322912859746
    [
        0.7372549019607844, 0.9507494693877551, 0.379865469387755,
        0.08011322448979587,
    ], // #f26014 0.4895000138041943
    [
        0.7411764705882353, 0.9473689387755101, 0.3713228979591835,
        0.07662159183673463,
    ], // #f15e13 0.4950892949093857
    [
        0.7450980392156863, 0.9438975510204083, 0.362879591836735,
        0.07320591836734706,
    ], // #f05c12 0.5007315701834343
    [
        0.7490196078431373, 0.9403391020408164, 0.35454253061224505,
        0.06986959183673475,
    ], // #ef5a11 0.5064273420848638
    [
        0.7529411764705882, 0.9366594285714285, 0.3463334285714285,
        0.0666342857142857,
    ], // #ee5810 0.5121771178362046
    [
        0.7568627450980392, 0.9328799591836735, 0.3382706938775511,
        0.0635120816326531,
    ], // #ed5610 0.5179814094691628
    [
        0.7607843137254902, 0.929016612244898, 0.3303309795918366,
        0.06048493877551016,
    ], // #ec540f 0.5238407338702185
    [
        0.7647058823529411, 0.9250671428571426, 0.32252102040816283,
        0.05755734693877535,
    ], // #eb520e 0.5297556128266552
    [
        0.7686274509803922, 0.9210282448979591, 0.3148508571428569,
        0.05473608163265297,
    ], // #ea500d 0.5357265730730268
    [
        0.7725490196078432, 0.9168730204081632, 0.30737457142857144,
        0.05207767346938775,
    ], // #e94e0d 0.541754146338064
    [
        0.7764705882352941, 0.9126333061224489, 0.3000559999999998,
        0.049537551020408105,
    ], // #e84c0c 0.5478388693920265
    [
        0.7803921568627451, 0.9083165714285716, 0.29289400000000015,
        0.04711828571428578,
    ], // #e74a0c 0.5539812840945038
    [
        0.7843137254901961, 0.9039259183673471, 0.28589224489795945,
        0.04482571428571437,
    ], // #e6480b 0.5601819374426686
    [
        0.788235294117647, 0.8994432653061225, 0.27909420408163277,
        0.042697428571428615,
    ], // #e5470a 0.5664413816199891
    [
        0.792156862745098, 0.8948583673469388, 0.27249383673469385,
        0.04072591836734694,
    ], // #e4450a 0.5727601740454012
    [
        0.796078431372549, 0.8901803673469386, 0.26598644897959167,
        0.03882306122448975,
    ], // #e24309 0.579138877422949
    [0.8, 0.8854048979591834, 0.2595501224489793, 0.0369713061224489], // #e14209 0.5855780597918946
    [
        0.803921568627451, 0.8805285714285711, 0.25317510204081595,
        0.0351642857142856,
    ], // #e04008 0.5920782945773028
    [
        0.807843137254902, 0.8755080000000001, 0.24687600000000007,
        0.03341800000000002,
    ], // #df3e08 0.5986401606411073
    [
        0.8117647058823529, 0.8703831020408164, 0.24064644897959184,
        0.03172387755102041,
    ], // #dd3d08 0.6052642423336588
    [
        0.8156862745098039, 0.8651539591836737, 0.2344804081632655,
        0.030076489795918423,
    ], // #dc3b07 0.6119511295457633
    [
        0.8196078431372549, 0.8598199591836736, 0.22837448979591848,
        0.028475224489795947,
    ], // #db3a07 0.6187014177612129
    [
        0.8235294117647058, 0.8543706122448982, 0.22233265306122474,
        0.026931632653061286,
    ], // #d93806 0.6255157081098155
    [
        0.8274509803921568, 0.8487886530612244, 0.21637457142857136,
        0.025443877551020386,
    ], // #d83706 0.6323946074209268
    [
        0.8313725490196079, 0.8431052244897957, 0.21047404081632637,
        0.024000857142857105,
    ], // #d63506 0.63933872827749
    [
        0.8352941176470589, 0.8373211428571428, 0.20463199999999987,
        0.022602571428571402,
    ], // #d53405 0.6463486890705872
    [
        0.8392156862745098, 0.8314360408163263, 0.19885183673469364,
        0.021249387755101986,
    ], // #d43205 0.6534251140545096
    [
        0.8431372549019608, 0.8254097959183673, 0.19316081632653057,
        0.019963061224489785,
    ], // #d23105 0.6605686334023486
    [
        0.8470588235294118, 0.8192762857142858, 0.18752938775510206,
        0.01872440816326531,
    ], // #d02f04 0.6677798832621137
    [
        0.8509803921568627, 0.8130421224489799, 0.18195763265306142,
        0.017530489795918407,
    ], // #cf2e04 0.6750595058133837
    [
        0.8549019607843137, 0.8067057551020408, 0.176447387755102,
        0.016382857142857133,
    ], // #cd2c04 0.6824081493244942
    [
        0.8588235294117647, 0.8002507346938776, 0.17100583673469394,
        0.015291428571428583,
    ], // #cc2b03 0.6898264682102674
    [
        0.8627450980392157, 0.7936671428571428, 0.1656428571428571,
        0.014249999999999992,
    ], // #ca2a03 0.6973151230902895
    [
        0.8666666666666667, 0.7869779591836733, 0.1603463265306121,
        0.013262530612244871,
    ], // #c82803 0.7048747808477398
    [
        0.8705882352941177, 0.7801852653061222, 0.15511142857142837,
        0.01232277551020405,
    ], // #c62703 0.7125061146887796
    [
        0.8745098039215686, 0.7732919183673468, 0.14993297959183663,
        0.011423224489795904,
    ], // #c52602 0.7202098042025018
    [
        0.8784313725490196, 0.7662677959183675, 0.1448344489795919,
        0.010589061224489809,
    ], // #c32402 0.7279865354214506
    [
        0.8823529411764706, 0.7591273469387755, 0.13980326530612242,
        0.009803877551020404,
    ], // #c12302 0.7358370008827139
    [
        0.8862745098039215, 0.7518806530612244, 0.1348296734693877,
        0.009063428571428566,
    ], // #bf2202 0.7437618996895956
    [
        0.8901960784313725, 0.7445311428571433, 0.12991542857142882,
        0.008369428571428604,
    ], // #bd2102 0.7517619375738729
    [
        0.8941176470588236, 0.737078244897959, 0.12507416326530607,
        0.007730163265306113,
    ], // #bb1f01 0.7598378269586428
    [
        0.8980392156862745, 0.7294865306122449, 0.12031326530612245,
        0.0071493469387755105,
    ], // #ba1e01 0.7679902870217652
    [
        0.9019607843137255, 0.7217885714285713, 0.11561387755102039,
        0.006613265306122446,
    ], // #b81d01 0.7762200437599082
    [
        0.9058823529411765, 0.7139872653061222, 0.11097599999999988,
        0.006121918367346926,
    ], // #b61c01 0.784527830053199
    [
        0.9098039215686274, 0.7060853061224486, 0.10639893877551,
        0.005675999999999982,
    ], // #b41b01 0.7929143857304894
    [
        0.9137254901960784, 0.6980555102040819, 0.10189289795918381,
        0.005294000000000011,
    ], // #b21901 0.8013804576352388
    [
        0.9176470588235294, 0.6899125714285713, 0.09746228571428564,
        0.004962285714285709,
    ], // #af1801 0.8099267996920226
    [
        0.9215686274509803, 0.6816648979591834, 0.0930906122448978,
        0.004679387755102031,
    ], // #ad1701 0.8185541729736715
    [
        0.9254901960784314, 0.6733109795918369, 0.08877861224489808,
        0.004443061224489802,
    ], // #ab1601 0.8272633457690465
    [
        0.9294117647058824, 0.664844612244898, 0.08453763265306126,
        0.0042481632653061235,
    ], // #a91501 0.8360550936514561
    [
        0.9333333333333333, 0.6562437959183672, 0.08037134693877546,
        0.004120489795918366,
    ], // #a71401 0.8449301995477252
    [
        0.9372549019607843, 0.6475423265306122, 0.07627151020408161,
        0.004043142857142856,
    ], // #a51301 0.8538894538079145
    [0.9411764705882353, 0.6387371428571428, 0.07223265306122448, 0.00401], // #a21201 0.8629336542757042
    [
        0.9450980392156862, 0.6298265714285712, 0.06825057142857134,
        0.004017714285714287,
    ], // #a01101 0.8720636063594438
    [
        0.9490196078431372, 0.6207911020408163, 0.06434481632653065,
        0.004087877551020407,
    ], // #9e1001 0.8812801231038756
    [
        0.9529411764705882, 0.611639551020408, 0.06051644897959178,
        0.004209632653061226,
    ], // #9b0f01 0.8905840252625381
    [
        0.9568627450980393, 0.602383102040816, 0.056746693877550894,
        0.004380367346938783,
    ], // #990e01 0.8999761413708569
    [
        0.9607843137254902, 0.5930224489795919, 0.05303489795918371,
        0.004597346938775508,
    ], // #970d01 0.9094573078199265
    [
        0.9647058823529412, 0.583558204081633, 0.049386408163265415,
        0.004854612244897951,
    ], // #940c01 0.9190283689309945
    [
        0.9686274509803922, 0.5739489387755103, 0.04582187755102044,
        0.00518820408163265,
    ], // #920b01 0.9286901770306494
    [
        0.9725490196078431, 0.564238857142857, 0.0423188571428571,
        0.0055614285714285765,
    ], // #8f0a01 0.9384435925267228
    [
        0.9764705882352941, 0.5544281224489794, 0.03887734693877545,
        0.005980244897959191,
    ], // #8d0901 0.9482894839849115
    [
        0.9803921568627451, 0.5445157142857142, 0.03549734693877549,
        0.006448367346938779,
    ], // #8a0901 0.9582287282061234
    [
        0.984313725490196, 0.5344745714285708, 0.032191877551020175,
        0.006970693877551057,
    ], // #880801 0.9682622103045614
    [
        0.9882352941176471, 0.5243144081632652, 0.028959142857142833,
        0.007545918367346943,
    ], // #850701 0.978390823786543
    [
        0.9921568627450981, 0.5140491836734692, 0.025787918367346903,
        0.008165877551020415,
    ], // #830602 0.9886154706300695
    [
        0.996078431372549, 0.5036799183673472, 0.02267820408163272,
        0.008832775510204066,
    ], // #800502 0.998937061365151
    [1.0, 0.49321, 0.01963, 0.00955], // #7d0502 1.0093565151548891
];
export const TurboColorMapOpacityLevelsSHIFTED = [
    [0.0, 0.0],
    [0.00392156862745098, 1.0],
    [0.00784313725490196, 1.0],
    [0.011764705882352941, 1.0],
    [0.01568627450980392, 1.0],
    [0.0196078431372549, 1.0],
    [0.023529411764705882, 1.0],
    [0.027450980392156862, 1.0],
    [0.03137254901960784, 1.0],
    [0.03529411764705882, 1.0],
    [0.0392156862745098, 1.0],
    [0.043137254901960784, 1.0],
    [0.047058823529411764, 1.0],
    [0.050980392156862744, 1.0],
    [0.054901960784313725, 1.0],
    [0.058823529411764705, 1.0],
    [0.06274509803921569, 1.0],
    [0.06666666666666667, 1.0],
    [0.07058823529411765, 1.0],
    [0.07450980392156863, 1.0],
    [0.0784313725490196, 1.0],
    [0.08235294117647059, 1.0],
    [0.08627450980392157, 1.0],
    [0.09019607843137255, 1.0],
    [0.09411764705882353, 1.0],
    [0.09803921568627451, 1.0],
    [0.10196078431372549, 1.0],
    [0.10588235294117647, 1.0],
    [0.10980392156862745, 1.0],
    [0.11372549019607843, 1.0],
    [0.11764705882352941, 1.0],
    [0.12156862745098039, 1.0],
    [0.12549019607843137, 1.0],
    [0.12941176470588237, 1.0],
    [0.13333333333333333, 1.0],
    [0.13725490196078433, 1.0],
    [0.1411764705882353, 1.0],
    [0.1450980392156863, 1.0],
    [0.14901960784313725, 1.0],
    [0.15294117647058825, 1.0],
    [0.1568627450980392, 1.0],
    [0.1607843137254902, 1.0],
    [0.16470588235294117, 1.0],
    [0.16862745098039217, 1.0],
    [0.17254901960784313, 1.0],
    [0.17647058823529413, 1.0],
    [0.1803921568627451, 1.0],
    [0.1843137254901961, 1.0],
    [0.18823529411764706, 1.0],
    [0.19215686274509805, 1.0],
    [0.19607843137254902, 1.0],
    [0.2, 1.0],
    [0.20392156862745098, 1.0],
    [0.20784313725490197, 1.0],
    [0.21176470588235294, 1.0],
    [0.21568627450980393, 1.0],
    [0.2196078431372549, 1.0],
    [0.2235294117647059, 1.0],
    [0.22745098039215686, 1.0],
    [0.23137254901960785, 1.0],
    [0.23529411764705882, 1.0],
    [0.23921568627450981, 1.0],
    [0.24313725490196078, 1.0],
    [0.24705882352941178, 1.0],
    [0.25098039215686274, 1.0],
    [0.2549019607843137, 1.0],
    [0.25882352941176473, 1.0],
    [0.2627450980392157, 1.0],
    [0.26666666666666666, 1.0],
    [0.27058823529411763, 1.0],
    [0.27450980392156865, 1.0],
    [0.2784313725490196, 1.0],
    [0.2823529411764706, 1.0],
    [0.28627450980392155, 1.0],
    [0.2901960784313726, 1.0],
    [0.29411764705882354, 1.0],
    [0.2980392156862745, 1.0],
    [0.30196078431372547, 1.0],
    [0.3058823529411765, 1.0],
    [0.30980392156862746, 1.0],
    [0.3137254901960784, 1.0],
    [0.3176470588235294, 1.0],
    [0.3215686274509804, 1.0],
    [0.3254901960784314, 1.0],
    [0.32941176470588235, 1.0],
    [0.3333333333333333, 1.0],
    [0.33725490196078434, 1.0],
    [0.3411764705882353, 1.0],
    [0.34509803921568627, 1.0],
    [0.34901960784313724, 1.0],
    [0.35294117647058826, 1.0],
    [0.3568627450980392, 1.0],
    [0.3607843137254902, 1.0],
    [0.36470588235294116, 1.0],
    [0.3686274509803922, 1.0],
    [0.37254901960784315, 1.0],
    [0.3764705882352941, 1.0],
    [0.3803921568627451, 1.0],
    [0.3843137254901961, 1.0],
    [0.38823529411764707, 1.0],
    [0.39215686274509803, 1.0],
    [0.396078431372549, 1.0],
    [0.4, 1.0],
    [0.403921568627451, 1.0],
    [0.40784313725490196, 1.0],
    [0.4117647058823529, 1.0],
    [0.41568627450980394, 1.0],
    [0.4196078431372549, 1.0],
    [0.4235294117647059, 1.0],
    [0.42745098039215684, 1.0],
    [0.43137254901960786, 1.0],
    [0.43529411764705883, 1.0],
    [0.4392156862745098, 1.0],
    [0.44313725490196076, 1.0],
    [0.4470588235294118, 1.0],
    [0.45098039215686275, 1.0],
    [0.4549019607843137, 1.0],
    [0.4588235294117647, 1.0],
    [0.4627450980392157, 1.0],
    [0.4666666666666667, 1.0],
    [0.47058823529411764, 1.0],
    [0.4745098039215686, 1.0],
    [0.47843137254901963, 1.0],
    [0.4823529411764706, 1.0],
    [0.48627450980392156, 1.0],
    [0.49019607843137253, 1.0],
    [0.49411764705882355, 1.0],
    [0.4980392156862745, 1.0],
    [0.5019607843137255, 1.0],
    [0.5058823529411764, 1.0],
    [0.5098039215686274, 1.0],
    [0.5137254901960784, 1.0],
    [0.5176470588235295, 1.0],
    [0.5215686274509804, 1.0],
    [0.5254901960784314, 1.0],
    [0.5294117647058824, 1.0],
    [0.5333333333333333, 1.0],
    [0.5372549019607843, 1.0],
    [0.5411764705882353, 1.0],
    [0.5450980392156862, 1.0],
    [0.5490196078431373, 1.0],
    [0.5529411764705883, 1.0],
    [0.5568627450980392, 1.0],
    [0.5607843137254902, 1.0],
    [0.5647058823529412, 1.0],
    [0.5686274509803921, 1.0],
    [0.5725490196078431, 1.0],
    [0.5764705882352941, 1.0],
    [0.5803921568627451, 1.0],
    [0.5843137254901961, 1.0],
    [0.5882352941176471, 1.0],
    [0.592156862745098, 1.0],
    [0.596078431372549, 1.0],
    [0.6, 1.0],
    [0.6039215686274509, 1.0],
    [0.6078431372549019, 1.0],
    [0.611764705882353, 1.0],
    [0.615686274509804, 1.0],
    [0.6196078431372549, 1.0],
    [0.6235294117647059, 1.0],
    [0.6274509803921569, 1.0],
    [0.6313725490196078, 1.0],
    [0.6352941176470588, 1.0],
    [0.6392156862745098, 1.0],
    [0.6431372549019608, 1.0],
    [0.6470588235294118, 1.0],
    [0.6509803921568628, 1.0],
    [0.6549019607843137, 1.0],
    [0.6588235294117647, 1.0],
    [0.6627450980392157, 1.0],
    [0.6666666666666666, 1.0],
    [0.6705882352941176, 1.0],
    [0.6745098039215687, 1.0],
    [0.6784313725490196, 1.0],
    [0.6823529411764706, 1.0],
    [0.6862745098039216, 1.0],
    [0.6901960784313725, 1.0],
    [0.6941176470588235, 1.0],
    [0.6980392156862745, 1.0],
    [0.7019607843137254, 1.0],
    [0.7058823529411765, 1.0],
    [0.7098039215686275, 1.0],
    [0.7137254901960784, 1.0],
    [0.7176470588235294, 1.0],
    [0.7215686274509804, 1.0],
    [0.7254901960784313, 1.0],
    [0.7294117647058823, 1.0],
    [0.7333333333333333, 1.0],
    [0.7372549019607844, 1.0],
    [0.7411764705882353, 1.0],
    [0.7450980392156863, 1.0],
    [0.7490196078431373, 1.0],
    [0.7529411764705882, 1.0],
    [0.7568627450980392, 1.0],
    [0.7607843137254902, 1.0],
    [0.7647058823529411, 1.0],
    [0.7686274509803922, 1.0],
    [0.7725490196078432, 1.0],
    [0.7764705882352941, 1.0],
    [0.7803921568627451, 1.0],
    [0.7843137254901961, 1.0],
    [0.788235294117647, 1.0],
    [0.792156862745098, 1.0],
    [0.796078431372549, 1.0],
    [0.8, 1.0],
    [0.803921568627451, 1.0],
    [0.807843137254902, 1.0],
    [0.8117647058823529, 1.0],
    [0.8156862745098039, 1.0],
    [0.8196078431372549, 1.0],
    [0.8235294117647058, 1.0],
    [0.8274509803921568, 1.0],
    [0.8313725490196079, 1.0],
    [0.8352941176470589, 1.0],
    [0.8392156862745098, 1.0],
    [0.8431372549019608, 1.0],
    [0.8470588235294118, 1.0],
    [0.8509803921568627, 1.0],
    [0.8549019607843137, 1.0],
    [0.8588235294117647, 1.0],
    [0.8627450980392157, 1.0],
    [0.8666666666666667, 1.0],
    [0.8705882352941177, 1.0],
    [0.8745098039215686, 1.0],
    [0.8784313725490196, 1.0],
    [0.8823529411764706, 1.0],
    [0.8862745098039215, 1.0],
    [0.8901960784313725, 1.0],
    [0.8941176470588236, 1.0],
    [0.8980392156862745, 1.0],
    [0.9019607843137255, 1.0],
    [0.9058823529411765, 1.0],
    [0.9098039215686274, 1.0],
    [0.9137254901960784, 1.0],
    [0.9176470588235294, 1.0],
    [0.9215686274509803, 1.0],
    [0.9254901960784314, 1.0],
    [0.9294117647058824, 1.0],
    [0.9333333333333333, 1.0],
    [0.9372549019607843, 1.0],
    [0.9411764705882353, 1.0],
    [0.9450980392156862, 1.0],
    [0.9490196078431372, 1.0],
    [0.9529411764705882, 1.0],
    [0.9568627450980393, 1.0],
    [0.9607843137254902, 1.0],
    [0.9647058823529412, 1.0],
    [0.9686274509803922, 1.0],
    [0.9725490196078431, 1.0],
    [0.9764705882352941, 1.0],
    [0.9803921568627451, 1.0],
    [0.984313725490196, 1.0],
    [0.9882352941176471, 1.0],
    [0.9921568627450981, 1.0],
    [0.996078431372549, 1.0],
    [1.0, 1.0],
];
