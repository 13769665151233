import React, { Component } from "react";
import "./styles.css";
import { TRAINING_URL, USER_MANUAL_URL } from "../../../constants";

export default class AboutThisSoftware extends Component {
    render() {
        const envClassName =
            import.meta.env.MODE === "production" ? "prod" : "dev";

        return (
            <div className={`about-this-container-${envClassName}`}>
                <div
                    className="about-this-child-container"
                    id="manual-container"
                >
                    <div id="about-this-manual-text">
                        Download the most recent version of the User Manual for
                        this Software
                    </div>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={USER_MANUAL_URL}
                        className="about-this-button"
                    >
                        <button
                            id="user-manual-dl-btn"
                            className="about-this-button-text"
                        >
                            Download
                        </button>
                    </a>
                </div>
                <div
                    className="about-this-child-container"
                    id="training-container"
                >
                    <div id="about-this-manual-text">
                        Access Unfold AI software training resources
                    </div>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={TRAINING_URL}
                        className="about-this-button"
                        id="training-link"
                    >
                        <button
                            id="start-training-btn"
                            className="about-this-button-text"
                        >
                            Start Training
                        </button>
                    </a>
                </div>
                <div
                    className="about-this-child-container"
                    id="software-version-container"
                >
                    <div>Software Version:</div>
                    <div>{import.meta.env.VITE_UI_SOFTWARE_VERSION}</div>
                    <div>UDI:</div>
                    <div>{import.meta.env.VITE_UI_SOFTWARE_UDI}</div>
                    {envClassName === "dev" ? (
                        <>
                            <div>Lambda API Endpoint:</div>
                            <div>{import.meta.env.VITE_API_DNS}</div>
                            <div>Task API Endpoint:</div>
                            <div>{import.meta.env.VITE_TASKS_API_DNS}</div>
                        </>
                    ) : null}
                </div>
            </div>
        );
    }
}
