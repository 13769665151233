// action types
export const SET_USERDATA = "SET_USERDATA";
export const SET_FLAG = "SET_FLAG";
export const SET_USERNAME = "SET_USERNAME";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_USER_UUID = "SET_USER_UUID";
export const HANDLE_LOGOUT = "HANDLE_LOGOUT";

// action creators
export const setUserData = function (userData) {
    return {
        type: SET_USERDATA,
        userData: userData,
    };
};

export const setFlag = function (flag) {
    return {
        type: SET_FLAG,
        flag,
    };
};

export const setUsername = function (text) {
    return {
        type: SET_USERNAME,
        text: text,
    };
};

export const setPassword = function (text) {
    return {
        type: SET_PASSWORD,
        text: text,
    };
};

export const setAuthToken = function (token) {
    return {
        type: SET_AUTH_TOKEN,
        token: token,
    };
};

export const setUserUUID = function (uuid) {
    return {
        type: SET_USER_UUID,
        useruuid: uuid,
    };
};

export const handleLogout = function () {
    return {
        type: HANDLE_LOGOUT,
    };
};
