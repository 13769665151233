import { ADJUSTMENT_FACTOR } from "../../consts";
import { sceneOrientations, volumeNames } from "../../../../../constants";
import * as THREE from "three";
import TargetModel from "./TargetModel";
import * as helpers from "../../helpers";
import { LPS2RAS, RAS2LPS } from "../../helpers";

export default class TargetSetController {
    constructor(targetSetModel, mriModel) {
        this._targetSetModel = targetSetModel;
        this._mriModel = mriModel;
        this._boundingCone = null;
        this._targetGuidanceOn = false;
        this._selectedTarget = null;
        this._mouseControlsEnabled = true;
        this._ablationProfileTemplate = null;
    }

    set ablationProfileVolume(uuid) {
        if (this._ablationProfileTemplate) {
            this._ablationProfileTemplate.selectedVolume = uuid;
        }

        if (this._targetGuidanceOn) {
            this._updateTargetGuidanceModel();
        }
    }

    get ablationProfileTemplate() {
        return this._ablationProfileTemplate;
    }

    set ablationProfileTemplate(profile) {
        this._ablationProfileTemplate = profile;
    }

    get mouseControlsEnabled() {
        return this._mouseControlsEnabled;
    }

    set mouseControlsEnabled(isOn) {
        this._mouseControlsEnabled = isOn;
    }

    get mriViewer() {
        return this._mriViewer;
    }

    set mriViewer(mriViewer) {
        this._mriViewer = mriViewer;
        this._raycaster = new THREE.Raycaster();
    }

    get meshViewer() {
        return this._meshViewer;
    }

    set meshViewer(meshViewer) {
        this._meshViewer = meshViewer;
    }

    get guidanceMode() {
        return this._targetGuidanceOn;
    }

    set guidanceMode(isOn) {
        if (!this._mriViewer || !this._ablationProfileTemplate) {
            return;
        }

        this._targetGuidanceOn = isOn;

        if (isOn) {
            let targetGuide = new TargetModel(
                this._ablationProfileTemplate,
                this._ablationProfileTemplate.ablationProfileUUID,
                this._ablationProfileTemplate.selectedVolume
            );
            targetGuide.meshViewer = this._meshViewer;
            targetGuide.mriViewer = this._mriViewer;

            this._targetGuide = targetGuide;

            this._mriViewer.mount.style.cursor = "crosshair";
        } else {
            if (this._targetGuide) {
                this._targetGuide.delete();
                this._targetGuide = null;
            }

            this._mriViewer.mount.style.cursor = "default";
        }
    }

    _updateTargetGuidanceModel() {
        if (this._targetGuide) {
            this._targetGuide.delete();
            this._targetGuide = null;
            this._mriViewer.mount.style.cursor = "default";
        }

        let targetGuide = new TargetModel(
            this._ablationProfileTemplate,
            this._ablationProfileTemplate.ablationProfileUUID,
            this._ablationProfileTemplate.selectedVolume
        );
        targetGuide.mriViewer = this._mriViewer;
        targetGuide.meshViewer = this._meshViewer;

        this._targetGuide = targetGuide;

        this._mriViewer.mount.style.cursor = "crosshair";
    }

    get selectedTarget() {
        return this._selectedTarget;
    }

    set selectedTarget(ref) {
        if (ref == null) {
            this._selectedTarget.highlight = false;
        }

        this._selectedTarget = ref;
    }

    set boundingCone(boundingCone) {
        this._boundingCone = boundingCone;
    }

    get boundingCone() {
        return this._boundingCone;
    }

    createTargetWithRaycaster(raycaster) {
        if (
            !this._mriModel ||
            !this._boundingCone ||
            !this._mouseControlsEnabled ||
            !this._ablationProfileTemplate
        ) {
            return;
        }

        let intersectRAS = helpers.getRaycasterIntersectionWithPlane(
            raycaster,
            this._mriModel.clipPlane
        );
        let intersectLPS = intersectRAS.clone().applyMatrix4(RAS2LPS);

        if (helpers.isPointInsideMRIVolume(intersectRAS, this._mriModel)) {
            let quaternion = helpers.getQuaternionAlignedWithBounds(
                intersectLPS,
                this._boundingCone
            );

            this._targetSetModel.addTarget(
                intersectLPS,
                quaternion,
                false,
                this._ablationProfileTemplate
            );
        }
    }

    moveSelectedTargetWithRaycaster(raycaster) {
        if (
            !this._mriModel ||
            !this._boundingCone ||
            !this._mouseControlsEnabled
        ) {
            return;
        }

        if (this._selectedTarget) {
            let intersectRAS = helpers.getRaycasterIntersectionWithPlane(
                raycaster,
                this._mriModel.clipPlane
            );

            if (helpers.isPointInsideMRIVolume(intersectRAS, this._mriModel)) {
                let cameraDirection = new THREE.Vector3(0, 0, -1);

                let LPSposition = this._selectedTarget.position;
                LPSposition.applyMatrix4(RAS2LPS);
                let selectedTargetPlane = new THREE.Plane();
                selectedTargetPlane.setFromNormalAndCoplanarPoint(
                    cameraDirection,
                    LPSposition
                );

                let newTargetPosition = new THREE.Vector3();

                selectedTargetPlane.projectPoint(
                    intersectRAS,
                    newTargetPosition
                );

                newTargetPosition.applyMatrix4(RAS2LPS);

                this._selectedTarget.position = newTargetPosition;
            }
        }
    }

    selectTargetWithRaycaster(raycaster) {
        if (!this._mouseControlsEnabled) {
            return;
        }

        this._selectedTarget =
            helpers.getRaycasterFirstIntersectionBehindClipPlane(
                raycaster,
                Array.from(this._targetSetModel.targets.values()),
                this._mriModel.clipPlane
            );

        if (this._selectedTarget) {
            this._selectedTarget.highlight = true;
        }

        return this._selectedTarget;
    }

    onHoverHighlightWithRaycaster(raycaster) {
        if (!this._mouseControlsEnabled) {
            return;
        }
        let hoveredTarget =
            helpers.getRaycasterFirstIntersectionBehindClipPlane(
                raycaster,
                Array.from(this._targetSetModel.targets.values()),
                this._mriModel.clipPlane
            );

        if (hoveredTarget) {
            if (this._hoveredTarget) {
                this._hoveredTarget.highlight = false;
                this._hoveredTarget = null;
            }

            this._hoveredTarget = hoveredTarget;
            this._hoveredTarget.highlight = true;
        } else {
            if (this._hoveredTarget) {
                this._hoveredTarget.highlight = false;
                this._hoveredTarget = null;
            }
        }
    }

    moveTargetGuideWithRaycaster(raycaster) {
        if (
            !this._mriModel ||
            !this._boundingCone ||
            !this._mouseControlsEnabled
        ) {
            return;
        }

        if (this._targetGuide) {
            let intersectRAS = helpers.getRaycasterIntersectionWithPlane(
                raycaster,
                this._mriModel.clipPlane
            );

            let intersectLPS = intersectRAS.clone().applyMatrix4(LPS2RAS);
            if (helpers.isPointInsideMRIVolume(intersectRAS, this._mriModel)) {
                let quaternion = helpers.getQuaternionAlignedWithBounds(
                    intersectLPS,
                    this._boundingCone
                );

                if (this._targetGuide.initialized) {
                    this._targetGuide.position = intersectLPS;
                    this._targetGuide.quaternion = quaternion;
                } else {
                    this._targetGuide.name = `${volumeNames.TARGET_GUIDE}`;
                    this._targetGuide.initializeView(
                        intersectLPS,
                        quaternion,
                        "#FFFFFF"
                    );
                    this._targetGuide.wireframe = true;
                }
            }
        }
    }

    getTargetVisibilityByID(id) {
        let target = this._targetSetModel.getTargetByID(id);

        if (target) {
            return target.visibility;
        }
    }

    setTargetVisibilityByID(id, visibility) {
        let target = this._targetSetModel.getTargetByID(id);
        target.visibility = visibility;
    }

    setTargetColorByID(id, color) {
        let target = this._targetSetModel.getTargetByID(id);
        target.color = color;
    }

    setTargetHighlightByID(id, isHighlighted) {
        let target = this._targetSetModel.getTargetByID(id);
        target.highlight = isHighlighted;
    }

    deleteTargetByID(queryID) {
        this._targetSetModel.deleteTargetByID(queryID);
    }

    deleteAllTargets() {
        this._targetSetModel.deleteAllTargets();
    }

    translateTargetByID(id, orientation, direction, plane) {
        let target = this._targetSetModel.getTargetByID(id);
        let adjustmentDistance = direction * ADJUSTMENT_FACTOR.TRANSLATION;

        target.position = helpers.getPositionTranslatedInPlane(
            target.position,
            adjustmentDistance,
            orientation,
            plane
        );
    }

    rotateTargetByID(id, orientation, direction) {
        let rotationMagnitude = direction * ADJUSTMENT_FACTOR.ROTATION;

        let target = this._targetSetModel.getTargetByID(id);

        let axis = new THREE.Vector3(0, 0, -1);
        if (orientation === sceneOrientations.CORONAL) {
            axis = new THREE.Vector3(0, 0, 1);
        }
        axis.applyQuaternion(this._mriViewer.camera.quaternion);
        axis.normalize();

        target.rotateOnWorldAxis(axis, rotationMagnitude);
    }
}
