import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { AVENDA_COMPONENT_COLORS } from "../../../constants";

export default class StepNode extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        let scope = this;
        let selectable = scope.props.finished && !scope.props.active;

        if (selectable && scope.props.callback) {
            scope.props.callback(scope.props.stepNumber);
        }
    }

    render() {
        let fontSize = 18;
        let tspanX = "8";
        let tspanY = "19";
        let bgColor = this.props.active
            ? AVENDA_COMPONENT_COLORS.BLUE
            : AVENDA_COMPONENT_COLORS.GRAY;
        let letterSpacing = "0.1073864";
        let d = 26;
        let r = d / 2;
        let viewBox = "0 0 " + String(d) + " " + String(d);

        let innerGraphic;

        let selectable = this.props.finished && !this.props.active;
        let selectableClassName = selectable ? "selectable" : "unselectable";

        let disabled = !selectable || this.props.previousStepDisabled;

        if (selectable || this.props.previousStepDisabled) {
            innerGraphic = (
                <g>
                    <g fill={AVENDA_COMPONENT_COLORS.BLUE}>
                        <circle cx={r} cy={r} r={r} />
                    </g>
                    <polyline
                        stroke={"#FFFFFF"}
                        strokeWidth={"2"}
                        points={"7 13 12.169128 19 19 7"}
                    />
                </g>
            );
        } else {
            innerGraphic = (
                <g>
                    <circle fill={bgColor} cx={r} cy={r} r={r} />
                    <text
                        fontFamily={"Helvetica"}
                        fontSize={fontSize}
                        fontWeight="normal"
                        letterSpacing={letterSpacing}
                        fill="#FFFFFF"
                    >
                        <tspan x={tspanX} y={tspanY}>
                            {this.props.stepNumber}
                        </tspan>
                    </text>
                </g>
            );
        }

        return (
            <button
                id={this.props.id}
                className={"step-node " + selectableClassName}
                style={{
                    padding: 0,
                    border: "none",
                    background: "none",
                }}
                disabled={disabled}
                onClick={this.onClick}
            >
                <svg width={d + "px"} height={d + "px"} viewBox={viewBox}>
                    <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                    >
                        {innerGraphic}
                    </g>
                </svg>
            </button>
        );
    }
}

StepNode.propTypes = {
    id: PropTypes.string,
    stepNumber: PropTypes.number,
    finished: PropTypes.bool,
    active: PropTypes.bool,
    style: PropTypes.object,
    callback: PropTypes.func,
    previousStepDisabled: PropTypes.bool,
};

StepNode.defaultProps = {
    stepNumber: 0,
    finished: false,
    active: false,
    callback: undefined,
    previousStepDisabled: false,
};
