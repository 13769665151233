import React from "react";
import PropTypes from "prop-types";

const CheckCircle = ({ fill, className }) => {
    const getCircleFill = () =>
        getComputedStyle(document.body).getPropertyValue(
            "--standardPrimaryColor"
        );

    const circleFill = fill || getCircleFill();

    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={circleFill}
                d="M15 30.002C23.2843 30.002 30 23.2862 30 15.002C30 6.71768 23.2843 0.00195312 15 0.00195312C6.71573 0.00195312 0 6.71768 0 15.002C0 23.2862 6.71573 30.002 15 30.002ZM22.7713 8.19399C23.1607 7.79759 23.7922 7.79759 24.1816 8.19399C24.571 8.59038 24.571 9.23307 24.1816 9.62947L12.2148 21.8099C11.8254 22.2063 11.194 22.2063 10.8045 21.8099L5.8184 16.7347C5.42896 16.3383 5.42896 15.6956 5.8184 15.2993C6.20784 14.9029 6.83925 14.9029 7.22869 15.2993L11.5097 19.6567L22.7713 8.19399Z"
            />
        </svg>
    );
};

CheckCircle.propTypes = {
    fill: PropTypes.string,
    className: PropTypes.string,
};

export default CheckCircle;
