import React, { Component } from "react";
import LinkWithCallback from "../LinkWithCallback/index";
import "./styles.css";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";
import AHButton from "../StyleGuide/AHButton";

export default class NextStepButton extends Component {
    constructor(props) {
        super(props);

        this.getButton = this.getButton.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.callback();
    }

    getButton() {
        let btn;

        if (this.props.reqsComplete) {
            if (this.props.noLink) {
                btn = (
                    <div
                        className={
                            "next-step-button incomplete click-outside-ignore"
                        }
                        style={this.props.style}
                    >
                        <AHButton
                            id="next-step-btn-complete-no-link"
                            onClick={this.handleClick}
                            onMouseDown={null}
                            text={this.props.reqsCompleteTxt}
                            size={"medium"}
                        />
                    </div>
                );
            } else {
                btn = (
                    <div
                        className={
                            "next-step-button complete click-outside-ignore"
                        }
                        style={this.props.style}
                    >
                        <LinkWithCallback
                            to={this.props.to}
                            onClick={this.props.callback}
                        >
                            <AHButton
                                id="next-step-btn-complete"
                                text={`${this.props.reqsCompleteTxt} >`}
                                size={"medium"}
                            />
                        </LinkWithCallback>
                    </div>
                );
            }
        } else {
            btn = (
                <div
                    className={
                        "next-step-button incomplete click-outside-ignore"
                    }
                    style={this.props.style}
                >
                    <AHButton
                        id="next-step-btn-incomplete"
                        style={{ height: "46px" }}
                        onClick={this.props.callback}
                        text={this.props.reqsIncompleteTxt}
                        size={"medium"}
                    />
                </div>
            );
        }

        return btn;
    }

    render() {
        return this.getButton();
    }
}

NextStepButton.propTypes = {
    reqsComplete: PropTypes.bool,
    noLink: PropTypes.bool,
    reqsCompleteTxt: PropTypes.string,
    reqsIncompleteTxt: PropTypes.string,
    to: PropTypes.string,
    callback: PropTypes.func,
    style: PropTypes.object,
};

NextStepButton.defaultProps = {
    reqsComplete: false,
    noLink: false,
    reqsCompleteTxt: "Next",
    reqsIncompleteTxt: "View Tasks",
    to: EMPTY_STRING,
    callback: undefined,
};
