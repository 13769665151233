import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.css";
import {
    setCurrentPlanUUID,
    setParseProgress,
    setUploadProgress,
    setUploadState,
    setParseFailureMessage,
} from "../actions";
import { uploadStates } from "../constants";
import UploadStartCard from "../UploadStartCard";
import UploadProgressCard from "../UploadProgressCard";
import UploadFinishedCard from "../UploadFinishedCard";
import UploadFailedCard from "../UploadFailedCard";
import UploadParseCard from "../UploadParseCard";
import { acceptableFileType } from "../helpers";
import {
    clientErrorMessages,
    EMPTY_STRING,
    FOLDER_STRING,
} from "../../../constants";
import PropTypes from "prop-types";

class UploadCardWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null,
            fileKey: EMPTY_STRING,
            folder: null,
            folderKey: EMPTY_STRING,
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onFileDropped = this.onFileDropped.bind(this);
        this.onFolderDropped = this.onFolderDropped.bind(this);
        this.setFileKey = this.setFileKey.bind(this);
        this.setFolderKey = this.setFolderKey.bind(this);
        this.getFileName = this.getFileName.bind(this);
        this.onFolderAdded = this.onFolderAdded.bind(this);
        this.packageFile = this.packageFile.bind(this);
        this.resetCardState = this.resetCardState.bind(this);
        this.handleFileAdded = this.handleFileAdded.bind(this);
    }

    onFilesAdded(fileList) {
        let files = this.fileListToArray(fileList);

        if (files.length > 0) {
            let file = files[0];

            this.handleFileAdded(file);
        }
    }

    onFolderAdded(folder) {
        folder = this.fileListToArray(folder);
        const files = folder.map((file) => this.packageFile(file));
        this.onFolderDropped(files);
    }

    fileListToArray(files) {
        const array = [];
        for (let i = 0; i < files.length; i++) {
            array.push(files.item(i));
        }
        return array;
    }

    packageFile(file) {
        return [file.webkitRelativePath, file];
    }

    onFileDropped(file) {
        this.handleFileAdded(file);
    }

    onFolderDropped(folder) {
        this.setState({
            folder: folder,
        });
        this.props.setUploadState(uploadStates.UPLOADING);
    }

    setFileKey(filekey) {
        this.setState({ fileKey: filekey });
    }

    setFolderKey(folderkey) {
        this.setState({ folderkey: folderkey });
    }

    getFileName() {
        if (this.state.fileKey !== EMPTY_STRING) {
            let fileName = this.state.fileKey.split("/").pop();
            if (Number(fileName) < 10) {
                fileName = FOLDER_STRING;
            }
            return fileName;
        }
        return this.props.label;
    }

    resetCardState() {
        this.setState({
            file: null,
            fileKey: EMPTY_STRING,
            folder: null,
            folderKey: EMPTY_STRING,
        });
        this.props.setUploadState(uploadStates.INIT);
        this.props.setUploadProgress(0);
        this.props.setParseProgress(0);
        this.props.uploadCallback(false);
        if (this.props.parseCallback) {
            this.props.parseCallback(false);
        }
    }

    handleFileAdded(file) {
        if (acceptableFileType(file, this.props.label)) {
            this.setState({
                file: file,
            });
            this.props.setUploadState(uploadStates.UPLOADING);
        } else {
            this.props.setParseFailureMessage(
                this.props.idx,
                clientErrorMessages.PARSING_UNACCEPTED_INPUT_TYPE
            );
            this.props.setUploadState(uploadStates.FAILED);
        }
    }

    render() {
        let card = null;
        let uploadSt = this.props.uploadState;

        if (this.props.uploadCompleteOverride) {
            uploadSt = uploadStates.COMPLETE;
        }

        switch (uploadSt) {
            case uploadStates.INIT: {
                card = (
                    <UploadStartCard
                        idx={this.props.idx}
                        text={this.props.text}
                        label={this.props.label}
                        folderSelect={this.props.folderSelect}
                        onFilesAdded={this.onFilesAdded}
                        onFolderAdded={this.onFolderAdded}
                        onFileDropped={this.onFileDropped}
                        onFolderDropped={this.onFolderDropped}
                    />
                );
                break;
            }
            case uploadStates.UPLOADING: {
                card = (
                    <UploadProgressCard
                        idx={this.props.idx}
                        text={this.props.text}
                        file={this.state.file}
                        folder={this.state.folder}
                        label={this.props.label}
                        setFileKey={this.setFileKey}
                        uploadCallback={this.props.uploadCallback}
                        setFolderKey={this.setFolderKey}
                        uploadIteration={this.props.uploadIteration}
                        isFreehand={this.props.isFreehand}
                    />
                );
                break;
            }
            case uploadStates.PARSING: {
                card = (
                    <UploadParseCard
                        idx={this.props.idx}
                        text={this.props.text}
                        file={this.state.file}
                        folder={this.state.folder}
                        label={this.props.label}
                        folderKey={this.state.folderKey}
                        fileKey={this.state.fileKey}
                    />
                );
                break;
            }
            case uploadStates.COMPLETE: {
                card = (
                    <UploadFinishedCard
                        idx={this.props.idx}
                        completedText={this.props.completedText}
                        text={this.props.label}
                        parseCallback={this.props.parseCallback}
                        label={this.props.label}
                        fileText={this.getFileName()}
                        setDeleteAlert={this.props.setDeleteAlert}
                    />
                );
                break;
            }
            case uploadStates.FAILED: {
                card = (
                    <UploadFailedCard
                        idx={this.props.idx}
                        text={this.props.text}
                        label={this.props.label}
                        uploadCallback={this.props.uploadCallback}
                        setDeleteAlert={this.props.setDeleteAlert}
                    />
                );
                break;
            }
            default:
                break;
        }

        return (
            <div
                id={this.props.id}
                className={`upload-card-wrp`}
                style={this.props.style}
            >
                {card}
            </div>
        );
    }
}

UploadCardWrapper.propTypes = {
    idx: PropTypes.number,
    text: PropTypes.string,
    label: PropTypes.string,
    uploadIteration: PropTypes.number,
    style: PropTypes.object,
    uploadState: PropTypes.number,
    setUploadState: PropTypes.func,
    uploadCallback: PropTypes.func,
    parseCallback: PropTypes.func,
    id: PropTypes.string,
    completed: PropTypes.bool,
    folderSelect: PropTypes.bool,
    completedText: PropTypes.string,
    setUploadProgress: PropTypes.func,
    setParseProgress: PropTypes.func,
    setDeleteAlert: PropTypes.func,
    setParseFailureMessage: PropTypes.func,
    uploadCompleteOverride: PropTypes.bool,
    isFreehand: PropTypes.bool,
};

UploadCardWrapper.defaultProps = {
    idx: 0,
    text: EMPTY_STRING,
    label: EMPTY_STRING,
    uploadIteration: 0,
    style: null,
    uploadState: uploadStates.INIT,
    completed: false,
    folderSelect: false,
    completedText: EMPTY_STRING,
    deleteCallback: null,
    isFreehand: false,
};

/* istanbul ignore next */
const mapStateToProps = function (state, ownProps) {
    return {
        uploadState: state.CreatePlanReducer.uploadState[ownProps.idx],
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch, ownProps) {
    return {
        setUploadState: (upstate) =>
            dispatch(setUploadState(ownProps.idx, upstate)),
        setCurrentPlanUUID: (uuid) => dispatch(setCurrentPlanUUID(uuid)),
        setUploadProgress: (prog) =>
            dispatch(setUploadProgress(ownProps.idx, prog)),
        setParseProgress: (prog) =>
            dispatch(setParseProgress(ownProps.idx, prog)),
        setParseFailureMessage: (idx, msg) =>
            dispatch(setParseFailureMessage(idx, msg)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(UploadCardWrapper);
