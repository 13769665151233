import { vsprintf } from "sprintf-js";
import {
    API_VERSION,
    TASKS_API_ENDPOINT,
    SUCCESS,
    httpMethods,
    clientErrorMessages,
} from "../../constants";
import { rootStore } from "../../redux/store";
import { setErrorState } from "../../redux/error_banner/actions";

export async function packPlan(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/patient/%s/plan/%s/pack", [
        TASKS_API_ENDPOINT,
        API_VERSION,
        input.useruuid,
        input.patientuuid,
        input.planuuid,
    ]);
    return await fetch(endpoint, {
        method: httpMethods.POST,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.TPLAN_PACK_FAILED);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}
