import React, { Component } from "react";
import "./styles.css";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import warningIconSrc from "../../../../assets/WarningTriangle.svg";
import { When } from "react-if";
import * as TreatmentPlan from "../../../../helpers/tplan/tplan";
import SpinLoader from "../../../__shared__/SpinLoader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import QuestionMark from "../../../__shared__/IconComponents/QuestionMark";

class LesionStatistics extends Component {
    getProstateCoverageWarning() {
        let plan = this.props.currentTreatmentPlan;
        if (
            plan.SavedData.UserSelectedLesionCoverageOfProstate >
            MAX_PROSTATE_COVERAGE
        ) {
            return true;
        }
    }

    render() {
        let plan = this.props.currentTreatmentPlan;
        let prostateVolume = TreatmentPlan.getCoreStructure(
            plan.TreatmentPlan
        ).VolumeCC;

        const renderTooltip = (props) => (
            <Tooltip
                style={{ width: "400px" }}
                className="marks-score-faq-tool-tip"
                {...props}
            >
                {EncapsulationConfidenceScoreDefinition}
            </Tooltip>
        );

        return (
            <table className={"margin-workflow-statistics"}>
                <tbody>
                    <tr className={"margin-workflow-statistics-row"}>
                        <td className={"margin-workflow-statistics-td"}>
                            <p
                                id={"clc-workflow-prostate-volume-label"}
                                className={"display-24"}
                            >
                                Encapsulation Confidence Score:
                            </p>
                        </td>
                        <td>
                            <Form className={"margin-workflow-form-dataview"}>
                                <Form.Control
                                    size={"sm"}
                                    id={
                                        "clc-workflow-cspca-coverage-score-value"
                                    }
                                    readOnly={true}
                                    placeholder={
                                        this.props.updatingMargin
                                            ? ""
                                            : formatMarksScore(
                                                  this.props
                                                      .userSelectedMarksScore
                                              )
                                    }
                                />
                            </Form>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <div className="marks-score-tooltip-container">
                                    <QuestionMark />
                                </div>
                            </OverlayTrigger>
                            <When condition={this.props.updatingMargin}>
                                <SpinLoader
                                    id={"lesion-statistics-spin-loader"}
                                    fgColor={"#434343"}
                                    width={"20px"}
                                    height={"20px"}
                                    loaderWidth={"3px"}
                                    animationDuration={"1s"}
                                />
                            </When>
                        </td>
                    </tr>
                    <tr className={"margin-workflow-statistics-row"}>
                        <td className={"margin-workflow-statistics-td"}>
                            <p
                                className={"display-16"}
                                id={"clc-workflow-clc-volume-label"}
                            >
                                Prostate Volume:
                            </p>
                        </td>
                        <td>
                            <Form className={"margin-workflow-form-dataview"}>
                                <Form.Control
                                    size={"sm"}
                                    readOnly={true}
                                    id={"clc-workflow-prostate-volume-value"}
                                    placeholder={formatVolume(prostateVolume)}
                                />
                            </Form>
                        </td>
                    </tr>
                    <tr className={"margin-workflow-statistics-row"}>
                        <td className={"margin-workflow-statistics-td"}>
                            <p
                                className={"display-16"}
                                id={"clc-workflow-prostate-coverage-label"}
                            >
                                Lesion Volume:
                            </p>
                        </td>
                        <td>
                            <Form className={"margin-workflow-form-dataview"}>
                                <Form.Control
                                    size={"sm"}
                                    id={"clc-workflow-clc-volume-value"}
                                    readOnly={true}
                                    placeholder={
                                        this.props.updatingMargin
                                            ? ""
                                            : formatLesionVolume(
                                                  this.props
                                                      .userSelectedLesionVolume
                                              )
                                    }
                                />
                            </Form>
                            <When condition={this.props.updatingMargin}>
                                <SpinLoader
                                    id={"lesion-statistics-spin-loader"}
                                    fgColor={"#434343"}
                                    width={"20px"}
                                    height={"20px"}
                                    loaderWidth={"3px"}
                                    animationDuration={"1s"}
                                />
                            </When>
                        </td>
                    </tr>
                    <tr className={"margin-workflow-statistics-row"}>
                        <td className={"margin-workflow-statistics-td"}>
                            <p
                                className={"display-16"}
                                id={"clc-workflow-cspca-coverage-score-label"}
                            >
                                Prostate covered by Lesion:
                            </p>
                        </td>
                        <td
                            className={
                                "margin-workflow-statistics-form-container"
                            }
                        >
                            <Form className={"margin-workflow-form-dataview"}>
                                <Form.Control
                                    size={"sm"}
                                    id={"clc-workflow-prostate-coverage-value"}
                                    readOnly={true}
                                    placeholder={
                                        this.props.updatingMargin
                                            ? ""
                                            : formatProstateCoverage(
                                                  this.props
                                                      .userSelectedProstateCoverage
                                              )
                                    }
                                />
                            </Form>
                            <When condition={this.props.updatingMargin}>
                                <SpinLoader
                                    id={"lesion-statistics-spin-loader"}
                                    fgColor={"#434343"}
                                    width={"20px"}
                                    height={"20px"}
                                    loaderWidth={"3px"}
                                    animationDuration={"1s"}
                                />
                            </When>
                            <When condition={this.getProstateCoverageWarning()}>
                                {getWarningTriangle()}
                            </When>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

LesionStatistics.propTypes = {
    updatingMargin: PropTypes.bool,
    userSelectedProstateCoverage: PropTypes.number,
    userSelectedLesionVolume: PropTypes.number,
    userSelectedMarksScore: PropTypes.number,
    currentTreatmentPlan: PropTypes.object,
};

LesionStatistics.defaultProps = {
    updatingMargin: false,
    userSelectedProstateCoverage: 0.0,
    userSelectedLesionVolume: 0.0,
    userSelectedMarksScore: 0.0,
    currentTreatmentPlan: {},
};

export default LesionStatistics;

const FLAIneligibleWarning =
    "Cancer lesion contour is larger than 50% of the prostate";
const MAX_PROSTATE_COVERAGE = 0.5;

const formatVolume = (volumeFloat) => `${volumeFloat.toFixed(1)}cc`;

const formatPercent = (percent) =>
    `${Math.min(percent * 100, 100).toFixed(1)}%`;

const formatMarksScore = (marksScore) => {
    marksScore = marksScore === 1.0 ? 0.999 : marksScore;

    return formatPercent(marksScore);
};

const formatLesionVolume = (volumeFloat) => {
    let volumeFmt = Math.max(volumeFloat, 0.1);
    let volumeStr = `${volumeFmt.toFixed(1)}cc`;
    if (volumeFmt <= 0.1) {
        volumeStr = "<" + volumeStr;
    }
    return volumeStr;
};

const formatProstateCoverage = (percent) => {
    let percentFmt = Math.min(percent * 100, 100);
    percentFmt = Math.max(percentFmt, 0.1);
    let percentStr = `${percentFmt.toFixed(1)}%`;
    if (percent <= 0.01) {
        percentStr = "<" + percentStr;
    }
    return percentStr;
};

const getWarningTriangle = () => (
    <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => (
            <Tooltip
                offset={{ left: 90 }}
                id={"margin-workflow-statistics-warning-tool-tip"}
                {...props}
            >
                {FLAIneligibleWarning}
            </Tooltip>
        )}
    >
        <div className={"margin-workflow-statistics-warning"}>
            <img src={warningIconSrc} alt={"!"} />
        </div>
    </OverlayTrigger>
);

const EncapsulationConfidenceScoreDefinition =
    "The Encapsulation Confidence Score (expressed from 0-100%) is a population-level " +
    "measure of confidence that the lesion contour contains all csPCa (GGG ≥ 2). " +
    "The score is based on the csPCa encapsulation rate observed using the same lesion " +
    "contour threshold in a retrospective study of whole mount data. For more information, consult the user manual.";
