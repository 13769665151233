import React, { Component } from "react";
import "./styles.css";
import zxcvbn from "zxcvbn";
import { passwordStrength } from "./constants";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";
import { BAD_PASSWORD_LIST } from "../../../constants";

class PasswordStrengthMeter extends Component {
    createPasswordLabel(result) {
        switch (result.score) {
            case 0:
                return passwordStrength.WEAK;
            case 1:
                return passwordStrength.WEAK;
            case 2:
                return passwordStrength.FAIR;
            case 3:
                return passwordStrength.GOOD;
            case 4:
                return passwordStrength.STRONG;
            default:
                return passwordStrength.WEAK;
        }
    }

    render() {
        /**
         * set user_input arg to specifically declare a weak password if e.g. user's name or email
         */
        const testedResult = zxcvbn(
            this.props.password,
            BAD_PASSWORD_LIST.concat([
                this.props.firstName,
                this.props.lastName,
                this.props.email,
            ])
        );

        return (
            <div className={"password-strength-meter"}>
                <progress
                    className={`password-strength-meter-progress strength-${this.createPasswordLabel(
                        testedResult
                    )}`}
                    value={testedResult.score}
                    max={"4"}
                />
                <br />
                <label className="display-14 black-light-text">
                    {this.props.password && (
                        <>
                            Password Strength:{" "}
                            {this.createPasswordLabel(testedResult)}
                        </>
                    )}
                </label>
            </div>
        );
    }
}

PasswordStrengthMeter.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
};

PasswordStrengthMeter.defaultProps = {
    password: EMPTY_STRING,
};

export default PasswordStrengthMeter;
