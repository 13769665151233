import {
    SET_SORT_CATEGORY,
    SET_SORT_ORDER,
    SET_DOWNLOAD_SELECT_OPEN,
    SET_DOWNLOAD_PROPERTIES,
} from "./actions";
import { initialState } from "./init";

export default HomeReducer;

function HomeReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_SORT_CATEGORY:
            return setSortCategory(state, action);
        case SET_SORT_ORDER:
            return setSortOrder(state, action);
        case SET_DOWNLOAD_SELECT_OPEN:
            return setDownloadSelectOpen(state, action);
        case SET_DOWNLOAD_PROPERTIES:
            return setDownloadProperties(state, action);
        default:
            return state;
    }
}

function setSortCategory(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.currentSortCategory = action.category;
    return stateCpy;
}

function setSortOrder(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.currentSortOrder = action.order;
    return stateCpy;
}

function setDownloadSelectOpen(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.downloadProperties.selectActive = action.active;
    return stateCpy;
}

function setDownloadProperties(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.downloadProperties.selectActive = action.propt.selectOpen;
    stateCpy.downloadProperties.patientuuid = action.propt.patID;
    stateCpy.downloadProperties.planuuid = action.propt.planID;
    return stateCpy;
}
