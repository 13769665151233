import React, { Component } from "react";
import { connect } from "react-redux";
import { ProgressBar, Spinner } from "react-bootstrap";
import "./styles.css";
import { setUploadProgress, setUploadState } from "../actions";
import { getRawUploadSignedURL, putFileS3XML, startParsing } from "../helpers";
import { EXCLUDE_FILE_LIST, uploadStates } from "../constants";
import { vsprintf } from "sprintf-js";
import { traverseDirectory } from "../../../helpers/helpers";
import { EMPTY_STRING } from "../../../constants";
import { PropTypes } from "prop-types";
import { filePathFilter } from "@jsdevtools/file-path-filter";
import {
    getAbortController,
    resetAbortController,
} from "../../__shared__/AbortController/AbortController";

class UploadProgressCard extends Component {
    constructor(props) {
        super(props);

        this.processFile = this.processFile.bind(this);
        this.processDirectory = this.processDirectory.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.uploadDirectory = this.uploadDirectory.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
    }

    componentDidMount() {
        resetAbortController();

        if (this.props.folder !== null) {
            this.processDirectory();
        } else if (this.props.file !== null) {
            this.processFile();
        }
    }

    componentWillUnmount() {
        this.props.setUploadProgress(0);
        let abortController = getAbortController();
        abortController.abort();
    }

    processFile() {
        let scope = this;
        let abortController = getAbortController();
        let signal = abortController.signal;

        new Promise(
            () => {
                if (scope.props.file) {
                    if (scope.props.file.name) {
                        scope.uploadFile(scope.props.file);
                    } else {
                        let file = scope.props.file.getAsFile();
                        scope.uploadFile(file);
                    }
                }
            },
            { signal }
        );
    }

    uploadFile(file) {
        let scope = this;
        let key = vsprintf("%s/%s/%s/%s/%s/%s", [
            scope.props.useruuid,
            scope.props.patientuuid,
            scope.props.planuuid,
            scope.props.uploadIteration,
            scope.props.label,
            file.name,
        ]);

        getRawUploadSignedURL(scope.props.authToken, key)
            .then((res) => {
                putFileS3XML(res.signedurl, file, scope.props.setUploadProgress)
                    .then(() => {
                        scope.props.uploadCallback(true);
                        scope.props.setFileKey(res.filekey);

                        if (
                            !startParsing({
                                label: scope.props.label,
                                token: scope.props.authToken,
                                useruuid: scope.props.useruuid,
                                patientuuid: scope.props.patientuuid,
                                planuuid: scope.props.planuuid,
                                visituuid: scope.props.visituuid,
                                queuetag: String(scope.props.uploadIteration),
                                filekey: res.filekey,
                                isdir: false,
                                isFreehand: this.props.isFreehand,
                            }).catch(() => {
                                scope.props.setUploadState(uploadStates.FAILED);
                            })
                        ) {
                            scope.props.setUploadState(uploadStates.FAILED);
                        }

                        let abortController = getAbortController();
                        let signal = abortController.signal;

                        if (!signal.aborted) {
                            scope.props.setUploadState(uploadStates.PARSING);
                        }
                    })
                    .catch(() => {
                        scope.props.setUploadState(uploadStates.FAILED);
                    });
            })
            .catch(() => {
                scope.props.setUploadState(uploadStates.FAILED);
            });
    }

    processDirectory() {
        let scope = this;

        let abortController = getAbortController();
        let signal = abortController.signal;

        new Promise(
            () => {
                let item;
                const fileEntryList = [];

                if (this.props.folder.webkitGetAsEntry) {
                    item = this.props.folder.webkitGetAsEntry();
                    traverseDirectory(item, fileEntryList).then(() => {
                        scope.uploadFiles(fileEntryList);
                    });
                } else {
                    item = this.props.folder;
                    this.uploadDirectory(item);
                }
            },
            { signal }
        );
    }

    uploadFiles(fileEntryList) {
        let numFilesProcessed = 0;
        let filesToUpload = [];
        let scope = this;

        fileEntryList.forEach((fileEntry) => {
            fileEntry.file(function (file) {
                filesToUpload.push([fileEntry.fullPath, file]);
                numFilesProcessed++;

                if (numFilesProcessed === fileEntryList.length) {
                    scope.uploadDirectory(filesToUpload);
                }
            });
        });
    }

    filterJunkFiles(filesToUpload) {
        let filteredList = [];
        filesToUpload.forEach((entry) => {
            let t = entry.filter(
                filePathFilter({ include: EXCLUDE_FILE_LIST })
            );
            if (!t.length > 0) {
                filteredList.push(entry);
            }
        });

        return filteredList;
    }

    getTotalUploadSize(files) {
        let totalUploadSize = 0;

        files.forEach((file) => {
            if (file[1]) {
                totalUploadSize += file[1].size;
            }
        });

        return totalUploadSize;
    }

    uploadDirectory(filesToUpload) {
        let scope = this;
        let numFilesUploaded = 0;
        let numBytesUploaded = 0;

        let filteredUploadFiles = this.filterJunkFiles(filesToUpload);

        let totalUploadSize = this.getTotalUploadSize(filteredUploadFiles);

        filteredUploadFiles.forEach((entry) => {
            if (entry.length >= 2) {
                let filePath = entry[0];
                let file = entry[1];
                var filePathSegment = filePath.startsWith("/")
                    ? filePath.substring(1)
                    : filePath;
                let key = vsprintf("%s/%s/%s/%s/%s/%s", [
                    scope.props.useruuid,
                    scope.props.patientuuid,
                    scope.props.planuuid,
                    scope.props.uploadIteration,
                    scope.props.label,
                    filePathSegment,
                ]);

                getRawUploadSignedURL(scope.props.authToken, key)
                    .then((res) => {
                        putFileS3XML(res.signedurl, file)
                            .then(() => {
                                scope.props.setUploadProgress(
                                    (numBytesUploaded / totalUploadSize) * 100
                                );
                                numBytesUploaded += file.size;
                                numFilesUploaded++;

                                if (
                                    numFilesUploaded ===
                                    filteredUploadFiles.length
                                ) {
                                    scope.props.uploadCallback(true);

                                    let folderKey = vsprintf("%s/%s/%s/%s/%s", [
                                        scope.props.useruuid,
                                        scope.props.patientuuid,
                                        scope.props.planuuid,
                                        scope.props.uploadIteration,
                                        scope.props.label,
                                    ]);

                                    scope.props.setFileKey(folderKey);
                                    scope.props.setFolderKey(folderKey);

                                    startParsing({
                                        label: scope.props.label,
                                        token: scope.props.authToken,
                                        useruuid: scope.props.useruuid,
                                        patientuuid: scope.props.patientuuid,
                                        planuuid: scope.props.planuuid,
                                        visituuid: scope.props.visituuid,
                                        queuetag: String(
                                            scope.props.uploadIteration
                                        ),
                                        filekey: folderKey,
                                        isdir: true,
                                        isFreehand: this.props.isFreehand,
                                    }).catch(() => {});

                                    let abortController = getAbortController();
                                    let signal = abortController.signal;

                                    if (!signal.aborted) {
                                        scope.props.setUploadState(
                                            uploadStates.PARSING
                                        );
                                    }
                                }
                            })
                            .catch(() => {
                                scope.props.setUploadState(uploadStates.FAILED);
                            });
                    })
                    .catch(() => {
                        scope.props.setUploadState(uploadStates.FAILED);
                    });
            } else {
                scope.props.setUploadState(uploadStates.FAILED);
            }
        });
    }

    isValidBiopsyDataFile(item) {
        let nameLower = item.name.toLowerCase();
        return (
            nameLower.endsWith(".vtk") ||
            nameLower.endsWith(".dcm") ||
            nameLower.endsWith(".xml") ||
            nameLower.endsWith(".bmp") ||
            nameLower.endsWith(".txt") ||
            nameLower === "seg"
        );
    }

    render() {
        return (
            <div
                id={this.props.id}
                className={`upload-card progress solid`}
                style={this.props.style}
            >
                <div className={"finish-check-container"}>
                    <Spinner animation="grow" variant="primary" />
                </div>
                <div className={"upload-bar-container"}>
                    <div className={"upload-progress-bar"}>
                        <ProgressBar
                            animated
                            now={this.props.uploadProgress}
                            label={`Uploading File... ${this.props.uploadProgress}%`}
                        />
                    </div>
                    <div className={"parse-progress-bar"}>
                        <ProgressBar
                            animated
                            now={0}
                            label={`Reading File... ${0}%`}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

UploadProgressCard.propTypes = {
    file: PropTypes.object,
    id: PropTypes.string,
    style: PropTypes.object,
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    planuuid: PropTypes.string,
    visituuid: PropTypes.string,
    patientuuid: PropTypes.string,
    uploadIteration: PropTypes.number,
    uploadProgress: PropTypes.string,
    label: PropTypes.string,
    folder: PropTypes.object,
    setUploadProgress: PropTypes.func,
    isFreehand: PropTypes.bool,
};

UploadProgressCard.defaultProps = {
    file: null,
    folder: null,
    id: EMPTY_STRING,
    style: null,
    useruuid: EMPTY_STRING,
    authToken: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    uploadIteration: 0,
    uploadProgress: "0",
    label: EMPTY_STRING,
    isFreehand: false,
};

/* istanbul ignore next */
const mapStateToProps = function (state, ownProps) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        planuuid: state.CreatePlanReducer.planuuid,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        uploadProgress: state.CreatePlanReducer.uploadProgress[ownProps.idx],
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch, ownProps) {
    return {
        setUploadState: (upstate) =>
            dispatch(setUploadState(ownProps.idx, upstate)),
        setUploadProgress: (prog) =>
            dispatch(setUploadProgress(ownProps.idx, prog)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadProgressCard);
