import React from "react";
import PropTypes from "prop-types";
import "./styles.css";
import refeshBtn from "../../../assets/Refresh.svg";

class FailureScreen extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        } else {
            window.location.reload();
        }
    }

    render() {
        return (
            <div
                id={this.props.id}
                className={"failure-screen"}
                style={this.props.style}
            >
                <div className={"recovery-message"}>
                    <p className={"display-22"}>
                        Oops. The software encountered a problem. Not all hope
                        is lost though. Press the button below to attempt to
                        recover.
                    </p>
                </div>
                <div className={"refresh-button"} onClick={this.handleClick}>
                    <img src={refeshBtn} alt={"Click Here"} />
                </div>
            </div>
        );
    }
}

FailureScreen.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.string,
    style: PropTypes.object,
};

FailureScreen.defaultProps = {
    onClick: undefined,
};

export default FailureScreen;
