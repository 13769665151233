import { vsprintf } from "sprintf-js";
import {
    API_ENDPOINT,
    API_VERSION,
    clientErrorMessages,
    httpMethods,
} from "../../../constants";
import { rootStore } from "../../../redux/store";
import { setErrorState } from "../../../redux/error_banner/actions";

export async function createNote(input) {
    const url = vsprintf("%s/api/%s/patient/%s/plan/%s/note", [
        API_ENDPOINT,
        API_VERSION,
        input.patientUuid,
        input.planUuid,
    ]);

    try {
        const resp = await fetch(url, {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                userUuid: input.userUuid,
                step: input.step,
                content: input.content,
            }),
            mode: "cors",
        });

        return resp.json();
    } catch (e) {
        rootStore.dispatch(
            setErrorState(
                true,
                clientErrorMessages.FAILED_TO_CREATE_PATIENT_NOTES
            )
        );
        return null;
    }
}

export async function getAllNotes(input) {
    const url = vsprintf("%s/api/%s/patient/%s/plan/%s/note", [
        API_ENDPOINT,
        API_VERSION,
        input.patientUuid,
        input.planUuid,
    ]);

    try {
        const resp = await fetch(url, {
            method: httpMethods.GET,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            mode: "cors",
        });

        return resp.json();
    } catch (e) {
        rootStore.dispatch(
            setErrorState(
                true,
                clientErrorMessages.FAILED_TO_FETCH_PATIENT_NOTES
            )
        );
        return null;
    }
}

export function formatDate(timestamp) {
    const timestampDate = timestamp.substring(0, 10);
    const [year, month, day] = timestampDate.split("-");
    return `${month}/${day}/${year}`;
}

export function filterAvendaHelperAccounts(doctors, user) {
    // Regular expression to match sub-account emails in the format "name-institution-avendahelper@avendahealth.com"
    const nameInstitutionPattern =
        /^[a-zA-Z0-9_.+-]+-[a-zA-Z0-9_.+-]+-avendahelper@avendahealth\.com$/;
    // Regular expression to match main account emails in the format "institution-avendahelper@avendahealth.com"
    const institutionPattern = /^\w+-avendahelper@avendahealth\.com$/;

    // Check if the user's email matches either the name-institution or the institution pattern
    if (
        user.email.match(nameInstitutionPattern) ||
        user.email.match(institutionPattern)
    ) {
        return doctors; // Show all emails
    } else {
        // If the user's email does not match the patterns for broader access,
        // filter out any doctor whose email matches the name-institution pattern
        return doctors.filter(
            (doctor) =>
                // Include the doctor if their email does not match the name-institution pattern
                !doctor.email.match(nameInstitutionPattern)
        );
    }
}
