import React, { Component } from "react";
import AHAlphaField from "../../../AHAlphaField";
import PasswordStrengthMeter from "../../../../../SignUpFlow/PasswordStrengthMeter";
import PropTypes from "prop-types";
import { EMPTY_STRING, fields } from "../../constants";
import AHButton from "../../../AHButton";
import { MAX_EMAIL_LENGTH } from "../../../../../../constants";

class MiniView extends Component {
    render() {
        let submitBtnPlaceRight = this.props.isSubmitButtonPlacedRight
            ? "right"
            : "";

        return (
            <>
                <AHAlphaField
                    label={fields.ENTER_PASSWORD}
                    value={this.props.newPassword}
                    placeholder={this.props.newPasswordPlaceholder}
                    onChange={this.props.onChange}
                    id={this.props.newPasswordId}
                    className={"ah-alpha-field-wrapper"}
                    type={"password"}
                    maxLength={MAX_EMAIL_LENGTH}
                    showLabel={this.props.isLabelVisible}
                />

                {this.props.newPassword.length > 0 && (
                    <div id={"signup-form-password-strength-meter"}>
                        <PasswordStrengthMeter
                            firstName={this.props.userData.firstName}
                            lastName={this.props.userData.lastName}
                            email={this.props.userData.email}
                            password={this.props.newPassword}
                        />
                    </div>
                )}

                <AHAlphaField
                    label={fields.PASSWORD_CONFIRMATION}
                    value={this.props.confirmPassword}
                    placeholder={this.props.confirmPasswordPlaceholder}
                    onChange={this.props.onChange}
                    id={this.props.confirmPasswordId}
                    className={"ah-alpha-field-wrapper"}
                    type={"password"}
                    maxLength={MAX_EMAIL_LENGTH}
                    showLabel={this.props.isLabelVisible}
                />

                <AHButton
                    id="sign-up-pwd-submit-btn"
                    className={`${submitBtnPlaceRight}`}
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.onSubmit();
                    }}
                    isOutline={false}
                    isSecondary={false}
                    isDynamic={false}
                    size={this.props.submitButtonSize}
                    text={this.props.submitButtonText}
                />
            </>
        );
    }
}

MiniView.propTypes = {
    submitButtonText: PropTypes.string,
    newPassword: PropTypes.string,
    confirmPassword: PropTypes.string,
    userData: PropTypes.object,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    isLabelVisible: PropTypes.bool,
    isSubmitButtonPlacedRight: PropTypes.bool,
    submitButtonSize: PropTypes.string,
    newPasswordId: PropTypes.string,
    confirmPasswordId: PropTypes.string,
    newPasswordPlaceholder: PropTypes.string,
    confirmPasswordPlaceholder: PropTypes.string,
};

MiniView.defaultProps = {
    newPassword: EMPTY_STRING,
    confirmPassword: EMPTY_STRING,
    userData: {},
    isLabelVisible: true,
    isSubmitButtonPlacedRight: true,
    newPasswordPlaceholder: EMPTY_STRING,
    confirmPasswordPlaceholder: EMPTY_STRING,
};

export default MiniView;
