import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    setActiveCore,
    setIsCoreFocused,
    setIsOnMeshViewerControl,
} from "../actions";
import { specialSelection, structureColorPalette } from "../../../constants";
import BiopsyButtonModal from "../BiopsyButtonModal";
import { mouseEventIsWithinCanvas } from "../helpers";

const unselectedNegCoreBorderColor = "#858585";
const selectedNegCoreBorderColor = "#0D52C9";
const negCoreBorderColor = "#0b42a1";
const posCoreBordercolor = "#e6990b";
const csposCoreBorderColor = "#8B0112";
const negCoreFillColor = structureColorPalette.BX_NEGATIVE;
const posCoreFillColor = structureColorPalette.BX_CANCER;
const csposCoreFillColor = structureColorPalette.BX_CS_CANCER;
const blankCoreFillColor = "#FFFFFF";
const selectedStrokeWidth = "4";
const unselectedStrokeWidth = "2";
const selectedBtnSize = "24";
const unselectedBtnSize = "18";
const selectedCenter = "12";
const unselectedCenter = "9";

class BiopsyButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovered: false,
        };

        this.handleHover = this.handleHover.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (!mouseEventIsWithinCanvas(event, this.props.canvas)) {
            return;
        }
        if (
            !event.target.closest(".biopsy-button") &&
            this.props.activeCore === this.props.idx
        ) {
            if (this.props.isOnMeshViewerControl) {
                this.props.setIsOnMeshViewerControl(false);
            } else {
                this.props.setIsCoreFocused(false);
                this.props.setActiveCore(specialSelection.NONE_SELECTED);
            }
        }
    }

    handleClick() {
        if (
            this.props.isCoreFocused &&
            this.props.activeCore === this.props.idx
        ) {
            this.props.setIsCoreFocused(false);
            this.props.setActiveCore(specialSelection.NONE_SELECTED);
        } else {
            this.props.setIsCoreFocused(true);
            this.props.setActiveCore(this.props.idx);
        }
    }

    handleHover(isHovered) {
        this.setState({ isHovered: false });
        if (this.props.isCoreFocused) {
            this.setState({ isHovered: !!isHovered });
        } else {
            this.props.setActiveCore(
                isHovered ? this.props.idx : specialSelection.NONE_SELECTED
            );
        }
    }

    getCoreColor(coreData, coreSelected) {
        if (coreData.GleasonGradeGroup === 0) {
            if (coreData.TissueLength > 0.0) {
                return negCoreBorderColor;
            } else {
                if (coreSelected) {
                    return selectedNegCoreBorderColor;
                } else {
                    return unselectedNegCoreBorderColor;
                }
            }
        } else if (coreData.GleasonGradeGroup === 1) {
            return posCoreBordercolor;
        } else {
            return csposCoreBorderColor;
        }
    }

    getFillColor(coreData) {
        if (coreData.GleasonGradeGroup === 0) {
            if (coreData.TissueLength > 0.0) {
                return negCoreFillColor;
            } else {
                return blankCoreFillColor;
            }
        } else if (coreData.GleasonGradeGroup === 1) {
            return posCoreFillColor;
        } else {
            return csposCoreFillColor;
        }
    }

    generateShape(center) {
        let shape = (
            <circle
                id={`biopsy-core-btn-${this.props.coreData.ID}`}
                cx={center - 1}
                cy={center}
                r={"6"}
            />
        );
        if (this.props.targeted) {
            shape = (
                <polygon
                    id={"Triangle"}
                    points={"7.5 2,15 15,1 15"}
                    strokeLinejoin={"round"}
                />
            );
        }
        return shape;
    }

    checkIsCoreHighlighted() {
        return this.props.activeCore === this.props.idx || this.state.isHovered;
    }

    render() {
        let isCoreHighlighted = this.checkIsCoreHighlighted();
        let coreColor = this.getCoreColor(
            this.props.coreData,
            isCoreHighlighted
        );
        let fillColor = this.getFillColor(this.props.coreData);
        let strokeWidth = isCoreHighlighted
            ? selectedStrokeWidth
            : unselectedStrokeWidth;
        let size = isCoreHighlighted ? selectedBtnSize : unselectedBtnSize;
        let center = isCoreHighlighted ? selectedCenter : unselectedCenter;
        let display = isCoreHighlighted ? "active" : "inactive";
        let shape = this.generateShape(center);

        return (
            <div
                id={this.props.id}
                className={`biopsy-button ${display} click-outside-ignore`}
                style={this.props.style}
                onMouseEnter={() => this.handleHover(true)}
                onMouseLeave={() => this.handleHover(false)}
                onClick={this.handleClick}
            >
                <svg
                    width={`${size}px`}
                    height={`${size}px`}
                    version={"1.1"}
                    xmlns={"http://www.w3.org/2000/svg"}
                >
                    <g
                        stroke={"none"}
                        strokeWidth={"1"}
                        fill={"none"}
                        fillRule={"evenodd"}
                    >
                        <g
                            fill={fillColor}
                            stroke={coreColor}
                            strokeWidth={strokeWidth}
                        >
                            {shape}
                        </g>
                    </g>
                </svg>
                <BiopsyButtonModal
                    active={isCoreHighlighted}
                    label={this.props.coreData.ID}
                    gleasonGradeGroup={this.props.coreData.GleasonGradeGroup}
                />
            </div>
        );
    }
}

BiopsyButton.propTypes = {
    activeCore: PropTypes.number,
    isCoreFocused: PropTypes.bool,
    isOnMeshViewerControl: PropTypes.bool,
    coreData: PropTypes.object,
    idx: PropTypes.number,
    canvas: PropTypes.object,
    setActiveCore: PropTypes.func,
    setIsCoreFocused: PropTypes.func,
    setIsOnMeshViewerControl: PropTypes.func,
    id: PropTypes.string,
    style: PropTypes.object,
    targeted: PropTypes.bool,
};

BiopsyButton.defaultProps = {
    activeCore: specialSelection.NONE_SELECTED,
    isCoreFocused: false,
    coreData: {},
    idx: 0,
};

const mapStateToProps = function (state, ownProps) {
    return {
        activeCore: state.CreatePlanBiopsyReducer.activeCore,
        isCoreFocused: state.CreatePlanBiopsyReducer.isCoreFocused,
        isOnMeshViewerControl:
            state.CreatePlanBiopsyReducer.isOnMeshViewerControl,
        coreData:
            state.CreatePlanReducer.currentTreatmentPlan.TreatmentPlan
                .StructureData.Biopsy[ownProps.idx],
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setActiveCore: (core) => dispatch(setActiveCore(core)),
        setIsCoreFocused: (isFocused) => dispatch(setIsCoreFocused(isFocused)),
        setIsOnMeshViewerControl: (isOnMeshViewerControl) =>
            dispatch(setIsOnMeshViewerControl(isOnMeshViewerControl)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BiopsyButton);
