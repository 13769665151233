import { TemplateBiopsyLocations, GLEASON_GRADE_NUMS } from "../constants";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import ReactTooltip from "react-tooltip";
import React from "react";
import { structureColorPalette } from "../../../constants";
import { Else, If, Then } from "react-if";
import CheckboxRenderer from "../../__shared__/AGGridCustomComponents/CheckboxRenderer";

export const columnDefs = (bxTemplate) => {
    const minWidth = 70;
    const smallFieldMaxWidth = 100;
    const mediumFieldMaxWidth = 2 * smallFieldMaxWidth;
    return [
        {
            field: "ID",
            headerName: "ID",
            flex: 2,
            editable: false,
            cellRenderer: (params) => getBiopsyIndicator(params),
            cellStyle: { border: "none" },
            checkboxSelection: false,
            suppressNavigable: true,
            minWidth: minWidth,
            maxWidth: mediumFieldMaxWidth,
        },
        {
            field: "TemplateLocation",
            headerName: "Location",
            headerTooltip: "Location",
            cellEditor: "agSelectCellEditor",
            valueGetter: getLocation,
            valueSetter: setLocation,
            cellEditorParams: {
                values: Object.keys(TemplateBiopsyLocations[bxTemplate]),
            },
            singleClickEdit: true,
            minWidth: smallFieldMaxWidth,
            maxWidth: smallFieldMaxWidth,
        },
        {
            field: "GleasonGradeGroup",
            headerName: "Gleason Grade Group",
            headerTooltip: "Gleason Grade Group",
            flex: 2,
            cellEditor: "agSelectCellEditor",
            valueGetter: getGleasonGrade,
            valueSetter: setGleasonGrade,
            cellEditorParams: {
                values: Object.keys(GLEASON_GRADE_NUMS),
            },
            singleClickEdit: true,
            minWidth: smallFieldMaxWidth,
            maxWidth: mediumFieldMaxWidth,
        },
        {
            field: "TissueLength",
            headerName: "Tissue Length (mm)",
            headerTooltip: "Tissue Length (mm)",
            valueGetter: getTissueLength,
            valueSetter: setTissueLength,
            singleClickEdit: true,
            minWidth: smallFieldMaxWidth,
            maxWidth: smallFieldMaxWidth,
        },
        {
            field: "CancerLength",
            headerName: "Cancer Length (mm)",
            headerTooltip: "Cancer Length (mm)",
            valueGetter: getCancerLength,
            valueSetter: setCancerLength,
            singleClickEdit: true,
            minWidth: smallFieldMaxWidth,
            maxWidth: smallFieldMaxWidth,
        },
        {
            field: "PercentCancer",
            headerName: "Percent Cancer (%)",
            headerTooltip: "Percent Cancer (%)",
            editable: false,
            cellStyle: { border: "none" },
            valueGetter: getCancerPercent,
            valueSetter: setCancerPercent,
            suppressNavigable: true,
            minWidth: smallFieldMaxWidth,
            maxWidth: smallFieldMaxWidth,
        },
        {
            field: "IsROITargeted",
            headerName: "Targeted Core?",
            headerTooltip: "Targeted Core?",
            cellRenderer: CheckboxRenderer,
            suppressNavigable: true,
            minWidth: smallFieldMaxWidth,
            maxWidth: smallFieldMaxWidth,
            editable: false,
        },
        {
            field: "IsMuted",
            headerName: "Exclude Core?",
            headerTooltip: "Exclude Core?",
            cellRenderer: CheckboxRenderer,
            suppressNavigable: true,
            minWidth: smallFieldMaxWidth,
            maxWidth: smallFieldMaxWidth,
            editable: false,
        },
        {
            field: "InputError",
            headerName: "Error",
            editable: false,
            suppressNavigable: true,
            cellRenderer: (params) => getErrorIndicator(params),
            minWidth: smallFieldMaxWidth,
            resizable: false,
        },
    ];
};

export const rowDefs = (plan) => {
    let rowData = [];
    const biopsyData = plan.TreatmentPlan.StructureData.Biopsy;
    if (biopsyData) {
        biopsyData.forEach((key) => {
            rowData.push({
                ID: key.ID,
                TemplateLocation: key.Frame.MRI.TemplateLocation,
                GleasonGradeGroup: key.GleasonGradeGroup,
                TissueLength: key.TissueLength,
                CancerLength: key.CancerLength,
                PercentCancer: key.PercentCancer,
                IsMuted: key.IsMuted,
                IsROITargeted: key.IsROITargeted,
                InputError: null,
            });
        });
    }
    return rowData;
};

const getBiopsyColor = (params) => {
    const gGG = params.data.GleasonGradeGroup;
    const len = params.data.TissueLength;

    let gGGC;

    if (gGG >= 2) gGGC = structureColorPalette.BX_CS_CANCER;
    if (gGG === 1) gGGC = structureColorPalette.BX_CANCER;
    if (gGG === 0) gGGC = structureColorPalette.BX_NEGATIVE;
    if (gGG === 0 && len === 0) gGGC = "white";

    return gGGC;
};

export const getBiopsyIndicator = (params) => {
    const color = getBiopsyColor(params);
    return (
        <div>
            <If condition={params.data.IsROITargeted}>
                <Then>
                    <div
                        className={"biopsy-indicator-triangle"}
                        style={{ borderBottomColor: color }}
                    />
                </Then>
                <Else>
                    <div
                        className={"biopsy-indicator-circle"}
                        style={{ background: color }}
                    />
                </Else>
            </If>
            {params.data.ID}
        </div>
    );
};

export const getLocation = (params) => {
    if (params.data.TemplateLocation) {
        return params.data.TemplateLocation;
    }
};

export const setLocation = (params) => {
    params.data.TemplateLocation = params.newValue;
    return true;
};

//convert numeric value to natural language
export const getGleasonGrade = (params) => {
    const obj = GLEASON_GRADE_NUMS;
    return Object.keys(obj).find(
        (value) => obj[`${value}`] === params.data.GleasonGradeGroup
    );
};

//convert natural language to numeric value
export const setGleasonGrade = (params) => {
    const obj = GLEASON_GRADE_NUMS;
    params.data.GleasonGradeGroup = obj[params.newValue];
    return true;
};

const getSingleDecimal = (value) =>
    Math.round((parseFloat(value) + Number.EPSILON) * 10) / 10;

const getOnlyNumber = (value) => {
    //ag grid doesnt have a number only field - allow only decimals
    let num;
    if (value !== null) {
        num = value.toString().replace(/[^\d.]/g, "");
    }
    if (!num) return 0;
    return num;
};

export const getTissueLength = (params) =>
    getSingleDecimal(params.data.TissueLength);

export const setTissueLength = (params) => {
    const newVal = getOnlyNumber(params.newValue);
    params.data.TissueLength = getSingleDecimal(newVal);
    return true;
};

export const getCancerLength = (params) =>
    getSingleDecimal(params.data.CancerLength);

export const setCancerLength = (params) => {
    const newVal = getOnlyNumber(params.newValue);
    params.data.CancerLength = getSingleDecimal(newVal);
    return true;
};

export const calCancerPercent = (params) => {
    const tissue = params.data.TissueLength;
    const cancer = params.data.CancerLength;
    if (0 < cancer && cancer <= tissue) {
        const value = (cancer / tissue) * 100;
        return getSingleDecimal(value);
    }
    return 0;
};

export const getCancerPercent = (params) => {
    setCancerPercent(params);
    return calCancerPercent(params);
};

export const setCancerPercent = (params) => {
    params.data.PercentCancer = calCancerPercent(params);
    return true;
};

export const getErrorIndicator = (params) => {
    let err = params.data.InputError;

    if (err) {
        const color = err.charAt(0) === "C" ? "#F0AD4E" : "#BE6464";
        const ind = (
            <div id={params.data.ID}>
                <ExclamationTriangleFill
                    data-for={color}
                    color={color}
                    data-tip={err}
                    size={20}
                />
                <ReactTooltip
                    id={color}
                    effect={"solid"}
                    className={"ah-react-tooltip"}
                    backgroundColor={color}
                />
            </div>
        );
        return ind;
    }
    return null;
};
