import {
    API_ENDPOINT,
    API_VERSION,
    BAD_NAME_CHAR_LIST,
    EMPTY_STRING,
    SUCCESS,
    clientErrorMessages,
} from "../../constants";
import { vsprintf } from "sprintf-js";
import { signUpErrorMsgs, zxcvbnStrengthLevel3 } from "./constants";
import { isEmpty } from "../../helpers/helpers";
import { BAD_PASSWORD_LIST, EMAIL_REGEX } from "../../constants";
import zxcvbn from "zxcvbn";

export async function createAccount(userForm) {
    return await fetch(
        vsprintf("%s/api/%s/user", [API_ENDPOINT, API_VERSION]),
        {
            method: "POST",
            body: JSON.stringify({
                firstName: userForm.firstName,
                lastName: userForm.lastName,
                email: userForm.email,
                phone: "+00000000000",
                birthDay: "1970-01-01",
                username: userForm.username,
                password: userForm.password,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return SUCCESS;
            }
            throw new Error(clientErrorMessages.USER_CREATE_FAILED);
        });
}

export async function checkSignUpCode(code) {
    return await fetch(
        vsprintf("%s/api/%s/proc/checkSignUpCode", [API_ENDPOINT, API_VERSION]),
        {
            method: "POST",
            body: JSON.stringify({
                code: code,
            }),
            mode: "cors",
        }
    );
}

export async function checkEmailFree(email) {
    return await fetch(
        vsprintf("%s/api/%s/proc/checkEmailFree", [API_ENDPOINT, API_VERSION]),
        {
            method: "POST",
            body: JSON.stringify({
                email: email,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.status === 200)
        .catch(() => false);
}

export async function checkSignUpFormValid(form, step1) {
    let res = {};

    if (step1) {
        const firstName = checkValidFirstName(form.firstName);
        if (!isEmpty(firstName)) {
            res.firstName = firstName;
        }

        const lastName = checkValidLastName(form.lastName);
        if (!isEmpty(lastName)) {
            res.lastName = lastName;
        }

        const email = await checkValidEmail(form.email);
        if (!isEmpty(email)) {
            res.email = email;
        }

        if (!isEmpty(res)) {
            return { isInvalid: true, msg: res };
        }
    }

    return { isInvalid: false, msg: [] };
}

export function checkValidFirstName(firstName) {
    let trimmedFirstName = firstName.trim();
    if (!trimmedFirstName || trimmedFirstName === EMPTY_STRING) {
        return signUpErrorMsgs.NO_FIRST_NAME;
    } else if (containsSQLInjectionChars(trimmedFirstName)) {
        return signUpErrorMsgs.NO_INVALID_CHAR;
    } else {
        return null;
    }
}

export function checkValidLastName(lastName) {
    let trimmedLastName = lastName.trim();
    if (!trimmedLastName || trimmedLastName === EMPTY_STRING) {
        return signUpErrorMsgs.NO_LAST_NAME;
    } else if (containsSQLInjectionChars(trimmedLastName)) {
        return signUpErrorMsgs.NO_INVALID_CHAR;
    } else {
        return null;
    }
}

export async function checkValidEmail(email) {
    if (!EMAIL_REGEX.test(email)) {
        return signUpErrorMsgs.NO_EMAIL;
    } else if (!(await checkEmailFree(email))) {
        return signUpErrorMsgs.EMAIL_TAKEN;
    } else {
        return null;
    }
}

export function containsSQLInjectionChars(name) {
    for (var i = 0; i < BAD_NAME_CHAR_LIST.length; i++) {
        if (name.indexOf(BAD_NAME_CHAR_LIST[i]) !== -1) {
            return true;
        }
    }
    return false;
}

export function checkValidPassword(form) {
    let firstName = form.firstName ? form.firstName : EMPTY_STRING;
    let lastName = form.lastName ? form.lastName : EMPTY_STRING;

    const badPwListStrengthTester = zxcvbn(form.newPassword, BAD_PASSWORD_LIST);

    const personalInfo = [firstName, lastName, form.email];
    const personalInfoStrengthTester = zxcvbn(form.newPassword, personalInfo);

    if (!form.newPassword || form.newPassword === EMPTY_STRING) {
        return signUpErrorMsgs.EMPTY_PASSWORD;
    } else if (
        personalInfoStrengthTester.sequence[0].dictionary_name === "user_inputs"
    ) {
        return signUpErrorMsgs.PERSONAL_INFO_PASSWORD;
    } else if (
        badPwListStrengthTester.sequence[0].dictionary_name === "user_inputs"
    ) {
        return signUpErrorMsgs.COMMON_PASSWORD;
    } else if (badPwListStrengthTester.score < zxcvbnStrengthLevel3) {
        return signUpErrorMsgs.SHORT_PASSWORD;
    } else if (form.newPassword !== form.confirmPassword) {
        return signUpErrorMsgs.NO_PW_MATCH;
    } else {
        return null;
    }
}

export function checkConfPassword(password, confPassword) {
    if (
        (password && !confPassword) ||
        (password && confPassword === EMPTY_STRING)
    ) {
        return signUpErrorMsgs.NO_CONFIRM;
    } else if (confPassword !== password) {
        return signUpErrorMsgs.NO_PW_MATCH;
    } else {
        return null;
    }
}
