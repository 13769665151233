// action types
export const SET_ERROR_STATE = "SET_ERROR_STATE";

// action creators
export const setErrorState = function (hasError, errorText) {
    return {
        type: SET_ERROR_STATE,
        hasError: hasError,
        errorText: errorText,
    };
};
