import React from "react";
import "./styles.css";
import uncheckedSquare from "../../../assets/UncheckedSquare.svg";
import checkedSquare from "../../../assets/CheckedSquare.svg";
import PropTypes from "prop-types";

class FilterAutoLvlItem extends React.Component {
    render() {
        let chkBox = !this.props.autoLevel ? uncheckedSquare : checkedSquare;

        return (
            <div
                id={this.props.id}
                className={"filter-lvl-item"}
                style={this.props.style}
                ref={this.setWrapperRef}
                onClick={this.props.clickCallback}
            >
                <img
                    src={chkBox}
                    width="24px"
                    height="24px"
                    alt={"Check Box"}
                    onClick={this.props.clickCallback}
                />
                <p className={"display-16"}>{this.props.name}</p>
            </div>
        );
    }
}

FilterAutoLvlItem.propTypes = {
    autoLevel: PropTypes.bool,
    clickCallback: PropTypes.func,
    id: PropTypes.string,
    style: PropTypes.object,
    name: PropTypes.string,
};

export default FilterAutoLvlItem;
