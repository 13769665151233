import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { sortInProgressPlans } from "../helpers";
import { DESCENDING, EMPTY_STRING } from "../../../constants";
import AHButton from "../../__shared__/StyleGuide/AHButton";
import LockCircle from "../../__shared__/IconComponents/LockCircle";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { lockedPlanTooltipMsg } from "../constants";

const BUBBLES_COUNT = 2;

class BubbleContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bubbles: [],
        };

        this.createBubbleComponent = this.createBubbleComponent.bind(this);
        this.makeBubbles = this.makeBubbles.bind(this);
    }

    componentDidMount() {
        this.makeBubbles(this.props.patientData);
    }

    async componentDidUpdate(prevProps) {
        if (this.props.patientData !== prevProps.patientData) {
            await this.makeBubbles(this.props.patientData);
        }
    }

    createBubbleComponent(isLocked, bubbleParams) {
        if (isLocked) {
            return (
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip className={"lock-tooltip"}>
                            <div className={"lock-tooltip-text"}>
                                {bubbleParams.tooltipMessage}
                            </div>
                        </Tooltip>
                    }
                >
                    <div style={{ position: "relative" }}>
                        <div
                            className={"side-by-side-ah-btn-container"}
                            key={bubbleParams.btnParams.patientUuid}
                        >
                            <AHButton
                                id={`bubble-${bubbleParams.idx}`}
                                className={"fixed-size"}
                                isOutline={true}
                                isDisabled={true}
                                size={"large"}
                                text={bubbleParams.btnTxt}
                            />
                        </div>
                        <div className={"lock-icon-container"}>
                            <LockCircle
                                className={"lock-icon"}
                                fill={getComputedStyle(
                                    document.body
                                ).getPropertyValue("--disabledButtonGray")}
                            />
                        </div>
                    </div>
                </OverlayTrigger>
            );
        } else {
            return (
                <div
                    className={"side-by-side-ah-btn-container"}
                    key={bubbleParams.btnParams.patientUuid}
                >
                    <AHButton
                        className={"fixed-size"}
                        isOutline={true}
                        size={"large"}
                        id={`bubble-${bubbleParams.idx}`}
                        onClick={(event) =>
                            this.props.checkNotification(
                                event,
                                bubbleParams.btnParams,
                                "BubbleContainer"
                            )
                        }
                        text={bubbleParams.btnTxt}
                    />
                </div>
            );
        }
    }

    async makeBubbles(patientData) {
        if (patientData == null || patientData.length < 1) {
            return [];
        }

        let inProgressPlans = [];

        patientData.forEach((plan) => {
            if (
                plan.SavedData != null &&
                !plan.SavedData.PlanCreationCompleteFlag
            ) {
                inProgressPlans.push(plan);
            }
        });

        inProgressPlans.sort(function (planA, planB) {
            return sortInProgressPlans(planA, planB, DESCENDING);
        });

        if (inProgressPlans.length < 1) {
            return [];
        }

        let bubbleData = inProgressPlans.slice(0, BUBBLES_COUNT);
        let bubbles = [];

        for (let elem of bubbleData) {
            const idx = bubbleData.indexOf(elem);
            let btnParams = {
                planUuid: elem.MetaData.PlanUUID,
                patientUuid: elem.MetaData.PatientUUID,
                visitUuid: elem.MetaData.VisitUUID,
                SavedData: {
                    PlanCreationStep: elem.SavedData.PlanCreationStep,
                },
            };

            let btnTxt = `${elem.MetaData.FirstName} ${elem.MetaData.LastName}`;
            if (btnTxt.length > 32) {
                btnTxt = btnTxt.slice(0, 32) + "...";
            }

            let tooltipMessage = "";
            let isLocked =
                elem.LockedByUser !== null &&
                elem.LockedByUser.uuid !== this.props.userUuid
                    ? true
                    : false;
            if (
                elem.LockedByUser !== null &&
                elem.LockedByUser.uuid !== this.props.userUuid
            ) {
                tooltipMessage = lockedPlanTooltipMsg(
                    elem.LockedByUser.firstName,
                    elem.LockedByUser.lastName,
                    elem.LockedByUser.email
                );
            }
            let bubble = this.createBubbleComponent(isLocked, {
                idx: idx,
                tooltipMessage: tooltipMessage,
                btnTxt: btnTxt,
                btnParams: btnParams,
            });
            bubbles.push(bubble);
        }

        this.setState({ bubbles: bubbles });
    }

    render() {
        let bubbles = this.state.bubbles;
        return (
            <div id={"home-page-bubble-container"}>
                <div className={"bubble-header"}>
                    <p className={"display-14"} style={{ color: "#9B9B9B" }}>
                        {bubbles.length > 0
                            ? "Continue planning"
                            : EMPTY_STRING}
                    </p>
                </div>
                <div className={"bubble-container"}>{bubbles}</div>
            </div>
        );
    }
}

BubbleContainer.propTypes = {
    patientData: PropTypes.array,
    checkNotification: PropTypes.func,
    authToken: PropTypes.string,
    userUuid: PropTypes.string,
};

BubbleContainer.defaultProps = {
    patientData: [],
};

export default BubbleContainer;
