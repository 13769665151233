import React from "react";
import BootstrapAlert from "react-bootstrap/Alert";
import PropTypes from "prop-types";

export default function Alert(props) {
    return (
        <BootstrapAlert
            variant={props.variant}
            className={props.className}
            style={props.customStyle}
        >
            {props.text}
        </BootstrapAlert>
    );
}

Alert.propTypes = {
    variant: PropTypes.string.isRequired,
    className: PropTypes.string,
    customStyle: PropTypes.object,
    text: PropTypes.string.isRequired,
};
