export const API_ENDPOINT = import.meta.env.VITE_API_DNS;
export const API_VERSION = "v1";
export const API_VERSION_2 = "v2";
export const TASKS_API_ENDPOINT = import.meta.env.VITE_TASKS_API_DNS;
export const WEBSOCKET_API_ENDPOINT = import.meta.env.VITE_WEBSOCKET_API_DNS;

export const MINIMUM_CHROME_VERISON = 78;
export const LASER_CATHETER_TIP_OFFSET = 6.5; // [mm] the distance from 14W ablation mesh centroid to LC tip

export const BACKSPACE_KEY = 8;
export const ENTER_KEY = 13;
export const ASCENDING = true;
export const DESCENDING = false;

export const ALLOWED = "ALLOWED";
export const RESTRICTED = "RESTRICTED";
export const FORBIDDEN = "FORBIDDEN";
export const MESSAGE = "message";
export const RESPONSE = "response";
export const PAYLOAD = "payload";
export const SUCCESS = "SUCCESS";
export const REQ_TOO_LONG = "Request Too Long";
export const REQ_TOO_LONG_STATUS_CODE = 413;
export const STATUS_OK = 200;
export const FAILURE = "FAILURE";
export const USER_NOT_FOUND = "USER_NOT_FOUND";
export const TOKEN = "token";
export const EMPTY_STRING = "";
export const N_A_STRING = "N/A";
export const FOLDER_STRING = "FOLDER";
export const CANNOT_PARSE_REQ = "CANNOT_PARSE_REQ";
export const CANNOT_RETRIEVE_OUTPUT_FILE_FROM_S3 =
    "CANNOT_RETRIEVE_OUTPUT_FILE_FROM_S3";
export const CANNOT_CONNECT_DB = "CANNOT_CONNECT_DB";
export const CANNOT_PREPARE_SQL = "CANNOT_PREPARE_SQL";
export const CANNOT_EXEC_QUERY = "CANNOT_EXEC_QUERY";
export const CANNOT_PARSE_RESP = "CANNOT_PARSE_RESP";
export const CANNOT_CONNECT_CACHE = "CANNOT_CONNECT_CACHE";
export const EMAIL_ALREADY_TAKEN = "EMAIL_ALREADY_TAKEN";
export const FAILED_LAMBDA_INVOKE = "FAILED_LAMBDA_INVOKE";
export const LAMBDA_BAD_RESPONSE = "LAMBDA_BAD_RESPONSE";
export const INCORRECT_CREDENTIALS = "INCORRECT_CREDENTIALS";
export const INCORRECT_PASSWORD = "INCORRECT_PASSWORD";
export const LOGIN_ATTEMPTS_EXCEEDED = "LOGIN_ATTEMPTS_EXCEEDED";
export const SUCCESS_OTP_NEXT = "SUCCESS_OTP_NEXT";
export const NO_MARGIN_CREATED = "NO_MARGIN_CREATED";
export const UNKNOWN_ERROR = "UNKNOWN_ERROR";
export const PATIENT_ALREADY_SHARED = "PATIENT_ALREADY_SHARED";
export const MAX_EMAIL_LENGTH = 254;

export const MAX_REPORT_BUG_PARAGRAPH_LENGTH = 1000;

export const BAD_PASSWORD_LIST = [
    "avenda",
    "avendahealth",
    "focalquest",
    "iquest",
    "prostate",
    "urology",
    "unfold",
];

export const BAD_NAME_CHAR_LIST = [
    '"',
    ";",
    "--",
    "#",
    "`",
    "_",
    "\\",
    "=",
    "/",
];

export const clientErrorMessages = {
    MFA_TOKEN_VALIDATE_FAILED: "Unable to validate MFA token.",
    MFA_TOKEN_VERIFY_FAILED: "Unable to verify MFA token.",
    MFA_AUTH_FAILED: "Unable to generate MFA authorization.",
    CHANGE_PASSWORD_FAILED: "Unable to change password.",
    UN_PW_INCORRECT: "The username or password is incorrect.",
    LOGIN_FAILED: "Please check connection and try again.",
    LOGIN_ATTEMPTS_EXCEEDED: "Too many login attempts. Try again in ",
    LOGOUT_FAILED: "Client failed to logout.",
    FILE_UPLOAD_FAILED: "Client failed to upload file.",
    MRI_LOAD_FAILED: "Client failed to load MRIs from server.",
    FAILED_TO_DELETE_MRI: "An error occurred while deleting the MRI file.",
    CEM_OVERLAY_FAILED: "Failed to load the CEM overlay.",
    MESH_DOWNLOAD_FAILED: "Client failed to download mesh.",
    AI_SEG_FAILED: "Failed to create AI segmentation.",
    PARSING_START_FAILED: "Client failed to start parsing process.",
    PARSING_GET_PROGRESS_FAILED:
        "Client was not able to get progress for parsing.",
    PARSING_UNACCEPTED_INPUT_TYPE: "upload input type not recognized.",
    PATHREPORT_LOAD_FAILED: "Client failed to load pathology report.",
    PROFILE_PICTURE_TOO_SMALL: "The provided image is too small.",
    PROFILE_PICTURE_TOO_LARGE: "The provided image is too large.",
    PROFILE_PICTURE_UPLOAD_FAILED: "Client could not upload profile picture.",
    PROFILE_PICTURE_UNSUPPORTED_TYPE:
        "Image type is not supported. Only jpg, png, bmp, gif, webp and icon are supported.",
    PRESIGNED_URL_FAILED: "Client failed to initiate upload.",
    SEGMENTATION_CREATE_FAILED: "Client failed to generate segmentation.",
    TPLAN_CREATE_FAILED: "Client failed to create patient data.",
    TPLAN_PACK_FAILED:
        "An error occurred while preparing the treatment plan for download.",
    TPLAN_UPDATE_FAILED: "Client failed to update the treatment plan.",
    TPLAN_RETRIEVE_FAILED: "Client failed to retrieve patient data.",
    NAV_PLAN_DOWNLOAD_FAILED: "Client failed to download navigation plan.",
    PLAN_DOWNLOAD_FAILED: "Client failed to download plan.",
    UNABLE_TO_REACH_SERVER: "Unable to reach server.",
    USER_CREATE_FAILED: "Server failed to create new user account.",
    USER_UPDATE_FAILED: "Client failed to retrieve or update user information.",
    UPDATE_ABLATION_SITE_FAILED: "Unable to update ablation site.",
    ATP_FAILED: "Auto ablation site placement failed.",
    MARGIN_CREATION_FAILED: "Unable to create cancer lesion contour.",
    CPM_GENERATION_FAILED: "Unable to generate CPM",
    SAVE_MESH_UPDATE_FAILED: "Unable to save updates to mesh.",
    CEM_DELETE_FAILED:
        "Unable to replace outdated cancer lesion initialization.",
    CLC_COVERAGE_FAILED: "Failed to calculate lesion coverage.",
    FILE_DELETE_FAILED: "Unable to delete file.",
    FAILED_TO_RETURN_TO_PREV_PAGE:
        "Couldn't return to previous step at this time.",
    FAILED_TO_SAVE_PROFILE: "Unable to save ablation profile.",
    FAILED_TO_GET_PROFILES: "Unable to get ablation profiles.",
    FAILED_TO_DEL_VOLUME: "Unable to delete ablation volume.",
    FAILED_TO_UPDATE_USER: "Unable to update user details.",
    FAILED_TO_UPDATE_PW: "Unable to update password.",
    PATIENT_ALREADY_SHARED: "Patient is already shared with this doctor",
    FAILED_TO_SHARE_PATIENT: "Unable to share patient",
    FAILED_TO_FETCH_PATIENT_NOTES: "Unable to fetch patient notes",
    FAILED_TO_CREATE_PATIENT_NOTES: "Unable to create patient notes",
};

export const EMAIL_REGEX =
    /^(((?!.*\.\.)(?!\.)([a-zA-Z0-9_\-.!#$%&'*+\-/=?^`{|}~]+)(?<!\.))|("([^"]*)"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

export const MAX_PARSING_STATUS_MISSES = 5;
export const MAX_KEY_PATIENT_INFO_CARD_RELOADS = 3;

export const loginReducerFlags = {
    PLAN_COMPLETED: "planCompleted",
    IS_AUTHENTICATED: "isAuthenticated",
};

const Byte = 1;
const Kilobyte = 1024 * Byte;
const Megabyte = 1024 * Kilobyte;
const Gigabyte = 1024 * Megabyte;
const Terabyte = 1024 * Gigabyte;
const Petabyte = 1024 * Terabyte;

export const byteUnits = {
    BYTE: Byte,
    KILOBYTE: Kilobyte,
    MEGABYTE: Megabyte,
    GIGABYTE: Gigabyte,
    TERABYTE: Terabyte,
    PETABYTE: Petabyte,
};

export const imageSize = {
    PROFILE_PIC_MIN: 256 * byteUnits.BYTE,
    PROFILE_PIC_MAX: 1 * byteUnits.MEGABYTE,
};

export const formatting = {
    space: " ",
};
export const specialSelection = {
    NONE_SELECTED: -2,
    ALL_SELECTED: -1,
};

export const sceneOrientations = {
    AXIAL: "axial",
    SAGITTAL: "sagittal",
    CORONAL: "coronal",
};

export const stackOrientations = {
    AXIAL: 0,
    SAGITTAL: 1,
    CORONAL: 2,
};

export const httpMethods = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
};

export const refFrames = {
    MRI: "MRI",
    TRUS: "TRUS",
};

export const keyStrings = {
    ARROW_LEFT: "ArrowLeft",
    ARROW_RIGHT: "ArrowRight",
    ENTER: "Enter",
    BACKSPACE: "Backspace",
    w: "w",
    a: "a",
    s: "s",
    d: "d",
    q: "q",
    e: "e",
    W: "W",
    A: "A",
    S: "S",
    D: "D",
    Q: "Q",
    E: "E",
    ONE: "1",
    TWO: "2",
    THREE: "3",
};

export const mouseButtons = {
    NONE: 0,
    LEFT: 1,
    MIDDLE: 2,
    RIGHT: 3,
};

// Order for Steps 1-5 should not be changed as their indexed positions are used in a function
export const stepPath = {
    STEP1: "/create_plan/upload_files",
    STEP2: "/create_plan/segment_mri",
    STEP3: "/create_plan/input_biopsy",
    STEP4: "/create_plan/edit_margin",
    STEP5: "/create_plan/plan_summary",
    STEP1_1: "/create_plan/select_plan_type",
    STEP4_1: "/create_plan/generate_cpm",
    INDEX: "/",
    HOME: "/home",
    PROFILE: "/profile",
    LOGIN: "/login",
    FORGOT_PW: "/forgot_pw",
    SIGNUP: "/signup",
    ENABLE_MFA: "/enable_mfa",
    SET_NEW_PASS: "/set_new_password",
    SECRET_CONTRIBUTOR_PAGE: "/contributors",
    CREATE_ABLATION_PROFILE: "/ablation_profile",
};

export const stepNumber = {
    UPLOAD: 0,
    SEG_MRI: 1,
    BIOPSY: 2,
    EDIT_MARGIN: 3,
    SUMMARY: 4,
};

export const MAX_MRI_ZOOM = 10;
export const MIN_MRI_ZOOM = 2.6;

export const sliderTypes = {
    INTENSITY: "Intensity",
    CONTRAST: "Contrast",
};

export const checkboxType = {
    AUTOLEVEL: "Auto Level",
};

export const sceneFilterNames = {
    MRI: "MRI",
    CPM: "Cancer Estimation Map",
    ROI: "ROI",
    MARGIN: "Lesion Contour",
    BIOPSY: "Biopsy Cores",
    BIOPSY_CS_PCA_CORES: "GGG 2+ Biopsy Cores",
    BIOPSY_PCA_CORES: "GGG 1 Biopsy Cores",
    BIOPSY_BENIGN_CORES: "Negative Biopsy Cores",
    AXES: "Axes",
    RECTAL_WALL: "Rectal Wall",
    URETHRA: "Urethra",
    PROSTATE: "Prostate",
    PROSTATE_AI: "ProstateAI",
    TUMOR: "Post-Surgery Ground Truth",
    LASER_CATHETER: "Treatment Applicator",
};

export const coreState = {
    BENIGN: "BENIGN_",
    PCA: "PCA_",
    CS_PCA: "CS_PCA_",
};

export const volumeNames = {
    MR_BIOPSY: "MRCORE_",
    MR_BIOPSY_BENIGN: "MRCORE_" + coreState.BENIGN,
    MR_BIOPSY_PCA: "MRCORE_" + coreState.PCA,
    MR_BIOPSY_CS_PCA: "MRCORE_" + coreState.CS_PCA,
    MR_PROSTATE: "MRProstate",
    MR_PROSTATE_USER_UPLOAD: "MRProstateUserUpload",
    MR_PROSTATE_AI: "MRProstateAI",
    MR_URETHRA: "MRUrethra",
    MR_MARGIN_AI: "MRMarginAI",
    MR_TUMOR: "MRTumor_",
    MR_RECTAL_WALL: "MRRectalWall",
    MR_ROI: "MRRoi_",
    MR_TARGETS: "MRTargets_",
    MRI: "MRI",
    OTHER: "OTHER_",
    TARGET_GUIDE: "TARGET_GUIDE",
    FRONT: "_FRONT",
    BACK: "_BACK",
    BOTH: "_BOTH",
    EDITING: "_EDITING",
    BOUNDING_CONE: "BoundingCone",
    EDITED_PROSTATE: "EditedProstate",
    CUSTOM_ABLATION_VOLUME: "CustomAblation",
    PROSTATE_AXES: "ProstateAxes",
};

export const ablationTarget = {
    SCALE: 1.0,
};

export const pageLoadStates = {
    LOADING: "LOADING",
    COMPLETE: "COMPLETE",
    FAILED: "FAILED",
};

export const buttonNames = {
    RESUME: "RESUME",
    REVIEW: "REVIEW",
};

export const editedVtkNames = {
    AI_EDITED_SEGMENTATION_FILE_NAME: "MR_PROSTATE_AI_EDITED.vtk",
    UPLOADED_EDITED_SEGMENTATION_FILE_NAME: "MR_PROSTATE_EDITED.vtk",
};

export const viewerPerspective = {
    TwoD: "2d",
    ThreeD: "3d",
};

export const submitBugsButtonText = {
    STANDBY: "Please describe the nature of the issue you are reporting.",
    EMPTY_FIELDS: "Description empty!",
    EMAIL_ISSUE: "Invalid Email",
    SUCCESS:
        "Thank you for submitting your issue. We will follow up with you shortly.",
    FAILURE:
        "There was an error submitting your issue. Please try again later.",
};

export const COLOR_BLIND_SAFE_COLORS = [
    "#ee7733",
    "#00c728",
    "#33bbee",
    "#ff96bd",
];

export const COLOR_BLIND_SAFE_COLORS_2D = [
    [COLOR_BLIND_SAFE_COLORS[0]],
    [COLOR_BLIND_SAFE_COLORS[1]],
    [COLOR_BLIND_SAFE_COLORS[2]],
    [COLOR_BLIND_SAFE_COLORS[3]],
];

export const structureColorPalette = {
    TX_MARGIN: "#F542E9",
    ROI_NEGATIVE: "#0D52C9",
    ROI_CANCER: "#FFAA0D",
    TUMOR_GROUND_TRUTH: "#36013f",
    ROI_CS_CANCER: "#D0021B",
    BX_NEGATIVE: "#0D52C9",
    BX_CANCER: "#FFAA0D",
    BX_CS_CANCER: "#D0021B",
    PROSTATE: "#FFFFFF",
    AI_PROSTATE: "#FFCCCC",
    TARGET_GUIDE: "#FFFFFF",
    URETHRA: "#FF96BD",
    EDITING_MESH: "#00FF00",
    LASER_CATHETER: "#D8D8D8",
    MUTED: "#222222",
};

export const stepBarStepNames = {
    PATIENT_INFO: "Patient Info",
    SEGMENTATION: "Segmentation",
    BIOPSY: "Pathology",
    CANCER_AI: "Cancer Lesion",
    SUMMARY: "Summary",
};

export const stepMetaData = {
    0: [
        {
            active: true,
            complete: false,
            name: stepBarStepNames.PATIENT_INFO,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.SEGMENTATION,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.BIOPSY,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.CANCER_AI,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.SUMMARY,
        },
    ],
    1: [
        {
            active: false,
            complete: true,
            name: stepBarStepNames.PATIENT_INFO,
        },
        {
            active: true,
            complete: false,
            name: stepBarStepNames.SEGMENTATION,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.BIOPSY,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.CANCER_AI,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.SUMMARY,
        },
    ],
    2: [
        {
            active: false,
            complete: true,
            name: stepBarStepNames.PATIENT_INFO,
        },
        {
            active: false,
            complete: true,
            name: stepBarStepNames.SEGMENTATION,
        },
        {
            active: true,
            complete: false,
            name: stepBarStepNames.BIOPSY,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.CANCER_AI,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.SUMMARY,
        },
    ],
    3: [
        {
            active: false,
            complete: true,
            name: stepBarStepNames.PATIENT_INFO,
        },
        {
            active: false,
            complete: true,
            name: stepBarStepNames.SEGMENTATION,
        },
        {
            active: false,
            complete: true,
            name: stepBarStepNames.BIOPSY,
        },
        {
            active: true,
            complete: false,
            name: stepBarStepNames.CANCER_AI,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.SUMMARY,
        },
    ],
    4: [
        {
            active: false,
            complete: true,
            name: stepBarStepNames.PATIENT_INFO,
        },
        {
            active: false,
            complete: true,
            name: stepBarStepNames.SEGMENTATION,
        },
        {
            active: false,
            complete: true,
            name: stepBarStepNames.BIOPSY,
        },
        {
            active: false,
            complete: true,
            name: stepBarStepNames.CANCER_AI,
        },
        {
            active: true,
            complete: true,
            name: stepBarStepNames.SUMMARY,
        },
    ],
};

export const freehandStepMetaData = {
    0: [
        {
            active: true,
            complete: false,
            name: stepBarStepNames.PATIENT_INFO,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.SEGMENTATION,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.SUMMARY,
        },
    ],
    1: [
        {
            active: false,
            complete: true,
            name: stepBarStepNames.PATIENT_INFO,
        },
        {
            active: true,
            complete: false,
            name: stepBarStepNames.SEGMENTATION,
        },
        {
            active: false,
            complete: false,
            name: stepBarStepNames.SUMMARY,
        },
    ],
    2: [
        {
            active: false,
            complete: true,
            name: stepBarStepNames.PATIENT_INFO,
        },
        {
            active: false,
            complete: true,
            name: stepBarStepNames.SEGMENTATION,
        },
        {
            active: true,
            complete: true,
            name: stepBarStepNames.SUMMARY,
        },
    ],
};

export const USER_MANUAL_URL = "https://avendahealth.com/redirects/manual";

export const TRAINING_URL = "https://avendahealth.com/redirects/training";

export const AVENDA_DEFAULT_ABLATION_PROFILE_USER_UUID = "global";
export const AVENDA_DEFAULT_ABLATION_PROFILE_UUID = "default";

export const avendaDefaultAblationProfileVolumeNames = {
    MED: "14W_150s_54C_Burn_DS",
    LARGE: "14W_260s_60C_Burn_DS",
    SMALL: "14W_90s_48C_Burn_DS",
};

export const TOTAL_FREEHAND_STEPS = 3;
export const TOTAL_DEFAULT_STEPS = 5;

export const AVENDA_COMPONENT_COLORS = {
    BLUE: "#0049C6",
    GRAY: "#A4B0B8",
};

export const cardTitleText = "Delete Ablation Profile?";

export const cardInfoText =
    "Are you sure you want to delete this profile? This action cannot be undone.";
