import React, { useState } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { If, Then } from "react-if";
import { EMPTY_STRING } from "../../../constants";
import {
    cpmProgressTrackerHeader,
    cpmProgressTrackerSubtitles,
} from "../constants";

const CancerMapProgressTracker = ({ mapProgressMessages, cemError }) => {
    const [showMessage, setShowMessage] = useState(false);

    let progress = 0;
    if (mapProgressMessages.length > 0) {
        let latestMsg = mapProgressMessages[mapProgressMessages.length - 1];
        if (latestMsg && latestMsg.progressPercent) {
            progress = latestMsg.progressPercent;
        }
    }
    let headerMsg = cemError
        ? cpmProgressTrackerHeader.CEM_ERROR
        : cpmProgressTrackerHeader.CEM_LOADING;
    const toggleMessage = () => {
        setShowMessage(!showMessage);
    };

    return (
        <div id={"cpm-loading-screen"}>
            <div className={"cmap-progress-container"} onClick={toggleMessage}>
                <div className={"cmap-progress-header"}>{headerMsg}</div>
                <div className={"cmap-progress-text"}>
                    {Number(progress).toFixed(2)}%
                </div>
                <div className={"cmap-progress-bar"}>
                    <div
                        className={"cmap-progress-bar-stroke"}
                        style={{ width: `${progress}%` }}
                    />
                </div>
                <If condition={cemError}>
                    <Then>
                        <div className={"cmap-error-container"}>
                            <div
                                className={
                                    "cmap-error-text-body display-regular-16 user-select-text"
                                }
                            >
                                <div className={"cmap-error-code"}>
                                    {cpmProgressTrackerSubtitles.ERROR_CODE}
                                    {cemError
                                        ? cemError.errorCode
                                        : EMPTY_STRING}
                                </div>
                                <div className={"cmap-error-request-uuid"}>
                                    {cpmProgressTrackerSubtitles.REQUEST_UUID}
                                    {cemError && cemError.requestUuid
                                        ? cemError.requestUuid
                                        : EMPTY_STRING}
                                </div>
                                <div className={"cmap-error-timestamp"}>
                                    {cpmProgressTrackerSubtitles.TIMESTAMP}
                                    {cemError
                                        ? cemError.timestamp
                                        : EMPTY_STRING}
                                </div>
                                <div className={"cmap-error-details"}>
                                    {cpmProgressTrackerSubtitles.DETAILS}
                                    {cemError && cemError.errorDetails
                                        ? cemError.errorDetails.split(
                                              " Trace: "
                                          )[0]
                                        : EMPTY_STRING}
                                </div>
                            </div>
                        </div>
                    </Then>
                </If>
            </div>
            <If condition={showMessage}>
                <Then>
                    <div className={"cmap-verbose-logs"}>
                        {mapProgressMessages.map((item, index) => (
                            <div key={index}>
                                <p>
                                    {cpmProgressTrackerSubtitles.MESSAGE}
                                    {item && item.message
                                        ? item.message
                                        : EMPTY_STRING}
                                </p>
                                <p>
                                    {cpmProgressTrackerSubtitles.PAYLOAD}
                                    {item && item.payload
                                        ? JSON.stringify(item.payload)
                                        : EMPTY_STRING}
                                </p>
                            </div>
                        ))}
                    </div>
                </Then>
            </If>
        </div>
    );
};

CancerMapProgressTracker.propTypes = {
    mapProgressMessages: PropTypes.array.isRequired,
    cemError: PropTypes.object,
};

export default CancerMapProgressTracker;
