import {
    API_ENDPOINT,
    API_VERSION,
    API_VERSION_2,
    httpMethods,
    SUCCESS,
    SUCCESS_OTP_NEXT,
    clientErrorMessages,
    FAILURE,
    INCORRECT_CREDENTIALS,
    EMPTY_STRING,
    LOGIN_ATTEMPTS_EXCEEDED,
    EMAIL_ALREADY_TAKEN,
} from "../../constants";
import { profileEmailMessages } from "../Profile/constants";
import { vsprintf } from "sprintf-js";
import { rootStore } from "../../redux/store";
import { setErrorState } from "../../redux/error_banner/actions";

export async function signInFetchToken(un, pw) {
    if (un === EMPTY_STRING || pw === EMPTY_STRING) return null;

    return await fetch(
        vsprintf("%s/api/%s/user/%s/signInV2", [
            API_ENDPOINT,
            API_VERSION_2,
            un,
        ]),
        {
            method: httpMethods.POST,
            body: JSON.stringify({
                password: pw,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (
                (json.message === SUCCESS &&
                    (json.payload.status === SUCCESS ||
                        json.payload.status === SUCCESS_OTP_NEXT)) ||
                (json.message === FAILURE &&
                    json.payload.status === LOGIN_ATTEMPTS_EXCEEDED) ||
                (json.message === FAILURE &&
                    json.payload.status === INCORRECT_CREDENTIALS)
            ) {
                return json.payload;
            }

            throw new Error(clientErrorMessages.LOGIN_FAILED);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function signOut(un) {
    try {
        const response = await fetch(
            vsprintf("%s/api/%s/user/%s/signOut", [
                API_ENDPOINT,
                API_VERSION,
                un,
            ]),
            {
                method: httpMethods.POST,
                mode: "cors",
            }
        );

        const json = await response.json();
        if (json.message === SUCCESS && json.payload.status === SUCCESS) {
            if (window.pendo) {
                try {
                    window.pendo.clearSession();
                } catch {
                    () => {};
                }
            }
            return json.payload;
        } else {
            throw new Error(clientErrorMessages.LOGOUT_FAILED);
        }
    } catch (err) {
        rootStore.dispatch(setErrorState(true, String(err)));
        return null;
    }
}

export async function getUser(un, token) {
    return await fetch(
        vsprintf("%s/api/%s/user/%s", [API_ENDPOINT, API_VERSION, un]),
        {
            method: httpMethods.GET,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }

            throw new Error(clientErrorMessages.USER_UPDATE_FAILED);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function updateUser(un, token, profile) {
    return await fetch(
        vsprintf("%s/api/%s/user/%s", [API_ENDPOINT, API_VERSION, un]),
        {
            method: httpMethods.PUT,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            mode: "cors",
            body: JSON.stringify({
                User: profile,
            }),
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
            if (
                json.message === FAILURE &&
                json.payload.status === EMAIL_ALREADY_TAKEN
            ) {
                rootStore.dispatch(
                    setErrorState(
                        true,
                        profileEmailMessages.EMAIL_ALREADY_TAKEN
                    )
                );
                return json.payload;
            }

            throw new Error(clientErrorMessages.USER_UPDATE_FAILED);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}
