import React from "react";
import PropTypes from "prop-types";

const LockCircle = ({ fill, className }) => {
    const getLockFill = () =>
        getComputedStyle(document.body).getPropertyValue(
            "--standardPrimaryColor"
        );

    const lockFill = fill || getLockFill();

    return (
        <svg
            width="57"
            height="57"
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx="29" cy="29" r="28" fill={lockFill} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.082 22.9013L20.0821 18.1667C20.0883 13.1066 24.1888 9.00622 29.2488 9C34.3088 9.00622 38.4093 13.1066 38.4155 18.1667L38.4154 22.9013H38.6008C40.7529 22.9013 42.4974 24.6459 42.4974 26.798V40.826C42.4974 42.9783 40.7529 44.7228 38.6008 44.7228H19.8967C17.7446 44.7228 16 42.9783 16 40.826V26.798C16 24.6459 17.7446 22.9013 19.8967 22.9013H20.082ZM34.1847 18.1667C34.1847 15.4406 31.9748 13.2308 29.2488 13.2308C26.5228 13.2308 24.3129 15.4406 24.3129 18.1667V22.9013H34.1847L34.1847 18.1667ZM29.2616 28.9094C30.9517 28.9165 32.316 30.2923 32.3088 31.9824C32.3022 33.1387 31.6465 34.1933 30.6123 34.7108L31.0848 38.0158C31.0873 38.0331 31.089 38.0505 31.09 38.0679C31.0905 38.0769 31.0908 38.0858 31.0909 38.0947C31.091 38.0994 31.091 38.1042 31.0909 38.1089C31.0876 38.4468 30.8108 38.7182 30.4727 38.7147H28.0246C27.996 38.7147 27.9674 38.7126 27.939 38.7088C27.6043 38.6614 27.3714 38.3516 27.4187 38.017L27.8912 34.712C26.8442 34.1939 26.1837 33.1248 26.1886 31.9566C26.1957 30.2665 27.5716 28.9023 29.2616 28.9094Z"
                fill="white"
            />
        </svg>
    );
};

LockCircle.propTypes = {
    fill: PropTypes.string,
    className: PropTypes.string,
};

export default LockCircle;
