import React, { Component } from "react";
import * as viewerMethods from "./viewer";
import * as controlMethods from "./controls";
import "./styles.css";
import { connect } from "react-redux";
import { isEmpty } from "../../../../helpers/helpers";
import PropTypes from "prop-types";
import MRIModel from "../Structures/MRI/MRIModel";
import TargetSetController from "../Structures/Targets/TargetSetController";
import TargetSetModel from "../Structures/Targets/TargetSetModel";
import PatientAnatomyModel from "../Structures/PatientAnatomy/PatientAnatomyModel";
import CustomTargetModel from "../Structures/CustomTargets/CustomTargetModel";
import CustomTargetController from "../Structures/CustomTargets/CustomTargetController";
import PatientAnatomyController from "../Structures/PatientAnatomy/PatientAnatomyController";
import {
    setActiveCore,
    setIsCoreFocused,
    setIsOnMeshViewerControl,
} from "../../../CreateTPlan_3Biopsy/actions";
import { specialSelection } from "../../../../constants";
import { mouseEventName } from "./consts";

class MeshViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            _onMouseDown: false,
        };

        this.onWindowResize = controlMethods.onWindowResize.bind(this);
        this.onMouseMove = controlMethods.onMouseMove.bind(this);
        this.initScene = viewerMethods.initScene.bind(this);
        this.animate = viewerMethods.animate.bind(this);
        this.start = viewerMethods.start.bind(this);
        this.stop = viewerMethods.stop.bind(this);
        this.renderScene = viewerMethods.renderScene.bind(this);
        this.resetScene = viewerMethods.resetScene.bind(this);
        this.outlineActiveCore = viewerMethods.outlineActiveCore.bind(this);
    }

    componentDidMount() {
        this.props.mriModel.meshViewer = this;

        if (this.props.targetSetController) {
            this.props.targetSetController.meshViewer = this;
        }

        if (this.props.targetSetModel) {
            this.props.targetSetModel.meshViewer = this;
        }

        if (this.props.patientAnatomyModel) {
            this.props.patientAnatomyModel.meshViewer = this;
        }

        if (this.props.customTargetController) {
            this.props.customTargetController.meshViewer = this;
        }

        if (this.props.customTargetModel) {
            this.props.customTargetModel.meshViewer = this;
        }

        if (this.props.patientAnatomyController) {
            this.props.patientAnatomyController.meshViewer = this;
            this.props.patientAnatomyController.hoveredBxCoreTextElement =
                this.textDisplay;
        }

        this.initScene();
        window.addEventListener("resize", this.onWindowResize);
        window.addEventListener("pointermove", (e) =>
            this.onMouseMove(e, mouseEventName.HOVER)
        );
        window.addEventListener("click", (e) =>
            this.onMouseMove(e, mouseEventName.CLICK)
        );
    }

    componentWillUnmount() {
        this.stop();
        this.cleanup();

        window.removeEventListener("resize", this.onWindowResize);
        window.removeEventListener("pointermove", (e) =>
            this.onMouseMove(e, mouseEventName.HOVER)
        );
        window.removeEventListener("click", (e) =>
            this.onMouseMove(e, mouseEventName.CLICK)
        );
    }

    render() {
        return (
            <div
                className={"mesh-viewer"}
                style={this.props.style}
                ref={(mount) => {
                    this.mount = mount;
                }}
            >
                {this.outlineActiveCore(this.props.activeCore)}
                <p
                    id={"mesh-viewer-info-display"}
                    ref={(mount) => (this.textDisplay = mount)}
                />
            </div>
        );
    }
}

MeshViewer.propTypes = {
    activeCore: PropTypes.number,
    activateCoreClick: PropTypes.bool,
    isCoreFocused: PropTypes.bool,
    setActiveCore: PropTypes.func,
    setIsCoreFocused: PropTypes.func,
    setIsOnMeshViewerControl: PropTypes.func,
    mriModel: PropTypes.instanceOf(MRIModel),
    targetSetController: PropTypes.instanceOf(TargetSetController),
    targetSetModel: PropTypes.instanceOf(TargetSetModel),
    patientAnatomyModel: PropTypes.instanceOf(PatientAnatomyModel),
    customTargetController: PropTypes.instanceOf(CustomTargetController),
    customTargetModel: PropTypes.instanceOf(CustomTargetModel),
    patientAnatomyController: PropTypes.instanceOf(PatientAnatomyController),
    viewerInput: PropTypes.object,
    displaySettings: PropTypes.object,
    style: PropTypes.object,
};

MeshViewer.defaultProps = {
    activeCore: specialSelection.NONE_SELECTED,
    activateCoreClick: false,
};

Object.assign(MeshViewer.prototype, { ...viewerMethods });
Object.assign(MeshViewer.prototype, { ...controlMethods });

const mapStateToProps = function (state, ownProps) {
    let displaySettings = isEmpty(ownProps.displaySettings)
        ? state.ImageViewerReducer.displaySettings
        : ownProps.displaySettings;
    return {
        activeCore: state.CreatePlanBiopsyReducer.activeCore,
        isCoreFocused: state.CreatePlanBiopsyReducer.isCoreFocused,
        displaySettings: displaySettings,
        viewerInput: state.ImageViewerReducer.viewerInput,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setActiveCore: (core) => dispatch(setActiveCore(core)),
        setIsCoreFocused: (isFocused) => dispatch(setIsCoreFocused(isFocused)),
        setIsOnMeshViewerControl: (isOnControl) =>
            dispatch(setIsOnMeshViewerControl(isOnControl)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(MeshViewer);
