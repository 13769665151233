import React, { Component } from "react";
import AvendaLogo from "../__shared__/AvendaLogo";
import "./styles.css";
import {
    EMAIL_REGEX,
    EMPTY_STRING,
    SUCCESS,
    MAX_EMAIL_LENGTH,
    stepPath,
    clientErrorMessages,
} from "../../constants";
import { sendPasswordReset } from "../../helpers/backend_api";
import { signUpFormFields } from "../SignUpFlow/constants";
import AHButton from "../__shared__/StyleGuide/AHButton";
import AHAlphaField from "../__shared__/StyleGuide/AHAlphaField";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { rootStore } from "../../redux/store";
import { setErrorState } from "../../redux/error_banner/actions";
import ErrorBanner from "../__shared__/ErrorBanner";

export class PasswordRecover extends Component {
    constructor(props) {
        super(props);
        this.renderForm = this.renderForm.bind(this);
        this.renderConfirmation = this.renderConfirmation.bind(this);

        this.state = {
            displayForm: true,
            email: EMPTY_STRING,
        };
    }

    handleSubmit = () => {
        if (
            this.state.email === EMPTY_STRING ||
            !this.state.email.match(EMAIL_REGEX)
        ) {
            window.alert("Please enter a valid email.");
            return;
        }

        let input = { email: this.state.email };
        sendPasswordReset(input)
            .then((response) => {
                if (response.payload.status === SUCCESS) {
                    this.setState({ displayForm: false });
                    return;
                } else {
                    rootStore.dispatch(
                        setErrorState(
                            true,
                            clientErrorMessages.UNABLE_TO_REACH_SERVER
                        )
                    );
                }
            })
            .catch(() => {
                rootStore.dispatch(
                    setErrorState(
                        true,
                        clientErrorMessages.UNABLE_TO_REACH_SERVER
                    )
                );
            });
    };

    onChange = (EMPTY_STRING, value) => {
        this.setState({ email: value });
    };

    handleBack = () => {
        this.props.history.push(stepPath.LOGIN);
    };

    renderForm() {
        return (
            <div className={"signup-screen"}>
                <ErrorBanner />
                <AvendaLogo to={"/login"} id={"signup-screen-avenda-logo"} />

                <div className={"signup-form"}>
                    <p
                        className={"display-36 black-text"}
                        style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                        Forgot Your Password?
                    </p>

                    <p
                        className={"display-14 black-text"}
                        style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                        Enter your email and we&apos;ll send you a recover link.
                    </p>

                    <AHAlphaField
                        label={signUpFormFields.EMAIL}
                        value={this.state.email}
                        placeholder={signUpFormFields.EMAIL}
                        onChange={this.onChange}
                        id={"registration-text-email"}
                        className={"ah-alpha-field-wrapper"}
                        type={"text"}
                        maxLength={MAX_EMAIL_LENGTH}
                        showLabel={false}
                    />

                    <AHButton
                        isOutline={true}
                        isSecondary={true}
                        size={"large"}
                        isDynamic={false}
                        text={"Back"}
                        className={"left"}
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleBack();
                        }}
                    />

                    <AHButton
                        isOutline={false}
                        isSecondary={false}
                        size={"large"}
                        isDynamic={false}
                        text={"Next"}
                        className={"right"}
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleSubmit();
                        }}
                    />

                    <p>&nbsp;</p>
                </div>
            </div>
        );
    }

    renderConfirmation() {
        return (
            <div className={"signup-screen"}>
                <AvendaLogo id={"signup-screen-avenda-logo"} />

                <div className={"signup-form"}>
                    <p
                        className={"display-36 black-text"}
                        style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                        Check Your Email
                    </p>

                    <p
                        className={"display-14 black-text"}
                        style={{ textAlign: "center", marginBottom: "20px" }}
                    >
                        A confirmation link has been sent to{" "}
                        <div id={"bold-text"}>{this.state.email}</div>
                        <br />
                        if this email matches our record.
                        <br />
                        <br />
                        Please follow the instructions there to proceed.
                        <br />
                        <br />
                        For assistance, contact{" "}
                        <div id={"bold-text"}>support@avendahealth.com</div>
                    </p>

                    <AHButton
                        isOutline={true}
                        isSecondary={true}
                        size={"large"}
                        isDynamic={false}
                        text={"Back"}
                        className={"center"}
                        onClick={(e) => {
                            e.preventDefault();
                            this.handleBack();
                        }}
                    />
                </div>
            </div>
        );
    }

    render() {
        return this.state.displayForm
            ? this.renderForm()
            : this.renderConfirmation();
    }
}

PasswordRecover.propTypes = {
    history: PropTypes.object,
};

export default withRouter(PasswordRecover);
