import React, { Fragment, useEffect, useState } from "react";
import "./styles.css";
import PatientNotesTabItem from "../PatientNotesTabItem";
import AHButton from "../../StyleGuide/AHButton";
import { createNote, formatDate, getAllNotes } from "../helpers";
import { connect } from "react-redux";
import { setCurrentTreatmentPlan } from "../../../CreateTPlan_1Upload/actions";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../../constants";
import {
    PATIENT_SHARE_ADD_NOTE_BUTTON_TEXT,
    PATIENT_SHARE_ADD_NOTE_PLACEHOLDER,
    PATIENT_SHARE_NO_NOTES,
    PATIENT_SHARE_NOTES_TITLE,
} from "../constants";

function PatientNotesTab(props) {
    const [notes, setNotes] = useState([]);
    const [newNoteContent, setNewNoteContent] = useState(EMPTY_STRING);

    // TODO: Cleanup component, refactor useEffect(), look into using useCallback()
    useEffect(() => {
        if (
            props.currentTreatmentPlan &&
            props.currentTreatmentPlan.TreatmentPlan &&
            props.currentTreatmentPlan.TreatmentPlan.PatientData
        ) {
            updatePatientNotes();
        }
    }, []);

    async function updatePatientNotes() {
        const getNotesRequest = {
            token: props.authToken,
            patientUuid:
                props.currentTreatmentPlan.TreatmentPlan.PatientData
                    .PatientUUID,
            planUuid:
                props.currentTreatmentPlan.TreatmentPlan.HeaderData.PlanUUID,
        };
        const getNotesResponse = await getAllNotes(getNotesRequest);

        if (getNotesResponse.payload["PlanNotes"]) {
            const planNotes = getNotesResponse.payload["PlanNotes"];
            await handleUpdatePlanNotesInTreatmentPlan(planNotes);

            const allPlanNotes = [];
            Object.keys(planNotes).forEach((stepKey) => {
                const stepNotes = planNotes[stepKey];
                allPlanNotes.push(...stepNotes);
            });

            allPlanNotes.sort(
                (note1, note2) =>
                    new Date(note1["Timestamp"]) - new Date(note2["Timestamp"])
            );
            setNotes(allPlanNotes);
        }
    }

    async function handleUpdatePlanNotesInTreatmentPlan(planNotes) {
        const { currentTreatmentPlan, setCurrentTreatmentPlan } = props;

        const updatedPlan = {
            ...currentTreatmentPlan,
            TreatmentPlan: {
                ...currentTreatmentPlan.TreatmentPlan,
                AdditionalData: {
                    ...currentTreatmentPlan.TreatmentPlan.AdditionalData,
                    PlanNotes: planNotes,
                },
            },
        };
        setCurrentTreatmentPlan(updatedPlan);
    }

    function buildPatientNotesTabItemList() {
        if (notes.length === 0) {
            return (
                <div className="display-regular-16 patient-notes-empty-text">
                    {PATIENT_SHARE_NO_NOTES}
                </div>
            );
        }

        return notes.map((note, index) => {
            const formattedDate = formatDate(note["Timestamp"]);
            return (
                <Fragment key={index}>
                    <PatientNotesTabItem
                        doctorName={note["Username"]}
                        doctorEmail={note["Email"]}
                        date={formattedDate}
                        note={note["Content"]}
                    />
                </Fragment>
            );
        });
    }

    function handleTextAreaChange(e) {
        setNewNoteContent(e.target.value);
    }

    async function handleCreateNote(step) {
        const createNoteRequest = {
            token: props.authToken,
            userUuid: props.userData.uuid,
            patientUuid:
                props.currentTreatmentPlan.TreatmentPlan.PatientData
                    .PatientUUID,
            planUuid:
                props.currentTreatmentPlan.TreatmentPlan.HeaderData.PlanUUID,
            step: step,
            content: newNoteContent,
        };
        await createNote(createNoteRequest);
        setNewNoteContent(EMPTY_STRING);
        await updatePatientNotes();
    }

    return (
        <div className="patient-notes-tab">
            <div className="patient-notes-title display-regular-20">
                {PATIENT_SHARE_NOTES_TITLE}
            </div>
            <div className="patient-notes-content">
                {buildPatientNotesTabItemList()}
            </div>
            <div className="patient-notes-tab-add-note">
                <textarea
                    className="add-note-textarea"
                    placeholder={PATIENT_SHARE_ADD_NOTE_PLACEHOLDER}
                    rows="4"
                    value={newNoteContent}
                    onChange={handleTextAreaChange}
                />
                {props.currentTreatmentPlan.TreatmentPlan &&
                newNoteContent !== EMPTY_STRING ? (
                    <AHButton
                        id="add-note-btn-active"
                        className="add-note-button"
                        text={PATIENT_SHARE_ADD_NOTE_BUTTON_TEXT}
                        onClick={() => handleCreateNote(props.step.toString())}
                    />
                ) : (
                    <AHButton
                        id="add-note-btn-disabled"
                        className="add-note-button"
                        text={PATIENT_SHARE_ADD_NOTE_BUTTON_TEXT}
                        isDisabled={true}
                    />
                )}
            </div>
        </div>
    );
}

PatientNotesTab.propTypes = {
    authToken: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    currentTreatmentPlan: PropTypes.object.isRequired,
    setCurrentTreatmentPlan: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
};

const mapStateToProps = function (state) {
    return {
        authToken: state.LoginReducer.authToken,
        userData: state.LoginReducer.userData,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientNotesTab);
