import React, { Component } from "react";
import uptick from "../../../assets/uptick.svg";
import downtick from "../../../assets/downtick.svg";
import { ASCENDING, DESCENDING, EMPTY_STRING } from "../../../constants";
import "./styles.css";
import PropTypes from "prop-types";

const selectedStyle = { color: "#000000" };
const regularStyle = { color: "#6c757d" };

export default class ListToggle extends Component {
    render() {
        let tick;
        if (this.props.selected) {
            tick = this.props.order === ASCENDING ? uptick : downtick;
        } else {
            tick = "";
        }
        let selectStyle = this.props.selected ? selectedStyle : regularStyle;
        return (
            <div
                className={"list-toggle-box"}
                id={this.props.id}
                onClick={this.props.callback}
            >
                <p
                    className={"display-22"}
                    style={{ ...this.props.textStyle, ...selectStyle }}
                >
                    {this.props.text}
                </p>
                <img src={tick} alt={""} />
            </div>
        );
    }
}

ListToggle.propTypes = {
    selected: PropTypes.bool,
    order: PropTypes.bool,
    text: PropTypes.string,
    callback: PropTypes.func,
    id: PropTypes.string,
    textStyle: PropTypes.object,
};

ListToggle.defaultProps = {
    selected: false,
    order: DESCENDING,
    text: EMPTY_STRING,
    callback: undefined,
};
