import * as THREE from "three";
import StructureModel from "../PatientAnatomy/StructureModel";
import { EMPTY_STRING, volumeNames } from "../../../../../constants";
import { GENERIC_MATERIAL } from "../../MeshViewer/materials";
import { CUSTOM_TARGET_NAMES, SPHERE_SEGMENTS } from "./consts";
import { VOLUME_SHAPES } from "../../../../CreateTPlan_4EditMargin/constants";
import { removeEntity } from "../../../../../helpers/threejs_helpers";
import FrameAxes from "../Other/FrameAxes";
import { makeCustomCatheterObject } from "../../../../../helpers/load_helpers";

export default class CustomTargetModel extends StructureModel {
    constructor(shape) {
        super();

        this._laserCatheterVisible = true;
        this._volumeShape = shape ? shape : EMPTY_STRING;
        this._xDim = 1;
        this._yDim = 1;
        this._zDim = 1;
        this._laserCatheterOffset = 0;
    }

    get laserCatheterOffset() {
        return this._laserCatheterOffset;
    }

    set laserCatheterOffset(value) {
        this._laserCatheterOffset = value;

        if (!this._meshViewer) {
            return;
        }

        removeEntity(
            this._meshViewer.RASframe,
            `${CUSTOM_TARGET_NAMES.LC_NAME}_${this.ID}`
        );

        this._laserCatheter = makeCustomCatheterObject(
            this._laserCatheterOffset,
            this.ID
        );

        this._laserCatheter.name = `${CUSTOM_TARGET_NAMES.LC_NAME}_${this.ID}`;

        this._meshViewer.RASframe.add(this._laserCatheter);
    }

    get xDim() {
        return this._xDim;
    }

    set xDim(val) {
        this._xDim = val;
    }

    get yDim() {
        return this._yDim;
    }

    set yDim(val) {
        this._yDim = val;
    }

    get zDim() {
        return this._zDim;
    }

    set zDim(val) {
        this._zDim = val;
    }

    get ID() {
        return this._ID;
    }

    set ID(id) {
        this._ID = id;
        this.name = `${volumeNames.CUSTOM_ABLATION_VOLUME}_${id}`;
    }

    get volumeShape() {
        return this._volumeShape;
    }

    set volumeShape(shape) {
        this._volumeShape = shape;
    }

    get laserCatheterVisibility() {
        return this._laserCatheterVisible;
    }

    set laserCatheterVisibility(visibility) {
        this._laserCatheterVisible = visibility;

        if (this._selfMeshView) {
            this._laserCatheter.visible = visibility;
        }
    }

    initializeView() {
        this.position = new THREE.Vector3(0, 0, 0);
        this._createMeshTargetView();
        this._initialized = true;
    }

    scaleCustomTargetMesh() {
        if (!this._meshViewer) {
            return;
        }

        if (!this._selfMeshView) {
            return;
        }

        let obj = this._meshViewer.RASframe.getObjectByName(
            CUSTOM_TARGET_NAMES.TARGET_NAME
        );

        obj.scale.x = this._xDim;
        obj.scale.y = this._yDim;
        obj.scale.z = this._zDim;
    }

    _createMeshTargetView() {
        if (!this._meshViewer) {
            return;
        }

        let geometry;

        switch (this._volumeShape) {
            case VOLUME_SHAPES.CUBE:
                geometry = new THREE.BoxGeometry(
                    this._xDim,
                    this._xDim,
                    this._xDim
                );
                break;
            case VOLUME_SHAPES.SPHERE:
                geometry = new THREE.SphereGeometry(
                    this._xDim,
                    SPHERE_SEGMENTS,
                    SPHERE_SEGMENTS
                );
                break;
            case VOLUME_SHAPES.PRISM:
                geometry = new THREE.BoxGeometry(
                    this._xDim,
                    this._yDim,
                    this._zDim
                );
                break;
            case VOLUME_SHAPES.ELLIPSE:
                geometry = new THREE.SphereGeometry(
                    this._xDim,
                    SPHERE_SEGMENTS,
                    SPHERE_SEGMENTS
                );
                break;
            default:
                break;
        }

        let material = GENERIC_MATERIAL.clone();
        material.opacity = 0.6;
        material.transparent = true;
        material.visible = true;
        material.wireframe = true;
        material.color = new THREE.Color("white");

        let targetObj = new THREE.Mesh(geometry, material);
        targetObj.name = CUSTOM_TARGET_NAMES.TARGET_NAME;

        if (!this._laserCatheter) {
            this._laserCatheter = makeCustomCatheterObject(
                this._laserCatheterOffset,
                this.ID
            );
        }

        if (!this._axesObject) {
            this._axesObject = makeAxesObject(this._meshViewer);
            this._axesObject.name = CUSTOM_TARGET_NAMES.AXES_NAME;
        }

        this._selfMeshView = targetObj;
        this._meshViewer.RASframe.add(targetObj);
        this._meshViewer.RASframe.add(this._laserCatheter);
    }

    delete() {
        if (!this._selfMeshView) {
            return;
        }

        if (this._axesObject) {
            this._axesObject.delete();
        }

        removeEntity(
            this._meshViewer.RASframe,
            `${CUSTOM_TARGET_NAMES.LC_NAME}_${this.ID}`
        );
        removeEntity(
            this._meshViewer.RASframe,
            CUSTOM_TARGET_NAMES.TARGET_NAME
        );
        removeEntity(
            this._meshViewer.RASframe,
            `${CUSTOM_TARGET_NAMES.AXES_NAME}_${volumeNames.BOTH}`
        );

        super.delete();

        this._laserCatheter = null;
        this._axesObject = null;
    }
}

function makeAxesObject(meshViewer) {
    let frameTransform = new THREE.Matrix4();
    frameTransform.set(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    let axes = new FrameAxes({
        originSphereVisible: false,
        noText: true,
        axisLength: 40,
    });
    axes.meshViewer = meshViewer;
    axes.initializeView(frameTransform, CUSTOM_TARGET_NAMES.AXES_NAME);
    return axes;
}
