import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass.js";
import { CopyShader } from "three/examples/jsm/shaders/CopyShader.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { deleteAllObjectsInScene } from "../../../../helpers/threejs_helpers";
import FrameAxes from "../Structures/Other/FrameAxes";
import CustomTrackballControls from "../CustomTrackballControls";
import { numCubeFaces } from "./consts";
import { LPS2RAS } from "../helpers";
import { specialSelection } from "../../../../constants";

export function initScene() {
    this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.setSize(this.mount.clientWidth, this.mount.clientHeight);
    this.renderer.sortObjects = false;

    let size = this.renderer.getDrawingBufferSize(new THREE.Vector2());
    this.renderTarget = new THREE.WebGLRenderTarget(size.width, size.height, {
        samples: 8,
    });

    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
        75,
        this.mount.clientWidth / this.mount.clientHeight,
        3,
        1000
    );

    // This is for Tool Profile's Mesh Viewer to be rendered correctly
    this.camera.position.z = 100;

    this.RASframe = new THREE.Object3D();

    this.RASframe.matrixAutoUpdate = false;
    this.RASframe.applyMatrix4(LPS2RAS);

    this.scene.add(this.RASframe);

    this.lights = [];

    for (let i = 0; i < numCubeFaces; i++) {
        let light = new THREE.PointLight(0xffffff, 0.7);
        this.scene.add(light);
        this.lights.push(light);
    }

    const origin = new THREE.Vector3(0, 0, 0);
    this.setLightCenter(origin);

    this.mount.appendChild(this.renderer.domElement);

    this.controls = new CustomTrackballControls(
        this.camera,
        this.mount,
        this.props.mriController,
        this.props.setIsOnMeshViewerControl
    );

    this.controls.init({
        rotateSpeed: 5.0,
        zoomSpeed: 5,
        panSpeed: 1,
        noZoom: false,
        noPan: false,
        staticMoving: true,
        dynamicDampingFactor: 0.3,
        invertMouseX: true,
    });

    this.controls.start();

    if (this.props.displaySettings.composerEnabled) {
        this.initComposerAndOutlinePass();
    }

    this.start();
}

export function initComposerAndOutlinePass() {
    this.composer = new EffectComposer(this.renderer, this.renderTarget);

    this.renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(this.renderPass);

    this.outlinePass = new OutlinePass(
        new THREE.Vector2(this.mount.clientWidth, this.mount.clientHeight),
        this.scene,
        this.camera
    );
    this.composer.addPass(this.outlinePass);

    this.outlinePass.renderToScreen = true;
    this.outlinePass.edgeStrength = 3;
    this.outlinePass.edgeGlow = 0;
    this.outlinePass.edgeThickness = 1;
    this.outlinePass.visibleEdgeColor.set(0xf0f0f0);
    this.outlinePass.hiddenEdgeColor.set(0xf0f0f0); //0x190a05

    const pixelRatio = this.renderer.getPixelRatio();
    this.effectFXAA = new ShaderPass(FXAAShader);
    this.effectFXAA.uniforms["resolution"].value.x =
        1 / (this.mount.clientWidth * pixelRatio);
    this.effectFXAA.uniforms["resolution"].value.y =
        1 / (this.mount.clientHeight * pixelRatio);
    this.effectFXAA.renderToScreen = false;
    this.composer.addPass(this.effectFXAA);

    this.outputPass = new ShaderPass(CopyShader);
    this.outputPass.renderToScreen = true;
    this.composer.addPass(this.outputPass);
}

export function setLightCenter(ctr) {
    const offsetAmount = 1000;
    const cubeOffsets = [
        new THREE.Vector3(0, 1, 0),
        new THREE.Vector3(0, -1, 0),
        new THREE.Vector3(1, 0, 0),
        new THREE.Vector3(-1, 0, 0),
        new THREE.Vector3(0, 0, -1),
        new THREE.Vector3(0, 0, 1),
    ];
    cubeOffsets.forEach((i) => i.multiplyScalar(offsetAmount).add(ctr));
    for (let i = 0; i < numCubeFaces; i++) {
        this.lights[parseInt(i)].position.copy(cubeOffsets[parseInt(i)]);
    }
}

export function setCenter(ctr) {
    this.controls.target.set(ctr.x, ctr.y, ctr.z);
    this.controls.update();
    this.setLightCenter(ctr);
}

export function start() {
    if (!this.frameId) {
        this.frameId = requestAnimationFrame(this.animate);
    }
}

export function stop() {
    cancelAnimationFrame(this.frameId);
}

export function animate() {
    this.controls.update();

    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
    this.rotateText();
}

export function renderScene() {
    if (this.composer) {
        this.composer.render(this.scene, this.camera);
    } else {
        this.renderer.render(this.scene, this.camera);
    }
}

export function cleanup() {
    deleteAllObjectsInScene(this.scene);
    this.renderer.forceContextLoss();
    this.renderer.dispose();
    this.renderer = null;
    this.scene = null;
}

export function showWorldAxes() {
    let frameTransform = new THREE.Matrix4();
    frameTransform.set(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    let text = "World Frame";
    let axes = new FrameAxes();
    axes.meshViewer = this;
    axes.initializeView(frameTransform, text);
    return axes;
}

export function rotateText() {
    let scope = this;
    this.scene.traverse((obj) => {
        if (obj.geometry && obj.geometry instanceof TextGeometry) {
            obj.lookAt(scope.camera.position);
            obj.up = scope.camera.up;
        }
    });
}

export function resetScene() {
    this.controls.reset();
    this.props.setActiveCore(specialSelection.NONE_SELECTED);
    this.props.setIsCoreFocused(false);
    this.outlinePass.selectedObjects = [];
    if (this.props.patientAnatomyController) {
        this.props.patientAnatomyController.updateHoveredText(
            {},
            this.props.patientAnatomyController.hoveredBxCoreTextElement
        );
    }
}

export function outlineActiveCore(coreIndex) {
    if (this.props.patientAnatomyController) {
        this.props.patientAnatomyController.outlineActiveCore(coreIndex);
    }
}
