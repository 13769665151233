import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { Font } from "three/examples/jsm/loaders/FontLoader";
import StructureModel from "../PatientAnatomy/StructureModel";
import Fonts from "../../../../../helpers/threejs_fonts";
import { EMPTY_STRING } from "../../../../../constants";

export default class FrameAxes extends StructureModel {
    constructor(
        params = {
            originSphereVisible: true,
            noText: false,
            axisLength: axisLen,
        }
    ) {
        super();
        this._originSphereVisible = params.originSphereVisible;
        this._noText = params.noText;
        this._axisLength = params.axisLength ? params.axisLength : axisLen;
    }

    update(frameTransform) {
        let axesObjects = this._getCoordinateFrameAxes(
            frameTransform,
            this.name
        );

        this._selfXArrowView.position.copy(axesObjects.xArrow.position);
        this._selfXArrowView.quaternion.copy(axesObjects.xArrow.quaternion);
        this._selfYArrowView.position.copy(axesObjects.yArrow.position);
        this._selfYArrowView.quaternion.copy(axesObjects.yArrow.quaternion);
        this._selfZArrowView.position.copy(axesObjects.zArrow.position);
        this._selfZArrowView.quaternion.copy(axesObjects.zArrow.quaternion);
        this._selfOriginSphereView.position.copy(
            axesObjects.originSphere.position
        );
        this._selfOriginSphereView.quaternion.copy(
            axesObjects.originSphere.quaternion
        );
        this._selfFrameTextView.position.copy(axesObjects.frameText.position);
        this._selfFrameTextView.quaternion.copy(
            axesObjects.frameText.quaternion
        );
    }

    initializeView(frameTransform, text = EMPTY_STRING) {
        this.name = text;
        this._createMeshView(frameTransform, text);
    }

    _createMeshView(frameTransform, text) {
        if (!this._meshViewer) {
            return;
        }

        let axesObjects = this._getCoordinateFrameAxes(frameTransform, text);

        this._selfXArrowView = axesObjects.xArrow;
        this._selfYArrowView = axesObjects.yArrow;
        this._selfZArrowView = axesObjects.zArrow;
        this._selfOriginSphereView = axesObjects.originSphere;
        this._selfFrameTextView = axesObjects.frameText;

        this._selfMeshView = new THREE.Object3D();
        this._selfMeshView.add(this._selfXArrowView);
        this._selfMeshView.add(this._selfYArrowView);
        this._selfMeshView.add(this._selfZArrowView);
        this._selfMeshView.add(this._selfOriginSphereView);
        this._selfMeshView.add(this._selfFrameTextView);

        this._meshViewer.RASframe.add(this._selfMeshView);
    }

    _getCoordinateFrameAxes(frameTransform, text) {
        let center = new THREE.Vector3();
        center.setFromMatrixPosition(frameTransform);

        let xAxis = new THREE.Vector3(1, 0, 0);
        let yAxis = new THREE.Vector3(0, 1, 0);
        let zAxis = new THREE.Vector3(0, 0, 1);
        let origin = new THREE.Vector3(0, 0, 0);

        origin.applyMatrix4(frameTransform);

        let dif = new THREE.Vector3(
            Math.abs(center.x - origin.x),
            Math.abs(center.y - origin.y),
            Math.abs(center.z - origin.z)
        );

        origin.add(dif);

        let r3 = new THREE.Matrix3();
        r3.setFromMatrix4(frameTransform);
        xAxis.applyMatrix3(r3);
        yAxis.applyMatrix3(r3);
        zAxis.applyMatrix3(r3);

        let xArrow = new THREE.ArrowHelper(
            xAxis,
            origin,
            this._axisLength,
            xAxisColor
        );
        let yArrow = new THREE.ArrowHelper(
            yAxis,
            origin,
            this._axisLength,
            yAxisColor
        );
        let zArrow = new THREE.ArrowHelper(
            zAxis,
            origin,
            this._axisLength,
            zAxisColor
        );

        let axesHelper = new THREE.Object3D();

        //ADD ORIGIN SPHERE
        let geometry = new THREE.SphereGeometry(
            originSphereRadius,
            originSphereSegments,
            originSphereSegments
        );
        let material = new THREE.MeshBasicMaterial({ color: originColor });

        let originSphere = new THREE.Mesh(geometry, material);
        originSphere.position.set(origin.x, origin.y, origin.z);
        originSphere.visible = this._originSphereVisible;

        let txtGeom = new TextGeometry(text, textSettings);
        let frameText = new THREE.Mesh(txtGeom, TEXT_MATERIAL.clone());
        frameText.position.set(origin.x, origin.y, origin.z + textOffset);
        frameText.visible = !this._noText;

        axesHelper.add(xArrow);
        axesHelper.add(yArrow);
        axesHelper.add(zArrow);
        axesHelper.add(originSphere);
        axesHelper.add(frameText);

        return {
            xArrow: xArrow,
            yArrow: yArrow,
            zArrow: zArrow,
            originSphere: originSphere,
            frameText: frameText,
        };
    }
}

const axisLen = 20;
const xAxisColor = 0xff0000;
const yAxisColor = 0x00ff00;
const zAxisColor = 0x0000ff;
const originColor = 0xffffff;
const textOffset = 5;
const originSphereRadius = 3;
const originSphereSegments = 10;

const textSettings = {
    font: new Font(Fonts.helvetikaRegular),
    size: 1.5,
    height: 0.2,
    curveSegments: 20,
    bevelEnabled: false,
    bevelThickness: 5,
    bevelSize: 5,
    bevelSegments: 5,
};

export const TEXT_MATERIAL = new THREE.MeshLambertMaterial({
    color: 0xffffff,
    transparent: true,
    side: THREE.FrontSide,
});
