import React, { Component } from "react";
import { connect } from "react-redux";
import {
    handleLogout,
    setAuthToken,
    setFlag,
} from "./components/Login/actions";
import {
    acquirePlanLock,
    checkPlanLocked,
    checkSharedPatient,
    checkToken,
    releasePlanLock,
} from "./helpers/backend_api";
import { withRouter } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { loginReducerFlags, stepPath, SUCCESS } from "./constants";
import PropTypes from "prop-types";

export class SessionManager extends Component {
    constructor(props) {
        super(props);
        this.timeout = 1000 * 60 * this.props.timeoutDuration;
        this.debounce = 1000 * 60 * this.props.debounceDuration;
        this.logout = this.logout.bind(this);
        this.onIdle = this.onIdle.bind(this);
        this.onAction = this.onAction.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.authToken) {
            checkToken(sessionStorage.authToken)
                .then(async (tokenValid) => {
                    if (tokenValid) {
                        this.props.setAuthToken(sessionStorage.authToken);
                        // TODO this.props.restoreSessionState(sessionStorage);
                        this.props.setFlag({
                            flagId: loginReducerFlags.IS_AUTHENTICATED,
                            newFlagState: true,
                        });
                        // this.props.history.push('/home')
                    } else {
                        await this.logout();
                    }
                })
                .catch(() => {});
        }
        if (window.pendo) {
            if (!sessionStorage.authToken) {
                try {
                    window.pendo.clearSession();
                } catch {
                    () => {};
                }
            }
        }
    }

    async logout() {
        await releasePlanLock({
            token: this.props.authToken,
            useruuid: this.props.userUuid,
            planuuid: this.props.planUuid,
        });
        this.props.handleLogout();
        if (window.pendo) {
            try {
                window.pendo.clearSession();
            } catch {
                () => {};
            }
        }
        this.props.history.push(stepPath.LOGIN);
    }

    onIdle() {
        if (sessionStorage.authToken) {
            this.props.handleLogout();
            this.props.history.push(stepPath.LOGIN);
        }
    }

    onAction() {
        if (!this.props.unText || !this.props.authToken) {
            return;
        }

        // renew and validate token
        if (sessionStorage.authToken) {
            checkToken(sessionStorage.authToken)
                .then(async (tokenValid) => {
                    if (!tokenValid) {
                        await this.logout();
                    } else {
                        const checkSharedPatientResponse =
                            await checkSharedPatient({
                                patientuuid: this.props.patientUuid,
                                token: this.props.authToken,
                            });
                        if (checkSharedPatientResponse) {
                            const checkPlanLockedResponse =
                                await checkPlanLocked({
                                    token: this.props.authToken,
                                    planuuid: this.props.planUuid,
                                });
                            if (
                                checkPlanLockedResponse.status === SUCCESS &&
                                (checkPlanLockedResponse.planLocked === false ||
                                    (checkPlanLockedResponse.planLocked ===
                                        true &&
                                        checkPlanLockedResponse.lockHolderUserUuid ===
                                            this.props.userUuid))
                            ) {
                                await acquirePlanLock({
                                    token: this.props.authToken,
                                    planuuid: this.props.planUuid,
                                    useruuid: this.props.userUuid,
                                });
                            }
                        }
                    }
                })
                .catch(() => {});
        }
    }

    render() {
        return (
            <div id={"session-manager"}>
                <IdleTimer
                    element={document}
                    onIdle={this.onIdle}
                    stopOnIdle={false}
                    timeout={this.timeout}
                    debounce={this.debounce}
                    onAction={this.onAction}
                />
            </div>
        );
    }

    static propTypes = {
        unText: PropTypes.string,
        authToken: PropTypes.string,
        timeoutDuration: PropTypes.number,
        debounceDuration: PropTypes.number,
        setAuthToken: PropTypes.func,
        setFlag: PropTypes.func,
        history: PropTypes.object,
        handleLogout: PropTypes.func,
        userUuid: PropTypes.string,
        planUuid: PropTypes.string,
        patientUuid: PropTypes.string,
    };

    static defaultProps = {
        timeoutDuration: 15,
        debounceDuration: 1,
    };
}

const mapStateToProps = function (state) {
    return {
        unText: state.LoginReducer.unText,
        authToken: state.LoginReducer.authToken,
        userUuid: state.LoginReducer.useruuid,
        planUuid: state.CreatePlanReducer.planuuid,
        patientUuid: state.CreatePlanReducer.patientuuid,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setFlag: (flag) => dispatch(setFlag(flag)),
        setAuthToken: (token) => dispatch(setAuthToken(token)),
        handleLogout: () => dispatch(handleLogout()),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SessionManager)
);
