import React, { Component } from "react";
import { buttonSize, EMPTY_STRING, fields } from "../../constants";
import AHAlphaField from "../../../AHAlphaField";
import { MAX_EMAIL_LENGTH } from "../../../../../../constants";
import { If, Then } from "react-if";
import PasswordStrengthMeter from "../../../../../SignUpFlow/PasswordStrengthMeter";
import AHButton from "../../../AHButton";
import PropTypes from "prop-types";
import "./styles.css";

class TableView extends Component {
    render() {
        return (
            <>
                <tr>
                    <td>{fields.NEW_PASSWORD}</td>
                    <td>
                        <AHAlphaField
                            label={fields.NEW_PASSWORD}
                            value={this.props.newPassword}
                            placeholder={this.props.newPasswordPlaceholder}
                            id={this.props.newPasswordId}
                            type={"password"}
                            maxLength={MAX_EMAIL_LENGTH}
                            showLabel={false}
                            onChange={this.props.onChange}
                        />
                    </td>
                </tr>
                <If condition={this.props.newPassword.length > 0}>
                    <Then>
                        <tr>
                            <td></td>
                            <td>
                                <PasswordStrengthMeter
                                    password={this.props.newPassword}
                                    firstName={this.props.userData.firstName}
                                    lastName={this.props.userData.lastName}
                                    email={this.props.userData.email}
                                />
                            </td>
                        </tr>
                    </Then>
                </If>
                <tr>
                    <td>{fields.PASSWORD_CONFIRMATION}</td>
                    <td>
                        <AHAlphaField
                            label={fields.PASSWORD_CONFIRMATION}
                            value={this.props.confirmPassword}
                            placeholder={this.props.confirmPasswordPlaceholder}
                            id={this.props.confirmPasswordId}
                            type={"password"}
                            maxLength={MAX_EMAIL_LENGTH}
                            showLabel={false}
                            onChange={this.props.onChange}
                        />
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <AHButton
                            id="reset-pwd-submit-btn"
                            className={"password-submit"}
                            onClick={this.props.onSubmit}
                            isOutline={false}
                            isSecondary={false}
                            isDynamic={false}
                            size={buttonSize.LARGE}
                            text={fields.PASSWORD_SUBMIT}
                        />
                    </td>
                </tr>
            </>
        );
    }
}

TableView.propTypes = {
    newPassword: PropTypes.string,
    confirmPassword: PropTypes.string,
    userData: PropTypes.object,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    newPasswordId: PropTypes.string,
    confirmPasswordId: PropTypes.string,
    newPasswordPlaceholder: PropTypes.string,
    confirmPasswordPlaceholder: PropTypes.string,
};

TableView.defaultProps = {
    newPassword: EMPTY_STRING,
    confirmPassword: EMPTY_STRING,
    userData: {},
    newPasswordPlaceholder: EMPTY_STRING,
    confirmPasswordPlaceholder: EMPTY_STRING,
};

export default TableView;
