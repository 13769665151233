import * as THREE from "three";

let DEBUG_mriModelHook;
let DEBUG_patientAnatomyModelHook;
let DEBUG_targetSetModelHook;

// In a page level component, call setDebugHooks() with relevant inputReferences
export function setDebugHooks(inputReferences) {
    DEBUG_mriModelHook = inputReferences.mriModel;
    DEBUG_patientAnatomyModelHook = inputReferences.patientAnatomyModel;
    DEBUG_targetSetModelHook = inputReferences.targetSetModel;
}

// In selenium, browser.execute_script("return window.DEBUG_getDebugHookDump();")
window.DEBUG_getDebugHookDump = function () {
    return {
        mriModel_visibility: DEBUG_mriModelHook.visibility,
        mriModel_contrast: DEBUG_mriModelHook.contrast,
        mriModel_intensity: DEBUG_mriModelHook.intensity,
        mriModel_autoLevel: DEBUG_mriModelHook.autoLevel,
        mriModel_orientation: DEBUG_mriModelHook.orientation,
        mriModel_boundingBox: DEBUG_mriModelHook.boundingBox,
        mriModel_orientationMaxIndex: DEBUG_mriModelHook.orientationMaxIndex,
        mriModel_lutVisibility: DEBUG_mriModelHook.lutVisibility,
        patientAnatomyModel_prostateVisibility:
            DEBUG_patientAnatomyModelHook.prostate
                ? DEBUG_patientAnatomyModelHook.prostate.visibility
                : null,
        patientAnatomyModel_prostateUploadedVisibility:
            DEBUG_patientAnatomyModelHook.prostateUploaded
                ? DEBUG_patientAnatomyModelHook.prostateUploaded.visibility
                : null,
        patientAnatomyModel_prostateAIVisibility:
            DEBUG_patientAnatomyModelHook.prostateAI
                ? DEBUG_patientAnatomyModelHook.prostateAI.visibility
                : null,
        patientAnatomyModel_roisVisibility: DEBUG_patientAnatomyModelHook.rois
            ? DEBUG_patientAnatomyModelHook.rois[0].visibility
            : null,
        patientAnatomyModel_bxCoresNegativeVisibility:
            DEBUG_patientAnatomyModelHook.bxCores
                ? DEBUG_patientAnatomyModelHook.bxCores.filter(
                      (bxCore) =>
                          bxCore.gleasonGradeGroup === 0 && bxCore.visibility
                  ).length > 0
                : null,
        patientAnatomyModel_bxCoresPositiveVisibility:
            DEBUG_patientAnatomyModelHook.bxCores
                ? DEBUG_patientAnatomyModelHook.bxCores.filter(
                      (bxCore) =>
                          bxCore.gleasonGradeGroup >= 1 && bxCore.visibility
                  ).length > 0
                : null,
        patientAnatomyModel_marginVisibility:
            DEBUG_patientAnatomyModelHook.margin
                ? DEBUG_patientAnatomyModelHook.margin.visibility
                : null,
        targetSetModel_laserCatheterVisibility:
            DEBUG_targetSetModelHook.laserCatheterVisibility,
    };
};

// In selenium, browser.execute_script("return window.DEBUG_getMRIViewerSceneObjectByName("MRProstate");")
window.DEBUG_getMRIViewerSceneObjectByName = function (objectName) {
    if (DEBUG_mriModelHook && DEBUG_mriModelHook.mriViewer) {
        return _getSceneObj(DEBUG_mriModelHook.mriViewer, objectName);
    }
};

// In selenium, browser.execute_script("return window.DEBUG_getMeshViewerSceneObjectByName("MRProstate");")
window.DEBUG_getMeshViewerSceneObjectByName = function (objectName) {
    if (DEBUG_mriModelHook && DEBUG_mriModelHook.meshViewer) {
        return JSON.parse(
            JSON.stringify(
                DEBUG_mriModelHook.meshViewer.scene.getObjectByName(objectName)
            )
        );
    }
};

window.DEBUG_getMeshViewerObjectPositionByName = function (objectName) {
    if (DEBUG_mriModelHook && DEBUG_mriModelHook.meshViewer) {
        return _getObjWorldPosition(DEBUG_mriModelHook.meshViewer, objectName);
    }
};

window.DEBUG_isMRIViewerObjectVisible = function (objectName) {
    if (DEBUG_mriModelHook && DEBUG_mriModelHook.mriViewer) {
        return DEBUG_mriModelHook.mriViewer.scene.getObjectByName(objectName)
            .visible;
    }
};

window.DEBUG_getResetObjStats = function (has2DViewer = true) {
    if (DEBUG_mriModelHook && DEBUG_mriModelHook.meshViewer) {
        let position = DEBUG_mriModelHook.meshViewer.camera.position;
        let up = DEBUG_mriModelHook.meshViewer.camera.up;
        let target = DEBUG_mriModelHook.meshViewer.controls.target;

        if (has2DViewer) {
            return {
                mesh_viewer_controls: {
                    camera_position: [position.x, position.y, position.z],
                    camera_up: [up.x, up.y, up.z],
                    target: [target.x, target.y, target.z],
                },
                mri_viewer_camera_zoom:
                    DEBUG_mriModelHook.mriViewer.camera.zoom,
                slice_index: DEBUG_mriModelHook.sliceIndexPercent,
            };
        } else {
            return {
                mesh_viewer_controls: {
                    camera_position: [position.x, position.y, position.z],
                    camera_up: [up.x, up.y, up.z],
                    target: [target.x, target.y, target.z],
                },
            };
        }
    }
};

const _getObjWorldPosition = (viewer, objectName) => {
    let object = viewer.scene.getObjectByName(objectName);
    let position = new THREE.Vector3();
    if (object) {
        object.getWorldPosition(position);
        return [position.x, position.y, position.z];
    } else {
        return [0, 0, 0];
    }
};

const _getSceneObj = (viewer, objectName) => {
    let sceneObj = viewer.scene.getObjectByName(objectName);
    let reconstructedObj = JSON.parse(JSON.stringify(sceneObj));
    reconstructedObj.name = sceneObj.name;
    reconstructedObj.visible = sceneObj.visible;
    reconstructedObj.material = sceneObj.material;
    return reconstructedObj;
};
