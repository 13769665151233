import * as THREE from "three";

/*
    Slightly modified form of ami.js/controls/trackballortho.js
    Modified to switch panning to scroll wheel drag
    Modified to change mri slice on scroll wheel spin

    Overall pretty self explanatory once you realize its just a bunch of mouse event listeners
    that invoke linear algebra based on mouse coordinates to modify camera coordinates
 */

const EPS = 0.000001;

const stateEnum = {
    NONE: -1,
    ROTATE: NaN, // Not implemented
    ZOOM: 2,
    PAN: 1,
    SCROLL: 4,
    TOUCH_ROTATE: NaN, // Not implemented
    TOUCH_ZOOM_PAN: 5,
};

const touchEvents = {
    ONE_FINGER: 1,
    TWO_FINGER: 2,
};

export default class CustomOrthographicControls {
    constructor(objectUnderControl, domElement, mriController) {
        this.mousedown = this.mousedown.bind(this);
        this.mousemove = this.mousemove.bind(this);
        this.mouseup = this.mouseup.bind(this);
        this.mousewheel = this.mousewheel.bind(this);
        this.contextmenu = this.contextmenu.bind(this);
        this.touchstart = this.touchstart.bind(this);
        this.touchend = this.touchend.bind(this);
        this.touchmove = this.touchmove.bind(this);

        this.mriController = mriController;
        this.objectUnderControl = objectUnderControl;
        this.domElement = domElement ? domElement : document;

        // API

        this.enabled = true;

        this.screen = { left: 0, top: 0, width: 0, height: 0 };

        this.radius = 0;

        this.zoomSpeed = 1.2;
        this.maxZoom = 2.0;
        this.minZoom = 0.25;

        this.noZoom = false;
        this.noPan = false;
        this.invertMouseX = false;
        this.noSliceScroll = false;
        this.staticMoving = false;
        this.sumDeltaY = 0.0;
        this.dynamicDampingFactor = 0.2;

        // internals

        this.target = new THREE.Vector3();

        this._changed = true;

        this._state = stateEnum.NONE;
        this._prevState = stateEnum.NONE;
        this._eye = new THREE.Vector3();
        this._zoomStart = new THREE.Vector2();
        this._zoomEnd = new THREE.Vector2();
        this._touchZoomDistanceStart = 0;
        this._touchZoomDistanceEnd = 0;
        this._panStart = new THREE.Vector2();
        this._panEnd = new THREE.Vector2();

        // window level fire after...

        // for reset

        this.target0 = this.target.clone();
        this.position0 = this.objectUnderControl.position.clone();
        this.up0 = this.objectUnderControl.up.clone();
        this.zoom0 = this.objectUnderControl.zoom;

        this.left0 = this.objectUnderControl.left;
        this.right0 = this.objectUnderControl.right;
        this.top0 = this.objectUnderControl.top;
        this.bottom0 = this.objectUnderControl.bottom;

        // events

        this.changeEvent = { type: "change" };
        this.startEvent = { type: "start" };
        this.endEvent = { type: "end" };

        this.domElement.addEventListener(
            "contextmenu",
            this.contextmenu,
            false
        );
        this.domElement.addEventListener("mousedown", this.mousedown, false);
        this.domElement.addEventListener("wheel", this.mousewheel, false);

        this.domElement.addEventListener("touchstart", this.touchstart, false);
        this.domElement.addEventListener("touchend", this.touchend, false);
        this.domElement.addEventListener("touchmove", this.touchmove, false);

        this.handleResize();

        // force an update at start
        this.update();
    }

    handleResize() {
        if (this.domElement === document) {
            this.screen.left = 0;
            this.screen.top = 0;
            this.screen.width = window.innerWidth;
            this.screen.height = window.innerHeight;
        } else {
            let box = this.domElement.getBoundingClientRect();
            // adjustments come from similar code in the jquery offset() function
            let d = this.domElement.ownerDocument.documentElement;
            this.screen.left = box.left + window.pageXOffset - d.clientLeft;
            this.screen.top = box.top + window.pageYOffset - d.clientTop;
            this.screen.width = box.width;
            this.screen.height = box.height;
        }

        this.radius = 0.5 * Math.min(this.screen.width, this.screen.height);

        this.left0 = this.objectUnderControl.left;
        this.right0 = this.objectUnderControl.right;
        this.top0 = this.objectUnderControl.top;
        this.bottom0 = this.objectUnderControl.bottom;
    }

    handleEvent(event) {
        if (typeof this[event.type] == "function") {
            this[event.type](event);
        }
    }

    getMouseOnScreen(pageX, pageY) {
        let x = (pageX - this.screen.left) / this.screen.width;
        let y = (pageY - this.screen.top) / this.screen.height;

        let vector = new THREE.Vector2();
        vector.set(x, y);

        if (this.invertMouseX) {
            vector.x = -1 * vector.x;
        }

        return vector;
    }

    zoomCamera() {
        if (this._state === stateEnum.TOUCH_ZOOM_PAN) {
            let factor =
                this._touchZoomDistanceEnd / this._touchZoomDistanceStart;
            this._touchZoomDistanceStart = this._touchZoomDistanceEnd;

            this.objectUnderControl.zoom *= factor;

            this._changed = true;
        } else {
            let factor =
                1.0 + (this._zoomEnd.y - this._zoomStart.y) * this.zoomSpeed;

            if (Math.abs(factor - 1.0) > EPS && factor > 0.0) {
                this.objectUnderControl.zoom /= factor;

                if (this.objectUnderControl.zoom > this.maxZoom) {
                    this.objectUnderControl.zoom = this.maxZoom;
                } else if (this.objectUnderControl.zoom < this.minZoom) {
                    this.objectUnderControl.zoom = this.minZoom;
                }

                if (this.staticMoving) {
                    this._zoomStart.copy(this._zoomEnd);
                } else {
                    this._zoomStart.y +=
                        (this._zoomEnd.y - this._zoomStart.y) *
                        this.dynamicDampingFactor;
                }

                this._changed = true;
            }
        }
    }

    panCamera() {
        let mouseChange = new THREE.Vector2(),
            objectUnderControlUp = new THREE.Vector3(),
            pan = new THREE.Vector3();

        mouseChange.copy(this._panEnd).sub(this._panStart);

        if (mouseChange.lengthSq()) {
            // Scale movement to keep clicked/dragged position under cursor
            let scale_x =
                (this.objectUnderControl.left - this.objectUnderControl.right) /
                this.objectUnderControl.zoom;
            let scale_y =
                (this.objectUnderControl.top - this.objectUnderControl.bottom) /
                this.objectUnderControl.zoom;
            mouseChange.x *= scale_x;
            mouseChange.y *= scale_y;

            pan.copy(this._eye)
                .cross(this.objectUnderControl.up)
                .setLength(mouseChange.x);
            pan.add(
                objectUnderControlUp
                    .copy(this.objectUnderControl.up)
                    .setLength(mouseChange.y)
            );

            this.objectUnderControl.position.add(pan);
            this.target.add(pan);

            if (this.staticMoving) {
                this._panStart.copy(this._panEnd);
            } else {
                this._panStart.add(
                    mouseChange
                        .subVectors(this._panEnd, this._panStart)
                        .multiplyScalar(this.dynamicDampingFactor)
                );
            }

            this._changed = true;
        }
    }

    update() {
        this._eye.subVectors(this.objectUnderControl.position, this.target);

        if (!this.noZoom) {
            this.zoomCamera();

            if (this._changed) {
                this.objectUnderControl.updateProjectionMatrix();
            }
        }

        if (!this.noPan) {
            this.panCamera();
        }

        this.objectUnderControl.position.addVectors(this.target, this._eye);

        this.objectUnderControl.lookAt(this.target);

        if (this._changed) {
            this.dispatchEvent(this.changeEvent);

            this._changed = false;
        }
    }

    reset() {
        this._state = stateEnum.NONE;
        this._prevState = stateEnum.NONE;

        this.target.copy(this.target0);
        this.objectUnderControl.position.copy(this.position0);
        this.objectUnderControl.up.copy(this.up0);
        this.objectUnderControl.zoom = this.zoom0;

        this._eye.subVectors(this.objectUnderControl.position, this.target);

        this.objectUnderControl.left = this.left0;
        this.objectUnderControl.right = this.right0;
        this.objectUnderControl.top = this.top0;
        this.objectUnderControl.bottom = this.bottom0;

        this.objectUnderControl.lookAt(this.target);

        this.objectUnderControl.updateProjectionMatrix();
        this.dispatchEvent(this.changeEvent);
    }

    mousedown(event) {
        if (this.enabled === false) return;

        event.preventDefault();
        event.stopPropagation();
        if (this._state === stateEnum.NONE) {
            this._state = event.button;
        }

        if (this._state === stateEnum.ROTATE && !this.noRotate) {
            // Not implemented
        } else if (this._state === stateEnum.ZOOM && !this.noZoom) {
            this._zoomStart.copy(
                this.getMouseOnScreen(event.pageX, event.pageY)
            );
            this._zoomEnd.copy(this._zoomStart);
        } else if (
            (this._state === stateEnum.PAN || event.shiftKey) &&
            !this.noPan
        ) {
            this._panStart.copy(
                this.getMouseOnScreen(event.pageX, event.pageY)
            );
            this._panEnd.copy(this._panStart);
        }

        document.addEventListener("mousemove", this.mousemove, false);
        document.addEventListener("mouseup", this.mouseup, false);

        this.dispatchEvent(this.startEvent);
    }

    mousemove(event) {
        if (this.enabled === false) return;

        event.preventDefault();
        event.stopPropagation();

        if (this._state === stateEnum.ROTATE && !this.noRotate) {
            // Not implemented
        } else if (this._state === stateEnum.ZOOM && !this.noZoom) {
            this._zoomEnd.copy(this.getMouseOnScreen(event.pageX, event.pageY));
        } else if (
            (this._state === stateEnum.PAN || event.shiftKey) &&
            !this.noPan
        ) {
            this._panEnd.copy(this.getMouseOnScreen(event.pageX, event.pageY));
        }
    }

    mouseup(event) {
        if (this.enabled === false) return;

        event.preventDefault();
        event.stopPropagation();

        this._state = stateEnum.NONE;

        document.removeEventListener("mousemove", this.mousemove);
        document.removeEventListener("mouseup", this.mouseup);

        this.dispatchEvent(this.endEvent);
    }

    mousewheel(event) {
        const deltaYThreshold = 10.0;

        if (!this.enabled) {
            return;
        }

        if (this.noSliceScroll === true) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        this.sumDeltaY = this.sumDeltaY + Math.abs(event.deltaY);

        if (this.sumDeltaY > deltaYThreshold) {
            this.sumDeltaY = 0.0;
            if (this.mriController) {
                this.mriController.shiftSliceByWheel(event);
            }
        }
    }

    touchstart(event) {
        if (this.enabled === false) return;

        let dx, dy, x, y;

        switch (event.touches.length) {
            case touchEvents.ONE_FINGER:
                this._state = stateEnum.TOUCH_ROTATE;

                break;

            case touchEvents.TWO_FINGER:
                this._state = stateEnum.TOUCH_ZOOM_PAN;
                dx = event.touches[0].pageX - event.touches[1].pageX;
                dy = event.touches[0].pageY - event.touches[1].pageY;
                this._touchZoomDistanceEnd = this._touchZoomDistanceStart =
                    Math.sqrt(dx * dx + dy * dy);

                x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                this._panStart.copy(this.getMouseOnScreen(x, y));
                this._panEnd.copy(this._panStart);
                break;

            default:
                this._state = stateEnum.NONE;
        }
        this.dispatchEvent(this.startEvent);
    }

    touchmove(event) {
        if (this.enabled === false) return;

        event.preventDefault();
        event.stopPropagation();

        let dx, dy, x, y;

        switch (event.touches.length) {
            case touchEvents.ONE_FINGER:
                break;

            case touchEvents.TWO_FINGER:
                dx = event.touches[0].pageX - event.touches[1].pageX;
                dy = event.touches[0].pageY - event.touches[1].pageY;
                this._touchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy);

                x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                this._panEnd.copy(this.getMouseOnScreen(x, y));
                break;

            default:
                this._state = stateEnum.NONE;
        }
    }

    touchend(event) {
        if (this.enabled === false) return;

        let x, y;

        switch (event.touches.length) {
            case touchEvents.ONE_FINGER:
                break;

            case touchEvents.TWO_FINGER:
                this._touchZoomDistanceStart = this._touchZoomDistanceEnd = 0;

                x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                this._panEnd.copy(this.getMouseOnScreen(x, y));
                this._panStart.copy(this._panEnd);
                break;

            default:
                break;
        }

        this._state = stateEnum.NONE;
        this.dispatchEvent(this.endEvent);
    }

    contextmenu(event) {
        event.preventDefault();
    }

    dispose() {
        this.domElement.removeEventListener(
            "contextmenu",
            this.contextmenu,
            false
        );
        this.domElement.removeEventListener("mousedown", this.mousedown, false);
        this.domElement.removeEventListener("wheel", this.mousewheel, false);

        this.domElement.removeEventListener(
            "touchstart",
            this.touchstart,
            false
        );
        this.domElement.removeEventListener("touchend", this.touchend, false);
        this.domElement.removeEventListener("touchmove", this.touchmove, false);
    }

    addEventListener(type, listener) {
        if (!this._listeners) this._listeners = {};

        let listeners = this._listeners;

        if (listeners[String(type)] === undefined) {
            listeners[String(type)] = [];
        }

        if (listeners[String(type)].indexOf(listener) === -1) {
            listeners[String(type)].push(listener);
        }
    }

    hasEventListener(type, listener) {
        if (!this._listeners) return false;

        let listeners = this._listeners;

        return (
            listeners[String(type)] !== undefined &&
            listeners[String(type)].indexOf(listener) !== -1
        );
    }

    removeEventListener(type, listener) {
        if (!this._listeners) return;

        let listeners = this._listeners;
        let listenerArray = listeners[String(type)];

        if (listenerArray) {
            let index = listenerArray.indexOf(listener);

            if (index !== -1) {
                listenerArray.splice(index, 1);
            }
        }
    }

    dispatchEvent(event) {
        if (!this._listeners) return;

        let listeners = this._listeners;
        let listenerArray = listeners[event.type];

        if (listenerArray) {
            event.target = this;

            let array = listenerArray.slice(0);

            for (let i = 0, l = array.length; i < l; i++) {
                array[Number(i)].call(this, event);
            }
        }
    }
}
