import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import LoginReducer from "../components/Login/reducer";
import CreatePlanReducer from "../components/CreateTPlan_1Upload/reducer";
import CreatePlanSegReducer from "../components/CreateTPlan_2SegMRI/reducer";
import CreatePlanBiopsyReducer from "../components/CreateTPlan_3Biopsy/reducer";
import HomeReducer from "../components/Home/reducer";
import SignupReducer from "../components/SignUpFlow/reducer";
import ImageViewerReducer from "./image_viewer/reducer";
import ErrorBannerReducer from "./error_banner/reducer";
import storageSession from "redux-persist/lib/storage/session";
import CreateAblationReducer from "../components/CreateAblationProfile/reducer";

let persistConfig = {
    key: "root",
    storage: storageSession,
    blacklist: ["ImageViewerReducer"],
};

const fullCrystal = combineReducers({
    LoginReducer: LoginReducer,
    CreatePlanReducer: CreatePlanReducer,
    CreatePlanBiopsyReducer: CreatePlanBiopsyReducer,
    CreatePlanSegReducer: CreatePlanSegReducer,
    HomeReducer: HomeReducer,
    SignupReducer: SignupReducer,
    ImageViewerReducer: ImageViewerReducer,
    ErrorBannerReducer: ErrorBannerReducer,
    CreateAblationReducer: CreateAblationReducer,
});

const rootReducer = persistReducer(persistConfig, fullCrystal);

export const rootStore = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export const stickyStore = persistStore(rootStore);
