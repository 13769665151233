import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles.css";

export default class LoginSignupButton extends Component {
    render() {
        return (
            <div className={"login-signup-button"}>
                <Link to={"/signup"} id="signup-btn" className="no-underline">
                    <p className={"display-16"}>Sign Up</p>
                </Link>
            </div>
        );
    }
}
