import React, { Component } from "react";
import { connect } from "react-redux";
import { ProgressBar, Spinner } from "react-bootstrap";
import "./styles.css";
import {
    setParseProgress,
    setParseFailureMessage,
    setUploadState,
} from "../actions";
import { uploadStates } from "../constants";
import { getParsingStatus } from "../helpers";
import {
    EMPTY_STRING,
    MAX_PARSING_STATUS_MISSES,
    SUCCESS,
    CANNOT_CONNECT_CACHE,
    clientErrorMessages,
} from "../../../constants";
import PropTypes from "prop-types";

class UploadParseCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            missCount: 0,
        };

        this.updateParseProgress = this.updateParseProgress.bind(this);
    }

    componentDidMount() {
        let scope = this;
        let key;

        if (this.props.folderKey !== EMPTY_STRING) {
            key = scope.props.folderKey;
        } else if (this.props.fileKey !== EMPTY_STRING) {
            key = scope.props.fileKey;
        }

        this.pollProgress = setInterval(() => {
            getParsingStatus(key).then((response) => {
                if (response) {
                    scope.updateParseProgress(response);
                } else {
                    scope.props.setParseProgress(0);
                    scope.props.setParseFailureMessage(
                        clientErrorMessages.UNABLE_TO_REACH_SERVER
                    );
                    scope.props.setUploadState(uploadStates.FAILED);
                }
            });
        }, 1000);
    }

    updateParseProgress(response) {
        let scope = this;

        if (response.status === SUCCESS) {
            if (
                response.progress != null &&
                !isNaN(parseFloat(response.progress))
            ) {
                scope.props.setParseProgress(parseFloat(response.progress));
            } else {
                scope.props.setParseProgress(0);
                scope.props.setParseFailureMessage(
                    clientErrorMessages.PARSING_GET_PROGRESS_FAILED
                );
                scope.props.setUploadState(uploadStates.FAILED);
            }
        } else if (response.status === CANNOT_CONNECT_CACHE) {
            scope.props.setParseProgress(0);
            scope.setState({ missCount: scope.state.missCount + 1 });

            if (this.state.missCount === MAX_PARSING_STATUS_MISSES) {
                scope.props.setParseFailureMessage(
                    clientErrorMessages.PARSING_START_FAILED
                );
                scope.props.setUploadState(uploadStates.FAILED);
            }
        } else {
            scope.props.setParseProgress(0);
            scope.props.setParseFailureMessage(response.error);
            scope.props.setUploadState(uploadStates.FAILED);
        }
    }

    componentDidUpdate() {
        if (this.props.parseProgress >= 100) {
            clearInterval(this.pollProgress);
            this.props.setUploadState(uploadStates.COMPLETE);
        }
    }

    componentWillUnmount() {
        clearInterval(this.pollProgress);
    }

    render() {
        return (
            <div
                id={this.props.id}
                className={`upload-card progress solid`}
                style={this.props.style}
            >
                <div className={"finish-check-container"}>
                    <Spinner animation="grow" variant="primary" />
                </div>
                <div className={"upload-bar-container"}>
                    <div className={"upload-progress-bar"}>
                        <ProgressBar now={100} label={`Uploading Complete!`} />
                    </div>
                    <div className={"parse-progress-bar"}>
                        <ProgressBar
                            animated
                            now={this.props.parseProgress}
                            label={`Reading File... ${this.props.parseProgress}%`}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

UploadParseCard.propTypes = {
    id: PropTypes.string,
    parseProgress: PropTypes.string,
    setUploadState: PropTypes.func,
    style: PropTypes.object,
    folderKey: PropTypes.string,
    fileKey: PropTypes.string,
};

UploadParseCard.defaultProps = {
    id: EMPTY_STRING,
    parseProgress: "0",
    folderKey: EMPTY_STRING,
    fileKey: EMPTY_STRING,
};

/* istanbul ignore next */
const mapStateToProps = function (state, ownProps) {
    return {
        parseProgress: state.CreatePlanReducer.parseProgress[ownProps.idx],
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch, ownProps) {
    return {
        setUploadState: (upstate) =>
            dispatch(setUploadState(ownProps.idx, upstate)),
        setParseProgress: (prog) =>
            dispatch(setParseProgress(ownProps.idx, prog)),
        setParseFailureMessage: (msg) =>
            dispatch(setParseFailureMessage(ownProps.idx, msg)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadParseCard);
