import React, { Component } from "react";
import "./styles.css";
import { segmentationDisplayOptions } from "../../constants";
import PropTypes from "prop-types";
import backArrow from "../../../../assets/back_arrow.svg";
import prostateIcon from "../../../../assets/prostate_icon.svg";
import AISegmentationListItem from "../AISegmentationListItem";
import PatientAnatomyModel from "../../../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyModel";

class SelectAISegmentationPanel extends Component {
    constructor(props) {
        super(props);

        this.handleSegmentationCreation =
            this.handleSegmentationCreation.bind(this);
    }

    handleSegmentationCreation() {
        this.props.setWorkflowState(segmentationDisplayOptions.AI_SEG_LOADING);
    }

    render() {
        let doesAIProstateExist =
            this.props.patientAnatomyModel.prostateAI != null;
        return (
            <div id={"select-ai-segmentation-panel"}>
                <div className={"segmentation-listing-header"}>
                    <p className={"display-20"}>AI Segmentation</p>
                </div>
                <div
                    className={"segmentation-listing-back-arrow"}
                    onClick={() => {
                        this.props.setWorkflowState(
                            segmentationDisplayOptions.SEG_LISTING
                        );
                    }}
                >
                    <img src={backArrow} alt={"<"} />
                </div>
                <div className={"ai-segmentation-listing-cntr"}>
                    {[
                        <AISegmentationListItem
                            key={0}
                            text={"Prostate"}
                            iconSrc={prostateIcon}
                            segmentationExistsAlready={doesAIProstateExist}
                            onClick={this.handleSegmentationCreation}
                        />,
                    ]}
                </div>
            </div>
        );
    }
}

SelectAISegmentationPanel.propTypes = {
    setWorkflowState: PropTypes.func,
    patientAnatomyModel: PropTypes.instanceOf(PatientAnatomyModel),
};

SelectAISegmentationPanel.defaultProps = {
    setWorkflowState: () => {},
};

export default SelectAISegmentationPanel;
