import * as THREE from "three";

export var GENERIC_MATERIAL = new THREE.MeshLambertMaterial({
    color: "#ffffff",
    transparent: true,
    opacity: 0.4,
    side: THREE.DoubleSide,
});

export var LINE_MATERIAL = new THREE.LineBasicMaterial({
    color: 0xffffff,
});

export var TEXT_MATERIAL = new THREE.MeshLambertMaterial({
    color: 0xffffff,
    transparent: true,
    side: THREE.FrontSide,
});
