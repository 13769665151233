import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import {
    FlexibleXYPlot,
    LineSeries,
    VerticalGridLines,
    MarkSeries,
    HorizontalGridLines,
} from "react-vis";
import "react-vis/dist/style.css";
import { curveCatmullRom } from "d3-shape";
import MarksScoreLegend from "../MarksScoreLegend";
import {
    getCrossHairs,
    getMarksScoreGradient,
    getPlotXAxis,
    getPlotYAxis,
} from "./plot_helpers";

class MarksScorePlot extends Component {
    constructor(props) {
        super(props);

        this.mouseDown = false;
        this.plotPoints = [];
        this.lesionParams = {};
        this.curveTuple = {
            marksScore: [],
            prostateCoverage: [],
            cpmThreshold: [],
            lesionVolume: [],
        };
        this.state = {
            defaultMarkerSize: 8,
        };
        this.ref = React.createRef();

        this.handlePlotClick = this.handlePlotClick.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleDefaultPointClick = this.handleDefaultPointClick.bind(this);
        this.handleDefaultPointMouseOver =
            this.handleDefaultPointMouseOver.bind(this);
        this.handleDefaultPointMouseOut =
            this.handleDefaultPointMouseOut.bind(this);
        this._handleCurveUserInput = this._handleCurveUserInput.bind(this);
    }

    componentDidMount() {
        let sd = this.props.currentTreatmentPlan.SavedData;
        this.curveTuple.marksScore = sd.MarksScoreCurve;
        this.curveTuple.prostateCoverage = sd.ProstateCoverageCurve;
        this.curveTuple.cpmThreshold = sd.CPMThresholdCurve;
        this.curveTuple.lesionVolume = sd.LesionVolumeCurve;
        this.marksScoreGradient = getMarksScoreGradient(this.curveTuple);
        this.plotPoints = this.getPlotPoints(this.curveTuple);

        let divRect = this.ref.current.getBoundingClientRect();
        this.setState({ aspectRatio: divRect.width / divRect.height });

        document.addEventListener("mouseup", this.handleMouseUp);
        document.addEventListener("mousedown", this.handleMouseDown);
    }

    componentWillUnmount() {
        document.removeEventListener("mouseup", this.handleMouseUp);
        document.removeEventListener("mousedown", this.handleMouseDown);
    }

    handleMouseUp(e) {
        if (this.mouseDown) {
            let divRect = this.ref.current.getBoundingClientRect();
            if (!isInsideDiv(e, divRect)) {
                this.props.getNewLesionContourHandler(
                    this.lesionParams.cpmThreshold
                );
            }
        }

        this.mouseDown = false;
    }

    handleMouseDown(e) {
        let divRect = this.ref.current.getBoundingClientRect();
        if (isInsideDiv(e, divRect)) {
            this.mouseDown = true;
        }
    }

    handleMouseMove(e) {
        if (!this.mouseDown) {
            return;
        }

        this._handleCurveUserInput(e);
        this.props.setLesionParameters(this.lesionParams);
    }

    handlePlotClick(e) {
        this._handleCurveUserInput(e);
        this.props.setLesionParameters(this.lesionParams);
        this.props.getNewLesionContourHandler(this.lesionParams.cpmThreshold);
    }

    handleDefaultPointClick(e) {
        e.event.stopPropagation();
        e.event.preventDefault();

        this.lesionParams = this.getCurveTupleAtXValue(
            this.props.defaultProstateCoverage,
            this.curveTuple
        );
        this.props.setLesionParameters(this.lesionParams);
        this.props.getNewLesionContourHandler(this.lesionParams.cpmThreshold);
    }

    handleDefaultPointMouseOver() {
        this.setState({ defaultMarkerSize: 10 });
    }

    handleDefaultPointMouseOut() {
        this.setState({ defaultMarkerSize: 8 });
    }

    _handleCurveUserInput(e) {
        const fixedYAxisWidth = 39;
        const plotRightPaddingOffset = 8;

        let currentTargetRect = e.currentTarget.getBoundingClientRect();

        let correctedWidth =
            currentTargetRect.width - fixedYAxisWidth - plotRightPaddingOffset;

        let correctedLeftPosition = Math.min(
            Math.max(e.pageX - currentTargetRect.left - fixedYAxisWidth, 0),
            correctedWidth
        );

        let xPercent = correctedLeftPosition / correctedWidth;

        this.lesionParams = this.getCurveTupleAtXValue(
            xPercent,
            this.curveTuple
        );
    }

    getPlotPoints(curveTuple) {
        let plotPoints = [];
        for (let i = curveTuple.marksScore.length - 1; i >= 0; --i) {
            plotPoints.push({
                x: 100 * curveTuple.prostateCoverage[parseInt(i)],
                y: 100 * curveTuple.marksScore[parseInt(i)],
            });
        }
        return plotPoints;
    }

    getCurveTupleAtXValue(x, curveTuple) {
        let tuple = {};

        if (x >= curveTuple.prostateCoverage[0]) {
            tuple = {
                marksScore: curveTuple.marksScore[0],
                prostateCoverage: curveTuple.prostateCoverage[0],
                lesionVolume: curveTuple.lesionVolume[0],
                cpmThreshold: curveTuple.cpmThreshold[0],
            };
        } else {
            for (let i = curveTuple.prostateCoverage.length - 1; i >= 0; --i) {
                if (curveTuple.prostateCoverage[parseInt(i)] >= x) {
                    let firstIdx = i === curveTuple.prostateCoverage.length - 1;
                    let x0 = firstIdx
                        ? 0
                        : curveTuple.prostateCoverage[parseInt(i + 1)];
                    let x1 = curveTuple.prostateCoverage[parseInt(i)];

                    let y0cpm = firstIdx
                        ? EPS
                        : curveTuple.cpmThreshold[parseInt(i + 1)];
                    let y1cpm = curveTuple.cpmThreshold[parseInt(i)];

                    let y0ms = firstIdx
                        ? 0
                        : curveTuple.marksScore[parseInt(i + 1)];
                    let y1ms = curveTuple.marksScore[parseInt(i)];

                    let y0lv = firstIdx
                        ? 0
                        : curveTuple.lesionVolume[parseInt(i + 1)];
                    let y1lv = curveTuple.lesionVolume[parseInt(i)];

                    let xDif = x1 - x0; // Need to check for vertical lines with xDif
                    let yCpm = !xDif
                        ? y1cpm
                        : y0cpm + (x - x0) * ((y1cpm - y0cpm) / (x1 - x0));
                    let yMs = !xDif
                        ? y1ms
                        : y0ms + (x - x0) * ((y1ms - y0ms) / (x1 - x0));
                    let yLv = !xDif
                        ? y1lv
                        : y0lv + (x - x0) * ((y1lv - y0lv) / (x1 - x0));

                    tuple.prostateCoverage = x;
                    tuple.cpmThreshold = yCpm;
                    tuple.marksScore = yMs;
                    tuple.lesionVolume = yLv;

                    break;
                }
            }
        }

        return tuple;
    }

    render() {
        let defaultXPosition = 100 * this.props.defaultProstateCoverage;
        let defaultYPosition = 100 * this.props.defaultMarksScore;
        let userSelectedXPosition =
            100 * this.props.userSelectedProstateCoverage;
        let userSelectedYPosition = 100 * this.props.userSelectedMarksScore;

        const configuredCurve = curveCatmullRom.alpha(0.5);

        return (
            <div
                id={this.props.id}
                style={this.props.style}
                ref={this.ref}
                className={"marks-score-plot-container"}
            >
                <FlexibleXYPlot
                    onClick={this.handlePlotClick}
                    onMouseMove={this.handleMouseMove}
                    className={"marks-score-plot"}
                    yDomain={[0, 100]}
                    xDomain={[0, 100]}
                >
                    <div className={"marks-score-line-series"} />
                    <MarksScoreLegend />
                    <VerticalGridLines
                        tickValues={[25, 50, 75]}
                        style={{ stroke: "#3A3A3A", zIndex: -2 }}
                    />
                    <HorizontalGridLines
                        tickValues={[25, 50, 75]}
                        style={{ stroke: "#3A3A3A", zIndex: -2 }}
                    />
                    {this.marksScoreGradient}
                    <LineSeries
                        color={"url(#marks-score-turbo-color-map-gradient)"}
                        className={"marks-score-line-series"}
                        strokeWidth={3}
                        curve={configuredCurve}
                        data={this.plotPoints}
                    />
                    {getCrossHairs(
                        defaultXPosition,
                        defaultYPosition,
                        "#FFFFFF"
                    )}
                    {getCrossHairs(
                        userSelectedXPosition,
                        userSelectedYPosition,
                        "#f542e9"
                    )}
                    <MarkSeries
                        onSeriesClick={this.handleDefaultPointClick}
                        onSeriesMouseOver={this.handleDefaultPointMouseOver}
                        onSeriesMouseOut={this.handleDefaultPointMouseOut}
                        fill={"#FFFFFF"}
                        stroke={"#B1B1B1"}
                        strokeWidth={2}
                        size={this.state.defaultMarkerSize}
                        className={"marks-score-plot-default-star-thumb"}
                        data={[
                            {
                                x: defaultXPosition,
                                y: defaultYPosition,
                            },
                        ]}
                    />
                    <MarkSeries
                        fill={"#f542e9"}
                        stroke={"#a82ca0"}
                        strokeWidth={2}
                        size={8}
                        className={"marks-score-plot-user-selected-thumb"}
                        data={[
                            {
                                x: userSelectedXPosition,
                                y: userSelectedYPosition,
                            },
                        ]}
                    />
                    {getPlotXAxis()}
                    {getPlotYAxis()}
                </FlexibleXYPlot>
            </div>
        );
    }
}

MarksScorePlot.propTypes = {
    style: PropTypes.object,
    id: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
    defaultProstateCoverage: PropTypes.number,
    defaultMarksScore: PropTypes.number,
    userSelectedProstateCoverage: PropTypes.number,
    userSelectedMarksScore: PropTypes.number,
    setLesionParameters: PropTypes.func,
    getNewLesionContourHandler: PropTypes.func,
};

MarksScorePlot.defaultProps = {
    currentTreatmentPlan: {},
    defaultProstateCoverage: 0.0,
    defaultMarksScore: 0.0,
    userSelectedProstateCoverage: 0.0,
    userSelectedMarksScore: 0.0,
    setLesionParameters: () => {},
    getNewLesionContourHandler: () => {},
};

export default MarksScorePlot;

const isInsideDiv = (e, divRect) =>
    e.clientX >= divRect.left &&
    e.clientX <= divRect.right &&
    e.clientY >= divRect.top &&
    e.clientY <= divRect.bottom;

const EPS = 1 - 0.00001;
