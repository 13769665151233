import React from "react";
import "./styles.css";
import PropTypes from "prop-types";

export default function PatientNotesTabItem(props) {
    return (
        <div className="patient-notes-tab-item display-regular-16 pendo-ignore">
            <div className="patient-notes-top">
                <div>
                    <div>Dr. {props.doctorName}</div>
                    <div>{props.doctorEmail}</div>
                </div>
                <div>{props.date}</div>
            </div>
            <div className="preserve-white-space">&quot;{props.note}&quot;</div>
        </div>
    );
}

PatientNotesTabItem.propTypes = {
    doctorName: PropTypes.string,
    doctorEmail: PropTypes.string,
    date: PropTypes.string.isRequired,
    note: PropTypes.string.isRequired,
};
