export const NAV_PLAN_OPTIONS = {
    PDF: "Patient Report (PDF)",
    FOCAL_ONE: "Focal One", // Alias for DICOM_RT
    SONABLATE: "Sonablate (Profuse)", // Alias for Artemis
    VARIAN: "Varian VariSeed", // Alias for DICOM_RT
    BK_FUSION: "bkFusion", // Alias for DICOM_RT
    MIM: "MIM Software", // Alias for DICOM_RT
    KOELIS: "Koelis Trinity", // Alias for DICOM_RT
    ARTEMIS: "Artemis",
    DICOM_RT: "DICOM RT",
    DICOM_SSEG: "DICOM SSEG",
    RAW_PLAN_ASSETS: "Raw Plan Assets",
    FOCALPOINT: "FocalPoint",
};

export const MODAL_STATES = {
    INITIAL: 0,
    DOWNLOADING: 1,
    FINISHED: 2,
    ERROR: 3,
};

export const FILE_TYPE_HEADER = "File Type";

export const DOWNLOAD_CARD_TITLE = "File Export";
export const DOWNLOAD_CARD_SUBTITLE = {
    INITIAL: "Select a file type to download.",
    DOWNLOADING: "Downloading...",
    DOWNLOAD_COMPLETE: "download complete.",
    ERROR: "failed to download.",
    SELECT_ANOTHER_FORMAT: "Select another format to download or exit screen.",
};

export const ARTEMIS_SONABLATE_DOWNLOAD_ALERT =
    "Please do not change any folder's name inside the downloaded zip file.";
