import * as THREE from "three";
import { mouseButtons } from "../../../../constants";
import { defaultMRIZoom } from "./viewer";

const Raycaster = new THREE.Raycaster();

const Mouse = {};

const setMouse = (e, mount) => {
    let rect = mount.getBoundingClientRect();
    Mouse.x = ((e.clientX - rect.left) / mount.clientWidth) * 2 - 1;
    Mouse.y = -((e.clientY - rect.top) / mount.clientHeight) * 2 + 1;
};

const checkMouseBounds = () =>
    Mouse.x < -1 || Mouse.x > 1 || Mouse.y < -1 || Mouse.y > 1;

export function onWindowResize() {
    if (this.mount) {
        this.camera.left = (this.mount.clientWidth / -2) * (1 / defaultMRIZoom);
        this.camera.right = (this.mount.clientWidth / 2) * (1 / defaultMRIZoom);
        this.camera.top = (this.mount.clientHeight / 2) * (1 / defaultMRIZoom);
        this.camera.bottom =
            (this.mount.clientHeight / -2) * (1 / defaultMRIZoom);
        this.camera.updateProjectionMatrix();

        if (this.props.mriModel) {
            this.props.mriModel.updateContourTextures();
        }

        this.renderer.setSize(this.mount.offsetWidth, this.mount.offsetHeight);
    }
}

export function setRendererSize(width, height) {
    this.renderer.setSize(width, height);
}

export function onMouseDown(e) {
    if (!this.mount) {
        return;
    }

    setMouse(e, this.mount);

    if (checkMouseBounds()) {
        return;
    }

    switch (e.which) {
        case mouseButtons.LEFT: {
            Raycaster.setFromCamera(Mouse, this.camera);

            if (
                this.props.targetSetController &&
                !this.props.targetSetController.guidanceMode &&
                !this.props.targetSetController.selectedTarget
            ) {
                this.props.targetSetController.selectTargetWithRaycaster(
                    Raycaster
                );
            }

            break;
        }
        default:
            break;
    }
}

export function onMouseUp(e) {
    if (!this.mount) {
        return;
    }

    setMouse(e, this.mount);

    if (checkMouseBounds()) {
        return;
    }

    switch (e.which) {
        case mouseButtons.LEFT: {
            Raycaster.setFromCamera(Mouse, this.camera);

            if (
                this.props.targetSetController &&
                this.props.targetSetController.selectedTarget
            ) {
                this.props.targetSetController.selectedTarget = null;

                this.props.updateCoverageCallback();
            }

            break;
        }
        default:
            break;
    }
}

export function onMouseMove(e) {
    if (!this.mount) {
        return;
    }

    setMouse(e, this.mount);

    if (checkMouseBounds()) {
        return;
    }

    Raycaster.setFromCamera(Mouse, this.camera);

    switch (e.which) {
        case mouseButtons.NONE: {
            if (
                this.props.targetSetController &&
                this.props.targetSetController.guidanceMode
            ) {
                this.props.targetSetController.moveTargetGuideWithRaycaster(
                    Raycaster
                );
            } else if (this.props.targetSetController) {
                this.props.targetSetController.onHoverHighlightWithRaycaster(
                    Raycaster
                );
            }

            break;
        }

        case mouseButtons.LEFT: {
            if (
                this.props.targetSetController &&
                this.props.targetSetController.selectedTarget
            ) {
                this.props.targetSetController.moveSelectedTargetWithRaycaster(
                    Raycaster
                );
            }

            break;
        }

        default:
            break;
    }
}

export function onClick(e) {
    if (!this.mount) {
        return;
    }

    setMouse(e, this.mount);

    if (checkMouseBounds()) {
        return;
    }

    Raycaster.setFromCamera(Mouse, this.camera);

    if (
        this.props.meshEditController &&
        this.props.meshEditController.editingActive
    ) {
        this.props.meshEditController.updateStructureMeshFromRaycaster(
            Raycaster
        );
    }

    if (
        this.props.targetSetController &&
        this.props.targetSetController.guidanceMode
    ) {
        this.props.targetSetController.createTargetWithRaycaster(Raycaster);
    }
}
