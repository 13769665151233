import * as THREE from "three";
import { EMPTY_STRING, volumeNames } from "../../../../constants";
import { MRI_GENERIC_MATERIAL } from "./materials";

export function makeMRIViewerMesh(params) {
    params.geometry.computeVertexNormals();
    params.geometry.computeBoundingBox();

    let meshObject = {};
    meshObject.scene = new THREE.Scene();

    meshObject.scene.name = params.name;

    meshObject.materialFront = MRI_GENERIC_MATERIAL.clone();
    meshObject.materialFront.side = THREE.FrontSide;
    meshObject.materialFront.opacity =
        params.frontOpacity == null ? 0 : params.frontOpacity;
    meshObject.materialBack = MRI_GENERIC_MATERIAL.clone();
    meshObject.materialBack.side = THREE.BackSide;
    meshObject.materialBack.opacity =
        params.backOpacity == null ? 1.0 : params.backOpacity;

    meshObject.meshFront = new THREE.Mesh(
        params.geometry,
        meshObject.materialFront
    );
    meshObject.meshBack = new THREE.Mesh(
        params.geometry,
        meshObject.materialBack
    );

    meshObject.meshFront.name = params.name + volumeNames.FRONT;
    meshObject.meshBack.name = params.name + volumeNames.BACK;

    if (params.visible != null) {
        meshObject.scene.visible = params.visible;
    }

    if (params.color != null && params.color !== EMPTY_STRING) {
        meshObject.materialFront.color = new THREE.Color(params.color);
        meshObject.materialBack.color = new THREE.Color(params.color);
    }

    if (params.scale != null) {
        meshObject.meshFront.scale.set(
            params.scale.x,
            params.scale.y,
            params.scale.z
        );
        meshObject.meshBack.scale.set(
            params.scale.x,
            params.scale.y,
            params.scale.z
        );
    }

    if (params.position != null) {
        meshObject.meshFront.position.set(
            params.position.x,
            params.position.y,
            params.position.z
        );
        meshObject.meshBack.position.set(
            params.position.x,
            params.position.y,
            params.position.z
        );
    }

    if (params.quaternion != null) {
        meshObject.meshFront.applyQuaternion(params.quaternion);
        meshObject.meshBack.applyQuaternion(params.quaternion);
    }

    if (params.rotation != null) {
        meshObject.meshFront.setRotationFromMatrix(params.rotation);
        meshObject.meshBack.setRotationFromMatrix(params.rotation);
    }

    if (params.translation != null) {
        meshObject.meshFront.translateOnAxis(
            params.translation.axis,
            params.translation.distance
        );
        meshObject.meshBack.translateOnAxis(
            params.translation.axis,
            params.translation.distance
        );
    }

    meshObject.scene.name = params.name;
    meshObject.scene.add(meshObject.meshFront);
    meshObject.scene.add(meshObject.meshBack);

    return meshObject;
}
