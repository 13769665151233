import { volumeNames } from "../../../../../constants";
import { LPS2RAS } from "../../helpers";
import BiopsyCoreModel from "./BiopsyCoreModel";
import ROIModel from "./ROIModel";

export default class PatientAnatomyModel {
    constructor() {
        this._models = new Map();
        this._targetSetModel = null;
    }

    get prostate() {
        return this._models.get(volumeNames.MR_PROSTATE);
    }

    set prostate(model) {
        this._setModel(volumeNames.MR_PROSTATE, model, true);
    }

    get rois() {
        return this._models.get(volumeNames.MR_ROI);
    }

    set rois(models) {
        this._setModel(volumeNames.MR_ROI, models);
    }

    get tumorGroundTruth() {
        return this._models.get(volumeNames.MR_TUMOR);
    }

    set tumorGroundTruth(models) {
        this._setModel(volumeNames.MR_TUMOR, models);
    }

    get margin() {
        return this._models.get(volumeNames.MR_MARGIN_AI);
    }

    set margin(model) {
        this._setModel(volumeNames.MR_MARGIN_AI, model);
    }

    get bxCores() {
        return this._models.get(volumeNames.MR_BIOPSY);
    }

    set bxCores(models) {
        this._setModel(volumeNames.MR_BIOPSY, models);
    }

    get bxCoresNegative() {
        return this._filterCoresByGGG(
            this._models.get(volumeNames.MR_BIOPSY),
            0
        );
    }

    get bxCoresPca() {
        return this._filterCoresByGGG(
            this._models.get(volumeNames.MR_BIOPSY),
            1
        );
    }

    get bxCoresCSPca() {
        return this._filterCoresByGGG(
            this._models.get(volumeNames.MR_BIOPSY),
            2,
            true
        );
    }

    set targetSet(targetSet) {
        this._targetSetModel = targetSet;
    }

    get mriViewer() {
        return this._mriViewer;
    }

    set mriViewer(mriViewer) {
        this._mriViewer = mriViewer;
    }

    get meshViewer() {
        return this._meshViewer;
    }

    set meshViewer(meshViewer) {
        this._meshViewer = meshViewer;
    }

    addRoi(model) {
        this._models.set(model.name, model);
        let rois = this._models.get(volumeNames.MR_ROI);

        if (rois) {
            rois.push(model);
        } else {
            this._models.set(volumeNames.MR_ROI, [model]);
        }
    }

    addTumorGroundTruth(model) {
        this._models.set(model.name, model);
        let tumors = this._models.get(volumeNames.MR_TUMOR);

        if (tumors) {
            tumors.push(model);
        } else {
            this._models.set(volumeNames.MR_TUMOR, [model]);
        }
    }

    addBxCore(model) {
        this._models.set(model._selfMeshView.name, model);
        let bxCores = this._models.get(volumeNames.MR_BIOPSY);

        if (bxCores) {
            bxCores.push(model);
        } else {
            this._models.set(volumeNames.MR_BIOPSY, [model]);
        }
    }

    getRoiByIndex(idx) {
        let rois = this._models.get(volumeNames.MR_ROI);

        if (rois && idx && idx < rois.length) {
            return rois[parseInt(idx)];
        }
    }

    isModelBxCore(model) {
        return model instanceof BiopsyCoreModel;
    }

    isModelROI(model) {
        return model instanceof ROIModel;
    }

    lookupModelWithView(view) {
        let model = this._models.get(view.name);
        if (!model && this._targetSetModel) {
            model = this._targetSetModel.lookupModelWithView(view);
        }
        return model;
    }

    _setModel(key, model, focus) {
        this._clearModel(key, model);
        this._models.set(key, model);

        if (model && focus) {
            if (this._meshViewer) {
                let centerLPS = model.center;
                centerLPS.applyMatrix4(LPS2RAS);
                this._meshViewer.setCenter(centerLPS);
            }
        }
    }

    _clearModel(key, newStructure) {
        if (!newStructure) {
            let modelsInMap = this._models.get(key);

            if (modelsInMap) {
                if (Array.isArray(modelsInMap)) {
                    for (let s of modelsInMap) {
                        s.delete();
                    }
                } else {
                    modelsInMap.delete();
                }

                this._models.delete(key);
            }
        }
    }

    _filterCoresByGGG(bxCores, ggg, allowGreater) {
        let filteredCores = [];

        if (bxCores) {
            for (let core of bxCores) {
                if (!allowGreater) {
                    if (core.gleasonGradeGroup === ggg) {
                        filteredCores.push(core);
                    }
                } else {
                    if (core.gleasonGradeGroup >= ggg) {
                        filteredCores.push(core);
                    }
                }
            }
        }

        return filteredCores;
    }
}
