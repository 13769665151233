import {
    API_ENDPOINT,
    API_VERSION,
    clientErrorMessages,
    httpMethods,
    SUCCESS,
} from "../../constants";
import { vsprintf } from "sprintf-js";
import { DOWNLOAD_VOLUME_CONST, EXPAND_VOLUMES_CONST } from "./constants";
import { rootStore } from "../../redux/store";
import { setErrorState } from "../../redux/error_banner/actions";

export async function createNewAblationProfile(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/ablationProfile", [
        API_ENDPOINT,
        API_VERSION,
        input.userUuid,
    ]);

    return await fetch(endpoint, {
        method: httpMethods.POST,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        body: JSON.stringify({
            name: input.profileName,
            modality: input.modality,
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            }
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function createAblationVolume(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/ablationProfile/%s/volumes", [
        API_ENDPOINT,
        API_VERSION,
        input.userUuid,
        input.ablationProfileId,
    ]);

    return await fetch(endpoint, {
        method: httpMethods.POST,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        body: JSON.stringify({
            name: input.volumeName,
            meshPrimitiveType: input.meshPrimitiveType,
            faces: Array.from(input.faces),
            vertices: Array.from(input.vertices),
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            }
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function getAblationProfileWithVolumes(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/ablationProfile/%s?expand=%s", [
        API_ENDPOINT,
        API_VERSION,
        input.userUuid,
        input.ablationProfileId,
        EXPAND_VOLUMES_CONST,
    ]);

    return await fetch(endpoint, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload.ablationProfile;
            }
            throw new Error(clientErrorMessages.FAILED_TO_GET_PROFILES);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function updateAblationProfile(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/ablationProfile/%s", [
        API_ENDPOINT,
        API_VERSION,
        input.userUuid,
        input.ablationProfileId,
    ]);

    return await fetch(endpoint, {
        method: httpMethods.PUT,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        body: JSON.stringify({
            ablationProfile: {
                name: input.profileName,
                modality: input.modality,
            },
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.FAILED_TO_SAVE_PROFILE);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function deleteAblationProfileVolume(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/ablationProfile/%s/volumes/%s", [
        API_ENDPOINT,
        API_VERSION,
        input.userUuid,
        input.ablationProfileId,
        input.volumeUuid,
    ]);

    return await fetch(endpoint, {
        method: httpMethods.DELETE,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.FAILED_TO_DEL_VOLUME);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function updateAblationProfileVolume(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/ablationProfile/%s/volumes/%s", [
        API_ENDPOINT,
        API_VERSION,
        input.userUuid,
        input.ablationProfileId,
        input.volumeUuid,
    ]);

    return await fetch(endpoint, {
        method: httpMethods.PUT,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        body: JSON.stringify({
            ablationVolume: {
                name: input.volumeName,
                meshPrimitiveType: input.meshPrimitiveType,
                edgeLength: input.edgeLength,
                scale: input.scale,
                radius: input.radius,
                displayApplicator: input.displayApplicator,
                applicatorOffset: input.applicatorOffset,
            },
            faces: Array.from(input.faces),
            vertices: Array.from(input.vertices),
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.FAILED_TO_SAVE_PROFILE);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function getAblationVolumeMeshURI(input) {
    let endpoint = vsprintf(
        "%s/api/%s/user/%s/ablationProfile/%s/volumes/%s?expand=%s",
        [
            API_ENDPOINT,
            API_VERSION,
            input.userUuid,
            input.ablationProfileId,
            input.volumeUuid,
            DOWNLOAD_VOLUME_CONST,
        ]
    );

    return await fetch(endpoint, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.FAILED_TO_SAVE_PROFILE);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}
