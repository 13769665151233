import { AVENDA_DEFAULT_ABLATION_PROFILE_UUID } from "../../constants";

export const modalViews = {
    MINIMIZED: "minimized",
    NORMAL: "normal",
    MAXIMIZED: "maximized",
};

export const STEP4_BANNER_TEXT =
    "Adjust the parameters and create a suitable treatment margin";

export const genMarginBtnState = {
    DEFAULT: "Default",
    FAILURE: "Failure",
    SUCCESS: "Success",
    NO_TREATMENT_REC: "NoTreat",
    LOADING: "Loading",
};

export const atpBtnState = {
    DEFAULT: "Default",
    LOADING: "Loading",
    FAILURE: "Failure",
};

export const ADJUSTMENT_DIRECTION = {
    UP: "UP",
    DOWN: "DOWN",
    LEFT: "LEFT",
    RIGHT: "RIGHT",
    ROTATE_CW: "ROTATE_CLOCK",
    ROTATE_CCW: "ROTATE_ANTI",
};

export const DIRECTION = {
    POSITIVE: 1,
    NEGATIVE: -1,
    MULTIPLIER: 5,
};

export const VOLUME_SHAPES = {
    CUBE: "cube",
    ELLIPSE: "ellipse",
    PRISM: "prism",
    SPHERE: "sphere",
    CAPSULE: "capsule",
};

export const DEFAULT_AVENDA_PROFILE = {
    name: "Avenda Health Default Ablation Tool Profile",
    ablationProfileUUID: AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
};

export const CEM_WARNING_TITLE = "WARNING";

export const cemWarningCodes = {
    CANCER_MAP_UNDERSAMPLED_ROI_ONLY: "CANCER_MAP_UNDERSAMPLED_ROI_ONLY",
    CANCER_MAP_UNDERSAMPLED_UNILATERAL: "CANCER_MAP_UNDERSAMPLED_UNILATERAL",
    CANCER_MAP_UNDERSAMPLED_LARGE_PROSTATE:
        "CANCER_MAP_UNDERSAMPLED_LARGE_PROSTATE",
};

export const cemWarningMsg = {
    WARNING_PREFIX: "Exercise caution when interpreting CEMs generated",
    CONNECTOR_OR: " or",
    TARGETED: " from ONLY targeted/ROI biopsy cores",
    UNILATERAL: " from ONLY unilateral biopsy",
    LARGE_PROSTATE:
        " from very large (>100cc) prostates where biopsy regions may be >2cm from the nearest biopsy core",
    WARNING_POSTFIX:
        '. Insufficient biopsy sampling may result in an unexpected "hotspot" with artificially inflated cancer risk on CEM areas far from any biopsy core or sampled ROIs.',
};

export const cemWarningMsgFooter = {
    FOOTER_PREFIX:
        "For additional details and examples of “hotspots,” refer to the latest version of the",
    USER_MANUAL: "User Manual",
};
