import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { EMPTY_STRING } from "../../../constants";

class AdjustmentDirectionButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    onMouseEnter() {
        this.setState({ hover: true });
    }

    onMouseLeave() {
        this.setState({ hover: false });
    }

    render() {
        let srcImg = this.state.hover
            ? this.props.imgSrcBlue
            : this.props.imgSrcDark;
        return (
            <div
                id={`${this.props.direction}-adj-dir-btn`}
                className={`adjustment-direction-button`}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                <img
                    className={"adjustment-dir-button-icon"}
                    style={this.props.iconStyle}
                    src={srcImg}
                    alt={"adjustment direction icon"}
                />
            </div>
        );
    }
}

AdjustmentDirectionButton.propTypes = {
    imgSrcBlue: PropTypes.string,
    imgSrcDark: PropTypes.string,
    id: PropTypes.string,
    iconStyle: PropTypes.object,
    children: PropTypes.any,
    direction: PropTypes.string,
};

AdjustmentDirectionButton.defaultProps = {
    direction: EMPTY_STRING,
};

export default AdjustmentDirectionButton;
