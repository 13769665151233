import React from "react";
import "./styles.css";
import uncheckedSquare from "../../../assets/UncheckedSquare.svg";
import checkedSquare from "../../../assets/CheckedSquare.svg";
import { EMPTY_STRING } from "../../../constants";
import PropTypes from "prop-types";
import StructureModel from "../Viewers/Structures/PatientAnatomy/StructureModel";
import MRIModel from "../Viewers/Structures/MRI/MRIModel";
import TargetSetModel from "../Viewers/Structures/Targets/TargetSetModel";

class FilterToggleItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        if (this.props.model) {
            if (this.props.model instanceof TargetSetModel) {
                this.props.model.laserCatheterVisibility =
                    !this.props.model.laserCatheterVisibility;
            } else if (this.props.model.length > 0) {
                this.props.model.forEach((_, idx) => {
                    this.props.model[parseInt(idx)].visibility =
                        !this.props.model[parseInt(idx)].visibility;
                });
            } else if (this.props.isCPMOverlay) {
                this.props.model.lutVisibility =
                    !this.props.model.lutVisibility;
                this.props.setState({
                    forceUpdate: this.props.forceUpdate + 1,
                });
            } else {
                this.props.model.visibility = !this.props.model.visibility;
            }

            this.forceUpdate();
        }

        event.stopPropagation();
    }

    getVisibility() {
        if (this.props.model) {
            if (this.props.model instanceof TargetSetModel) {
                return this.props.model.laserCatheterVisibility;
            } else if (this.props.model.length > 0) {
                return this._isAnyListItemVisible(this.props.model);
            } else if (this.props.isCPMOverlay) {
                return this.props.model.lutVisibility;
            } else {
                return this.props.model.visibility;
            }
        }
    }

    _isAnyListItemVisible(structureList) {
        for (let i = 0; i < structureList.length; i++) {
            if (structureList[parseInt(i)].visibility) {
                return true;
            }
        }

        return false;
    }

    render() {
        let chkBox = this.getVisibility() ? checkedSquare : uncheckedSquare;
        const itemId =
            "filter-toggle-item-" +
            this.props.name.toLowerCase().replace(/ /g, "");
        let textLabel =
            this.props.textLabel === EMPTY_STRING
                ? this.props.name
                : this.props.textLabel;
        let cpmStyleClass = this.props.isCPMOverlay
            ? "cpm-filter-item-tag"
            : EMPTY_STRING;

        return (
            <div
                id={itemId}
                className={"filter-toggle-item"}
                style={this.props.style}
                ref={this.setWrapperRef}
                onClick={this.handleClick}
            >
                <div
                    className={`filter-list-item-color-tag ${cpmStyleClass}`}
                    style={{ backgroundColor: this.props.color }}
                />
                <img
                    src={chkBox}
                    width="24px"
                    height="24px"
                    alt={"Check Box"}
                />
                <p className={"display-16"}>{textLabel}</p>
            </div>
        );
    }
}

FilterToggleItem.propTypes = {
    model: PropTypes.oneOfType([
        PropTypes.instanceOf(StructureModel),
        PropTypes.arrayOf(PropTypes.instanceOf(StructureModel)),
        PropTypes.instanceOf(MRIModel),
        PropTypes.instanceOf(TargetSetModel),
    ]),
    isCPMOverlay: PropTypes.bool,
    name: PropTypes.string,
    textLabel: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.string,
    setState: PropTypes.func,
    forceUpdate: PropTypes.number,
};

FilterToggleItem.defaultProps = {
    textLabel: EMPTY_STRING,
    isCPMOverlay: false,
};

export default FilterToggleItem;
