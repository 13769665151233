import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { EMPTY_STRING } from "../../../../constants";
import plusIcon from "../../../../assets/plus_icon.svg";
import greenChk from "../../../../assets/circle_green_check.svg";
import { If, Then, Else } from "react-if";

export default class AISegmentationListItem extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    getPlusOrCheck(segExists) {
        return (
            <If condition={segExists}>
                <Then>
                    <div className={"ai-segmentation-right-icon"}>
                        <img src={greenChk} alt={"X"} />
                    </div>
                </Then>
                <Else>
                    <div className={"ai-segmentation-right-icon"}>
                        <img src={plusIcon} alt={"+"} />
                    </div>
                </Else>
            </If>
        );
    }

    handleClick() {
        if (!this.props.segmentationExistsAlready) {
            this.props.onClick();
        }
    }

    render() {
        let darkStyle = this.props.dark ? "dark" : "light";
        let active = this.props.segmentationExistsAlready
            ? "inactive"
            : "active";
        return (
            <div
                className={`segmentation-list-item ${darkStyle} ${active}`}
                onClick={this.handleClick}
            >
                <div className={`ai-segmentation-list-item-type-icon`}>
                    <img src={this.props.iconSrc} alt={""} />
                </div>
                <p className={`ai-segmentation-list-item-text display-16`}>
                    {this.props.text}
                </p>
                {this.getPlusOrCheck(this.props.segmentationExistsAlready)}
            </div>
        );
    }
}

AISegmentationListItem.propTypes = {
    segmentationExistsAlready: PropTypes.bool,
    color: PropTypes.string,
    dark: PropTypes.bool,
    text: PropTypes.string,
    iconSrc: PropTypes.string,
    onClick: PropTypes.func,
};

AISegmentationListItem.defaultProps = {
    segmentationExistsAlready: false,
    color: EMPTY_STRING,
    dark: false,
    text: EMPTY_STRING,
    iconSrc: EMPTY_STRING,
    onClick: () => {},
};
