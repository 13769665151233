import React, { Component } from "react";
import "./styles.css";
import { connect } from "react-redux";
import BiopsyButton from "../BiopsyButton";
import { specialSelection } from "../../../constants";
import { setActiveCore } from "../actions";
import PropTypes from "prop-types";
import { TemplateBiopsyLocations } from "../constants";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";

class BiopsyPlot extends Component {
    constructor(props) {
        super(props);

        this.generateBiopsyButtons = this.generateBiopsyButtons.bind(this);

        this.mount = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", () => this.forceUpdate(), true);
    }

    generateBiopsyButtons(coreLocations) {
        if (!this.mount.current) {
            return;
        }
        let rect = this.mount.current.getBoundingClientRect();
        return coreLocations.map((el, idx) => {
            const isMuted = el.ROI ? el.IsMuted : true;
            if (isMuted) {
                return null;
            }

            // TODO - I dont think this is actually needed. Should remove this on follow up MR
            let topScaling = rect.height * 0.61;
            let topOffset = rect.height * 0.23;
            let leftScaling = rect.width * 0.7;
            let leftOffset = rect.width * 0.16;
            return (
                <BiopsyButton
                    key={idx}
                    idx={idx}
                    name={el.ID}
                    targeted={el.TAR}
                    canvas={rect}
                    style={{
                        top:
                            el.y * topScaling -
                            halfBtnSizePlusMargin +
                            topOffset,
                        left:
                            el.x * leftScaling -
                            halfBtnSizePlusMargin +
                            leftOffset,
                    }}
                />
            );
        });
    }

    groupBxsByRegion(currentTreatmentPlan, coreLocations) {
        let cluster = [];
        let tplan = currentTreatmentPlan.TreatmentPlan;
        let bxs = tplan.StructureData.Biopsy;
        let bxTemplate = TreatmentPlan.getBxTemplate(tplan);
        if (bxs) {
            bxs = bxs.filter((obj) => obj.Frame.MRI.TemplateLocation !== "");
            bxs.forEach((obj, idx) => {
                // fall back if parser cannot identify region and subsequently coords
                let xCoord = 0;
                let yCoord = 0;

                // if there is a corresponding core location update coords
                xCoord = coreLocations[parseInt(idx)]
                    ? coreLocations[parseInt(idx)].x
                    : xCoord;
                yCoord = coreLocations[parseInt(idx)]
                    ? coreLocations[parseInt(idx)].y
                    : yCoord;

                // if region is known set coords based on biopsy locations constant
                if (obj.Frame.MRI.TemplateLocation) {
                    xCoord =
                        TemplateBiopsyLocations[String(bxTemplate)][
                            String(obj.Frame.MRI.TemplateLocation)
                        ].X;
                    yCoord =
                        TemplateBiopsyLocations[String(bxTemplate)][
                            String(obj.Frame.MRI.TemplateLocation)
                        ].Y;
                }

                cluster.push({
                    ID: idx,
                    ROI: obj.Frame.MRI.TemplateLocation,
                    x: xCoord,
                    y: yCoord,
                    TAR: obj.IsROITargeted,
                    IsMuted: obj.IsMuted,
                });
            });
        }

        //group roi cores into their new roi polygon
        const countBxs = (arr, classifier) => {
            classifier = classifier || String;
            return arr.reduce((counter, obj) => {
                const p = classifier(obj);
                counter[`${p}`] = Object.prototype.hasOwnProperty.call(
                    counter,
                    `${p}`
                )
                    ? counter[`${p}`] + 1
                    : 1;
                return counter;
            }, {});
        };

        const polygon = countBxs(cluster, (obj) => {
            if (obj.ROI !== "") return obj.ROI;
        });

        return this.generateBxsCoords(polygon, cluster);
    }

    generateBxsCoords(polygon, cluster) {
        for (const [key, value] of Object.entries(polygon)) {
            //cluster bxs by template location
            const roiPoints = cluster.filter((obj) => {
                if (obj.ROI === "") {
                    return false;
                } else {
                    return obj.ROI ? obj.ROI.toString() === key : false;
                }
            });

            let radius = 0.075;

            if (roiPoints.length === 1) radius = 0;

            const centerX = roiPoints[0] ? roiPoints[0].x : 0;
            const centerY = roiPoints[0] ? roiPoints[0].y : 0;

            //loop through each roi core and determine its new xy coord based on circle
            for (let i = 0; i < value; i++) {
                //offset is used to orient polygon "normally" and based on odd/even # points
                let offSet = 90;

                if (value % 2 === 0) {
                    offSet = 180 / value;
                }

                const deg = (360 / value) * (i + 1) - offSet;

                //js math doesnt support degrees - convert to radians
                const rad = deg * (Math.PI / 180);

                //bounding div for all bxs is oddly scaled in x axis to always align with cartoon - adjust position 70%
                const cX = centerX + radius * Math.cos(rad) * 0.7;
                const cY = centerY + radius * Math.sin(rad);

                const replacePointCoord = roiPoints.shift();

                if (replacePointCoord) {
                    cluster[replacePointCoord.ID].x = 1 - cX;
                    cluster[replacePointCoord.ID].y = cY;
                }
            }
        }

        cluster.forEach((obj) => {
            obj.x = 1 - obj.x;
        });

        return cluster;
    }

    render() {
        let { currentTreatmentPlan, coreLocations } = this.props;

        let btns;

        if (currentTreatmentPlan && coreLocations.length > 0) {
            btns = this.generateBiopsyButtons(
                this.groupBxsByRegion(currentTreatmentPlan, coreLocations)
            );
        }

        return (
            <div id={this.props.id} className={"biopsy-plot"} ref={this.mount}>
                {btns}
            </div>
        );
    }
}

BiopsyPlot.propTypes = {
    activeCore: PropTypes.number,
    coreLocations: PropTypes.array,
    currentTreatmentPlan: PropTypes.object,
    style: PropTypes.object,
    setActiveCore: PropTypes.func,
    id: PropTypes.string,
    initState: PropTypes.bool,
    authToken: PropTypes.string,
};

BiopsyPlot.defaultProps = {
    activeCore: specialSelection.NONE_SELECTED,
    coreLocations: [],
    currentTreatmentPlan: {},
    style: {
        height: 0,
        width: 0,
    },
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
        activeCore: state.CreatePlanBiopsyReducer.activeCore,
        coreLocations: state.CreatePlanBiopsyReducer.coreLocations,
        authToken: state.LoginReducer.authToken,
    };
};

/* istanbul ignore next */
const mapDispatchToProps = function (dispatch) {
    return {
        setActiveCore: (core) => dispatch(setActiveCore(core)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(BiopsyPlot);

const halfBtnSizePlusMargin = 17;
