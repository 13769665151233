import React, { Component } from "react";
import "./styles.css";
import { ListGroup } from "react-bootstrap";

export default class Contributors extends Component {
    constructor(props) {
        super(props);

        this.contributors = [
            "Shyam Natarajan, CEO",
            "Brit Berry-Pusey, COO",
            "Leonard Marks, CMO",
            "Crystal Womack, Quality Manager",
            "Thomas Summers, Software Engineer",
            "Stephany Palaguachi, Software Engineer Intern",
            "Jonhas Colina, Machine Learning Engineer Intern",
            "Nicholas Ficara, Software Engineer Intern",
            "Earth Kulruchakorn, Software Engineer Intern",
            "Michal Freedman, Machine Learning Intern",
            "Ally Chen, Designer",
            "Alan Lee, Designer",
            "Christin Carolina, Design Intern",
            "Alan Priester, Senior Data Scientist",
            "Jeremy Bong, Staff Cloud Engineer",
            "Yash Kamothi, Software Engineer",
            "Prateek Bhatnagar, Software Engineer",
            "Joshua Shubert, Chief Technology Officer",
            "Sakina Mota, Data Scientist",
            "Graham Campbell, Senior UI Engineer",
            "Sumit Janawlekar, Software Engineer",
            "Anthony (Zijian) Hu, Software Engineer",
            "Lucy (Yun) Hao, Software Engineer Intern",
            "Brianna Rogers, Software Engineer Intern",
            "Matthew Salvador, Data Science Intern",
            "Mikayla Ward, Data Science Intern",
            "John Quilantang, Software Engineer Intern",
            "Rita Sargsyan, Data Science Intern",
            "Jennifer Lizarraga, Software Engineer Intern",
            "Diego Sanchez, Data Science Intern",
            "Dan Pham, Software Engineer",
            "Tiffany Fang, Software Engineer",
            "Emily Malone, Engineering Manager",
            "Jason (Zhangxing) Bian, Data Science Intern",
        ];
    }

    getListItems() {
        this.contributors.sort();
        return this.contributors.map((listItem, idx) => (
            <ListGroup.Item key={idx}>{listItem}</ListGroup.Item>
        ));
    }
    render() {
        return (
            <div className="contributors-page">
                <ListGroup>
                    <ListGroup.Item>
                        To honor the engineers, scientists, and designers who
                        developed this software:
                    </ListGroup.Item>
                    {this.getListItems()}
                    <ListGroup.Item>
                        The doctors and early users who graciously lent us their
                        time and feedback
                    </ListGroup.Item>
                    <ListGroup.Item>
                        And the 13 brave patients whose data was invaluable in
                        the development and testing of this software
                    </ListGroup.Item>
                </ListGroup>
            </div>
        );
    }
}
