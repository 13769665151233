import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../../constants";

class AHAlphaField extends Component {
    render() {
        return (
            <div className={this.props.className || "ah-alpha-field-wrapper"}>
                <p className={"display-14-bold black-text"}>
                    {this.props.showLabel &&
                        this.props.label &&
                        this.props.label}
                </p>
                <div className={"ah-alpha-field-input"}>
                    <input
                        type={this.props.type}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={(event) => {
                            this.props.onChange(
                                this.props.label,
                                event.target.value
                            );
                        }}
                        onKeyDown={(event) => {
                            this.props.onKeyDown(this.props.label, event);
                        }}
                        maxLength={this.props.maxLength}
                        className={"display-22"}
                        id={this.props.id}
                        ref={this.props.setInputRef}
                    />
                </div>
                <p
                    id={`error-msg-${this.props.id}`}
                    className={"display-14-bold red"}
                >
                    {this.props.error}
                </p>
            </div>
        );
    }
}

AHAlphaField.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    maxLength: PropTypes.number,
    error: PropTypes.string,
    showLabel: PropTypes.bool,
    setInputRef: PropTypes.func,
};

AHAlphaField.defaultProps = {
    id: undefined,
    className: EMPTY_STRING,
    label: EMPTY_STRING,
    placeholder: EMPTY_STRING,
    value: EMPTY_STRING,
    type: "text",
    onChange: () => {},
    onKeyDown: () => {},
    maxLength: 254,
    error: EMPTY_STRING,
    showLabel: true,
    setInputRef: () => {},
};

export default AHAlphaField;
