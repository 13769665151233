import { SET_ABLATION_PROFILE_UUID, SET_CREATE_NEW_PROFILE } from "./actions";
import { initialState } from "./init";

export default CreateAblationReducer;

function CreateAblationReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_ABLATION_PROFILE_UUID:
            return setAblationProfile(state, action);
        case SET_CREATE_NEW_PROFILE:
            return setCreateNewProfile(state, action);
        default:
            return state;
    }
}

function setAblationProfile(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.ablationProfileUUID = action.uuid;
    return stateCpy;
}

function setCreateNewProfile(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.createNewProfile = action.isOn;
    return stateCpy;
}
