import * as THREE from "three";
import { deleteAllObjectsInScene } from "../../../../helpers/threejs_helpers";
import CustomOrthographicControls from "../CustomOrthographicControls";
import { LPS2RAS } from "../helpers";

export const defaultMRIZoom = 8.7;
export function initScene() {
    //ADD RENDERER
    this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.setSize(this.mount.clientWidth, this.mount.clientHeight);
    this.renderer.localClippingEnabled = true;
    this.renderer.autoClear = false;

    //ADD SCENE
    this.scene = new THREE.Scene();

    //ADD CAMERA
    this.camera = new THREE.OrthographicCamera(
        (this.mount.clientWidth / -2) * (1 / defaultMRIZoom),
        (this.mount.clientWidth / 2) * (1 / defaultMRIZoom),
        (this.mount.clientHeight / 2) * (1 / defaultMRIZoom),
        (this.mount.clientHeight / -2) * (1 / defaultMRIZoom),
        0.1,
        10000
    );

    let light = new THREE.AmbientLight(0xffffff);
    this.scene.add(light);

    this.RASframe = new THREE.Scene();

    this.RASframe.applyMatrix4(LPS2RAS);

    this.scene.add(this.RASframe);

    this.mount.appendChild(this.renderer.domElement);

    this.controls = new CustomOrthographicControls(
        this.camera,
        this.mount,
        this.props.mriController
    );
    this.controls.noRotate = true;
    this.controls.staticMoving = true;
    this.controls.invertMouseX = true;
    this.camera.controls = this.controls;

    this.meshObjects = [];

    this.stopRenderer = false;

    this.start();
}

export function start() {
    if (!this.frameId) {
        this.frameId = requestAnimationFrame(this.animate);
    }
}

export function stop() {
    this.stopRenderer = true;
}

const fpsTiers = {
    LOW: 15,
    MID: 30,
    HIGH: 60,
};

let currentFPS = fpsTiers.LOW;

export function animate() {
    if (this.stopRenderer) {
        return;
    }
    let scope = this;
    this.controls.update();

    setTimeout(function () {
        window.requestAnimationFrame(scope.animate);
    }, 1000 / currentFPS);

    const begin = window.performance.now();
    this.renderScene();
    const elapsedTime = window.performance.now() - begin;

    currentFPS = this.setDynamicFPS(elapsedTime);
}

export function setDynamicFPS(elapsedTime) {
    const previousWeight = 100;

    this.frameCount = this.frameCount + 1 || 0;

    this.runningAverage =
        ((this.runningAverage || 0) * (previousWeight - 1) + elapsedTime) /
        previousWeight;

    if (this.runningAverage < 1) {
        return fpsTiers.HIGH;
    } else if (this.runningAverage < 2) {
        return fpsTiers.MID;
    } else {
        return fpsTiers.LOW;
    }
}

export function renderScene() {
    let scope = this;

    this.renderer.clear();
    this.props.mriModel.renderLUT();
    this.renderer.render(this.scene, this.camera);
    this.renderer.clearDepth();

    if (this.meshObjects && this.meshObjects.length > 0) {
        this.meshObjects.forEach(function (meshObject) {
            scope.props.mriModel.updateContourRenders(meshObject);
        });
    }
}

export function cleanup() {
    this.props.mriModel.dispose();

    deleteAllObjectsInScene(this.scene);
    this.renderer.forceContextLoss();
    this.renderer.dispose();
    this.renderer = null;
    this.scene = null;
}

export function resetScene() {
    this.controls.reset();
}
