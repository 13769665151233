// action types
export const SET_ABLATION_PROFILE_UUID = "SET_ABLATION_PROFILE_UUID";
export const SET_CREATE_NEW_PROFILE = "SET_CREATE_NEW_PROFILE";

// action creators

export const setAblationProfile = function (uuid) {
    return {
        type: SET_ABLATION_PROFILE_UUID,
        uuid: uuid,
    };
};

export const setCreateNewProfile = function (isOn) {
    return {
        type: SET_CREATE_NEW_PROFILE,
        isOn: isOn,
    };
};
