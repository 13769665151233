import FilterToggleItem from "../FilterToggleItem";
import {
    checkboxType,
    sceneFilterNames,
    sliderTypes,
    structureColorPalette,
} from "../../../constants";
import React from "react";
import FilterAutoLvlItem from "../FilterAutoLvlItem";
import FilterSliderItem from "../FilterSliderItem";
import FilterMRIModalItem from "../FilterMRIModalItem";

export const addMRIOptions = (scope, items) => {
    items.push(
        <FilterMRIModalItem
            key={"mri-modal-item"}
            onClick={scope.toggleMRIOptions}
            mriOptionsVisible={scope.state.mriOptionsVisible}
        />
    );

    if (scope.state.mriOptionsVisible) {
        items.push(
            <FilterToggleItem
                key={"mri-filter-toggle-item"}
                model={scope.props.mriModel}
                name={sceneFilterNames.MRI}
            />
        );
        items.push(
            <FilterAutoLvlItem
                key={"mri-filter-auto-lvl-item"}
                autoLevel={scope.state.autoLevel}
                clickCallback={scope.handleAutoLvlClick}
                name={checkboxType.AUTOLEVEL}
            />
        );
        items.push(
            <FilterSliderItem
                key={"mri-contrast-slider-item"}
                mriModel={scope.props.mriModel}
                autoLevel={scope.state.autoLevel}
                name={sliderTypes.CONTRAST}
            />
        );
        items.push(
            <FilterSliderItem
                key={"mri-intensity-slider-item"}
                mriModel={scope.props.mriModel}
                autoLevel={scope.state.autoLevel}
                name={sliderTypes.INTENSITY}
            />
        );
    }
    return items;
};

export const addCancerMap = (scope, items) => {
    if (scope.props.mriModel.lutEnabled) {
        items.push(
            <FilterToggleItem
                key={"cpm-filter-toggle-item"}
                model={scope.props.mriModel}
                name={sceneFilterNames.CPM}
                isCPMOverlay={true}
                forceUpdate={scope.state.forceUpdate}
                setState={(p) => {
                    scope.setState(p);
                }}
            />
        );
    }
    return items;
};

export const addFilterItem = (items, model, name, color) => {
    if (
        (model && Array.isArray(model) && model.length > 0) ||
        (model && !Array.isArray(model))
    ) {
        items.push(
            <FilterToggleItem
                key={name}
                model={model}
                name={name}
                color={color}
            />
        );
    }
};

export const addBxCores = (props, items) => {
    if (props.ignoreBxCoreLabels) {
        addFilterItem(
            items,
            props.patientAnatomyModel.bxCores,
            sceneFilterNames.BIOPSY,
            structureColorPalette.BX_NEGATIVE
        );
    } else {
        addFilterItem(
            items,
            props.patientAnatomyModel.bxCoresNegative,
            sceneFilterNames.BIOPSY_BENIGN_CORES,
            structureColorPalette.BX_NEGATIVE
        );
        addFilterItem(
            items,
            props.patientAnatomyModel.bxCoresPca,
            sceneFilterNames.BIOPSY_PCA_CORES,
            structureColorPalette.BX_CANCER
        );
        addFilterItem(
            items,
            props.patientAnatomyModel.bxCoresCSPca,
            sceneFilterNames.BIOPSY_CS_PCA_CORES,
            structureColorPalette.BX_CS_CANCER
        );
    }
};

export const getActiveProstate = (props) => {
    let prostate;
    if (props.patientAnatomyModel.prostate) {
        prostate = props.patientAnatomyModel.prostate;
    } else if (
        props.patientAnatomyModel.prostateAI &&
        props.patientAnatomyModel.prostateAI.visibility
    ) {
        prostate = props.patientAnatomyModel.prostateAI;
    } else if (
        props.patientAnatomyModel.prostateUploaded &&
        props.patientAnatomyModel.prostateUploaded.visibility
    ) {
        prostate = props.patientAnatomyModel.prostateUploaded;
    }
    return prostate;
};
