import * as THREE from "three";
import { refFrames } from "../../constants";

export function computeBiopsyButtonLocations(plan) {
    // Compute the average trajectory of the bx cores
    let avgCoreDirection = computeAverageBxCoreTrajectory(
        plan.StructureData.Biopsy
    );

    // Compute the plane orthogonal to the average bx trajectory
    let projectionPlane = new THREE.Plane(avgCoreDirection, 0);
    let projectionPlaneXVec = new THREE.Vector3(1, 0, 0);
    let projectionPlaneYVec = new THREE.Vector3();
    projectionPlaneYVec.crossVectors(avgCoreDirection, projectionPlaneXVec);

    // Compute the placement bounds for the projected bx cores
    let wbbox =
        plan.StructureData.CoreStructure.Frame[refFrames.MRI].BoundingBox;
    let prostateBounds = getProstateBboxProjectedOntoPlane(
        wbbox,
        projectionPlaneXVec,
        projectionPlaneYVec
    );

    let bxCoreProjBounds = getBxCoreBboxProjectedOntoPlane(
        plan.StructureData.Biopsy,
        projectionPlane,
        projectionPlaneXVec,
        projectionPlaneYVec
    );

    let combinedBounds = getCombinedBboxOfProstateAndBxCores(
        prostateBounds,
        bxCoreProjBounds
    );

    let projCores = getProjectedBxCoresRelativePosition(
        plan.StructureData.Biopsy,
        combinedBounds,
        projectionPlane,
        projectionPlaneXVec,
        projectionPlaneYVec
    );

    return projCores;
}

function computeAverageBxCoreTrajectory(bxCores) {
    let avgCoreDirection = new THREE.Vector3();
    bxCores.forEach(function (b) {
        let bxTip = new THREE.Vector3(
            b.Frame[refFrames.MRI].CorTip.X,
            b.Frame[refFrames.MRI].CorTip.Y,
            b.Frame[refFrames.MRI].CorTip.Z
        );

        let bxBot = new THREE.Vector3(
            b.Frame[refFrames.MRI].CorBot.X,
            b.Frame[refFrames.MRI].CorBot.Y,
            b.Frame[refFrames.MRI].CorBot.Z
        );

        let bxDirection = new THREE.Vector3();

        bxDirection.subVectors(bxBot, bxTip);

        avgCoreDirection.add(bxDirection);
    });
    avgCoreDirection.divideScalar(bxCores.length);
    avgCoreDirection.x = 0.0;
    avgCoreDirection.normalize();

    return avgCoreDirection;
}

function getProstateBboxProjectedOntoPlane(
    worldBbox,
    projectionPlaneXVec,
    projectionPlaneYVec
) {
    let max = worldBbox.Max;
    let min = worldBbox.Min;

    // generate the 8 bbox corners
    let bbox = [];
    bbox.push(new THREE.Vector3(min.X, min.Y, min.Z));
    bbox.push(new THREE.Vector3(min.X, min.Y, max.Z));
    bbox.push(new THREE.Vector3(min.X, max.Y, min.Z));
    bbox.push(new THREE.Vector3(min.X, max.Y, max.Z));
    bbox.push(new THREE.Vector3(max.X, min.Y, min.Z));
    bbox.push(new THREE.Vector3(max.X, min.Y, max.Z));
    bbox.push(new THREE.Vector3(max.X, max.Y, min.Z));
    bbox.push(new THREE.Vector3(max.X, max.Y, max.Z));

    // project world reference frame prostate bounding box into coronal plane
    let prostateMaxX = -1000,
        prostateMaxY = -1000;
    let prostateMinX = 1000,
        prostateMinY = 1000;

    bbox.forEach(function (el) {
        let x = el.dot(projectionPlaneXVec);
        let y = el.dot(projectionPlaneYVec);

        if (x > prostateMaxX) {
            prostateMaxX = x;
        }
        if (y > prostateMaxY) {
            prostateMaxY = y;
        }

        if (x < prostateMinX) {
            prostateMinX = x;
        }
        if (y < prostateMinY) {
            prostateMinY = y;
        }
    });

    return {
        minX: prostateMinX,
        maxX: prostateMaxX,
        minY: prostateMinY,
        maxY: prostateMaxY,
    };
}

function getBxCoreBboxProjectedOntoPlane(
    bxCores,
    projectionPlane,
    projectionPlaneXVec,
    projectionPlaneYVec
) {
    let bxMaxX = -1000,
        bxMaxY = -1000;
    let bxMinX = 1000,
        bxMinY = 1000;
    bxCores.forEach(function (b) {
        let bxCenter = new THREE.Vector3(
            b.Frame[refFrames.MRI].Center.X,
            b.Frame[refFrames.MRI].Center.Y,
            b.Frame[refFrames.MRI].Center.Z
        );

        let projectedBxCenter = new THREE.Vector3();
        projectionPlane.projectPoint(bxCenter, projectedBxCenter);

        let x = projectedBxCenter.dot(projectionPlaneXVec);
        let y = projectedBxCenter.dot(projectionPlaneYVec);

        if (x > bxMaxX) {
            bxMaxX = x;
        }
        if (y > bxMaxY) {
            bxMaxY = y;
        }

        if (x < bxMinX) {
            bxMinX = x;
        }
        if (y < bxMinY) {
            bxMinY = y;
        }
    });

    return {
        minX: bxMinX,
        maxX: bxMaxX,
        minY: bxMinY,
        maxY: bxMaxY,
    };
}

function prostateBboxSupersetOfBxCoreBbox(prostateBounds, bxCoreProjBounds) {
    return (
        bxCoreProjBounds.maxX < prostateBounds.maxX &&
        bxCoreProjBounds.minX > prostateBounds.minX &&
        bxCoreProjBounds.maxY < prostateBounds.maxY &&
        bxCoreProjBounds.minY > prostateBounds.minY
    );
}

function prostateBboxSubsetOfBxCoreBbox(prostateBounds, bxCoreProjBounds) {
    return (
        bxCoreProjBounds.maxX >= prostateBounds.maxX &&
        bxCoreProjBounds.minX <= prostateBounds.minX &&
        bxCoreProjBounds.maxY >= prostateBounds.maxY &&
        bxCoreProjBounds.minY <= prostateBounds.minY
    );
}

function getCombinedBboxOfProstateAndBxCores(prostateBounds, bxCoreProjBounds) {
    let combinedBounds;

    let xrange = prostateBounds.maxX - prostateBounds.minX;
    let yrange = prostateBounds.maxY - prostateBounds.minY;

    if (prostateBboxSupersetOfBxCoreBbox(prostateBounds, bxCoreProjBounds)) {
        const xscale = 0.1;
        const yscale = 0.2;

        combinedBounds = {
            maxX: prostateBounds.maxX - xrange * xscale,
            minX: prostateBounds.minX + xrange * xscale,
            maxY: prostateBounds.maxY - yrange * yscale,
            minY: prostateBounds.minY + yrange * yscale,
        };
    } else if (
        prostateBboxSubsetOfBxCoreBbox(prostateBounds, bxCoreProjBounds)
    ) {
        combinedBounds = {
            maxX: bxCoreProjBounds.maxX,
            minX: bxCoreProjBounds.minX,
            maxY: bxCoreProjBounds.maxY,
            minY: bxCoreProjBounds.minY,
        };
    } else {
        combinedBounds = {
            maxX: Math.max(bxCoreProjBounds.maxX, prostateBounds.maxX),
            minX: Math.min(bxCoreProjBounds.minX, prostateBounds.minX),
            maxY: Math.max(bxCoreProjBounds.maxY, prostateBounds.maxY),
            minY: Math.min(bxCoreProjBounds.minY, prostateBounds.minY),
        };
    }

    return combinedBounds;
}

function getProjectedBxCoresRelativePosition(
    bxCores,
    combinedBounds,
    projectionPlane,
    projectionPlaneXVec,
    projectionPlaneYVec
) {
    // Compute to x and y positions of the projected cores for use in creating the biopsy buttons
    let xrange = combinedBounds.maxX - combinedBounds.minX;
    let yrange = combinedBounds.maxY - combinedBounds.minY;

    let projCores = [];
    bxCores.forEach(function (b) {
        let bxCenter = new THREE.Vector3(
            b.Frame[refFrames.MRI].Center.X,
            b.Frame[refFrames.MRI].Center.Y,
            b.Frame[refFrames.MRI].Center.Z
        );

        let projectedBxCenter = new THREE.Vector3();
        projectionPlane.projectPoint(bxCenter, projectedBxCenter);

        let x =
            1 -
            (projectedBxCenter.dot(projectionPlaneXVec) - combinedBounds.minX) /
                xrange;
        let y =
            (projectedBxCenter.dot(projectionPlaneYVec) - combinedBounds.minY) /
            yrange;

        const plotCoordMax = 0.98;
        const plotCoordMin = 0.02;

        x = x > plotCoordMax ? plotCoordMax : x;
        x = x < plotCoordMin ? plotCoordMin : x;
        y = y > plotCoordMax ? plotCoordMax : y;
        y = y < plotCoordMin ? plotCoordMin : y;

        let projCore = { x: x, y: y };
        projCores.push(projCore);
    });

    return projCores;
}
