import React, { Component } from "react";
import TopBar from "../../__shared__/TopBar";
import ProfileMenu from "../../__shared__/ProfileMenu";
import StepMarkerBar from "../../__shared__/StepMarkerBar";
import AvendaLogo from "../../__shared__/AvendaLogo";
import "./styles.css";
import PreviousStepButton from "../../__shared__/PreviousStepButton";
import NextStepButton from "../../__shared__/NextStepButton";
import { connect } from "react-redux";
import {
    FREEHAND_BIOPSY_UPLOAD_NUDGE_TEXT,
    parsingCardLabels,
    PATIENT_DETAILS_NUDGE_TEXT,
    SEG_MRI_STEP,
    UPLOAD_STEP,
    uploadCardText,
} from "../constants";
import {
    setCurrentTreatmentPlan,
    setStepActive,
    setStepComplete,
} from "../actions";
import {
    deleteUpload,
    getTreatmentPlan,
    updatePatientData,
    updateVisit,
} from "../helpers";
import { getLoadingScreen, isEmpty } from "../../../helpers/helpers";
import KeyPatientInfoCard from "../KeyPatientInfoCard";
import {
    EMPTY_STRING,
    pageLoadStates,
    stepPath,
    SUCCESS,
} from "../../../constants";
import RequirementsModal from "../../__shared__/RequirementsModal";
import { updateTreatmentPlan } from "../../../helpers/backend_api";
import DecisionAlertCard from "../../__shared__/DecisionAlertCard";
import PropTypes from "prop-types";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import { withRouter } from "react-router";
import UploadCardWrapper from "../UploadCardWrapper";
import { Else, If, Then } from "react-if";
import SpinLoader from "../../__shared__/SpinLoader";
import MutedNudgeText from "../../__shared__/MutedNudgeText";

class FreehandUploadWorkflow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadState: pageLoadStates.LOADING,
            displayConfirmPatientDataAlert: false,
            mriUploaded: false,
            mriParsed: false,
            patientData: null,
            planMetaData: null,
            displayDeleteAlert: parsingCardLabels.NONE,
            deleteInProgress: false,
            mriUploadIteration: 0,
        };

        this.requirements = {
            required: [
                {
                    id: "mri-req",
                    satisfied: this.state.mriParsed,
                    reqText:
                        "Upload your Patient's MRI (folder/.zip of DICOM files)",
                    listIndex: 1,
                },
            ],
        };

        this.patientKeyInfoCardRef = React.createRef();
        this.mriUploadCardRef = React.createRef();
        this.nextStep = this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.requirementsCheck = this.requirementsCheck.bind(this);
        this.getRequirements = this.getRequirements.bind(this);
        this.mriUploadCheck = this.mriUploadCheck.bind(this);
        this.verifyPatientDataBtnCallback =
            this.verifyPatientDataBtnCallback.bind(this);
        this.getConfirmPatientDataAlert =
            this.getConfirmPatientDataAlert.bind(this);
        this.setMRIDataUploaded = this.setMRIDataUploaded.bind(this);
        this.updatePatientDetails = this.updatePatientDetails.bind(this);
        this.setMRIDataParsed = this.setMRIDataParsed.bind(this);
        this.setDeleteAlert = this.setDeleteAlert.bind(this);
        this.loadUploadedPlanInfo = this.loadUploadedPlanInfo.bind(this);
        this.saveTreatmentPlan = this.saveTreatmentPlan.bind(this);
        this.autoSaveTreatmentPlan = this.autoSaveTreatmentPlan.bind(this);
    }

    async autoSaveTreatmentPlan() {
        if (!this.state.mriParsed) {
            return;
        }
        let plan = await this.saveTreatmentPlan();
        if (plan) {
            this.props.setStepActive(UPLOAD_STEP, true);
            this.props.setCurrentTreatmentPlan(plan);
        }
    }

    async saveTreatmentPlan() {
        let plan;
        await getTreatmentPlan(
            this.props.authToken,
            this.props.patientuuid,
            this.props.planuuid
        )
            .then((payload) => payload.json())
            .then((json) => {
                if (json.payload.status === SUCCESS) {
                    plan = json.payload.plan;
                } else {
                    plan = this.props.currentTreatmentPlan;
                }
            })
            .catch(() => {
                plan = this.props.currentTreatmentPlan;
            });

        if (plan) {
            let prevPSA = plan.TreatmentPlan.PatientData.PSA;
            plan.TreatmentPlan.PatientData = this.state.patientData;
            plan.MetaData = this.state.planMetaData;
            plan.TreatmentPlan.PatientData.PSA = !isNaN(
                plan.TreatmentPlan.PatientData.PSA
            )
                ? parseFloat(plan.TreatmentPlan.PatientData.PSA)
                : 0;

            plan.SavedData.PlanCreationStep = SEG_MRI_STEP;

            if (prevPSA > 0 && plan.TreatmentPlan.PatientData.PSA === 0) {
                await updateTreatmentPlan({
                    useruuid: this.props.useruuid,
                    patientuuid: this.props.patientuuid,
                    planuuid: this.props.planuuid,
                    token: this.props.authToken,
                    reset: true,
                    plan: plan,
                });
            }

            await updateTreatmentPlan({
                useruuid: this.props.useruuid,
                patientuuid: this.props.patientuuid,
                planuuid: this.props.planuuid,
                token: this.props.authToken,
                plan: plan,
            });

            await updatePatientData({
                patientuuid: this.props.patientuuid,
                token: this.props.authToken,
                patientData: plan.TreatmentPlan.PatientData,
            });

            await updateVisit({
                visituuid: this.props.visituuid,
                patientuuid: this.props.patientuuid,
                visit: {
                    FirstName: plan.TreatmentPlan.PatientData.FirstName,
                    MiddleName: plan.TreatmentPlan.PatientData.MiddleName,
                    LastName: plan.TreatmentPlan.PatientData.LastName,
                    PatientMRN: plan.TreatmentPlan.PatientData.PatientMRN,
                    DOB: plan.TreatmentPlan.PatientData.DOB,
                    Race: plan.TreatmentPlan.PatientData.Race,
                    Ethnicity: plan.TreatmentPlan.PatientData.Ethnicity,
                },
                token: this.props.authToken,
            });
        }
        return plan;
    }

    mriUploadCheck(tplan) {
        if (TreatmentPlan.hasMRI(tplan)) {
            this.setState({
                mriUploaded: true,
                mriParsed: true,
            });
        } else {
            this.setState({
                mriUploaded: false,
                mriParsed: false,
            });
        }
    }

    setMRIDataUploaded(mriUploadedState) {
        this.setState({ mriUploaded: mriUploadedState });
    }

    loadUploadedPlanInfo(mriParsedState) {
        let patientData = {},
            planMetaData = {};

        if (mriParsedState) {
            this.mriUploadCheck(this.props.currentTreatmentPlan.TreatmentPlan);

            if (!isEmpty(this.props.currentTreatmentPlan)) {
                if (
                    TreatmentPlan.hasPatientData(
                        this.props.currentTreatmentPlan.TreatmentPlan
                    )
                ) {
                    patientData =
                        this.props.currentTreatmentPlan.TreatmentPlan
                            .PatientData;
                }

                if (
                    TreatmentPlan.hasMetaData(this.props.currentTreatmentPlan)
                ) {
                    planMetaData = this.props.currentTreatmentPlan.MetaData;
                }
            }
        }

        return { patientData: patientData, planMetaData: planMetaData };
    }

    setMRIDataParsed(mriParsedState) {
        let loadedInfo = this.loadUploadedPlanInfo(mriParsedState);

        this.setState({
            mriParsed: mriParsedState,
            patientData: loadedInfo.patientData,
            planMetaData: loadedInfo.planMetaData,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            loadState: nextProps.pageState,
        });
    }

    componentDidMount() {
        this.mriUploadCheck(this.props.currentTreatmentPlan.TreatmentPlan);
        this.setState({
            loadState: this.props.pageState,
            patientData: this.props.patientData,
            planMetaData: this.props.planMetaData,
        });
    }

    prevStep() {
        this.autoSaveTreatmentPlan();
    }

    async nextStep() {
        if (this.requirementsCheck()) {
            let scope = this;
            let plan = await this.saveTreatmentPlan();

            if (plan) {
                scope.props.history.push(stepPath.STEP2);
                scope.props.setStepComplete(UPLOAD_STEP, true);
                scope.props.setStepActive(UPLOAD_STEP, false);
                scope.props.setStepActive(SEG_MRI_STEP, true);
                scope.props.setCurrentTreatmentPlan(plan);
            }
        } else {
            this.requirementsModalRef.openModal();
        }
    }

    requirementsCheck() {
        return this.state.mriParsed;
    }

    getRequirements() {
        this.requirements.required[0].satisfied = this.state.mriParsed;
        return this.requirements;
    }

    verifyPatientDataBtnCallback() {
        if (this.requirementsCheck()) {
            this.setState({ displayConfirmPatientDataAlert: true });
            return true;
        } else {
            this.requirementsModalRef.openModal();
            return false;
        }
    }

    getConfirmPatientDataAlert(isOn) {
        if (!isOn) {
            return null;
        }

        return (
            <div>
                <DecisionAlertCard
                    id={"review-patient-data-alert"}
                    noBtnCallback={() => {
                        this.setState({
                            displayConfirmPatientDataAlert: false,
                        });
                    }}
                    noBtnTxt={"Revise"}
                    yesBtnTxt={"Confirm"}
                    yesBtnCallback={this.nextStep}
                >
                    <div className={"review-patient-data-alert-card-header"}>
                        <p className={"display-26 black-text"}>
                            Confirm Patient Data?
                        </p>
                    </div>
                    <div className={"review-patient-data-alert-card-body"}>
                        <div className={"review-patient-data-alert-row"}>
                            <p className={"display-16 black-text"}>
                                Please ensure you have reviewed the patient data
                                for accuracy.
                            </p>
                        </div>
                    </div>
                </DecisionAlertCard>
                <div id={"review-patient-data-alert-overlay"} />
            </div>
        );
    }

    deleteAllUploads() {
        let scope = this;
        deleteUpload({
            useruuid: this.props.useruuid,
            patientuuid: this.props.patientuuid,
            planuuid: this.props.planuuid,
            visituuid: this.props.visituuid,
            iteration: this.state.uploadIteration,
            token: this.props.authToken,
        }).then(() => {
            scope.mriUploadCardRef.current.resetCardState();

            this.setState({
                displayDeleteAlert: parsingCardLabels.NONE,
                uploadIteration: this.state.uploadIteration + 1,
                deleteInProgress: false,
                mriUploaded: false,
                mriParsed: false,
            });
        });
    }

    async resetPlanProgress() {
        let plan = this.props.currentTreatmentPlan;

        if (!isEmpty(plan)) {
            plan.SavedData.PlanCreationStep = UPLOAD_STEP;
            plan.SavedData.IsMriUploaded = false;
            plan.TreatmentPlan.AdditionalData.MRI[0] = {};

            this.props.setStepComplete(UPLOAD_STEP, false);
            this.props.setStepActive(UPLOAD_STEP, true);
        }

        this.props.setCurrentTreatmentPlan(plan);
    }

    resetUploadCard() {
        this.setState({
            deleteInProgress: true,
        });

        this.deleteAllUploads();

        this.resetPlanProgress();
        this.setState({
            displayDeleteAlert: parsingCardLabels.NONE,
            uploadIteration: this.state.uploadIteration + 1,
            deleteInProgress: false,
        });
    }

    getDeleteAlert(label) {
        if (label === parsingCardLabels.NONE) {
            return null;
        }

        let cardTitleText = "DELETE Uploaded MRI files?";
        let cardInfoText =
            "Note: You can upload the same files again. You must have an MRI uploaded to proceed.";

        return (
            <div className={"delete-alert-card"}>
                <DecisionAlertCard
                    id={"summary-alert"}
                    noBtnCallback={() => {
                        this.setState({
                            displayDeleteAlert: parsingCardLabels.NONE,
                        });
                    }}
                    noBtnTxt={"Go Back"}
                    yesBtnTxt={
                        <If condition={this.state.deleteInProgress}>
                            <Then>
                                <SpinLoader
                                    display={"inline-block"}
                                    height={"25px"}
                                    width={"25px"}
                                    animationDuration={"1s"}
                                />
                            </Then>
                            <Else>{"Confirm"}</Else>
                        </If>
                    }
                    yesBtnCallback={() => {
                        this.resetUploadCard();
                    }}
                >
                    <div className={"summary-alert-card-header"}>
                        <p
                            className={"display-26"}
                            style={{ color: "#000000" }}
                        >
                            {cardTitleText}
                        </p>
                    </div>
                    <div className={"summary-alert-card-body"}>
                        <div className={"summary-alert-row"}>
                            <p
                                className={"display-16"}
                                style={{ color: "#000000" }}
                            >
                                {cardInfoText}
                            </p>
                        </div>
                    </div>
                </DecisionAlertCard>
            </div>
        );
    }

    updatePatientDetails(patData, metaData) {
        this.setState({
            patientData: patData,
            planMetaData: metaData,
        });
    }

    setDeleteAlert(label) {
        this.setState({
            displayDeleteAlert: label,
        });
    }

    render() {
        let requirements = this.getRequirements();
        let upldState = this.state.mriParsed ? "finished" : "waiting";
        let mutedTextContent = this.state.mriParsed
            ? PATIENT_DETAILS_NUDGE_TEXT
            : FREEHAND_BIOPSY_UPLOAD_NUDGE_TEXT;

        return (
            <div>
                <div className={"create-plan-upload-screen"}>
                    <TopBar>
                        <AvendaLogo
                            id={"create-plan-avenda-logo"}
                            to={"/home"}
                        />
                        <div className={"create-plan-step-bar-container"}>
                            <div className={"step-bar-prev-btn-group"}>
                                <PreviousStepButton
                                    to={stepPath.HOME}
                                    callback={this.prevStep}
                                    isReady={
                                        this.state.loadState !==
                                        pageLoadStates.LOADING
                                    }
                                />
                                <StepMarkerBar />
                            </div>
                            <NextStepButton
                                reqsComplete={this.requirementsCheck()}
                                reqsCompleteTxt={"Verify Patient Data"}
                                noLink={true}
                                text={""}
                                to={stepPath.STEP2}
                                callback={this.verifyPatientDataBtnCallback}
                                isReady={
                                    this.state.loadState !==
                                    pageLoadStates.LOADING
                                }
                            />
                        </div>
                        <ProfileMenu />
                    </TopBar>
                    <RequirementsModal
                        onRef={(ref) => (this.requirementsModalRef = ref)}
                        requirements={requirements}
                    />
                    {getLoadingScreen(this.state.loadState)}
                    <KeyPatientInfoCard
                        ref={this.patientKeyInfoCardRef}
                        updatePatientDetails={this.updatePatientDetails}
                        isBiopsyDataParsed={this.state.mriParsed}
                        patientData={this.state.patientData}
                        metaData={this.state.planMetaData}
                        isFreehandPlan={true}
                        autoSaveCallback={this.autoSaveTreatmentPlan}
                    />
                    <div>
                        <div className={`nudge-text-cntr ${upldState}`}>
                            <MutedNudgeText
                                content={mutedTextContent}
                                id={"Step1"}
                            />
                        </div>
                        <div
                            id={this.props.id}
                            className={`upload-container ${upldState}`}
                            style={this.props.style}
                        >
                            <div
                                className={`upload-container-header ${upldState}`}
                            >
                                <div className={"header-cnts"}>
                                    <p
                                        id={"upload-header-text"}
                                        className={"display-30"}
                                        style={{ color: "#7C7C7C" }}
                                    >
                                        Upload Patient Data
                                    </p>
                                    <div
                                        className={`trash-upload-button ${upldState}`}
                                        onClick={this.handleDeleteUpload}
                                    />
                                </div>
                            </div>
                            <div className={`upload-cards ${upldState}`}>
                                <UploadCardWrapper
                                    ref={this.mriUploadCardRef}
                                    idx={0}
                                    completedText={
                                        uploadCardText.MRI_UPLOAD_COMPLETE
                                    }
                                    text={uploadCardText.MRI_UPLOAD_DEFAULT}
                                    label={parsingCardLabels.MRI}
                                    folderSelect={true}
                                    uploadCallback={this.setMRIDataUploaded}
                                    parseCallback={this.setMRIDataParsed}
                                    uploadIteration={
                                        this.state.mriUploadIteration
                                    }
                                    setDeleteAlert={this.setDeleteAlert}
                                    isFreehand={true}
                                />
                            </div>
                        </div>
                        {this.getDeleteAlert(this.state.displayDeleteAlert)}
                    </div>
                </div>
                {this.getConfirmPatientDataAlert(
                    this.state.displayConfirmPatientDataAlert
                )}
            </div>
        );
    }
}

FreehandUploadWorkflow.propTypes = {
    isPathReportUploaded: PropTypes.bool,
    useruuid: PropTypes.string,
    planuuid: PropTypes.string,
    patientuuid: PropTypes.string,
    visituuid: PropTypes.string,
    authToken: PropTypes.string,
    pageState: PropTypes.string,
    id: PropTypes.string,
    setStepActive: PropTypes.func,
    setStepComplete: PropTypes.func,
    history: PropTypes.object,
    patientData: PropTypes.object,
    planMetaData: PropTypes.object,
    style: PropTypes.object,
    currentTreatmentPlan: PropTypes.object,
    setCurrentTreatmentPlan: PropTypes.func,
};

FreehandUploadWorkflow.defaultProps = {
    currentTreatmentPlan: {},
    authToken: EMPTY_STRING,
    useruuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    isPathReportUploaded: false,
    visituuid: EMPTY_STRING,
};

const mapStateToProps = function (state) {
    return {
        authToken: state.LoginReducer.authToken,
        useruuid: state.LoginReducer.useruuid,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        isPathReportUploaded: state.CreatePlanReducer.isPathReportUploaded,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        setStepActive: (stepIdx, isActive) =>
            dispatch(setStepActive(stepIdx, isActive)),
        setStepComplete: (stepIdx, isComplete) =>
            dispatch(setStepComplete(stepIdx, isComplete)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(FreehandUploadWorkflow)
);
