export let signUpFormFields;

// zxcvbn returns a score from 0 to 4, with 0 being the weakest and 4 being the strongest
// Source: https://github.com/dropbox/zxcvbn#usage
export const zxcvbnStrengthLevel3 = 3;

signUpFormFields = {
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    EMAIL: "Email",
    PHONE_NUMBER: "Phone Number",
    DATE_OF_BIRTH: "Date of Birth",
    USERNAME: "Username",
    PASS: "Enter Password",
    CONFIRM_PASS: "Confirm Password",
};

export const signUpErrorMsgs = {
    NO_FIRST_NAME: "Please enter your first name.",
    NO_LAST_NAME: "Please enter your last name.",
    NO_INVALID_CHAR: "Please ensure name contains only valid characters.",
    NO_EMAIL: "Please enter a valid email.",
    EMAIL_TAKEN: "This email is associated with an existing account.",
    NO_PHONE: "Phone Number field is required.",
    SHORT_PHONE: "Please enter a valid phone number.",
    NO_DOB: "Please enter your Date of Birth.",
    NO_USERNAME: "Username field is required.",
    SHORT_USERNAME: "Please enter a username that is at least 6 characters.",
    USERNAME_TAKEN: "This username is already associated with an account.",
    EMPTY_PASSWORD: "Password cannot be empty.",
    PERSONAL_INFO_PASSWORD: "Password cannot contain personal information.",
    SHORT_PASSWORD: "Please create a stronger password.",
    COMMON_PASSWORD: "Password cannot be a common word.",
    NO_CONFIRM: "Please confirm your password.",
    NO_PW_MATCH: "Passwords do not match.",
};

export const signUpFlowStates = {
    ACCESS_CODE: "ACCESS_CODE",
    SIGNUP_FORM: "SIGNUP_FORM",
    SIGNUP_FORM_STEPS: 3,
};
