import React, { Component } from "react";
import { EMPTY_STRING } from "../../../constants";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import resetArrow from "../../../assets/ccw_btn_dark.svg";

class ResetViewerButton extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
    }

    renderTooltip(props) {
        return (
            <Tooltip id="reset-viewer-button-tooltip" {...props}>
                {this.props.toolTipText}
            </Tooltip>
        );
    }

    handleClick() {
        this.props.resetViewer();
    }

    render() {
        let img = this.props.imgBlue ? this.props.imgBlue : resetArrow;

        return (
            <div
                id={"reset-viewer-button"}
                onClick={this.handleClick}
                style={{ ...this.props.style, cursor: "pointer" }}
            >
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip}
                >
                    <img
                        width={this.props.width}
                        src={img}
                        alt={this.props.toolTipText}
                    />
                </OverlayTrigger>
            </div>
        );
    }
}

ResetViewerButton.propTypes = {
    resetViewer: PropTypes.func,
    imgBlue: PropTypes.string,
    toolTipText: PropTypes.string,
    style: PropTypes.object,
    width: PropTypes.string,
};

ResetViewerButton.defaultProps = {
    resetViewer: undefined,
    width: "40px",
    toolTipText: EMPTY_STRING,
};

export default ResetViewerButton;
