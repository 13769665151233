import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";

export default class EncircledCharSvg extends Component {
    render() {
        return (
            <svg
                id={this.props.id}
                className={this.props.className}
                style={this.props.style}
                width="26px"
                height="26px"
                viewBox="0 0 26 26"
                version="1.1"
            >
                <g
                    id="TP-v2-Copy"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g id="Group-13" transform="translate(-2.375000, 1.000000)">
                        <circle
                            id="Oval"
                            stroke={this.props.color}
                            cx="15"
                            cy="12"
                            r="12"
                        />
                        <text
                            id="?"
                            fontFamily="Roboto-Regular, Roboto"
                            fontSize="17"
                            fontWeight="normal"
                            letterSpacing="0.3090909"
                            fill={this.props.color}
                        >
                            <tspan x="10.8320268" y="18">
                                {this.props.char}
                            </tspan>
                        </text>
                    </g>
                </g>
            </svg>
        );
    }
}

EncircledCharSvg.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    color: PropTypes.string,
    char: PropTypes.string,
    style: PropTypes.object,
    fill: PropTypes.string,
};

EncircledCharSvg.defaultProps = {
    char: "?",
    color: "#424242",
};
