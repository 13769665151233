import React, { Component } from "react";
import "./styles.css";
import { clientErrorMessages, EMPTY_STRING } from "../../../constants";
import PropTypes from "prop-types";
import greenCheck from "../../../assets/circle_green_check.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import { updateTreatmentPlan } from "../../../helpers/backend_api";
import { rootStore } from "../../../redux/store";
import { setErrorState } from "../../../redux/error_banner/actions";
import { setCurrentTreatmentPlan } from "../../CreateTPlan_1Upload/actions";
import EncircledCharSvg from "../../__shared__/EncircledCharSvg";

class WorkflowManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeWorkflowIdx: step4ActiveWorkflow.MARGIN_GENERATION,
        };

        this.getActiveWorkflow = this.getActiveWorkflow.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        if (this.props.currentTreatmentPlan.SavedData.LesionConfirmed) {
            this.setState({
                activeWorkflowIdx: step4ActiveWorkflow.TARGET_PLACEMENT,
            });
        }
    }

    handleClick(workflowIdx) {
        if (workflowIdx === step4ActiveWorkflow.TARGET_PLACEMENT) {
            if (!this.props.currentTreatmentPlan.SavedData.LesionConfirmed) {
                return;
            }
        }

        this.setState({ activeWorkflowIdx: workflowIdx });
    }

    getActiveWorkflow(workflowIdx) {
        let scope = this;

        let childWorkflow = this.props.children[parseInt(workflowIdx)];
        if (workflowIdx === step4ActiveWorkflow.MARGIN_GENERATION) {
            childWorkflow = React.cloneElement(childWorkflow, {
                handleConfirm: () => {
                    let plan = scope.props.currentTreatmentPlan;
                    plan.SavedData.LesionConfirmed = true;

                    updateTreatmentPlan({
                        useruuid: scope.props.useruuid,
                        patientuuid: scope.props.patientuuid,
                        planuuid: scope.props.planuuid,
                        token: scope.props.authToken,
                        plan: plan,
                    })
                        .then((payload) => payload.json())
                        .then((json) => {
                            scope.setState({
                                activeWorkflowIdx:
                                    step4ActiveWorkflow.TARGET_PLACEMENT,
                            });
                            scope.props.setCurrentTreatmentPlan(
                                json.payload.plan
                            );
                        })
                        .catch(() => {
                            rootStore.dispatch(
                                setErrorState(
                                    true,
                                    clientErrorMessages.TPLAN_UPDATE_FAILED
                                )
                            );
                        });
                },
            });
        }

        return childWorkflow;
    }

    getCheckmark(workflowIdx) {
        let plan = this.props.currentTreatmentPlan;
        if (workflowIdx === step4ActiveWorkflow.MARGIN_GENERATION) {
            if (
                plan.SavedData.LesionConfirmed ||
                !TreatmentPlan.hasCancerEstimationMap(plan)
            ) {
                return <img src={greenCheck} alt={"X"} />;
            } else {
                return (
                    <EncircledCharSvg
                        char={"1"}
                        color={"#FFFFFF"}
                        className={"unselectable"}
                    />
                );
            }
        } else if (workflowIdx === step4ActiveWorkflow.TARGET_PLACEMENT) {
            if (TreatmentPlan.hasTargets(plan.TreatmentPlan)) {
                return <img src={greenCheck} alt={"X"} />;
            } else {
                let color = plan.SavedData.LesionConfirmed
                    ? "#FFFFFF"
                    : "#777777";

                return (
                    <EncircledCharSvg
                        char={"2"}
                        color={color}
                        className={"unselectable"}
                    />
                );
            }
        }

        return null;
    }

    getRevisedActiveWorkflowIdx(workflowIdx) {
        if (
            !TreatmentPlan.hasCancerEstimationMap(
                this.props.currentTreatmentPlan
            )
        ) {
            workflowIdx = step4ActiveWorkflow.TARGET_PLACEMENT;
        }

        return workflowIdx;
    }

    render() {
        let workflowIdx = this.getRevisedActiveWorkflowIdx(
            this.state.activeWorkflowIdx
        );

        let activeWorkflow = this.getActiveWorkflow(workflowIdx);
        let color = this.props.currentTreatmentPlan.SavedData.LesionConfirmed
            ? "#FFFFFF"
            : "#777777";
        let cmActive =
            workflowIdx === step4ActiveWorkflow.MARGIN_GENERATION
                ? "active"
                : "inactive";
        let abActive =
            workflowIdx === step4ActiveWorkflow.TARGET_PLACEMENT
                ? "active"
                : "inactive";

        return (
            <div id={this.props.id} className={"step-4-workflow-manager"}>
                <div className={"step-4-workflow-manager-header"}>
                    <div
                        className={`step-4-workflow-manager-header-item ${cmActive}`}
                        onClick={() =>
                            this.handleClick(
                                step4ActiveWorkflow.MARGIN_GENERATION
                            )
                        }
                    >
                        <p className={"display-14"}>Review Lesion Contour</p>
                        {this.getCheckmark(
                            step4ActiveWorkflow.MARGIN_GENERATION
                        )}
                    </div>
                    <div
                        className={`step-4-workflow-manager-header-item ${abActive}`}
                        onClick={() =>
                            this.handleClick(
                                step4ActiveWorkflow.TARGET_PLACEMENT
                            )
                        }
                    >
                        <p className={"display-14"} style={{ color: color }}>
                            Tool Placement
                        </p>
                        {this.getCheckmark(
                            step4ActiveWorkflow.TARGET_PLACEMENT
                        )}
                    </div>
                </div>
                <div className={"step-4-workflow-manager-body"}>
                    {activeWorkflow}
                </div>
            </div>
        );
    }
}

WorkflowManager.propTypes = {
    currentTreatmentPlan: PropTypes.object,
    id: PropTypes.string,
    children: PropTypes.any,
};

WorkflowManager.defaultProps = {
    id: EMPTY_STRING,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        planuuid: state.CreatePlanReducer.planuuid,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

/* istanbul ignore once */
const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(WorkflowManager)
);

export const step4ActiveWorkflow = {
    MARGIN_GENERATION: 0,
    TARGET_PLACEMENT: 1,
};
