import React from "react";
import { clientErrorMessages, EMPTY_STRING, SUCCESS } from "../../../constants";
import "./styles.css";
import PropTypes from "prop-types";
import plusSignBlue from "../../../assets/plus_icon_blue.svg";
import { deleteAblationProfile, getAblationProfiles } from "../helpers";
import { isEmpty } from "../../../helpers/helpers";
import { rootStore } from "../../../redux/store";
import { setErrorState } from "../../../redux/error_banner/actions";
import AblationCollapsibleButton from "./AblationCollapsibleButton";
import DecisionAlertCard from "../../__shared__/DecisionAlertCard";
import { Else, If, Then } from "react-if";
import SpinLoader from "../../__shared__/SpinLoader";
import { updateUser } from "../../Login/helpers";
import { connect } from "react-redux";
import { setUserData } from "../../Login/actions";
import { cardTitleText, cardInfoText } from "../../../constants";

class ToolProfileMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preMadeProfiles: [],
            customProfiles: [],
            toolInDeletion: null,
            deleteInProgress: false,
        };

        this.getToolProfiles = this.getToolProfiles.bind(this);
        this.handleInitiateDelete = this.handleInitiateDelete.bind(this);
        this.handleDeleteToolProfile = this.handleDeleteToolProfile.bind(this);
        this.handleChangeDefaultTool = this.handleChangeDefaultTool.bind(this);
        this.renderPreMadeToolList = this.renderPreMadeToolList.bind(this);
        this.renderCustomToolList = this.renderCustomToolList.bind(this);
        this.renderDeleteModal = this.renderDeleteModal.bind(this);
    }

    async componentDidMount() {
        await this.getToolProfiles();
    }

    async getToolProfiles() {
        const { userData, authToken } = this.props;

        if (!userData || !authToken) {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.LOGIN_FAILED)
            );
            return;
        }

        const request = {
            userUuid: userData.uuid,
            authToken,
        };

        const response = await getAblationProfiles(request);
        const { preMadeProfiles, customProfiles } = response;
        if (!response || response.status !== SUCCESS) {
            this.setState({
                preMadeProfiles: [],
                customProfiles: [],
            });
        } else {
            this.setState({
                preMadeProfiles: isEmpty(preMadeProfiles)
                    ? []
                    : preMadeProfiles,
                customProfiles: isEmpty(customProfiles) ? [] : customProfiles,
            });
        }
    }

    handleInitiateDelete(toolProfileUUID) {
        this.setState({ toolInDeletion: toolProfileUUID });
    }

    async handleDeleteToolProfile() {
        this.setState({ deleteInProgress: true });

        const request = {
            userUuid: this.props.userData.uuid,
            authToken: this.props.authToken,
            ablationProfileUUID: this.state.toolInDeletion,
        };

        try {
            await deleteAblationProfile(request);
        } catch (e) {
            rootStore.dispatch(
                setErrorState(true, clientErrorMessages.LOGIN_FAILED)
            );
            this.setState({
                deleteInProgress: false,
                toolInDeletion: null,
            });
        }
        await this.getToolProfiles();
        this.setState({
            deleteInProgress: false,
            toolInDeletion: null,
        });
    }

    async handleChangeDefaultTool(toolProfileUUID) {
        const updatedUserData = {
            ...this.props.userData,
            defaultAblationProfileUUID: toolProfileUUID,
        };

        try {
            const response = await updateUser(
                this.props.unText,
                this.props.authToken,
                updatedUserData
            );
            if (isEmpty(response) || response.status !== SUCCESS) {
                rootStore.dispatch(
                    setErrorState(
                        true,
                        clientErrorMessages.FAILED_TO_UPDATE_USER
                    )
                );
            }
            this.props.setUserData(response.user);
        } catch (e) {
            rootStore.dispatch(setErrorState(true, String(e.message)));
        }
    }

    renderPreMadeToolList() {
        return this.state.preMadeProfiles.map(
            ({ ablationProfileUUID, name, modality }) => (
                <AblationCollapsibleButton
                    key={ablationProfileUUID}
                    toolUUID={ablationProfileUUID}
                    toolName={name}
                    modality={modality}
                    isCustom={false}
                    selectedAsDefault={
                        this.props.userData.defaultAblationProfileUUID ===
                        ablationProfileUUID
                    }
                    handleInitiateDelete={null}
                    handleChangeDefaultTool={this.handleChangeDefaultTool}
                    handleEditTool={null}
                />
            )
        );
    }

    renderCustomToolList() {
        return this.state.customProfiles.map(
            ({ ablationProfileUUID, name, modality }) => (
                <AblationCollapsibleButton
                    key={ablationProfileUUID}
                    toolUUID={ablationProfileUUID}
                    toolName={name}
                    modality={modality}
                    isCustom={true}
                    selectedAsDefault={
                        this.props.userData.defaultAblationProfileUUID ===
                        ablationProfileUUID
                    }
                    handleInitiateDelete={this.handleInitiateDelete}
                    handleChangeDefaultTool={this.handleChangeDefaultTool}
                    handleEditTool={this.props.editToolCallback}
                />
            )
        );
    }

    renderDeleteModal() {
        if (!this.state.toolInDeletion) {
            return null;
        }

        return (
            <DecisionAlertCard
                id={"ablation-profile-alert"}
                noBtnCallback={() => {
                    this.setState({
                        toolInDeletion: null,
                    });
                }}
                noBtnTxt={"Go Back"}
                yesBtnTxt={
                    <If condition={this.state.deleteInProgress}>
                        <Then>
                            <SpinLoader
                                display={"inline-block"}
                                height={"25px"}
                                width={"25px"}
                                animationDuration={"1s"}
                            />
                        </Then>
                        <Else>{"Confirm"}</Else>
                    </If>
                }
                yesBtnCallback={this.handleDeleteToolProfile}
            >
                <div className={"ablation-profile-alert-card-header"}>
                    <p className={"display-26 black-text"}>{cardTitleText}</p>
                </div>
                <div className={"ablation-profile-alert-card-body"}>
                    <div className={"ablation-profile-alert-row"}>
                        <p className={"display-16 black-text"}>
                            {cardInfoText}
                        </p>
                    </div>
                </div>
            </DecisionAlertCard>
        );
    }

    // used two render list functions so that
    // pre-made default tools are always on top
    render() {
        return (
            <div className="tool-profile-menu-container">
                {this.renderPreMadeToolList()}
                {this.renderCustomToolList()}
                {this.renderDeleteModal()}
                <button
                    className="tool-profile-menu-add-tool-button"
                    onClick={() => {
                        this.props.createToolCallback();
                    }}
                >
                    Create New Tool Profile
                    <img src={plusSignBlue} alt="add new tool profile button" />
                </button>
            </div>
        );
    }
}

ToolProfileMenu.propTypes = {
    userData: PropTypes.object,
    unText: PropTypes.string,
    authToken: PropTypes.string,
    editToolCallback: PropTypes.func,
    createToolCallback: PropTypes.func,
    setUserData: PropTypes.func,
};

ToolProfileMenu.defaultProps = {
    userData: {},
    authToken: EMPTY_STRING,
    setUserData: () => {},
};

const mapDispatchToProps = function (dispatch) {
    return {
        setUserData: (userData) => dispatch(setUserData(userData)),
    };
};

export default connect(null, mapDispatchToProps)(ToolProfileMenu);
