import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import legendUserSelect from "../../../../assets/marks_score_plot_legend_user_select.svg";
import legendStar from "../../../../assets/marks_score_plot_legend_star.svg";
import xBtn from "../../../../assets/gray_x.svg";

class MarksScoreLegend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            legendVisible: true,
            xBtnVisible: false,
        };
    }

    render() {
        return (
            <div
                id={this.props.id}
                style={{
                    ...this.props.style,
                    ...{ display: this.state.legendVisible ? "" : "none" },
                }}
                className={`marks-score-legend`}
                onMouseEnter={() => {
                    this.setState({ xBtnVisible: true });
                }}
                onMouseLeave={() => {
                    this.setState({ xBtnVisible: false });
                }}
            >
                <div className={"marks-score-legend-row"}>
                    <div id={"marks-score-patient-profile-rainbow"}>
                        <div id={"marks-score-patient-profile-rainbow-umbra"} />
                        <div id={"marks-score-patient-profile-rainbow-conic"} />
                    </div>
                    <p className={"display-10"}>Patient Profile</p>
                </div>
                <div className={"marks-score-legend-row"}>
                    <img src={legendUserSelect} alt={"O"} />
                    <p className={"display-10"}>User Selected</p>
                </div>
                <div className={"marks-score-legend-row"}>
                    <img src={legendStar} alt={"*"} />
                    <p className={"display-10"}>Default</p>
                </div>
                <div
                    className={"marks-score-legend-x-button"}
                    onClick={() => {
                        this.setState({ legendVisible: false });
                    }}
                    style={{
                        display: this.state.xBtnVisible ? "flex" : "none",
                    }}
                >
                    <img src={xBtn} alt={"x"} />
                </div>
            </div>
        );
    }
}

MarksScoreLegend.propTypes = {
    style: PropTypes.object,
    id: PropTypes.string,
};

export default MarksScoreLegend;
