import React from "react";
import PropTypes from "prop-types";

const Lock = ({ fill, className }) => {
    const getLockFill = () =>
        getComputedStyle(document.body).getPropertyValue(
            "--standardPrimaryColor"
        );

    const lockFill = fill || getLockFill();

    return (
        <svg
            width="22"
            height="29"
            viewBox="0 0 27 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.37426 14.0751L4.37431 9.34054C4.38052 4.28045 8.48102 0.180047 13.541 0.173828C18.601 0.180047 22.7015 4.28045 22.7077 9.34054L22.7077 14.0751H22.893C25.0451 14.0751 26.7897 15.8197 26.7897 17.9718V31.9998C26.7897 34.1521 25.0451 35.8966 22.893 35.8966H4.18889C2.03684 35.8966 0.292236 34.1521 0.292236 31.9998V17.9719C0.292236 15.8197 2.03684 14.0751 4.18889 14.0751H4.37426ZM18.4769 9.34054C18.4769 6.61445 16.267 4.40462 13.541 4.40462C10.815 4.40462 8.60511 6.61445 8.60511 9.34054V14.0751H18.4769L18.4769 9.34054ZM13.5539 20.0832C15.2439 20.0903 16.6082 21.4662 16.6011 23.1562C16.5944 24.3126 15.9387 25.3671 14.9045 25.8847L15.377 29.1896C15.3795 29.2069 15.3812 29.2243 15.3822 29.2418C15.3827 29.2507 15.383 29.2596 15.3832 29.2686C15.3832 29.2733 15.3832 29.278 15.3832 29.2828C15.3798 29.6206 15.103 29.892 14.765 29.8885H12.3169C12.2882 29.8885 12.2596 29.8865 12.2313 29.8827C11.8966 29.8352 11.6636 29.5255 11.711 29.1908L12.1834 25.8859C11.1365 25.3677 10.4759 24.2986 10.4808 23.1304C10.4879 21.4403 11.8638 20.0761 13.5539 20.0832Z"
                fill={lockFill}
            />
        </svg>
    );
};

Lock.propTypes = {
    fill: PropTypes.string,
    className: PropTypes.string,
};

export default Lock;
