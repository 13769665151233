import React, { Component } from "react";
import search from "../../../assets/search_icon.svg";
import "./styles.css";
import PropTypes from "prop-types";
import { ASCENDING, EMPTY_STRING } from "../../../constants";
import { If, Then } from "react-if";
import Fuse from "fuse.js";
import PatientList from "../PatientList";
import { sortCategories } from "../constants";
import { setDownloadProperties } from "../actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ClickOutside from "../../__shared__/ClickOutside";

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.search = this.search.bind(this);

        this.fuse = null;

        this.state = {
            searchTerm: EMPTY_STRING,
            focused: false,
            toggleCloseAll: false,
        };
    }

    handleUpdate(event) {
        this.setState({
            searchTerm: event.target.value,
        });
        this.setState({ toggleCloseAll: !this.state.toggleCloseAll });
    }

    handleClickOutside() {
        this.setState({ focused: false });
    }

    search(searchTerm) {
        const maxSearchResults = 50;

        let patientData = this.props.patientsFetchedData;
        if (patientData && this.props.loadComplete && !this.fuse) {
            this.fuse = new Fuse(patientData, {
                keys: [
                    {
                        name: "MetaData.FirstName",
                        weight: 0.2,
                    },
                    {
                        name: "MetaData.MiddleName",
                        weight: 0.05,
                    },
                    {
                        name: "MetaData.LastName",
                        weight: 0.25,
                    },
                    {
                        name: "MetaData.PatientMRN",
                        weight: 0.5,
                    },
                ],
                includeScore: true,
            });
        }

        if (!this.fuse || !searchTerm) {
            return [];
        }

        let searchResults = this.fuse.search(searchTerm);

        return searchResults.slice(0, maxSearchResults);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.downloadProperties.selectActive !==
                prevProps.downloadProperties.selectActive &&
            this.props.downloadProperties.selectActive
        ) {
            this.setState({ focused: false });
        }
        if (
            this.state.focused !== prevState.focused &&
            this.state.focused &&
            this.props.downloadProperties.selectActive
        ) {
            this.props.setDownloadProperties(false, EMPTY_STRING, EMPTY_STRING);
        }
    }

    render() {
        let results = this.search(this.state.searchTerm);
        let parsedResults = results.map((result) => {
            let parsedResult = result.item;
            parsedResult.score = result.score;
            return parsedResult;
        });

        return (
            <ClickOutside onClickOutsideCallback={this.handleClickOutside}>
                <div
                    id={this.props.id}
                    className={"search-bar"}
                    style={this.props.style}
                >
                    <img src={search} alt={""} />
                    <input
                        id={"search-bar-input"}
                        type={"text"}
                        placeholder={"Search for patients..."}
                        value={this.state.searchTerm}
                        onChange={(event) => {
                            this.handleUpdate(event);
                        }}
                        onFocus={() => {
                            this.setState({ focused: true });
                        }}
                        maxLength={"50"}
                        className={"display-22"}
                        autoComplete={"off"}
                    />
                    <If condition={this.state.focused}>
                        <Then>
                            <div className={"search-results"}>
                                <PatientList
                                    style={{ maxHeight: "60vh" }}
                                    patientData={parsedResults}
                                    loadComplete={this.props.loadComplete}
                                    checkNotification={
                                        this.props.checkNotification
                                    }
                                    sortOrder={ASCENDING}
                                    sortCategory={sortCategories.SEARCH_SCORE}
                                    noItemsText={
                                        this.state.searchTerm === EMPTY_STRING
                                            ? emptySearchTermText
                                            : noItemsText
                                    }
                                    toggleCloseAll={this.state.toggleCloseAll}
                                />
                            </div>
                        </Then>
                    </If>
                </div>
            </ClickOutside>
        );
    }
}

SearchBar.propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    patientsFetchedData: PropTypes.array,
    loadComplete: PropTypes.bool,
    downloadProperties: PropTypes.object,
    setDownloadProperties: PropTypes.func,
    checkNotification: PropTypes.func,
};

SearchBar.defaultProps = {
    patientsFetchedData: [],
    loadComplete: false,
};

const mapStateToProps = function (state) {
    return {
        downloadProperties: state.HomeReducer.downloadProperties,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setDownloadProperties: (selectOpen, patID, planID) =>
            dispatch(setDownloadProperties(selectOpen, patID, planID)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SearchBar)
);

const noItemsText = "No patients matched your search query";
const emptySearchTermText = "Search for patients by typing their name or MRN";
