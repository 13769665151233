import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.css";
import KeyPatientInfoMiniCard from "../KeyPatientInfoMiniCard";
import PathologyInfoMiniCard from "../PathologyInfoMiniCard";
import TreatmentPlanInfoMiniCard from "../TreatmentPlanInfoMiniCard";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";
import { hasProperty, isEmpty } from "../../../helpers/helpers";
import { setDownloadProperties } from "../../Home/actions";
import { If, Then } from "react-if";
import AHButton from "../../__shared__/StyleGuide/AHButton";

class SummaryPanel extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.getPlanCreationCompleteFlag(this.props.plan)) {
            this.props.setDownloadProperties(
                true,
                this.props.patientuuid,
                this.props.planuuid
            );
        } else {
            if (this.props.finalizeCallback) {
                this.props.finalizeCallback();
            }
        }
    }

    getPlanCreationCompleteFlag(plan) {
        let flag = false;
        if (
            !isEmpty(plan) &&
            hasProperty(plan, "SavedData.PlanCreationCompleteFlag")
        ) {
            flag = plan.SavedData.PlanCreationCompleteFlag;
        }
        return flag;
    }

    render() {
        if (!this.props.plan) {
            return;
        }

        let finalized = this.getPlanCreationCompleteFlag(this.props.plan);
        let stubbedSummaryStyling = EMPTY_STRING;
        let stubbedButtonStyling = EMPTY_STRING;
        if (this.props.isFreehandPlan) {
            stubbedSummaryStyling = "summary-panel-stubbed-view";
            stubbedButtonStyling = "summary-btn-stubbed-view";
        }

        return (
            <div className={"summary-panel-container"}>
                <If
                    condition={this.props.plan && this.props.plan.TreatmentPlan}
                >
                    <Then>
                        <div
                            className={`summary-panel ${stubbedSummaryStyling}`}
                        >
                            <KeyPatientInfoMiniCard />
                            <If condition={!this.props.isFreehandPlan}>
                                <Then>
                                    <PathologyInfoMiniCard />
                                    <TreatmentPlanInfoMiniCard
                                        cemWarningMsg={this.props.cemWarningMsg}
                                    />
                                </Then>
                            </If>
                            <div
                                className={`summary-panel-button incomplete ${stubbedButtonStyling}`}
                            >
                                <AHButton
                                    id={"summary-final-btn"}
                                    className="click-outside-ignore"
                                    onClick={this.handleClick}
                                    text={
                                        finalized ? "Download" : "Finalize Plan"
                                    }
                                    size={"large"}
                                />
                            </div>
                        </div>
                    </Then>
                </If>
            </div>
        );
    }
}

SummaryPanel.propTypes = {
    useruuid: PropTypes.string,
    authToken: PropTypes.string,
    visituuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    isFreehandPlan: PropTypes.bool,
    plan: PropTypes.object,
    finalizeCallback: PropTypes.func,
    setDownloadProperties: PropTypes.func,
    cemWarningMsg: PropTypes.string,
};

SummaryPanel.defaultProps = {
    useruuid: EMPTY_STRING,
    authToken: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    plan: {},
    finalizeCallback: undefined,
    isFreehandPlan: false,
    cemWarningMsg: EMPTY_STRING,
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        plan: state.CreatePlanReducer.currentTreatmentPlan,
        downloadProperties: state.HomeReducer.downloadProperties,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setDownloadProperties: (selectOpen, patID, planID) =>
            dispatch(setDownloadProperties(selectOpen, patID, planID)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPanel);
