import { hasProperty, isEmpty } from "../helpers";
import {
    AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
    refFrames,
} from "../../constants";
import { SystematicTemplateNames } from "../../components/CreateTPlan_3Biopsy/constants";

export const hasMargins = (tplan) =>
    !isEmpty(tplan) &&
    hasProperty(tplan, "StructureData.Margins.length") &&
    tplan.StructureData.Margins.length > 0;

export const getMargins = (tplan) => {
    if (hasMargins(tplan)) {
        return tplan.StructureData.Margins;
    }
};

export const getMarginByIdx = (tplan, idx) => {
    if (hasMargins(tplan) && tplan.StructureData.Margins.length > idx) {
        return tplan.StructureData.Margins[Number(idx)];
    }
};

export const hasTargets = (tplan) =>
    !isEmpty(tplan) &&
    hasProperty(tplan, "StructureData.Targets.length") &&
    tplan.StructureData.Targets.length > 0;

export const getTargets = (tplan) => {
    if (hasTargets(tplan)) {
        return tplan.StructureData.Targets;
    }
};

export const getTargetsByIdx = (tplan, idx) => {
    if (hasTargets(tplan) && tplan.StructureData.Targets.length > idx) {
        return tplan.StructureData.Targets[Number(idx)];
    }
};

export const hasBxCores = (tplan) =>
    !isEmpty(tplan) &&
    hasProperty(tplan, "StructureData.Biopsy.length") &&
    tplan.StructureData.Biopsy.length > 0;

export const hasROIs = (tplan) =>
    !isEmpty(tplan) &&
    hasProperty(tplan, "StructureData.ROIs.length") &&
    tplan.StructureData.ROIs.length > 0;

export const getROIs = (tplan) => {
    if (hasROIs(tplan)) {
        return tplan.StructureData.ROIs;
    }
};

export const getROIByIdx = (tplan, idx) => {
    if (hasROIs(tplan) && tplan.StructureData.ROIs.length > idx) {
        return tplan.StructureData.ROIs[Number(idx)];
    }
};

export const hasOtherStructures = (tplan) =>
    !isEmpty(tplan) &&
    hasProperty(tplan, "StructureData.OtherStructures.length") &&
    tplan.StructureData.OtherStructures.length > 0;

export const getOtherStructures = (tplan) => {
    if (hasOtherStructures(tplan)) {
        return tplan.StructureData.OtherStructures;
    }
};

export const hasCoreStructure = (tplan) =>
    !isEmpty(tplan) && hasProperty(tplan, "StructureData.CoreStructure");

export const getCoreStructure = (tplan) => {
    if (hasCoreStructure(tplan)) {
        return tplan.StructureData.CoreStructure;
    }
};

export const hasMRI = (tplan) => {
    if (tplan?.AdditionalData?.MRI?.length > 0) {
        return (
            Array.isArray(tplan.AdditionalData.MRI[0].Slices) &&
            tplan.AdditionalData.MRI[0].Slices.length > 0
        );
    }

    return false;
};

export const getMRI = (tplan) => {
    if (hasMRI(tplan)) {
        return tplan.AdditionalData.MRI;
    }
};

export const getMRIURIs = (tplan) => {
    if (hasMRI(tplan)) {
        return tplan.AdditionalData.MRI[0].Slices.map((slice) => slice.URI);
    }
};

export const getStructureInFrame = (structure, frame) => {
    if (hasProperty(structure, `Frame.${frame}`)) {
        return structure.Frame[frame.toString()];
    } else {
        return undefined;
    }
};

export const getBiopsyCores = (tplan) => tplan.StructureData.Biopsy;

export const getBiopsyCoreByIdx = (tplan, idx) => {
    if (tplan.StructureData.Biopsy.length > idx) {
        return tplan.StructureData.Biopsy[Number(idx)];
    }
};

export const hasCancerEstimationMap = (tplan) =>
    !isEmpty(tplan) &&
    hasProperty(tplan, "SavedData.CPMURI.length") &&
    tplan.SavedData.CPMURI.length > 0;

export const hasPatientData = (tplan) =>
    !isEmpty(tplan) && hasProperty(tplan, "PatientData");

export const getGleasonGradeGroup = (tplan) => {
    if (hasPatientData(tplan)) {
        return tplan.PatientData.GleasonGradeGroup;
    }
};

export const hasPlanCreationStep = (tplan) =>
    !isEmpty(tplan) && hasProperty(tplan, "SavedData.PlanCreationStep");

export const hasMetaData = (tplan) =>
    !isEmpty(tplan) && hasProperty(tplan, "MetaData");

export const getPlanCreationStep = (tplan) => {
    if (hasPlanCreationStep(tplan)) {
        return tplan.SavedData.PlanCreationStep;
    } else {
        return 0;
    }
};
export const isMRIOnly = (tplan) => {
    if (!isEmpty(tplan) && hasProperty(tplan, "SavedData.MRIOnlyPlan")) {
        return tplan.SavedData.MRIOnlyPlan;
    } else {
        return false;
    }
};

export const isCustomProfilePlan = (tplan) => {
    if (
        !isEmpty(tplan) &&
        hasProperty(tplan, "SavedData.AblationProfileUUID")
    ) {
        return (
            tplan.SavedData.AblationProfileUUID !==
            AVENDA_DEFAULT_ABLATION_PROFILE_UUID
        );
    } else {
        return false;
    }
};

export const addCoreStructureToPlan = (plan, mriStructParams) => {
    if (
        getStructureInFrame(getCoreStructure(plan.TreatmentPlan), refFrames.MRI)
    ) {
        return plan;
    }

    let coreStructure = {
        Frame: { MRI: mriStructParams },
    };

    plan.TreatmentPlan.StructureData["CoreStructure"] = coreStructure;

    return plan;
};

export const hasUserUploadedSegmentationURI = (tplan) =>
    !isEmpty(tplan) &&
    hasProperty(tplan, "SavedData.UploadedProstateURI.length") &&
    tplan.SavedData.UploadedProstateURI.length > 0;

export const hasPathologyReport = (tplan) =>
    !isEmpty(tplan) &&
    hasProperty(tplan, "SavedData.IsPathReportUploaded") &&
    tplan.SavedData.IsPathReportUploaded;

export const hasTemplate = (tplan) =>
    !isEmpty(tplan) &&
    hasProperty(tplan, "SavedData.IsTemplateUploaded") &&
    tplan.SavedData.IsTemplateUploaded;

export const get3DDataFolder = (tplan, patientUUID, visitUUID) => {
    let scanId = tplan.MetaData.MasterScanID;
    if (scanId) {
        return `${patientUUID}/${visitUUID}/${scanId}/3D_DATA`;
    } else {
        // No scan ID expected for freehand case
        return `${patientUUID}/${visitUUID}/3D_DATA`;
    }
};

export const getBxTemplate = (tplan) => {
    if (
        hasStructureMetData(tplan) &&
        tplan.StructureData.StructureMetaData.BiopsyTemplate
    ) {
        return tplan.StructureData.StructureMetaData.BiopsyTemplate;
    } else {
        return SystematicTemplateNames.TR_12; // Default to TR template for backwards compatibility
    }
};

const hasStructureMetData = (tplan) =>
    !isEmpty(tplan) &&
    hasProperty(tplan, "StructureData.StructureMetaData") &&
    tplan.StructureData.StructureMetaData;
