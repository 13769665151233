import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles.css";

export default class SignupLoginButton extends Component {
    render() {
        return (
            <div className="signup-login-button">
                <Link to={"/login"} className="no-underline">
                    <p className="display-regular-16">
                        Already Have an Account? Log In
                    </p>
                </Link>
            </div>
        );
    }
}
