import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";

export default class RequirementList extends Component {
    render() {
        return (
            <div
                id={this.props.id}
                className="requirement-list-container"
                style={this.props.style}
            >
                <div className={"requirement-list"}>
                    {this.props.requiredItems.map((item, index) => (
                        <div key={`required-${index}`}>{item}</div>
                    ))}
                </div>
                <div
                    className={"optional-list"}
                    style={{
                        display:
                            this.props.optionalItems.length <= 0
                                ? "none"
                                : "block",
                    }}
                >
                    <div
                        className="display-regular-20 text-blue"
                        style={{ margin: "10px 0 15px 0" }}
                    >
                        Optional Tasks
                    </div>
                    {this.props.optionalItems.map((item, index) => (
                        <div key={`optional-${index}`}>{item}</div>
                    ))}
                </div>
            </div>
        );
    }
}

RequirementList.propTypes = {
    requiredItems: PropTypes.array,
    optionalItems: PropTypes.array,
    id: PropTypes.string,
    style: PropTypes.object,
};

RequirementList.defaultProps = {
    requiredItems: [],
    optionalItems: [],
};
