export const CREATE_PROFILE_STEPS = {
    CREATE_ABLATION_VOLUME: "CREATE_ABLATION_VOLUME",
    ABLATION_VOLUMES_LIST: "ABLATION_VOLUMES_LIST",
};

export const EXPAND_VOLUMES_CONST = "volumes";
export const DOWNLOAD_VOLUME_CONST = "download";

export const MODALITY_MENU_ITEMS = {
    LASER: "Laser",
    CRYO: "Cryo",
    HIFU: "HIFU",
    OTHER: "Other",
};

export const SUBJECT_MENU_ITEMS = {
    DEFAULT: "Select",
    OPTION_1: "Software Issue",
    OPTION_2: "Feedback",
    OPTION_3: "Support Request",
};

export const DIMENSION_LABELS = {
    X: "X",
    Y: "Y",
    Z: "Z",
    OFFSET: "OFFSET",
    APPLICATOR: "APPLICATOR",
    NAME: "NAME",
};

const startingMeasurement = 5; //[mm]

export const DEFAULT_DIM_VALUES = {
    X: startingMeasurement,
    Y: startingMeasurement,
    Z: startingMeasurement,
    OFFSET: 0.0,
    ELLIPSE: startingMeasurement,
};

export const MAX_TOOL_VOLUME_DIMENSION = 99;
