import React, { Component } from "react";
import LinkWithCallback from "../LinkWithCallback/index";
import "./styles.css";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";
import backArrow from "../../../assets/back_arrow.svg";

export default class PreviousStepButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let btnNotReady = this.props.isReady ? "" : "button-not-ready";

        return (
            <div
                className={`prev-step-button unselectable ${btnNotReady}`}
                style={this.props.style}
            >
                <LinkWithCallback
                    to={this.props.to}
                    callback={this.props.callback}
                    disabled={!this.props.isReady}
                >
                    <img
                        style={{ height: "20px" }}
                        src={backArrow}
                        alt={"<-"}
                    />
                </LinkWithCallback>
            </div>
        );
    }
}

PreviousStepButton.propTypes = {
    to: PropTypes.string,
    callback: PropTypes.func,
    isReady: PropTypes.bool,
    buttonText: PropTypes.string,
    style: PropTypes.object,
};

PreviousStepButton.defaultProps = {
    to: EMPTY_STRING,
    callback: undefined,
    isReady: true,
};
