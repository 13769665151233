import { initialState } from "./init";
import { SET_ERROR_STATE } from "./actions";

export default function ErrorBannerReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_ERROR_STATE:
            return setErrorState(state, action);
        default:
            return state;
    }
}

function setErrorState(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.hasError = action.hasError;
    stateCpy.errorText = action.errorText;
    return stateCpy;
}
