import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import { ReactComponent as DownloadIcon } from "../../../../assets/download.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/check.svg";
import {
    ARTEMIS_SONABLATE_DOWNLOAD_ALERT,
    MODAL_STATES,
    NAV_PLAN_OPTIONS,
} from "../constants";
import {
    createPDFExport,
    createProfuseExport,
    createStandardDicomExport,
    downloadTreatmentPlan,
    updateTreatmentPlan,
} from "../../../../helpers/backend_api";
import SpinLoader from "../../SpinLoader";
import { getCemWarningMsg } from "../../../CreateTPlan_4EditMargin/helpers";

export class DownloadGroupListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onHoverDownloadBtn: false,
            isDownloading: false,
            isDownloaded: false,
        };
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
        this.handleDownloadSuccess = this.handleDownloadSuccess.bind(this);
        this.handleDownloadError = this.handleDownloadError.bind(this);
        this.getFileName = this.getFileName.bind(this);
        this.isDownloadAllowed = this.isDownloadAllowed.bind(this);
        this.showCheckIcon = this.showCheckIcon.bind(this);
        this.onBeforeUnload = this.onBeforeUnload.bind(this);
    }

    onMouseEnter() {
        if (this.isDownloadAllowed()) {
            this.setState({
                onHoverDownloadBtn: true,
            });
        }
    }

    onMouseLeave() {
        this.setState({
            onHoverDownloadBtn: false,
        });
    }

    onBeforeUnload(e) {
        e.preventDefault();
        e.returnValue = "";
    }

    async handleDownload() {
        if (!this.isDownloadAllowed()) {
            return;
        }

        this.setState({
            isDownloading: true,
            onHoverDownloadBtn: false,
        });

        window.addEventListener("beforeunload", this.onBeforeUnload);
        this.props.setModalState(MODAL_STATES.DOWNLOADING);

        let scope = this;
        switch (this.props.fileType) {
            case NAV_PLAN_OPTIONS.PDF: {
                let cemWarnings = [];
                if (
                    this.props.currentTreatmentPlan &&
                    this.props.currentTreatmentPlan.SavedData
                ) {
                    cemWarnings =
                        this.props.currentTreatmentPlan.SavedData.CEMWarnings;
                }
                createPDFExport({
                    useruuid: this.props.useruuid,
                    patientuuid: this.props.patientuuid,
                    planuuid: this.props.planuuid,
                    token: this.props.authToken,
                    metaTreatmentPlan: this.props.currentTreatmentPlan,
                    userData: this.props.userData,
                    includeToolSites: false,
                    exportFormat: NAV_PLAN_OPTIONS.PDF,
                    cemWarningMsg: getCemWarningMsg(cemWarnings),
                })
                    .then((result) => {
                        let updatedPlan = scope.props.currentTreatmentPlan;
                        this.handleDownloadSuccess(result, updatedPlan);
                    })
                    .catch(() => this.handleDownloadError());
                break;
            }

            case NAV_PLAN_OPTIONS.SONABLATE:
            case NAV_PLAN_OPTIONS.ARTEMIS:
                alert(ARTEMIS_SONABLATE_DOWNLOAD_ALERT);
                createProfuseExport({
                    useruuid: this.props.useruuid,
                    patientuuid: this.props.patientuuid,
                    planuuid: this.props.planuuid,
                    token: this.props.authToken,
                    treatmentPlan:
                        this.props.currentTreatmentPlan.TreatmentPlan,
                    exportPlatform: this.props.fileType,
                })
                    .then((result) => {
                        let updatedPlan = scope.props.currentTreatmentPlan;

                        if (
                            this.state.navPlanSelected ===
                            NAV_PLAN_OPTIONS.ARTEMIS
                        ) {
                            updatedPlan.SavedData.ArtemisNavigationPlanURI =
                                result.s3uri;
                        } else if (
                            this.state.navPlanSelected ===
                            NAV_PLAN_OPTIONS.SONABLATE
                        ) {
                            updatedPlan.SavedData.SonablateNavigationPlanURI =
                                result.s3uri;
                        }

                        this.handleDownloadSuccess(result, updatedPlan);
                    })
                    .catch(() => this.handleDownloadError());
                break;

            case NAV_PLAN_OPTIONS.FOCAL_ONE:
            case NAV_PLAN_OPTIONS.VARIAN:
            case NAV_PLAN_OPTIONS.BK_FUSION:
            case NAV_PLAN_OPTIONS.MIM:
            case NAV_PLAN_OPTIONS.KOELIS:
            case NAV_PLAN_OPTIONS.DICOM_RT:
                createStandardDicomExport({
                    useruuid: this.props.useruuid,
                    patientuuid: this.props.patientuuid,
                    planuuid: this.props.planuuid,
                    token: this.props.authToken,
                    treatmentPlan:
                        this.props.currentTreatmentPlan.TreatmentPlan,
                    includeToolSites: false,
                    exportFormat: NAV_PLAN_OPTIONS.DICOM_RT,
                })
                    .then((result) => {
                        let updatedPlan = scope.props.currentTreatmentPlan;
                        updatedPlan.SavedData.DicomRTExportURI = result.s3uri;
                        this.handleDownloadSuccess(result, updatedPlan);
                    })
                    .catch(() => this.handleDownloadError());
                break;

            case NAV_PLAN_OPTIONS.DICOM_SSEG:
                createStandardDicomExport({
                    useruuid: this.props.useruuid,
                    patientuuid: this.props.patientuuid,
                    planuuid: this.props.planuuid,
                    token: this.props.authToken,
                    treatmentPlan:
                        this.props.currentTreatmentPlan.TreatmentPlan,
                    includeToolSites: true,
                    exportFormat: NAV_PLAN_OPTIONS.DICOM_SSEG,
                })
                    .then((result) => {
                        let updatedPlan = scope.props.currentTreatmentPlan;
                        updatedPlan.SavedData.DicomSSEGExportURI = result.s3uri;
                        this.handleDownloadSuccess(result, updatedPlan);
                    })
                    .catch(() => this.handleDownloadError());
                break;

            case NAV_PLAN_OPTIONS.RAW_PLAN_ASSETS:
            case NAV_PLAN_OPTIONS.FOCALPOINT:
                downloadTreatmentPlan({
                    useruuid: this.props.useruuid,
                    patientuuid: this.props.patientuuid,
                    planuuid: this.props.planuuid,
                    token: this.props.authToken,
                })
                    .then((result) => {
                        if (result !== null && result.planUri !== null) {
                            result.downloadUri = result.planUri;
                            this.handleDownloadSuccess(result);
                        } else {
                            this.handleDownloadError();
                        }
                    })
                    .catch(() => this.handleDownloadError());
                break;

            default:
                break;
        }
    }

    async handleDownloadSuccess(result, updatedPlan = null) {
        try {
            window.removeEventListener("beforeunload", this.onBeforeUnload);
            this.setState({
                isDownloading: false,
            });

            if (updatedPlan) {
                updateTreatmentPlan({
                    useruuid: this.props.useruuid,
                    patientuuid: this.props.patientuuid,
                    planuuid: this.props.planuuid,
                    token: this.props.authToken,
                    plan: updatedPlan,
                });
            }

            window.open(result.downloadUri, "_self");

            this.props.setLatestDownloadedFileInfo(
                this.props.fileType,
                this.getFileName(result.downloadUri)
            );

            this.setState({
                isDownloaded: true,
            });

            this.props.setModalState(MODAL_STATES.FINISHED);
        } catch (e) {
            this.handleDownloadError();
        }
    }

    handleDownloadError() {
        this.setState({
            isDownloading: false,
        });

        this.props.setLatestDownloadedFileInfo(
            this.props.fileType,
            this.getFileName("")
        );

        this.props.setModalState(MODAL_STATES.ERROR);
    }

    getFileName(downloadUri) {
        let pathArray = downloadUri.split("/");
        let fileNameWithParams = pathArray[pathArray.length - 1];
        let fileNameWithoutParams = fileNameWithParams.split("?");
        return fileNameWithoutParams[0];
    }

    isDownloadAllowed() {
        return (
            !this.state.isDownloading &&
            this.props.modalState !== MODAL_STATES.DOWNLOADING
        );
    }

    showCheckIcon() {
        return (
            !this.state.isDownloading &&
            (!this.state.onHoverDownloadBtn ||
                this.props.modalState === MODAL_STATES.DOWNLOADING) &&
            this.state.isDownloaded
        );
    }

    showSpinner() {
        return this.state.isDownloading;
    }

    render() {
        return (
            <li
                id={`download-button-${this.props.fileKey}`}
                className={`list-group-item display-regular-20 ${
                    this.state.onHoverDownloadBtn ? "list-group-item-hover" : ""
                }`}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onClick={this.handleDownload}
            >
                {this.props.fileType}
                {this.isDownloadAllowed() && this.state.onHoverDownloadBtn && (
                    <DownloadIcon
                        className={"download-icon click-outside-ignore"}
                    />
                )}
                {this.showCheckIcon() && (
                    <CheckIcon
                        id={`check-icon-${this.props.fileKey}`}
                        className={"check-icon"}
                    />
                )}
                {this.showSpinner() && (
                    <SpinLoader
                        width={"25px"}
                        height={"25px"}
                        display={"block"}
                        loaderWidth={"3px"}
                        fgColor={getComputedStyle(
                            document.documentElement
                        ).getPropertyValue("--avendaTeal1")}
                    />
                )}
                <input
                    type="file"
                    id="file"
                    ref={this.inputFile}
                    style={{ display: "none" }}
                />
            </li>
        );
    }
}

DownloadGroupListItem.propTypes = {
    fileKey: PropTypes.string,
    fileType: PropTypes.string,
    useruuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    authToken: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
    userData: PropTypes.object,
    modalState: PropTypes.number,
    setModalState: PropTypes.func,
    setLatestDownloadedFileInfo: PropTypes.func,
    handlePlanDownload: PropTypes.func,
};
