import { initialState } from "../CreateTPlan_2SegMRI/init";
import { SET_SELECTED_SEGMENTATION } from "./actions";

export default CreatePlanSegReducer;

function CreatePlanSegReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_SELECTED_SEGMENTATION:
            return setSelectedSegmentation(state, action);
        default:
            return state;
    }
}

function setSelectedSegmentation(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.selectedSegmentation = action.seg;
    return stateCpy;
}
