import * as THREE from "three";
import StructureModel from "../PatientAnatomy/StructureModel";
import {
    AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
    refFrames,
    volumeNames,
} from "../../../../../constants";
import { makeMRIViewerMesh } from "../../MRIViewer/helpers";
import {
    defaultAblationVolumeSize,
    defaultFLATargetParams,
    LaserCatheterModels,
} from "./consts";
import { makeCustomCatheterObject } from "../../../../../helpers/load_helpers";

export default class TargetModel extends StructureModel {
    constructor(
        profile,
        abUUID = AVENDA_DEFAULT_ABLATION_PROFILE_UUID,
        vUUID = defaultAblationVolumeSize.LARGE
    ) {
        super();

        this._laserCatheterVisible = true;
        this._metadata = JSON.parse(JSON.stringify(defaultFLATargetParams));
        this._ablationProfile = profile;
        this._ablationProfileUUID = abUUID;
        this._ablationVolumeUUID = vUUID;
    }

    get ablationProfileUUID() {
        return this._ablationProfileUUID;
    }

    set ablationProfileUUID(uuid) {
        this._ablationProfileUUID = uuid;
    }

    get ablationVolumeUUID() {
        return this._ablationVolumeUUID;
    }

    set ablationVolumeUUID(uuid) {
        this._ablationVolumeUUID = uuid;
    }

    get ID() {
        return this._ID;
    }

    set ID(id) {
        this._ID = id;
        this.name = `${volumeNames.MR_TARGETS}_${id}`;
    }

    get laserCatheterVisibility() {
        return this._laserCatheterVisible;
    }

    set laserCatheterVisibility(visibility) {
        this._laserCatheterVisible = visibility;

        if (this._selfMeshView) {
            this._laserCatheter.visible = visibility;
        }
    }

    initializeView(position, quaternion, color) {
        this.position = position;
        this.quaternion = quaternion;
        this.color = new THREE.Color(color);
        this._createMRITargetView(
            position,
            quaternion,
            color,
            this._ablationProfile.volumesDict[this._ablationVolumeUUID]
        );
        this._createMeshTargetView(
            position,
            quaternion,
            color,
            this._ablationProfile.volumesDict[this._ablationVolumeUUID],
            this._ablationProfile.applicatorOffsets[this._ablationVolumeUUID]
        );
        this._initialized = true;
    }

    getTargetInTreatmentPlanFormat() {
        this._metadata.ID = this.ID;
        this._metadata.Color = `#${this.color.getHexString()}`;
        this._metadata.Frame.MRI.Center = {
            X: this.position.x,
            Y: this.position.y,
            Z: this.position.z,
        };
        this._metadata.Frame.MRI.Orientation = {
            X: this._orientation.x,
            Y: this._orientation.y,
            Z: this._orientation.z,
        };
        this._metadata.BoundingBox = {
            Min: {
                X: this.boundingBox.min.x,
                Y: this.boundingBox.min.y,
                Z: this.boundingBox.min.z,
            },
            Max: {
                X: this.boundingBox.max.x,
                Y: this.boundingBox.max.y,
                Z: this.boundingBox.max.z,
            },
        };

        this._metadata.GeometricData = {
            Faces: this.faces,
            Vertices: this.vertices,
        };

        this._metadata.AblationProfileUUID = this._ablationProfileUUID;
        this._metadata.AblationVolumeUUID = this._ablationVolumeUUID;
        this._metadata.ApplicatorOffset =
            this._ablationProfile.applicatorOffsets[this._ablationVolumeUUID];
        this._metadata.LCPartNumber =
            LaserCatheterModels.OCTOBER_2022_MODEL.PART_NUMBER;
        this._metadata.LCPartRevision =
            LaserCatheterModels.OCTOBER_2022_MODEL.REVISION_NUMBER;
        return this._metadata;
    }

    setTargetFromTreatmentPlanFormat(target) {
        let position = new THREE.Vector3(
            target.Frame[refFrames.MRI].Center.X,
            target.Frame[refFrames.MRI].Center.Y,
            target.Frame[refFrames.MRI].Center.Z
        );

        let direction = new THREE.Vector3(
            target.Frame[refFrames.MRI].Orientation.X,
            target.Frame[refFrames.MRI].Orientation.Y,
            target.Frame[refFrames.MRI].Orientation.Z
        );

        let upVec = new THREE.Vector3(0, 1, 0);

        let quaternion = new THREE.Quaternion();
        quaternion.setFromUnitVectors(upVec, direction.clone().normalize());

        let color = new THREE.Color(target.Color);

        this._metadata = target;
        this._ablationProfile.selectedVolume = target.AblationVolumeUUID;

        this.initializeView(position, quaternion, color);
    }

    _createMRITargetView(position, quaternion, color, geometry) {
        if (!this._mriViewer || !geometry) {
            return;
        }

        geometry = geometry.clone();

        let targetObj = makeMRIViewerMesh({
            name: this.name,
            geometry: geometry,
            frontOpacity: 0,
            backOpacity: 1.0,
            visible: true,
            color: color,
            quaternion: quaternion,
            position: {
                x: position.x,
                y: position.y,
                z: position.z,
            },
        });

        this._selfMRIView = targetObj;
        this._mriViewer.RASframe.add(targetObj.scene);

        if (this._mriViewer.meshObjects) {
            this._mriViewer.meshObjects.push(targetObj);
        } else {
            this._mriViewer.meshObjects = [];
        }
    }

    _createMeshTargetView(position, quaternion, color, geometry, offset) {
        if (!this._meshViewer || !geometry) {
            return;
        }

        geometry = geometry.clone();
        geometry.computeVertexNormals();
        geometry.computeBoundsTree();

        let material = new THREE.MeshLambertMaterial({
            transparent: true,
            opacity: 0.5,
            color: color,
            depthWrite: false,
        });

        let targetObj = new THREE.Object3D();
        targetObj.material = material;
        targetObj.quaternion.copy(quaternion);
        targetObj.position.copy(position);
        targetObj.name = this.name;
        targetObj.ID = this.ID;

        let targetObjBack = new THREE.Mesh(
            geometry,
            targetObj.material.clone()
        );
        targetObjBack.material.side = THREE.BackSide;
        targetObjBack.name = this.name;
        targetObjBack.ID = this.ID;
        targetObj.add(targetObjBack);

        let targetObjFront = new THREE.Mesh(
            geometry,
            targetObj.material.clone()
        );
        targetObjFront.material.side = THREE.FrontSide;
        targetObjFront.name = this.name;
        targetObjFront.ID = this.ID;
        targetObj.add(targetObjFront);

        this._laserCatheter = makeCustomCatheterObject(offset, this.ID);
        targetObj.add(this._laserCatheter);

        this._selfMeshView = targetObj;
        this._meshViewer.RASframe.add(targetObj);
    }
}
