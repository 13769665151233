import React, { Component } from "react";
import "./styles.css";
import { EMPTY_STRING, specialSelection } from "../../../constants";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ClickOutside from "../ClickOutside";

class MRIViewerButton extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
    }

    renderTooltip(props) {
        return (
            <Tooltip id="mri-button-tooltip" {...props}>
                {this.props.toolTipText}
            </Tooltip>
        );
    }

    handleClick() {
        if (!this.props.locked) {
            if (this.props.callback) {
                this.props.callback(!this.props.active);
            }

            if (this.props.onClick) {
                this.props.onClick(
                    this.props.active
                        ? specialSelection.NONE_SELECTED
                        : this.props.idx
                );
            }
        }
    }

    render() {
        let buttonId = "mri-viewer-button-" + this.props.name;

        let display = this.props.active ? "active" : "inactive";

        let img;
        if (this.props.active) {
            img = this.props.imgBlue;
        } else {
            img = this.props.dark ? this.props.imgLight : this.props.imgDark;
        }

        let modal = this.props.children != null ? this.props.children : null;
        if (modal != null) {
            modal = React.cloneElement(modal, { active: this.props.active });
        }

        let opacity = this.props.locked ? { opacity: 0.3 } : {};

        return (
            <div
                id={buttonId}
                className={`mri-viewer-button ${display}`}
                style={{ ...this.props.style, ...opacity }}
                onClick={this.handleClick}
            >
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip}
                >
                    <img
                        className={"click-outside-ignore"}
                        src={img}
                        alt={this.props.alt}
                        onClick={this.handleClick}
                    />
                </OverlayTrigger>
                <ClickOutside
                    onClickOutsideCallback={
                        this.props.active && this.handleClick
                    }
                >
                    {modal}
                </ClickOutside>
            </div>
        );
    }
}

MRIViewerButton.propTypes = {
    idx: PropTypes.number,
    alt: PropTypes.string,
    active: PropTypes.bool,
    dark: PropTypes.bool,
    locked: PropTypes.bool,
    callback: PropTypes.func,
    onClick: PropTypes.func,
    imgBlue: PropTypes.string,
    imgLight: PropTypes.string,
    imgDark: PropTypes.string,
    name: PropTypes.string,
    toolTipText: PropTypes.string,
    children: PropTypes.any,
    style: PropTypes.object,
};

MRIViewerButton.defaultProps = {
    idx: specialSelection.NONE_SELECTED,
    alt: EMPTY_STRING,
    active: false,
    dark: false,
    locked: false,
    callback: undefined,
    onClick: undefined,
    imgBlue: EMPTY_STRING,
    imgLight: EMPTY_STRING,
    imgDark: EMPTY_STRING,
};

export default MRIViewerButton;
