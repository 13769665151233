import React, { Component } from "react";
import { connect } from "react-redux";
import { ProgressBar } from "react-bootstrap";
import "./styles.css";
import PropTypes from "prop-types";
import trashCan from "../../../assets/trash_can.svg";
import redX from "../../../assets/red_x.svg";

class UploadFailedCard extends Component {
    render() {
        const isBiopsyDataUploaded = this.props.isBiopsyDataUploaded;
        const parseFailureText = `Parsing Failed: ${
            this.props.parseFailureMessage[this.props.idx]
        }`;
        const uploadLabel = isBiopsyDataUploaded
            ? "Uploading Complete!"
            : "Upload Failed";
        const uploadBarVariant = isBiopsyDataUploaded ? "" : "danger";

        return (
            <div
                id={this.props.id}
                className={`upload-card failed solid`}
                style={this.props.style}
            >
                <div className={"failed-check-container"}>
                    <img src={redX} alt={"FAIL"} />
                </div>
                <div className={"upload-bar-container"}>
                    <div className={"upload-progress-bar"}>
                        <ProgressBar
                            now={100}
                            variant={uploadBarVariant}
                            label={uploadLabel}
                        />
                    </div>
                    <div className={"parse-progress-bar"}>
                        <ProgressBar
                            now={100}
                            variant="danger"
                            label={parseFailureText}
                        />
                    </div>
                </div>
                <div className={"delete-upload-btn-cntr"}>
                    <img
                        className={"delete-upload-btn"}
                        id={`${this.props.label}-upload-card-delete-btn`}
                        src={trashCan}
                        alt={""}
                        onClick={() => {
                            this.props.setDeleteAlert(this.props.label);
                        }}
                    />
                </div>
            </div>
        );
    }
}

UploadFailedCard.propTypes = {
    isBiopsyDataUploaded: PropTypes.bool,
    parseFailureMessage: PropTypes.array,
    idx: PropTypes.number,
    id: PropTypes.string,
    style: PropTypes.object,
    setDeleteAlert: PropTypes.func,
    label: PropTypes.string,
};

UploadFailedCard.defaultProps = {
    isBiopsyDataUploaded: false,
    parseFailureMessage: [],
};

/* istanbul ignore next */
const mapStateToProps = function (state) {
    return {
        isBiopsyDataUploaded: state.CreatePlanReducer.isBiopsyDataUploaded,
        parseFailureMessage: state.CreatePlanReducer.parseFailureMessage,
    };
};

export default connect(mapStateToProps)(UploadFailedCard);
