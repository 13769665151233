import React, { Component } from "react";
import "./styles.css";
import PropTypes from "prop-types";
import { EMPTY_STRING } from "../../../constants";
import { ReactComponent as EditPencilButton } from "../../../assets/edit_pencil.svg";

export default class EditButton extends Component {
    render() {
        return (
            <EditPencilButton
                alt={this.props.alt}
                id={this.props.id}
                className={
                    (this.props.theme
                        ? "edit-button-theme edit-button-" + this.props.theme
                        : "edit-button") +
                    " " +
                    this.props.className
                }
                onClick={this.props.onClick}
                style={{
                    ...this.props.style,
                }}
            />
        );
    }
}

EditButton.propTypes = {
    alt: PropTypes.string,
    id: PropTypes.string,
    theme: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

EditButton.defaultProps = {
    alt: EMPTY_STRING,
    className: EMPTY_STRING,
};
